/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
import { useSnackbar } from "notistack";
// Redux
import { useTypedDispatch, useTypedSelector } from "@/common/lib/redux/store";
import {
  loadingOn,
  loadingOff,
  setErrorText,
  setConfirmText,
} from "@/common/lib/redux/actions/commonAction";
// Components
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider,
  InputAdornment,
  Theme,
  Typography,
} from "@mui/material";
import { Button, Dropdown, Input } from "@/common/components";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// API
import { onError } from "@/common/lib/api/common";
import { postOfferInfo } from "@/common/lib/api/offer";
// Libs
import { isNull, isNotNull } from "@/common/lib/common";
import {
  getMakerList,
  getModelList,
  yearList,
  getYearList,
} from "@/common/lib/varList";

const styles = (theme: Theme) => ({
  container: {
    width: "100%",
    border: "1px solid " + theme.palette.divider,
    padding: "2vh 4vw",
    "& > div.title > p": {
      fontSize: "1.85rem",
      fontWeight: "bold",
      textAlign: "center",
      userSelect: "none",
      marginBottom: "1vh",
    },
    "& > div.top": {
      margin: "1vh 0",
      "& > div": {
        display: "flex",
        alignItems: "center",
        margin: "2vh 4vw",
        "& > p": {
          fontWeight: 500,
          userSelect: "none",
          width: "5em",
          "&::after": {
            content: "'*'",
            color: theme.palette.error.main,
            padding: "1vw",
          },
        },
        "& > span": {
          width: "5vw",
          textAlign: "center",
        },
      },
    },
    "& > div.bottom": {
      margin: "2vh 4vw",
      "& > p": {
        fontWeight: 500,
        userSelect: "none",
        marginBottom: "1vh",
      },
    },
    "& > div.button": {
      margin: "2vh 4vw 0",
      "& > button": {
        width: "100%",
        fontSize: "1rem",
        fontWeight: 500,
        lineHeight: "1.85",
      },
    },
  },
  dropdownFormControl: {
    "&.SMALL": {
      width: "5em !important",
    },
    "&.NORMAL": {
      width: "8.5em !important",
    },
    fontSize: "0.875rem !important",
  },
  dropdownMenuItem: {
    fontSize: "0.875rem",
  },
  input: {
    width: "8.5em",
    fontSize: "0.875rem !important",
    "& input": {
      textAlign: "right",
    },
  },
  textArea: {
    width: "100%",
  },
  divider: {
    margin: "1vh 0",
  },
  dialog: {
    maxWidth: 900,
    width: "fit-content",
    height: "fit-content",
    padding: "2vh 4.5vw",
    borderRadius: "3.5vw",
    userSelect: "none",
  },
  dialogTitle: {
    padding: "1.5vh 4.5vw",
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    wordBreak: "keep-all",
  },
  dialogContent: {
    padding: "0 4.5vw",
    "& > p > span": {
      display: "block",
      fontSize: "0.925rem",
      fontWeight: "500",
      "& > span": {
        fontSize: "0.85rem",
        color: theme.palette.error.main,
        wordBreak: "keep-all",
      },
    },
  },
  dialogActions: {
    "& > button": {
      fontSize: "1.05rem",
    },
  },
});

const OfferContainer = () => {
  const classes = useStyles(styles);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { loginToken } = useTypedSelector((state) => state.auth);
  const dispatch = useTypedDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const inputRef = useRef<HTMLInputElement>(null);

  const [maker, setMaker] = useState<string>("");
  const [model, setModel] = useState<string>("");
  const [price, setPrice] = useState<string>("");
  const [details, setDetails] = useState<string>("");

  const [minYear, setMinYear] = useState<string>("");
  const [maxYear, setMaxYear] = useState<string>("");
  const [minYearList, setMinYearList] = useState<Option[]>(yearList);
  const [maxYearList, setMaxYearList] = useState<Option[]>(yearList);

  const [dialogOpen, setDialogOpen] = useState(false);

  const postOfferMutation = useMutation(postOfferInfo, {
    onSuccess: () => {
      dispatch(
        setConfirmText({
          title: t("DIALOG.TITLE.OFFER_DONE"),
          content: t("DIALOG.200.OFFER_SUCCESS"),
          onApply: () => {
            setMaker("");
            setModel("");
            setPrice("");
            setDetails("");
            setMinYear("");
            setMaxYear("");
            setDialogOpen(false);
          },
          onClose: () => {
            navigate("/offerlist");
          },
        })
      );
    },
    onError: (error) => {
      onError(error);
    },
    onSettled: () => {
      dispatch(loadingOff());
    },
  });

  useEffect(() => {
    if (isNull(minYear)) {
      setMaxYearList(yearList);
    } else {
      const currentYear = new Date().getFullYear() + 1;
      setMaxYearList(getYearList(Number(minYear), currentYear));
    }
  }, [minYear]);

  useEffect(() => {
    if (isNull(maxYear)) {
      setMinYearList(yearList);
    } else {
      const currentYear = new Date().getFullYear() + 1;
      setMinYearList(getYearList(currentYear - 30, Number(maxYear)));
    }
  }, [maxYear]);

  useEffect(() => {
    if (inputRef.current !== null) {
      if (inputRef.current.scrollHeight > 160 || details.length > 250) {
        setDetails(details.slice(0, -1));
      }
    }
  }, [details]);

  const handleOnSubmit = () => {
    if (
      (isNull(minYear) && isNull(maxYear)) ||
      isNull(maker) ||
      isNull(model) ||
      isNull(price)
    ) {
      enqueueSnackbar(t("MSG.CHECK_REQUIRE_VALUE"), { variant: "error" });
    } else {
      setDialogOpen(true);
    }
  };
  const handleOnApply = () => {
    if (isNotNull(loginToken)) {
      const data: { [key: string]: string } = {};
      if (isNotNull(minYear) && isNotNull(maxYear)) {
        data["year"] = minYear + "~" + maxYear;
      } else if (isNotNull(minYear) && isNull(maxYear)) {
        data["year"] = minYear + "~";
      } else if (isNull(minYear) && isNotNull(maxYear)) {
        data["year"] = "~" + maxYear;
      }
      data["maker"] = maker;
      data["model"] = model;
      data["price"] = price;
      if (isNotNull(details)) {
        data["details"] = details;
      }
      dispatch(loadingOn());
      postOfferMutation.mutate({ data: data });
    } else {
      dispatch(
        setErrorText({
          title: t("DIALOG.401.LOGIN_ONLY_SERVICE"),
          content: t("DIALOG.CONTENT.BOOKING_REQUEST_LOGIN"),
        })
      );
    }
    navigate("/offerlist");
  };

  return (
    <>
      <div className={classes.container}>
        <div className="title">
          <Typography>{t("OFFER.OFFER.OFFER_INFO")}</Typography>
        </div>
        <Divider className={classes.divider} />
        <div className="top">
          <div>
            <Typography>{t("VEHICLE.PRODUCT.MAKER")}</Typography>
            <Dropdown
              label="Maker"
              variant="standard"
              value={maker}
              options={getMakerList("")}
              dispatch={(value: string) => {
                setModel("");
                setMaker(value);
              }}
              formControlClassName={`${classes.dropdownFormControl} NORMAL`}
              menuItemClassName={classes.dropdownMenuItem}
            />
          </div>
          <div>
            <Typography>{t("VEHICLE.PRODUCT.MODEL")}</Typography>
            <Dropdown
              label="Model"
              variant="standard"
              value={model}
              options={getModelList("", maker)}
              dispatch={setModel}
              formControlClassName={`${classes.dropdownFormControl} NORMAL`}
              menuItemClassName={classes.dropdownMenuItem}
            />
          </div>
          <div>
            <Typography>{t("VEHICLE.PRODUCT.YEAR")}</Typography>
            <Dropdown
              size="small"
              label="From"
              variant="standard"
              value={minYear}
              options={minYearList}
              dispatch={setMinYear}
              formControlClassName={`${classes.dropdownFormControl} SMALL`}
              menuItemClassName={classes.dropdownMenuItem}
            />
            <span>~</span>
            <Dropdown
              size="small"
              label="To"
              variant="standard"
              value={maxYear}
              options={maxYearList}
              dispatch={setMaxYear}
              formControlClassName={`${classes.dropdownFormControl} SMALL`}
              menuItemClassName={classes.dropdownMenuItem}
            />
          </div>
          <div>
            <Typography>{t("VEHICLE.SALES.PRICE")}</Typography>
            <Input
              variant="standard"
              value={Number(price).toLocaleString()}
              dispatch={(value: string) => {
                const numberValue = value.replaceAll(",", "");
                const regex = /^[0-9\b]+$/;
                if (numberValue === "" || regex.test(numberValue)) {
                  setPrice(String(Number(numberValue)));
                }
              }}
              placeholder="Hope Price"
              className={classes.input}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">USD</InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        <Divider className={classes.divider} />
        <div className="bottom">
          <Typography>{t("OFFER.OFFER.OFFER_DETAIL_INFO")}</Typography>
          <Input
            variant="outlined"
            value={details}
            dispatch={setDetails}
            placeholder="(etc. Color, Option, Fuel, Mission, Drive...)"
            className={classes.textArea}
            multiline
            minRows={7}
            maxRows={7}
            inputRef={inputRef}
          />
        </div>
        <Divider className={classes.divider} />
        <div className="button">
          <Button variant="contained" onClick={() => handleOnSubmit()}>
            {t("OFFER.OFFER.SUBMIT")}
          </Button>
        </div>
      </div>
      <Dialog
        classes={{ paper: classes.dialog }}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="request-confirm-title"
        aria-describedby="request-confirm-description"
      >
        <DialogTitle
          id="request-confirm-title"
          classes={{ root: classes.dialogTitle }}
        >
          <Markup content={t("OFFER.OFFER.MSG.TITLE")} />
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
          <DialogContentText id="request-confirm-description">
            <Markup content={t("OFFER.OFFER.MSG.CONTENT")} />
          </DialogContentText>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogActions }}>
          <Button onClick={() => handleOnApply()}>{t("OPTION.APPLY")}</Button>
          <Button onClick={() => setDialogOpen(false)}>
            {t("OPTION.CANCEL")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OfferContainer;
