import {
  INIT_ON,
  INIT_OFF,
  SET_ACCESS_URL,
  SET_ERROR_TEXT,
  SET_CONFIRM_TEXT,
  DARK_MODE_ON,
  DARK_MODE_OFF,
  LOADING_ON,
  LOADING_OFF,
} from "../types/commonType";

// Action Functions
export const initOn = () => ({ type: INIT_ON });
export const initOff = () => ({ type: INIT_OFF });
export const setAccessURL = (url: string) => ({
  type: SET_ACCESS_URL,
  payload: url,
});
export const setErrorText = ({ title, content, onClose }: ErrorTextType) => ({
  type: SET_ERROR_TEXT,
  payload: {
    title: title,
    content: content,
    onClose: onClose !== undefined ? onClose : () => {},
  },
});
export const setConfirmText = ({
  title,
  content,
  onApply,
  onClose,
}: ContentTextType) => ({
  type: SET_CONFIRM_TEXT,
  payload: {
    title: title,
    content: content,
    onApply: onApply,
    onClose: onClose,
  },
});
export const darkModeOn = () => ({ type: DARK_MODE_ON });
export const darkModeOff = () => ({ type: DARK_MODE_OFF });
export const loadingOn = () => ({ type: LOADING_ON });
export const loadingOff = () => ({ type: LOADING_OFF });
