import {
  useTable,
  useFilters,
  usePagination,
  useGroupBy,
  useSortBy,
} from "react-table";
// Components
import { IconButton, Theme, Typography } from "@mui/material";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
import { colors } from "@/common/lib/style/palette";

const styles = (theme: Theme) => ({
  table: {
    tableLayout: "fixed",
    borderCollapse: "collapse",
    borderSpacing: 0,
    borderTop: "2px solid " + theme.palette.text.primary,
    userSelect: "none",
    fontSize: "0.85rem",
    "& th": {
      padding: "12.5px 15px",
      backgroundColor: colors.gray[2],
      border: "1px solid " + theme.palette.divider,
      whiteSpace: "pre-wrap",
    },
    "& td": {
      border: "1px solid " + theme.palette.divider,
    },
    "& tr:nth-child(2n) > td": {
      background: colors.gray[1],
    },
    "& .title": {
      "& > .MuiTypography-body1": {
        fontSize: "1.25rem",
        fontWeight: 800,
      },
      "& > .MuiTypography-body2": {
        fontSize: "1rem",
        fontWeight: 600,
      },
    },
    "& .top": {
      margin: "1.5vh 4vw 0.5vh",
      paddingBottom: "0.5vh",
      borderBottom: "2px solid " + theme.palette.primary.dark,
      "& > .MuiTypography-body1": {
        fontSize: "1.1rem",
        fontWeight: 800,
        lineHeight: "1.3",
        color: theme.palette.primary.dark,
      },
    },
    "& .middle": {
      padding: "0 4vw",
    },
    "& .middle2": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0.5vh 4vw 1vh",
      marginBottom: "1vh",
      borderBottom: "2px dotted " + theme.palette.primary.main,
      "& > svg": {
        fontSize: "1.15rem",
        marginRight: "1vw",
      },
      "& > p": {
        fontSize: "0.9rem",
        fontWeight: 500,
      },
    },
    "& .bottom": {
      display: "inline-table",
      width: "100%",
      "& > div": {
        display: "table-cell",
        width: "50%",
        paddingBottom: "1vh",
        textAlign: "center",
        verticalAlign: "middle",
        "& > .MuiTypography-body1": {
          fontSize: "0.95rem",
          fontWeight: 800,
          color: theme.palette.primary.dark,
        },
        "& > .MuiTypography-body2": {
          fontSize: "0.9rem",
          fontWeight: 600,
        },
        "& > button": {
          padding: "0 0.5rem",
          fontSize: "0.825rem",
          lineHeight: "1.85",
        },
      },
      "& > div:first-child:not(:list-child)": {
        borderRight: "2px dotted " + theme.palette.primary.main,
      },
      "& > div:last-child:not(:first-child)": {
        borderLeft: "2px dotted " + theme.palette.primary.main,
      },
    },
    "& .noData": {
      fontSize: "0.925rem",
      textAlign: "center",
      padding: "1.5vh 0",
    },
  },
  pagination: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "1vh",
    "& > span": {
      wordSpacing: "0.15rem",
      margin: "0 2.5vw",
      fontSize: "0.9rem",
    },
    "& > button": {
      padding: "1.5vw",
      "& > svg": {
        fontSize: "1.35rem",
      },
    },
  },
});

const OfferListTable = ({
  columns,
  data,
  updateData,
  filterTypes,
  noDataText,
  ...props
}: any) => {
  const classes = useStyles(styles);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      updateData,
      filterTypes,
    },
    useFilters,
    useGroupBy,
    useSortBy,
    usePagination
  );

  return (
    <>
      <table
        className={
          props.className !== undefined
            ? `${classes.table} ${props.className}`
            : classes.table
        }
        {...getTableProps()}
      >
        <colgroup>
          <col style={{ width: "15%" }} />
          <col style={{ width: "52.5%" }} />
          <col style={{ width: "32.5%" }} />
        </colgroup>
        <thead>
          <tr>
            <th colSpan={columns.length}>
              <div className="title">
                <Typography>* OFFER LIST *</Typography>
              </div>
            </th>
          </tr>
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              style={{ display: "none" }}
            >
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>
                  {column.canFilter ? column.render("Filter") : null}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                <td colSpan={columns.length}>
                  <div className="top">
                    <Typography variant="body1">OFFER LIST</Typography>
                    <div {...row.cells[0].getCellProps()}>
                      {row.cells[0].render("Cell")}
                    </div>
                  </div>
                  <div className="middle">
                    <div {...row.cells[1].getCellProps()}>
                      {row.cells[1].render("Cell")}
                    </div>
                  </div>
                  <div className="middle2">
                    <AccessTimeIcon />
                    <Typography>Offer Date : {row.cells[3].value}</Typography>
                  </div>
                  <div className="bottom">{row.cells[3].render("Cell")}</div>
                </td>
              </tr>
            );
          })}
          {page.length === 0 && (
            <tr>
              <td colSpan={columns.length}>{noDataText}</td>
            </tr>
          )}
        </tbody>
      </table>
      <div className={classes.pagination}>
        <IconButton onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          <KeyboardDoubleArrowLeftIcon />
        </IconButton>
        <IconButton onClick={() => previousPage()} disabled={!canPreviousPage}>
          <NavigateBeforeIcon />
        </IconButton>
        {pageOptions.length === 0 ? (
          <span>0 of 1</span>
        ) : (
          <span>
            {pageIndex + 1} of {pageOptions.length}
          </span>
        )}
        <IconButton onClick={() => nextPage()} disabled={!canNextPage}>
          <NavigateNextIcon />
        </IconButton>
        <IconButton
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          <KeyboardDoubleArrowRightIcon />
        </IconButton>
      </div>
    </>
  );
};

export default OfferListTable;
