import {
  useTable,
  useFilters,
  usePagination,
  useGroupBy,
  useSortBy,
} from "react-table";
// Components
import { Dropdown } from "@/common/components";
import { IconButton, Theme, Typography } from "@mui/material";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
import { colors } from "@/common/lib/style/palette";
// Libs
import { isNotNull } from "@/common/lib/common";
import { browserViewList } from "@/common/lib/varList";

const styles = (theme: Theme) => ({
  table: {
    tableLayout: "fixed",
    borderCollapse: "collapse",
    borderSpacing: 0,
    borderTop: "2px solid " + theme.palette.text.primary,
    userSelect: "none",
    "& th": {
      padding: "12.5px 15px",
      backgroundColor: colors.gray[2],
      border: "1px solid " + theme.palette.divider,
      whiteSpace: "pre-wrap",
    },
    "& td": {
      border: "1px solid " + theme.palette.divider,
    },
    "& tr:nth-child(2n) > td": {
      background: colors.gray[1],
    },
    "& .title": {
      "& > p": {
        fontSize: "1.35rem",
        fontWeight: 800,
      },
    },
    "& .left": {
      width: "16.5%",
      padding: "12.5px 15px",
      textAlign: "center",
      float: "left",
      "& > .MuiTypography-body1": {
        fontSize: "1rem",
        fontWeight: 800,
        color: theme.palette.primary.dark,
        marginBottom: 2.5,
      },
      "& > .MuiTypography-body2": {
        fontSize: "1.05rem",
        fontWeight: "bold",
        lineHeight: "1.3",
        "&:hover": {
          cursor: "copy",
          color: theme.palette.primary.main,
        },
      },
    },
    "& .middle": {
      width: "68.5%",
      padding: "12.5px 15px",
      float: "left",
    },
    "& .right": {
      width: "15%",
      padding: "12.5px 15px",
      textAlign: "center",
      float: "right",
      "& > .MuiTypography-body1": {
        fontSize: "1rem",
        fontWeight: 800,
        color: theme.palette.primary.dark,
        marginBottom: 2.5,
      },
      "& > .MuiTypography-body2": {
        fontSize: "0.975rem",
        fontWeight: "bold",
        lineHeight: "1.3",
        marginBottom: 5,
      },
    },
    "& .noData": {
      fontSize: "0.925rem",
      textAlign: "center",
      padding: "15px 0",
    },
  },
  status: {
    width: "75px !important",
    height: "25px !important",
    fontSize: "0.8125rem !important",
    margin: "auto",
    wordSpacing: -0.75,
    letterSpacing: -0.375,
  },
  pagination: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: 15,
    "& > span": {
      wordSpacing: 5,
      marginRight: 10,
    },
  },
  dropdownFormControl: {
    marginLeft: 10,
    width: 135,
    fontSize: "14px !important",
  },
  dropdownMenuItem: {
    fontSize: "14px !important",
  },
});

const OfferListTable = ({
  columns,
  data,
  updateData,
  filterTypes,
  noDataText,
  ...props
}: any) => {
  const classes = useStyles(styles);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      updateData,
      filterTypes,
    },
    useFilters,
    useGroupBy,
    useSortBy,
    usePagination
  );

  return (
    <>
      <table
        className={
          props.className !== undefined
            ? `${classes.table} ${props.className}`
            : classes.table
        }
        {...getTableProps()}
      >
        <thead>
          <tr>
            <th colSpan={columns.length}>
              <div className="title">
                <Typography>* OFFER LIST *</Typography>
              </div>
            </th>
          </tr>
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              style={{ display: "none" }}
            >
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>
                  {column.canFilter ? column.render("Filter") : null}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                <td colSpan={columns.length}>
                  <div className="left">
                    <Typography variant="body1">OFFER LIST</Typography>
                  </div>
                  <div className="middle" {...row.cells[0].getCellProps()}>
                    {row.cells[0].render("Cell")}
                  </div>
                  <div className="right" {...row.cells[2].getCellProps()}>
                    <Typography variant="body1">RESPONSE</Typography>
                    {row.cells[2].render("Cell")}
                  </div>
                </td>
              </tr>
            );
          })}
          {page.length === 0 && (
            <tr>
              <td colSpan={columns.length}>
                <Typography className="noData">{noDataText}</Typography>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <div className={classes.pagination}>
        {pageOptions.length === 0 ? (
          <span>0 of 1</span>
        ) : (
          <span>
            {pageIndex + 1} of {pageOptions.length}
          </span>
        )}
        <IconButton onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          <KeyboardDoubleArrowLeftIcon />
        </IconButton>
        <IconButton onClick={() => previousPage()} disabled={!canPreviousPage}>
          <NavigateBeforeIcon />
        </IconButton>
        <IconButton onClick={() => nextPage()} disabled={!canNextPage}>
          <NavigateNextIcon />
        </IconButton>
        <IconButton
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          <KeyboardDoubleArrowRightIcon />
        </IconButton>
        <Dropdown
          value={pageSize}
          options={browserViewList}
          dispatch={(value: number) => {
            if (isNotNull(value)) {
              setPageSize(value);
            } else {
              setPageSize(10);
            }
          }}
          formControlClassName={classes.dropdownFormControl}
          menuItemClassName={classes.dropdownMenuItem}
        />
      </div>
    </>
  );
};

export default OfferListTable;
