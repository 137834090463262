import axios from "axios";
// Redux
import { store } from "@/common/lib/redux/store";
// Libs
import { isNotNull } from "@/common/lib/common";

export const getWishInfo = async (search_type: string, pk: any) => {
  const state = store.getState();
  const headers: { [key: string]: string } = {
    "Content-Type": "application/json",
  };
  if (isNotNull(state.auth.loginToken)) {
    headers["Authorization"] = `Bearer ${state.auth.loginToken}`;
  }

  const url = `/api/wishes/${pk}`;
  const result = await axios({
    method: "get",
    url: url,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
    headers: headers,
    withCredentials: true,
    params: {
      search_type: search_type,
    },
  });
  return result;
};

export const getWishList = async () => {
  const state = store.getState();
  const headers: { [key: string]: string } = {
    "Content-Type": "application/json",
  };
  if (isNotNull(state.auth.loginToken)) {
    headers["Authorization"] = `Bearer ${state.auth.loginToken}`;
  }

  const url = `/api/wishes/`;
  const result = await axios({
    method: "get",
    url: url,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
    headers: headers,
    withCredentials: true,
    params: {
      search_type: "USER",
    },
  });
  return result;
};

export const postWishInfo = async (data: { uid: string }) => {
  const state = store.getState();
  const headers: { [key: string]: string } = {
    "Content-Type": "application/json",
  };
  if (isNotNull(state.auth.loginToken)) {
    headers["Authorization"] = `Bearer ${state.auth.loginToken}`;
  }

  const url = "/api/wishes/";
  const result = await axios({
    method: "post",
    url: url,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
    data: data,
    headers: headers,
    withCredentials: true,
  });
  return result;
};

export const patchWishInfo = async (data: { id: number; data: any }) => {
  const state = store.getState();
  const headers: { [key: string]: string } = {
    "Content-Type": "application/json",
  };
  if (isNotNull(state.auth.loginToken)) {
    headers["Authorization"] = `Bearer ${state.auth.loginToken}`;
  }

  const url = `/api/wishes/${data["id"]}/`;
  const result = await axios({
    method: "patch",
    url: url,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
    headers: headers,
    withCredentials: true,
    data: data["data"],
  });
  return result;
};

export const deleteWishInfo = async (data: { id: number }) => {
  const state = store.getState();
  const headers: { [key: string]: string } = {
    "Content-Type": "application/json",
  };
  if (isNotNull(state.auth.loginToken)) {
    headers["Authorization"] = `Bearer ${state.auth.loginToken}`;
  }

  const url = `/api/wishes/${data["id"]}/`;
  const result = await axios({
    method: "delete",
    url: url,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
    headers: headers,
    withCredentials: true,
  });
  return result;
};
