import { useEffect, useMemo, useState } from "react";
import { css } from "@emotion/css";
import { useTheme } from "@emotion/react";
import { debounce } from "lodash";

export const useStyles = (stylesElement: { [key: string]: any } | Function) => {
  const theme = useTheme();

  return useMemo(() => {
    const rawClasses: { [key: string]: any } =
      typeof stylesElement === "function"
        ? stylesElement(theme)
        : stylesElement;
    const procClasses: { [key: string]: any } = {};

    Object.entries(rawClasses).forEach(([key, value = {}]) => {
      procClasses[key] = css(value);
    });

    return procClasses;
  }, [stylesElement, theme]);
};

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = debounce(() => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }, 250);

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};
