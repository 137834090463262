// Components
import { Theme } from "@mui/material";
import StatusMark from "./StatusMark";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// Libs
import { isNotNull } from "@/common/lib/common";

const styles = (theme: Theme) => ({
  container: {
    position: "relative",
    width: "fit-content",
    height: "22.5vh",
    margin: "auto",
    "& > div": {
      width: "unset",
      height: "6.5%",
      aspectRatio: "1",
      fontSize: "35%",
    },
  },
  image: {
    height: "inherit",
    fill: theme.palette.text.primary,
  },
  front_fender_r: {
    position: "absolute",
    top: "8.5%",
    left: "60%",
    transform: "translate(-50%, -50%)",
  },
  door_f_r: {
    position: "absolute",
    top: "42.5%",
    left: "56.5%",
    transform: "translate(-50%, -50%)",
  },
  door_r_r: {
    position: "absolute",
    top: "62.5%",
    left: "56.5%",
    transform: "translate(-50%, -50%)",
  },
  rear_fender_r: {
    position: "absolute",
    top: "87.5%",
    left: "47.5%",
    transform: "translate(-50%, -50%)",
  },
  rocker_panel_r: {
    position: "absolute",
    top: "47.5%",
    left: "81.5%",
    transform: "translate(-50%, -50%)",
  },
  pillar_a_r: {
    position: "absolute",
    top: "39.5%",
    left: "15%",
    transform: "translate(-50%, -50%)",
  },
  pillar_b_r: {
    position: "absolute",
    top: "55%",
    left: "17.5%",
    transform: "translate(-50%, -50%)",
  },
  pillar_c_r: {
    position: "absolute",
    top: "67.5%",
    left: "7.5%",
    transform: "translate(-50%, -50%)",
  },
});

const RightSideImage = ({ ...props }: any) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1312 2312"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <g transform="translate(0, 2312) scale(0.1, -0.1)">
        <path
          d="M8595 22914 c-264 -18 -342 -27 -390 -45 -66 -25 -154 -81 -225 -144
-83 -74 -221 -160 -370 -232 -362 -175 -422 -206 -510 -262 -52 -34 -110 -66
-130 -71 -103 -28 -537 -379 -710 -575 -179 -202 -315 -425 -341 -557 -10 -49
-16 -60 -45 -77 -28 -17 -33 -26 -38 -68 -19 -158 -59 -251 -217 -503 -108
-174 -141 -236 -163 -314 -10 -32 -35 -86 -56 -120 -42 -68 -70 -141 -70 -187
0 -39 -22 -90 -59 -136 -40 -51 -57 -101 -65 -203 -5 -59 -16 -105 -36 -150
-36 -82 -59 -200 -110 -565 -63 -442 -114 -708 -164 -841 -9 -25 -19 -106 -25
-195 -10 -161 -23 -238 -65 -381 -26 -89 -60 -260 -71 -358 -25 -216 -77 -446
-120 -530 -31 -61 -50 -74 -180 -120 -120 -43 -186 -80 -276 -157 -35 -31 -89
-67 -119 -81 -72 -34 -135 -72 -375 -231 -110 -73 -234 -149 -275 -169 -84
-40 -123 -68 -225 -157 -108 -95 -167 -138 -226 -168 -150 -76 -607 -434 -994
-777 -187 -166 -696 -674 -836 -835 -222 -253 -369 -456 -430 -591 -54 -122
-63 -137 -97 -157 -32 -19 -35 -26 -47 -102 -4 -22 -35 -103 -70 -180 -106
-235 -138 -369 -180 -745 -15 -129 -36 -280 -47 -335 -32 -162 -36 -198 -47
-445 -28 -620 -49 -2562 -32 -2965 12 -271 20 -328 53 -390 51 -94 68 -135 83
-202 22 -95 120 -291 190 -378 67 -84 171 -254 180 -296 4 -18 17 -35 36 -44
27 -14 59 -60 59 -85 0 -6 11 -35 25 -63 17 -37 33 -56 53 -63 22 -9 31 -20
36 -47 8 -44 49 -105 141 -214 38 -46 88 -113 111 -150 29 -48 51 -73 77 -85
28 -14 39 -27 47 -56 13 -49 53 -106 148 -207 51 -55 85 -101 107 -150 24 -51
68 -112 166 -226 74 -85 193 -230 265 -321 141 -178 1003 -1193 1119 -1318
152 -163 404 -467 485 -584 62 -90 141 -289 184 -461 32 -126 57 -305 106
-739 72 -641 102 -835 130 -844 16 -6 40 -58 40 -87 0 -76 84 -229 205 -374
110 -133 206 -203 295 -220 30 -5 93 -26 140 -46 168 -72 405 -125 660 -147
173 -15 230 -6 343 54 l82 43 150 -1 c233 0 1446 -120 1832 -180 89 -13 197
-35 241 -48 165 -50 499 -70 1017 -63 355 5 377 6 540 35 292 52 458 101 654
193 139 65 329 180 391 235 55 50 62 68 79 202 6 46 26 129 46 185 46 135 63
223 74 394 7 98 17 159 31 200 49 134 59 178 94 378 21 116 50 266 66 335 34
149 48 245 59 402 4 70 13 123 21 133 7 8 44 24 81 35 38 11 100 29 137 40 40
11 77 29 91 44 57 61 52 276 -9 396 -29 57 -75 106 -142 152 -64 43 -77 61
-59 82 12 14 35 21 256 75 133 33 453 140 524 175 25 13 80 43 121 68 41 25
104 61 140 81 201 110 630 532 630 619 0 25 33 89 59 112 11 10 27 39 35 65
33 100 41 186 41 421 0 346 -27 499 -113 643 -124 209 -340 449 -511 567 -52
36 -136 96 -187 133 -110 80 -186 117 -388 187 -82 28 -180 67 -218 86 -61 31
-83 36 -211 49 -146 14 -199 27 -229 53 -30 26 -9 35 67 29 55 -5 76 -3 93 9
44 30 65 162 83 504 16 308 24 7159 10 8110 -11 715 -19 878 -46 930 -25 48
-107 58 -590 70 -364 9 -442 15 -438 36 3 19 105 35 288 44 246 13 320 24 366
56 27 18 57 27 121 34 103 12 183 36 337 101 327 138 725 403 859 572 62 77
151 214 187 287 15 30 48 87 74 125 l46 70 3 297 c4 300 -4 427 -32 508 -31
92 -198 366 -281 461 -122 139 -259 264 -375 341 -379 251 -1007 447 -1558
487 -68 5 -125 11 -128 13 -8 8 138 17 546 33 217 9 395 17 397 18 3 4 23
2028 23 2332 l-1 250 -47 36 c-100 77 -175 99 -335 99 -120 0 -198 -17 -322
-67 -113 -46 -174 -48 -293 -10 -56 18 -125 31 -205 38 -179 15 -248 27 -307
55 -29 13 -91 36 -139 51 l-86 27 -370 1 c-203 1 -392 1 -420 -1z m695 -471
c0 -391 19 -426 258 -475 l112 -23 0 -677 c0 -373 -2 -678 -4 -678 -2 0 -114
-22 -250 -50 -613 -124 -937 -212 -1133 -305 -394 -187 -995 -632 -1019 -755
-6 -28 -74 -100 -96 -100 -12 0 -38 -57 -38 -84 0 -17 -11 -34 -34 -51 -67
-51 -156 -270 -175 -431 -6 -50 -21 -118 -32 -150 -80 -221 18 -921 171 -1229
154 -309 507 -664 805 -809 241 -118 329 -157 377 -167 27 -6 48 -12 46 -14
-5 -5 -1066 -25 -1893 -35 -440 -5 -938 -12 -1107 -15 l-308 -6 0 28 c0 31
120 789 146 918 8 44 33 150 56 235 53 206 67 287 78 461 7 115 13 157 30 194
27 58 51 171 91 412 95 585 142 773 259 1038 31 72 63 155 70 185 15 64 32
101 56 121 31 25 74 102 74 131 0 43 26 85 69 113 40 24 53 50 65 127 4 20 23
60 43 89 45 66 98 179 153 329 58 159 123 316 163 397 106 208 586 644 902
818 39 21 108 63 154 93 99 65 519 267 588 283 93 21 134 50 258 176 l120 122
473 1 472 0 0 -217z m1490 127 c43 -23 45 -74 45 -1015 l0 -890 -150 -17
c-142 -16 -330 -29 -617 -44 l-128 -6 0 513 c0 676 10 782 72 823 13 8 73 22
133 31 129 18 195 38 250 75 74 51 115 163 115 315 0 120 33 170 130 200 30 9
60 18 65 20 22 7 67 4 85 -5z m-888 -24 c213 -37 263 -58 298 -126 27 -52 28
-118 4 -159 -40 -68 -192 -109 -359 -97 -160 10 -213 39 -240 129 -27 92 -17
247 18 269 23 15 136 8 279 -16z m911 -2151 c9 -4 17 -13 17 -21 0 -34 -88
-50 -353 -64 -519 -27 -594 -32 -675 -41 -48 -6 -127 -21 -177 -34 -49 -13
-133 -26 -185 -30 -123 -9 -218 -31 -475 -111 -249 -78 -346 -115 -520 -198
-330 -158 -623 -376 -777 -580 -38 -50 -91 -141 -139 -241 -129 -267 -144
-326 -154 -595 -8 -195 10 -574 30 -659 9 -35 31 -89 49 -120 19 -31 42 -82
51 -114 44 -152 334 -485 538 -618 205 -133 350 -196 813 -348 426 -141 521
-177 513 -196 -2 -5 -26 -9 -54 -7 -45 3 -85 17 -155 57 -14 8 -67 18 -118 23
-74 8 -100 15 -131 36 -46 32 -91 46 -151 46 -63 0 -121 18 -151 46 -18 17
-45 28 -90 35 -93 14 -143 32 -339 124 -240 111 -356 193 -564 394 -176 172
-221 249 -322 548 -246 728 -117 1284 417 1798 121 116 173 158 229 182 25 11
65 37 90 58 145 123 523 315 619 315 15 0 43 11 62 25 63 47 117 65 330 114
408 94 622 127 999 156 422 32 721 40 773 20z m-438 -611 c460 -49 907 -204
1250 -433 238 -158 387 -314 475 -496 149 -311 153 -321 158 -435 7 -163 -37
-373 -110 -527 -94 -200 -425 -527 -670 -661 -686 -376 -1827 -439 -2528 -139
-215 92 -385 182 -485 257 -133 99 -330 295 -338 335 -3 17 -17 46 -31 65 -43
60 -86 148 -116 239 -41 127 -80 310 -80 377 0 70 27 188 69 303 107 292 409
675 552 697 39 7 54 19 74 57 7 14 25 24 55 30 25 5 54 17 65 27 30 28 129 71
300 129 520 180 881 226 1360 175z m582 -3392 c17 -9 22 -24 26 -69 3 -32 11
-731 18 -1553 10 -1168 10 -1595 1 -1955 -15 -567 -26 -653 -93 -710 -31 -25
-31 -25 -239 -25 -208 0 -208 0 -239 25 -34 29 -56 88 -72 191 -7 46 -14 680
-19 1934 -4 1026 -11 1877 -15 1891 -5 18 5 51 34 121 23 52 41 100 41 105 0
17 38 45 72 54 51 13 459 6 485 -9z m-3830 -210 c32 -18 43 -71 43 -208 0
-107 -2 -124 -21 -148 -35 -44 -141 -66 -409 -85 -147 -11 -220 -15 -895 -51
-414 -22 -713 -44 -867 -64 -54 -7 -98 -10 -98 -7 1 35 44 318 56 364 17 66
45 127 66 145 58 48 573 71 1548 68 424 -1 560 -4 577 -14z m2323 -12 c215
-20 346 -53 482 -121 90 -45 94 -56 80 -212 -6 -71 -14 -132 -17 -135 -2 -2
-105 3 -227 12 -356 26 -501 32 -1768 72 -272 8 -495 16 -496 17 -1 2 24 369
25 374 5 15 1761 8 1921 -7z m-4832 -185 c8 -17 12 -92 12 -213 l0 -187 -214
-108 c-304 -153 -768 -435 -1301 -790 -332 -221 -439 -299 -595 -432 -52 -45
-162 -135 -245 -200 -87 -69 -229 -198 -336 -305 -259 -258 -404 -438 -430
-533 -6 -21 -27 -58 -48 -80 -50 -55 -68 -92 -77 -158 -9 -69 -17 -90 -64
-169 -38 -64 -48 -95 -70 -217 -6 -35 -26 -113 -45 -174 -39 -126 -61 -256
-70 -409 -9 -157 -23 -235 -62 -335 -54 -140 -61 -188 -55 -380 2 -94 1 -254
-3 -358 l-7 -187 -177 1 c-203 0 -268 8 -289 35 -11 16 -13 52 -7 199 29 829
73 1154 200 1475 29 74 60 163 69 197 30 120 162 354 217 385 16 9 29 24 29
33 0 131 674 868 1245 1361 39 33 176 146 307 252 130 105 262 215 295 245 76
70 227 176 673 472 666 441 939 605 1008 605 21 0 32 -7 40 -25z m5337 -492
l70 -19 2 -650 c1 -357 7 -1418 12 -2359 5 -940 8 -1712 5 -1714 -3 -3 -26 3
-52 13 -89 33 -448 39 -2432 39 -2048 1 -2595 -8 -2837 -43 -192 -29 -173 -38
-173 83 1 115 11 176 35 193 9 7 20 36 25 65 6 30 14 308 20 619 10 612 20
780 50 848 43 98 63 335 95 1122 21 530 33 745 56 980 10 113 29 313 41 445
12 132 24 243 27 247 8 10 207 42 474 78 280 37 465 46 1192 60 843 16 871 16
2145 15 1134 -2 1177 -3 1245 -22z m-5443 -305 c10 -18 12 -98 11 -348 -4
-473 -29 -957 -78 -1455 -36 -364 -52 -593 -75 -1020 -11 -209 -25 -432 -30
-495 -27 -291 -78 -610 -104 -646 -10 -13 -102 -27 -442 -70 -405 -51 -1938
-257 -2285 -307 -110 -16 -162 -20 -164 -13 -12 36 -35 225 -35 284 0 40 9
127 20 194 11 68 31 213 45 324 25 197 65 407 104 552 11 40 25 117 30 170 10
99 28 160 76 267 15 33 35 93 45 134 12 47 31 90 50 115 41 54 80 135 80 166
0 28 34 90 49 90 24 0 69 53 81 95 8 30 35 71 78 122 150 176 476 474 569 518
29 14 60 37 70 51 37 57 411 351 743 583 192 135 340 226 397 246 23 8 44 24
54 42 11 20 49 46 130 89 63 33 211 121 329 196 118 75 220 137 227 137 7 1
18 -9 25 -21z m6433 -3298 c10 -8 24 -28 31 -45 21 -52 27 -703 19 -2179 l-7
-1398 -252 5 c-247 5 -334 15 -356 41 -23 29 -32 341 -37 1328 -5 989 5 1830
26 2091 11 138 14 141 146 171 11 2 108 4 216 3 158 -2 200 -5 214 -17z
m-6690 -1202 c-11 -494 -22 -550 -115 -595 -69 -34 -179 -42 -740 -53 -529
-10 -757 -19 -1200 -45 -387 -23 -776 -43 -1180 -60 -201 -8 -410 -18 -466
-21 l-101 -6 -6 53 c-10 82 -8 305 3 384 6 38 17 79 25 91 20 31 102 48 469
95 171 22 514 68 761 104 248 35 578 80 735 100 157 20 434 57 615 81 272 37
1012 131 1145 147 19 2 41 5 48 5 12 2 13 -46 7 -280z m5434 -121 c216 -46
290 -79 313 -136 21 -53 27 -269 38 -1236 11 -1005 5 -1445 -21 -1549 -11 -43
-17 -51 -54 -67 -54 -24 -143 -42 -335 -69 -428 -59 -791 -153 -1163 -301
-406 -162 -668 -363 -1002 -769 -236 -287 -256 -306 -348 -322 -28 -5 -117
-40 -197 -78 -144 -67 -362 -161 -410 -175 -67 -20 -243 -96 -454 -194 -342
-161 -379 -171 -637 -171 -199 0 -354 16 -449 46 -42 13 -178 48 -304 79 -255
62 -415 112 -423 131 -7 19 35 951 53 1164 7 96 18 194 24 217 14 57 37 359
55 718 28 563 66 1080 90 1250 32 222 55 563 55 809 0 79 3 237 7 353 l6 210
111 12 c61 6 143 18 181 27 39 8 95 19 125 24 75 12 601 27 1395 40 965 16
946 16 2130 15 l1090 -1 124 -27z m-5519 -734 c0 -113 -30 -1170 -40 -1403 -6
-140 -17 -302 -25 -360 -21 -149 -46 -422 -115 -1240 -75 -895 -88 -999 -124
-1013 -17 -7 -148 21 -591 127 -475 113 -475 113 -555 152 -157 75 -296 191
-492 410 -139 156 -204 245 -243 331 -22 46 -40 73 -54 78 -23 7 -54 61 -65
110 -9 41 -39 84 -67 99 -26 12 -35 36 -44 109 -5 36 -16 58 -47 95 -27 30
-79 123 -143 257 -149 307 -163 341 -180 451 -10 62 -27 120 -44 154 -53 105
-79 263 -133 790 -25 244 -33 373 -33 517 0 105 2 194 5 197 10 9 872 65 1250
81 891 36 1434 67 1600 90 150 20 140 22 140 -32z m-3286 -154 c3 -17 6 -163
6 -324 0 -739 71 -1137 289 -1613 49 -106 74 -186 86 -274 4 -26 21 -55 53
-92 31 -36 83 -126 152 -262 58 -114 133 -252 168 -307 34 -54 79 -129 99
-165 66 -121 95 -154 347 -410 137 -139 247 -254 245 -256 -2 -2 -65 -44 -139
-93 -179 -119 -262 -161 -287 -144 -6 3 -16 18 -24 32 -8 15 -54 74 -102 130
-142 168 -216 276 -277 409 -4 8 -16 17 -26 19 -31 5 -54 32 -89 101 -18 36
-74 117 -125 180 -151 191 -341 483 -439 678 -23 45 -64 107 -91 137 -32 36
-50 64 -50 80 0 36 -24 89 -64 144 -37 50 -66 134 -66 190 0 52 -17 106 -44
138 -33 40 -44 83 -56 213 -11 120 -33 1498 -24 1512 3 4 106 8 228 8 l224 0
6 -31z m10126 -2028 l0 -389 -22 -6 c-39 -11 -448 -19 -550 -12 l-98 8 0 394
0 394 335 0 335 0 0 -389z m-972 -344 c5 -23 -23 -54 -70 -78 -20 -10 -123
-39 -230 -64 -679 -159 -741 -177 -990 -295 -188 -89 -294 -150 -378 -220 -49
-41 -100 -70 -124 -70 -7 0 -23 -16 -35 -35 -11 -19 -39 -47 -62 -62 -135 -90
-258 -251 -381 -496 -102 -202 -122 -303 -122 -592 1 -227 20 -396 64 -573 34
-132 66 -207 120 -277 21 -28 43 -70 49 -93 11 -53 42 -101 131 -201 123 -140
372 -346 490 -406 151 -77 445 -186 621 -230 349 -89 1122 -194 1434 -195 172
-1 317 -15 353 -34 64 -33 152 -139 152 -182 0 -55 -73 -75 -278 -76 -302 0
-911 82 -1272 172 -63 16 -180 42 -260 58 -146 30 -254 66 -450 149 -158 68
-405 195 -495 256 -44 29 -111 71 -150 92 -98 54 -133 81 -149 118 -18 42 -39
63 -146 148 -67 52 -95 81 -109 113 -10 24 -54 94 -96 157 -169 247 -251 504
-262 824 -5 149 13 377 39 485 17 72 180 400 246 497 127 184 368 440 525 557
40 30 108 72 152 94 44 22 93 54 110 71 20 22 50 38 100 52 38 11 176 56 305
99 276 93 519 155 841 215 253 47 322 52 327 22z m643 -537 c213 -21 510 -86
757 -166 192 -62 580 -269 766 -408 59 -45 82 -70 101 -110 14 -28 59 -91 101
-141 88 -105 134 -177 173 -273 83 -200 105 -461 52 -612 -73 -209 -196 -395
-399 -604 -133 -136 -209 -192 -427 -308 -222 -118 -319 -157 -539 -217 -223
-61 -274 -65 -771 -65 -474 0 -590 7 -763 49 -180 44 -583 221 -735 323 -293
199 -611 539 -647 695 -14 57 -32 97 -63 139 -15 20 -21 48 -25 107 -7 119 13
229 64 359 25 62 49 136 54 164 13 64 40 111 126 213 37 44 73 94 80 111 20
47 101 115 274 229 317 210 543 319 839 405 346 100 690 139 982 110z m-7912
-951 c124 -40 516 -137 956 -238 193 -44 455 -105 582 -135 334 -80 535 -120
808 -161 390 -59 535 -62 690 -14 39 11 162 65 275 119 236 112 283 133 708
305 173 69 316 124 319 121 3 -3 9 -42 14 -87 10 -98 -1 -193 -37 -337 -21
-83 -25 -120 -21 -185 6 -108 88 -499 128 -612 184 -523 733 -1022 1417 -1289
76 -30 158 -64 181 -75 51 -27 242 -79 455 -127 89 -19 168 -37 176 -40 18 -5
14 -55 -55 -849 -46 -529 -54 -680 -65 -1195 -8 -450 -13 -492 -54 -555 -9
-14 -35 -35 -59 -47 -38 -21 -55 -23 -177 -22 -277 2 -825 60 -980 105 -57 16
-167 43 -245 59 -396 82 -703 162 -1150 301 -331 103 -597 199 -705 254 -47
24 -111 52 -143 61 -32 9 -106 44 -165 77 -289 160 -328 170 -821 206 -144 11
-265 22 -268 24 -2 3 -15 114 -28 248 -26 266 -45 403 -75 519 -26 105 -38
207 -49 415 -15 296 -47 341 -226 328 -69 -5 -78 -4 -100 17 -14 13 -25 29
-25 36 0 24 -184 370 -241 454 -32 47 -116 148 -186 224 -70 76 -148 164 -171
195 -24 31 -110 130 -190 220 -193 216 -250 287 -274 341 -12 28 -59 86 -127
155 -59 61 -175 189 -256 285 -82 96 -186 213 -230 260 -96 101 -123 138 -137
186 -7 26 -18 39 -42 49 -73 31 -48 76 107 193 139 105 357 242 384 242 3 0
49 -14 102 -31z m7221 -2850 c124 -15 243 -22 405 -23 196 -2 235 -5 263 -20
39 -20 55 -55 46 -105 -15 -86 -166 -893 -180 -961 -9 -41 -35 -156 -59 -255
-24 -99 -53 -241 -65 -315 -26 -173 -67 -353 -92 -410 -25 -58 -56 -91 -143
-149 -152 -103 -303 -172 -414 -190 -50 -8 -72 -17 -96 -41 -17 -16 -36 -30
-43 -30 -46 0 -47 211 -3 735 38 456 55 692 81 1135 25 442 37 565 61 628 11
28 17 28 239 1z m-5327 -1754 c466 -36 516 -49 932 -237 340 -154 486 -212
588 -233 17 -3 25 -10 21 -17 -9 -15 -113 -3 -240 27 -203 48 -221 46 -403
-46 -206 -104 -338 -104 -621 -2 -332 119 -466 218 -564 414 -44 88 -54 119
-38 119 4 0 150 -11 325 -25z"
        />
        <path
          d="M9835 19509 c-157 -10 -384 -34 -450 -49 -56 -13 -272 -106 -395
-172 -260 -138 -417 -282 -549 -505 -81 -137 -118 -314 -108 -515 6 -134 25
-200 72 -259 19 -24 40 -62 46 -84 22 -82 149 -212 360 -370 125 -94 196 -136
316 -189 284 -126 549 -179 888 -178 247 1 425 25 717 98 235 59 323 96 498
211 279 185 406 339 475 577 61 213 48 543 -28 694 -30 62 -125 170 -248 285
-131 122 -215 185 -257 193 -18 4 -40 17 -48 29 -8 12 -42 35 -76 50 -151 68
-487 165 -638 185 -88 11 -376 11 -575 -1z m75 -535 l0 -316 -47 28 c-27 15
-79 48 -118 74 -214 142 -465 280 -511 280 -69 0 -16 57 104 112 48 22 121 56
162 75 91 43 183 61 313 62 l97 1 0 -316z m415 306 c154 -14 431 -104 514
-168 l34 -26 -35 -30 c-20 -17 -96 -67 -170 -110 -181 -108 -315 -196 -379
-251 -59 -51 -77 -55 -83 -22 -5 24 -36 543 -36 590 0 31 -1 31 155 17z
m-1236 -433 c189 -111 491 -323 491 -344 0 -7 -1 -13 -2 -13 -97 -19 -730 -13
-828 8 -28 6 -35 13 -41 38 -14 71 36 173 140 282 62 64 109 94 135 86 11 -4
58 -29 105 -57z m2128 21 c99 -92 203 -258 203 -324 0 -65 -28 -69 -594 -84
-203 -6 -370 -9 -372 -7 -7 7 351 242 426 279 41 20 93 56 115 79 50 52 122
99 152 99 14 0 43 -18 70 -42z m-1558 -597 c-50 -39 -469 -311 -569 -369 -58
-34 -120 -65 -139 -68 -30 -6 -37 -2 -100 62 -85 88 -153 213 -155 287 -2 78
15 85 204 96 85 4 296 9 469 10 308 1 313 1 290 -18z m1578 9 c175 -11 213
-19 213 -48 0 -46 -131 -253 -215 -339 -73 -75 -99 -72 -264 32 -47 30 -106
61 -130 70 -28 9 -70 40 -116 84 -60 57 -123 103 -271 198 -24 15 544 18 783
3z m-1327 -491 l0 -342 -101 7 c-56 4 -152 20 -213 35 -123 31 -319 105 -337
128 -19 22 19 55 181 161 163 107 340 237 415 305 28 26 51 46 53 47 1 0 2
-154 2 -341z m435 215 c181 -121 349 -227 424 -266 69 -36 91 -56 91 -80 0
-59 -370 -198 -536 -200 -52 -1 -69 2 -84 18 -26 26 -37 115 -45 364 -3 113
-8 220 -11 239 -3 18 -2 32 3 30 4 -2 76 -49 158 -105z"
        />
        <path
          d="M5102 12665 c-108 -20 -118 -29 -128 -117 -10 -87 -9 -871 1 -1010
l7 -98 354 0 354 0 -2 602 -3 603 -90 17 c-110 21 -384 23 -493 3z m270 -232
c29 -25 38 -106 38 -338 0 -226 -11 -354 -35 -412 -16 -37 -55 -66 -78 -57
-36 14 -42 68 -42 404 0 332 4 388 31 415 18 17 59 11 86 -12z"
        />
        <path
          d="M4717 7200 c-112 -11 -165 -34 -184 -78 -24 -57 -32 -336 -23 -743 5
-206 9 -389 10 -406 l0 -33 354 0 354 0 7 368 c9 436 3 740 -17 796 -17 50
-49 73 -117 86 -76 14 -291 20 -384 10z m203 -258 c19 -16 23 -33 32 -142 10
-132 3 -534 -11 -608 -10 -53 -28 -73 -58 -66 -60 16 -82 119 -90 420 -7 279
-1 357 32 390 29 29 65 31 95 6z"
        />
        <path
          d="M10435 6493 c-276 -7 -476 -31 -607 -71 -277 -86 -652 -288 -797
-430 -42 -42 -62 -71 -75 -109 -12 -35 -28 -60 -51 -77 -44 -34 -87 -110 -124
-222 -41 -123 -52 -206 -40 -313 28 -251 140 -467 343 -659 224 -212 550 -357
983 -437 264 -49 497 -53 653 -10 77 21 149 33 266 44 168 17 408 107 642 242
202 116 305 205 324 278 7 27 29 63 58 97 52 60 99 167 127 294 25 113 22 331
-6 435 -45 167 -126 303 -265 440 -198 196 -440 321 -840 434 -98 28 -202 53
-230 56 -81 7 -253 11 -361 8z m-147 -510 c-5 -279 -12 -333 -42 -333 -8 0
-66 33 -128 73 -62 41 -194 124 -293 187 -99 62 -189 119 -199 126 -18 12 -11
18 80 60 161 75 314 121 524 157 68 12 63 37 58 -270z m597 238 c86 -21 215
-69 334 -123 l74 -34 -69 -56 c-90 -72 -600 -408 -619 -408 -7 0 -5 279 4 452
9 185 11 188 120 188 42 0 112 -9 156 -19z m-1451 -386 c84 -50 251 -173 324
-239 34 -31 72 -56 83 -56 22 0 101 -39 139 -69 54 -43 30 -46 -408 -46 -387
0 -409 1 -447 20 -46 23 -56 55 -31 103 43 82 244 322 270 322 7 0 38 -16 70
-35z m2179 -13 c40 -25 110 -123 162 -227 55 -110 63 -140 40 -149 -50 -19
-236 -27 -577 -24 l-377 3 244 163 c266 178 398 252 448 252 18 0 44 -8 60
-18z m-1628 -633 c13 -13 -20 -39 -205 -163 -164 -110 -366 -229 -419 -247
-27 -10 -35 -8 -53 7 -49 45 -228 338 -228 374 0 19 53 31 179 40 159 11 712
3 726 -11z m1799 -12 c37 -16 38 -18 33 -57 -10 -75 -204 -331 -262 -346 -18
-4 -80 33 -281 167 -143 95 -283 187 -313 205 -50 30 -52 33 -30 38 52 12 107
13 454 12 320 -1 364 -3 399 -19z m-886 -337 c288 -192 352 -241 352 -270 0
-32 -211 -104 -439 -150 -146 -29 -153 -28 -168 40 -12 56 -36 289 -49 478
l-6 104 21 -12 c12 -6 142 -91 289 -190z m-620 -11 c1 -101 -2 -224 -7 -274
-15 -134 -44 -160 -157 -143 -48 7 -482 133 -491 142 -7 7 148 137 220 183
159 102 182 119 195 145 8 17 24 29 45 33 44 10 76 28 127 70 23 20 48 34 54
32 7 -2 12 -64 14 -188z"
        />
      </g>
    </svg>
  );
};

// 2. 프론트펜더      3. 도어       6. 리어펜더
// 8. 라커패널       14. 필러
const RightSide = ({ data, ...props }: any) => {
  const classes = useStyles(styles);

  return (
    <>
      <div
        className={
          props.className !== undefined
            ? `${classes.container} ${props.className}`
            : `${classes.container}`
        }
      >
        <RightSideImage className={classes.image} />
        {data.inspection !== undefined &&
          isNotNull(data.inspection.front_fender_r) && (
            <StatusMark
              className={classes.front_fender_r}
              status={data.inspection.front_fender_r}
            />
          )}
        {data.inspection !== undefined &&
          isNotNull(data.inspection.door_f_r) && (
            <StatusMark
              className={classes.door_f_r}
              status={data.inspection.door_f_r}
            />
          )}
        {data.inspection !== undefined &&
          isNotNull(data.inspection.door_r_r) && (
            <StatusMark
              className={classes.door_r_r}
              status={data.inspection.door_r_r}
            />
          )}
        {data.inspection !== undefined &&
          isNotNull(data.inspection.rear_fender_r) && (
            <StatusMark
              className={classes.rear_fender_r}
              status={data.inspection.rear_fender_r}
            />
          )}
        {data.inspection !== undefined &&
          isNotNull(data.inspection.rocker_panel_r) && (
            <StatusMark
              className={classes.rocker_panel_r}
              status={data.inspection.rocker_panel_r}
            />
          )}
        {data.inspection !== undefined &&
          isNotNull(data.inspection.pillar_a_r) && (
            <StatusMark
              className={classes.pillar_a_r}
              status={data.inspection.pillar_a_r}
            />
          )}
        {data.inspection !== undefined &&
          isNotNull(data.inspection.pillar_b_r) && (
            <StatusMark
              className={classes.pillar_b_r}
              status={data.inspection.pillar_b_r}
            />
          )}
        {data.inspection !== undefined &&
          isNotNull(data.inspection.pillar_c_r) && (
            <StatusMark
              className={classes.pillar_c_r}
              status={data.inspection.pillar_c_r}
            />
          )}
      </div>
    </>
  );
};

export default RightSide;
