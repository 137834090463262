import { useNavigate } from "react-router-dom";
// 컴포넌트
import { Box, Paper, Theme, Typography } from "@mui/material";
import { Button, SNSIcon } from "@/common/components";
import { CompanyLogoIcon } from "@/common/icons";
import BrowserNotSupportedIcon from "@mui/icons-material/BrowserNotSupported";
// 스타일 관련
import { useStyles } from "@/common/lib/style/hooks";

const styles = (theme: Theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "90vw",
    padding: "2vh 4vw",
    borderRadius: "2vw",
    userSelect: "none",
  },
  topContainer: {
    width: "75vw",
    display: "flex",
    borderTop: "0.75vw solid " + theme.palette.logo.blue,
    borderBottom: "0.75vw solid " + theme.palette.logo.blue,
    margin: "0.5vh 0",
    padding: "1vh 0",
  },
  bottomContainer: {
    width: "75vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0.75vh 0",
  },
  logo: {
    width: "62.5%",
    fill: theme.palette.logo.blue,
    marginBottom: "0.5vh",
  },
  icon: {
    width: "15%",
    height: "auto",
    color: theme.palette.logo.blue,
  },
  content: {
    paddingLeft: "2vw",
  },
  title: {
    color: theme.palette.logo.blue,
    fontSize: "1rem",
    fontWeight: "bold",
    cursor: "default",
  },
  snsIcon: {
    width: "10vw !important",
    height: "10vw !important",
    minHeight: "10vw",
    "& > svg": {
      width: "7vw",
      height: "7vw",
    },
    "&.facebook": {
      margin: "0 2vw",
    },
  },
  button: {
    fontSize: "0.8rem",
    padding: "0.85vh 4vw",
  },
});

const NotFoundPage = () => {
  const classes = useStyles(styles);
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate("/");
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      minWidth="100vw"
      minHeight="100vh"
    >
      <Paper classes={{ root: classes.paper }} square={true}>
        <CompanyLogoIcon className={classes.logo} />
        <div className={classes.topContainer}>
          <BrowserNotSupportedIcon classes={{ root: classes.icon }} />
          <div className={classes.content}>
            <Typography className={classes.title}>404 NOT FOUND</Typography>
            <Typography variant="body2">
              The requested URL
              <br />
              was not found on this server.
            </Typography>
          </div>
        </div>
        <div className={classes.bottomContainer}>
          <div>
            <SNSIcon sns="whatsApp" className={classes.snsIcon} />
            <SNSIcon sns="facebook" className={`${classes.snsIcon} facebook`} />
            <SNSIcon sns="youtube" className={classes.snsIcon} />
          </div>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={handleOnClick}
          >
            &lt;&lt; Index page
          </Button>
        </div>
      </Paper>
    </Box>
  );
};

export default NotFoundPage;
