/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
// Redux
import { useTypedDispatch, useTypedSelector } from "@/common/lib/redux/store";
import { loadingOn, loadingOff } from "@/common/lib/redux/actions/commonAction";
// Components
import { Drawer } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Button } from "@/common/components";
import SearchPage from "@/mobile/pages/common/SearchPage";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// API
import { getVehicleCount } from "@/common/lib/api/vehicle";
// Libs
import { isObjectEmpty } from "@/common/lib/common";

const styles = () => ({
  button: {
    flexDirection: "column",
    width: "100%",
    fontSize: "1rem",
    "& > div": {
      display: "flex",
      alignItems: "center",
    },
    borderRadius: "0.25rem",
    marginBottom: "2vh",
  },
  searchPaper: {
    width: "100%",
    padding: "2vh 4vw",
  },
});

const SearchContainer = () => {
  const classes = useStyles(styles);
  const { t } = useTranslation();
  const { params } = useTypedSelector((state) => state.search);
  const dispatch = useTypedDispatch();

  const [searchOpen, setSearchOpen] = useState(false);
  const [countData, setCountData] = useState<CountData>({
    total: 0,
    type: {},
    status: {},
    maker: {},
    mission: {},
    fuel: {},
    color: {},
  });

  const vehicleCountQuery = useQuery(
    "vehicleCount",
    () => {
      const countParams = {
        ...params,
      };
      dispatch(loadingOn());
      return getVehicleCount(countParams);
    },
    {
      onSettled: () => {
        dispatch(loadingOff());
      },
    }
  );

  useEffect(() => {
    vehicleCountQuery.refetch();
  }, [params]);

  useEffect(() => {
    if (vehicleCountQuery.isSuccess) {
      setCountData({ ...vehicleCountQuery.data.data.count });
    }
  }, [vehicleCountQuery.isLoading, vehicleCountQuery.isRefetching]);

  return (
    <>
      <Button
        variant="contained"
        className={classes.button}
        onClick={() => setSearchOpen(true)}
      >
        <div>
          <SearchIcon />
          {isObjectEmpty(params) ? t("BUTTON.FIND_MY_CAR") : t("SEARCH.MODIFY")}
        </div>
        <span>{t("SEARCH.RESULT", { count: countData["total"] })}</span>
      </Button>
      <Drawer
        classes={{ paper: classes.searchPaper }}
        open={searchOpen}
        onClose={() => setSearchOpen(false)}
      >
        <SearchPage setOpen={setSearchOpen} />
      </Drawer>
    </>
  );
};

export default SearchContainer;
