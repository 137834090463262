/* eslint-disable react-hooks/exhaustive-deps */
// Components
import { Card, CardContent, Skeleton, Theme } from "@mui/material";
// Styles
import { useStyles } from "@/common/lib/style/hooks";

const styles = (theme: Theme) => ({
  card: {
    width: "25%",
    boxSizing: "border-box",
    border: "1px solid " + theme.palette.divider,
    borderRadius: "10px !important",
    boxShadow: "0 0 5px " + theme.palette.divider + " !important",
    position: "relative",
  },
  imageContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box",
    padding: "0 !important",
    marginBottom: 10,
  },
  image: {
    width: "100%",
    height: "unset",
    aspectRatio: "1.33",
  },
  cardContent: {
    height: 110,
    position: "relative",
    boxSizing: "border-box",
    padding: "0 15px 15px !important",
  },
  title: {
    width: 200,
    height: 20,
    marginBottom: 5,
  },
  subtitle: {
    width: 175,
    height: 15,
  },
  statusInfo: {
    position: "absolute",
    left: 15,
    bottom: 15,
    "& > .MuiSkeleton-root": {
      width: 82.5,
      height: 27.5,
      borderRadius: 5,
    },
  },
  priceInfo: {
    position: "absolute",
    right: 15,
    bottom: 15,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "center",
    "& > .MuiSkeleton-root": {
      width: 95,
      height: 25,
    },
  },
});

const CarouselListItemSkeleton = () => {
  const classes = useStyles(styles);

  return (
    <Card className={classes.card}>
      <CardContent className={classes.imageContent}>
        <Skeleton
          classes={{ root: classes.image }}
          animation="wave"
          variant="rectangular"
        />
      </CardContent>
      <CardContent className={classes.cardContent}>
        <Skeleton
          classes={{ root: classes.title }}
          animation="wave"
          variant="rectangular"
        />
        <Skeleton
          classes={{ root: classes.subtitle }}
          animation="wave"
          variant="rectangular"
        />
        <div className={classes.statusInfo}>
          <Skeleton animation="wave" variant="rectangular" />
        </div>
        <div className={classes.priceInfo}>
          <Skeleton animation="wave" variant="rectangular" />
        </div>
      </CardContent>
    </Card>
  );
};

export default CarouselListItemSkeleton;
