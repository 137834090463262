/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
// Components
import { Theme, Typography } from "@mui/material";
import RoomIcon from "@mui/icons-material/Room";
// Styles
import { useStyles } from "@/common/lib/style/hooks";

const styles = (theme: Theme) => ({
  title: {
    display: "flex",
    alignItems: "flex-end",
    background: theme.palette.primary.main,
    userSelect: "none",
    padding: "1vh 3vw",
    "& > svg": {
      fontSize: "1.85rem",
      color: "white",
      marginRight: "1vw",
    },
    "& > div": {
      display: "flex",
      alignItems: "center",
      "& > p": {
        fontSize: "1.15rem",
        fontWeight: "bold",
        color: "white",
      },
      "& > span": {
        fontSize: "1rem",
        fontWeight: "bold",
        color: "white",
        margin: "0 1vw",
      },
    },
  },
  content: {
    padding: "1vh 4vw 0",
    "& p.title": {
      fontSize: "1.1rem",
      fontWeight: "bold",
      marginBottom: "0.5vh",
    },
    "& p.content": {
      fontSize: "0.85rem",
      padding: "0 4vw",
    },
  },
});

const MapContainer = () => {
  const classes = useStyles(styles);
  const [, setMap] = useState(null);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API
      ? process.env.REACT_APP_GOOGLE_MAP_API
      : "",
  });

  const containerStyle = {
    width: "100%",
    aspectRatio: "1.25",
    marginBottom: "1vh",
  };

  const center = {
    lat: 37.4783809,
    lng: 126.6002286,
  };

  const onLoad = useCallback((map: any) => {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const options = {
    minZoom: 12,
    maxZoom: 17,
  };

  return (
    <div>
      <div className={classes.title}>
        <RoomIcon />
        <div>
          <Typography>오시는길</Typography>
          <span>|</span>
          <Typography>LOCATION</Typography>
        </div>
      </div>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          onLoad={onLoad}
          onUnmount={onUnmount}
          zoom={17}
          options={options}
        >
          <Marker position={center}></Marker>
        </GoogleMap>
      )}
      <div className={classes.content}>
        <Typography className="title">Address</Typography>
        <Typography className="content">
          1F - 107, 187, Gyeongin-ro
          <br />
          Michuhol-gu, Incheon, Republic of Korea
        </Typography>
      </div>
      <div className={classes.content}>
        <Typography className="title">Office Hours</Typography>
        <Typography className="content">
          Monday ~ Friday (except Holidays)
          <br />
          9:00 A.M. ~ 6:00 P.M. - Korea Local Time
        </Typography>
      </div>
      <div className={classes.content}>
        <Typography className="title">Contact</Typography>
        <Typography className="content">
          Tel : +82-32-565-5513
          <br />
          Fax : +82-32-565-5514
          <br />
          Email : autoleaderost@gmail.com
        </Typography>
      </div>
    </div>
  );
};

export default React.memo(MapContainer);
