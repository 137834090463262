const SteeringIcon = ({ ...props }: any) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <g transform="translate(0, 512) scale(0.1,-0.1)">
        <path
          d="M2365 5114 c-27 -2 -111 -13 -185 -24 -710 -105 -1348 -511 -1752
-1115 -202 -302 -340 -661 -400 -1040 -32 -201 -32 -549 0 -750 115 -729 511
-1352 1117 -1757 302 -202 661 -340 1040 -400 201 -32 549 -32 750 0 284 45
512 117 764 243 501 248 902 649 1150 1150 126 252 198 480 243 764 32 201 32
549 0 750 -115 729 -511 1352 -1117 1757 -299 200 -662 340 -1030 398 -127 20
-459 34 -580 24z m381 -645 c648 -64 1226 -462 1517 -1045 38 -77 97 -210 97
-221 0 -2 -810 -3 -1800 -3 -990 0 -1800 1 -1800 3 0 11 59 144 97 221 224
448 626 797 1102 956 261 87 517 116 787 89z m-54 -1621 c70 -32 124 -86 157
-157 54 -115 27 -264 -64 -356 -122 -121 -328 -121 -450 0 -78 78 -111 204
-82 306 57 194 259 289 439 207z m-1913 -298 c784 -81 1400 -838 1454 -1789
l5 -83 -48 6 c-71 9 -252 63 -350 103 -244 101 -425 220 -619 408 -330 319
-527 732 -571 1190 -15 159 -14 175 9 175 10 0 65 -5 120 -10z m3701 -22 c0
-18 -5 -82 -10 -143 -44 -458 -241 -871 -571 -1190 -194 -188 -375 -307 -619
-408 -98 -40 -279 -94 -350 -103 l-48 -6 5 83 c57 996 725 1768 1556 1798 35
1 37 0 37 -31z"
        />
      </g>
    </svg>
  );
};

export default SteeringIcon;
