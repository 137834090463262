import { useTranslation } from "react-i18next";
// Components
import { Theme, Typography } from "@mui/material";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// Libs
import { isNotNull } from "@/common/lib/common";

const styles = (theme: Theme) => ({
  container: {
    userSelect: "none",
    padding: "15px 25px",
    marginBottom: 40,
    display: "flex",
    alignItems: "flex-start",
    borderTop: "2px solid " + theme.palette.text.primary,
    borderBottom: "2px solid " + theme.palette.text.primary,
    minHeight: 175,
    whiteSpace: "pre",
  },
  title: {
    fontSize: "1.65rem",
    fontWeight: "bold",
    userSelect: "none",
    marginBottom: 15,
  },
});

interface propType {
  data: VehicleData;
}

const CommentContainer = ({ data }: propType) => {
  const classes = useStyles(styles);
  const { t } = useTranslation();

  return (
    <>
      {data !== undefined && isNotNull(data.sales.advert_explain) && (
        <>
          <Typography className={classes.title}>
            {t("DETAIL.COMMENT.TITLE")}
          </Typography>
          <div className={classes.container}>{data.sales.advert_explain}</div>
        </>
      )}
    </>
  );
};

export default CommentContainer;
