import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";

// Local Storage
import storage from "redux-persist/lib/storage";
// Session Storage
// import storageSession from "redux-persist/lib/storage/session";

import authReducer from "./authReducer";
import commonReducer from "./commonReducer";
import globalReducer from "./globalReducer";
import searchReducer from "./searchReducer";

const authPersistConfig = {
  key: "auth",
  storage: storage,
  blacklist: ["_persist"],
};

const globalPersistConfig = {
  key: "global",
  storage: storage,
  blacklist: ["_persist"],
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  common: commonReducer,
  global: persistReducer(globalPersistConfig, globalReducer),
  search: searchReducer,
});

export default rootReducer;
