/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
// Redux
import { setParams } from "@/common/lib/redux/actions/searchAction";
import { useTypedDispatch } from "@/common/lib/redux/store";
// Components
import {
  Card,
  CardContent,
  Divider,
  Drawer,
  Theme,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Button } from "@/common/components";
import SearchPage from "@/mobile/pages/common/SearchPage";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// API
import { getVehicleCount } from "@/common/lib/api/vehicle";
// Libs
import { getUTCDate } from "@/common/lib/common";

const styles = (theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "1vh 4vw 2.5vh",
    boxSizing: "border-box",
  },
  title: {
    fontSize: "0.85rem",
    fontStyle: "oblique",
    textAlign: "end",
    cursor: "default",
    "& > span > span": {
      fontSize: "1.35rem",
      fontWeight: "bold",
      color: theme.palette.primary.main,
      textShadow: "1px 1px 0px #000",
    },
  },
  topDiv: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    cursor: "default",
    marginBottom: "1vh",
    "& > p": {
      lineHeight: 1,
    },
  },
  top: {
    paddingBottom: "0.75vh !important",
  },
  bottom: {
    paddingTop: "0.75vh !important",
  },
  card: {
    border: "4.5px solid " + theme.palette.primary.main,
    borderRadius: "1.5vw",
    boxShadow: "unset",
    padding: "0.5vh 2vw",
  },
  cardContent: {
    display: "flex",
    alignItems: "center",
    padding: "0px !important",
  },
  find: {
    color: theme.palette.primary.main,
    fontSize: "1.5rem",
    fontWeight: "bold",
    marginRight: "1vw",
  },
  mycar: {
    fontSize: "1rem",
    fontWeight: "bold",
  },
  in: {
    margin: "0 0.7vw",
    fontSize: "0.7rem",
    fontWeight: "bold",
  },
  autoleader: {
    color: theme.palette.primary.main,
    fontSize: "1.2rem",
    fontWeight: "bold",
  },
  table: {
    width: "100%",
    "& td": {
      fontSize: "0.85rem",
      textAlign: "right",
      cursor: "default",
    },
    "& td.count": {
      color: theme.palette.primary.main,
      fontSize: "1rem",
      fontWeight: "bold",
    },
  },
  button: {
    width: "100%",
    borderRadius: "1vw",
    fontSize: "0.85rem",
  },
  searchIcon: {
    fontSize: "1.25rem",
  },
  searchPaper: {
    width: "100%",
    padding: "2vh 4vw",
  },
});

const IndexSearchContainer = () => {
  const classes = useStyles(styles);
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();

  const [totalCount, setTotalCount] = useState(0);
  const [weekCount, setWeekCount] = useState(0);
  const [searchOpen, setSearchOpen] = useState(false);

  const totalCountQuery = useQuery("totalCount", () => {
    const startDate = new Date(1980, 1, 1);

    return getVehicleCount({
      criteria: JSON.stringify([
        {
          type: "SALES_ON",
          period: getUTCDate(startDate) + "~",
        },
      ]),
    });
  });
  const weekCountQuery = useQuery("weekCount", () => {
    const startDate = new Date(1980, 1, 1);
    startDate.setDate(startDate.getDate() - 7);

    return getVehicleCount({
      criteria: JSON.stringify([
        {
          type: "SALES_ON",
          period: getUTCDate(startDate) + "~",
        },
      ]),
    });
  });

  const handleSearchOnClick = () => {
    dispatch(setParams({}));
    setSearchOpen(true);
  };

  useEffect(() => {
    if (totalCountQuery.isSuccess) {
      setTotalCount(totalCountQuery.data.data.count["total"]);
    }
  }, [totalCountQuery.isLoading]);

  useEffect(() => {
    if (weekCountQuery.isSuccess) {
      setWeekCount(weekCountQuery.data.data.count["total"]);
    }
  }, [weekCountQuery.isLoading]);

  return (
    <>
      <div className={classes.container}>
        <Typography className={classes.title}>
          <Markup content={t("SEARCH.MSG.WELCOME", { count: totalCount })} />
        </Typography>
        <div className={classes.topDiv}>
          <Typography className={classes.find}>
            {t("SEARCH.INDEX_SEARCH_FIND")}
          </Typography>
          <Typography className={classes.mycar}>
            {t("SEARCH.INDEX_SEARCH_MYCAR")}
          </Typography>
          <Typography className={classes.in}>
            {t("SEARCH.INDEX_SEARCH_IN")}
          </Typography>
          <Typography className={classes.autoleader}>AUTOLEADER</Typography>
        </div>
        <Card className={classes.card}>
          <CardContent className={`${classes.cardContent} ${classes.top}`}>
            <table className={classes.table}>
              <colgroup>
                <col style={{ width: "35%" }} />
                <col style={{ width: "50%" }} />
                <col style={{ width: "15%" }} />
              </colgroup>
              <tbody>
                <tr>
                  <td>{t("SEARCH.INDEX_SEARCH_TOTAL")}</td>
                  <td className="count">{totalCount.toLocaleString()}</td>
                  <td className="ea">{t("SEARCH.INDEX_SEARCH_EA")}</td>
                </tr>
                <tr>
                  <td>
                    <Markup content={t("SEARCH.INDEX_SEARCH_RECENT")} />
                  </td>
                  <td className="count">{weekCount.toLocaleString()}</td>
                  <td className="ea">{t("SEARCH.INDEX_SEARCH_EA")}</td>
                </tr>
              </tbody>
            </table>
          </CardContent>
          <Divider />
          <CardContent className={`${classes.cardContent} ${classes.bottom}`}>
            <Button
              variant="contained"
              className={classes.button}
              onClick={handleSearchOnClick}
            >
              <SearchIcon className={classes.searchIcon} />
              {t("BUTTON.FIND_MY_CAR")}
            </Button>
          </CardContent>
        </Card>
      </div>
      <Drawer
        classes={{ paper: classes.searchPaper }}
        open={searchOpen}
        onClose={() => setSearchOpen(false)}
      >
        <SearchPage setOpen={setSearchOpen} />
      </Drawer>
    </>
  );
};

export default IndexSearchContainer;
