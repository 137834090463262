import { useEffect, useState } from "react";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// Libs
import { Theme, Typography } from "@mui/material";

const styles = (theme: Theme) => ({
  typography: {
    textAlign: "center",
    fontSize: "2.25rem",
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
});

const CountUp = ({ start = 0, end = 1 }) => {
  const classes = useStyles(styles);
  const [num, setNum] = useState(start);

  useEffect(() => {
    const countup = setInterval(() => {
      if (num < end) {
        setNum((num: number) => {
          return num + Math.ceil((end - num) / 10);
        });
      }
    }, 25);
    return () => clearInterval(countup);
  });

  return (
    <Typography className={classes.typography}>
      {num.toLocaleString()}
    </Typography>
  );
};

export default CountUp;
