const ExhuastIcon = ({ ...props }: any) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <g transform="translate(0, 512) scale(0.1,-0.1)">
        <path
          d="M1825 4054 c-297 -45 -515 -153 -711 -348 -211 -211 -326 -463 -352
-770 l-7 -88 -75 -19 c-83 -21 -215 -81 -286 -130 -84 -58 -185 -162 -245
-253 -231 -351 -189 -798 105 -1104 114 -120 269 -210 436 -254 l85 -23 1785
0 1785 0 85 23 c221 58 413 194 539 381 159 234 193 544 91 810 -59 152 -197
326 -334 420 -72 50 -203 109 -290 131 -44 11 -81 22 -83 23 -1 2 -12 39 -23
83 -79 311 -327 559 -645 645 -65 18 -107 22 -225 22 -168 0 -245 -16 -387
-83 -47 -22 -87 -39 -88 -38 -1 2 -23 34 -50 72 -172 246 -435 418 -735 482
-73 15 -315 27 -375 18z"
        />
      </g>
    </svg>
  );
};

export default ExhuastIcon;
