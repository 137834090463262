export const colors = {
  gray: [
    "#f8f9fa",
    "#f1f3f5",
    "#e9ecef",
    "#dee2e6",
    "#ced4da",
    "#adb5bd",
    "#868e96",
    "#495057",
    "#343a40",
    "#212529",
  ],
  red: [
    "#ffe5e5",
    "#ffd1d1",
    "#ffa3a3",
    "#fc7373",
    "#f75050",
    "#f03333",
    "#e72323",
    "#d41c1c",
    "#c21919",
    "#aa1818",
    "#931515",
    "#7b1414",
    "#691111",
  ],
  orange: [
    "#ffeadb",
    "#ffd8bd",
    "#ffc499",
    "#ffa666",
    "#ff8e3d",
    "#fb7718",
    "#fb6b04",
    "#f06705",
    "#eb6505",
    "#e16005",
    "#db5e06",
    "#d15a05",
    "#cc5805",
  ],
  yellow: [
    "#ffffdb",
    "#ffffbd",
    "#ffff99",
    "#ffff66",
    "#ffff3d",
    "#fbfb18",
    "#fbfb04",
    "#f0f005",
    "#ebeb05",
    "#e1e105",
    "#dbdb06",
    "#d1d105",
    "#cccc05",
  ],
  green: [
    "#e5ffea",
    "#d1ffda",
    "#a3ffb4",
    "#73fc8c",
    "#50f76e",
    "#33f056",
    "#23e747",
    "#1cd43d",
    "#19c238",
    "#18aa33",
    "#15932c",
    "#147b27",
    "#116921",
  ],
  teal: [
    "#e8fcf6",
    "#c6faeb",
    "#97f2d7",
    "#65e7c0",
    "#43dbad",
    "#20cf9b",
    "#14bd8a",
    "#0eaa7b",
    "#0b936a",
    "#0a805d",
    "#087252",
    "#066549",
    "#04583f",
  ],
  cyan: [
    "#e8fafc",
    "#c6f3fa",
    "#97e6f2",
    "#65d6e7",
    "#43c7db",
    "#20b8cf",
    "#14a7bd",
    "#0e95aa",
    "#0b8193",
    "#0a7080",
    "#086472",
    "#065965",
    "#044d58",
  ],
  blue: [
    "#e5f3ff",
    "#d1eaff",
    "#a3d4ff",
    "#73bcfc",
    "#50a9f7",
    "#3398f0",
    "#238be7",
    "#1c7ed4",
    "#1973c2",
    "#1866aa",
    "#155893",
    "#144b7b",
    "#114069",
  ],
  violet: [
    "#f4f0ff",
    "#e4dbff",
    "#cdbdff",
    "#b097fc",
    "#9675fa",
    "#855ef7",
    "#7850f2",
    "#7149e9",
    "#663fd9",
    "#5e3cc3",
    "#583da9",
    "#503a92",
    "#443375",
  ],
  grape: [
    "#fcf0ff",
    "#f8dbff",
    "#f2bdff",
    "#e897fc",
    "#df75fa",
    "#d95ef7",
    "#d150f2",
    "#c949e9",
    "#ba3fd9",
    "#a83cc3",
    "#933da9",
    "#803a92",
    "#683375",
  ],
};

export const darkColors = {
  gray: [
    "#f8f9fa",
    "#f1f3f5",
    "#e9ecef",
    "#dee2e6",
    "#ced4da",
    "#adb5bd",
    "#868e96",
    "#495057",
    "#343a40",
    "#212529",
  ],
  red: [
    "#4d0000",
    "#610000",
    "#8f0000",
    "#be0404",
    "#e00b0b",
    "#ee2020",
    "#ea3e3e",
    "#e95858",
    "#ec6a6a",
    "#ed8282",
    "#f09898",
    "#f2b0b0",
    "#f5c2c2",
  ],
  orange: [
    "#572400",
    "#753100",
    "#994000",
    "#cc5500",
    "#f56600",
    "#fc7d22",
    "#fc8936",
    "#fb8f41",
    "#fb9246",
    "#fb9750",
    "#fb9b56",
    "#fba060",
    "#fba365",
  ],
  yellow: [
    "#575700",
    "#757500",
    "#999900",
    "#cccc00",
    "#f5f500",
    "#fcfc22",
    "#fcfc36",
    "#fbfb41",
    "#fbfb46",
    "#fbfb50",
    "#fbfb56",
    "#fbfb60",
    "#fbfb65",
  ],
  green: [
    "#004d0e",
    "#006112",
    "#008f1a",
    "#04be26",
    "#0be032",
    "#20ee46",
    "#3eea5e",
    "#58e973",
    "#6aec82",
    "#82ed96",
    "#98f0a8",
    "#b0f2bc",
    "#c2f5cb",
  ],
  teal: [
    "#084532",
    "#08684b",
    "#13966e",
    "#1fc794",
    "#3ad9aa",
    "#5ce6bc",
    "#70f0ca",
    "#84f5d3",
    "#9bf8dc",
    "#aef9e3",
    "#bcfae8",
    "#cafced",
    "#d8fdf2",
  ],
  cyan: [
    "#083d45",
    "#085b68",
    "#138496",
    "#1fb0c7",
    "#3ac4d9",
    "#5cd3e6",
    "#70dff0",
    "#84e6f5",
    "#9bebf8",
    "#aeeff9",
    "#bcf2fa",
    "#caf5fc",
    "#d8f8fd",
  ],
  blue: [
    "#00294d",
    "#003461",
    "#004c8f",
    "#0467be",
    "#0b7ce0",
    "#208eee",
    "#3e9aea",
    "#58a5e9",
    "#6aafec",
    "#82bbed",
    "#98c7f0",
    "#b0d3f2",
    "#c2ddf5",
  ],
  violet: [
    "#110042",
    "#160057",
    "#1d0075",
    "#2a0599",
    "#3407bb",
    "#3c0ad1",
    "#4411df",
    "#4e1ce3",
    "#663fd9",
    "#7e63cf",
    "#917cd0",
    "#a292d3",
    "#b9addb",
  ],
  grape: [
    "#350042",
    "#450057",
    "#5e0075",
    "#7c0599",
    "#9707bb",
    "#aa0ad1",
    "#b611df",
    "#bb1ce3",
    "#ba3fd9",
    "#b963cf",
    "#bf7cd0",
    "#c692d3",
    "#d2addb",
  ],
};
