import { AnyAction } from "redux";

import { SET_LOGIN_TOKEN } from "../types/authType";

interface stateType {
  loginToken: string;
}

const initialState: stateType = {
  loginToken: "",
};

const authReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_LOGIN_TOKEN:
      return {
        ...state,
        loginToken: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
