const WindowIcon = ({ ...props }: any) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <g transform="translate(0, 512) scale(0.1,-0.1)">
        <path
          d="M883 4115 c-130 -35 -236 -121 -291 -235 -42 -89 -581 -1982 -589
-2070 -11 -128 38 -259 131 -352 93 -93 148 -114 581 -222 1249 -311 2281
-325 3505 -45 376 86 615 156 682 198 144 91 227 253 215 416 -7 90 -555 2015
-595 2090 -57 106 -164 188 -289 220 -84 22 -3269 21 -3350 0z m1527 -435 l0
-150 -136 0 c-120 0 -141 -2 -174 -21 -44 -25 -80 -83 -80 -129 0 -46 36 -104
80 -129 37 -20 48 -21 459 -21 478 0 475 0 519 78 28 50 28 94 0 144 -37 66
-74 78 -233 78 l-135 0 0 150 0 150 720 0 c586 0 727 -3 753 -14 73 -30 65 -7
361 -1047 152 -536 276 -984 276 -997 0 -37 -25 -86 -58 -113 -54 -46 -589
-181 -1017 -258 -135 -24 -454 -71 -483 -71 -4 0 154 161 350 358 196 196 362
370 367 385 28 73 -5 157 -72 188 -51 23 -77 24 -125 4 -25 -11 -188 -167
-519 -497 l-481 -481 -304 6 c-166 3 -377 13 -468 21 -177 17 -396 44 -419 52
-10 3 119 139 367 387 209 210 386 395 391 410 48 127 -69 244 -196 196 -15
-5 -235 -217 -488 -469 l-460 -459 -160 35 c-371 83 -657 163 -692 196 -29 26
-53 76 -53 110 0 13 124 461 276 997 295 1035 288 1016 359 1047 24 11 174 13
753 14 l722 0 0 -150z"
        />
      </g>
    </svg>
  );
};

export default WindowIcon;
