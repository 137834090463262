import axios from "axios";
// Redux
import { store } from "@/common/lib/redux/store";
// Libs
import { isNotNull } from "@/common/lib/common";

export const getVehicleCount = async (params: { [key: string]: any } = {}) => {
  params = {
    ...params,
    search_type: "COUNT",
  };
  // status : WAIT ON REZ DONE NOT
  if (Object.keys(params).includes("status")) {
    if (params["status"] === "ALL") {
      delete params["status"];
    }
  } else {
    params = {
      ...params,
      status: "ON,REZ",
    };
  }

  const state = store.getState();
  const headers: { [key: string]: string } = {
    "Content-Type": "application/json",
  };
  if (isNotNull(state.auth.loginToken)) {
    headers["Authorization"] = `Bearer ${state.auth.loginToken}`;
  }

  const url = "/api/vehicles/";
  const result = await axios({
    method: "get",
    url: url,
    params: params,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
    headers: headers,
    withCredentials: true,
  });
  return result;
};

export const getVehicleList = async (
  page: number = 1,
  size: number = 10,
  align: string = "-modified",
  params: { [key: string]: any } = {}
) => {
  params = {
    ...params,
    search_type: "LIST",
    page: String(page),
    size: String(size),
    align: align,
  };
  // status : WAIT ON REZ DONE NOT
  if (Object.keys(params).includes("status")) {
    if (params["status"] === "ALL") {
      delete params["status"];
    }
  } else {
    params = {
      ...params,
      status: "ON,REZ",
    };
  }

  const state = store.getState();
  const headers: { [key: string]: string } = {
    "Content-Type": "application/json",
  };
  if (isNotNull(state.auth.loginToken)) {
    headers["Authorization"] = `Bearer ${state.auth.loginToken}`;
  }

  const url = "/api/vehicles/";
  const result = await axios({
    method: "get",
    url: url,
    params: params,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
    headers: headers,
    withCredentials: true,
  });
  return result;
};

export const getVehicleInfo = async (uid: string) => {
  const state = store.getState();
  const headers: { [key: string]: string } = {
    "Content-Type": "application/json",
  };
  if (isNotNull(state.auth.loginToken)) {
    headers["Authorization"] = `Bearer ${state.auth.loginToken}`;
  }

  const url = `/api/vehicles/${uid}/`;
  const result = await axios({
    method: "get",
    url: url,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
    headers: headers,
    withCredentials: true,
  });
  return result;
};
