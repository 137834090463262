import axios from "axios";
// Redux
import { store } from "@/common/lib/redux/store";

export const loginAPI = async (data: {
  type: string;
  username: string;
  password: string;
}) => {
  const url = "/auth/login";
  const result = await axios({
    method: "post",
    url: url,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  });
  return result;
};

export const logoutAPI = async () => {
  const url = "/auth/logout";
  const result = await axios({
    method: "post",
    url: url,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  });
  return result;
};

export const refreshTokenAPI = async () => {
  const url = "/auth/token/refresh";
  const result = await axios({
    method: "post",
    url: url,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  });
  return result;
};

export const verifyTokenAPI = async () => {
  const state = store.getState();
  const headers: { [key: string]: string } = {
    "Content-Type": "application/json",
  };
  if (state.auth.loginToken !== undefined) {
    headers["Authorization"] = `Bearer ${state.auth.loginToken}`;
  }

  const url = "/auth/token/verify";
  const result = await axios({
    method: "post",
    url: url,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
    headers: headers,
    withCredentials: true,
  });
  return result;
};
