import { colors, darkColors } from "@/common/lib/style/palette";

const ColorPage = () => {
  return (
    <div>
      <div style={{ display: "flex" }}>
        {colors.gray.map((color: String) => {
          return (
            <div
              style={{ width: 50, height: 50, backgroundColor: `${color}` }}
            />
          );
        })}
      </div>
      <div style={{ display: "flex" }}>
        {colors.red.map((color: String) => {
          return (
            <div
              style={{ width: 50, height: 50, backgroundColor: `${color}` }}
            />
          );
        })}
      </div>
      <div style={{ display: "flex" }}>
        {colors.orange.map((color: String) => {
          return (
            <div
              style={{ width: 50, height: 50, backgroundColor: `${color}` }}
            />
          );
        })}
      </div>
      <div style={{ display: "flex" }}>
        {colors.yellow.map((color: String) => {
          return (
            <div
              style={{ width: 50, height: 50, backgroundColor: `${color}` }}
            />
          );
        })}
      </div>
      <div style={{ display: "flex" }}>
        {colors.green.map((color: String) => {
          return (
            <div
              style={{ width: 50, height: 50, backgroundColor: `${color}` }}
            />
          );
        })}
      </div>
      <div style={{ display: "flex" }}>
        {colors.teal.map((color: String) => {
          return (
            <div
              style={{ width: 50, height: 50, backgroundColor: `${color}` }}
            />
          );
        })}
      </div>
      <div style={{ display: "flex" }}>
        {colors.cyan.map((color: String) => {
          return (
            <div
              style={{ width: 50, height: 50, backgroundColor: `${color}` }}
            />
          );
        })}
      </div>
      <div style={{ display: "flex" }}>
        {colors.blue.map((color: String) => {
          return (
            <div
              style={{ width: 50, height: 50, backgroundColor: `${color}` }}
            />
          );
        })}
      </div>
      <div style={{ display: "flex" }}>
        {colors.violet.map((color: String) => {
          return (
            <div
              style={{ width: 50, height: 50, backgroundColor: `${color}` }}
            />
          );
        })}
      </div>
      <div style={{ display: "flex" }}>
        {colors.grape.map((color: String) => {
          return (
            <div
              style={{ width: 50, height: 50, backgroundColor: `${color}` }}
            />
          );
        })}
      </div>
      <div style={{ display: "flex" }}>
        {darkColors.red.map((color: String) => {
          return (
            <div
              style={{ width: 50, height: 50, backgroundColor: `${color}` }}
            />
          );
        })}
      </div>
      <div style={{ display: "flex" }}>
        {darkColors.orange.map((color: String) => {
          return (
            <div
              style={{ width: 50, height: 50, backgroundColor: `${color}` }}
            />
          );
        })}
      </div>
      <div style={{ display: "flex" }}>
        {darkColors.yellow.map((color: String) => {
          return (
            <div
              style={{ width: 50, height: 50, backgroundColor: `${color}` }}
            />
          );
        })}
      </div>
      <div style={{ display: "flex" }}>
        {darkColors.green.map((color: String) => {
          return (
            <div
              style={{ width: 50, height: 50, backgroundColor: `${color}` }}
            />
          );
        })}
      </div>
      <div style={{ display: "flex" }}>
        {darkColors.teal.map((color: String) => {
          return (
            <div
              style={{ width: 50, height: 50, backgroundColor: `${color}` }}
            />
          );
        })}
      </div>
      <div style={{ display: "flex" }}>
        {darkColors.cyan.map((color: String) => {
          return (
            <div
              style={{ width: 50, height: 50, backgroundColor: `${color}` }}
            />
          );
        })}
      </div>
      <div style={{ display: "flex" }}>
        {darkColors.blue.map((color: String) => {
          return (
            <div
              style={{ width: 50, height: 50, backgroundColor: `${color}` }}
            />
          );
        })}
      </div>
      <div style={{ display: "flex" }}>
        {darkColors.violet.map((color: String) => {
          return (
            <div
              style={{ width: 50, height: 50, backgroundColor: `${color}` }}
            />
          );
        })}
      </div>
      <div style={{ display: "flex" }}>
        {darkColors.grape.map((color: String) => {
          return (
            <div
              style={{ width: 50, height: 50, backgroundColor: `${color}` }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ColorPage;
