import { AnyAction } from "redux";

import {
  INIT_ON,
  INIT_OFF,
  SET_ACCESS_URL,
  SET_ERROR_TEXT,
  SET_CONFIRM_TEXT,
  DARK_MODE_ON,
  DARK_MODE_OFF,
  LOADING_ON,
  LOADING_OFF,
} from "../types/commonType";

interface stateType {
  init: boolean;
  accessURL: string;
  darkMode: boolean;
  errorTitle: string;
  errorContent: string;
  errorOnClose: Function;
  confirmTitle: string;
  confirmContent: string;
  confirmOnApply: Function;
  confirmOnClose: Function;
  loading: boolean;
}

const initialState: stateType = {
  init: false,
  accessURL: "",
  darkMode: false,
  errorTitle: "",
  errorContent: "",
  errorOnClose: () => {},
  confirmTitle: "",
  confirmContent: "",
  confirmOnApply: () => {},
  confirmOnClose: () => {},
  loading: false,
};

const commonReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case INIT_ON:
      return {
        ...state,
        init: true,
      };
    case INIT_OFF:
      return {
        ...state,
        init: false,
      };
    case SET_ACCESS_URL:
      return {
        ...state,
        accessURL: action.payload,
      };
    case SET_ERROR_TEXT:
      return {
        ...state,
        errorTitle: action.payload["title"],
        errorContent: action.payload["content"],
        errorOnClose: action.payload["onClose"],
      };
    case SET_CONFIRM_TEXT:
      return {
        ...state,
        confirmTitle: action.payload["title"],
        confirmContent: action.payload["content"],
        confirmOnApply: action.payload["onApply"],
        confirmOnClose: action.payload["onClose"],
      };
    case DARK_MODE_ON:
      return {
        ...state,
        darkMode: true,
      };
    case DARK_MODE_OFF:
      return {
        ...state,
        darkMode: false,
      };
    case LOADING_ON:
      return {
        ...state,
        loading: true,
      };
    case LOADING_OFF:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default commonReducer;
