/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

const Image = ({ src, alt, ...props }: any) => {
  const [objectURL, setObjectURL] = useState("");

  useEffect(() => {
    if (src instanceof File) {
      setObjectURL(URL.createObjectURL(src));
    } else {
      setObjectURL(src);
    }

    return () => {
      if (src instanceof File) {
        URL.revokeObjectURL(objectURL);
      }
    };
  }, [src]);

  return <img alt={alt} src={objectURL} {...props} />;
};

export default Image;
