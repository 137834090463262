// Components
import { Radio as MuiRadio, FormControlLabel, Theme } from "@mui/material";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// Libs
import { isNull, isNotNull } from "@/common/lib/common";

const styles = (theme: Theme) => ({
  formControlLabel: {
    margin: 0,
    "&:hover:not(.Mui-disabled)": {
      "& > .MuiRadio-root > span > svg": {
        color: theme.palette.primary.main,
      },
      "& > .MuiTypography-root": {
        color: theme.palette.primary.main,
      },
    },
    "&& .Mui-checked:not(.Mui-disabled)": {
      "& + .MuiTypography-root": {
        color: theme.palette.primary.main,
      },
    },
  },
  radio: {
    color: theme.palette.text.primary,
    "&.Mui-checked": {
      color: theme.palette.primary.main + "!important",
      "& ~ span": {
        color: theme.palette.primary.main + "!important",
      },
    },
  },
});

const Radio = ({
  label,
  formControlClassName,
  radioClassName,
  ...props
}: any) => {
  const classes = useStyles(styles);

  return isNotNull(label) ? (
    <FormControlLabel
      className={
        isNull(formControlClassName)
          ? classes.formControlLabel
          : `${classes.formControlLabel} ${formControlClassName}`
      }
      label={label}
      control={
        <MuiRadio
          {...props}
          className={
            isNull(radioClassName)
              ? classes.radio
              : `${classes.radio} ${radioClassName}`
          }
        />
      }
    />
  ) : (
    <MuiRadio
      {...props}
      className={
        isNull(radioClassName)
          ? classes.radio
          : `${classes.radio} ${radioClassName}`
      }
    />
  );
};

export default Radio;
