/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// Redux
import { useTypedSelector } from "@/common/lib/redux/store";
// Components
import { Divider, Theme, Typography } from "@mui/material";
import { ProductCarousel } from "@/mobile/components/index";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// Libs
import { usePrevious } from "@/common/lib/hooks";

const styles = (theme: Theme) => ({
  subTitle: {
    fontSize: "1rem",
    fontWeight: 500,
    fontStyle: "Italic",
    color: theme.palette.primary.main,
    lineHeight: "1.25",
    letterSpacing: "0.035rem",
    margin: "0 -5vw",
    padding: "1vh 7vw 0",
  },
  title: {
    fontSize: "1.45rem",
    fontWeight: 500,
    color: theme.palette.primary.main,
    lineHeight: "1.25",
    margin: "0 -5vw",
    padding: "0 7vw 1vh",
  },
  container: {
    margin: "0 -5vw",
    padding: "1vh 0 0",
  },
});

const RecommendListContainer = () => {
  const classes = useStyles(styles);
  const { t } = useTranslation();
  const { params } = useTypedSelector((state) => state.search);
  const prevParams = usePrevious(params);
  const [recommendData, setRecommendData] = useState<CarouselDataType[]>([
    {
      key: "recommendData",
      page: 1,
      size: 12,
      align: "-modified",
      params: {
        ...params,
        status: "ON,REZ",
        owner: "1",
      },
    },
  ]);
  const [recommendData2, setRecommendData2] = useState<CarouselDataType[]>([
    {
      key: "recommendData2",
      page: 2,
      size: 12,
      align: "-modified",
      params: {
        ...params,
        status: "ON,REZ",
        owner: "1",
      },
    },
  ]);
  const [data, setData] = useState<VehicleData[]>([]);
  const [data2, setData2] = useState<VehicleData[]>([]);

  useEffect(() => {
    if (
      prevParams !== undefined &&
      JSON.stringify(params) !== JSON.stringify(prevParams)
    ) {
      setRecommendData([
        {
          key: "recommendData",
          page: 1,
          size: 12,
          align: "-modified",
          params: {
            ...params,
            status: "ON,REZ",
            owner: "1",
          },
        },
      ]);
      setRecommendData2([
        {
          key: "recommendData2",
          page: 2,
          size: 12,
          align: "-modified",
          params: {
            ...params,
            status: "ON,REZ",
            owner: "1",
          },
        },
      ]);
    }
  }, [params]);

  return (
    <div style={data.length > 0 || data2.length > 0 ? {} : { display: "none" }}>
      <Divider />
      <Typography className={classes.subTitle}>
        {t("LIST.RECOMMEND.AUTOLEADER'S")}
      </Typography>
      <Typography className={classes.title}>
        {t("LIST.RECOMMEND.TITLE")}
      </Typography>
      <Divider />
      <div className={classes.container}>
        <ProductCarousel rows={1} data={recommendData} setData={setData} />
        <ProductCarousel rows={1} data={recommendData2} setData={setData2} />
      </div>
      <Divider sx={{ marginBottom: "2vh" }} />
    </div>
  );
};

export default RecommendListContainer;
