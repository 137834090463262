/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
// Redux
import { useTypedDispatch } from "@/common/lib/redux/store";
import { loadingOn, loadingOff } from "@/common/lib/redux/actions/commonAction";
// Components
import { Button } from "@/common/components";
import OfferListTable from "./OfferListTable";
import {
  ClickAwayListener,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grow,
  Paper,
  Popper,
  Theme,
  Typography,
  IconButton,
} from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import DatePicker from "react-datepicker";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
import "react-datepicker/dist/react-datepicker.css";
// API
import { onError } from "@/common/lib/api/common";
import { getOfferList, patchOfferInfo } from "@/common/lib/api/offer";

const styles = (theme: Theme) => ({
  topDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "0.5vh",
    userSelect: "none",
    "& > button": {
      padding: 0,
      margin: "0 2vw",
      color: theme.palette.text.primary + "!important",
      "& > svg": {
        fontSize: "2.35rem",
      },
      "&:hover": {
        color: theme.palette.primary.main + "!important",
      },
    },
    "& > .all": {
      fontSize: "1.5rem",
      fontWeight: "bold",
    },
    "& > .date": {
      cursor: "pointer",
      fontSize: "1.5rem",
      fontWeight: "bold",
      "&:hover": {
        color: theme.palette.primary.main,
      },
      "& > span": {
        fontSize: "1.35rem",
        fontWeight: "bold",
        marginRight: 5,
      },
    },
  },
  searchDiv: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    marginBottom: "1.5vh",
    "& > div": {
      minWidth: 185,
      width: "50vw",
    },
    "& > p": {
      lineHeight: "1",
      textDecoration: "underline",
      cursor: "pointer",
      userSelect: "none",
      fontSize: "0.925rem",
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
  },
  titleDiv: {
    "& > p": {
      fontSize: "0.95rem",
      fontWeight: "bold",
      lineHeight: "1.5",
      wordBreak: "keep-all",
    },
    "& > div": {
      display: "flex",
      "& > p": {
        fontSize: "0.95rem",
        fontWeight: "bold",
        lineHeight: "1.5",
        wordBreak: "keep-all",
      },
      "& > p:not(:first-child)": {
        "&::before": {
          display: "inline-block",
          content: "''",
          width: 1,
          height: 10,
          backgroundColor: theme.palette.text.primary,
          margin: "0 2vw",
        },
      },
    },
  },
  contentTitle: {
    fontSize: "0.925rem",
    fontWeight: 500,
    marginBottom: 3.5,
  },
  contentDiv: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    width: "100%",
    height: "6em",
    marginBottom: 3.5,
    fontSize: "0.85rem",
    fontFamily: "'Noto Sans', 'Noto Sans KR', 'Roboto', sans-serif",
    color: theme.palette.text.secondary,
    "& > textarea": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "inherit",
      height: "inherit",
      border: "1px solid " + theme.palette.text.disabled,
      padding: "0 5px",
      resize: "none",
      overflow: "hidden",
      transition: "height 200ms ease-in-out",
      fontSize: "inherit",
      fontFamily: "inherit",
      color: "inherit",
      zIndex: 2,
      "&:focus-visible": {
        height: "10.25em",
        "& ~ span": {
          opacity: 1,
        },
      },
    },
  },
  table: {
    width: "100%",
  },
  dialog: {
    maxWidth: 900,
    width: "fit-content",
    height: "fit-content",
    padding: "2vh 4.5vw",
    borderRadius: "3.5vw",
    userSelect: "none",
  },
  dialogTitle: {
    padding: "1.5vh 4.5vw",
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    wordBreak: "keep-all",
  },
  dialogContent: {
    padding: "0 4.5vw",
    "& > p > span": {
      display: "block",
      fontSize: "0.925rem",
      fontWeight: "500",
    },
  },
  dialogActions: {
    "& > button": {
      fontSize: "1.05rem",
    },
  },
});

interface DataType {
  id: number;
  year: string;
  maker: string;
  model: string;
  price: number;
  details: string;
  offerDate: Date;
  responseDate: Date;
}

const OfferListContainer = () => {
  const classes = useStyles(styles);
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();

  const anchorRef = useRef<HTMLParagraphElement>(null);

  const [open, setOpen] = useState(false);
  const [view, setView] = useState("ALL");
  const [date, setDate] = useState<Date | null>(
    moment(new Date()).startOf("month").local().toDate()
  );
  const [dialogOpen, setDialogOpen] = useState(0);

  const handlePrevOnClick = () => {
    if (date !== null) {
      const prevDate = new Date(date?.setMonth(date.getMonth() - 1));
      setDate(prevDate);
    }
  };
  const handleNextOnClick = () => {
    if (date !== null) {
      const nextDate = new Date(date?.setMonth(date.getMonth() + 1));
      setDate(nextDate);
    }
  };
  const handleDateOnClick = () => {
    setOpen(true);
  };

  const offerListQuery = useQuery(
    "wishList",
    () => {
      dispatch(loadingOn());
      return getOfferList();
    },
    {
      onSuccess: () => {
        dispatch(loadingOff());
      },
      onError: () => {
        dispatch(loadingOff());
      },
    }
  );
  const offerInfoMutation = useMutation(patchOfferInfo, {
    onError: (error) => {
      onError(error);
    },
  });

  const DateMonthFilter = ({ column: { setFilter } }: any) => {
    useEffect(() => {
      setFilter(date);
    }, [date]);

    return null;
  };

  const columns: any = [
    {
      Header: "Title",
      accessor: "title",
      Cell: (e: any) => {
        return (
          <div className={classes.titleDiv}>
            <Typography>{e.row.original["maker"]}</Typography>
            <div>
              <Typography>{e.row.original["model"]}</Typography>
              <Typography>{e.row.original["year"]}</Typography>
              <Typography>
                USD {e.row.original["price"].toLocaleString()}
              </Typography>
            </div>
          </div>
        );
      },
      disableFilters: true,
    },
    {
      Header: "Content",
      accessor: "content",
      Cell: (e: any) => {
        return (
          <>
            <Typography className={classes.contentTitle}>
              Details for offer.
            </Typography>
            <div className={classes.contentDiv}>
              <textarea
                readOnly
                wrap="hard"
                value={
                  e.row.original["details"] !== null
                    ? e.row.original["details"]
                    : undefined
                }
                spellCheck="false"
              />
            </div>
          </>
        );
      },
      disableFilters: true,
    },
    {
      Header: "Offer\nDate",
      accessor: (data: any) => {
        return moment(data.offerDate).local().format("YYYY-MM-DD");
      },
      Filter: DateMonthFilter,
      filter: "date",
    },
    {
      Header: "Response\nDate",
      accessor: (data: any) => {
        return moment(data.responseDate).local().format("YYYY-MM-DD");
      },
      Cell: (e: any) => {
        const handleOnClick = (id: number) => {
          setDialogOpen(id);
        };

        return Date.parse(e.row.original["responseDate"]) === 0 ? (
          <>
            <div>
              <Typography variant="body1">RESPONSE</Typography>
              <Typography variant="body2">
                {t("OFFER.RESPONSE_WAIT")}
              </Typography>
            </div>
            <div>
              <Button
                variant="outlined"
                onClick={() => handleOnClick(e.row.original["id"])}
              >
                {t("OFFER.REQUEST_CANCEL")}
              </Button>
            </div>
          </>
        ) : (
          <div>
            <Typography variant="body1">RESPONSE</Typography>
            <Typography variant="body2">{t("OFFER.RESPONSE_DONE")}</Typography>
          </div>
        );
      },
      disableFilters: true,
    },
  ];
  const [data, setData] = useState<DataType[]>([]);
  const updateData = (rowIndex: number, columnId: string, value: any) => {
    setData((prevData) =>
      prevData.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...prevData[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };
  const filterTypes = {
    date: (rows: any[], _id: any, filterValue: any) => {
      let startDate = moment(filterValue).local().startOf("month");
      let endDate = moment(filterValue).local().endOf("month");

      return view === "ALL"
        ? rows.filter(() => {
            return true;
          })
        : rows.filter((val) => {
            return moment(val.original["offerDate"])
              .local()
              .isBetween(startDate, endDate);
          });
    },
  };

  const handleOnApply = () => {
    if (dialogOpen !== 0) {
      offerInfoMutation.mutate({
        id: dialogOpen,
        data: {
          response_at: new Date("9999-12-31T23:59:59.999+09:00"),
        },
      });
    }
    setDialogOpen(0);
  };

  useEffect(() => {
    if (offerListQuery.isSuccess) {
      const result: DataType[] = [];
      offerListQuery.data.data.results.forEach((data: any) => {
        result.push({
          id: data.id,
          year: data.year,
          maker: data.maker,
          model: data.model,
          price: data.price,
          details: data.details,
          offerDate: new Date(data.created),
          responseDate: new Date(data.response_at),
        });
      });
      setData(result);
    }
  }, [offerListQuery.isLoading, offerListQuery.isRefetching]);

  return (
    <>
      <div>
        {view === "ALL" && (
          <>
            <div className={classes.topDiv}>
              <IconButton disabled>
                <NavigateBeforeIcon />
              </IconButton>
              <Typography className="all">{t("OFFER.ALL")}</Typography>
              <IconButton disabled>
                <NavigateNextIcon />
              </IconButton>
            </div>
            <div className={classes.searchDiv}>
              <Typography
                className="view"
                onClick={() => {
                  setView("DATE");
                  setDate(moment(new Date()).startOf("month").local().toDate());
                }}
              >
                {t("OFFER.VIEW_MONTH")}
              </Typography>
            </div>
          </>
        )}
        {view === "DATE" && (
          <>
            <div className={classes.topDiv}>
              <IconButton onClick={() => handlePrevOnClick()}>
                <NavigateBeforeIcon />
              </IconButton>
              {date !== null && (
                <Typography
                  className="date"
                  onClick={() => handleDateOnClick()}
                  ref={anchorRef}
                >
                  {date.getFullYear()}
                  <span>.</span>
                  {("0" + (date.getMonth() + 1)).slice(-2)}
                </Typography>
              )}
              <IconButton onClick={() => handleNextOnClick()}>
                <NavigateNextIcon />
              </IconButton>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                placement="bottom"
                transition
                disablePortal
                style={{ zIndex: 5 }}
              >
                {({ TransitionProps }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin: "top",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={() => setOpen(false)}>
                        <div>
                          <DatePicker
                            selected={date}
                            onChange={(date) => {
                              setDate(date);
                              setOpen(false);
                            }}
                            inline
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                          />
                        </div>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
              <Typography className="view" onClick={() => setView("ALL")}>
                {t("OFFER.VIEW_ALL")}
              </Typography>
            </div>
          </>
        )}
        <OfferListTable
          className={classes.table}
          columns={columns}
          data={data}
          updateData={updateData}
          filterTypes={filterTypes}
          noDataText={t("OFFER.OFFER_LIST.MSG.NO_DATA")}
        />
      </div>
      <Dialog
        classes={{ paper: classes.dialog }}
        open={dialogOpen !== 0}
        onClose={() => setDialogOpen(0)}
        aria-labelledby="request-confirm-title"
        aria-describedby="request-confirm-description"
      >
        <DialogTitle
          id="request-confirm-title"
          classes={{ root: classes.dialogTitle }}
        >
          <Markup content={t("OFFER.OFFER_LIST.REQUEST_CANCEL.TITLE")} />
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
          <DialogContentText id="request-confirm-description">
            <Markup content={t("OFFER.OFFER_LIST.REQUEST_CANCEL.CONTENT")} />
          </DialogContentText>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogActions }}>
          <Button onClick={() => handleOnApply()}>{t("OPTION.YES")}</Button>
          <Button onClick={() => setDialogOpen(0)}>{t("OPTION.NO")}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OfferListContainer;
