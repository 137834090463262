/* eslint-disable react-hooks/exhaustive-deps */
import {
  Dispatch,
  SetStateAction,
  forwardRef,
  useEffect,
  useState,
} from "react";
import Slider, { CustomArrowProps } from "react-slick";
import { useQuery, useQueries } from "react-query";
import { Markup } from "interweave";
// Components
import { Card, CardContent, Divider, Theme, Typography } from "@mui/material";
import {
  CarouselListItem,
  CarouselListItemSkeleton,
} from "@/browser/components/index";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// API
import { getVehicleList } from "@/common/lib/api/vehicle";
// Libs
import { isNotNull } from "@/common/lib/common";

const styles = (theme: Theme) => ({
  title: {
    fontSize: 28,
    fontWeight: "bold",
    cursor: "default",
    "& > span > span": {
      fontSize: 34,
      color: theme.palette.primary.main,
    },
    "&.left": {
      textAlign: "left",
    },
    "&.right": {
      textAlign: "right",
    },
  },
  divider: {
    margin: "1.5vh 0 !important",
  },
  container: {
    display: "flex",
    flexDirection: "row",
  },
  card: {
    width: "12%",
    height: 312.88,
    boxSizing: "border-box",
    border: "4.5px solid " + theme.palette.divider,
    borderRadius: "10px !important",
    boxShadow: "0 0 5px " + theme.palette.divider + " !important",
    borderColor: theme.palette.primary.main,
    "&.left": {
      margin: "10px 10px 10px 0",
    },
    "&.right": {
      margin: "10px 0 10px 10px",
    },
  },
  cardContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    padding: "16px !important",
  },
  label: {
    textAlign: "center",
    fontSize: 24,
    fontWeight: "bold",
    cursor: "default",
    "& > span > span": {
      fontSize: 32,
      color: theme.palette.primary.main,
    },
  },
  carousel: {
    "&.label": {
      width: "88%",
    },
    "&:not(.label)": {
      width: "100%",
    },
    "& .slick-slide": {
      "& > div": {
        display: "flex",
        margin: "10px 0",
        "& > div:first-child": {
          marginRight: 5,
        },
        "& > div:last-child": {
          marginLeft: 5,
        },
        "& > div:not(:first-child):not(:last-child)": {
          margin: "0 5px",
        },
      },
    },
    "& .slick-prev, & .slick-next": {
      zIndex: 1,
      opacity: 0.25,
      transition: "500ms cubic-bezier(0.22, 0.61, 0.36, 1) all",
      fontSize: 75,
      color: theme.palette.primary.main,
      "&:hover": {
        opacity: 1,
      },
    },
    "&:not(.left):not(.right)": {
      "& .slick-prev": {
        left: -87.5,
      },
      "& .slick-next": {
        right: -87.5,
      },
    },
    "&.left": {
      "& .slick-prev": {
        left: -221.5,
      },
      "& .slick-next": {
        right: -87.5,
      },
    },
    "&.right": {
      "& .slick-prev": {
        left: -87.5,
      },
      "& .slick-next": {
        right: -221.5,
      },
    },
  },
});

interface propType {
  position?: string;
  title?: string;
  label?: string;
  arrows?: boolean;
  defaults?: boolean;
  rows?: number;
  data: CarouselDataType[];
  setData?: Dispatch<SetStateAction<VehicleData[]>>;
}

const PrevArrow = (props: CustomArrowProps) => {
  const { className, style, onClick } = props;
  return (
    <NavigateBeforeIcon
      className={className}
      style={{ ...style }}
      onClick={onClick}
    />
  );
};

const NextArrow = (props: CustomArrowProps) => {
  const { className, style, onClick } = props;
  return (
    <NavigateNextIcon
      className={className}
      style={{ ...style }}
      onClick={onClick}
    />
  );
};

const ProductCarousel = forwardRef<Slider, propType>(
  (
    {
      position = "left",
      title,
      label,
      arrows = true,
      defaults = false,
      rows = 1,
      data = [],
      setData,
    },
    ref
  ) => {
    const classes = useStyles(styles);

    const [itemList, setItemList] = useState<VehicleData[]>([]);
    const [loading, setLoading] = useState(true);

    const defaultQuery = useQuery(
      "defaultProductCarousel",
      () => {
        return getVehicleList(1, 12, "-modified", { status: "ON" });
      },
      {
        enabled: false,
        retry: false,
        onSuccess: (response) => {
          setItemList(response.data.results);
        },
        onSettled: () => {
          setLoading(false);
        },
      }
    );
    const queries = useQueries(
      data.map((query) => {
        return {
          queryKey: query.key,
          queryFn: () =>
            getVehicleList(query.page, query.size, query.align, query.params),
          enabled: false,
          retry: false,
        };
      })
    );
    const settings = {
      dots: false,
      arrows: arrows,
      infinite: true,
      draggable: false,
      swipe: false,
      swipeToSlide: false,
      rows: rows,
      slidesPerRow: 4,
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow />,
    };

    useEffect(() => {
      queries.forEach((query) => query.refetch());
    }, [data]);

    useEffect(() => {
      if (queries.every((result) => result.isSuccess === true)) {
        let maxSize = 0;
        for (let idx = 0; idx < data.length; idx++) {
          if (maxSize < data[idx].size) {
            maxSize = data[idx].size;
          }
        }
        setItemList([]);
        if (setData !== undefined) {
          setData([]);
        }
        for (let idx = 0; idx < maxSize; idx++) {
          for (let jdx = 0; jdx < queries.length; jdx++) {
            if (
              queries[jdx].isSuccess &&
              isNotNull(queries[jdx].data?.data.results[idx])
            ) {
              setItemList((prev) => [
                ...prev,
                queries[jdx].data?.data.results[idx],
              ]);
              if (setData !== undefined) {
                setData((prev) => [
                  ...prev,
                  queries[jdx].data?.data.results[idx],
                ]);
              }
            }
          }
        }
      }
    }, [queries.some((result) => result.isLoading)]);

    useEffect(() => {
      if (queries.every((result) => result.isSuccess === true)) {
        let maxSize = 0;
        for (let idx = 0; idx < data.length; idx++) {
          if (maxSize < data[idx].size) {
            maxSize = data[idx].size;
          }
        }
        setItemList([]);
        if (setData !== undefined) {
          setData([]);
        }
        for (let idx = 0; idx < maxSize; idx++) {
          for (let jdx = 0; jdx < queries.length; jdx++) {
            if (
              queries[jdx].isSuccess &&
              isNotNull(queries[jdx].data?.data.results[idx])
            ) {
              setItemList((prev) => [
                ...prev,
                queries[jdx].data?.data.results[idx],
              ]);
              if (setData !== undefined) {
                setData((prev) => [
                  ...prev,
                  queries[jdx].data?.data.results[idx],
                ]);
              }
            }
          }
        }
      }
    }, [queries.some((result) => result.isRefetching)]);

    useEffect(() => {
      if (queries.every((result) => result.isFetching === true)) {
        if (defaults) {
          defaultQuery.refetch();
        } else {
          setLoading(false);
        }
      }
    }, [queries.some((result) => result.isFetching)]);

    return (
      <>
        {(loading || itemList.length > 0) && (
          <>
            {isNotNull(title) && (
              <>
                <Divider className={classes.divider} />
                <Typography className={`${classes.title} ${position}`}>
                  <Markup content={title} />
                </Typography>
                <Divider className={classes.divider} />
              </>
            )}
            <div className={classes.container}>
              {isNotNull(label) && position === "left" && (
                <Card className={`${classes.card} ${position}`}>
                  <CardContent className={classes.cardContent}>
                    <Typography className={classes.label}>
                      <Markup content={label} />
                    </Typography>
                  </CardContent>
                </Card>
              )}
              <Slider
                ref={ref}
                {...settings}
                className={
                  isNotNull(label)
                    ? `${classes.carousel} ${position} label`
                    : `${classes.carousel}`
                }
              >
                {loading
                  ? [...Array(32).keys()].map((idx) => {
                      return (
                        <CarouselListItemSkeleton key={"skeleton" + idx} />
                      );
                    })
                  : itemList.map((item, idx) => {
                      return <CarouselListItem key={idx} data={item} />;
                    })}
              </Slider>
              {isNotNull(label) && position === "right" && (
                <Card className={`${classes.card} ${position}`}>
                  <CardContent className={classes.cardContent}>
                    <Typography className={classes.label}>
                      <Markup content={label} />
                    </Typography>
                  </CardContent>
                </Card>
              )}
            </div>
          </>
        )}
      </>
    );
  }
);

export default ProductCarousel;
