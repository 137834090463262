/* eslint-disable no-eval */
import { isMobile } from "react-device-detect";
// Components
import {
  Autocomplete as MuiAutocomplete,
  TextField,
  Theme,
  useTheme,
} from "@mui/material";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// Libs
import { isNotNull } from "@/common/lib/common";

const styles = (theme: Theme, color: string = "primary") => ({
  root: {
    /* width 설정은 여기서 */
    "&.Size-small": {
      "&.Mobile": {
        [theme.breakpoints.up("md")]: {
          width: 144,
        },
        [theme.breakpoints.between("sm", "md")]: {
          width: 108,
        },
        [theme.breakpoints.down("sm")]: {
          width: 72.5,
        },
      },
      "&:not(.Mobile)": {
        width: 72.5,
      },
    },
    "&.Size-normal": {
      "&.Mobile": {
        [theme.breakpoints.up("md")]: {
          width: 215,
        },
        [theme.breakpoints.between("sm", "md")]: {
          width: 165,
        },
        [theme.breakpoints.down("sm")]: {
          width: 107.5,
        },
      },
      "&:not(.Mobile)": {
        width: 107.5,
      },
    },
    "&.Size-middle": {
      "&.Mobile": {
        [theme.breakpoints.up("md")]: {
          width: 285,
        },
        [theme.breakpoints.between("sm", "md")]: {
          width: 215,
        },
        [theme.breakpoints.down("sm")]: {
          width: 142.5,
        },
      },
      "&:not(.Mobile)": {
        width: 142.5,
      },
    },
    "&.Size-large": {
      "&.Mobile": {
        [theme.breakpoints.up("md")]: {
          width: 350,
        },
        [theme.breakpoints.between("sm", "md")]: {
          width: 262.5,
        },
        [theme.breakpoints.down("sm")]: {
          width: 175,
        },
      },
      "&:not(.Mobile)": {
        width: 175,
      },
    },
    "&&": {
      "&:hover": {
        "& .MuiInputLabel-root:not(.Mui-error)": {
          color: eval(`theme.palette.${color}.main`),
        },
        "& .MuiInputBase-root:not(.Mui-error)": {
          color: eval(`theme.palette.${color}.main`),
          "& .MuiAutocomplete-popupIndicator > svg": {
            color: eval(`theme.palette.${color}.main`),
          },
          "& > .MuiOutlinedInput-notchedOutline": {
            borderColor: eval(`theme.palette.${color}.main`),
          },
          "&.MuiInput-underline::before": {
            borderColor: eval(`theme.palette.${color}.main`),
          },
          "&.MuiFilledInput-underline::before": {
            borderColor: eval(`theme.palette.${color}.main`),
          },
        },
        "& .MuiIconButton-root": {
          visibility: "visible",
        },
      },
      "& .MuiInputBase-root.Mui-error": {
        color: theme.palette.error.main,
        "& .MuiSvgIcon-root": {
          color: theme.palette.error.main,
        },
      },
      "& .MuiInputLabel-root": {
        lineHeight: "1",
        width: "calc(100% - 2.5em)",
        top: "calc(50% - .5em)",
        fontSize: "1rem",
        "&.MuiInputLabel-shrink": {
          width: "calc(150% - 2.5em)",
        },
        "&.MuiInputLabel-outlined": {
          transform: "translate(14px, 0) scale(1)",
          [theme.breakpoints.up("md")]: {
            "&.MuiInputLabel-shrink": {
              transform: "translate(14px, -85%) scale(0.75)",
            },
          },
          [theme.breakpoints.between("sm", "md")]: {
            "&.MuiInputLabel-shrink": {
              transform: "translate(14px, -100%) scale(0.75)",
            },
          },
          [theme.breakpoints.down("sm")]: {
            "&.MuiInputLabel-shrink": {
              transform: "translate(14px, -115%) scale(0.75)",
            },
          },
        },
        "&.MuiInputLabel-filled": {
          transform: "translate(12px, 0) scale(1)",
          [theme.breakpoints.up("md")]: {
            "&.MuiInputLabel-shrink": {
              transform: "translate(12px, -40%) scale(0.75)",
            },
          },
          [theme.breakpoints.between("sm", "md")]: {
            "&.MuiInputLabel-shrink": {
              transform: "translate(12px, -55%) scale(0.75)",
            },
          },
          [theme.breakpoints.down("sm")]: {
            "&.MuiInputLabel-shrink": {
              transform: "translate(12px, -70%) scale(0.75)",
            },
          },
        },
        "&.MuiInputLabel-standard": {
          transform: "unset",
          "&.MuiInputLabel-shrink": {
            transform: "translate(0, -70%) scale(0.75)",
          },
        },
      },
      "& .MuiInputBase-root > input": {
        lineHeight: "1",
        width: "calc(100% - 2.5em)",
        fontSize: "1rem",
      },
      "& .MuiAutocomplete-endAdornment": {
        position: "absolute",
        top: "calc(50% - 0.875rem)",
        "& > .MuiIconButton-root": {
          width: "1em",
          height: "1em",
          "&:hover": {
            color: eval(`theme.palette.${color}.main`),
          },
        },
      },
    },
  },
  endAdornment: {
    "& > .MuiIconButton-root:hover": {
      color: eval(`theme.palette.${color}.main`),
    },
  },
  option: {
    "&:hover": {
      color: eval(`theme.palette.${color}.main`),
    },
    "&[aria-selected='true']": {
      color: eval(`theme.palette.${color}.main`),
    },
  },
});

const Autocomplete = ({
  label,
  size = "small",
  color = "primary",
  variant,
  placeholder,
  value,
  dispatch,
  options,
  autocompleteClassName,
  textFieldClassName,
  ...props
}: any) => {
  const theme = useTheme();
  const classes = useStyles(styles(theme, color));

  return (
    <MuiAutocomplete
      size="small"
      classes={{
        root: classes.root,
        endAdornment: classes.endAdornment,
        option: classes.option,
      }}
      className={
        isMobile
          ? `${autocompleteClassName} Size-${size} Mobile`
          : `${autocompleteClassName} Size-${size}`
      }
      value={
        isNotNull(value)
          ? options.filter((option: any) => {
              return option.value === value;
            })[0]
          : null
      }
      onChange={(_event, option: Option) => {
        isNotNull(option) ? dispatch(option.value) : dispatch("");
      }}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={variant}
          label={label}
          placeholder={placeholder}
          className={textFieldClassName}
        />
      )}
      {...props}
    />
  );
};

export default Autocomplete;
