/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
// Redux
import { setAccessURL } from "@/common/lib/redux/actions/commonAction";
import { useTypedSelector, useTypedDispatch } from "@/common/lib/redux/store";
// Components
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Modal,
  Theme,
  Typography,
} from "@mui/material";
import LoginPage from "@/mobile/pages/auth/LoginPage";
import { CompanyLogoIcon } from "@/common/icons";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// Libs
import { isNull, isNotNull } from "@/common/lib/common";

const styles = (theme: Theme) => ({
  dialog: {
    maxWidth: 900,
    width: "fit-content",
    height: "fit-content",
    padding: "2vh 4.5vw",
    borderRadius: "3.5vw",
    border: "1vw solid " + theme.palette.logo.blue,
    userSelect: "none",
    "& > .MuiDialogTitle-root": {
      padding: "1vh 2vw",
    },
    "& > .MuiDialogContent-root": {
      padding: "0 2vw 1vh",
    },
  },
  logo: {
    width: "75%",
    fill: theme.palette.logo.blue,
  },
  text: {
    fontSize: "0.8rem",
    fontWeight: "bold",
    wordBreak: "keep-all",
    "& > span > span": {
      color: theme.palette.logo.blue,
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const LoginOnlyPage = ({ ...props }: { [key: string]: any }) => {
  const classes = useStyles(styles);
  const [confirm, setConfirm] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);
  const { loginToken } = useTypedSelector((state) => state.auth);
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    if (!loginOpen && confirm && isNull(loginToken)) {
      if (location.key === "default") {
        navigate("/");
      } else {
        navigate(-1);
      }
    }
  }, [loginOpen]);

  useEffect(() => {
    if (!confirm && isNotNull(loginToken)) {
      setConfirm(true);
    } else if (confirm && isNull(loginToken)) {
      dispatch(setAccessURL(location.pathname));
      setLoginOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirm, loginToken]);

  return (
    <>
      {isNotNull(loginToken) && props.children}
      <Dialog
        classes={{ paper: classes.dialog }}
        open={!confirm && isNull(loginToken)}
        onClose={() => setConfirm(true)}
      >
        <DialogTitle sx={{ textAlign: "center" }}>
          <CompanyLogoIcon className={classes.logo} />
        </DialogTitle>
        <DialogContent sx={{ textAlign: "center" }}>
          <Typography variant="h6" className={classes.text}>
            <Markup content={t("DIALOG.CONTENT.LOGIN_ONLY_PAGE")} />
          </Typography>
        </DialogContent>
      </Dialog>
      <Modal
        classes={{ root: classes.modal }}
        open={loginOpen}
        onClose={() => setLoginOpen(false)}
      >
        <div>
          <LoginPage onClose={() => setLoginOpen(false)} />
        </div>
      </Modal>
    </>
  );
};

export default LoginOnlyPage;
