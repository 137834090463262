import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
// Component
import { Theme, Typography } from "@mui/material";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import EngineeringIcon from "@mui/icons-material/Engineering";
// Styles
import { useStyles } from "@/common/lib/style/hooks";

const styles = (theme: Theme) => ({
  outerDiv: {
    display: "inline-flex",
    width: "100%",
    margin: "15px 0",
    userSelect: "none",
    "&.right": {
      justifyContent: "flex-end",
    },
  },
  topDiv: {
    display: "flex",
    alignItems: "center",
    marginBottom: 15,
    "&.right": {
      justifyContent: "flex-end",
    },
  },
  bottomDiv: {
    "&.right > p": {
      textAlign: "right",
    },
  },
  img: {
    width: 375,
  },
  icon: {
    color: theme.palette.primary.main,
    fontSize: "3.5rem",
    marginRight: 10,
  },
  title: {
    fontSize: "2rem",
    fontWeight: "bold",
    wordSpacing: "-1.5px",
  },
  content: {
    "&.left": {
      paddingLeft: 12.5,
    },
    margin: "5px 0 10px",
    cursor: "default",
    wordSpacing: "-1.5px",
  },
  comment: {
    fontSize: "1.35rem",
    wordSpacing: "-1.5px",
    fontWeight: 500,
  },
});

const ProfileContainer = () => {
  const classes = useStyles(styles);
  const { t } = useTranslation();

  return (
    <div>
      <div className={classes.outerDiv}>
        <img
          alt=""
          src={require(`@/common/images/profile/main_image1.png`)}
          className={classes.img}
        />
        <div style={{ marginLeft: 25 }}>
          <div className={classes.topDiv}>
            <MiscellaneousServicesIcon className={classes.icon} />
            <Typography className={classes.title}>
              {t("PROFILE.PROFILE.TITLE1")}
            </Typography>
          </div>
          <div className={classes.bottomDiv}>
            <Typography className={classes.comment}>
              {t("PROFILE.PROFILE.COMMENT1_1")}
            </Typography>
            <p className={`${classes.content} left`}>
              <Markup content={t("PROFILE.PROFILE.CONTENT1_1")} />
            </p>
            <Typography className={classes.comment}>
              {t("PROFILE.PROFILE.COMMENT1_2")}
            </Typography>
            <p className={`${classes.content} left`}>
              <Markup content={t("PROFILE.PROFILE.CONTENT1_2")} />
            </p>
          </div>
        </div>
      </div>
      <div className={`${classes.outerDiv} right`}>
        <div style={{ marginRight: 25 }}>
          <div className={`${classes.topDiv} right`}>
            <EmojiEventsIcon className={classes.icon} />
            <Typography className={classes.title}>
              {t("PROFILE.PROFILE.TITLE2")}
            </Typography>
          </div>
          <div className={`${classes.bottomDiv} right`}>
            <Typography className={classes.comment}>
              {t("PROFILE.PROFILE.COMMENT2_1")}
            </Typography>
            <p className={classes.content}></p>
            <Typography className={classes.comment}>
              {t("PROFILE.PROFILE.COMMENT2_2")}
            </Typography>
            <p className={classes.content}>
              <Markup content={t("PROFILE.PROFILE.CONTENT2")} />
            </p>
          </div>
        </div>
        <img
          alt=""
          src={require(`@/common/images/profile/main_image2.png`)}
          className={classes.img}
        />
      </div>
      <div className={classes.outerDiv}>
        <img
          alt=""
          src={require(`@/common/images/profile/main_image3.png`)}
          className={classes.img}
        />
        <div style={{ marginLeft: 25 }}>
          <div className={classes.topDiv}>
            <EngineeringIcon className={classes.icon} />
            <Typography className={classes.title}>
              {t("PROFILE.PROFILE.TITLE3")}
            </Typography>
          </div>
          <div className={classes.bottomDiv}>
            <Typography className={classes.comment}>
              {t("PROFILE.PROFILE.COMMENT3_1")}
            </Typography>
            <p className={`${classes.content} left`}>
              <Markup content={t("PROFILE.PROFILE.CONTENT3_1")} />
            </p>
            <Typography className={classes.comment}>
              {t("PROFILE.PROFILE.COMMENT3_2")}
            </Typography>
            <p className={`${classes.content} left`}>
              <Markup content={t("PROFILE.PROFILE.CONTENT3_2")} />
            </p>
          </div>
        </div>
      </div>
      <div className={`${classes.outerDiv} right`}>
        <div style={{ marginRight: 25 }}>
          <div className={`${classes.topDiv} right`}>
            <SupportAgentIcon className={classes.icon} />
            <Typography className={classes.title}>
              {t("PROFILE.PROFILE.TITLE4")}
            </Typography>
          </div>
          <div className={`${classes.bottomDiv} right`}>
            <Typography className={classes.comment}>
              {t("PROFILE.PROFILE.COMMENT4_1")}
            </Typography>
            <p className={classes.content}>
              <Markup content={t("PROFILE.PROFILE.CONTENT4_1")} />
            </p>
            <Typography className={classes.comment}>
              {t("PROFILE.PROFILE.COMMENT4_2")}
            </Typography>
            <p className={classes.content}>
              <Markup content={t("PROFILE.PROFILE.CONTENT4_2")} />
            </p>
          </div>
        </div>
        <img
          alt=""
          src={require(`@/common/images/profile/main_image4.png`)}
          className={classes.img}
        />
      </div>
    </div>
  );
};

export default ProfileContainer;
