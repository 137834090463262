const CompanyIcon = ({ ...props }: any) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1051 385"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <g transform="translate(0, 385) scale(0.1, -0.1)">
        <path
          d="M5463 3778 c-21 -20 -23 -21 -41 -5 -26 23 -103 22 -151 -3 -82 -41
-125 -121 -119 -217 5 -75 32 -113 83 -113 32 0 106 42 140 80 32 36 10 31
-44 -10 -29 -22 -64 -43 -78 -46 -48 -13 -81 48 -68 125 14 85 102 171 175
171 50 0 63 -15 45 -51 -11 -21 -12 -30 -3 -35 7 -4 14 -1 16 6 3 8 25 35 49
61 23 25 43 49 43 53 0 14 -27 5 -47 -16z"
        />
        <path
          d="M4962 3755 c-30 -25 -59 -86 -86 -185 -14 -52 -63 -140 -77 -140 -14
0 -17 44 -8 101 14 88 24 109 53 109 14 0 28 5 31 10 4 6 -5 10 -20 10 -23 0
-25 3 -20 23 11 36 11 41 -5 44 -10 2 -23 -12 -38 -42 -15 -31 -28 -45 -41
-45 -11 0 -24 -6 -28 -14 -4 -8 -17 -15 -28 -18 -25 -4 -26 -32 -2 -36 12 -3
20 4 24 17 3 12 9 21 13 21 5 0 16 3 24 6 14 5 15 2 6 -21 -5 -14 -10 -35 -10
-46 0 -27 -40 -119 -52 -119 -6 0 -7 22 -2 56 6 51 5 56 -12 52 -38 -7 -41
-108 -4 -128 15 -8 24 -5 40 12 11 12 20 27 20 32 1 6 7 -4 15 -21 8 -18 21
-33 30 -33 16 0 46 26 64 56 9 16 12 17 20 5 7 -11 14 -5 29 25 11 21 35 53
52 69 l32 30 -6 -46 c-8 -57 0 -79 29 -79 16 0 33 15 59 53 20 29 36 56 36 60
0 19 -20 2 -49 -43 -18 -27 -34 -50 -37 -50 -2 0 -3 26 -2 58 3 51 1 57 -18
60 -12 2 -35 -9 -53 -23 -46 -39 -38 -14 13 38 53 55 85 129 66 152 -17 20
-24 19 -58 -10z m19 -72 c-11 -22 -27 -46 -36 -53 -16 -13 -16 -11 0 25 22 54
48 92 52 78 2 -6 -5 -29 -16 -50z"
        />
        <path
          d="M4630 3680 c-6 -11 -22 -80 -36 -153 -13 -72 -28 -141 -33 -152 -7
-18 -9 -16 -15 13 -4 18 -11 62 -14 99 -12 117 -35 95 -88 -81 -18 -60 -37
-113 -42 -118 -17 -17 -37 94 -37 209 0 115 -7 132 -36 97 -17 -21 -17 -167 0
-247 7 -32 23 -70 36 -85 38 -43 55 -23 90 106 16 62 33 112 37 112 4 0 8 -20
8 -45 0 -61 23 -115 49 -115 30 0 39 26 67 183 13 78 29 152 34 164 12 29 -5
40 -20 13z"
        />
        <path
          d="M5446 3579 c-48 -56 -26 -119 24 -69 11 11 21 20 23 20 1 0 2 -17 2
-37 0 -55 27 -55 81 -2 24 23 46 40 49 37 3 -3 -2 -21 -10 -41 -25 -60 3 -87
53 -51 21 15 25 15 43 -2 18 -16 22 -16 56 -1 50 20 75 19 68 -4 -9 -28 13
-32 43 -9 31 25 93 53 100 45 3 -3 -4 -15 -15 -28 -37 -40 -45 -64 -28 -81 12
-12 22 -13 42 -5 14 5 26 8 27 7 19 -49 51 -53 132 -17 64 28 42 36 -26 9 -36
-14 -56 -18 -63 -11 -16 16 11 41 43 41 36 0 75 31 67 53 -12 32 -93 13 -127
-28 -16 -20 -70 -48 -77 -41 -3 2 11 26 31 52 29 37 34 50 24 61 -11 13 -32
13 -71 -3 -9 -3 -16 3 -20 17 l-6 21 -27 -21 c-24 -19 -64 -29 -64 -15 0 3 5
14 10 25 15 28 -4 59 -37 59 -18 0 -42 -17 -83 -60 -79 -82 -105 -76 -39 9 27
34 24 44 -11 36 -25 -5 -30 -3 -30 12 0 11 5 24 11 30 8 8 7 13 -1 18 -17 10
-41 -17 -45 -51 -3 -30 -66 -96 -73 -76 -2 6 9 34 23 61 28 51 27 83 -3 59
-12 -10 -16 -10 -19 0 -8 23 -51 13 -77 -19z m64 3 c0 -5 -14 -21 -31 -38 -30
-28 -31 -28 -19 -4 7 14 17 31 23 38 11 14 27 16 27 4z m300 -55 c0 -15 -35
-69 -50 -77 -26 -14 -30 -13 -30 9 0 11 14 31 31 45 27 23 49 33 49 23z m330
-106 c0 -10 -24 -21 -43 -21 -7 0 -3 7 7 15 22 17 36 19 36 6z"
        />
        <path
          d="M110 3430 c0 -20 6 -20 1941 -20 1843 0 1940 1 1937 18 -3 16 -105
17 -1941 20 -1932 2 -1937 2 -1937 -18z"
        />
        <path
          d="M6470 3430 c0 -20 6 -20 1941 -20 1843 0 1940 1 1937 18 -3 16 -105
17 -1941 20 -1932 2 -1937 2 -1937 -18z"
        />
        <path
          d="M5127 3140 c-88 -22 -195 -70 -277 -124 -74 -48 -55 -46 28 4 129 76
275 120 402 120 54 0 61 -2 56 -17 -2 -10 -12 -44 -20 -75 -9 -32 -24 -62 -34
-66 -23 -12 -159 -22 -452 -32 -213 -7 -258 -11 -342 -33 -98 -26 -193 -71
-182 -88 3 -5 19 -2 36 7 59 31 169 63 246 73 98 13 215 18 747 36 510 18 670
19 696 5 15 -9 19 -7 19 6 0 17 -51 70 -110 114 -29 23 -32 28 -22 47 7 12 8
24 4 27 -4 2 -167 7 -362 10 -332 6 -360 5 -433 -14z m541 -19 c22 -12 62 -87
62 -116 0 -10 -38 -15 -156 -20 -201 -10 -205 -10 -213 4 -5 8 7 106 18 150 3
10 268 -6 289 -18z m140 -37 c39 -30 43 -66 8 -82 -33 -15 -60 6 -80 65 -9 25
-16 48 -16 51 0 11 58 -12 88 -34z"
        />
        <path
          d="M4515 2856 c-16 -7 -41 -26 -55 -41 -26 -29 -19 -27 39 12 43 29 119
30 180 3 35 -16 41 -17 31 -4 -36 43 -133 58 -195 30z"
        />
        <path
          d="M5685 2851 c-16 -10 -43 -33 -59 -50 l-30 -31 -288 0 c-249 0 -288
-2 -288 -15 0 -13 38 -15 284 -15 l284 0 44 41 c55 52 90 70 136 71 47 0 80
-13 120 -47 37 -31 49 -29 26 3 -40 58 -167 82 -229 43z"
        />
        <path
          d="M320 2007 c-79 -172 -163 -352 -187 -401 -24 -49 -43 -90 -43 -92 0
-2 38 -4 84 -4 l85 0 25 55 25 55 158 0 159 0 27 -55 27 -55 80 0 c44 0 80 3
80 7 0 10 -360 785 -369 795 -3 4 -71 -134 -151 -305z m195 -139 c22 -50 42
-95 43 -100 2 -4 -39 -8 -91 -8 l-95 0 46 100 c25 55 49 100 52 100 3 0 23
-41 45 -92z"
        />
        <path
          d="M2732 2309 c-79 -13 -157 -55 -217 -114 -168 -168 -162 -425 13 -587
86 -79 148 -102 277 -103 93 0 105 3 167 33 197 96 284 311 208 515 -67 178
-260 288 -448 256z m137 -165 c68 -20 132 -78 161 -146 88 -205 -137 -412
-338 -312 -198 99 -179 383 30 455 65 23 81 23 147 3z"
        />
        <path
          d="M5290 1928 c-100 -216 -185 -399 -187 -405 -4 -10 16 -13 80 -13 l85
0 23 55 23 55 160 0 161 0 24 -55 23 -55 84 0 c46 0 84 2 84 4 0 5 -370 799
-374 803 -2 2 -85 -173 -186 -389z m199 10 c5 -13 25 -58 45 -100 l35 -78 -94
0 -94 0 35 78 c20 42 40 87 45 100 5 12 11 22 14 22 3 0 9 -10 14 -22z"
        />
        <path
          d="M950 2293 c0 -5 0 -134 0 -288 1 -271 1 -281 24 -331 48 -104 136
-164 252 -172 128 -8 233 50 292 162 l27 51 3 293 3 292 -80 0 -80 0 -3 -281
-3 -280 -42 -40 c-37 -34 -48 -39 -90 -39 -63 0 -109 28 -133 83 -18 38 -20
67 -20 300 l0 257 -75 0 c-41 0 -75 -3 -75 -7z"
        />
        <path
          d="M1732 2223 l3 -78 98 -3 97 -3 0 -314 0 -315 75 0 75 0 0 315 0 314
98 3 97 3 3 78 3 77 -276 0 -276 0 3 -77z"
        />
        <path
          d="M3802 1908 l3 -393 235 0 235 0 3 78 3 77 -161 0 -160 0 0 315 0 315
-80 0 -80 0 2 -392z"
        />
        <path
          d="M4470 1905 l0 -395 235 0 235 0 0 80 0 80 -160 0 -160 0 0 80 0 80
135 0 135 0 0 80 0 80 -135 0 -135 0 0 75 0 74 158 3 157 3 3 78 3 77 -236 0
-235 0 0 -395z"
        />
        <path
          d="M6030 1905 l0 -398 163 6 c184 6 244 19 324 74 99 67 148 172 147
318 0 105 -11 150 -56 225 -39 64 -96 110 -175 141 -50 19 -82 23 -230 27
l-173 4 0 -397z m373 202 c85 -50 123 -157 97 -267 -25 -102 -102 -159 -232
-167 l-78 -6 0 238 0 238 83 -5 c68 -3 93 -9 130 -31z"
        />
        <path
          d="M6870 1905 l0 -395 235 0 235 0 0 80 0 80 -155 0 -155 0 0 80 0 80
135 0 135 0 0 80 0 80 -135 0 -135 0 0 75 0 75 155 0 155 0 0 80 0 80 -235 0
-235 0 0 -395z"
        />
        <path
          d="M7560 1905 l0 -395 80 0 80 0 0 187 0 188 125 -188 125 -187 96 0
c73 0 93 3 88 13 -4 6 -55 77 -112 157 -65 89 -99 146 -92 148 28 10 94 79
111 117 24 53 24 147 0 200 -10 22 -38 59 -64 82 -65 60 -118 73 -294 73
l-143 0 0 -395z m331 206 c21 -22 29 -39 29 -66 0 -27 -8 -44 -29 -66 -27 -27
-35 -29 -100 -29 l-71 0 0 95 0 95 71 0 c65 0 73 -2 100 -29z"
        />
        <path
          d="M8405 1865 c-67 -23 -125 -107 -125 -181 0 -36 49 -118 85 -143 46
-31 130 -40 171 -17 58 31 58 31 39 64 l-18 30 -31 -19 c-49 -30 -92 -25 -132
15 -29 29 -34 41 -34 79 0 37 6 51 30 75 39 39 75 46 125 21 l40 -19 17 23
c24 30 19 38 -32 65 -51 25 -79 27 -135 7z"
        />
        <path
          d="M8765 1861 c-74 -34 -115 -95 -115 -172 0 -133 149 -221 269 -159
138 70 132 255 -9 328 -53 27 -92 28 -145 3z m110 -65 c36 -15 75 -67 75 -99
0 -58 -58 -117 -115 -117 -124 0 -155 165 -40 216 38 17 39 17 80 0z"
        />
        <path
          d="M9460 1690 l0 -180 105 0 105 0 0 38 0 37 -70 -3 -70 -4 0 146 0 146
-35 0 -35 0 0 -180z"
        />
        <path
          d="M9670 1835 c0 -35 0 -35 45 -35 l45 0 0 -145 0 -145 35 0 35 0 0 145
0 145 45 0 c45 0 45 0 45 35 l0 35 -125 0 -125 0 0 -35z"
        />
        <path
          d="M10002 1693 l3 -178 82 1 c145 2 207 58 201 186 -4 80 -24 113 -88
146 -35 17 -61 22 -122 22 l-78 0 2 -177z m192 66 c25 -28 28 -38 23 -78 -7
-61 -41 -91 -103 -91 l-44 0 0 98 c0 54 3 102 8 106 4 4 25 5 47 3 28 -4 48
-15 69 -38z"
        />
        <path
          d="M9092 1578 c-26 -26 -7 -78 28 -78 9 0 25 9 35 20 37 41 -24 97 -63
58z"
        />
        <path
          d="M9272 1578 c-19 -19 -14 -56 8 -68 23 -13 27 -48 5 -56 -19 -8 -20
-24 -1 -24 51 0 91 90 61 136 -17 26 -53 32 -73 12z"
        />
        <path
          d="M10362 1578 c-36 -36 8 -92 54 -67 41 22 26 79 -21 79 -12 0 -26 -5
-33 -12z"
        />
        <path
          d="M3230 1012 c-30 -24 -21 -66 19 -86 34 -18 45 -40 26 -56 -17 -14
-55 -4 -55 16 0 8 -4 14 -10 14 -13 0 -13 -6 0 -31 22 -40 100 -17 100 30 0
23 -8 34 -35 50 -42 25 -49 61 -11 61 15 0 26 -7 29 -17 4 -17 4 -17 12 0 9
21 7 24 -26 32 -17 4 -33 0 -49 -13z"
        />
        <path
          d="M3970 1023 c-62 -23 -81 -103 -35 -148 39 -40 125 -31 125 12 0 8 -7
6 -20 -7 -26 -26 -46 -25 -75 5 -46 45 -23 125 36 125 17 0 32 -6 35 -15 9
-21 24 -19 24 4 0 25 -50 39 -90 24z"
        />
        <path
          d="M5279 1002 c-27 -24 -33 -36 -32 -68 3 -90 119 -117 161 -37 29 53
-4 117 -64 129 -26 5 -38 0 -65 -24z m97 -28 c20 -47 10 -92 -23 -107 -22 -10
-28 -8 -49 16 -43 51 -27 127 27 127 25 0 33 -6 45 -36z"
        />
        <path
          d="M6048 1014 c-65 -35 -65 -118 -1 -152 32 -18 78 -14 96 6 26 28 20
38 -7 13 -27 -25 -70 -21 -86 9 -14 26 -12 94 2 108 18 18 75 15 87 -5 9 -16
10 -16 11 3 0 34 -55 44 -102 18z"
        />
        <path
          d="M6280 1023 c-92 -35 -73 -173 23 -173 105 0 120 152 17 174 -14 3
-32 2 -40 -1z m60 -28 c6 -8 14 -32 17 -53 4 -32 1 -44 -16 -61 -28 -28 -45
-27 -70 5 -23 29 -27 74 -11 105 13 23 62 26 80 4z"
        />
        <path
          d="M2978 1013 c8 -3 12 -26 12 -64 0 -69 21 -99 70 -99 47 0 70 30 70
93 0 29 5 58 12 65 9 9 4 12 -22 12 -29 -1 -32 -2 -17 -11 12 -7 17 -23 17
-55 0 -61 -14 -84 -50 -84 -19 0 -33 7 -40 19 -14 27 -13 104 2 119 9 9 3 12
-27 11 -22 0 -34 -3 -27 -6z"
        />
        <path
          d="M3378 1013 c19 -7 16 -133 -4 -147 -12 -9 -2 -12 50 -14 57 -1 67 1
77 19 6 11 8 23 5 26 -3 3 -8 -2 -12 -11 -4 -10 -18 -16 -40 -16 -31 0 -34 3
-34 30 0 25 4 30 24 30 13 0 26 -6 29 -12 2 -7 6 -1 9 14 5 25 3 26 -29 21
-32 -5 -33 -4 -33 26 0 26 4 31 25 31 13 0 29 -8 34 -17 9 -16 10 -16 11 5 0
21 -4 22 -62 21 -35 0 -57 -3 -50 -6z"
        />
        <path
          d="M3568 1013 c15 -6 17 -143 2 -143 -5 0 -10 -4 -10 -10 0 -5 24 -10
54 -10 73 0 116 33 116 89 0 59 -31 81 -111 80 -35 0 -58 -3 -51 -6z m112 -23
c23 -23 27 -77 7 -104 -9 -12 -23 -17 -42 -14 -29 3 -30 5 -33 57 -2 29 -1 60
2 67 8 20 43 17 66 -6z"
        />
        <path
          d="M4182 1003 c-5 -10 -18 -42 -28 -72 -10 -29 -25 -56 -31 -58 -23 -9
-14 -20 17 -20 30 0 41 12 19 19 -19 6 9 48 32 48 22 0 42 -39 28 -55 -5 -7 4
-11 26 -11 19 0 35 4 35 8 0 4 -4 8 -9 8 -4 0 -21 34 -36 75 -28 77 -38 88
-53 58z m20 -48 c4 -20 1 -25 -14 -25 -14 0 -18 5 -14 16 3 9 6 20 6 25 0 20
18 7 22 -16z"
        />
        <path
          d="M4338 1013 c15 -6 17 -143 2 -143 -5 0 -10 -4 -10 -8 0 -4 16 -8 35
-8 19 0 35 4 35 8 0 4 -4 8 -10 8 -5 0 -10 14 -10 30 0 39 13 38 39 -4 18 -29
44 -45 74 -46 4 0 -8 16 -28 37 -39 41 -41 46 -20 63 18 15 19 42 3 58 -7 7
-37 12 -67 11 -31 0 -50 -3 -43 -6z m85 -24 c14 -22 2 -49 -23 -49 -16 0 -20
6 -20 36 0 28 4 35 16 32 9 -2 21 -10 27 -19z"
        />
        <path
          d="M4658 1008 c7 -7 12 -39 12 -75 0 -49 -3 -63 -15 -63 -8 0 -15 -4
-15 -8 0 -5 30 -8 68 -8 64 1 67 2 70 26 2 22 1 23 -8 8 -7 -11 -23 -18 -40
-18 -27 0 -30 3 -30 30 0 22 5 30 18 30 10 0 23 -5 30 -12 9 -9 12 -6 12 13 0
23 -3 25 -30 21 -28 -4 -30 -2 -30 27 0 25 4 31 21 31 12 0 28 -8 35 -17 12
-17 13 -16 14 5 0 20 -4 22 -62 22 -48 0 -59 -3 -50 -12z"
        />
        <path
          d="M4852 1008 c8 -7 23 -27 33 -44 17 -31 17 -32 -6 -63 -12 -17 -29
-31 -36 -31 -7 0 -13 -4 -13 -9 0 -5 14 -9 30 -9 22 1 29 5 26 15 -6 14 13 53
26 53 8 0 23 -40 20 -55 -1 -5 14 -10 33 -11 39 -1 44 6 16 23 -23 15 -84 121
-76 133 4 6 -10 10 -31 10 -32 0 -35 -2 -22 -12z"
        />
        <path
          d="M4945 1010 c4 -6 1 -18 -6 -26 -23 -28 -3 -23 23 6 l26 30 -24 0
c-15 0 -23 -4 -19 -10z"
        />
        <path
          d="M5058 1013 c15 -6 17 -143 2 -143 -5 0 -10 -3 -10 -8 0 -4 19 -8 42
-8 36 -1 39 0 25 11 -22 16 -25 145 -4 145 36 0 51 -61 20 -79 -15 -9 -15 -10
-2 -11 39 0 65 47 43 79 -13 17 -25 21 -72 20 -31 0 -51 -3 -44 -6z"
        />
        <path
          d="M5488 1013 c15 -6 17 -134 2 -149 -7 -7 2 -10 25 -10 19 0 35 4 35 8
0 4 -4 8 -10 8 -13 0 -13 47 0 55 5 3 20 -10 32 -29 20 -31 62 -55 75 -43 2 2
-13 21 -33 41 -34 34 -36 38 -20 55 38 42 12 71 -63 70 -31 0 -50 -3 -43 -6z
m80 -12 c22 -13 11 -56 -15 -59 -19 -3 -23 1 -23 26 0 40 12 50 38 33z"
        />
        <path
          d="M5676 1004 c-9 -25 2 -30 18 -9 8 11 20 16 30 12 23 -9 24 -137 1
-137 -8 0 -15 -4 -15 -9 0 -5 18 -8 40 -7 22 0 40 4 40 8 0 5 -4 8 -10 8 -6 0
-10 30 -10 70 0 63 2 70 20 70 11 0 20 -7 20 -15 0 -8 5 -15 10 -15 6 0 10 9
10 20 0 18 -7 20 -74 20 -59 0 -75 -3 -80 -16z"
        />
        <path
          d="M6460 1010 c21 -13 15 -129 -8 -146 -14 -10 -10 -12 23 -12 34 0 37
2 23 12 -14 10 -16 23 -11 66 l6 53 25 -62 c13 -33 27 -61 31 -61 3 0 18 26
33 58 l26 57 1 -51 c1 -27 -3 -54 -9 -60 -6 -6 5 -10 32 -10 36 -1 39 0 25 11
-21 15 -25 140 -4 148 6 3 0 6 -14 6 -23 1 -30 -7 -50 -54 -13 -30 -26 -55
-29 -55 -3 0 -16 25 -29 55 -21 50 -26 55 -55 55 -23 0 -27 -3 -16 -10z"
        />
        <path
          d="M6728 1013 c15 -6 17 -143 2 -143 -5 0 -10 -3 -10 -8 0 -4 19 -8 42
-8 36 -1 39 0 25 11 -22 16 -25 145 -4 145 36 0 51 -61 20 -79 -15 -9 -15 -10
-2 -11 39 0 65 47 43 79 -13 17 -25 21 -72 20 -31 0 -51 -3 -44 -6z"
        />
        <path
          d="M6940 963 c-11 -32 -29 -70 -40 -84 l-18 -26 29 0 c27 0 40 11 20 19
-16 6 8 48 28 48 23 0 44 -31 36 -52 -4 -10 4 -14 29 -14 32 0 34 2 21 16 -8
9 -25 45 -38 80 -14 35 -29 66 -35 68 -6 2 -20 -23 -32 -55z m36 -30 c-2 -3
-11 -3 -19 -1 -11 2 -12 9 -5 28 l10 25 9 -24 c5 -13 7 -26 5 -28z"
        />
        <path
          d="M7108 1013 c15 -6 17 -143 2 -143 -5 0 -10 -4 -10 -8 0 -5 14 -9 30
-9 17 0 30 4 30 9 0 4 -4 8 -10 8 -5 0 -10 25 -10 56 l0 57 51 -67 c28 -36 55
-66 60 -66 5 0 9 33 9 73 0 43 5 78 12 85 9 9 4 12 -22 12 -30 0 -32 -1 -16
-13 13 -10 17 -24 14 -61 l-3 -49 -48 62 c-39 49 -54 61 -75 60 -15 0 -21 -3
-14 -6z"
        />
        <path
          d="M7333 1009 c21 -13 57 -81 57 -110 0 -12 -8 -27 -17 -34 -14 -11 -11
-12 25 -11 23 0 42 4 42 8 0 5 -4 8 -10 8 -32 0 15 128 52 143 7 3 -3 5 -22 5
-19 0 -29 -2 -23 -5 10 -3 9 -11 -2 -34 -9 -16 -19 -29 -24 -29 -11 0 -32 50
-26 61 4 5 -11 9 -32 9 -31 -1 -35 -3 -20 -11z"
        />
        <path
          d="M97 563 c-34 -34 -141 -33 5124 -33 4881 0 5130 1 5127 18 -3 16
-256 17 -5124 20 -2816 1 -5124 -1 -5127 -5z"
        />
        <path
          d="M3040 180 c0 -101 3 -141 11 -138 7 2 12 32 13 73 1 65 3 70 24 73
12 2 22 7 22 12 0 5 -10 10 -22 12 -20 3 -23 9 -24 52 0 28 -6 52 -12 54 -9 3
-12 -35 -12 -138z"
        />
        <path
          d="M3530 310 c0 -5 23 -10 50 -10 28 0 50 5 50 10 0 6 -22 10 -50 10
-27 0 -50 -4 -50 -10z"
        />
        <path
          d="M4000 285 c0 -31 -3 -35 -25 -35 -16 0 -25 -6 -25 -15 0 -9 9 -15 25
-15 24 0 25 -3 25 -55 0 -30 5 -55 10 -55 6 0 10 42 10 105 0 63 -4 105 -10
105 -5 0 -10 -16 -10 -35z"
        />
        <path
          d="M4540 180 c0 -87 4 -140 10 -140 6 0 10 53 10 140 0 87 -4 140 -10
140 -6 0 -10 -53 -10 -140z"
        />
        <path
          d="M4810 290 c0 -26 -4 -30 -26 -30 -16 0 -34 10 -46 25 -40 51 -118 23
-118 -42 0 -65 81 -87 122 -33 28 39 62 37 66 -2 7 -56 22 -26 22 42 0 40 -4
70 -10 70 -5 0 -10 -13 -10 -30z m-92 -12 c27 -27 7 -78 -29 -78 -21 0 -49 26
-49 45 0 35 53 58 78 33z"
        />
        <path
          d="M5239 283 c-33 -53 -37 -137 -11 -189 18 -35 52 -75 52 -61 0 2 -10
26 -21 52 -33 74 -27 138 19 228 2 4 -1 7 -6 7 -6 0 -21 -17 -33 -37z"
        />
        <path
          d="M5662 279 c12 -23 23 -62 26 -89 4 -43 -5 -72 -46 -153 -2 -4 3 -5
11 -2 19 8 54 72 62 117 8 41 -7 98 -37 141 -29 41 -40 32 -16 -14z"
        />
        <path
          d="M7247 313 c-4 -3 -7 -66 -7 -140 0 -112 2 -133 15 -133 13 0 15 22
15 140 0 128 -4 152 -23 133z"
        />
        <path
          d="M7820 270 l0 -50 -39 0 c-55 0 -61 -18 -9 -22 l43 -3 3 -77 c5 -135
22 -87 22 62 0 87 -4 140 -10 140 -5 0 -10 -22 -10 -50z"
        />
        <path
          d="M2350 300 c0 -6 28 -10 63 -10 l62 0 -25 -20 c-14 -10 -46 -25 -73
-32 -32 -8 -44 -15 -37 -22 7 -7 26 -5 59 7 44 15 52 16 84 2 40 -17 67 -19
67 -7 0 5 -19 14 -41 21 l-41 12 28 30 28 29 -87 0 c-51 0 -87 -4 -87 -10z"
        />
        <path d="M2885 300 c-3 -6 0 -13 9 -16 23 -9 69 0 74 14 5 15 -74 17 -83 2z" />
        <path
          d="M3298 280 c-9 -16 -33 -37 -52 -47 -20 -9 -36 -20 -36 -25 0 -16 35
-7 71 18 l37 25 37 -25 c40 -27 60 -32 70 -17 3 5 -10 14 -29 21 -41 13 -71
44 -63 65 11 27 -18 16 -35 -15z"
        />
        <path
          d="M4109 305 c0 -3 -1 -24 -1 -47 l-1 -43 86 -3 87 -3 0 51 0 50 -85 0
c-47 0 -85 -2 -86 -5z m141 -45 l0 -30 -60 0 -60 0 0 30 0 30 60 0 60 0 0 -30z"
        />
        <path
          d="M5375 300 c-9 -15 19 -22 70 -18 25 2 45 0 45 -4 0 -10 -61 -44 -103
-58 -28 -9 -36 -15 -28 -23 8 -8 24 -5 59 9 47 20 48 20 91 1 30 -13 46 -16
54 -8 7 7 5 12 -9 16 -10 4 -30 11 -43 16 l-24 10 27 27 c14 16 26 31 26 35 0
11 -158 8 -165 -3z"
        />
        <path
          d="M3810 290 c0 -5 23 -10 50 -10 28 0 50 -3 50 -6 0 -16 -44 -65 -76
-85 -24 -15 -32 -25 -25 -32 7 -7 21 -2 45 16 42 32 48 33 71 7 18 -20 45 -27
45 -12 0 5 -14 18 -30 30 -29 22 -30 24 -15 52 24 46 20 50 -50 50 -37 0 -65
-4 -65 -10z"
        />
        <path
          d="M5951 274 c-12 -15 -21 -35 -21 -46 0 -23 43 -68 65 -68 10 0 15 -10
15 -30 l0 -30 -61 0 c-37 0 -58 -4 -54 -10 8 -13 255 -13 255 0 0 6 -25 10
-55 10 -54 0 -55 1 -55 29 0 22 6 31 29 41 52 21 59 94 12 119 -11 6 -40 11
-65 11 -36 0 -48 -5 -65 -26z m121 -11 c10 -9 18 -24 18 -34 0 -21 -36 -49
-65 -49 -29 0 -65 28 -65 50 0 46 74 68 112 33z"
        />
        <path
          d="M6510 225 l0 -75 35 0 c31 0 35 -3 35 -25 0 -24 -3 -25 -55 -25 -30
0 -55 -4 -55 -10 0 -6 48 -10 125 -10 77 0 125 4 125 10 0 6 -25 10 -55 10
-52 0 -55 1 -55 25 0 23 4 25 40 25 74 0 41 19 -37 22 -70 3 -78 5 -78 23 0
18 8 20 73 23 92 4 86 22 -7 22 -53 0 -68 3 -68 15 0 12 15 15 74 15 58 0 73
3 73 15 0 12 -16 15 -85 15 l-85 0 0 -75z"
        />
        <path
          d="M7057 294 c-14 -14 6 -24 49 -24 40 0 44 -2 44 -25 0 -23 -4 -25 -45
-25 l-45 0 0 -55 0 -55 73 0 c39 0 79 5 87 10 18 12 12 13 -73 10 l-68 -2 3
33 c3 31 5 33 48 33 l45 1 0 50 0 50 -55 3 c-31 2 -59 0 -63 -4z"
        />
        <path
          d="M2620 280 c0 -6 32 -10 76 -10 l77 0 -7 -65 c-4 -40 -3 -65 3 -65 12
0 18 20 26 93 l6 57 -90 0 c-54 0 -91 -4 -91 -10z"
        />
        <path
          d="M3495 280 c-4 -6 17 -10 57 -10 57 -1 61 -2 44 -15 -10 -8 -38 -17
-62 -21 -25 -3 -47 -11 -51 -17 -4 -7 10 -8 43 -3 67 10 73 10 122 1 29 -5 42
-4 42 4 0 6 -9 11 -19 11 -11 0 -27 3 -36 6 -14 6 -13 8 5 21 11 8 20 18 20
24 0 12 -157 12 -165 -1z"
        />
        <path
          d="M4350 280 c0 -5 23 -10 50 -10 38 0 50 -4 50 -15 0 -28 -29 -72 -70
-108 -52 -44 -35 -60 19 -18 40 32 67 77 77 130 l7 31 -67 0 c-37 0 -66 -4
-66 -10z"
        />
        <path
          d="M7630 200 l0 -90 53 0 c60 0 97 8 97 22 0 5 -26 7 -57 6 -32 -2 -59
0 -60 5 -2 4 -3 34 -3 67 l0 60 50 0 c28 0 50 5 50 10 0 6 -28 10 -65 10 l-65
0 0 -90z"
        />
        <path
          d="M2860 250 c0 -5 23 -10 50 -10 l51 0 -15 -28 c-8 -16 -33 -45 -55
-65 -23 -20 -41 -39 -41 -42 0 -14 29 -2 61 25 l36 29 23 -24 c21 -22 50 -34
50 -20 0 3 -14 18 -31 33 -30 27 -30 27 -15 64 9 21 16 40 16 43 0 3 -29 5
-65 5 -37 0 -65 -4 -65 -10z"
        />
        <path
          d="M2680 150 l0 -50 -50 0 c-27 0 -50 -4 -50 -10 0 -6 50 -10 130 -10
136 0 183 17 63 22 l-68 3 0 48 c0 31 -4 47 -12 47 -9 0 -13 -17 -13 -50z"
        />
        <path
          d="M3454 188 c-6 -10 -3 -10 64 -8 41 1 52 -2 52 -14 0 -12 -10 -16 -34
-16 -19 0 -38 -4 -41 -10 -4 -6 23 -10 74 -10 47 0 81 -4 81 -10 0 -6 -32 -10
-75 -10 l-75 0 0 -35 0 -35 90 0 c53 0 90 4 90 10 0 6 -34 10 -81 10 -60 0
-80 3 -77 13 3 8 30 13 76 15 l72 3 0 29 c0 28 -3 30 -35 30 -25 0 -35 4 -35
16 0 13 11 15 58 13 31 -2 57 1 57 7 0 11 -254 14 -261 2z"
        />
        <path
          d="M2310 180 c0 -6 27 -10 60 -10 47 0 60 -3 60 -15 0 -8 -8 -15 -18
-15 -26 0 -62 -29 -62 -50 0 -28 39 -50 90 -50 51 0 90 22 90 50 0 21 -36 50
-62 50 -10 0 -18 7 -18 15 0 12 13 15 60 15 33 0 60 4 60 10 0 6 -50 10 -130
10 -80 0 -130 -4 -130 -10z m174 -66 c9 -3 16 -14 16 -24 0 -32 -80 -42 -116
-15 -19 15 -19 15 0 30 20 15 72 20 100 9z"
        />
        <path
          d="M4060 170 c0 -5 28 -10 63 -12 59 -3 62 -4 65 -30 4 -38 22 -36 22 2
l0 30 61 0 c37 0 58 4 54 10 -8 13 -265 13 -265 0z"
        />
        <path
          d="M3190 150 c0 -6 26 -10 58 -10 l58 0 0 -47 c-1 -28 4 -48 12 -50 8
-3 12 10 12 46 l0 51 55 0 c30 0 55 5 55 10 0 6 -48 10 -125 10 -77 0 -125 -4
-125 -10z"
        />
        <path
          d="M4662 104 l3 -56 83 -1 82 -2 0 58 c0 52 -14 79 -22 41 -5 -25 -115
-26 -122 -1 -2 9 -9 17 -16 17 -7 0 -10 -19 -8 -56z m148 -19 c0 -12 -13 -15
-60 -15 -47 0 -60 3 -60 15 0 12 13 15 60 15 47 0 60 -3 60 -15z"
        />
        <path
          d="M5330 150 c0 -6 27 -10 60 -10 l60 0 0 -51 c0 -66 24 -62 28 4 l3 47
55 0 c29 0 54 5 54 10 0 6 -50 10 -130 10 -80 0 -130 -4 -130 -10z"
        />
        <path
          d="M3857 123 c-4 -3 -7 -21 -7 -40 l0 -33 90 0 c53 0 90 4 90 10 0 6
-32 10 -75 10 l-75 0 0 30 c0 29 -9 38 -23 23z"
        />
        <path
          d="M4109 125 c0 -3 -1 -21 -1 -41 l-1 -36 89 0 c55 0 90 4 92 11 2 7
-23 11 -77 11 l-81 0 0 30 c0 17 -4 30 -10 30 -5 0 -10 -2 -11 -5z"
        />
      </g>
    </svg>
  );
};

export default CompanyIcon;
