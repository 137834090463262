/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
// Redux
import { useTypedDispatch, useTypedSelector } from "@/common/lib/redux/store";
import { loadingOn, loadingOff } from "@/common/lib/redux/actions/commonAction";
import { setParams } from "@/common/lib/redux/actions/searchAction";
// Components
import { Theme, Typography } from "@mui/material";
import {
  GeneralListItem,
  GeneralListItemSkeleton,
} from "@/browser/components/vehicle/list";
import { Checkbox, Dropdown, Pagination } from "@/common/components";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// API
import { getVehicleList } from "@/common/lib/api/vehicle";
// Libs
import { getUTCDate, isNull } from "@/common/lib/common";
import { browserViewList, sortList } from "@/common/lib/varList";

const styles = (theme: Theme) => ({
  topDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& > div": {
      display: "flex",
      alignItems: "flex-end",
    },
  },
  midDiv: {
    borderTop: "1px solid " + theme.palette.divider,
    borderBottom: "1px solid " + theme.palette.divider,
    paddingTop: 20,
    paddingBottom: 20,
    marginTop: 15,
    marginBottom: 15,
  },
  itemList: {
    display: "flex",
    flexDirection: "column",
    listStyle: "none",
    userSelect: "none",
    margin: 0,
    padding: 0,
    "& > li": {
      position: "relative",
      margin: "10px 0",
      "&.noData": {
        fontSize: "1.15rem",
        fontWeight: "bold",
        textAlign: "center",
      },
    },
    "& > li:first-child": {
      marginTop: 0,
    },
    "& > li:last-child": {
      marginBottom: 0,
    },
  },
  title: {
    fontSize: 22,
    fontWeight: 500,
    cursor: "default",
    userSelect: "none",
  },
  checkboxFormControl: {
    "& > .MuiCheckbox-root": {
      padding: 6,
    },
    "& > .MuiTypography-root": {
      fontSize: 14,
    },
  },
  checkbox: {
    "& > svg": {
      fontSize: 20,
    },
  },
  dropdownFormControl: {
    marginLeft: 25,
    width: 135,
    fontSize: "14px !important",
  },
  dropdownMenuItem: {
    fontSize: "14px !important",
  },
});

const GeneralListContainer = () => {
  const classes = useStyles(styles);
  const { t } = useTranslation();
  const { loading } = useTypedSelector((state) => state.common);
  const { params } = useTypedSelector((state) => state.search);
  const dispatch = useTypedDispatch();
  const [init, setInit] = useState(false);

  const [page, setPage] = useState(1);
  const [view, setView] = useState("");
  const [sort, setSort] = useState("");
  const [itemList, setItemList] = useState<VehicleData[]>([]);

  const [dot, setDot] = useState(1);
  const [count, setCount] = useState(0);
  const [totalPage, setTotalPage] = useState(0);

  const [checkPromote, setCheckPromote] = useState(false);

  const vehicleListQuery = useQuery(
    "vehicleList",
    () => {
      const size = isNull(view) ? 100 : Number(view) * 10;
      const align = isNull(sort) ? "-modified" : sort;
      const listParams = {
        ...params,
      };
      dispatch(loadingOn());
      return getVehicleList(dot, size, align, listParams);
    },
    {
      retry: false,
      staleTime: 1000,
      onSuccess: (response) => {
        setItemList(response.data.results);
        setCount(response.data.count["total"]);
      },
      onSettled: () => {
        dispatch(loadingOff());
      },
    }
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    const size = isNull(view) ? 10 : Number(view);
    setDot(Math.floor((page - 1) / size) + 1);
  }, [page]);

  useEffect(() => {
    const size = isNull(view) ? 10 : Number(view);
    setTotalPage(Math.ceil(count / size));
  }, [view, count]);

  useEffect(() => {
    setPage(1);
  }, [view, sort, checkPromote]);

  useEffect(() => {
    if (checkPromote) {
      dispatch(
        setParams({
          ...params,
          criteria: JSON.stringify([
            {
              type: "DISCOUNT_EXPIRED",
              period: getUTCDate(new Date()) + "~2099-12-31T23:59",
            },
          ]),
        })
      );
    } else {
      const data = { ...params };
      if (Object.keys(params).includes("criteria")) {
        delete data["criteria"];
      }
      dispatch(setParams({ ...data }));
    }
  }, [checkPromote]);

  useEffect(() => {
    if (init) {
      vehicleListQuery.refetch();
    }
  }, [view, sort, dot, params]);

  useEffect(() => {
    if (vehicleListQuery.isSuccess) {
      setInit(true);
    }
  }, [vehicleListQuery.isLoading]);

  return (
    <>
      <div className={classes.topDiv}>
        <Typography className={classes.title}>
          {t("LIST.GENERAL.TITLE")}
        </Typography>
        <div>
          <Checkbox
            label={t("LIST.GENERAL.ONLY_VIEW_PROMOTE")}
            value={checkPromote}
            dispatch={setCheckPromote}
            formControlClassName={classes.checkboxFormControl}
            checkboxClassName={classes.checkbox}
          />
          <Dropdown
            label={t("DROPDOWN.ALIGN.LABEL")}
            value={sort}
            options={sortList}
            dispatch={setSort}
            formControlClassName={classes.dropdownFormControl}
            menuItemClassName={classes.dropdownMenuItem}
          />
          <Dropdown
            label={t("DROPDOWN.VIEW.LABEL")}
            value={view}
            options={browserViewList}
            dispatch={setView}
            formControlClassName={classes.dropdownFormControl}
            menuItemClassName={classes.dropdownMenuItem}
          />
        </div>
      </div>
      <div className={classes.midDiv}>
        {loading ? (
          <ul className={classes.itemList}>
            {[...Array(10).keys()].map((idx) => {
              return (
                <li
                  key={
                    "skeleton" +
                    (isNull(view) ? 10 : Number(view)) * (page - 1) +
                    idx
                  }
                >
                  <GeneralListItemSkeleton />
                </li>
              );
            })}
          </ul>
        ) : (
          <ul className={classes.itemList}>
            {itemList.length > 0 ? (
              itemList
                .slice(
                  (isNull(view) ? 10 : Number(view)) * ((page - 1) % 10),
                  (isNull(view) ? 10 : Number(view)) * (((page - 1) % 10) + 1)
                )
                .map((item, idx) => {
                  return (
                    <li
                      key={
                        (isNull(view) ? 10 : Number(view)) * (page - 1) + idx
                      }
                    >
                      <GeneralListItem data={item} />
                    </li>
                  );
                })
            ) : (
              <li className="noData">{t("LIST.GENERAL.MSG.NO_DATA")}</li>
            )}
          </ul>
        )}
      </div>
      <div className={classes.botDiv}>
        <Pagination page={page} totalPage={totalPage} setPage={setPage} />
      </div>
    </>
  );
};

export default GeneralListContainer;
