import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
// Redux
import { setAccessURL } from "@/common/lib/redux/actions/commonAction";
import { useTypedSelector, useTypedDispatch } from "@/common/lib/redux/store";
// Components
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Theme,
  Typography,
} from "@mui/material";
import { CompanyLogoIcon } from "@/common/icons";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// Libs
import { isNull, isNotNull } from "@/common/lib/common";

const styles = (theme: Theme) => ({
  dialog: {
    maxWidth: 900,
    width: "fit-content",
    height: "fit-content",
    padding: "12px 45px 16px",
    borderRadius: 10,
    border: "2.5px solid " + theme.palette.logo.blue,
    userSelect: "none",
  },
  logo: {
    width: 250,
    fill: theme.palette.logo.blue,
  },
  text: {
    fontWeight: "bold",
    "& > span > span": {
      color: theme.palette.logo.blue,
    },
  },
});

const NotLoginOnlyPage = ({ ...props }: { [key: string]: any }) => {
  const classes = useStyles(styles);
  const [confirm, setConfirm] = useState(false);
  const { loginToken } = useTypedSelector((state) => state.auth);
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    if (confirm && isNotNull(loginToken)) {
      dispatch(setAccessURL(location.pathname));
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirm, loginToken]);

  return (
    <>
      {isNull(loginToken) && props.children}
      <Dialog
        classes={{ paper: classes.dialog }}
        open={!confirm && isNotNull(loginToken)}
        onClose={() => setConfirm(true)}
      >
        <DialogTitle sx={{ textAlign: "center" }}>
          <CompanyLogoIcon className={classes.logo} />
        </DialogTitle>
        <DialogContent sx={{ textAlign: "center" }}>
          <Typography variant="h6" className={classes.text}>
            <Markup content={t("DIALOG.CONTENT.NOT_LOGIN_ONLY_PAGE")} />
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default NotLoginOnlyPage;
