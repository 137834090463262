import { AnyAction } from "redux";

import { SET_PARAMS, REFRESH_ON, REFRESH_OFF } from "../types/searchType";

interface stateType {
  params: { [key: string]: string };
  isRefresh: boolean;
}

const initialState: stateType = {
  params: {},
  isRefresh: true,
};

const searchReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_PARAMS:
      return {
        ...state,
        params: action.payload,
      };
    case REFRESH_ON:
      return {
        ...state,
        isRefresh: false,
      };
    case REFRESH_OFF:
      return {
        ...state,
        isRefresh: true,
      };
    default:
      return state;
  }
};

export default searchReducer;
