import { useTranslation } from "react-i18next";
// Components
import { Breadcrumbs, Link, Theme, Typography } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import BasePage from "@/mobile/pages/common/BasePage";
import { SNSFab } from "@/mobile/components/common";
import {
  GeneralListContainer,
  RecommendListContainer,
  SearchContainer,
} from "@/mobile/components/vehicle/list";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
import { colors } from "@/common/lib/style/palette";

const styles = (theme: Theme) => ({
  basePage: {
    padding: "2vh 5vw",
  },
  title: {
    paddingBottom: "0.75vh",
    borderBottom: "1px solid " + colors.gray[7],
    marginBottom: "2vh",
    "& > p": {
      fontSize: "1.75rem",
      fontWeight: 600,
      fontFamily: '"Exo 2"',
      textAlign: "center",
      color: theme.palette.primary.main,
      cursor: "default",
      userSelect: "none",
      whiteSpace: "nowrap",
      margin: "-1vh -5vw 1vh",
    },
    "& > nav": {
      display: "flex",
      justifyContent: "flex-end",
      "& .MuiLink-root": {
        display: "flex",
        alignItems: "center",
        fontSize: "0.85rem",
        fontWeight: 500,
        color: colors.gray[7],
        "& > svg": {
          fontSize: "1.35rem",
          marginRight: "1vw",
        },
      },
    },
  },
});

const ListPage = () => {
  const classes = useStyles(styles);
  const { t } = useTranslation();

  return (
    <BasePage className={classes.basePage}>
      <div className={classes.title}>
        <Typography>{t("LIST.TITLE")}</Typography>
        <Breadcrumbs>
          <Link underline="hover" color="inherit" href="/">
            <HomeIcon />
            Home
          </Link>
          <Link underline="hover" color="inherit" href="/vehicle/list">
            Products List
          </Link>
        </Breadcrumbs>
      </div>
      <SearchContainer />
      <RecommendListContainer />
      <GeneralListContainer />
      <SNSFab />
    </BasePage>
  );
};

export default ListPage;
