/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useTranslation } from "react-i18next";
// Redux
import { useTypedDispatch } from "@/common/lib/redux/store";
import { loadingOn, loadingOff } from "@/common/lib/redux/actions/commonAction";
// Components
import { FavoriteButton, Image, Input } from "@/common/components";
import WishListTable from "./WishListTable";
import { Theme, Typography, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// API
import { onError } from "@/common/lib/api/common";
import { getWishList, patchWishInfo } from "@/common/lib/api/wish";
// Libs
import {
  openNewTab,
  getAutowiniCommission,
  getAutowiniBusCommission,
} from "@/common/lib/common";

const styles = (theme: Theme) => ({
  searchDiv: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 7.5,
    "& > div": {
      width: 285,
    },
  },
  titleDiv: {
    position: "relative",
    display: "flex",
    "& > img": {
      width: "27.5%",
      aspectRatio: "1.33",
      borderRadius: 5,
      marginRight: 12.5,
    },
    "& > div": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      width: "72.5%",
      paddingTop: 5,
    },
  },
  table: {
    width: "100%",
  },
  title: {
    display: "table-cell",
    fontSize: "1.05rem",
    fontWeight: "bold",
    lineHeight: "1.25",
    "&:hover": {
      color: theme.palette.primary.main,
      cursor: "pointer",
    },
  },
  advertText: {
    fontSize: "0.875rem",
    lineHeight: "1",
  },
  favorite: {
    position: "absolute",
    top: -8,
    right: -10.5,
    "& > svg": {
      fontSize: "1.85rem",
    },
  },
  memo: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    width: "100%",
    height: "1.65em",
    marginBottom: 3.5,
    fontSize: "0.85rem",
    fontFamily: "'Noto Sans', 'Noto Sans KR', 'Roboto', sans-serif",
    color: theme.palette.text.secondary,
    "& > textarea:nth-child(1)": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "inherit",
      height: "inherit",
      border: "1px solid " + theme.palette.text.disabled,
      padding: "0 5px",
      resize: "none",
      overflow: "hidden",
      transition: "height 200ms ease-in-out",
      fontSize: "inherit",
      fontFamily: "inherit",
      color: "inherit",
      zIndex: 2,
      "&:focus-visible": {
        height: "4.75em",
        "& ~ span": {
          opacity: 1,
        },
      },
    },
    "& > textarea:nth-child(2)": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "inherit",
      height: "inherit",
      border: "1px solid " + theme.palette.text.disabled,
      padding: "0 5px",
      resize: "none",
      overflow: "hidden",
      transition: "height 200ms ease-in-out",
      fontSize: "inherit",
      fontFamily: "inherit",
      color: "inherit",
      zIndex: 1,
      opacity: 0,
    },
    "& > span": {
      pointerEvents: "none",
      position: "absolute",
      right: 10,
      bottom: "-2.85em",
      color: theme.palette.text.secondary,
      transition: "opacity 200ms ease-in-out",
      zIndex: 2,
      opacity: 0,
    },
  },
  date: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "2.5px 7.5px",
    "& > svg": {
      fontSize: "1.25rem",
      marginRight: 3,
    },
    "& > p": {
      fontSize: "0.875rem",
      fontWeight: 500,
    },
  },
});

interface DataType {
  id: number;
  uid: string;
  image: string;
  title: string;
  advertText: string;
  price: number;
  status: string;
  wishDate: Date;
  memo: string;
}

const WishListContainer = () => {
  const classes = useStyles(styles);
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();

  const inputRef = useRef<HTMLTextAreaElement[]>([]);

  const [searchText, setSearchText] = useState("");
  const [newWishList, setNewWishList] = useState<number[]>([]);

  const getMainTitle = (data: any) => {
    const titles = [];
    titles.push(data.product.year);
    titles.push(data.product.maker);
    titles.push(data.product.detail_model);
    if (data.product.type === "CAR") {
    } else if (data.product.type === "TRUCK") {
      titles.push(data.truck_product.tonner.toFixed(1) + "t");
      if (
        typeof data.truck_product.engine_type !== "undefined" &&
        data.truck_product.engine_type !== null
      ) {
        titles.push(data.truck_product.engine_type);
      }
      if (
        typeof data.truck_product.cabin_type !== "undefined" &&
        data.truck_product.cabin_type !== null
      ) {
        titles.push(data.truck_product.cabin_type);
      }
    } else if (data.product.type === "BUS") {
    }
    return titles.join(" ");
  };

  const wishListQuery = useQuery(
    "wishList",
    () => {
      dispatch(loadingOn());
      return getWishList();
    },
    {
      onSuccess: () => {
        dispatch(loadingOff());
      },
      onError: () => {
        dispatch(loadingOff());
      },
    }
  );
  const wishInfoMutation = useMutation(patchWishInfo, {
    onError: (error) => {
      onError(error);
    },
  });

  const SearchFilter = ({ column: { setFilter } }: any) => {
    useEffect(() => {
      setFilter(searchText);
    }, [searchText]);

    return null;
  };

  const columns: any = [
    {
      Header: "Prod.No",
      accessor: "uid",
      Filter: SearchFilter,
      filter: "search",
    },
    {
      Header: "Title",
      accessor: "title",
      Cell: (e: any) => {
        const [init, setInit] = useState(false);
        const [memo, setMemo] = useState(e.row.original["memo"]);

        const handleOnChange = (evt: any) => {
          setMemo(evt.target.value);
        };
        const handleOnBlur = async (evt: any) => {
          if (e.row.original["memo"] !== evt.target.value) {
            setTimeout(() => {
              e.updateData(e.row.index, "memo", evt.target.value);
              wishInfoMutation.mutate({
                id: e.row.original["id"],
                data: {
                  memo: evt.target.value,
                },
              });
            }, 200);
          }
        };

        useEffect(() => {
          if (init) {
            const lines = Math.ceil(
              inputRef.current[e.row.original["id"] - 1].scrollHeight /
                inputRef.current[e.row.original["id"] - 1].clientHeight
            );
            if (lines >= 4 || memo.length > 100) {
              setMemo(memo.slice(0, -1));
            }
          }
        }, [memo]);
        useEffect(() => {
          setInit(true);
        }, []);

        return (
          <div className={classes.titleDiv}>
            <Image alt="" src={e.row.original["image"]} />
            <div>
              <div>
                <Typography
                  variant="caption"
                  className={classes.title}
                  onClick={() =>
                    openNewTab("/vehicle/detail/" + e.row.original["uid"])
                  }
                >
                  {e.value}
                </Typography>
                <Typography variant="caption" className={classes.advertText}>
                  {e.row.original["advertText"]}
                </Typography>
              </div>
              <div>
                <div className={classes.memo}>
                  <textarea
                    wrap="hard"
                    value={memo}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    spellCheck="false"
                    placeholder="Space for notes"
                  />
                  <textarea
                    ref={(element) => {
                      if (element !== null) {
                        inputRef.current[e.row.original["id"] - 1] = element;
                      }
                    }}
                    wrap="hard"
                    value={memo}
                    onChange={handleOnChange}
                    spellCheck="false"
                  />
                  <span>{memo.length}/100 Byte</span>
                </div>
                <div className={classes.date}>
                  <AccessTimeIcon />
                  <Typography>
                    Wish Date :{" "}
                    {moment(e.row.original["wishDate"])
                      .local()
                      .format("YYYY-MM-DD")}
                  </Typography>
                </div>
              </div>
            </div>
            <FavoriteButton
              className={classes.favorite}
              confirm={true}
              wish_id={
                e.row.original["id"] !== undefined ? e.row.original["id"] : null
              }
              product_uid={e.row.original["uid"]}
            />
          </div>
        );
      },
      Filter: SearchFilter,
      filter: "search",
    },
    {
      Header: "Price",
      accessor: "price",
      disableFilters: true,
    },
    {
      Header: "Status",
      accessor: "status",
      disableFilters: true,
    },
    {
      Header: "Wish\nDate",
      accessor: (data: any) => {
        return moment(data.wishDate).local().format("YYYY-MM-DD");
      },
      disableFilters: true,
    },
  ];
  const [data, setData] = useState<DataType[]>([]);
  const updateData = (rowIndex: number, columnId: string, value: any) => {
    setData((prevData) =>
      prevData.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...prevData[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };
  const filterTypes = {
    search: (rows: any[], _id: any, filterValue: any) => {
      return rows.filter((val) => {
        const uidValue_Low = String(val.original["uid"]).toLowerCase();
        const titleValue_Low = String(val.original["title"]).toLowerCase();
        const memoValue_Low = String(val.original["memo"]).toLowerCase();
        const filterValue_Low = String(filterValue).toLocaleLowerCase();

        if (
          uidValue_Low.includes(filterValue_Low) ||
          titleValue_Low.includes(filterValue_Low) ||
          memoValue_Low.includes(filterValue_Low)
        ) {
          return true;
        } else {
          return false;
        }
      });
    },
  };

  useEffect(() => {
    const storageListener = (event: StorageEvent) => {
      const newValue_String = event.newValue;

      const regex = /^{"wishList":.*/;
      if (newValue_String !== null && regex.test(newValue_String)) {
        const newValue_JSON = JSON.parse(
          newValue_String
            .replaceAll('\\"', '"')
            .replaceAll('"{', "{")
            .replaceAll('}"', "}")
            .replaceAll('"[', "[")
            .replaceAll(']"', "]")
        );
        if (Object.keys(newValue_JSON).includes("wishList")) {
          setNewWishList(newValue_JSON["wishList"]);
        }
      }
    };

    window.addEventListener("storage", storageListener);
    return () => {
      window.removeEventListener("storage", storageListener);
    };
  }, []);

  useEffect(() => {
    wishListQuery.refetch();
  }, [newWishList]);

  useEffect(() => {
    if (wishListQuery.isSuccess) {
      const result: DataType[] = [];
      wishListQuery.data.data.results.forEach((data: any) => {
        let isDiscount = false;
        if (data.product.discount_expired !== null) {
          const todayDate = new Date();
          const expireDate = new Date(data.sales.discount_expired);
          expireDate.setHours(expireDate.getHours() + 9);

          if (expireDate >= todayDate) {
            isDiscount = true;
          }
        }
        result.push({
          id: data.id,
          uid: data.product.uid,
          image: data.image,
          title: getMainTitle(data),
          advertText: data.sales.advert_text,
          price: isDiscount
            ? data.sales.price +
              (data.product.type === "CAR" || data.product.type === "TRUCK"
                ? getAutowiniCommission(data.sales.price)
                : getAutowiniBusCommission(data.sales.price)) -
              (data.sales.discount !== null ? data.sales.discount : 0)
            : data.sales.price +
              (data.product.type === "CAR" || data.product.type === "TRUCK"
                ? getAutowiniCommission(data.sales.price)
                : getAutowiniBusCommission(data.sales.price)),
          status: data.sales.status,
          wishDate: new Date(data.created),
          memo: data.memo,
        });
      });
      setData(result);
    }
  }, [wishListQuery.isLoading, wishListQuery.isRefetching]);

  return (
    <div>
      <div className={classes.searchDiv}>
        <Input
          isClearable
          variant="standard"
          value={searchText}
          dispatch={setSearchText}
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <WishListTable
        className={classes.table}
        columns={columns}
        data={data}
        updateData={updateData}
        filterTypes={filterTypes}
        noDataText={t("MY_LIST.WISH_LIST.MSG.NO_DATA")}
      />
    </div>
  );
};

export default WishListContainer;
