// Styles
import { useStyles } from "@/common/lib/style/hooks";
import { colors } from "@/common/lib/style/palette";

const styles = () => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "1rem",
    height: "1rem",
    borderRadius: "50%",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontFamily: '"Noto Sans"',
    color: "white",
    lineHeight: "1",
    userSelect: "none",
    "&.X": {
      backgroundColor: colors.red[5],
    },
    "&.W": {
      backgroundColor: colors.cyan[5],
    },
    "&.C": {
      backgroundColor: colors.orange[5],
    },
    "&.A": {
      backgroundColor: colors.teal[6],
    },
    "&.U": {
      backgroundColor: colors.violet[6],
    },
    "&.T": {
      backgroundColor: colors.gray[7],
    },
  },
});

// X : 교환(교체)   W : 판금/용접   C : 부식
// A : 흠집        U : 요철        T : 손상
const StatusMark = ({ status, ...props }: any) => {
  const classes = useStyles(styles);

  return (
    <div
      className={
        props.className !== undefined
          ? `${classes.container} ${status} ${props.className}`
          : `${classes.container} ${status}`
      }
    >
      {status}
    </div>
  );
};

export default StatusMark;
