/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState, ReactNode } from "react";
// Components
import { CircularProgress, Theme } from "@mui/material";
// Styles
import { useStyles } from "@/common/lib/style/hooks";

const styles = (theme: Theme) => ({
  container: {
    margin: "35px 0",
    textAlign: "center",
  },
  circularRoot: {
    width: "100px !important",
    height: "100px !important",
  },
  circularCircle: {
    color: theme.palette.primary.main,
  },
});

interface propType {
  children: ReactNode;
}

const ScrollComponent = ({ children }: propType) => {
  const classes = useStyles(styles);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [isDisplay, setIsDisplay] = useState(false);

  useEffect(() => {
    const scrollY = window.innerHeight + window.scrollY;
    const positionY = scrollRef.current?.getBoundingClientRect().top
      ? scrollRef.current?.getBoundingClientRect().top +
        document.documentElement.scrollTop
      : 0;
    if (scrollY >= positionY) {
      setIsDisplay(true);
    }

    const scrollFunction = () => {
      const scrollY = window.innerHeight + window.scrollY;
      const positionY = scrollRef.current?.getBoundingClientRect().top
        ? scrollRef.current?.getBoundingClientRect().top +
          document.documentElement.scrollTop
        : 0;
      if (scrollY >= positionY) {
        setIsDisplay(true);
      }
    };

    window.addEventListener("scroll", scrollFunction);

    return () => {
      window.removeEventListener("scroll", scrollFunction);
    };
  }, []);

  return isDisplay ? (
    <>{children}</>
  ) : (
    <div ref={scrollRef} className={classes.container}>
      <CircularProgress
        classes={{
          root: classes.circularRoot,
          circle: classes.circularCircle,
        }}
        thickness={2.5}
      />
    </div>
  );
};

export default ScrollComponent;
