const MissionIcon = ({ ...props }: any) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <g transform="translate(0, 512) scale(0.1,-0.1)">
        <path
          d="M452 4790 c-109 -29 -187 -74 -272 -160 -87 -86 -131 -163 -160 -276
-74 -290 90 -603 372 -705 l58 -22 0 -1067 0 -1067 -58 -22 c-230 -83 -391
-317 -392 -567 0 -79 31 -199 72 -277 42 -81 164 -203 245 -245 133 -70 286
-88 427 -52 113 29 190 73 276 160 87 86 131 163 160 276 74 290 -90 603 -372
705 l-58 22 0 458 0 459 614 0 614 0 17 -49 c51 -156 198 -304 357 -362 l58
-22 0 -242 0 -242 -58 -22 c-282 -102 -446 -415 -372 -705 29 -113 73 -190
160 -276 86 -87 163 -131 276 -160 141 -36 294 -18 427 52 80 42 201 163 245
245 41 77 72 198 72 277 -1 250 -162 484 -392 567 l-58 22 0 243 0 242 49 17
c156 51 304 198 362 357 l22 58 613 0 614 0 0 -240 c0 -222 2 -244 20 -280 23
-45 80 -80 130 -80 50 0 107 35 130 80 20 38 20 57 20 888 l0 849 58 22 c230
83 391 317 392 567 0 79 -31 199 -72 277 -42 81 -164 203 -245 245 -133 70
-286 88 -427 52 -113 -29 -190 -73 -276 -160 -87 -86 -131 -163 -160 -276 -74
-290 90 -603 372 -705 l58 -22 0 -458 0 -459 -614 0 -613 0 -22 58 c-58 159
-206 306 -362 357 l-49 17 0 242 0 243 58 22 c230 83 391 317 392 567 0 79
-31 199 -72 277 -42 81 -164 203 -245 245 -133 70 -286 88 -427 52 -113 -29
-190 -73 -276 -160 -87 -86 -131 -163 -160 -276 -74 -290 90 -603 372 -705
l58 -22 0 -242 0 -242 -58 -22 c-159 -58 -306 -206 -357 -362 l-17 -49 -614 0
-614 0 0 459 0 458 58 22 c230 83 391 317 392 567 0 79 -31 199 -72 277 -42
81 -164 203 -245 245 -132 69 -291 88 -431 52z m282 -314 c105 -51 161 -144
161 -266 0 -85 -21 -142 -74 -203 -82 -92 -237 -121 -353 -64 -160 79 -215
278 -118 430 80 126 246 170 384 103z m1960 0 c105 -51 161 -144 161 -266 0
-85 -21 -142 -74 -203 -82 -92 -237 -121 -353 -64 -160 79 -215 278 -118 430
80 126 246 170 384 103z m1960 0 c105 -51 161 -144 161 -266 0 -85 -21 -142
-74 -203 -82 -92 -237 -121 -353 -64 -160 79 -215 278 -118 430 80 126 246
170 384 103z m-1960 -1650 c105 -51 161 -144 161 -266 0 -85 -21 -142 -74
-203 -82 -92 -237 -121 -353 -64 -160 79 -215 278 -118 430 80 126 246 170
384 103z m-1960 -1650 c105 -51 161 -144 161 -266 0 -123 -56 -214 -163 -267
-47 -23 -70 -28 -132 -28 -62 0 -85 5 -132 28 -160 79 -215 278 -118 430 80
126 246 170 384 103z m1960 0 c105 -51 161 -144 161 -266 0 -123 -56 -214
-163 -267 -47 -23 -70 -28 -132 -28 -62 0 -85 5 -132 28 -160 79 -215 278
-118 430 80 126 246 170 384 103z"
        />
        <path
          d="M4154 1606 c-17 -8 -42 -29 -55 -47 l-24 -34 -3 -548 c-2 -545 -2
-549 19 -587 25 -44 83 -80 128 -80 51 0 108 35 131 80 17 34 20 59 20 185 l0
145 85 0 c101 0 143 -15 176 -61 21 -29 24 -49 29 -144 4 -93 9 -115 27 -141
66 -93 204 -80 254 24 19 39 21 56 17 155 -6 125 -26 196 -76 269 l-30 44 19
24 c31 40 71 121 85 175 53 196 -53 418 -244 512 l-76 38 -225 3 c-176 2 -233
-1 -257 -12z m430 -300 c75 -31 108 -148 60 -212 -43 -59 -68 -69 -175 -72
l-99 -4 0 151 0 151 90 0 c55 0 104 -6 124 -14z"
        />
      </g>
    </svg>
  );
};

export default MissionIcon;
