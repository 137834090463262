/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Markup } from "interweave";
// Redux
import { useTypedSelector } from "@/common/lib/redux/store";
// Components
import Slider from "react-slick";
import ReactPlayer from "react-player/youtube";
import { Grid, IconButton, Modal, Theme, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FavoriteButton, Image } from "@/common/components";
import { YoutubeIcon } from "@/common/icons";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
import { colors } from "@/common/lib/style/palette";
// Libs
import { isNotNull, getOptionLabel } from "@/common/lib/common";
import {
  carCategoryList,
  truckCategoryList,
  truckSubCategoryList,
  busCategoryList,
  truckEngineTypeList,
  truckCabinTypeList,
  truckWheelbaseList,
  missionList,
  fuelList,
  colorList,
} from "@/common/lib/varList";

interface propType {
  data: VehicleData;
}

const styles = (theme: Theme) => ({
  container: {
    marginBottom: "2.5vh",
  },
  discountBorder: {
    overflow: "hidden",
    position: "absolute",
    width: "100%",
    height: "100%",
  },
  discountLabel: {
    zIndex: 1,
    position: "absolute",
    top: "0.7em",
    left: "-1.9em",
    width: "7.5em",
    height: "2.25em",
    transform: "rotate(-45deg)",
    background: theme.palette.error.main,
    color: "white",
    textAlign: "center",
    fontWeight: "bold",
    lineHeight: "1.25",
    cursor: "default",
    opacity: 0.95,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "& > p": {
      margin: 0,
      fontSize: "0.625em",
      "&.discount": {
        fontSize: "0.75em",
      },
    },
  },
  mainCarouselSlider: {
    "& .slick-slide": {
      "& > div": {
        display: "flex",
        "& > div": {
          padding: "0.15rem",
        },
      },
    },
    "& .slick-prev, & .slick-next": {
      width: "12.5%",
      height: "100%",
      zIndex: 1,
      transition: "500ms cubic-bezier(0.22, 0.61, 0.36, 1) all",
      "&::before": {
        fontSize: "2rem",
        lineHeight: "unset",
      },
    },
    "& .slick-prev": {
      left: 0,
    },
    "& .slick-next": {
      right: 0,
    },
  },
  subCarouselSlider: {
    "& .slick-slide": {
      "& > div": {
        display: "flex",
        "& > div": {
          padding: "0.15rem",
          position: "relative",
          "&:hover": {
            "& > svg": {
              fill: "#E83F3A",
            },
          },
          "& > div": {
            position: "absolute",
            opacity: 0,
            width: "calc(100% - 0.3rem)",
            aspectRatio: "1.33",
            border: "0.2rem solid " + theme.palette.primary.main,
            borderRadius: "0.25rem",
            boxSizing: "border-box",
            "&.active": {
              opacity: 1,
            },
          },
        },
      },
    },
    "& .slick-dots": {
      position: "unset",
      bottom: "unset",
      "& li": {
        margin: "0 1vw",
        width: "0.85rem",
        height: "0.85rem",
        "& > button": {
          width: "inherit",
          height: "inherit",
          "&::before": {
            content: "'■'",
            width: "inherit",
            height: "inherit",
            fontSize: "0.85rem",
            lineHeight: "1",
          },
        },
      },
    },
  },
  mainImage: {
    width: "100%",
    aspectRatio: "1.33",
    borderRadius: "0.25rem",
    objectFit: "contain",
  },
  subImage: {
    width: "100%",
    aspectRatio: "1.33",
    borderRadius: "0.25rem",
    objectFit: "contain",
  },
  youtubeImage: {
    width: "66%",
    height: "66%",
    opacity: 0.85,
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto",
    fill: theme.palette.text.primary,
  },
  mainTitle: {
    fontSize: "1.25rem",
    fontWeight: 500,
  },
  advertTitle: {
    fontSize: "1rem",
  },
  tagTitle: {
    display: "flex",
    margin: 0,
    padding: 0,
    listStyle: "none",
    "& > li": {
      display: "inline-block",
      textAlign: "center",
      wordSpacing: "-0.04rem",
      marginRight: "1.5vw",
      fontSize: "0.85rem",
      "&::before": {
        content: "'#'",
        marginRight: "0.1rem",
      },
    },
  },
  table: {
    width: "100%",
    tableLayout: "fixed",
    borderCollapse: "collapse",
    borderSpacing: 0,
    userSelect: "none",
    marginTop: 2.5,
    borderTop: "1px dashed " + theme.palette.text.secondary,
    "& th, & td": {
      fontSize: "0.85rem",
      padding: "1.25vh 3vw",
      textAlign: "unset",
    },
    "& tr": {
      borderBottom: "1px dashed " + theme.palette.text.secondary,
    },
    "& tr:nth-child(2n-1)": {
      background:
        "linear-gradient(to right, " +
        colors.gray[0] +
        ", " +
        colors.gray[1] +
        ")",
    },
    "& th": {
      fontWeight: 500,
      color: theme.palette.text.secondary,
    },
    "& td": {
      fontWeight: 600,
      color: theme.palette.text.primary,
    },
  },
  copyText: {
    cursor: "copy",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalDiv: {
    width: 640,
    "@media (max-width: 640px)": {
      width: "100vw",
    },
  },
  modalClose: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    "& > button": {
      margin: 0,
      padding: "0.25rem",
      transform: "translate(0.25rem, 0)",
      "& > svg": {
        fontSize: "1.85rem",
      },
    },
    "@media (max-width: 640px)": {
      "& > button > svg": {
        fontSize: "2.35rem",
      },
    },
  },
  modalPlayer: {
    width: "100% !important",
    height: "unset !important",
    aspectRatio: "1.78",
  },
  favorite: {
    position: "absolute",
    top: "1vw",
    right: "1vw",
    "& > svg": {
      fontSize: "2.5rem",
    },
  },
});

const InfoContainer = ({ data }: propType) => {
  const classes = useStyles(styles);
  const { t } = useTranslation();
  const { loginToken } = useTypedSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();

  const mainCarouselRef = useRef<Slider>(null);
  const subCarouselRef = useRef<Slider>(null);

  const [imageIdx, setImageIdx] = useState(0);
  const [isDiscount, setIsDiscount] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [isEnabledClick, setIsEnabledClick] = useState(true);

  useEffect(() => {
    if (data !== undefined) {
      if (data.sales.discount_expired !== null) {
        const todayDate = new Date();
        const expireDate = new Date(data.sales.discount_expired);
        expireDate.setHours(expireDate.getHours() + 9);

        if (expireDate >= todayDate) {
          setIsDiscount(true);
        } else {
          setIsDiscount(false);
        }
      }
    }
  }, [data]);

  useEffect(() => {
    if (!isEnabledClick) {
      setTimeout(() => {
        setIsEnabledClick(true);
      }, 350);
    }
  }, [isEnabledClick]);

  useEffect(() => {
    if (mainCarouselRef.current !== null) {
      mainCarouselRef.current.slickGoTo(imageIdx);
    }
    if (subCarouselRef.current !== null) {
      if (data.media.youtube !== "") {
        subCarouselRef.current.slickGoTo(Math.floor((imageIdx + 1) / 10));
      } else {
        subCarouselRef.current.slickGoTo(Math.floor(imageIdx / 10));
      }
    }
  }, [imageIdx]);

  const getMainTitle = () => {
    const titles = [];
    titles.push(data.product.year);
    titles.push(data.product.maker);
    titles.push(data.product.detail_model);
    if (data.product.type === "TRUCK" && data.truck_product !== undefined) {
      titles.push("<br />");
      if (data.truck_product.category === "CargoTruck") {
        titles.push(data.truck_product.engine_type);
        titles.push(data.truck_product.cabin_type);
      }
      if (
        data.truck_product.category === "TopTruck" ||
        data.truck_product.category === "WingbodyTruck" ||
        data.truck_product.category === "Trailer" ||
        data.truck_product.category === "TankLorry"
      ) {
        titles.push(data.truck_product.sub_category);
      }
      titles.push(data.truck_product.drive);
      titles.push(data.truck_product.tonner.toFixed(1) + "t");
    }
    return titles.join(" ");
  };
  const getTagTitle = () => {
    const titles = [];
    titles.push(data.product.fuel);
    titles.push(data.product.mission);
    if (data.product.type === "CAR" && data.car_product !== undefined) {
      titles.push(data.product.exhaust + "cc");
      titles.push(data.car_product.seats + "seats");
    } else if (
      data.product.type === "TRUCK" &&
      data.truck_product !== undefined
    ) {
      titles.push(data.product.exhaust + "cc");
    } else if (data.product.type === "BUS" && data.bus_product !== undefined) {
      titles.push(data.product.horse_power + "HP");
      titles.push(data.bus_product.seats + "seats");
    }

    return (
      <ul className={classes.tagTitle}>
        {titles.map((title, idx) => {
          return <li key={idx}>{title}</li>;
        })}
      </ul>
    );
  };
  const getModelValue = () => {
    const values = [];
    if (data !== undefined) {
      values.push(data.product.year);
      values.push(data.product.maker);
      values.push(data.product.detail_model);
    }
    if (values.length === 0) {
      return "N/A";
    }
    return values.join(" ");
  };
  const getCategoryValue = () => {
    const values = [];
    if (data !== undefined) {
      if (data.product.type === "CAR" && data.car_product !== undefined) {
        values.push(getOptionLabel(data.car_product.category, carCategoryList));
      } else if (
        data.product.type === "TRUCK" &&
        data.truck_product !== undefined
      ) {
        if (
          data.truck_product.category === "CargoTruck" ||
          data.truck_product.category === "DumpTruck" ||
          data.truck_product.category === "Tractor" ||
          data.truck_product.category === "CampingCar" ||
          data.truck_product.category === "Others"
        ) {
          values.push(
            getOptionLabel(data.truck_product.category, truckCategoryList)
          );
          if (
            data.truck_product.load_weight !== null &&
            data.truck_product.load_weight > 0
          ) {
            values.push(data.truck_product.load_weight + "t");
          }
        } else if (data.truck_product.category === "TowTruck") {
          values.push(
            getOptionLabel(
              data.truck_product.sub_category,
              truckSubCategoryList[data.truck_product.category]
            )
          );
          if (
            data.truck_product.load_weight !== null &&
            data.truck_product.load_weight > 0
          ) {
            values.push(data.truck_product.load_weight + "t");
          }
        } else if (data.truck_product.category === "CraneTruck") {
          values.push(
            getOptionLabel(
              data.truck_product.sub_category,
              truckSubCategoryList[data.truck_product.category]
            )
          );
          if (
            data.truck_product.crane_boom_height !== null &&
            data.truck_product.crane_boom_height > 0
          ) {
            values.push(data.truck_product.crane_boom_height + "m");
          }
        } else if (
          data.truck_product.category === "TopTruck" ||
          data.truck_product.category === "WingbodyTruck" ||
          data.truck_product.category === "Trailer"
        ) {
          values.push(
            getOptionLabel(data.truck_product.category, truckCategoryList)
          );
          values.push(
            getOptionLabel(
              data.truck_product.sub_category,
              truckSubCategoryList[data.truck_product.category]
            )
          );
          if (
            data.truck_product.load_weight !== null &&
            data.truck_product.load_weight > 0
          ) {
            values.push(data.truck_product.load_weight + "t");
          }
        } else if (data.truck_product.category === "TankLorry") {
          values.push(
            getOptionLabel(data.truck_product.category, truckCategoryList)
          );
          values.push(
            getOptionLabel(
              data.truck_product.sub_category,
              truckSubCategoryList[data.truck_product.category]
            )
          );
          if (
            data.truck_product.tank_volume !== null &&
            data.truck_product.tank_volume > 0
          ) {
            values.push(data.truck_product.tank_volume + "L");
          }
        }
      } else if (
        data.product.type === "BUS" &&
        data.bus_product !== undefined
      ) {
        values.push(getOptionLabel(data.bus_product.category, busCategoryList));
      }
    }
    if (values.length === 0) {
      return "N/A";
    }
    return values.join(" / ");
  };

  const handleImageOnClick = (imageIdx: number) => {
    if (isEnabledClick) {
      setImageIdx(imageIdx);
      setIsEnabledClick(false);
    }
  };
  const handleCopyOnClick = () => {
    navigator.clipboard.writeText(window.location.href).then(
      () => {
        enqueueSnackbar(t("MSG.COPY_SUCCESS"), { variant: "success" });
      },
      () => {
        enqueueSnackbar(t("MSG.COPY_FAILURE"), { variant: "error" });
      }
    );
  };

  const mainCaroselSettings = {
    infinite: true,
    autoPlay: false,
    arrows: true,
    swipe: true,
    draggable: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 350,
    beforeChange: (_: number, next: number) => setImageIdx(next),
  };
  const subCaroselSettings = {
    infinite: true,
    autoPlay: false,
    dots: true,
    arrows: false,
    swipe: true,
    draggable: false,
    rows: 2,
    slidesPerRow: 5,
    speed: 350,
  };

  return (
    <>
      {data !== undefined && (
        <>
          <Grid container className={classes.container}>
            <Grid
              item
              xs={12}
              sx={{ padding: "0.5vh 0 1vh", position: "relative" }}
            >
              {isNotNull(loginToken) && isDiscount && (
                <div className={classes.discountBorder}>
                  <div className={classes.discountLabel}>
                    <p>SPECIAL</p>
                    <p className="discount">DISCOUNT</p>
                  </div>
                </div>
              )}
              <Slider
                {...mainCaroselSettings}
                ref={mainCarouselRef}
                className={classes.mainCarouselSlider}
              >
                {data.media.image.map((src: File | string, idx: number) => {
                  return (
                    <div key={idx}>
                      <Image alt="" src={src} className={classes.mainImage} />
                    </div>
                  );
                })}
              </Slider>
              <Slider
                {...subCaroselSettings}
                ref={subCarouselRef}
                className={classes.subCarouselSlider}
              >
                {data.media.youtube !== "" && (
                  <div onClick={() => setModalOpen(true)}>
                    <YoutubeIcon className={classes.youtubeImage} />
                    <Image
                      alt=""
                      src={data.media.image[0]}
                      className={classes.subImage}
                    />
                  </div>
                )}
                {data.media.image.map((src: File | string, idx: number) => {
                  return (
                    <div key={idx}>
                      <div
                        className={idx === imageIdx ? `active` : ``}
                        onClick={() => handleImageOnClick(idx)}
                      />
                      <Image alt="" src={src} className={classes.subImage} />
                    </div>
                  );
                })}
              </Slider>
              <FavoriteButton
                className={classes.favorite}
                wish_id={data.wish !== undefined ? data.wish.id : null}
                product_uid={data.product.uid}
              />
            </Grid>
            <Grid item xs={12} sx={{ padding: "0.5vh 0" }}>
              <Typography className={classes.mainTitle}>
                <Markup content={getMainTitle()} />
              </Typography>
              <Typography className={classes.advertTitle}>
                {data.sales.advert_text}
              </Typography>
              {getTagTitle()}
            </Grid>
            <Grid item xs={12} sx={{ padding: "0.5vh 0" }}>
              <table className={classes.table}>
                <colgroup>
                  <col style={{ width: "25%" }} />
                  <col style={{ width: "25%" }} />
                  <col style={{ width: "25%" }} />
                  <col style={{ width: "25%" }} />
                </colgroup>
                <thead></thead>
                <tbody>
                  <tr>
                    <th>ID</th>
                    <td
                      colSpan={3}
                      className={classes.copyText}
                      onClick={handleCopyOnClick}
                    >
                      {data.product.uid}
                    </td>
                  </tr>
                  <tr>
                    <th>{t("VEHICLE.PRODUCT.MODEL")}</th>
                    <td colSpan={3}>{getModelValue()}</td>
                  </tr>
                  <tr>
                    <th>{t("VEHICLE.PRODUCT.CATEGORY")}</th>
                    <td colSpan={3}>{getCategoryValue()}</td>
                  </tr>
                  {(data.product.type === "CAR" ||
                    data.product.type === "BUS") && (
                    <tr>
                      <th>{t("VEHICLE.PRODUCT.COLOR")}</th>
                      <td>{getOptionLabel(data.product.color, colorList)}</td>
                      <th>{t("VEHICLE.PRODUCT.DOOR")}</th>
                      <td>
                        {data.product.door !== null ? data.product.door : "-"}
                      </td>
                    </tr>
                  )}
                  {data.product.type === "TRUCK" &&
                    data.truck_product !== undefined && (
                      <>
                        <tr>
                          <th>
                            <Markup
                              content={t("VEHICLE.PRODUCT.ENGINE_TYPE")}
                            />
                          </th>
                          <td>
                            {getOptionLabel(
                              data.truck_product.engine_type,
                              truckEngineTypeList
                            )}
                          </td>
                          <th>
                            <Markup content={t("VEHICLE.PRODUCT.CABIN_TYPE")} />
                          </th>
                          <td>
                            {getOptionLabel(
                              data.truck_product.cabin_type,
                              truckCabinTypeList
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th>{t("VEHICLE.PRODUCT.WHEELBASE")}</th>
                          <td colSpan={3}>
                            {getOptionLabel(
                              data.truck_product.wheelbase,
                              truckWheelbaseList
                            )}
                          </td>
                        </tr>
                      </>
                    )}
                  <tr>
                    <th>{t("VEHICLE.PRODUCT.STEERING")}</th>
                    <td>{data.product.steering}</td>
                    {data.product.type === "CAR" &&
                      data.car_product !== undefined && (
                        <>
                          <th>{t("VEHICLE.PRODUCT.SEATS")}</th>
                          <td>
                            {t("VEHICLE.PRODUCT.SEATS_UNIT", {
                              seats: data.car_product.seats,
                            })}
                          </td>
                        </>
                      )}
                    {data.product.type === "TRUCK" &&
                      data.truck_product !== undefined && (
                        <>
                          <th>{t("VEHICLE.PRODUCT.TONNER")}</th>
                          <td>{data.truck_product.tonner.toFixed(1)} t</td>
                        </>
                      )}
                    {data.product.type === "BUS" &&
                      data.bus_product !== undefined && (
                        <>
                          <th>{t("VEHICLE.PRODUCT.SEATS")}</th>
                          <td>
                            {t("VEHICLE.PRODUCT.SEATS_UNIT", {
                              seats: data.bus_product.seats,
                            })}
                          </td>
                        </>
                      )}
                  </tr>
                  <tr>
                    <th>{t("VEHICLE.PRODUCT.MISSION")}</th>
                    <td
                      colSpan={
                        data.product.type === "BUS" &&
                        (data.bus_product === undefined ||
                          data.bus_product.total_weight === null)
                          ? 3
                          : 1
                      }
                    >
                      {getOptionLabel(data.product.mission, missionList)}
                    </td>
                    {data.product.type === "CAR" &&
                      data.car_product !== undefined && (
                        <>
                          <th>{t("VEHICLE.PRODUCT.DRIVE")}</th>
                          <td>{data.car_product.drive}</td>
                        </>
                      )}
                    {data.product.type === "TRUCK" &&
                      data.truck_product !== undefined && (
                        <>
                          <th>{t("VEHICLE.PRODUCT.DRIVE")}</th>
                          <td>{data.truck_product.drive}</td>
                        </>
                      )}
                    {data.product.type === "BUS" &&
                      data.bus_product !== undefined &&
                      isNotNull(data.bus_product.total_weight) && (
                        <>
                          <th>{t("VEHICLE.PRODUCT.TOTAL_WEIGHT")}</th>
                          <td>
                            {data.bus_product.total_weight !== null
                              ? data.bus_product.total_weight.toFixed(1) + " t"
                              : "-"}
                          </td>
                        </>
                      )}
                  </tr>
                  <tr>
                    <th>{t("VEHICLE.PRODUCT.FUEL")}</th>
                    <td colSpan={3}>
                      {getOptionLabel(data.product.fuel, fuelList)}
                    </td>
                  </tr>
                  {(data.product.exhaust !== null &&
                    data.product.exhaust > 0) ||
                    (data.product.horse_power !== null &&
                      data.product.horse_power > 0 && (
                        <tr>
                          {data.product.exhaust !== null &&
                            data.product.exhaust > 0 && (
                              <>
                                <th>{t("VEHICLE.PRODUCT.EXHAUST")}</th>
                                <td
                                  colSpan={
                                    data.product.horse_power !== null &&
                                    data.product.horse_power > 0
                                      ? 1
                                      : 3
                                  }
                                >
                                  {data.product.exhaust.toLocaleString()} cc
                                </td>
                              </>
                            )}
                          {data.product.horse_power !== null &&
                            data.product.horse_power > 0 && (
                              <>
                                <th>{t("VEHICLE.PRODUCT.HORSE_POWER")}</th>
                                <td
                                  colSpan={
                                    data.product.exhaust !== null &&
                                    data.product.exhaust > 0
                                      ? 1
                                      : 3
                                  }
                                >
                                  {data.product.horse_power.toLocaleString()} HP
                                </td>
                              </>
                            )}
                        </tr>
                      ))}
                  <tr>
                    <th>{t("VEHICLE.PRODUCT.SIZE")}</th>
                    <td colSpan={3}>
                      {data.product.length.toLocaleString()}mm(L) x{" "}
                      {data.product.width.toLocaleString()}mm(W) x{" "}
                      {data.product.height.toLocaleString()}mm(H)
                    </td>
                  </tr>
                </tbody>
              </table>
            </Grid>
          </Grid>
          <Modal
            className={classes.modal}
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            disableAutoFocus={true}
          >
            <div className={classes.modalDiv}>
              <div className={classes.modalClose}>
                <IconButton onClick={() => setModalOpen(false)}>
                  <CloseIcon sx={{ color: "white", fontSize: "2rem" }} />
                </IconButton>
              </div>
              <ReactPlayer
                className={classes.modalPlayer}
                url={data.media.youtube}
                controls
              />
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default InfoContainer;
