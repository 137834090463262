// Libs
import i18n from "@/common/lib/lang/i18n";
import { isNull, isNotNull } from "@/common/lib/common";

export const initList = [{ label: i18n.t("DROPDOWN.SELECT"), value: "" }];

export const baseList = (list: Option[]) => {
  list.unshift({ label: i18n.t("DROPDOWN.SELECT"), value: "" });
  list.push({ label: i18n.t("DROPDOWN.OTHERS"), value: "Others" });
  return list;
};

const currentYear = new Date().getFullYear() + 1;
export const yearList = [{ label: i18n.t("DROPDOWN.SELECT"), value: "" }];
yearList.push(
  ...Array.from({ length: 30 }, (_, i) => currentYear - i).map(
    (year: number) => {
      return { label: String(year), value: String(year) };
    }
  )
);

export const getYearList = (start: number, end: number) => {
  const currentYear = new Date().getFullYear() + 1;
  if (isNull(start)) {
    start = currentYear - 30;
  }
  if (isNull(end)) {
    end = currentYear;
  }
  const result = [{ label: i18n.t("DROPDOWN.SELECT"), value: "" }];
  result.push(
    ...Array.from({ length: end - start + 1 }, (_, i) => end - i).map(
      (year: number) => {
        return { label: String(year), value: String(year) };
      }
    )
  );
  return result;
};

export const typeList = [
  { label: i18n.t("DROPDOWN.SELECT"), value: "" },
  { label: i18n.t("VEHICLE.TYPE.CAR"), value: "CAR" },
  { label: i18n.t("VEHICLE.TYPE.TRUCK"), value: "TRUCK" },
  { label: i18n.t("VEHICLE.TYPE.BUS"), value: "BUS" },
];

export const carCategoryList = baseList([
  { label: i18n.t("VEHICLE.CATEGORY.CAR.SUB_COMPACT"), value: "Sub-Compact" },
  { label: i18n.t("VEHICLE.CATEGORY.CAR.SUV"), value: "SUV" },
  { label: i18n.t("VEHICLE.CATEGORY.CAR.VAN"), value: "Van/MiniVan" },
  { label: i18n.t("VEHICLE.CATEGORY.CAR.SEDAN"), value: "Sedan" },
  { label: i18n.t("VEHICLE.CATEGORY.CAR.COUPE"), value: "Coupe" },
  { label: i18n.t("VEHICLE.CATEGORY.CAR.WAGON"), value: "Wagon" },
  { label: i18n.t("VEHICLE.CATEGORY.CAR.HATCHBACK"), value: "Hatchback" },
  { label: i18n.t("VEHICLE.CATEGORY.CAR.CONVERTIBLE"), value: "Convertible" },
  { label: i18n.t("VEHICLE.CATEGORY.CAR.PICKUP"), value: "Pickup" },
  { label: i18n.t("VEHICLE.CATEGORY.CAR.AMBULANCE"), value: "Ambulance" },
  { label: i18n.t("VEHICLE.CATEGORY.CAR.WRECK"), value: "WreckCar" },
]);

export const truckCategoryList = baseList([
  { label: i18n.t("VEHICLE.CATEGORY.TRUCK.CARGO"), value: "CargoTruck" },
  { label: i18n.t("VEHICLE.CATEGORY.TRUCK.TOP"), value: "TopTruck" },
  {
    label: i18n.t("VEHICLE.CATEGORY.TRUCK.WINGBODY"),
    value: "WingbodyTruck",
  },
  { label: i18n.t("VEHICLE.CATEGORY.TRUCK.DUMP"), value: "DumpTruck" },
  {
    label: i18n.t("VEHICLE.CATEGORY.TRUCK.TRACTOR"),
    value: "Tractor",
  },
  { label: i18n.t("VEHICLE.CATEGORY.TRUCK.TRAILER"), value: "Trailer" },
  { label: i18n.t("VEHICLE.CATEGORY.TRUCK.CRAIN"), value: "CraneTruck" },
  { label: i18n.t("VEHICLE.CATEGORY.TRUCK.TOW"), value: "TowTruck" },
  { label: i18n.t("VEHICLE.CATEGORY.TRUCK.TANKLLORY"), value: "TankLorry" },
  { label: i18n.t("VEHICLE.CATEGORY.TRUCK.CAMPING"), value: "CampingCar" },
]);

export const truckSubCategoryList: { [key: string]: Option[] } = {
  "": [{ label: i18n.t("DROPDOWN.SELECT"), value: "" }],
  CargoTruck: baseList([
    { label: i18n.t("VEHICLE.CATEGORY.TRUCK.CARGO"), value: "CargoTruck" },
  ]),
  TopTruck: baseList([
    {
      label: i18n.t("VEHICLE.SUB_CATEGORY.TRUCK.BOX.NORMAL"),
      value: "Normal Box",
    },
    {
      label: i18n.t("VEHICLE.SUB_CATEGORY.TRUCK.BOX.REFRIGERATOR"),
      value: "Fridge Box",
    },
    {
      label: i18n.t("VEHICLE.SUB_CATEGORY.TRUCK.BOX.FREEZER"),
      value: "Freezer Box",
    },
    {
      label: i18n.t("VEHICLE.SUB_CATEGORY.TRUCK.BOX.SOFT"),
      value: "Soft Box",
    },
    {
      label: i18n.t("VEHICLE.SUB_CATEGORY.TRUCK.BOX.LOW_NORMAL"),
      value: "Low Normal Box",
    },
    {
      label: i18n.t("VEHICLE.SUB_CATEGORY.TRUCK.BOX.LOW_REFRIGERATOR"),
      value: "Low Fridge Box",
    },
    {
      label: i18n.t("VEHICLE.SUB_CATEGORY.TRUCK.BOX.LOW_FREEZER"),
      value: "Low Freezer Box",
    },
    {
      label: i18n.t("VEHICLE.SUB_CATEGORY.TRUCK.BOX.LOW_SOFT"),
      value: "Low Soft Box",
    },
    {
      label: i18n.t("VEHICLE.SUB_CATEGORY.TRUCK.BOX.HIGH_NORMAL"),
      value: "High Normal Box",
    },
    {
      label: i18n.t("VEHICLE.SUB_CATEGORY.TRUCK.BOX.HIGH_REFRIGERATOR"),
      value: "High Fridge Box",
    },
    {
      label: i18n.t("VEHICLE.SUB_CATEGORY.TRUCK.BOX.HIGH_FREEZER"),
      value: "High Freezer Box",
    },
    {
      label: i18n.t("VEHICLE.SUB_CATEGORY.TRUCK.BOX.HIGH_SOFT"),
      value: "High Soft Box",
    },
  ]),
  WingbodyTruck: baseList([
    {
      label: i18n.t("VEHICLE.SUB_CATEGORY.TRUCK.BOX.NORMAL"),
      value: "Normal Wing",
    },
    {
      label: i18n.t("VEHICLE.SUB_CATEGORY.TRUCK.BOX.REFRIGERATOR"),
      value: "Fridge Wing",
    },
    {
      label: i18n.t("VEHICLE.SUB_CATEGORY.TRUCK.BOX.FREEZER"),
      value: "Freezer Wing",
    },
    {
      label: i18n.t("VEHICLE.SUB_CATEGORY.TRUCK.BOX.SOFT"),
      value: "Soft Wing",
    },
    {
      label: i18n.t("VEHICLE.SUB_CATEGORY.TRUCK.BOX.LOW_NORMAL"),
      value: "Low Normal Wing",
    },
    {
      label: i18n.t("VEHICLE.SUB_CATEGORY.TRUCK.BOX.LOW_REFRIGERATOR"),
      value: "Low Fridge Wing",
    },
    {
      label: i18n.t("VEHICLE.SUB_CATEGORY.TRUCK.BOX.LOW_FREEZER"),
      value: "Low Freezer Wing",
    },
    {
      label: i18n.t("VEHICLE.SUB_CATEGORY.TRUCK.BOX.LOW_SOFT"),
      value: "Low Soft Wing",
    },
    {
      label: i18n.t("VEHICLE.SUB_CATEGORY.TRUCK.BOX.HIGH_NORMAL"),
      value: "High Normal Wing",
    },
    {
      label: i18n.t("VEHICLE.SUB_CATEGORY.TRUCK.BOX.HIGH_REFRIGERATOR"),
      value: "High Fridge Wing",
    },
    {
      label: i18n.t("VEHICLE.SUB_CATEGORY.TRUCK.BOX.HIGH_FREEZER"),
      value: "High Freezer Wing",
    },
    {
      label: i18n.t("VEHICLE.SUB_CATEGORY.TRUCK.BOX.HIGH_SOFT"),
      value: "High Soft Wing",
    },
  ]),
  DumpTruck: baseList([
    { label: i18n.t("VEHICLE.CATEGORY.TRUCK.DUMP"), value: "DumpTruck" },
  ]),
  Tractor: baseList([
    {
      label: i18n.t("VEHICLE.CATEGORY.TRUCK.TRACTOR"),
      value: "Tractor",
    },
  ]),
  Trailer: baseList([
    {
      label: i18n.t("VEHICLE.SUB_CATEGORY.TRUCK.TRAILER.20FT"),
      value: "20ft Container",
    },
    {
      label: i18n.t("VEHICLE.SUB_CATEGORY.TRUCK.TRAILER.40FT"),
      value: "40ft Container",
    },
    {
      label: i18n.t("VEHICLE.SUB_CATEGORY.TRUCK.TRAILER.LOWBED"),
      value: "Low Bed Container",
    },
    {
      label: i18n.t("VEHICLE.SUB_CATEGORY.TRUCK.TRAILER.DUMP"),
      value: "Dump Trailer",
    },
    {
      label: i18n.t("VEHICLE.SUB_CATEGORY.TRUCK.TRAILER.TANK"),
      value: "Tank Trailer",
    },
    {
      label: i18n.t("VEHICLE.SUB_CATEGORY.TRUCK.TRAILER.BCT"),
      value: "BCT Bulk",
    },
    {
      label: i18n.t("VEHICLE.SUB_CATEGORY.TRUCK.TRAILER.TRANSPORTER"),
      value: "Transporter",
    },
  ]),
  CraneTruck: baseList([
    {
      label: i18n.t("VEHICLE.SUB_CATEGORY.TRUCK.CRAIN.LADDER"),
      value: "LadderTruck",
    },
    {
      label: i18n.t("VEHICLE.SUB_CATEGORY.TRUCK.CRAIN.CARGO_CRAIN"),
      value: "CargoCrain",
    },
    {
      label: i18n.t("VEHICLE.SUB_CATEGORY.TRUCK.CRAIN.AERIAL_LIFT"),
      value: "AerialLift",
    },
  ]),
  TowTruck: baseList([
    {
      label: i18n.t("VEHICLE.SUB_CATEGORY.TRUCK.TOW.WRECK"),
      value: "WreckCar",
    },
    {
      label: i18n.t("VEHICLE.SUB_CATEGORY.TRUCK.TOW.SELF_LOADER"),
      value: "SelfLoader",
    },
    {
      label: i18n.t("VEHICLE.SUB_CATEGORY.TRUCK.TOW.CAR_CARRIER"),
      value: "CarCarrier",
    },
  ]),
  TankLorry: baseList([
    {
      label: i18n.t("VEHICLE.SUB_CATEGORY.TRUCK.TANKLLORY.HARDSTEEL"),
      value: "HardSteel Tank",
    },
    {
      label: i18n.t("VEHICLE.SUB_CATEGORY.TRUCK.TANKLLORY.STAINLESS"),
      value: "Stainless Tank",
    },
    {
      label: i18n.t("VEHICLE.SUB_CATEGORY.TRUCK.TANKLLORY.ALUMINUM"),
      value: "Aluminum Tank",
    },
    {
      label: i18n.t("VEHICLE.SUB_CATEGORY.TRUCK.TANKLLORY.HEAT"),
      value: "Heat Insulation Tank",
    },
    {
      label: i18n.t("VEHICLE.SUB_CATEGORY.TRUCK.TANKLLORY.MILK"),
      value: "Milk Tank",
    },
    {
      label: i18n.t("VEHICLE.SUB_CATEGORY.TRUCK.TANKLLORY.CHEMICAL"),
      value: "Chemical Tank",
    },
    {
      label: i18n.t("VEHICLE.SUB_CATEGORY.TRUCK.TANKLLORY.VACUUM"),
      value: "Vacuum Tank",
    },
  ]),
  CampingCar: baseList([
    { label: i18n.t("VEHICLE.CATEGORY.TRUCK.CAMPING"), value: "CampingCar" },
  ]),
  Others: baseList([]),
};

export const busCategoryList = baseList([
  { label: i18n.t("VEHICLE.CATEGORY.BUS.BUS"), value: "BUS" },
]);

export const productList: {
  [maker: string]: {
    [type: string]: {
      [model: string]: string[];
    };
  };
} = {
  Hyundai: {
    CAR: {
      Accent: ["Accent", "New Accent", "Accent(New)"],
      Avante: [
        "Avante",
        "All New Avante",
        "Avante XD",
        "New Avante XD",
        "Avante Hybrid",
        "Avante HD",
        "Avante MD",
        "Avante Coupe",
        "The New Avante",
        "Avante AD",
        "The New Avante AD",
        "Avante(CN7)",
        "Avante Hybrid(CN7)",
      ],
      Genesis: [
        "Genesis",
        "Genesis DH",
        "Genesis Coupe",
        "The New Genesis Coupe",
      ],
      Grace: ["Grace", "New Grace"],
      Grandeur: [
        "Grandeur",
        "New Grandeur",
        "Grandeur XG",
        "New Grandeur XG",
        "Grandeur TG",
        "Grandeur New Luxury",
        "The Luxury Grandeur",
        "Grandeur HG",
        "Grandeur HG Hybrid",
        "Grandeur IG",
        "Grandeur IG Hybrid",
        "The New Grandeur IG",
        "The New Grandeur IG Hybrid",
        "Grandeur(GN7)",
        "Grandeur Hybrid(GN7)",
      ],
      Ioniq: [
        "Ioniq Hybrid",
        "Ioniq Electric",
        "The New Ioniq Hybrid",
        "The New Ioniq Electric",
      ],
      Ioniq5: ["Ioniq5"],
      Ioniq6: ["Ioniq6"],
      Kona: [
        "Kona",
        "Kona Electric",
        "Kona Hybrid",
        "The New Kona",
        "The New Kona Hybrid",
        "Kona(SX2)",
        "Kona Electric(SX2)",
        "Kona Hybrid(SX2)",
      ],
      Maxcruz: ["Maxcruz", "The New Maxcruz"],
      NEXO: ["NEXO"],
      Palisade: ["Palisade", "The New Palisade"],
      SantaFe: [
        "SantaFe",
        "SantaFe CM",
        "SantaFe DM",
        "SantaFe The Prime",
        "SantaFe The Style",
        "SantaFe TM",
        "The New SantaFe",
      ],
      Solati: ["Solati"],
      Sonata: [
        "Sonata",
        "Sonata II",
        "Sonata III",
        "EF Sonata",
        "New EF Sonata",
        "NF Sonata",
        "NF Sonata Transform",
        "Sonata Hybrid",
        "YF Sonata",
        "YF Sonata The Brilliant",
        "LF Sonata",
        "LF Sonata Hybrid",
        "Sonata New Rise",
        "Sonata New Rise Hybrid",
        "Sonata(DN8)",
        "Sonata Hybrid(DN8)",
        "Sonata The Edge(DN8)",
        "Sonata The Edge Hybrid(DN8)",
      ],
      Starex: [
        "Starex",
        "Starex Jumbo",
        "Grand Starex",
        "The New Grand Starex",
      ],
      Staria: ["Staria"],
      Terracan: ["Terracan", "Terracan Power Plus"],
      Tucson: [
        "Tucson",
        "Tucson ix",
        "New Tucson ix",
        "All New Tucson",
        "Tucson(NX4)",
        "Tucson Hybrid(NX4)",
      ],
      VENUE: ["VENUE"],
      Veloster: ["Veloster", "The New Veloster", "Veloster(JS)"],
      Veracruz: ["Veracruz"],
      Verna: ["Verna", "New Verna", "Verna(New)", "Verna Transform"],
      i30: ["i30", "i30 CW", "i30(New)", "The New i30", "i30(PD)"],
      i40: ["i40", "i40 Saloon", "The New i40", "The New i40 Saloon"],
    },
    TRUCK: {
      Porter: ["Porter", "Porter II", "New Porter"],
      Mighty: [
        "Mighty",
        "Mighty2",
        "Mighty QT",
        "e-Mighty",
        "New Mighty",
        "All New Mighty",
      ],
      "Mega Truck": ["Mega Truck", "New Mega Truck"],
    },
    BUS: {
      County: ["County", "e-County", "New County"],
      "Aero Town": ["Aero Town", "e-Aero Town"],
      Universe: ["Noble", "Luxury", "Elegance", "Comfort", "Classic", "Prime"],
    },
  },
  Genesis: {
    CAR: {
      EQ900: ["EQ900"],
      G70: ["G70", "The New G70", "The New G70 Shooting Brake"],
      G80: ["G80", "G80(RG3)", "Electrified G80(RG3)"],
      G90: ["G90", "G90(RS4)"],
      GV60: ["GV60"],
      GV70: ["GV70", "Electrified GV70"],
      GV80: ["GV80"],
    },
  },
  Kia: {
    CAR: {
      Carens: [
        "Carens",
        "Carens II",
        "New Carens",
        "All New Carens",
        "The New Carens",
      ],
      Carnival: [
        "Carnival",
        "Carnival II",
        "New Carnival",
        "Grand Carnival",
        "Carnival R",
        "All New Carnival",
        "The New Carnival",
        "Carnival 4th Gen",
      ],
      Forte: ["Forte", "Forte Koup", "Forte Hatchback"],
      K3: [
        "K3",
        "K3 Koup",
        "K3 Euro",
        "The New K3",
        "The New K3 Koup",
        "The New K3 Euro",
        "All New K3",
        "The New K3 2nd Gen",
      ],
      K5: [
        "K5",
        "K5 Hybrid",
        "The New K5",
        "The New K5 Hybrid",
        "K5 2nd Gen",
        "K5 Hybrid 2nd Gen",
        "The New K5 2nd Gen",
        "The New K5 Hybrid 2nd Gen",
        "K5 3rd Gen",
        "K5 Hybrid 3rd Gen",
      ],
      K7: [
        "K7",
        "K7 Hybrid",
        "The Prestige K7",
        "The New K7",
        "All New K7",
        "All New K7 Hybrid",
        "K7 Premire",
        "K7 Premire Hybrid",
      ],
      K8: ["K8", "K8 Hybrid"],
      K9: ["K9", "The New K9", "The K9", "The New K9 2nd Gen"],
      Lotze: ["Lotze", "Lotze Advance", "Lotze Innovation"],
      Mohave: ["Mohave", "The New Mohave", "Mohave The Master"],
      Morning: [
        "Morning",
        "New Morning",
        "All New Morning",
        "The New Morning",
        "All New Morning(JA)",
        "Morning Urban",
        "The New Morning(JA)",
      ],
      Niro: [
        "Niro",
        "Niro EV",
        "The New Niro",
        "Niro Plus",
        "The All New Niro",
        "The All New Niro EV",
      ],
      Opirus: ["Opirus", "New Opirus", "Opirus Premium"],
      Pride: ["Pride", "Pride(New)", "All New Pride", "The New Pride"],
      Ray: ["Ray", "The New Ray", "The New Kia Ray"],
      Regal: ["Regal"],
      Retona: ["Retona"],
      Rio: ["Rio"],
      Seltos: ["Seltos", "The New Seltos"],
      Sorento: [
        "Sorento",
        "New Sorento",
        "Sorento R",
        "New Sorento R",
        "All New Sorento",
        "The New Sorento",
        "Sorento 4th Gen",
      ],
      Soul: [
        "Soul",
        "Soul EV",
        "All New Soul",
        "The New Soul",
        "Soul Booster",
        "Soul Booster EV",
      ],
      Sportage: [
        "Sportage",
        "New Sportage",
        "Sportage R",
        "The New Sportage R",
        "Sportage 4th Gen",
        "Sportage The Bold",
        "Sportage 5th Gen",
        "Sportage Hybrid 5th Gen",
      ],
      Stinger: ["Stinger", "Stinger Meister"],
      Stonic: ["Stonic"],
      "Bongo III Minibus": ["Bongo III Minibus"],
    },
    TRUCK: { Bongo: ["Bongo III", "The New Bongo III", "Bongo Frontier"] },
    BUS: { Granbrid: ["Granbrid", "New Granbird"] },
  },
  "GM Daewoo (Chevrolet)": {
    CAR: {
      Alpheon: ["Alpheon"],
      Aveo: [
        "Aveo Sedan",
        "Aveo Hatchback",
        "The New Aveo Sedan",
        "The New Aveo Hatchback",
      ],
      "BOLT EV": ["BOLT EV", "New BOLT EV"],
      "BOLT EUV": ["BOLT EUV"],
      Camaro: ["Camaro", "All New Camaro", "The New Camaro"],
      Captiva: ["Captiva"],
      Colorado: ["Colorado", "Real New Colorado", "All New Colorado"],
      Cruze: [
        "Cruze",
        "Cruze 5",
        "Amazing New Cruze",
        "Amazing New Cruze 5",
        "All New Cruze",
      ],
      Damas: ["Damas", "Damas II", "New Damas"],
      Kalos: ["Kalos"],
      Labo: ["Labo", "New Labo"],
      Lacetti: ["Lacetti", "Lacetti Premiere"],
      Malibu: ["Malibu", "All New Malibu", "The New Malibu"],
      Matiz: [
        "Matiz",
        "Matiz II",
        "Matiz Classic",
        "All New Matiz",
        "Matiz Creative",
      ],
      Orlando: ["Orlando"],
      Spark: ["Spark", "Spark EV", "The Next Spark", "The New Spark"],
      Tosca: ["Tosca", "Tosca Premium6"],
      Trailblazer: ["Trailblazer"],
      Traverse: ["Traverse"],
      Trax: ["Trax", "The New Trax", "Trax Crossover"],
      Volt: ["Volt"],
      Winstorm: ["Winstorm", "Winstorm Maxx"],
    },
  },
  "Renault Samsung": {
    CAR: {
      QM3: ["QM3", "New QM3"],
      QM5: ["QM5", "New QM5", "QM5 Neo"],
      QM6: ["QM6", "The New QM6"],
      SM3: ["SM3", "SM3 New Generation", "New SM3", "SM3 Z.E.", "SM3 Neo"],
      SM5: [
        "SM5",
        "New SM5",
        "SM5 New Impression",
        "New SM5(New)",
        "New SM5 Platinum",
        "SM5 Nova",
      ],
      SM6: ["SM6", "The New SM6"],
      SM7: ["SM7", "SM7 New Art", "All New SM7", "SM7 Nova"],
      XM3: ["XM3"],
      Clio: ["Clio"],
      Twizy: ["Twizy"],
      Master: ["Master"],
    },
  },
  "KG Mobility (Ssangyong)": {
    CAR: {
      Actyon: ["Actyon", "Actyon Sports"],
      Istana: ["Istana"],
      Korando: [
        "Korando Jeep",
        "Korando Family",
        "New Korando",
        "Korando Turismo",
        "Korando Sports",
        "The New Korando Sports",
        "Korando C",
        "New Korando C",
        "New Style Korando C",
        "VIEWtiful Korando",
        "Korando e-Motion",
      ],
      Kyron: ["Kyron", "New Kyron"],
      Musso: ["Musso", "New Musso", "New Musso Van", "Musso Sports"],
      Rexton: [
        "Rexton",
        "New Rexton",
        "Rexton II",
        "Super Rexton",
        "Rexton W",
        "G4 Rexton",
        "Rexton Sports",
        "Rexton Sports Khan",
        "The New Rexton Sports",
        "The New Rexton Sports Khan",
        "All New Rexton",
        "Rexton New Arena",
      ],
      Rodius: ["Rodius", "New Rodius", "Rodius Euro"],
      Tivoli: ["Tivoli", "Tivoli Air", "Tivoli Armor", "Very New Tivoli"],
      Torres: ["Torres"],
    },
  },
  Audi: {
    CAR: {
      A1: ["A1", "A1(GB)"],
      A3: ["A3", "New A3", "A3(8Y)"],
      A4: ["A4", "New A4", "A4(B9)"],
      A5: ["A5", "A5(F5)"],
      A6: ["A6", "New A6", "A6(C8)"],
      A7: ["A7", "A7(4K)"],
      A8: ["A8", "New A8", "A8(D5)"],
      Q2: ["Q2"],
      Q3: ["Q3", "Q3(F3)"],
      Q4: ["Q4"],
      Q5: ["Q5", "Q5(FY)"],
      Q7: ["Q7", "Q7(4M)"],
      Q8: ["Q8"],
      R8: ["R8", "R8(4S)"],
      RS5: ["RS5", "RS5(F5)"],
      RS6: ["RS6", "RS6(C8)"],
      RS7: ["RS7", "RS7(4K)"],
      RSQ8: ["RSQ8"],
      S3: ["S3", "S3(8Y)"],
      S4: ["S4", "New S4", "S4(B9)"],
      S5: ["S5", "S5(F5)"],
      S6: ["S6", "S6(C8)"],
      S7: ["S7", "S7(4K)"],
      S8: ["S8", "S8(D5)"],
      SQ5: ["SQ5", "SQ5(FY)"],
      SQ8: ["SQ8"],
      TT: ["TT", "New TT", "TT(8S)"],
      TTS: ["TTS", "TTS(8S)"],
      TTRS: ["TTRS", "TTRS(8S)"],
    },
  },
  BMW: {
    CAR: {
      "1 Series": [
        "1 Series",
        "1 Series(F40)",
        "1 Series(F20)",
        "1 Series(E82)",
      ],
      "2 Series": [
        "2 Series",
        "2 Series(F22)",
        "2 Series Gran Coupe(F44)",
        "2 Series(G42)",
        "2 Series Active Tourer(F45)",
        "2 Series Active Tourer(U06)",
      ],
      "3 Series": [
        "3 Series",
        "3 Series(E90)",
        "3 Series(F30)",
        "3 Series(G20)",
      ],
      "4 Series": ["4 Series", "4 Series(F32)", "4 Series(G22)"],
      "5 Series": [
        "5 Series",
        "5 Series(E60)",
        "5 Series(F10)",
        "5 Series(G30)",
      ],
      "6 Series": ["6 Series", "6 Series(E63)", "6 Series(F12)"],
      "7 Series": [
        "7 Series",
        "7 Series(E65)",
        "7 Series(F01)",
        "7 Series(G11)",
        "7 Series(G70)",
      ],
      "8 Series": ["8 Series", "8 Series(G15)"],
      "Grand Turismo": [
        "3 Series GT(F34)",
        "5 Series GT(F07)",
        "6 Series GT(G32)",
      ],
      "1M": ["1M(E82)"],
      M2: ["M2(F87)"],
      M3: ["M3(E36)", "M3(E46)", "M3(E90)", "M3(F80)", "M3(G80)"],
      M4: ["M4(F82)", "M4(G82)"],
      M5: ["M5(E60)", "M5(F10)", "M5(F90)"],
      M6: ["M6(E63)", "M6(F12)"],
      M8: ["M8(G15)"],
      "M Coupe/M Roadster": [
        "M Coupe/M Roadster(E36)",
        "M Coupe/M Roadster(E85)",
      ],
      X1: ["X1(E84)", "X1(F48)", "X1(U11)"],
      X2: ["X2(F39)"],
      X3: ["X3(E83)", "X3(F25)", "X3(G01)"],
      X4: ["X4(F26)", "X4(G02)"],
      X5: ["X5(E53)", "X5(E70)", "X5(F15)", "X5(G05)"],
      X6: ["X6(E71)", "X6(F16)", "X6(G06)"],
      X7: ["X7(G07)"],
      X3M: ["X3M(G01)"],
      X4M: ["X4M(G02)"],
      X5M: ["X5M(E70)", "X5M(F15)", "X5M(G05)"],
      X6M: ["X6M(E71)", "X6M(F16)", "X6M(G06)"],
      Z3: ["Z3"],
      Z4: ["Z4(E85)", "Z4(E89)", "Z4(G29)"],
      Z8: ["Z8"],
      i3: ["i3"],
      i4: ["i4"],
      i7: ["i7"],
      i8: ["i8"],
      iX: ["iX"],
      iX3: ["iX3"],
    },
  },
  Ford: {
    CAR: {
      Bronco: ["Bronco", "Bronco 6th Gen"],
      "E-Series": ["E-Series"],
      Econoline: ["Econoline"],
      Ecosports: ["Ecosports"],
      Escape: ["Escape"],
      Expedition: ["Expedition"],
      Explorer: ["Explorer", "Explorer 6th Gen"],
      "Explorer SportTrac": ["Explorer SportTrac"],
      F150: ["F150"],
      F250: ["F250"],
      F350: ["F350"],
      "Five Hundred": ["Five Hundred"],
      Focus: ["Focus"],
      Fusion: ["Fusion"],
      Kuga: ["Kuga", "Kuga 2nd Gen"],
      Mondeo: ["Mondeo", "Mondeo 3rd Gen", "Mondeo 4th Gen"],
      Mustang: ["Mustang"],
      Ranger: ["Ranger", "Ranger 3rd Gen", "Ranger 4th Gen"],
      "S-MAX": ["S-MAX"],
      Taurus: ["Taurus", "New Taurus"],
      Transit: ["Transit"],
    },
  },
  Honda: {
    CAR: {
      Accord: ["Accord", "All New Accord", "Accord 10th Gen"],
      "CR-V": ["CR-V", "New CR-V", "CR-V 5th Gen", "CR-V 6th Gen"],
      "HR-V": ["HR-V", "New HR-V"],
      Civic: ["Civic"],
      Fit: ["Fit"],
      Odyssey: ["Odyssey"],
      Pilot: ["Pilot", "Pilot 3rd Gen"],
    },
  },
  Infiniti: {
    CAR: {
      Q: ["Q30", "Q45", "Q50", "Q60", "Q70"],
      G: ["G20", "G25", "G35", "G37"],
      M: ["M30", "M30d", "M35", "M35h", "M37", "M45", "M56"],
      EX: ["EX30d", "EX35", "EX37"],
      FX: ["FX30d", "FX35", "FX37", "FX45", "FX50"],
      JX: ["JX35"],
      QX: ["QX30", "QX4", "QX50", "QX56", "QX60", "QX70", "QX80"],
    },
  },
  Jaguar: {
    CAR: {
      "E-PACE": ["E-PACE"],
      "F-PACE": ["F-PACE"],
      "I-PACE": ["I-PACE"],
      "F-TYPE": ["F-TYPE"],
      "S-TYPE": ["S-TYPE"],
      "X-TYPE": ["X-TYPE"],
      XE: ["XE"],
      XF: ["XF", "New XF", "XF(X260)"],
      XJ: ["New XJ", "All New XJ"],
    },
  },
  Jeep: {
    CAR: {
      Cherokee: [
        "Cherokee",
        "Cherokee(KL)",
        "Grand Cherokee",
        "Grand Cherokee(WL)",
      ],
      Commander: ["Commander"],
      Compass: ["Compass", "Compass 2nd Gen"],
      Gladiator: ["Gladiator(JT)"],
      Renegade: ["Renegade"],
      Wrangler: [
        "Wrangler(YJ)",
        "Wrangler(TJ)",
        "Wrangler(JK)",
        "Wrangler(JL)",
      ],
    },
  },
  "Land Rover": {
    CAR: {
      Defender: ["Defender", "Defender (L663)"],
      Discovery: [
        "Discovery",
        "Discovery2",
        "Discovery3",
        "Discovery4",
        "Discovery5",
      ],
      "Discovery Sport": ["Discovery Sport", "Discovery Sport 2nd Gen"],
      Freelander: ["Freelander", "Freelander2"],
      "Range Rover": [
        "Range Rover",
        "Range Rover 4th Gen",
        "Range Rover 5th Gen",
      ],
      "Range Rover Evoque": [
        "Range Rover Evoque",
        "Range Rover Evoque 2nd Gen",
      ],
      "Range Rover Sport": [
        "Range Rover Sport",
        "Range Rover Sport 2nd Gen",
        "Range Rover Sport 3rd Gen",
      ],
      "Range Rover Velar": ["Range Rover Velar"],
    },
  },
  Lexus: {
    CAR: {
      CT: ["CT200h"],
      ES: [
        "ES300",
        "ES330",
        "ES350",
        "New ES300h",
        "New ES350",
        "ES300h 7th Gen",
      ],
      GS: [
        "GS300",
        "GS350",
        "GS400",
        "GS430",
        "GS450h",
        "GS460",
        "New GS200t",
        "New GS250",
        "New GS300",
        "New GS350",
        "New GS450h",
        "New GS F",
      ],
      GX: ["GX460", "GX470"],
      IS: [
        "IS200",
        "IS250",
        "IS300",
        "IS350",
        "IS F",
        "New IS200t",
        "New IS250",
        "New IS300",
      ],
      LS: [
        "LS400",
        "LS430",
        "LS460",
        "LS460L",
        "LS600hL",
        "LS500 5th Gen",
        "LS500h 5th Gen",
      ],
      LX: ["LX"],
      NX: ["NX200t", "NX300", "NX300h", "NX350h 2nd Gen", "NX450h+ 2nd Gen"],
      RC: ["RC200t", "RC300", "RC350", "RC F"],
      RX: [
        "RX300",
        "RX330",
        "RX350",
        "RX400h",
        "RX450h",
        "RX350 4th Gen",
        "RX450h 4th Gen",
        "RX450hL 4th Gen",
      ],
      SC: ["SC300", "SC400", "SC430"],
      UX: ["UX250h", "UX300e"],
    },
  },
  Maserati: {
    CAR: {
      Coupe: ["Coupe"],
      Ghibli: ["Ghibli"],
      GranSport: ["GranSport"],
      GranCabrio: ["GranCabrio"],
      GranTurismo: ["GranTurismo"],
      Grecale: ["Grecale"],
      Levante: ["Levante"],
      MC12: ["MC12"],
      MC20: ["MC20"],
      Spyder: ["Spyder"],
      Quattroporte: ["Quattroporte"],
    },
  },
  "Mercedes Benz": {
    CAR: {
      "A Class": [
        "A Class",
        "A Class W168",
        "A Class W169",
        "A Class W176",
        "A Class W177",
      ],
      "B Class": ["B Class", "B Class W245", "B Class W246"],
      "C Class": ["C Class", "C Class W204", "C Class W205", "C Class W206"],
      "CL Class": [
        "CL Class",
        "CL Class C140",
        "CL Class C215",
        "CL Class C216",
        "New CL Class",
      ],
      "CLA Class": ["CLA Class", "CLA Class C117", "CLA Class C118"],
      "CLK Class": ["CLK Class", "CLK Class C208", "CLK Class C209"],
      "CLS Class": [
        "CLS Class",
        "CLS Class W218",
        "CLS Class W219",
        "CLS Class C218",
        "CLS Class C257",
      ],
      "E Class": [
        "E Class",
        "E Class W123",
        "E Class W124",
        "E Class W210",
        "E Class W211",
        "E Class W212",
        "E Class W213",
        "New E Class",
      ],
      "G Class": ["G Class", "G Class W463", "G Class W463b"],
      "GL Class": ["GL Class", "GL Class X164", "GL Class X166"],
      "GLA Class": ["GLA Class", "GLA Class X156", "GLA Class H247"],
      "GLB Class": ["GLB Class", "GLB Class X247"],
      "GLC Class": ["GLC Class", "GLC Class X253", "GLC Class X254"],
      "GLE Class": ["GLE Class", "GLE Class W166", "GLE Class W167"],
      "GLK Class": ["GLK Class", "GLK Class X204"],
      "GLS Class": ["GLS Class", "GLS Class X166", "GLS Class X167"],
      "M Class": ["M Class", "M Class W163", "M Class W164", "M Class W166"],
      "R Class": ["R Class"],
      "S Class": [
        "S Class",
        "S Class W221",
        "S Class W222",
        "S Class W223",
        "S Class S600",
      ],
      "SL Class": ["SL Class", "New SL Class", "SL Class R232"],
      "SLC Class": ["SLC Class", "SLC Class R172"],
      "SLK Class": ["SLK Class", "New SLK Class"],
      "V Class": ["V Class"],
      "AMG GT": ["AMG GT"],
      Sprinter: ["Sprinter"],
    },
    TRUCK: { Cargo: ["Cargo"], Dump: ["Dump"], Tractor: ["Tractor"] },
  },
  Mini: {
    CAR: {
      Cooper: [
        "Cooper",
        "Cooper S",
        "Cooper D",
        "Cooper SD",
        "Cooper Electric",
      ],
      "Cooper Convertible": ["Cooper Convertible", "Cooper S Convertible"],
      Coupe: ["Cooper Coupe", "Cooper S Coupe", "Cooper SD Coupe"],
      Clubman: [
        "Cooper Clubman",
        "Cooper S Clubman",
        "Cooper D Clubman",
        "Cooper SD Clubman",
      ],
      Countryman: [
        "Cooper Countryman",
        "Cooper S Countryman",
        "Cooper D Countryman",
        "Cooper SD Countryman",
      ],
      Paceman: ["Cooper D Paceman", "Cooper SD Paceman"],
      Roadster: ["Cooper Roadster", "Cooper S Roadster"],
    },
  },
  Nissan: {
    CAR: {
      Altima: ["Altima", "Altima(L34)"],
      Cube: ["Cube"],
      Juke: ["Juke"],
      Rogue: ["Rogue (S35)", "Rouge (T32)"],
      Maxima: ["Maxima", "Maxima(A36)"],
      Murano: ["Murano", "Murano(Z52)"],
      Pathfinder: ["Pathfinder", "Pathfinder 4th Gen"],
      Qashqai: ["Qashqai", "Qashqai 2nd Gen"],
    },
  },
  Subaru: {
    CAR: {
      BRZ: ["BRZ"],
      Forester: ["Forester"],
      Impreza: ["Impreza"],
      Legacy: ["Legacy"],
      Outback: ["Outback"],
      R1: ["R1"],
    },
  },
  Toyota: {
    CAR: {
      Avalon: ["Avalon", "Avalon 5th Gen"],
      Camry: ["Camry", "New Camry", "Camry(XV70)"],
      RAV4: ["RAV4", "RAV4 5th Gen"],
      Sienna: ["Sienna", "Sienna 4th Gen"],
      Crown: ["Crown", "Crown Majesta", "Crown Crossover"],
      Tacoma: ["Tacoma"],
      Tundra: ["Tundra"],
      Prius: ["Prius", "Prius C", "Prius V", "Prius Prime", "Prius 4th Gen"],
    },
  },
  Volkswagen: {
    CAR: {
      Arteon: ["Arteon"],
      Beetle: ["Beetle"],
      CC: ["CC", "New CC"],
      EOS: ["EOS"],
      Golf: [
        "Golf 1st Gen",
        "Golf 2nd Gen",
        "Golf 3rd Gen",
        "Golf 4th Gen",
        "Golf 5th Gen",
        "Golf 6th Gen",
        "Golf 7th Gen",
        "Golf 8th Gen",
      ],
      "ID.4": ["ID.4"],
      Jetta: ["Jetta", "New Jetta", "Jetta 7th Gen"],
      Passat: [
        "Passat",
        "New Passat",
        "The New Passat",
        "Passat GT(B8)",
        "Passat Variant",
      ],
      Phaeton: ["Phaeton"],
      Polo: ["Polo"],
      "T-ROC": ["T-ROC"],
      Tiguan: ["Tiguan", "New Tiguan", "Tiguan 2nd Gen", "Tiguan All Space"],
      Touareg: ["Touareg", "New Touareg", "Touareg 3rd Gen"],
    },
  },
  Volvo: {
    CAR: {
      C30: ["C30"],
      C40: ["C40 Recharge"],
      C70: ["C70"],
      S40: ["S40"],
      S60: ["S60", "S60 Cross Country", "S60 3rd Gen"],
      S70: ["S70"],
      S80: ["S80"],
      S90: ["S90"],
      V40: ["V40", "V40 Cross Country"],
      V50: ["V50"],
      V60: ["V60", "V60 Cross Country", "V60 Cross Country 2nd Gen"],
      V70: ["V70"],
      V90: ["V90", "V90 Cross Country"],
      XC40: ["XC40", "XC40 Recharge"],
      XC60: ["XC60", "XC60 2nd Gen"],
      XC70: ["XC70"],
      XC90: ["XC90", "XC90 2nd Gen"],
    },
    TRUCK: { Cargo: ["Cargo"], Dump: ["Dump"], Tractor: ["Tractor"] },
  },
};

export const getTypeList = (maker: string) => {
  const result = [];
  const typeList: string[] = [];
  if (isNull(maker)) {
    ["CAR", "TRUCK", "BUS"].forEach((type) => {
      typeList.push(type);
    });
  } else {
    Object.keys(productList[maker]).forEach((type) => {
      typeList.push(type);
    });
  }
  if (typeList.includes("CAR")) {
    result.push({ label: i18n.t("VEHICLE.TYPE.CAR"), value: "CAR" });
  }
  if (typeList.includes("TRUCK")) {
    result.push({ label: i18n.t("VEHICLE.TYPE.TRUCK"), value: "TRUCK" });
  }
  if (typeList.includes("BUS")) {
    result.push({ label: i18n.t("VEHICLE.TYPE.BUS"), value: "BUS" });
  }
  result.unshift({ label: i18n.t("DROPDOWN.SELECT"), value: "" });

  return result;
};

export const getMakerList = (type: string) => {
  const result = [];
  Object.entries(productList).forEach(([maker, typeList]) => {
    if (isNull(type)) {
      result.push({ label: maker, value: maker });
    } else if (Object.keys(typeList).includes(type)) {
      result.push({ label: maker, value: maker });
    }
  });
  result.unshift({ label: i18n.t("DROPDOWN.SELECT"), value: "" });
  result.push({ label: i18n.t("DROPDOWN.OTHERS"), value: "Others" });
  return result;
};

export const getModelList = (type: string, maker: string) => {
  const result = [];
  if (isNotNull(maker)) {
    if (isNull(type)) {
      Object.values(productList[maker]).forEach((value) => {
        Object.keys(value).forEach((model) => {
          result.push({ label: model, value: model });
        });
      });
    } else {
      Object.keys(productList[maker][type]).forEach((model) => {
        result.push({ label: model, value: model });
      });
    }
    result.push({ label: i18n.t("DROPDOWN.OTHERS"), value: "Others" });
  }
  result.unshift({ label: i18n.t("DROPDOWN.SELECT"), value: "" });
  return result;
};

export const getDetailModelList = (maker: string, model: string) => {
  const result = [];
  if (isNotNull(maker) && isNotNull(model)) {
    Object.values(productList[maker]).forEach((value) => {
      if (Object.keys(value).includes(model)) {
        value[model].forEach((detailModel) => {
          result.push({ label: detailModel, value: detailModel });
        });
      }
    });
    result.push({ label: i18n.t("DROPDOWN.OTHERS"), value: "Others" });
  }
  result.unshift({ label: i18n.t("DROPDOWN.SELECT"), value: "" });
  return result;
};

export const missionList = baseList([
  { label: i18n.t("VEHICLE.MISSION.M/T"), value: "M/T" },
  { label: i18n.t("VEHICLE.MISSION.A/T"), value: "A/T" },
  { label: i18n.t("VEHICLE.MISSION.CVT"), value: "CVT" },
]);

export const steeringList = [
  { label: "LHD", value: "LHD" },
  { label: "RHD", value: "RHD" },
];

export const fuelList = baseList([
  { label: i18n.t("VEHICLE.FUEL.GASOLINE"), value: "Gasoline" },
  { label: i18n.t("VEHICLE.FUEL.DIESEL"), value: "Diesel" },
  { label: i18n.t("VEHICLE.FUEL.LPG"), value: "LPG" },
  { label: i18n.t("VEHICLE.FUEL.HYDROGEN"), value: "Hydrogen" },
  { label: i18n.t("VEHICLE.FUEL.ELECTRIC"), value: "Electric" },
  { label: i18n.t("VEHICLE.FUEL.CNG"), value: "CNG" },
  { label: i18n.t("VEHICLE.FUEL.GASOLINE+CNG"), value: "Gasoline+CNG" },
  { label: i18n.t("VEHICLE.FUEL.GASOLINE+LPG"), value: "Gasoline+LPG" },
  { label: i18n.t("VEHICLE.FUEL.GASOLINE+EV"), value: "Gasoline+EV" },
  { label: i18n.t("VEHICLE.FUEL.DIESEL+EV"), value: "Diesel+EV" },
  { label: i18n.t("VEHICLE.FUEL.LPG+EV"), value: "LPG+EV" },
]);

export const colorList = baseList([
  { label: i18n.t("VEHICLE.COLOR.BLACK"), value: "Black" },
  { label: i18n.t("VEHICLE.COLOR.CHARCOAL"), value: "Charcoal" },
  { label: i18n.t("VEHICLE.COLOR.GRAY"), value: "Gray" },
  { label: i18n.t("VEHICLE.COLOR.SILVER"), value: "Silver" },
  { label: i18n.t("VEHICLE.COLOR.WHITE"), value: "White" },
  { label: i18n.t("VEHICLE.COLOR.PEARL"), value: "Pearl" },
  { label: i18n.t("VEHICLE.COLOR.BEIGE"), value: "Beige" },
  { label: i18n.t("VEHICLE.COLOR.GOLD"), value: "Gold" },
  { label: i18n.t("VEHICLE.COLOR.RED"), value: "Red" },
  { label: i18n.t("VEHICLE.COLOR.BURGUNDY"), value: "Burgundy" },
  { label: i18n.t("VEHICLE.COLOR.PINK"), value: "Pink" },
  { label: i18n.t("VEHICLE.COLOR.ORANGE"), value: "Orange" },
  { label: i18n.t("VEHICLE.COLOR.YELLOW"), value: "Yellow" },
  { label: i18n.t("VEHICLE.COLOR.GREEN"), value: "Green" },
  { label: i18n.t("VEHICLE.COLOR.TURQUOISE"), value: "Turquoise" },
  { label: i18n.t("VEHICLE.COLOR.SKYBLUE"), value: "Skyblue" },
  { label: i18n.t("VEHICLE.COLOR.BLUE"), value: "Blue" },
  { label: i18n.t("VEHICLE.COLOR.NAVY"), value: "Navy" },
  { label: i18n.t("VEHICLE.COLOR.BROWN"), value: "Brown" },
  { label: i18n.t("VEHICLE.COLOR.PURPLE"), value: "Purple" },
]);

export const carSeatsList = [
  { label: i18n.t("DROPDOWN.SELECT"), value: "" },
  { label: i18n.t("VEHICLE.PRODUCT.SEATS_UNIT", { seats: 2 }), value: "2" },
  { label: i18n.t("VEHICLE.PRODUCT.SEATS_UNIT", { seats: 3 }), value: "3" },
  { label: i18n.t("VEHICLE.PRODUCT.SEATS_UNIT", { seats: 4 }), value: "4" },
  { label: i18n.t("VEHICLE.PRODUCT.SEATS_UNIT", { seats: 5 }), value: "5" },
  { label: i18n.t("VEHICLE.PRODUCT.SEATS_UNIT", { seats: 6 }), value: "6" },
  { label: i18n.t("VEHICLE.PRODUCT.SEATS_UNIT", { seats: 7 }), value: "7" },
  { label: i18n.t("VEHICLE.PRODUCT.SEATS_UNIT", { seats: 8 }), value: "8" },
  { label: i18n.t("VEHICLE.PRODUCT.SEATS_UNIT", { seats: 9 }), value: "9" },
  { label: i18n.t("VEHICLE.PRODUCT.SEATS_UNIT", { seats: 10 }), value: "10" },
  { label: i18n.t("VEHICLE.PRODUCT.SEATS_UNIT", { seats: 11 }), value: "11" },
  { label: i18n.t("VEHICLE.PRODUCT.SEATS_UNIT", { seats: 12 }), value: "12" },
  { label: i18n.t("VEHICLE.PRODUCT.SEATS_UNIT", { seats: 13 }), value: "13" },
  { label: i18n.t("VEHICLE.PRODUCT.SEATS_UNIT", { seats: 14 }), value: "14" },
  { label: i18n.t("VEHICLE.PRODUCT.SEATS_UNIT", { seats: 15 }), value: "15" },
];

export const carDriveList = [
  { label: i18n.t("DROPDOWN.SELECT"), value: "" },
  { label: "FWD", value: "FWD" },
  { label: "RWD", value: "RWD" },
  { label: "4WD", value: "4WD" },
];

export const truckAxleList = [
  { label: i18n.t("DROPDOWN.SELECT"), value: "" },
  { label: i18n.t("VEHICLE.AXLE.FRONT"), value: "Front" },
  { label: i18n.t("VEHICLE.AXLE.MIDDLE"), value: "Middle" },
  { label: i18n.t("VEHICLE.AXLE.REAR"), value: "Rear" },
];

export const truckDriveList = baseList([
  { label: "4X2", value: "4X2" },
  { label: "6X2", value: "6X2" },
  { label: "4X4", value: "4X4" },
  { label: "6X4", value: "6X4" },
  { label: "8X4", value: "8X4" },
  { label: "10X4", value: "10X4" },
  { label: "12X4", value: "12X4" },
]);

export const truckWheelbaseList = [
  { label: i18n.t("DROPDOWN.SELECT"), value: "" },
  { label: i18n.t("VEHICLE.WHEELBASE.NORMAL"), value: "Normal" },
  { label: i18n.t("VEHICLE.WHEELBASE.SHORT"), value: "Short" },
  { label: i18n.t("VEHICLE.WHEELBASE.LONG"), value: "Long" },
  { label: i18n.t("VEHICLE.WHEELBASE.EXTRA_LONG"), value: "Extra Long" },
  { label: i18n.t("VEHICLE.WHEELBASE.EXTRA_LONG+"), value: "Extra Long+" },
  {
    label: i18n.t("VEHICLE.WHEELBASE.SUPER_EXTRA_LONG"),
    value: "Super Extra Long",
  },
  {
    label: i18n.t("VEHICLE.WHEELBASE.SUPER_EXTRA_LONG+"),
    value: "Super Extra Long+",
  },
];

export const truckEngineTypeList = baseList([
  { label: "InterCooler", value: "InterCooler" },
  { label: "CRDi", value: "CRDi" },
]);

export const truckCabinTypeList = baseList([
  { label: i18n.t("VEHICLE.CABIN.SINGLE"), value: "Single Cab" },
  { label: i18n.t("VEHICLE.CABIN.SUPER"), value: "Super Cab" },
  { label: i18n.t("VEHICLE.CABIN.KING"), value: "King Cab" },
  { label: i18n.t("VEHICLE.CABIN.DOUBLE"), value: "Double Cab" },
]);

export const busSeatsList = [
  { label: i18n.t("DROPDOWN.SELECT"), value: "" },
  {
    label: i18n.t("VEHICLE.PRODUCT.SEATS_UNIT", { seats: "~25" }),
    value: "~25",
  },
  {
    label: i18n.t("VEHICLE.PRODUCT.SEATS_UNIT", { seats: "26~30" }),
    value: "26~30",
  },
  {
    label: i18n.t("VEHICLE.PRODUCT.SEATS_UNIT", { seats: "31~35" }),
    value: "31~35",
  },
  {
    label: i18n.t("VEHICLE.PRODUCT.SEATS_UNIT", { seats: "36~40" }),
    value: "36~40",
  },
  {
    label: i18n.t("VEHICLE.PRODUCT.SEATS_UNIT", { seats: "41~45" }),
    value: "41~45",
  },
  {
    label: i18n.t("VEHICLE.PRODUCT.SEATS_UNIT", { seats: "46~" }),
    value: "46~",
  },
];

const commonOptionList: {
  [main: string]: {
    [sub: string]: Option[];
  };
} = {
  INEXTERIOR: {
    HEADLAMP: [
      { label: i18n.t("VEHICLE.OPTION.HID"), value: "hid_headlamp" },
      { label: i18n.t("VEHICLE.OPTION.LED"), value: "led_headlamp" },
    ],
    ALLOY_WHEEL: [
      { label: i18n.t("VEHICLE.OPTION.ALLOY_WHEEL"), value: "alloy_wheel" },
    ],
    STEERING_REMOCON: [
      {
        label: i18n.t("VEHICLE.OPTION.STEERING_REMOCON"),
        value: "steering_remocon",
      },
    ],
    POWER_STEERING: [
      {
        label: i18n.t("VEHICLE.OPTION.POWER_STEERING"),
        value: "power_steering",
      },
    ],
    HEAT_STEERING: [
      {
        label: i18n.t("VEHICLE.OPTION.HEAT_STEERING"),
        value: "heat_steering",
      },
    ],
    ECM: [{ label: i18n.t("VEHICLE.OPTION.ECM"), value: "ecm" }],
    AFM: [{ label: i18n.t("VEHICLE.OPTION.AFM"), value: "afm" }],
  },
  SEAT: {
    LEATHER_SEAT: [
      { label: i18n.t("VEHICLE.OPTION.LEATHER_SEAT"), value: "leather_seat" },
    ],
  },
  CONVINIENCE: {
    SMART_KEY: [
      { label: i18n.t("VEHICLE.OPTION.SMART_KEY"), value: "smart_key" },
    ],
    BUTTON_BOOT: [
      { label: i18n.t("VEHICLE.OPTION.BUTTON_BOOT"), value: "button_boot" },
    ],
    AIRCON: [
      { label: i18n.t("VEHICLE.OPTION.MANUAL"), value: "aircon" },
      { label: i18n.t("VEHICLE.OPTION.AUTO"), value: "auto_aircon" },
    ],
    NAVIGATION: [
      { label: i18n.t("VEHICLE.OPTION.NAVIGATION"), value: "navigation" },
    ],
    RADIO: [{ label: i18n.t("VEHICLE.OPTION.RADIO"), value: "radio" }],
    CD_PLAYER: [
      { label: i18n.t("VEHICLE.OPTION.CD_PLAYER"), value: "cd_player" },
    ],
    DVD_PLAYER: [
      { label: i18n.t("VEHICLE.OPTION.DVD_PLAYER"), value: "dvd_player" },
    ],
    USB_TERMINAL: [
      { label: i18n.t("VEHICLE.OPTION.USB_TERMINAL"), value: "usb_terminal" },
    ],
    BLUETOOTH: [
      { label: i18n.t("VEHICLE.OPTION.BLUETOOTH"), value: "bluetooth" },
    ],
    HUD: [{ label: i18n.t("VEHICLE.OPTION.HUD"), value: "hud" }],
    WIRELESS_DOORLOCK: [
      {
        label: i18n.t("VEHICLE.OPTION.WIRELESS_DOORLOCK"),
        value: "wireless_doorlock",
      },
    ],
    BLACKBOX: [{ label: i18n.t("VEHICLE.OPTION.BLACKBOX"), value: "blackbox" }],
  },
  SAFETY: {
    AIRBAG: [
      {
        label: i18n.t("VEHICLE.OPTION.DRIVER_SEAT"),
        value: "driver_airbag",
      },
      {
        label: i18n.t("VEHICLE.OPTION.PASSENGER_SEAT"),
        value: "passenger_airbag",
      },
    ],
    SIDE_AIRBAG: [
      { label: i18n.t("VEHICLE.OPTION.SIDE_AIRBAG"), value: "side_airbag" },
    ],
    CURTAIN_AIRBAG: [
      {
        label: i18n.t("VEHICLE.OPTION.CURTAIN_AIRBAG"),
        value: "curtain_airbag",
      },
    ],
    KNEE_AIRBAG: [
      { label: i18n.t("VEHICLE.OPTION.KNEE_AIRBAG"), value: "knee_airbag" },
    ],
    ABS: [{ label: i18n.t("VEHICLE.OPTION.ABS"), value: "abs" }],
    AEB: [{ label: i18n.t("VEHICLE.OPTION.AEB"), value: "aeb" }],
    LDWS: [{ label: i18n.t("VEHICLE.OPTION.LDWS"), value: "ldws" }],
    TCS: [{ label: i18n.t("VEHICLE.OPTION.TCS"), value: "tcs" }],
    ESP: [{ label: i18n.t("VEHICLE.OPTION.ESP"), value: "esp" }],
    TPMS: [{ label: i18n.t("VEHICLE.OPTION.TPMS"), value: "tpms" }],
    BSD: [{ label: i18n.t("VEHICLE.OPTION.BSD"), value: "bsd" }],
    LSD: [{ label: i18n.t("VEHICLE.OPTION.LSD"), value: "lsd" }],
    ECS: [{ label: i18n.t("VEHICLE.OPTION.ECS"), value: "ecs" }],
    CAMERA: [
      { label: i18n.t("VEHICLE.OPTION.FRONT_SIDE"), value: "front_camera" },
      { label: i18n.t("VEHICLE.OPTION.REAR_SIDE"), value: "rear_camera" },
    ],
    AROUND_VIEW_CAMERA: [
      {
        label: i18n.t("VEHICLE.OPTION.AROUND_VIEW_CAMERA"),
        value: "around_view_camera",
      },
    ],
    SPARE_TIRE: [
      { label: i18n.t("VEHICLE.OPTION.SPARE_TIRE"), value: "spare_tire" },
    ],
    PARK_SENSOR: [
      { label: i18n.t("VEHICLE.OPTION.FRONT_SIDE"), value: "front_sensor" },
      { label: i18n.t("VEHICLE.OPTION.REAR_SIDE"), value: "rear_sensor" },
    ],
    EPS: [{ label: i18n.t("VEHICLE.OPTION.EPS"), value: "eps" }],
  },
};

export const optionList: {
  [type: string]: {
    [main: string]: {
      [sub: string]: Option[];
    };
  };
} = {
  CAR: {
    INEXTERIOR: Object.assign({}, commonOptionList["INEXTERIOR"], {
      SUNROOF: [
        {
          label: i18n.t("VEHICLE.OPTION.NORMAL"),
          value: "normal_sunroof",
        },
        {
          label: i18n.t("VEHICLE.OPTION.PANORAMIC"),
          value: "panoramic_sunroof",
        },
      ],
      POWER_WINDOW: [
        { label: i18n.t("VEHICLE.OPTION.POWER_WINDOW"), value: "power_window" },
      ],
      POWER_DOORLOCK: [
        {
          label: i18n.t("VEHICLE.OPTION.POWER_DOORLOCK"),
          value: "power_doorlock",
        },
      ],
      POWER_TAILGATE: [
        {
          label: i18n.t("VEHICLE.OPTION.POWER_TAILGATE"),
          value: "power_tailgate",
        },
      ],
      PADDLE_SHIFT: [
        { label: i18n.t("VEHICLE.OPTION.PADDLE_SHIFT"), value: "paddle_shift" },
      ],
      REAR_SPOILER: [
        { label: i18n.t("VEHICLE.OPTION.REAR_SPOILER"), value: "rear_spoiler" },
      ],
      ROOF_RACK: [
        { label: i18n.t("VEHICLE.OPTION.ROOF_RACK"), value: "roof_rack" },
      ],
    }),
    SEAT: Object.assign({}, commonOptionList["SEAT"], {
      HEAT_SEAT: [
        {
          label: i18n.t("VEHICLE.OPTION.FRONT_SEAT"),
          value: "heat_front_seat",
        },
        {
          label: i18n.t("VEHICLE.OPTION.REAR_SEAT"),
          value: "heat_rear_seat",
        },
      ],
      POWER_SEAT: [
        {
          label: i18n.t("VEHICLE.OPTION.DRIVER_SEAT"),
          value: "power_driver_seat",
        },
        {
          label: i18n.t("VEHICLE.OPTION.PASSENGER_SEAT"),
          value: "power_passenger_seat",
        },
      ],
      POWER_SEAT2: [
        {
          label: i18n.t("VEHICLE.OPTION.REAR_SEAT"),
          value: "power_rear_seat",
        },
      ],
      VENT_SEAT: [
        {
          label: i18n.t("VEHICLE.OPTION.DRIVER_SEAT"),
          value: "ventilated_driver_seat",
        },
        {
          label: i18n.t("VEHICLE.OPTION.PASSENGER_SEAT"),
          value: "ventilated_passenger_seat",
        },
      ],
      VENT_SEAT2: [
        {
          label: i18n.t("VEHICLE.OPTION.REAR_SEAT"),
          value: "ventilated_rear_seat",
        },
      ],
      MEMORY_SEAT: [
        {
          label: i18n.t("VEHICLE.OPTION.DRIVER_SEAT"),
          value: "memory_driver_seat",
        },
        {
          label: i18n.t("VEHICLE.OPTION.PASSENGER_SEAT"),
          value: "memory_passenger_seat",
        },
      ],
    }),
    CONVINIENCE: Object.assign({}, commonOptionList["CONVINIENCE"], {
      CRUISE_CONTROL: [
        {
          label: i18n.t("VEHICLE.OPTION.NORMAL"),
          value: "cruise_control",
        },
        {
          label: i18n.t("VEHICLE.OPTION.ADAPTIVE"),
          value: "adaptive_cruise_control",
        },
      ],
      AUTO_SLIDE_DOOR: [
        {
          label: i18n.t("VEHICLE.OPTION.AUTO_SLIDE_DOOR"),
          value: "auto_slide_door",
        },
      ],
    }),
    SAFETY: Object.assign({}, commonOptionList["SAFETY"], {}),
  },
  TRUCK: {
    INEXTERIOR: Object.assign({}, commonOptionList["INEXTERIOR"], {
      SUNROOF: [{ label: i18n.t("VEHICLE.OPTION.SUNROOF"), value: "sunroof" }],
      GREASER: [{ label: i18n.t("VEHICLE.OPTION.GREASER"), value: "greaser" }],
      PTO: [{ label: i18n.t("VEHICLE.OPTION.PTO"), value: "pto" }],
      TACHOMETER: [
        { label: i18n.t("VEHICLE.OPTION.TACHOMETER"), value: "tachometer" },
      ],
      ROOF_SPOILER: [
        { label: i18n.t("VEHICLE.OPTION.ROOF_SPOILER"), value: "roof_spoiler" },
      ],
      POWER_LIFTGATE: [
        {
          label: i18n.t("VEHICLE.OPTION.POWER_LIFTGATE"),
          value: "power_liftgate",
        },
      ],
      POWER_CAPTILT: [
        {
          label: i18n.t("VEHICLE.OPTION.POWER_CAPTILT"),
          value: "power_captilt",
        },
      ],
    }),
    SEAT: Object.assign({}, commonOptionList["SEAT"], {
      HEAT_SEAT: [
        {
          label: i18n.t("VEHICLE.OPTION.HEAT_SEAT"),
          value: "heat_seat",
        },
      ],
      POWER_SEAT: [
        {
          label: i18n.t("VEHICLE.OPTION.POWER_SEAT"),
          value: "power_seat",
        },
      ],
      VENT_SEAT: [
        {
          label: i18n.t("VEHICLE.OPTION.VENT_SEAT"),
          value: "ventilated_seat",
        },
      ],
      MEMORY_SEAT: [
        {
          label: i18n.t("VEHICLE.OPTION.MEMORY_SEAT"),
          value: "memory_seat",
        },
      ],
    }),
    CONVINIENCE: Object.assign({}, commonOptionList["CONVINIENCE"], {
      TV: [{ label: i18n.t("VEHICLE.OPTION.TV"), value: "tv" }],
      REFRIGERATOR: [
        { label: i18n.t("VEHICLE.OPTION.REFRIGERATOR"), value: "refrigerator" },
      ],
      PURIFIER: [
        { label: i18n.t("VEHICLE.OPTION.PURIFIER"), value: "purifier" },
      ],
      PRE_HEATER: [
        { label: i18n.t("VEHICLE.OPTION.PRE_HEATER"), value: "pre_heater" },
      ],
      PRE_AIRCON: [
        { label: i18n.t("VEHICLE.OPTION.PRE_AIRCON"), value: "pre_aircon" },
      ],
      HEAT_BED: [
        { label: i18n.t("VEHICLE.OPTION.HEAT_BED"), value: "heat_bed" },
      ],
    }),
    SAFETY: Object.assign({}, commonOptionList["SAFETY"], {
      RETARDER: [
        { label: i18n.t("VEHICLE.OPTION.RETARDER"), value: "retarder" },
      ],
      EHS: [{ label: i18n.t("VEHICLE.OPTION.EHS"), value: "ehs" }],
      AIR_BRAKE: [
        { label: i18n.t("VEHICLE.OPTION.AIR_BRAKE"), value: "air_brake" },
      ],
    }),
  },
  BUS: {
    INEXTERIOR: Object.assign({}, commonOptionList["INEXTERIOR"], {
      SUNROOF: [{ label: i18n.t("VEHICLE.OPTION.SUNROOF"), value: "sunroof" }],
      GREASER: [{ label: i18n.t("VEHICLE.OPTION.GREASER"), value: "greaser" }],
      PTO: [{ label: i18n.t("VEHICLE.OPTION.PTO"), value: "pto" }],
      TACHOMETER: [
        { label: i18n.t("VEHICLE.OPTION.TACHOMETER"), value: "tachometer" },
      ],
      ROOF_SPOILER: [
        { label: i18n.t("VEHICLE.OPTION.ROOF_SPOILER"), value: "roof_spoiler" },
      ],
    }),
    SEAT: Object.assign({}, commonOptionList["SEAT"], {
      HEAT_SEAT: [
        {
          label: i18n.t("VEHICLE.OPTION.HEAT_SEAT"),
          value: "heat_seat",
        },
      ],
      POWER_SEAT: [
        {
          label: i18n.t("VEHICLE.OPTION.POWER_SEAT"),
          value: "power_seat",
        },
      ],
      VENT_SEAT: [
        {
          label: i18n.t("VEHICLE.OPTION.VENT_SEAT"),
          value: "ventilated_seat",
        },
      ],
      MEMORY_SEAT: [
        {
          label: i18n.t("VEHICLE.OPTION.MEMORY_SEAT"),
          value: "memory_seat",
        },
      ],
    }),
    CONVINIENCE: Object.assign({}, commonOptionList["CONVINIENCE"], {
      TV: [{ label: i18n.t("VEHICLE.OPTION.TV"), value: "tv" }],
      REFRIGERATOR: [
        { label: i18n.t("VEHICLE.OPTION.REFRIGERATOR"), value: "refrigerator" },
      ],
      PURIFIER: [
        { label: i18n.t("VEHICLE.OPTION.PURIFIER"), value: "purifier" },
      ],
      PRE_HEATER: [
        { label: i18n.t("VEHICLE.OPTION.PRE_HEATER"), value: "pre_heater" },
      ],
      PRE_AIRCON: [
        { label: i18n.t("VEHICLE.OPTION.PRE_AIRCON"), value: "pre_aircon" },
      ],
      KARAOKE: [{ label: i18n.t("VEHICLE.OPTION.KARAOKE"), value: "karaoke" }],
      AMPLIFIER: [
        { label: i18n.t("VEHICLE.OPTION.AMPLIFIER"), value: "amplifier" },
      ],
    }),
    SAFETY: Object.assign({}, commonOptionList["SAFETY"], {
      RETARDER: [
        { label: i18n.t("VEHICLE.OPTION.RETARDER"), value: "retarder" },
      ],
      EHS: [{ label: i18n.t("VEHICLE.OPTION.EHS"), value: "ehs" }],
      AIR_BRAKE: [
        { label: i18n.t("VEHICLE.OPTION.AIR_BRAKE"), value: "air_brake" },
      ],
    }),
  },
};

export const browserViewList = [
  { label: i18n.t("DROPDOWN.SELECT"), value: "" },
  { label: i18n.t("DROPDOWN.VIEW.VIEW_10"), value: "10" },
  { label: i18n.t("DROPDOWN.VIEW.VIEW_20"), value: "20" },
  { label: i18n.t("DROPDOWN.VIEW.VIEW_30"), value: "30" },
];

export const mobileViewList = [
  { label: i18n.t("DROPDOWN.SELECT"), value: "" },
  { label: i18n.t("DROPDOWN.VIEW.VIEW_5"), value: "5" },
  { label: i18n.t("DROPDOWN.VIEW.VIEW_10"), value: "10" },
  { label: i18n.t("DROPDOWN.VIEW.VIEW_15"), value: "15" },
];

export const sortList = [
  { label: i18n.t("DROPDOWN.SELECT"), value: "" },
  { label: i18n.t("DROPDOWN.ALIGN.ASC_CREATED"), value: "created" },
  { label: i18n.t("DROPDOWN.ALIGN.DSC_CREATED"), value: "-created" },
  { label: i18n.t("DROPDOWN.ALIGN.ASC_MODIFIED"), value: "modified" },
  { label: i18n.t("DROPDOWN.ALIGN.DSC_MODIFIED"), value: "-modified" },
  { label: i18n.t("DROPDOWN.ALIGN.ASC_YEAR"), value: "year" },
  { label: i18n.t("DROPDOWN.ALIGN.DSC_YEAR"), value: "-year" },
  { label: i18n.t("DROPDOWN.ALIGN.ASC_PRICE"), value: "price" },
  { label: i18n.t("DROPDOWN.ALIGN.DSC_PRICE"), value: "-price" },
];
