import axios from "axios";
import Resizer from "react-image-file-resizer";
// Redux
import { store } from "@/common/lib/redux/store";

export const checkLogin = () => {
  const state = store.getState();
  if (isNotNull(state.auth.loginToken)) {
    return true;
  }
  return false;
};

export const validatePassword = (value: string) => {
  let errorCode = 0;
  const containsDigit = /\d+/;
  const containsAlphabet = /[a-zA-Z]+/;
  const containsSpecial = /[`~!@#$%^&*()\-_=+{}[\]:;'"]+/;

  if (value.length < 8) {
    errorCode = errorCode | 1;
  }
  if (!containsDigit.test(value)) {
    errorCode = errorCode | 2;
  }
  if (!containsAlphabet.test(value)) {
    errorCode = errorCode | 4;
  }
  if (!containsSpecial.test(value)) {
    errorCode = errorCode | 8;
  }
  return errorCode;
};

export const getAutowiniCommission = (value: number) => {
  if (value >= 400 && value <= 726) {
    return 253;
  } else if (value >= 727 && value <= 1196) {
    return 273;
  } else if (value >= 1197 && value <= 1686) {
    return 303;
  } else if (value >= 1687 && value <= 2181) {
    return 313;
  } else if (value >= 2182 && value <= 2646) {
    return 318;
  } else if (value >= 2647 && value <= 3136) {
    return 353;
  } else if (value >= 3137 && value <= 3631) {
    return 363;
  } else if (value >= 3632 && value <= 4126) {
    return 368;
  } else if (value >= 4127 && value <= 4586) {
    return 373;
  } else if (value >= 4587 && value <= 5081) {
    return 413;
  } else if (value >= 5082 && value <= 5576) {
    return 418;
  } else if (value >= 5577 && value <= 6060) {
    return 423;
  } else if (value >= 6061 && value <= 6532) {
    return 439;
  } else if (value >= 6533 && value <= 7004) {
    return 467;
  } else if (value >= 7005 && value <= 7475) {
    return 495;
  } else if (value >= 7476 && value <= 8419) {
    return 524;
  } else if (value >= 8420 && value <= 9362) {
    return 580;
  } else if (value >= 9363 && value <= 10306) {
    return 637;
  } else if (value >= 10307 && value <= 11249) {
    return 693;
  } else if (value >= 11250 && value <= 12192) {
    return 750;
  } else if (value >= 12193 && value <= 14999) {
    return 807;
  } else if (value >= 15000 && value <= 15009) {
    return 825;
  } else if (value >= 15010) {
    return 826 + Math.floor((value - 15010) / 20);
  } else {
    return 0;
  }
};

export const getAutowiniBusCommission = (value: number) => {
  if (value >= 900 && value <= 1196) {
    return 498;
  } else if (value >= 1197 && value <= 1686) {
    return 528;
  } else if (value >= 1687 && value <= 2181) {
    return 538;
  } else if (value >= 2182 && value <= 2646) {
    return 543;
  } else if (value >= 2647 && value <= 3136) {
    return 578;
  } else if (value >= 3137 && value <= 3631) {
    return 588;
  } else if (value >= 3632 && value <= 4126) {
    return 593;
  } else if (value >= 4127 && value <= 4586) {
    return 598;
  } else if (value >= 4587 && value <= 5081) {
    return 638;
  } else if (value >= 5082 && value <= 5576) {
    return 643;
  } else if (value >= 5577 && value <= 6071) {
    return 648;
  } else if (value >= 6072 && value <= 6566) {
    return 653;
  } else if (value >= 6567 && value <= 8561) {
    return 658;
  } else if (value >= 8562 && value <= 9476) {
    return 663;
  } else if (value >= 9477 && value <= 14456) {
    return 748;
  } else if (value >= 14457 && value <= 19326) {
    return 768;
  } else if (value >= 19327 && value <= 24276) {
    return 898;
  } else if (value >= 24277 && value <= 29176) {
    return 948;
  } else if (value >= 29177 && value <= 49176) {
    return 1248;
  } else if (value >= 49177) {
    return 1448;
  } else {
    return 0;
  }
};

export const getTokenData = () => {
  const state = store.getState();
  const result = {
    id: null,
    puid: undefined,
    guid: undefined,
    name: undefined,
    username: undefined,
    group: null,
    image: undefined,
    is_staff: false,
    is_manager: false,
    is_superuser: false,
  };
  const tokenData = parseJWT(state.auth.loginToken);
  if (isNotNull(tokenData)) {
    result["id"] = tokenData.id;
    result["puid"] = tokenData.puid;
    result["guid"] = tokenData.guid;
    result["name"] = tokenData.name;
    result["username"] = tokenData.username;
    result["group"] = tokenData.group;
    result["image"] = tokenData.image;
    result["is_staff"] = tokenData.is_staff;
    result["is_manager"] = tokenData.is_manager;
    result["is_superuser"] = tokenData.is_superuser;
  }
  return result;
};
// return value : String | Number | Boolean | Undefined | Null | Object | Array | RegExp | Math | Date | Function
export const getType = (target: any) => {
  return Object.prototype.toString.call(target).slice(8, -1);
};

export const getUTCDate = (date: Date) => {
  return (
    String(date.getUTCFullYear()).padStart(4, "0") +
    "-" +
    String(date.getUTCMonth() + 1).padStart(2, "0") +
    "-" +
    String(date.getUTCDate()).padStart(2, "0") +
    "T" +
    String(date.getUTCHours()).padStart(2, "0") +
    ":" +
    String(date.getUTCMinutes()).padStart(2, "0")
  );
};

export const isNull = (value: any) => {
  return value === undefined || value === null || value === "" ? true : false;
};

export const isNotNull = (value: any) => {
  return !isNull(value);
};

export const isObjectEmpty = (object: Object) => {
  return object &&
    Object.keys(object).length === 0 &&
    Object.getPrototypeOf(object) === Object.prototype
    ? true
    : false;
};

export const isNotObjectEmpty = (object: Object) => {
  return !isObjectEmpty(object);
};

export const getDateFormat = (value: Date | null) => {
  if (value === null) {
    return "-";
  }
  const date = new Date(value);

  const year = String(date.getFullYear());
  const month = ("0" + (1 + date.getMonth())).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  return year + "/" + month + "/" + day;
};

export const getCommaFormat = (value: number) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const getOptionLabel = (value: string | undefined, list: Option[]) => {
  const result = list.filter((item: Option) => item.value === value);
  if (result.length > 0) {
    return result[0].label;
  }
  return "";
};

export const getOptionValue = (label: string, list: Option[]) => {
  const result = list.filter((item: Option) => item.label === label);
  if (result.length > 0) {
    return result[0].value;
  }
  return "";
};

export const openNewTab = (url: string) => {
  window.open(
    url,
    "w_" + Date.now() + Math.floor(Math.random() * 1000000).toString()
  );
};

export const parseJWT = (token: string) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export const resizeImage = (
  file: File,
  width: number,
  height: number,
  quality: number,
  rotate: number,
  output: string
) => {
  const promise = new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      width, // width
      height, // height
      "JPEG",
      quality, // quality
      rotate, // rotate
      (image: any) => {
        resolve(image);
      },
      output
    );
  });
  return promise;
};

export const getImageFile = async (fileURL: string) => {
  const result = await axios({
    method: "get",
    url: fileURL + "?_",
    responseType: "blob",
    withCredentials: false,
  }).then((response) => {
    const blob = new Blob([response.data]);
    const result = fileURL.match(/\/media\/(.*)$/);
    if (result !== null) {
      const fileName = result[1];
      const file = new File([blob], fileName, { type: response.data.type });
      return file;
    }
  });
  return result;
};

export const getCountryColor = (code: string) => {
  if (["GT", "HN", "SV", "NI", "CR", "PA"].includes(code)) {
    return "CA"; // 중미
  } else if (["CO", "EC", "PE", "CL", "AR", "UY", "PY", "BR"].includes(code)) {
    return "SA"; // 남미
  } else if (["DO", "CU", "JM", "HT", "PR"].includes(code)) {
    return "CB"; // 카리브해
  } else if (["RU", "GE", "AZ", "UA", "MD", "LT"].includes(code)) {
    return "RU"; // 러시아 방면
  } else {
    return "";
  }
};
