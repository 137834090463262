import { Markup } from "interweave";
import { useTranslation } from "react-i18next";
// Component
import { Card, Divider, Theme, Typography } from "@mui/material";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
// Styles
import { useStyles } from "@/common/lib/style/hooks";

const styles = (theme: Theme) => ({
  container: {
    margin: "0 2vw",
    userSelect: "none",
  },
  divider: {
    margin: "1.5vh -2vw",
  },
  card: {
    display: "flex",
  },
  profileOuterDiv: {
    margin: "1.5vh 0",
    "&.right": {
      "& > div": {
        justifyContent: "flex-end",
        "& > p": {
          textAlign: "end",
        },
      },
      "& > p": {
        textAlign: "end",
      },
    },
  },
  profileInnerDiv: {
    display: "flex",
    alignItems: "center",
    marginBottom: "1vh",
  },
  img: {
    width: "100%",
  },
  icon: {
    color: theme.palette.primary.main,
    fontSize: "2.5rem",
    marginRight: "2.5vw",
  },
  totalTitle: {
    fontSize: "1.3rem",
    fontWeight: "bold",
    cursor: "default",
    "& > span > span": {
      color: theme.palette.primary.main,
    },
  },
  title: {
    fontSize: "1.15rem",
    fontWeight: "bold",
    wordSpacing: "-1.5px",
    wordBreak: "keep-all",
  },
  content: {
    cursor: "default",
    lineHeight: "1.85",
    fontSize: "0.85rem",
    margin: "0 0 1vh 0",
    wordSpacing: "-1.5px",
    wordBreak: "keep-all",
    "& > span > span": {
      fontSize: "1.1rem",
      fontWeight: "bold",
      color: theme.palette.primary.main,
    },
  },
  comment: {
    fontSize: "1.1rem",
    fontWeight: 500,
    wordSpacing: "-1.5px",
    wordBreak: "keep-all",
  },
});

const ProfileContainer = () => {
  const classes = useStyles(styles);
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <Typography className={classes.totalTitle}>
        <Markup content={t("PROFILE.TITLE_MOBILE")} />
      </Typography>
      <Divider className={classes.divider} />
      <div className={classes.profileOuterDiv}>
        <div className={classes.profileInnerDiv}>
          <MiscellaneousServicesIcon className={classes.icon} />
          <Typography className={classes.title}>
            <Markup content={t("PROFILE.PROFILE.TITLE1_MOBILE")} />
          </Typography>
        </div>
        <Divider className={classes.divider} />
        <Card className={classes.card}>
          <img
            alt=""
            src={require(`@/common/images/profile/main_image1.png`)}
            className={classes.img}
          />
        </Card>
        <Divider className={classes.divider} />
        <Typography className={classes.comment}>
          <Markup content={t("PROFILE.PROFILE.COMMENT1_1_MOBILE")} />
        </Typography>
        <p className={`${classes.content} left`}>
          <Markup content={t("PROFILE.PROFILE.CONTENT1_1_MOBILE")} />
        </p>
        <Typography className={classes.comment}>
          <Markup content={t("PROFILE.PROFILE.COMMENT1_2_MOBILE")} />
        </Typography>
        <p className={`${classes.content} left`}>
          <Markup content={t("PROFILE.PROFILE.CONTENT1_2_MOBILE")} />
        </p>
      </div>
      <Divider className={classes.divider} />
      <div className={`${classes.profileOuterDiv} right`}>
        <div className={classes.profileInnerDiv}>
          <EmojiEventsIcon className={classes.icon} />
          <Typography className={classes.title}>
            <Markup content={t("PROFILE.PROFILE.TITLE2_MOBILE")} />
          </Typography>
        </div>
        <Divider className={classes.divider} />
        <Card className={classes.card}>
          <img
            alt=""
            src={require(`@/common/images/profile/main_image2.png`)}
            className={classes.img}
          />
        </Card>
        <Divider className={classes.divider} />
        <Typography className={classes.comment}>
          <Markup content={t("PROFILE.PROFILE.COMMENT2_1_MOBILE")} />
        </Typography>
        <Typography className={classes.comment}>
          <Markup content={t("PROFILE.PROFILE.COMMENT2_2_MOBILE")} />
        </Typography>
        <p className={classes.content}>
          <Markup content={t("PROFILE.PROFILE.CONTENT2_MOBILE")} />
        </p>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.profileOuterDiv}>
        <div className={classes.profileInnerDiv}>
          <MiscellaneousServicesIcon className={classes.icon} />
          <Typography className={classes.title}>
            {t("PROFILE.PROFILE.TITLE3")}
          </Typography>
        </div>
        <Divider className={classes.divider} />
        <Card className={classes.card}>
          <img
            alt=""
            src={require(`@/common/images/profile/main_image3.png`)}
            className={classes.img}
          />
        </Card>
        <Divider className={classes.divider} />
        <Typography className={classes.comment}>
          {t("PROFILE.PROFILE.COMMENT3_1")}
        </Typography>
        <p className={`${classes.content} left`}>
          <Markup content={t("PROFILE.PROFILE.CONTENT3_1")} />
        </p>
        <Typography className={classes.comment}>
          <Markup content={t("PROFILE.PROFILE.COMMENT3_2_MOBILE")} />
        </Typography>
        <p className={`${classes.content} left`}>
          <Markup content={t("PROFILE.PROFILE.CONTENT3_2_MOBILE")} />
        </p>
      </div>
      <Divider className={classes.divider} />
      <div className={`${classes.profileOuterDiv} right`}>
        <div className={classes.profileInnerDiv}>
          <SupportAgentIcon className={classes.icon} />
          <Typography className={classes.title}>
            <Markup content={t("PROFILE.PROFILE.TITLE4_MOBILE")} />
          </Typography>
        </div>
        <Divider className={classes.divider} />
        <Card className={classes.card}>
          <img
            alt=""
            src={require(`@/common/images/profile/main_image4.png`)}
            className={classes.img}
          />
        </Card>
        <Divider className={classes.divider} />
        <Typography className={classes.comment}>
          {t("PROFILE.PROFILE.COMMENT4_1")}
        </Typography>
        <p className={classes.content}>
          <Markup content={t("PROFILE.PROFILE.CONTENT4_1")} />
        </p>
        <Typography className={classes.comment}>
          {t("PROFILE.PROFILE.COMMENT4_2")}
        </Typography>
        <p className={classes.content}>
          <Markup content={t("PROFILE.PROFILE.CONTENT4_2_MOBILE")} />
        </p>
      </div>
    </div>
  );
};

export default ProfileContainer;
