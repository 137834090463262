/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
// Redux
import { useTypedSelector } from "@/common/lib/redux/store";
// Components
import { Card, CardContent, Theme, Typography } from "@mui/material";
import { FavoriteButton, Image, Status } from "@/common/components";
// Libs
import {
  getAutowiniCommission,
  getAutowiniBusCommission,
  isNotNull,
  openNewTab,
} from "@/common/lib/common";
// Styles
import { useStyles } from "@/common/lib/style/hooks";

const styles = (theme: Theme) => ({
  card: {
    width: "25%",
    boxSizing: "border-box",
    border: "1px solid " + theme.palette.divider,
    borderRadius: "10px !important",
    boxShadow: "0 0 5px " + theme.palette.divider + " !important",
    position: "relative",
  },
  imageContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box",
    padding: "0 !important",
    marginBottom: 10,
  },
  image: {
    width: "100%",
    aspectRatio: "1.33",
    objectFit: "contain",
  },
  cardContent: {
    height: 110,
    position: "relative",
    boxSizing: "border-box",
    padding: "0 15px 15px !important",
  },
  cardTitle: {
    maxHeight: 40,
    fontWeight: "bold",
    lineHeight: 1.25,
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordSpacing: "-1.5px",
    "&.index": {
      fontSize: 16,
    },
    "&.list": {
      fontSize: 15,
    },
  },
  cardInfo: {
    "& > ul": {
      margin: 0,
      padding: 0,
      listStyle: "none",
      lineHeight: "1",
      maxHeight: "2.5em",
      overflow: "hidden",
      "& > li:first-child": {
        marginLeft: 0,
      },
      "& > li:last-child": {
        marginRight: 0,
      },
      "& > li": {
        display: "inline-block",
        margin: "0 0.15em",
        wordSpacing: "-0.05em",
        fontSize: "0.825em",
      },
    },
  },
  statusInfo: {
    position: "absolute",
    left: 15,
    bottom: 15,
    "& > span": {
      fontSize: "0.75em",
      width: "6.5em",
      height: "2em",
      borderRadius: "0.35em",
      wordSpacing: "-0.05rem",
      letterSpacing: "-0.025px",
    },
  },
  priceInfo: {
    position: "absolute",
    right: 15,
    bottom: 15,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "center",
    "& > h4": {
      fontSize: "1.15em",
      fontWeight: "bold",
      "&.discount": {
        color: theme.palette.error.main,
      },
    },
    "& > h5": {
      fontSize: "0.85em",
      lineHeight: 1,
      textDecoration: "line-through",
      textDecorationThickness: 1,
      color: theme.palette.text.disabled,
    },
  },
  border: {
    width: "100%",
    height: "100%",
    position: "absolute",
    boxSizing: "border-box",
    border: "4.5px solid transparent",
    zIndex: 2,
    "&:hover": {
      border: "4.5px solid " + theme.palette.primary.main,
      borderRadius: 10,
    },
    cursor: "pointer",
  },
  discountBorder: {
    overflow: "hidden",
    position: "absolute",
    width: "100%",
    height: "100%",
  },
  discountLabel: {
    zIndex: 1,
    position: "absolute",
    top: 14,
    left: -31,
    width: 125,
    height: 35,
    transform: "rotate(-45deg)",
    background: theme.palette.error.main,
    color: "white",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 12,
    lineHeight: 1.25,
    cursor: "default",
    opacity: 0.95,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "& > p": {
      margin: 0,
      "&.discount": {
        fontSize: 14,
      },
    },
  },
  favorite: {
    position: "absolute",
    top: 0,
    right: 0,
    "& > svg": {
      fontSize: "1.85rem",
    },
  },
});

interface propType {
  data: VehicleData;
}

const CarouselListItem = ({ data }: propType) => {
  const classes = useStyles(styles);
  const { loginToken } = useTypedSelector((state) => state.auth);

  const [isDiscount, setIsDiscount] = useState(false);

  const getMainTitle = () => {
    const titles = [];
    titles.push(data.product.year);
    titles.push(data.product.maker);
    titles.push(data.product.detail_model);

    return titles.join(" ");
  };

  useEffect(() => {
    if (data.sales.discount_expired !== null) {
      const todayDate = new Date();
      const expireDate = new Date(data.sales.discount_expired);
      expireDate.setHours(expireDate.getHours() + 9);

      if (expireDate >= todayDate) {
        setIsDiscount(true);
      }
    }
  }, []);

  return (
    <Card
      className={classes.card}
      onClick={() => openNewTab("/vehicle/detail/" + data.product.uid)}
    >
      <div className={classes.border} />
      {isNotNull(loginToken) && isDiscount && (
        <div className={classes.discountBorder}>
          <div className={classes.discountLabel}>
            <p>SPECIAL</p>
            <p className="discount">DISCOUNT</p>
          </div>
        </div>
      )}
      <CardContent className={classes.imageContent}>
        <Image alt="" src={data.media.image[0]} className={classes.image} />
      </CardContent>
      <CardContent className={classes.cardContent}>
        <Typography className={classes.cardTitle}>{getMainTitle()}</Typography>
        <div className={classes.cardInfo}>
          {data.product.type === "CAR" && (
            <ul>
              <li>{data.product.exhaust} cc</li>
              <li>{data.product.mission}</li>
              {data.car_product !== undefined && (
                <li>{data.car_product.seats} seats</li>
              )}
            </ul>
          )}
          {data.product.type === "TRUCK" && (
            <ul>
              {data.truck_product !== undefined && (
                <>
                  {data.truck_product.category === "TopTruck" ||
                    (data.truck_product.category === "WingbodyTruck" && (
                      <li>{data.truck_product.sub_category}</li>
                    ))}
                  <li>{data.truck_product.tonner.toFixed(1)} t</li>
                  <li>{data.truck_product.engine_type}</li>
                </>
              )}
              <li>{data.product.exhaust} cc</li>
            </ul>
          )}
          {data.product.type === "BUS" && (
            <ul>
              <li>{data.product.horse_power}HP</li>
              {data.bus_product !== undefined && (
                <li>{data.bus_product.seats}seats</li>
              )}
            </ul>
          )}
        </div>
        <div className={classes.statusInfo}>
          <Status status={data.sales.status} />
        </div>
        <div className={classes.priceInfo}>
          {/* {isNotNull(loginToken) ? ( */}
          {isDiscount ? (
            <>
              <Typography variant="h5">
                USD{" "}
                {(
                  data.sales.price +
                  (data.product.type === "CAR" || data.product.type === "TRUCK"
                    ? getAutowiniCommission(data.sales.price)
                    : getAutowiniBusCommission(data.sales.price))
                ).toLocaleString()}
              </Typography>
              <Typography variant="h4" className="discount">
                USD{" "}
                {(
                  data.sales.price +
                  (data.product.type === "CAR" || data.product.type === "TRUCK"
                    ? getAutowiniCommission(data.sales.price)
                    : getAutowiniBusCommission(data.sales.price)) -
                  (data.sales.discount !== null ? data.sales.discount : 0)
                ).toLocaleString()}
              </Typography>
            </>
          ) : (
            <Typography variant="h4">
              USD{" "}
              {(
                data.sales.price +
                (data.product.type === "CAR" || data.product.type === "TRUCK"
                  ? getAutowiniCommission(data.sales.price)
                  : getAutowiniBusCommission(data.sales.price))
              ).toLocaleString()}
            </Typography>
          )}
          {/* ) : (
            <Typography variant="h4">USD *,***</Typography>
          )} */}
        </div>
      </CardContent>
      <FavoriteButton
        className={classes.favorite}
        wish_id={data.wish !== undefined ? data.wish.id : null}
        product_uid={data.product.uid}
      />
    </Card>
  );
};

export default CarouselListItem;
