/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-eval */
import { useCallback, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { getCountries, CountryCode } from "libphonenumber-js";
import classNames from "classnames";
// Components
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// Libs
import { isNotNull } from "@/common/lib/common";

const styles = (theme: Theme, color: string = "primary") => ({
  formControl: {
    "&:not(.MuiFormControl-fullWidth)": {
      width: "fit-content",
      height: "fit-content",
    },
    "&&": {
      "&:hover": {
        "& .MuiInputLabel-root:not(.Mui-error)": {
          color: eval(`theme.palette.${color}.main`) + "!important",
        },
        "& .MuiInputBase-root:not(.Mui-error)": {
          color: eval(`theme.palette.${color}.main`) + "!important",
          "& svg[data-testid='ArrowDropDownIcon']": {
            color: eval(`theme.palette.${color}.main`) + "!important",
          },
          "& > .MuiOutlinedInput-notchedOutline": {
            borderColor: eval(`theme.palette.${color}.main`) + "!important",
          },
          "&.MuiInput-underline::before": {
            borderColor: eval(`theme.palette.${color}.main`) + "!important",
          },
          "&.MuiFilledInput-underline::before": {
            borderColor: eval(`theme.palette.${color}.main`) + "!important",
          },
        },
        "& .MuiIconButton-root": {
          visibility: "visible",
        },
      },
      "& .MuiInputBase-root.Mui-error": {
        color: theme.palette.error.main,
        "& .MuiSvgIcon-root": {
          color: theme.palette.error.main,
        },
      },
      "& .MuiInputLabel-root:not(.Mui-error)": {
        "&.Mui-focused": {
          color: eval(`theme.palette.${color}.main`),
        },
        "&:not(.Mui-focused)": {
          color: theme.palette.text.primary,
        },
      },
      "& .MuiInputBase-root:not(.Mui-error)": {
        "&.Mui-focused": {
          color: eval(`theme.palette.${color}.main`),
          "& svg[data-testid='ArrowDropDownIcon']": {
            color: eval(`theme.palette.${color}.main`),
          },
          "& > .MuiOutlinedInput-notchedOutline": {
            borderColor: eval(`theme.palette.${color}.main`),
          },
          "&.MuiInput-underline::before": {
            borderColor: eval(`theme.palette.${color}.main`),
          },
          "&.MuiFilledInput-underline::before": {
            borderColor: eval(`theme.palette.${color}.main`),
          },
        },
        "&:not(.Mui-focused)": {
          color: theme.palette.text.primary,
          "& svg[data-testid='ArrowDropDownIcon']": {
            color: theme.palette.text.primary,
          },
          "& > .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.text.primary,
          },
          "&.MuiInput-underline::before": {
            borderColor: theme.palette.text.primary,
          },
          "&.MuiFilledInput-underline::before": {
            borderColor: theme.palette.text.primary,
          },
        },
      },
    },
  },
  inputLabel: {
    width: "calc(100% - 2.5em)",
    top: "calc(50% - 0.71875em)",
    "&.MuiInputLabel-shrink": {
      width: "calc(150% - 2.5em)",
    },
    "&.MuiInputLabel-outlined": {
      transform: "translate(14px, 0) scale(1)",
      "&.Mobile": {
        [theme.breakpoints.up("md")]: {
          "&.MuiInputLabel-shrink": {
            transform: "translate(14px, -55%) scale(0.75)",
          },
        },
        [theme.breakpoints.between("sm", "md")]: {
          "&.MuiInputLabel-shrink": {
            transform: "translate(14px, -65%) scale(0.75)",
          },
        },
        [theme.breakpoints.down("sm")]: {
          "&.MuiInputLabel-shrink": {
            transform: "translate(14px, -75%) scale(0.75)",
          },
        },
      },
      "&:not(.Mobile)": {
        "&.MuiInputLabel-shrink": {
          transform: "translate(14px, -115%) scale(0.75)",
        },
      },
    },
    "&.MuiInputLabel-filled": {
      transform: "translate(12px, 0) scale(1)",
      [theme.breakpoints.up("md")]: {
        "&.MuiInputLabel-shrink": {
          transform: "translate(12px, -50%) scale(0.75)",
        },
      },
      [theme.breakpoints.between("sm", "md")]: {
        "&.MuiInputLabel-shrink": {
          transform: "translate(12px, -45%) scale(0.75)",
        },
      },
      [theme.breakpoints.down("sm")]: {
        "&.MuiInputLabel-shrink": {
          transform: "translate(12px, -40%) scale(0.75)",
        },
      },
    },
    "&.MuiInputLabel-standard": {
      transform: "unset",
      "&.MuiInputLabel-shrink": {
        transform: "translate(0, -70%) scale(0.75)",
      },
    },
  },
  select: {
    /* width 설정은 여기서 */
    "&.Size-small": {
      "&.Mobile": {
        [theme.breakpoints.up("md")]: {
          width: 144,
        },
        [theme.breakpoints.between("sm", "md")]: {
          width: 108,
        },
        [theme.breakpoints.down("sm")]: {
          width: 72.5,
        },
      },
      "&:not(.Mobile)": {
        width: 72.5,
      },
    },
    "&.Size-normal": {
      "&.Mobile": {
        [theme.breakpoints.up("md")]: {
          width: 215,
        },
        [theme.breakpoints.between("sm", "md")]: {
          width: 165,
        },
        [theme.breakpoints.down("sm")]: {
          width: 107.5,
        },
      },
      "&:not(.Mobile)": {
        width: 107.5,
      },
    },
    "&.Size-middle": {
      "&.Mobile": {
        [theme.breakpoints.up("md")]: {
          width: 285,
        },
        [theme.breakpoints.between("sm", "md")]: {
          width: 215,
        },
        [theme.breakpoints.down("sm")]: {
          width: 142.5,
        },
      },
      "&:not(.Mobile)": {
        width: 142.5,
      },
    },
    "&.Size-large": {
      "&.Mobile": {
        [theme.breakpoints.up("md")]: {
          width: 350,
        },
        [theme.breakpoints.between("sm", "md")]: {
          width: 262.5,
        },
        [theme.breakpoints.down("sm")]: {
          width: 175,
        },
      },
      "&:not(.Mobile)": {
        width: 175,
      },
    },
    "& > .MuiSelect-select": {
      display: "flex",
      alignItems: "center",
      paddingRight: "calc(8px + 1.5em) !important",
      "& > img": {
        marginRight: 10,
      },
      "& > .MuiTypography-body1": {
        width: "100%",
        fontSize: "0.925rem",
      },
      "&:focus": {
        backgroundColor: "unset",
      },
    },
    "& > .MuiInputBase-inputAdornedEnd": {
      paddingRight: "calc(4px + 2.5em) !important",
    },
    "& > .MuiIconButton-root": {
      width: "1em",
      height: "1em",
      padding: 4,
      "& > svg": {
        fontSize: "1.25rem",
      },
      "&:hover": {
        color: eval(`theme.palette.${color}.main`),
      },
    },
    "&.MuiFilledInput-root": {
      backgroundColor: "inherit",
    },
    "&.MuiFilledInput-root, &.MuiOutlinedInput-root": {
      "& > .MuiIconButton-root": {
        right: "calc(8px + 0.5em)",
      },
    },
    "&.MuiInput-root": {
      "& > .MuiSvgIcon-root": {
        marginRight: -2,
      },
      "& > .MuiIconButton-root": {
        right: "calc(-6px + 1em)",
      },
    },
  },
  menuItem: {
    /* width 설정은 여기서 */
    minHeight: "unset",
    "&.MuiMenuItem-root": {
      "&:hover": {
        color: eval(`theme.palette.${color}.main`),
      },
    },
    "&.Mui-selected": {
      color: eval(`theme.palette.${color}.main`),
    },
    "& > img": {
      marginRight: 10,
    },
    "& > .MuiTypography-body1": {
      width: "100%",
      fontSize: "0.925rem",
    },
  },
});
// size : small, normal1, normal2, big
const NationDropdown = ({
  label,
  size = "normal",
  color = "primary",
  value,
  dispatch,
  isClearable = false,
  formControlClassName,
  inputLabelClassName,
  selectClassName,
  menuItemClassName,
  ...props
}: any) => {
  const theme = useTheme();
  const classes = useStyles(styles(theme, color));
  const [labelId, setLabelId] = useState("");
  const [countryList, setCountryList] = useState<
    {
      code: CountryCode;
      region: string | undefined;
    }[]
  >([]);

  const onClose = useCallback(() => {
    dispatch("");
  }, [dispatch]);

  useEffect(() => {
    const randInt = Math.floor(Math.random() * 1000000);
    setLabelId(randInt + "Label");

    const regionNames = new Intl.DisplayNames(["en"], { type: "region" });
    const countryList: {
      code: CountryCode;
      region: string | undefined;
    }[] = [];
    getCountries().forEach((countryCode) => {
      if (!["AC", "TA"].includes(countryCode)) {
        countryList.push({
          code: countryCode,
          region: regionNames.of(countryCode),
        });
      }
    });
    const countrySortList = countryList.sort((a, b) => {
      if (a.region !== undefined && b.region !== undefined) {
        return a.region.localeCompare(b.region);
      } else {
        return a.code.localeCompare(b.code);
      }
    });
    setCountryList(countrySortList);
  }, []);

  return (
    <FormControl
      size="small"
      className={classNames(classes.formControl, {
        formControlClassName: formControlClassName !== undefined,
      })}
      {...props}
    >
      {label && (
        <InputLabel
          id={labelId}
          className={classNames(
            classes.inputLabel,
            { inputLabelClassName: inputLabelClassName !== undefined },
            { Mobile: isMobile }
          )}
        >
          {label}
        </InputLabel>
      )}
      <Select
        label={label}
        labelId={labelId}
        value={isNotNull(value) ? value : ""}
        onChange={(e) => dispatch(e.target.value)}
        multiple={false}
        className={classNames(
          classes.select,
          { selectClassName: selectClassName !== undefined },
          props.fullWidth === undefined ? `Size-${size}` : "",
          { Mobile: isMobile }
        )}
        endAdornment={
          value && isClearable ? (
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          ) : undefined
        }
      >
        <MenuItem
          disabled
          value=""
          sx={{
            display: "none",
          }}
        >
          <em>-</em>
        </MenuItem>
        {countryList.map((country: any, index: number) => {
          return (
            <MenuItem
              key={index}
              value={country.code}
              className={classNames(
                classes.menuItem,
                { menuItemClassName: menuItemClassName !== undefined },
                { Mobile: isMobile }
              )}
            >
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${country.code?.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${country.code?.toLowerCase()}.png 2x`}
                alt=""
              />
              <Typography variant="body1">{country.region}</Typography>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default NationDropdown;
