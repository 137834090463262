/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { v4 as uuidv4 } from "uuid";
import { Markup } from "interweave";
import { parsePhoneNumberFromString } from "libphonenumber-js";
// Redux
import { setLoginToken } from "@/common/lib/redux/actions/authAction";
import {
  loadingOn,
  loadingOff,
  setErrorText,
} from "@/common/lib/redux/actions/commonAction";
import { initWishList } from "@/common/lib/redux/actions/globalAction";
import { useTypedDispatch, useTypedSelector } from "@/common/lib/redux/store";
// Components
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider,
  IconButton,
  InputAdornment,
  Modal,
  Paper,
  Theme,
  Typography,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CollectionsIcon from "@mui/icons-material/Collections";
import {
  Button,
  Image,
  Input,
  NationAutocomplete,
  PhoneInput,
} from "@/common/components";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// API
import { onError } from "@/common/lib/api/common";
import { logoutAPI, refreshTokenAPI } from "@/common/lib/api/auth";
import {
  getUserInfo,
  patchUserInfo,
  checkUserDuplicate,
} from "@/common/lib/api/user";
// Libs
import {
  getTokenData,
  isNull,
  isNotNull,
  resizeImage,
  validatePassword,
} from "@/common/lib/common";
import { emailRegex, phoneRegex } from "@/common/lib/regex";

const styles = (theme: Theme) => ({
  container: {
    border: "1px solid " + theme.palette.divider,
    padding: "3vh 6vw",
    userSelect: "none",
  },
  title: {
    "& > h1": {
      userSelect: "none",
      fontSize: "1.5rem",
      fontWeight: "bold",
      color: theme.palette.primary.main,
      textAlign: "center",
      marginBottom: "1.5vh",
    },
    "& > ul.content": {
      margin: 0,
      paddingLeft: "3vw",
      userSelect: "none",
      "& > li": {
        fontSize: "0.825rem",
        marginBottom: "0.25vh",
        "&::marker": {
          fontSize: "0.75rem",
        },
        "& > span > span": {
          color: theme.palette.error.main,
          fontWeight: "bold",
          padding: "0 1vw",
        },
        "&.listStyleNone": {
          listStyleType: "none",
          fontSize: "0.8rem",
        },
      },
    },
  },
  topDiv: {
    "& > div:first-child": {
      position: "relative",
      width: "80%",
      aspectRatio: "1",
      margin: "0 auto",
    },
    "& > div:last-child": {
      display: "flex",
      flexDirection: "column",
      "& > p": {
        userSelect: "none",
        fontSize: "0.925rem",
        fontWeight: "bold",
        "&.require-value::after": {
          content: "'*'",
          color: theme.palette.error.main,
          padding: "1vw",
        },
        "&.unique-value::after": {
          content: "'**'",
          color: theme.palette.error.main,
          padding: "1vw",
        },
      },
      "& > .MuiFormControl-root": {
        marginBottom: "1vh",
        "&.good": {
          "& > div": {
            color: theme.palette.primary.main,
          },
          "& .MuiFormHelperText-root, & .MuiSvgIcon-root": {
            color: theme.palette.primary.main,
          },
        },
        "&.bad": {
          "& > div": {
            color: theme.palette.error.main,
          },
          "& .MuiFormHelperText-root, & .MuiSvgIcon-root": {
            color: theme.palette.error.main,
          },
        },
      },
      "& > span": {
        userSelect: "none",
        fontSize: "0.85rem",
        fontWeight: "bold",
        alignSelf: "flex-end",
        color: theme.palette.grey[700],
        marginTop: "1vh",
        "&:hover": {
          cursor: "pointer",
          textDecoration: "underline",
          color: theme.palette.primary.main,
        },
      },
    },
  },
  middleDiv: {
    "& > h1": {
      userSelect: "none",
      fontSize: "1.15rem",
      fontWeight: "bold",
      color: theme.palette.primary.main,
      marginBottom: "1.5vh",
    },
    "& > div.content": {
      "& > p": {
        userSelect: "none",
        fontSize: "0.925rem",
        fontWeight: "bold",
        "&.require-value::after": {
          content: "'*'",
          color: theme.palette.error.main,
          padding: "1vw",
        },
        "&.unique-value::after": {
          content: "'**'",
          color: theme.palette.error.main,
          padding: "1vw",
        },
      },
      "& > .MuiFormControl-root": {
        marginBottom: "1vh",
        "&.good": {
          "& > div": {
            color: theme.palette.primary.main,
          },
          "& .MuiFormHelperText-root, & .MuiSvgIcon-root": {
            color: theme.palette.primary.main,
          },
        },
        "&.bad": {
          "& > div": {
            color: theme.palette.error.main,
          },
          "& .MuiFormHelperText-root, & .MuiSvgIcon-root": {
            color: theme.palette.error.main,
          },
        },
      },
      "& > .MuiAutocomplete-root": {
        marginBottom: "1vh",
      },
    },
  },
  bottomDiv: {
    display: "flex",
    flexDirection: "column",
    "& > button": {
      width: "100%",
      fontSize: "1.15rem",
      fontWeight: "bold",
      marginBottom: "1.5vh",
    },
    "& > span": {
      userSelect: "none",
      fontSize: "0.85rem",
      fontWeight: "bold",
      alignSelf: "flex-end",
      color: theme.palette.grey[600],
      "&:hover": {
        cursor: "pointer",
        textDecoration: "underline",
        color: theme.palette.primary.main,
      },
    },
  },
  divider: {
    borderStyle: "dotted",
    borderColor: "rgba(0, 0, 0, 0.38)",
    margin: "2vh 0",
  },
  userIcon: {
    width: "100%",
    height: "100%",
    color: "rgba(0, 0, 0, 0.54)",
  },
  userImage: {
    width: "100%",
    aspectRatio: "1",
    borderRadius: "50%",
    objectFit: "cover",
    padding: "3vw",
  },
  uploadButton: {
    position: "absolute",
    bottom: 0,
    right: 0,
    border: "1vw solid rgba(0, 0, 0, 0.54)",
    "& > svg": {
      fontSize: "1.5rem",
    },
    "&:hover": {
      borderColor: theme.palette.primary.main,
      "& > svg": {
        color: theme.palette.primary.main,
      },
    },
  },
  uploadInput: {
    display: "none",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    userSelect: "none",
  },
  paper: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    width: "90%",
    padding: "4vh 6vw",
    borderRadius: "5vw",
    "& > h1": {
      fontSize: "1.25rem",
      fontWeight: "bold",
      color: theme.palette.primary.main,
      marginBottom: "0.75vh",
    },
    "& > h2": {
      fontSize: "0.95rem",
      fontWeight: "bold",
      marginBottom: "0.75vh",
    },
    "& > ul": {
      margin: 0,
      paddingLeft: "6vw",
      marginBottom: "2vh",
      userSelect: "none",
      "& > li": {
        fontSize: "0.85rem",
        "&::marker": {
          fontSize: "0.75rem",
        },
        "&.bad": {
          color: theme.palette.error.main,
          fontWeight: "bold",
          "&:not(.special)::after": {
            content: "'X'",
            padding: "0 1vw",
          },
        },
        "&.good": {
          color: theme.palette.primary.main,
          fontWeight: "bold",
          "&:not(.special)::after": {
            content: "'O'",
            padding: "0 1vw",
          },
        },
        "&.special": {
          listStyleType: "none",
        },
      },
    },
    "& > div": {
      marginBottom: "1.5vh",
      fontSize: "0.95rem",
      padding: "0 2vw",
    },
    "& > hr": {
      borderStyle: "dotted",
      borderColor: "rgba(0, 0, 0, 0.38)",
      margin: "0.75vh 0 2.25vh",
    },
  },
  closeButton: {
    position: "absolute",
    top: "2vw",
    right: "2vw",
    "& > svg": {
      fontSize: "1.85rem",
    },
  },
  dialog: {
    maxWidth: 900,
    width: "fit-content",
    height: "fit-content",
    padding: "2vh 4.5vw",
    borderRadius: "3.5vw",
    userSelect: "none",
  },
  dialogTitle: {
    padding: "1.5vh 4.5vw",
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: theme.palette.error.main,
    wordBreak: "keep-all",
  },
  dialogContent: {
    padding: "0 4.5vw",
    "& > p > span": {
      display: "block",
      marginBottom: "1vh",
      fontWeight: "500",
      "& > span": {
        fontWeight: "bold",
        color: theme.palette.error.main,
        marginRight: "0.75vw",
        wordBreak: "keep-all",
      },
    },
  },
  dialogActions: {
    "& > button": {
      fontSize: "1.05rem",
    },
  },
});

const MyInfoContainer = () => {
  const classes = useStyles(styles);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();
  const { loginToken } = useTypedSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();

  const [tokenData, setTokenData] = useState<TokenData>({
    id: null,
    puid: undefined,
    guid: undefined,
    name: undefined,
    username: undefined,
    group: null,
    image: undefined,
    is_staff: false,
    is_manager: false,
    is_superuser: false,
  });

  const [puid, setPuid] = useState("");
  const [initUserData, setInitUserData] = useState<{ [key: string]: any }>({
    name: "",
    username: "",
    image: undefined,
    email: "",
    phone: "",
    address1: "",
    address2: "",
    address3: "",
    zip_code: "",
    country: "",
    city: "",
    state: "",
    nationality: "",
  });
  const [userData, setUserData] = useState<{ [key: string]: any }>({
    name: "",
    username: "",
    image: undefined,
    email: "",
    phone: "",
    address1: "",
    address2: "",
    address3: "",
    zip_code: "",
    country: "",
    city: "",
    state: "",
    nationality: "",
  });

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [isCheckEmail, setIsCheckEmail] = useState(false);
  const [isCheckPhone, setIsCheckPhone] = useState(false);

  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPhone, setErrorPhone] = useState(false);

  const [helperTextEmail, setHelperTextEmail] = useState<string | null>("");
  const [helperTextPhone, setHelperTextPhone] = useState<string | null>("");

  const [modalOpen, setModalOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [error, setError] = useState(false);
  const [errorCode, setErrorCode] = useState(0);

  const userInfoQuery = useQuery(
    ["userInfo", puid],
    () => {
      if (puid !== undefined) {
        dispatch(loadingOn());
        return getUserInfo(puid);
      }
    },
    {
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      onSettled: () => {
        dispatch(loadingOff());
      },
    }
  );
  const checkEmailMutation = useMutation(checkUserDuplicate, {
    onSuccess: (response) => {
      if (response.data.puid !== undefined) {
        setHelperTextEmail(t("USER.MSG.ALREADY_EXIST_EMAIL"));
        setErrorEmail(true);
      } else {
        setHelperTextEmail(t("USER.MSG.AVAILABLE_EMAIL"));
      }
      setIsCheckEmail(true);
    },
    onError: (error) => {
      onError(error);
    },
    onSettled: () => {
      dispatch(loadingOff());
    },
  });
  const checkPhoneMutation = useMutation(checkUserDuplicate, {
    onSuccess: (response) => {
      if (response.data.puid !== undefined) {
        setHelperTextPhone(t("USER.MSG.ALREADY_EXIST_PHONE"));
        setErrorPhone(true);
      } else {
        setHelperTextPhone(t("USER.MSG.AVAILABLE_PHONE"));
      }
      setIsCheckPhone(true);
    },
    onError: (error) => {
      onError(error);
    },
    onSettled: () => {
      dispatch(loadingOff());
    },
  });
  const passwordMutation = useMutation(patchUserInfo, {
    onSuccess: () => {
      enqueueSnackbar(t("DIALOG.200.SUCCESS_CHANGE_PASSWORD"), {
        variant: "success",
      });
      setModalOpen(false);
    },
    onError: (error) => {
      onError(error);
    },
    onSettled: () => {
      dispatch(loadingOff());
    },
  });
  const patchUserInfoMutation = useMutation(patchUserInfo, {
    onSuccess: () => {
      enqueueSnackbar(t("DIALOG.200.SUCCESS_PATCH_USER_INFO"), {
        variant: "success",
      });
      refreshTokenMutation.mutate();
    },
    onError: (error) => {
      onError(error);
    },
    onSettled: () => {
      dispatch(loadingOff());
    },
  });
  const refreshTokenMutation = useMutation(refreshTokenAPI, {
    onSuccess: (response) => {
      if (Object.keys(response.data).includes("access_token")) {
        dispatch(setLoginToken(response.data.access_token));
        navigate(0);
      } else {
        dispatch(
          setErrorText({
            title: t("DIALOG.TITLE.AUTH_SERVER_NOTICE"),
            content: t("DIALOG.404.FAIL_TO_GET_TOKEN"),
            onClose: () => {
              navigate("/login");
            },
          })
        );
      }
    },
    onError: (error) => {
      onError(error);
    },
  });
  const leaveMutation = useMutation(patchUserInfo, {
    onSuccess: () => {
      logoutMutation.mutate();
    },
    onError: (error) => {
      onError(error);
      dispatch(loadingOff());
    },
  });
  const logoutMutation = useMutation(logoutAPI, {
    onSuccess: () => {
      dispatch(setLoginToken(""));
      dispatch(initWishList());
      navigate("/");
    },
    onError: (error) => {
      onError(error);
    },
    onSettled: () => {
      dispatch(loadingOff());
    },
  });

  const InputProps = (key: string) => {
    const onClick = () => {
      if (key === "email") {
        if (isNotNull(email)) {
          if (emailRegex.test(email)) {
            dispatch(loadingOn());
            checkEmailMutation.mutate({ data: { email: email } });
          } else {
            setErrorEmail(true);
            setHelperTextEmail(t("USER.MSG.INVALID_EMAIL"));
          }
        } else {
          setErrorEmail(true);
          setHelperTextEmail(t("USER.MSG.INPUT_EMAIL"));
        }
      } else if (key === "phone") {
        if (isNotNull(phone)) {
          const phoneNumber = parsePhoneNumberFromString(phone);
          if (phoneNumber !== undefined && phoneNumber.isValid()) {
            dispatch(loadingOn());
            checkPhoneMutation.mutate({ data: { phone: phone } });
          } else {
            setErrorPhone(true);
            setHelperTextPhone(t("USER.MSG.INVALID_PHONE"));
          }
        } else {
          setErrorPhone(true);
          setHelperTextPhone(t("USER.MSG.INPUT_PHONE"));
        }
      }
    };

    return {
      endAdornment: (
        <InputAdornment position="end">
          <CheckIcon onClick={() => onClick()} />
        </InputAdornment>
      ),
    };
  };
  const getClassName = (code: number) => {
    let result = "";

    if (isNotNull(newPassword) && isNotNull(newPasswordConfirm)) {
      if (newPassword === newPasswordConfirm) {
        if (errorCode & code) {
          return "bad";
        } else {
          return "good";
        }
      }
    }
    return result;
  };
  const handlePasswordOnBlur = () => {
    if (isNotNull(newPassword) && isNotNull(newPasswordConfirm)) {
      if (newPassword === newPasswordConfirm) {
        setError(false);
      } else {
        setError(true);
      }
    } else {
      setError(false);
    }
  };
  const handlePasswordOnClick = () => {
    if (isNull(password)) {
      enqueueSnackbar(t("USER.MSG.INPUT_CURRENT_PASSWORD"), {
        variant: "warning",
      });
      return;
    }
    if (isNull(newPassword)) {
      enqueueSnackbar(t("USER.MSG.INPUT_NEW_PASSWORD"), { variant: "warning" });
      return;
    }
    if (isNull(newPasswordConfirm)) {
      enqueueSnackbar(t("USER.MSG.INPUT_NEW_PASSWORD_CONFIRM"), {
        variant: "warning",
      });
      return;
    }
    if (error || errorCode !== 0) {
      enqueueSnackbar(t("USER.MSG.NOT_VALID_PASSWORD"), { variant: "error" });
      return;
    }

    if (tokenData["puid"] !== undefined) {
      dispatch(loadingOn());
      passwordMutation.mutate({
        puid: tokenData["puid"],
        data: {
          password: password,
          newPassword: newPassword,
        },
      });
    }
  };
  const handleOnSubmit = async () => {
    if (tokenData["puid"] !== undefined) {
      const sendData: { [key: string]: any } = {};
      Object.keys(userData).forEach((key: string) => {
        if (!["id", "username", "nationality"].includes(key)) {
          if (initUserData[key] !== userData[key]) {
            sendData[key] = userData[key];
          }
        }
      });

      if (userData["email"] !== email) {
        if (!isCheckEmail) {
          enqueueSnackbar(t("USER.MSG.CHECK_EXIST_EMAIL"), {
            variant: "warning",
          });
          return;
        }

        if (errorEmail) {
          enqueueSnackbar(t("USER.MSG.CHECK_EMAIL"), {
            variant: "warning",
          });
          return;
        }
        // 필수값 확인
        if (isNull(email)) {
          enqueueSnackbar(t("MSG.CHECK_REQUIRE_VALUE"), {
            variant: "error",
          });
          return;
        }
        sendData["email"] = email;
      }
      if (userData["phone"] !== phone) {
        if (!isCheckPhone) {
          enqueueSnackbar(t("USER.MSG.CHECK_EXIST_PHONE"), {
            variant: "warning",
          });
          return;
        }

        if (errorPhone) {
          enqueueSnackbar(t("USER.MSG.CHECK_PHONE"), {
            variant: "warning",
          });
          return;
        }
        // 필수값 확인
        if (isNull(phone)) {
          enqueueSnackbar(t("MSG.CHECK_REQUIRE_VALUE"), {
            variant: "error",
          });
          return;
        }
        sendData["phone"] = phone;
      }

      if (Object.keys(sendData).includes("image")) {
        const fileData = await resizeImage(
          sendData["image"],
          320,
          240,
          100,
          0,
          "blob"
        );
        const blobData = fileData as BlobPart;
        sendData["image"] = new File([blobData], uuidv4() + ".jpg", {
          type: "image/jpg",
        });
      }

      dispatch(loadingOn());
      patchUserInfoMutation.mutateAsync({
        puid: tokenData["puid"],
        data: sendData,
      });
    }
  };
  const handleLeaveOnClick = () => {
    if (tokenData["puid"] !== undefined) {
      if (
        tokenData["is_superuser"] ||
        tokenData["is_manager"] ||
        tokenData["is_staff"]
      ) {
        dispatch(
          setErrorText({
            title: t("DIALOG.TITLE.AUTH_SERVER_NOTICE"),
            content: t("DIALOG.CONTENT.LEAVE_MANAGER"),
          })
        );
      } else {
        dispatch(loadingOn());
        leaveMutation.mutate({
          puid: tokenData["puid"],
          data: {
            expired: new Date(),
            status: "INACTIVE",
          },
        });
      }
    } else {
      dispatch(
        setErrorText({
          title: t("DIALOG.TITLE.AUTH_SERVER_NOTICE"),
          content: t("DIALOG.401.LOGIN_ONLY_SERVICE"),
        })
      );
    }
    setDialogOpen(false);
  };

  useEffect(() => {
    window.onbeforeunload = () => {
      window.scrollTo(0, 0);
    };
  }, []);

  useEffect(() => {
    setTokenData(getTokenData());
  }, [loginToken]);

  useEffect(() => {
    if (tokenData["puid"] !== undefined) {
      setPuid(tokenData["puid"]);
    }
  }, [tokenData]);

  useEffect(() => {
    if (isNotNull(puid)) {
      userInfoQuery.refetch();
    }
  }, [puid]);

  useEffect(() => {
    if (userInfoQuery.isSuccess) {
      if (isNull(userData["id"])) {
        if (userInfoQuery.data !== undefined) {
          const result = userInfoQuery.data.data;

          const recvData: { [key: string]: any } = {};
          recvData["name"] = result["name"];
          recvData["username"] = result["username"];
          recvData["image"] = result["image"];
          recvData["email"] = result["email"];
          recvData["phone"] = result["phone"];
          recvData["address1"] = isNotNull(result["address1"])
            ? result["address1"]
            : "";
          recvData["address2"] = isNotNull(result["address2"])
            ? result["address2"]
            : "";
          recvData["address3"] = isNotNull(result["address3"])
            ? result["address3"]
            : "";
          recvData["zip_code"] = isNotNull(result["zip_code"])
            ? result["zip_code"]
            : "";
          recvData["country"] = isNotNull(result["country"])
            ? result["country"]
            : "";
          recvData["city"] = isNotNull(result["city"]) ? result["city"] : "";
          recvData["state"] = isNotNull(result["state"]) ? result["state"] : "";
          recvData["nationality"] = result["nationality"];

          setInitUserData(recvData);
          setUserData(recvData);

          setEmail(result["email"]);
          setPhone(result["phone"]);
        } else {
          setPuid("");
        }
      }
    }
  }, [userInfoQuery.isLoading]);

  useEffect(() => {
    if (isNotNull(newPassword) && isNotNull(newPasswordConfirm)) {
      if (newPassword === newPasswordConfirm) {
        setErrorCode(validatePassword(newPassword));
      } else {
        setErrorCode(0);
      }
    } else {
      setErrorCode(0);
    }
  }, [newPassword, newPasswordConfirm]);

  useEffect(() => {
    setIsCheckEmail(false);
    setErrorEmail(false);
    setHelperTextEmail("");
  }, [email]);

  useEffect(() => {
    setIsCheckPhone(false);
    setErrorPhone(false);
    setHelperTextPhone("");
  }, [phone]);

  return (
    <>
      <div className={classes.container}>
        <div className={classes.title}>
          <Typography component="h1">Edit Profile</Typography>
          <ul className="content">
            <li>
              <Markup content={t("JOIN.MSG.NOTICE_1")} />
            </li>
            <li>
              <Markup content={t("JOIN.MSG.NOTICE_2")} />
            </li>
            <li className="listStyleNone">{t("JOIN.MSG.NOTICE_3")}</li>
            <li>{t("JOIN.MSG.NOTICE_4")}</li>
            <li className="listStyleNone">
              <Markup content={t("JOIN.MSG.NOTICE_5")} />
            </li>
          </ul>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.topDiv}>
          <div>
            {isNull(userData["image"]) ? (
              <AccountCircleIcon
                className={classes.userIcon}
                onDoubleClick={() => {
                  document.getElementById("imageUpload")?.click();
                }}
              />
            ) : (
              <Image
                alt={""}
                src={userData["image"]}
                className={classes.userImage}
                onDoubleClick={() => {
                  document.getElementById("imageUpload")?.click();
                }}
              />
            )}
            <IconButton
              className={classes.uploadButton}
              onClick={() => {
                document.getElementById("imageUpload")?.click();
              }}
            >
              <CollectionsIcon />
            </IconButton>
            <input
              type="file"
              id="imageUpload"
              name="imageUpload"
              accept="image/jpg, image/gif, image/png, image/jpeg"
              className={classes.uploadInput}
              onChange={async (e: any) => {
                const resizeFile = await resizeImage(
                  e.target.files[0],
                  320,
                  240,
                  75,
                  0,
                  "file"
                );
                setUserData({
                  ...userData,
                  image: resizeFile,
                });
              }}
            />
          </div>
          <div>
            {(tokenData["is_superuser"] ||
              tokenData["is_manager"] ||
              tokenData["is_staff"]) && (
              <>
                <Typography>Position</Typography>
                <Input
                  disabled
                  variant="standard"
                  value={
                    userData["nationality"] === "S0"
                      ? "Super User"
                      : userData["nationality"] === "S1"
                      ? "Manager"
                      : userData["nationality"] === "S2"
                      ? "Staff"
                      : "User"
                  }
                  fullWidth
                />
              </>
            )}
            <Typography className="require-value">Name</Typography>
            <Input
              variant="standard"
              value={userData["name"]}
              dispatch={(value: string) => {
                setUserData({
                  ...userData,
                  name: value,
                });
              }}
              fullWidth
              inputProps={{
                maxLength: 32,
              }}
            />
            {!(
              tokenData["is_superuser"] ||
              tokenData["is_manager"] ||
              tokenData["is_staff"]
            ) && (
              <>
                <Typography className="require-value">Nationality</Typography>
                <NationAutocomplete
                  variant="standard"
                  value={userData["nationality"]}
                  dispatch={(value: string) => {
                    setUserData({
                      ...userData,
                      nationality: value,
                    });
                  }}
                  fullWidth
                />
              </>
            )}
            <Typography className="unique-value">Email Address</Typography>
            <Input
              className={isCheckEmail ? (!errorEmail ? "good" : "bad") : ""}
              variant="standard"
              value={email}
              dispatch={setEmail}
              error={errorEmail}
              helperText={helperTextEmail}
              placeholder="example@autoskorea.com"
              fullWidth
              InputProps={InputProps("email")}
              inputProps={{
                maxLength: 64,
              }}
            />
            <Typography className="unique-value">Phone Number</Typography>
            <PhoneInput
              className={isCheckPhone ? (!errorPhone ? "good" : "bad") : ""}
              variant="standard"
              value={phone}
              dispatch={(value: string) => {
                if (isNull(value) || phoneRegex.test(value)) {
                  if (
                    isNotNull(value) &&
                    value[0] !== "+" &&
                    value[0] !== " "
                  ) {
                    setPhone("+" + value);
                  } else {
                    setPhone(String(value).trim());
                  }
                }
              }}
              placeholder="Please enter only digits except '-'"
              error={errorPhone}
              helperText={helperTextPhone}
              fullWidth
              InputProps={InputProps("phone")}
              inputProps={{
                maxLength: 20,
              }}
            />
            <Typography variant="caption" onClick={() => setModalOpen(true)}>
              {t("USER.PASSWORD_CHANGE")}
            </Typography>
          </div>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.middleDiv}>
          <Typography component="h1">Shipping Information</Typography>
          <div className="content">
            <Typography>Address</Typography>
            <Input
              variant="standard"
              value={userData["address1"]}
              dispatch={(value: string) => {
                setUserData({
                  ...userData,
                  address1: value,
                });
              }}
              fullWidth
              inputProps={{
                maxLength: 80,
              }}
            />
            <Typography>Address 2</Typography>
            <Input
              variant="standard"
              value={userData["address2"]}
              dispatch={(value: string) => {
                setUserData({
                  ...userData,
                  address2: value,
                });
              }}
              fullWidth
              inputProps={{
                maxLength: 80,
              }}
            />
            <Typography>Address 3</Typography>
            <Input
              variant="standard"
              value={userData["address3"]}
              dispatch={(value: string) => {
                setUserData({
                  ...userData,
                  address3: value,
                });
              }}
              fullWidth
              inputProps={{
                maxLength: 80,
              }}
            />
            <Typography>State</Typography>
            <Input
              variant="standard"
              value={userData["state"]}
              dispatch={(value: string) => {
                setUserData({
                  ...userData,
                  state: value,
                });
              }}
              fullWidth
              inputProps={{
                maxLength: 64,
              }}
            />
            <Typography>City</Typography>
            <Input
              variant="standard"
              value={userData["city"]}
              dispatch={(value: string) => {
                setUserData({
                  ...userData,
                  city: value,
                });
              }}
              fullWidth
              inputProps={{
                maxLength: 64,
              }}
            />
            <Typography>Country</Typography>
            <NationAutocomplete
              variant="standard"
              value={userData["country"]}
              dispatch={(value: string) => {
                setUserData({
                  ...userData,
                  country: value,
                });
              }}
              fullWidth
            />
            <Typography>Zip Code</Typography>
            <Input
              variant="standard"
              value={userData["zip_code"]}
              dispatch={(value: string) => {
                setUserData({
                  ...userData,
                  zip_code: value,
                });
              }}
              fullWidth
              inputProps={{
                maxLength: 16,
              }}
            />
          </div>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.bottomDiv}>
          <Button variant="contained" onClick={() => handleOnSubmit()}>
            {t("BUTTON.MODIFY")}
          </Button>
          <Typography variant="caption" onClick={() => setDialogOpen(true)}>
            {t("BUTTON.LEAVE")}
          </Typography>
        </div>
      </div>
      <Modal className={classes.modal} open={modalOpen} disableAutoFocus={true}>
        <Paper classes={{ root: classes.paper }} square={true}>
          <Typography component="h1">{t("USER.PASSWORD_CHANGE")}</Typography>
          <Typography component="h2">
            {t("USER.PASSWORD_CONDITION.TITLE")}
          </Typography>
          <ul>
            <li className={getClassName(1)}>
              {t("USER.PASSWORD_CONDITION.CONTENT_1")}
            </li>
            <li className={getClassName(2)}>
              {t("USER.PASSWORD_CONDITION.CONTENT_2")}
            </li>
            <li className={getClassName(4)}>
              {t("USER.PASSWORD_CONDITION.CONTENT_3")}
            </li>
            <li className={getClassName(8)}>
              {t("USER.PASSWORD_CONDITION.CONTENT_4")}
            </li>
            <li className={`${getClassName(8)} special`}>
              `~!@#$%^&*()-_=+{}[]:;'"
            </li>
          </ul>
          <Divider />
          <Input
            type="password"
            variant="standard"
            placeholder={t("USER.CURRENT_PASSWORD")}
            value={password}
            dispatch={setPassword}
            inputProps={{
              maxLength: 32,
            }}
          />
          <Input
            type="password"
            variant="standard"
            placeholder={t("USER.NEW_PASSWORD")}
            error={error || errorCode !== 0}
            value={newPassword}
            dispatch={setNewPassword}
            onBlur={() => handlePasswordOnBlur()}
            inputProps={{
              maxLength: 32,
            }}
          />
          <Input
            type="password"
            variant="standard"
            placeholder={t("USER.NEW_PASSWORD_CONFIRM")}
            error={error || errorCode !== 0}
            helperText={error ? t("USER.MSG.PASSWORD_ERROR") : ""}
            value={newPasswordConfirm}
            dispatch={setNewPasswordConfirm}
            onBlur={() => handlePasswordOnBlur()}
            inputProps={{
              maxLength: 32,
            }}
          />
          <Divider />
          <Button variant="contained" onClick={() => handlePasswordOnClick()}>
            {t("BUTTON.MODIFY")}
          </Button>
          <IconButton
            className={classes.closeButton}
            onClick={() => {
              setNewPassword("");
              setNewPasswordConfirm("");
              setError(false);
              setErrorCode(0);
              setModalOpen(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Paper>
      </Modal>
      <Dialog
        classes={{ paper: classes.dialog }}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="leave-confirm-title"
        aria-describedby="leave-confirm-description"
      >
        <DialogTitle
          id="leave-confirm-title"
          classes={{ root: classes.dialogTitle }}
        >
          <Markup content={t("USER.LEAVE.TITLE_MOBILE")} />
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
          <DialogContentText id="leave-confirm-description">
            <Markup content={t("USER.LEAVE.CONTENT_1")} />
            <Markup content={t("USER.LEAVE.CONTENT_2")} />
            <Markup content={t("USER.LEAVE.CONTENT_3")} />
          </DialogContentText>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogActions }}>
          <Button onClick={() => handleLeaveOnClick()}>
            {t("OPTION.APPLY")}
          </Button>
          <Button onClick={() => setDialogOpen(false)}>
            {t("OPTION.CANCEL")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MyInfoContainer;
