/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Markup } from "interweave";
// Redux
import { useTypedDispatch, useTypedSelector } from "@/common/lib/redux/store";
import {
  loadingOn,
  loadingOff,
  setErrorText,
} from "@/common/lib/redux/actions/commonAction";
// Components
import Icon from "@mdi/react";
import {
  mdiWhatsapp as WhatsappIcon,
  mdiFacebookMessenger as FacebookMessengerIcon,
  mdiDropbox as DropboxIcon,
} from "@mdi/js";
import Slider from "react-slick";
import ReactPlayer from "react-player/youtube";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Modal,
  Theme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { Button, FavoriteButton, Image } from "@/common/components";
import { YoutubeIcon } from "@/common/icons";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
import { colors } from "@/common/lib/style/palette";
// API
import { onError } from "@/common/lib/api/common";
import { postBookingRequestInfo } from "@/common/lib/api/bookingRequest";
// Libs
import { isNotNull, getOptionLabel } from "@/common/lib/common";
import {
  carCategoryList,
  truckCategoryList,
  truckSubCategoryList,
  busCategoryList,
  truckEngineTypeList,
  truckCabinTypeList,
  truckWheelbaseList,
  missionList,
  fuelList,
  colorList,
} from "@/common/lib/varList";

const styles = (theme: Theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: 45,
  },
  gallaryDiv: {
    position: "relative",
    width: 700,
  },
  discountBorder: {
    overflow: "hidden",
    position: "absolute",
    width: "100%",
    height: "100%",
  },
  discountLabel: {
    zIndex: 1,
    position: "absolute",
    top: "1.65em",
    left: "-3.75em",
    width: "15em",
    height: "4.25em",
    transform: "rotate(-45deg)",
    background: theme.palette.error.main,
    color: "white",
    textAlign: "center",
    fontWeight: "bold",
    lineHeight: "1.25",
    cursor: "default",
    opacity: 0.95,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "& > p": {
      margin: 0,
      fontSize: "1.25em",
      "&.discount": {
        fontSize: "1.5em",
      },
    },
  },
  mainCarouselSlider: {
    "& .slick-slide": {
      "& > div": {
        display: "flex",
        "& > div": {
          padding: 2.5,
        },
      },
    },
    "& .slick-prev, & .slick-next": {
      width: "12.5%",
      height: "100%",
      zIndex: 1,
      opacity: 0,
      transition: "500ms cubic-bezier(0.22, 0.61, 0.36, 1) all",
      "&::before": {
        fontSize: 45,
        lineHeight: "unset",
      },
      "&:hover": {
        opacity: 1,
      },
    },
    "& .slick-prev": {
      left: 0,
    },
    "& .slick-next": {
      right: 0,
    },
  },
  subCarouselSlider: {
    "& .slick-slide": {
      "& > div": {
        display: "flex",
        "& > div": {
          padding: 2.5,
          position: "relative",
          "&:hover": {
            cursor: "pointer",
            "& > svg": {
              fill: "#E83F3A",
            },
          },
          "& > div": {
            position: "absolute",
            opacity: 0,
            width: "calc(100% - 5px)",
            aspectRatio: "1.33",
            border: "4.5px solid " + theme.palette.primary.main,
            borderRadius: 5,
            boxSizing: "border-box",
            "&.active": {
              opacity: 1,
            },
          },
        },
      },
    },
    "& .slick-dots": {
      position: "unset",
      bottom: "unset",
      "& li": {
        margin: "0 7.5px",
        width: 18,
        height: 18,
        "& > button": {
          width: "inherit",
          height: "inherit",
          "&::before": {
            content: "'■'",
            width: "inherit",
            height: "inherit",
            fontSize: 18,
            lineHeight: "1",
          },
        },
      },
    },
  },
  mainImage: {
    width: "100%",
    aspectRatio: "1.33",
    borderRadius: 5,
    objectFit: "contain",
  },
  subImage: {
    width: "100%",
    aspectRatio: "1.33",
    borderRadius: 5,
    objectFit: "contain",
  },
  youtubeImage: {
    width: "66%",
    height: "66%",
    opacity: 0.85,
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto",
    fill: theme.palette.text.primary,
  },
  tableDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: 450,
    paddingBottom: 29.5,
  },
  table: {
    width: "100%",
    tableLayout: "fixed",
    borderCollapse: "collapse",
    borderSpacing: 0,
    userSelect: "none",
    marginTop: 2.5,
    borderTop: "1px dashed " + theme.palette.text.secondary,
    "& th, & td": {
      fontSize: "0.925rem",
      padding: 15,
      textAlign: "unset",
    },
    "& tr": {
      borderBottom: "1px dashed " + theme.palette.text.secondary,
    },
    "& tr:nth-child(2n-1)": {
      background:
        "linear-gradient(to right, " +
        colors.gray[0] +
        ", " +
        colors.gray[1] +
        ")",
    },
    "& th": {
      fontWeight: 500,
      color: theme.palette.text.secondary,
    },
    "& td": {
      fontWeight: 600,
      color: theme.palette.text.primary,
    },
  },
  SNS: {
    "& > button": {
      boxSizing: "border-box",
      padding: "1.5vh 2vw",
      borderRadius: "0.35rem",
      cursor: "pointer",
      textTransform: "none",
      fontSize: "0.875rem",
      fontWeight: 500,
      "& > svg": {
        width: "2rem",
        height: "2rem",
        marginRight: "0.45rem",
      },
      "&:hover": {
        opacity: 0.85,
      },
    },
    "& .whatsapp": {
      width: "calc(50% - 5px)",
      height: "fit-content",
      margin: "10px 5px 10px 0",
      background: "#2BA63A",
    },
    "& .messenger": {
      width: "calc(50% - 5px)",
      height: "fit-content",
      margin: "10px 0 10px 5px",
      background: "#4B69B0",
    },
    "& .dropbox": {
      width: "100%",
      height: "fit-content",
      marginBottom: 10,
      background: "#0061FE",
    },
    "& .request": {
      width: "100%",
      height: "fit-content",
      background: colors.teal[7],
    },
  },
  copyText: {
    cursor: "copy",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    userSelect: "none",
  },
  modalDiv: {
    width: 640,
  },
  modalClose: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    "& > button": {
      margin: 0,
      padding: 8,
      transform: "translate(16px, 0)",
      "& > svg": {
        fontSize: "2.85rem",
      },
    },
  },
  modalPlayer: {
    width: "100% !important",
    height: "unset !important",
    aspectRatio: "1.78",
  },
  dialog: {
    maxWidth: 900,
    width: "fit-content",
    height: "fit-content",
    padding: "16px 45px 12px",
    borderRadius: 10,
    userSelect: "none",
  },
  dialogTitle: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  dialogContent: {
    padding: "0 36px",
    "& > p > span": {
      display: "block",
      fontWeight: "500",
      "& > span": {
        fontSize: "0.875rem",
        color: theme.palette.error.main,
      },
    },
  },
  dialogActions: {
    "& > button": {
      fontSize: "1.0625rem",
    },
  },
  favorite: {
    position: "absolute",
    top: 15,
    right: 15,
    "& > svg": {
      fontSize: "3rem",
    },
  },
});

interface propType {
  data: VehicleData;
}

const InfoContainer = ({ data }: propType) => {
  const classes = useStyles(styles);
  const { t } = useTranslation();
  const { loginToken } = useTypedSelector((state) => state.auth);
  const dispatch = useTypedDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const facebookURL = "https://www.facebook.com/autoleadercar";
  const whatsappURL = "https://wa.me/821076599744";

  const mainCarouselRef = useRef<Slider>(null);
  const subCarouselRef = useRef<Slider>(null);

  const [imageIdx, setImageIdx] = useState(0);
  const [isDiscount, setIsDiscount] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isEnabledClick, setIsEnabledClick] = useState(true);

  const postBookingRequestMutation = useMutation(postBookingRequestInfo, {
    onSuccess: () => {
      dispatch(
        setErrorText({
          title: t("DIALOG.TITLE.BOOKING_REQUEST_DONE"),
          content: t("DIALOG.200.BOOKING_SUCCESS"),
        })
      );
    },
    onError: (error) => {
      onError(error);
    },
    onSettled: () => {
      dispatch(loadingOff());
    },
  });

  useEffect(() => {
    if (data !== undefined) {
      if (data.sales.discount_expired !== null) {
        const todayDate = new Date();
        const expireDate = new Date(data.sales.discount_expired);
        expireDate.setHours(expireDate.getHours() + 9);

        if (expireDate >= todayDate) {
          setIsDiscount(true);
        } else {
          setIsDiscount(false);
        }
      }
    }
  }, [data]);

  useEffect(() => {
    if (!isEnabledClick) {
      setTimeout(() => {
        setIsEnabledClick(true);
      }, 350);
    }
  }, [isEnabledClick]);

  useEffect(() => {
    if (mainCarouselRef.current !== null) {
      mainCarouselRef.current.slickGoTo(imageIdx);
    }
    if (subCarouselRef.current !== null) {
      if (data.media.youtube !== "") {
        subCarouselRef.current.slickGoTo(Math.floor((imageIdx + 1) / 10));
      } else {
        subCarouselRef.current.slickGoTo(Math.floor(imageIdx / 10));
      }
    }
  }, [imageIdx]);

  const getModelValue = () => {
    const values = [];
    if (data !== undefined) {
      values.push(data.product.year);
      values.push(data.product.maker);
      values.push(data.product.detail_model);
    }
    if (values.length === 0) {
      return "N/A";
    }
    return values.join(" ");
  };
  const getCategoryValue = () => {
    const values = [];
    if (data !== undefined) {
      if (data.product.type === "CAR" && data.car_product !== undefined) {
        values.push(getOptionLabel(data.car_product.category, carCategoryList));
      } else if (
        data.product.type === "TRUCK" &&
        data.truck_product !== undefined
      ) {
        if (
          data.truck_product.category === "CargoTruck" ||
          data.truck_product.category === "DumpTruck" ||
          data.truck_product.category === "Tractor" ||
          data.truck_product.category === "CampingCar" ||
          data.truck_product.category === "Others"
        ) {
          values.push(
            getOptionLabel(data.truck_product.category, truckCategoryList)
          );
          if (
            data.truck_product.load_weight !== null &&
            data.truck_product.load_weight > 0
          ) {
            values.push(data.truck_product.load_weight + "t");
          }
        } else if (data.truck_product.category === "TowTruck") {
          values.push(
            getOptionLabel(
              data.truck_product.sub_category,
              truckSubCategoryList[data.truck_product.category]
            )
          );
          if (
            data.truck_product.load_weight !== null &&
            data.truck_product.load_weight > 0
          ) {
            values.push(data.truck_product.load_weight + "t");
          }
        } else if (data.truck_product.category === "CraneTruck") {
          values.push(
            getOptionLabel(
              data.truck_product.sub_category,
              truckSubCategoryList[data.truck_product.category]
            )
          );
          if (
            data.truck_product.crane_boom_height !== null &&
            data.truck_product.crane_boom_height > 0
          ) {
            values.push(data.truck_product.crane_boom_height + "m");
          }
        } else if (
          data.truck_product.category === "TopTruck" ||
          data.truck_product.category === "WingbodyTruck" ||
          data.truck_product.category === "Trailer"
        ) {
          values.push(
            getOptionLabel(data.truck_product.category, truckCategoryList)
          );
          values.push(
            getOptionLabel(
              data.truck_product.sub_category,
              truckSubCategoryList[data.truck_product.category]
            )
          );
          if (
            data.truck_product.load_weight !== null &&
            data.truck_product.load_weight > 0
          ) {
            values.push(data.truck_product.load_weight + "t");
          }
        } else if (data.truck_product.category === "TankLorry") {
          values.push(
            getOptionLabel(data.truck_product.category, truckCategoryList)
          );
          values.push(
            getOptionLabel(
              data.truck_product.sub_category,
              truckSubCategoryList[data.truck_product.category]
            )
          );
          if (
            data.truck_product.tank_volume !== null &&
            data.truck_product.tank_volume > 0
          ) {
            values.push(data.truck_product.tank_volume + "L");
          }
        }
      } else if (
        data.product.type === "BUS" &&
        data.bus_product !== undefined
      ) {
        values.push(getOptionLabel(data.bus_product.category, busCategoryList));
      }
    }
    if (values.length === 0) {
      return "N/A";
    }
    return values.join(" / ");
  };

  const handleImageOnClick = (imageIdx: number) => {
    if (isEnabledClick) {
      setImageIdx(imageIdx);
      setIsEnabledClick(false);
    }
  };
  const handleCopyOnClick = () => {
    navigator.clipboard.writeText(window.location.href).then(
      () => {
        enqueueSnackbar(t("MSG.COPY_SUCCESS"), { variant: "success" });
      },
      () => {
        enqueueSnackbar(t("MSG.COPY_FAILURE"), { variant: "error" });
      }
    );
  };
  const handleSNSOnClick = (url: string | undefined) => {
    if (url !== undefined) {
      window.open(url, "_blank");
    }
  };
  const handleOnApply = () => {
    if (isNotNull(loginToken)) {
      if (data.sales.status === "ON" || data.sales.status === "REZ") {
        dispatch(loadingOn());
        postBookingRequestMutation.mutate({ uid: data.product.uid });
      } else {
        dispatch(
          setErrorText({
            title: t("DIALOG.TITLE.DISABLE_STATUS"),
            content: t("DIALOG.CONTENT.BOOKING_REQUEST_STATUS"),
          })
        );
      }
    } else {
      dispatch(
        setErrorText({
          title: t("DIALOG.401.LOGIN_ONLY_SERVICE"),
          content: t("DIALOG.CONTENT.BOOKING_REQUEST_LOGIN"),
        })
      );
    }
    setDialogOpen(false);
  };

  const mainCaroselSettings = {
    infinite: true,
    autoPlay: false,
    arrows: true,
    swipe: true,
    draggable: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 350,
    beforeChange: (_: number, next: number) => setImageIdx(next),
  };
  const subCaroselSettings = {
    infinite: true,
    autoPlay: false,
    dots: true,
    arrows: false,
    swipe: true,
    draggable: false,
    rows: 2,
    slidesPerRow: 5,
    speed: 350,
  };

  return (
    <>
      {data !== undefined && (
        <>
          <div className={classes.container}>
            <div className={classes.gallaryDiv}>
              {isNotNull(loginToken) && isDiscount && (
                <div className={classes.discountBorder}>
                  <div className={classes.discountLabel}>
                    <p>SPECIAL</p>
                    <p className="discount">DISCOUNT</p>
                  </div>
                </div>
              )}
              <Slider
                {...mainCaroselSettings}
                ref={mainCarouselRef}
                className={classes.mainCarouselSlider}
              >
                {data.media.image.map((src: File | string, idx: number) => {
                  return (
                    <div key={idx}>
                      <Image alt="" src={src} className={classes.mainImage} />
                    </div>
                  );
                })}
              </Slider>
              <Slider
                {...subCaroselSettings}
                ref={subCarouselRef}
                className={classes.subCarouselSlider}
              >
                {data.media.youtube !== "" && (
                  <div onClick={() => setModalOpen(true)}>
                    <YoutubeIcon className={classes.youtubeImage} />
                    <Image
                      alt=""
                      src={data.media.image[0]}
                      className={classes.subImage}
                    />
                  </div>
                )}
                {data.media.image.map((src: File | string, idx: number) => {
                  return (
                    <div key={idx}>
                      <div
                        className={idx === imageIdx ? `active` : ``}
                        onClick={() => handleImageOnClick(idx)}
                      />
                      <Image alt="" src={src} className={classes.subImage} />
                    </div>
                  );
                })}
              </Slider>
              <FavoriteButton
                className={classes.favorite}
                wish_id={data.wish !== undefined ? data.wish.id : null}
                product_uid={data.product.uid}
              />
            </div>
            <div className={classes.tableDiv}>
              <table className={classes.table}>
                <colgroup>
                  <col style={{ width: "25%" }} />
                  <col style={{ width: "25%" }} />
                  <col style={{ width: "25%" }} />
                  <col style={{ width: "25%" }} />
                </colgroup>
                <thead></thead>
                <tbody>
                  <tr>
                    <th>ID</th>
                    <td
                      colSpan={3}
                      className={classes.copyText}
                      onClick={handleCopyOnClick}
                    >
                      {data.product.uid}
                    </td>
                  </tr>
                  <tr>
                    <th>{t("VEHICLE.PRODUCT.MODEL")}</th>
                    <td colSpan={3}>{getModelValue()}</td>
                  </tr>
                  <tr>
                    <th>{t("VEHICLE.PRODUCT.CATEGORY")}</th>
                    <td colSpan={3}>{getCategoryValue()}</td>
                  </tr>
                  {(data.product.type === "CAR" ||
                    data.product.type === "BUS") && (
                    <tr>
                      <th>{t("VEHICLE.PRODUCT.COLOR")}</th>
                      <td>{getOptionLabel(data.product.color, colorList)}</td>
                      <th>{t("VEHICLE.PRODUCT.DOOR")}</th>
                      <td>
                        {data.product.door !== null ? data.product.door : "-"}
                      </td>
                    </tr>
                  )}
                  {data.product.type === "TRUCK" &&
                    data.truck_product !== undefined && (
                      <>
                        <tr>
                          <th>
                            <Markup
                              content={t("VEHICLE.PRODUCT.ENGINE_TYPE")}
                            />
                          </th>
                          <td>
                            {getOptionLabel(
                              data.truck_product.engine_type,
                              truckEngineTypeList
                            )}
                          </td>
                          <th>
                            <Markup content={t("VEHICLE.PRODUCT.CABIN_TYPE")} />
                          </th>
                          <td>
                            {getOptionLabel(
                              data.truck_product.cabin_type,
                              truckCabinTypeList
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th>{t("VEHICLE.PRODUCT.WHEELBASE")}</th>
                          <td colSpan={3}>
                            {getOptionLabel(
                              data.truck_product.wheelbase,
                              truckWheelbaseList
                            )}
                          </td>
                        </tr>
                      </>
                    )}
                  <tr>
                    <th>{t("VEHICLE.PRODUCT.STEERING")}</th>
                    <td>{data.product.steering}</td>
                    {data.product.type === "CAR" &&
                      data.car_product !== undefined && (
                        <>
                          <th>{t("VEHICLE.PRODUCT.SEATS")}</th>
                          <td>
                            {t("VEHICLE.PRODUCT.SEATS_UNIT", {
                              seats: data.car_product.seats,
                            })}
                          </td>
                        </>
                      )}
                    {data.product.type === "TRUCK" &&
                      data.truck_product !== undefined && (
                        <>
                          <th>{t("VEHICLE.PRODUCT.TONNER")}</th>
                          <td>{data.truck_product.tonner.toFixed(1)} t</td>
                        </>
                      )}
                    {data.product.type === "BUS" &&
                      data.bus_product !== undefined && (
                        <>
                          <th>{t("VEHICLE.PRODUCT.SEATS")}</th>
                          <td>
                            {t("VEHICLE.PRODUCT.SEATS_UNIT", {
                              seats: data.bus_product.seats,
                            })}
                          </td>
                        </>
                      )}
                  </tr>
                  <tr>
                    <th>{t("VEHICLE.PRODUCT.MISSION")}</th>
                    <td
                      colSpan={
                        data.product.type === "BUS" &&
                        (data.bus_product === undefined ||
                          data.bus_product.total_weight === null)
                          ? 3
                          : 1
                      }
                    >
                      {getOptionLabel(data.product.mission, missionList)}
                    </td>
                    {data.product.type === "CAR" &&
                      data.car_product !== undefined && (
                        <>
                          <th>{t("VEHICLE.PRODUCT.DRIVE")}</th>
                          <td>{data.car_product.drive}</td>
                        </>
                      )}
                    {data.product.type === "TRUCK" &&
                      data.truck_product !== undefined && (
                        <>
                          <th>{t("VEHICLE.PRODUCT.DRIVE")}</th>
                          <td>{data.truck_product.drive}</td>
                        </>
                      )}
                    {data.product.type === "BUS" &&
                      data.bus_product !== undefined &&
                      data.bus_product.total_weight !== null && (
                        <>
                          <th>{t("VEHICLE.PRODUCT.TOTAL_WEIGHT")}</th>
                          <td>{data.bus_product.total_weight.toFixed(1)} t</td>
                        </>
                      )}
                  </tr>
                  <tr>
                    <th>{t("VEHICLE.PRODUCT.FUEL")}</th>
                    <td colSpan={3}>
                      {getOptionLabel(data.product.fuel, fuelList)}
                    </td>
                  </tr>
                  {(data.product.exhaust !== null &&
                    data.product.exhaust > 0) ||
                    (data.product.horse_power !== null &&
                      data.product.horse_power > 0 && (
                        <tr>
                          {data.product.exhaust !== null &&
                            data.product.exhaust > 0 && (
                              <>
                                <th>{t("VEHICLE.PRODUCT.EXHAUST")}</th>
                                <td
                                  colSpan={
                                    data.product.horse_power !== null &&
                                    data.product.horse_power > 0
                                      ? 1
                                      : 3
                                  }
                                >
                                  {data.product.exhaust.toLocaleString()} cc
                                </td>
                              </>
                            )}
                          {data.product.horse_power !== null &&
                            data.product.horse_power > 0 && (
                              <>
                                <th>{t("VEHICLE.PRODUCT.HORSE_POWER")}</th>
                                <td
                                  colSpan={
                                    data.product.exhaust !== null &&
                                    data.product.exhaust > 0
                                      ? 1
                                      : 3
                                  }
                                >
                                  {data.product.horse_power.toLocaleString()} HP
                                </td>
                              </>
                            )}
                        </tr>
                      ))}
                  <tr>
                    <th>{t("VEHICLE.PRODUCT.SIZE")}</th>
                    <td colSpan={3}>
                      {data.product.length.toLocaleString()}mm(L) x{" "}
                      {data.product.width.toLocaleString()}mm(W) x{" "}
                      {data.product.height.toLocaleString()}mm(H)
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className={classes.SNS}>
                <Button
                  variant="contained"
                  className="whatsapp"
                  onClick={() => handleSNSOnClick(whatsappURL)}
                >
                  <Icon path={WhatsappIcon} />
                  WhatsApp
                </Button>
                <Button
                  variant="contained"
                  className="messenger"
                  onClick={() => handleSNSOnClick(facebookURL)}
                >
                  <Icon path={FacebookMessengerIcon} />
                  Messenger
                </Button>
                <Button
                  variant="contained"
                  className="dropbox"
                  onClick={() => handleSNSOnClick(data.media.dropbox)}
                >
                  <Icon path={DropboxIcon} />
                  Get Image From Dropbox
                </Button>
                <Button
                  variant="contained"
                  className="request"
                  onClick={() => setDialogOpen(true)}
                >
                  <SupportAgentIcon />
                  Booking Request
                </Button>
              </div>
            </div>
          </div>
          <Modal
            className={classes.modal}
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            disableAutoFocus={true}
          >
            <div className={classes.modalDiv}>
              <div className={classes.modalClose}>
                <IconButton onClick={() => setModalOpen(false)}>
                  <CloseIcon sx={{ color: "white", fontSize: "2rem" }} />
                </IconButton>
              </div>
              <ReactPlayer
                className={classes.modalPlayer}
                url={data.media.youtube}
                controls
              />
            </div>
          </Modal>
          <Dialog
            classes={{ paper: classes.dialog }}
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
            aria-labelledby="request-confirm-title"
            aria-describedby="request-confirm-description"
          >
            <DialogTitle
              id="request-confirm-title"
              classes={{ root: classes.dialogTitle }}
            >
              <Markup content={t("DETAIL.BOOKING.MSG.TITLE")} />
            </DialogTitle>
            <DialogContent classes={{ root: classes.dialogContent }}>
              <DialogContentText id="request-confirm-description">
                <Markup content={t("DETAIL.BOOKING.MSG.CONTENT")} />
              </DialogContentText>
            </DialogContent>
            <DialogActions classes={{ root: classes.dialogActions }}>
              <Button onClick={() => handleOnApply()}>
                {t("OPTION.APPLY")}
              </Button>
              <Button onClick={() => setDialogOpen(false)}>
                {t("OPTION.CANCEL")}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
};

export default InfoContainer;
