import { useEffect, useState } from "react";

// Components
import { SNSIcon } from "@/common/components";
// Styles
import { useStyles } from "@/common/lib/style/hooks";

const styles = () => ({
  container: {
    right: 75,
    zIndex: 99,
    display: "flex",
    flexDirection: "column",
    "&.on": {
      position: "absolute",
      bottom: 370, // 295 + 75
    },
    "&:not(.on)": {
      position: "fixed",
      bottom: 75,
    },
  },
  snsIcon: {
    margin: "20px 0",
  },
});

const SNSFab = () => {
  const classes = useStyles(styles);
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const checkScroll = () => {
      // window.innerHeight : 보여지는 화면(969)
      // window.scrollY : 현재 스크롤 위치(0 ~ 509)
      // document.documentElement.scrollHeight : 화면 전체 크기(1478)
      if (
        window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight - 295
      ) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", checkScroll);

    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  });

  return (
    <div className={isFixed ? `${classes.container} on` : classes.container}>
      <SNSIcon sns="whatsApp" />
      <SNSIcon sns="facebook" className={classes.snsIcon} />
      <SNSIcon sns="youtube" />
    </div>
  );
};

export default SNSFab;
