// Components
import { Theme } from "@mui/material";
import StatusMark from "./StatusMark";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// Libs
import { isNotNull } from "@/common/lib/common";

const styles = (theme: Theme) => ({
  container: {
    position: "relative",
    width: "fit-content",
    height: "22.5vh",
    margin: "auto",
    "& > div": {
      width: "unset",
      height: "6.5%",
      aspectRatio: "1",
      fontSize: "35%",
    },
  },
  image: {
    height: "inherit",
    fill: theme.palette.text.primary,
  },
  bonnet: {
    position: "absolute",
    top: "15%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  roof_panel: {
    position: "absolute",
    top: "55%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  trunk_lid: {
    position: "absolute",
    top: "87.5%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
});

const FrontSideImage = ({ ...props }: any) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1450 2300"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <g transform="translate(0, 2300) scale(0.1, -0.1)">
        <path
          d="M6825 22794 c-507 -30 -1072 -100 -1540 -190 -347 -67 -1024 -238
  -1104 -278 -25 -13 -93 -38 -151 -56 -210 -65 -536 -219 -715 -339 -292 -194
  -464 -392 -635 -731 -45 -88 -96 -178 -115 -200 -21 -26 -35 -53 -35 -69 0
  -38 -34 -134 -71 -199 -62 -110 -118 -263 -268 -729 l-91 -282 -11 -183 c-6
  -102 -13 -781 -16 -1533 l-5 -1350 91 -78 c101 -87 111 -110 58 -132 -49 -20
  -356 -35 -741 -35 l-341 0 -305 -316 c-168 -174 -372 -386 -454 -470 l-148
  -155 21 -310 c41 -592 76 -771 155 -804 89 -38 395 -44 515 -11 89 25 205 90
  667 374 241 149 486 299 543 334 112 68 222 162 247 211 12 22 14 44 9 83 -15
  122 52 33 162 -214 210 -471 365 -918 451 -1302 45 -202 70 -364 92 -606 24
  -251 32 -313 65 -479 24 -119 48 -333 60 -535 10 -170 53 -683 95 -1135 54
  -575 70 -831 77 -1240 12 -715 -48 -1404 -202 -2310 -82 -484 -120 -628 -335
  -1280 -411 -1248 -463 -1495 -477 -2285 -10 -595 40 -1460 107 -1854 34 -200
  120 -464 220 -673 67 -141 114 -210 161 -234 29 -14 39 -26 39 -43 0 -102 383
  -359 705 -474 435 -154 1215 -294 2045 -367 647 -57 1309 -97 1600 -97 291 0
  953 40 1600 97 830 73 1610 213 2045 367 322 115 705 372 705 474 0 17 10 29
  39 43 47 24 94 93 161 234 100 209 186 473 220 673 67 394 117 1259 107 1854
  -14 790 -66 1037 -477 2285 -215 652 -253 796 -335 1280 -154 906 -214 1595
  -202 2310 7 409 23 665 77 1240 42 452 85 965 95 1135 12 202 36 416 60 535
  33 166 41 228 65 479 22 242 47 404 92 606 86 384 241 831 451 1302 110 247
  177 336 162 214 -5 -39 -3 -61 9 -83 25 -49 135 -143 247 -211 57 -35 302
  -185 543 -334 462 -284 578 -349 667 -374 120 -33 426 -27 515 11 79 33 114
  212 155 804 l21 310 -148 155 c-82 84 -286 296 -454 470 l-305 316 -341 0
  c-385 0 -692 15 -741 35 -53 22 -43 45 58 132 l91 78 -5 1350 c-3 752 -10
  1431 -16 1533 l-11 183 -91 282 c-150 466 -206 619 -268 729 -37 65 -71 161
  -71 199 0 16 -14 43 -35 69 -19 22 -70 112 -115 200 -171 339 -343 537 -635
  731 -179 120 -505 274 -715 339 -58 18 -126 43 -151 56 -80 40 -757 211 -1104
  278 -338 65 -874 139 -1250 172 -159 14 -987 27 -1140 18z m1030 -269 c1089
  -89 2186 -345 2805 -655 260 -131 561 -337 601 -413 22 -39 -8 -32 -766 190
  -322 94 -939 234 -1337 304 -470 82 -1205 133 -1908 133 -598 0 -1136 -31
  -1643 -95 -400 -50 -1155 -212 -1602 -342 -560 -164 -685 -199 -729 -204 -46
  -6 -48 -5 -37 14 40 76 341 282 601 413 636 318 1831 592 2885 660 94 6 193
  12 220 14 114 9 734 -4 910 -19z m-180 -685 c290 -15 761 -58 1120 -101 180
  -22 861 -153 1130 -219 484 -117 1218 -358 1470 -482 121 -60 141 -75 159
  -118 10 -24 27 -45 46 -55 29 -15 33 -23 45 -99 4 -22 18 -62 32 -90 79 -156
  251 -645 313 -891 94 -372 104 -539 104 -1715 0 -1102 -8 -1260 -66 -1291 -39
  -21 -154 -3 -323 49 -88 27 -272 84 -410 126 -598 185 -1306 362 -1580 395
  -49 6 -137 15 -194 21 -57 6 -153 22 -214 36 -231 53 -632 96 -1362 146 -117
  8 -384 13 -695 13 -311 0 -578 -5 -695 -13 -730 -50 -1131 -93 -1362 -146 -61
  -14 -157 -30 -214 -36 -57 -6 -144 -15 -194 -21 -274 -33 -982 -210 -1580
  -395 -137 -42 -322 -99 -410 -126 -169 -52 -284 -70 -323 -49 -58 31 -66 189
  -66 1291 0 1176 10 1343 104 1715 62 246 234 735 313 891 14 28 28 68 32 90
  12 76 16 84 45 99 19 10 36 31 46 55 18 43 38 58 159 118 252 124 986 365
  1470 482 269 66 950 197 1130 219 336 40 840 87 1090 100 222 12 663 12 880 1z
  m5 -4800 c548 -14 720 -27 1410 -106 684 -78 895 -113 1292 -213 728 -185
  1393 -384 1568 -469 124 -61 124 -47 -17 -333 -223 -449 -275 -570 -298 -682
  -4 -20 -15 -31 -35 -39 -20 -7 -32 -19 -36 -36 -22 -102 -39 -151 -87 -253
  -98 -206 -190 -470 -223 -636 -2 -12 -16 -35 -30 -52 -30 -35 -44 -80 -44
  -136 0 -54 -24 -143 -60 -224 -22 -50 -33 -65 -47 -63 -10 2 -123 32 -250 68
  -428 118 -856 212 -1183 259 -96 14 -294 45 -440 70 -298 50 -381 60 -630 75
  -810 48 -2360 37 -2910 -20 -52 -6 -214 -30 -360 -55 -146 -25 -344 -56 -440
  -70 -327 -47 -755 -141 -1183 -259 -127 -36 -240 -66 -250 -68 -33 -6 -107
  194 -107 287 0 56 -14 101 -44 136 -14 17 -28 40 -30 52 -33 166 -125 430
  -223 636 -48 102 -65 151 -87 253 -4 17 -16 29 -36 36 -20 8 -31 19 -35 39
  -23 112 -75 233 -298 682 -141 286 -141 272 -17 333 175 85 840 284 1568 469
  397 100 608 135 1292 213 669 77 892 94 1355 105 451 11 499 11 915 1z m-5669
  -1035 c3 -93 10 -281 14 -416 l7 -246 -154 -88 c-181 -104 -405 -242 -468
  -289 -25 -19 -151 -93 -280 -166 -129 -73 -269 -155 -310 -181 -41 -27 -80
  -49 -86 -49 -44 0 -67 78 -95 318 -11 103 -29 236 -39 295 -12 71 -15 119 -10
  138 20 69 100 159 479 542 l315 317 311 -2 310 -3 6 -170z m11420 -142 c379
  -383 459 -473 479 -542 5 -19 2 -67 -10 -138 -10 -59 -28 -192 -39 -295 -28
  -240 -51 -318 -95 -318 -6 0 -45 22 -86 49 -41 26 -181 108 -310 181 -129 73
  -255 147 -280 166 -63 47 -287 185 -467 288 l-153 88 0 36 c0 91 22 778 25
  790 3 9 75 12 313 12 l308 0 315 -317z m-5673 -1802 c40 -12 233 -28 502 -41
  318 -16 739 -52 761 -65 12 -8 402 -76 659 -115 454 -70 683 -121 790 -175 66
  -34 74 -51 61 -133 -26 -156 -80 -704 -125 -1262 -52 -628 -77 -1033 -98
  -1580 -17 -435 -17 -2185 0 -2560 24 -516 47 -799 77 -910 8 -30 11 -58 7 -62
  -55 -55 -1402 -160 -2527 -198 -676 -22 -1355 -7 -2365 55 -752 46 -1357 108
  -1392 143 -4 4 -1 32 7 62 30 111 53 394 77 910 17 375 17 2125 0 2560 -21
  547 -46 952 -98 1580 -45 558 -99 1106 -125 1262 -13 82 -5 99 61 133 107 54
  336 105 790 175 257 39 647 107 659 115 22 13 443 49 761 65 265 13 460 29
  496 40 40 11 981 12 1022 1z m-4184 -546 c17 -8 37 -24 43 -34 18 -28 33 -133
  33 -226 0 -101 14 -190 40 -247 10 -24 26 -96 34 -160 18 -135 112 -1888 148
  -2768 18 -420 17 -1849 0 -2070 -34 -429 -81 -684 -141 -767 -24 -33 -54 -43
  -133 -44 -95 -2 -102 7 -94 116 3 44 26 206 51 360 97 591 121 797 135 1140
  12 292 25 455 48 600 13 87 17 173 16 415 0 370 -23 670 -94 1250 -27 221 -34
  296 -60 645 -6 77 -19 183 -30 235 -30 151 -48 305 -65 550 -11 161 -35 395
  -61 594 -13 109 -26 239 -27 288 -2 87 -1 91 26 114 33 28 83 32 131 9z m7483
  -9 c27 -23 28 -27 26 -114 -1 -49 -14 -179 -27 -288 -26 -199 -50 -433 -61
  -594 -17 -245 -35 -399 -65 -550 -11 -52 -24 -158 -30 -235 -26 -349 -33 -424
  -60 -645 -71 -580 -94 -880 -94 -1250 -1 -242 3 -328 16 -415 23 -145 36 -308
  48 -600 14 -343 38 -549 135 -1140 25 -154 48 -316 51 -360 8 -109 1 -118 -94
  -116 -79 1 -109 11 -133 44 -60 83 -107 338 -141 767 -17 221 -18 1650 0 2070
  36 880 130 2633 148 2768 8 64 24 136 34 160 26 57 40 146 40 247 0 93 15 198
  33 226 31 50 128 64 174 25z m-7392 -6606 c261 -32 430 -48 815 -80 201 -17
  502 -44 670 -60 504 -49 732 -55 2100 -55 1368 0 1596 6 2100 55 168 16 469
  43 670 60 435 36 856 80 1007 105 32 6 33 4 63 -62 49 -108 80 -208 90 -293 5
  -46 23 -113 44 -167 19 -49 58 -168 86 -264 29 -95 69 -219 91 -274 45 -117
  69 -205 69 -255 0 -49 18 -111 40 -135 30 -34 41 -64 43 -119 3 -67 -15 -101
  -67 -125 -71 -34 -622 -187 -841 -234 -662 -142 -1294 -226 -1950 -259 -348
  -18 -2542 -18 -2890 0 -656 33 -1288 117 -1950 259 -219 47 -770 200 -841 234
  -52 24 -70 58 -67 125 2 55 13 85 43 119 22 24 40 86 40 135 0 50 24 138 69
  255 22 55 62 179 91 274 28 96 67 215 86 264 21 54 39 121 44 167 10 85 41
  185 90 293 30 66 31 68 63 62 18 -3 104 -14 192 -25z m-609 -1984 c938 -278
  1697 -390 3044 -448 379 -16 1921 -16 2300 0 1349 58 2102 169 3047 449 l153
  45 37 -19 c33 -16 41 -27 60 -83 88 -263 115 -1161 58 -1940 -24 -336 -54
  -608 -76 -692 -13 -50 -74 -102 -195 -165 -109 -56 -483 -223 -636 -284 -469
  -186 -1190 -338 -2083 -438 -397 -45 -428 -46 -1515 -46 -1087 0 -1118 1
  -1515 46 -893 100 -1614 252 -2083 438 -153 61 -527 228 -636 284 -121 63
  -182 115 -195 165 -22 84 -52 356 -76 692 -57 779 -30 1677 58 1940 19 55 27
  68 57 82 19 10 38 18 41 18 3 0 72 -20 155 -44z m-5 -3235 c46 -16 163 -58
  259 -94 268 -99 466 -160 728 -226 622 -156 1316 -266 1977 -313 236 -16 2234
  -16 2470 0 661 47 1355 157 1977 313 262 66 460 127 728 226 227 84 341 123
  357 123 43 0 -193 -333 -356 -503 -110 -114 -290 -216 -551 -310 -165 -60
  -309 -97 -560 -146 -824 -161 -1044 -187 -2210 -263 -252 -16 -988 -16 -1240
  0 -1166 76 -1386 102 -2210 263 -251 49 -395 86 -560 146 -261 94 -441 196
  -551 310 -163 170 -399 503 -356 503 7 0 51 -13 98 -29z"
        />
      </g>
    </svg>
  );
};

// 1. 보닛      4. 트렁크리드     7. 루프패널
const FrontSide = ({ data, ...props }: any) => {
  const classes = useStyles(styles);

  return (
    <>
      <div
        className={
          props.className !== undefined
            ? `${classes.container} ${props.className}`
            : `${classes.container}`
        }
      >
        <FrontSideImage className={classes.image} />
        {data.inspection !== undefined && isNotNull(data.inspection.bonnet) && (
          <StatusMark
            className={classes.bonnet}
            status={data.inspection.bonnet}
          />
        )}
        {data.inspection !== undefined &&
          isNotNull(data.inspection.roof_panel) && (
            <StatusMark
              className={classes.roof_panel}
              status={data.inspection.roof_panel}
            />
          )}
        {data.inspection !== undefined &&
          isNotNull(data.inspection.trunk_lid) && (
            <StatusMark
              className={classes.trunk_lid}
              status={data.inspection.trunk_lid}
            />
          )}
      </div>
    </>
  );
};

export default FrontSide;
