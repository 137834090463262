/* eslint-disable react-hooks/exhaustive-deps */
// Redux
import { useTypedSelector } from "@/common/lib/redux/store";
import ReactPlayer from "react-player";
// Components
import { Card, CardContent, Theme } from "@mui/material";
import BasePage from "@/browser/pages/common/BasePage";
import { SNSFab } from "@/browser/components/common";
import {
  IndexSearchContainer,
  MainCarousel,
  ProductCarousel,
} from "@/browser/components/index";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// Libs
import { getUTCDate, isNotNull } from "@/common/lib/common";

const styles = (theme: Theme) => ({
  basePage: {
    marginBottom: "15px !important",
  },
  title: {
    fontSize: 28,
    fontWeight: "bold",
    "& > span > span": {
      fontSize: 36,
      color: theme.palette.primary.main,
    },
  },
  topDiv: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    marginTop: 35,
    padding: "15px 0 30px",
  },
  card: {
    width: 450,
    aspectRatio: "1.778",
    boxSizing: "border-box",
    border: "1px solid " + theme.palette.divider,
    borderRadius: 10,
    boxShadow: "0 0 5px " + theme.palette.divider,
  },
  cardContent: {
    height: "100%",
    boxSizing: "border-box",
    padding: "0px !important",
  },
});

const IndexPage = () => {
  const classes = useStyles(styles);
  const { loginToken } = useTypedSelector((state) => state.auth);

  const promoteData = [
    {
      key: "promoteData",
      page: 1,
      size: 32,
      align: "-discount_expired",
      params: {
        status: "ON",
        criteria: JSON.stringify([
          {
            type: "DISCOUNT_EXPIRED",
            period: getUTCDate(new Date()) + "~2099-12-31T23:59",
          },
        ]),
      },
    },
  ];
  const suvData = [
    {
      key: "suvData_1",
      page: 1,
      size: 8,
      align: "-modified",
      params: {
        status: "ON",
        model: "SantaFe",
      },
    },
    {
      key: "suvData_2",
      page: 1,
      size: 8,
      align: "-modified",
      params: {
        status: "ON",
        model: "Tucson",
      },
    },
    {
      key: "suvData_3",
      page: 1,
      size: 8,
      align: "-modified",
      params: {
        status: "ON",
        model: "Sportage",
      },
    },
    {
      key: "suvData_4",
      page: 1,
      size: 8,
      align: "-modified",
      params: {
        status: "ON",
        model: "Sorento",
      },
    },
  ];
  const vanData = [
    {
      key: "vanData_1",
      page: 1,
      size: 8,
      align: "-modified",
      params: {
        status: "ON",
        model: "Carnival",
      },
    },
    {
      key: "vanData_2",
      page: 1,
      size: 8,
      align: "-modified",
      params: {
        status: "ON",
        model: "Starex",
      },
    },
  ];
  const newCarData = [
    {
      key: "newCarData_1",
      page: 1,
      size: 24,
      align: "-created",
      params: {
        status: "ON",
        type: "CAR",
      },
    },
  ];
  const newTruckData = [
    {
      key: "newTruckData_1",
      page: 1,
      size: 24,
      align: "-created",
      params: {
        status: "ON",
        type: "TRUCK",
      },
    },
  ];

  const playerSettings = {
    youtube: {
      playerVars: { showinfo: 1 },
    },
  };

  return (
    <BasePage className={classes.basePage}>
      <MainCarousel />
      <div className={classes.topDiv}>
        <IndexSearchContainer />
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <ReactPlayer
              url="https://youtu.be/DuM88xJRMtY"
              config={playerSettings}
              controls
              width="100%"
              height="100%"
            />
          </CardContent>
        </Card>
      </div>
      {isNotNull(loginToken) && (
        <ProductCarousel
          position="right"
          title="Special Promotion <span>SALE</span>"
          rows={1}
          data={promoteData}
        />
      )}
      <ProductCarousel
        position="left"
        title="<span>Top 4</span> Most Popular SUV/VANs"
        label="<span>Best</span><br>SUV<br>Models"
        rows={1}
        data={suvData}
      />
      <ProductCarousel
        position="left"
        label="<span>Best</span><br>Van<br>Models"
        rows={1}
        data={vanData}
      />
      <ProductCarousel
        position="right"
        title="<span>New</span> Products in Stock"
        label="<span>New</span><br>Arrival<br>Cars"
        rows={1}
        data={newCarData}
      />
      <ProductCarousel
        position="right"
        label="<span>New</span><br>Arrival<br>Trucks"
        rows={1}
        data={newTruckData}
      />
      <SNSFab />
    </BasePage>
  );
};

export default IndexPage;
