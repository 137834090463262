/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { getCountries, CountryCode } from "libphonenumber-js";
// Redux
import { useTypedDispatch } from "@/common/lib/redux/store";
import { loadingOn, loadingOff } from "@/common/lib/redux/actions/commonAction";
// Components
import { Input } from "@/common/components";
import ShippingTable from "./ShippingTable";
import {
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  Theme,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SearchIcon from "@mui/icons-material/Search";
import DatePicker from "react-datepicker";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
import "react-datepicker/dist/react-datepicker.css";
// API
import { getShippingScheduleList } from "@/common/lib/api/shippingSchdule";

const styles = (theme: Theme) => ({
  topDiv: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 5,
    userSelect: "none",
    "& > button": {
      padding: 0,
      margin: "0 15px",
      color: theme.palette.text.primary + "!important",
      "& > svg": {
        fontSize: "3rem",
      },
      "&:hover": {
        color: theme.palette.primary.main + "!important",
      },
    },
    "& > .all": {
      fontSize: "1.85rem",
      fontWeight: "bold",
    },
    "& > .date": {
      cursor: "pointer",
      fontSize: "1.85rem",
      fontWeight: "bold",
      "&:hover": {
        color: theme.palette.primary.main,
      },
      "& > span": {
        fontSize: "1.75rem",
        fontWeight: "bold",
        marginRight: 5,
      },
    },
    "& > .view": {
      position: "absolute",
      bottom: 0,
      right: 0,
      cursor: "pointer",
      fontSize: "0.925rem",
      "&:hover": {
        color: theme.palette.primary.main,
        textDecoration: "underline",
      },
    },
  },
  searchDiv: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 7.5,
    "& > div": {
      width: 285,
    },
  },
  titleDiv: {
    display: "flex",
    alignItems: "center",
    "& > img": {
      width: "25%",
      aspectRatio: "1.33",
      borderRadius: 5,
      marginRight: 12.5,
    },
    "& > div": {
      width: "75%",
    },
  },
  table: {
    width: "100%",
  },
  location: {
    textAlign: "center",
    "& > .MuiTypography-body1": {
      fontSize: "0.875rem",
      fontWeight: 600,
      lineHeight: "1.25",
      wordBreak: "keep-all",
      marginTop: -1.5,
    },
    "& > .MuiTypography-body2": {
      fontSize: "0.825rem",
      fontWeight: 500,
      lineHeight: "1.25",
      wordBreak: "keep-all",
    },
  },
});

interface DataType {
  vesselName: string;
  vesselType: string;
  line: string;
  arrivalCountry: string;
  arrivalPort: string;
  arrivalDate: Date;
  departureCountry: string;
  departurePort: string;
  departureDate: Date;
}

const ShippingContainer = () => {
  const classes = useStyles(styles);
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();

  const anchorRef = useRef<HTMLParagraphElement>(null);

  const [open, setOpen] = useState(false);
  const [view, setView] = useState("ALL");
  const [date, setDate] = useState<Date | null>(
    moment(new Date()).startOf("month").local().toDate()
  );
  const [searchText, setSearchText] = useState("");
  const [countryList, setCountryList] = useState<
    {
      code: CountryCode;
      region: string | undefined;
    }[]
  >([]);

  const handlePrevOnClick = () => {
    if (date !== null) {
      const prevDate = new Date(date?.setMonth(date.getMonth() - 1));
      setDate(prevDate);
    }
  };
  const handleNextOnClick = () => {
    if (date !== null) {
      const nextDate = new Date(date?.setMonth(date.getMonth() + 1));
      setDate(nextDate);
    }
  };
  const handleDateOnClick = () => {
    setOpen(true);
  };
  const getCountryRegion = (code: any) => {
    if (typeof code === "string" && code !== "") {
      if (countryList.filter((item) => item.code === code).length > 0) {
        return countryList.filter((item) => item.code === code)[0].region;
      } else {
        return "";
      }
    }
    return "";
  };

  const shippingScheduleListQuery = useQuery(
    "shippingScheduleList",
    () => {
      dispatch(loadingOn());
      return getShippingScheduleList();
    },
    {
      onSuccess: () => {
        dispatch(loadingOff());
      },
      onError: () => {
        dispatch(loadingOff());
      },
    }
  );

  const SearchFilter = ({ column: { setFilter } }: any) => {
    useEffect(() => {
      setFilter(searchText);
    }, [searchText]);

    return null;
  };
  const DateMonthFilter = ({ column: { setFilter } }: any) => {
    useEffect(() => {
      setFilter(date);
    }, [date]);

    return null;
  };

  const columns: any = [
    {
      Header: "Vessel Name & Voy No.",
      accessor: "vesselName",
      disableFilters: true,
    },
    {
      Header: "Vessel\nType",
      accessor: "vesselType",
      disableFilters: true,
    },
    {
      Header: "Line",
      accessor: "line",
      disableFilters: true,
    },
    {
      Header: "Departure\nCountry",
      accessor: "departureCountry",
      Cell: (e: any) => {
        return (
          <div className={classes.location}>
            <img
              loading="lazy"
              width="40"
              src={`https://flagcdn.com/w40/${e.row.original[
                "departureCountry"
              ]?.toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${e.row.original[
                "departureCountry"
              ]?.toLowerCase()}.png 2x`}
              alt=""
            />
            <Typography variant="body1">
              {getCountryRegion(e.row.original["departureCountry"])}
            </Typography>
            <Typography variant="body2">
              {e.row.original["departurePort"]}
            </Typography>
          </div>
        );
      },
      Filter: SearchFilter,
      filter: "search",
    },
    {
      Header: "Arrival\nCountry",
      accessor: "arrivalCountry",
      Cell: (e: any) => {
        return (
          <div className={classes.location}>
            <img
              loading="lazy"
              width="40"
              src={`https://flagcdn.com/w40/${e.row.original[
                "arrivalCountry"
              ]?.toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${e.row.original[
                "arrivalCountry"
              ]?.toLowerCase()}.png 2x`}
              alt=""
            />
            <Typography variant="body1">
              {getCountryRegion(e.row.original["arrivalCountry"])}
            </Typography>
            <Typography variant="body2">
              {e.row.original["arrivalPort"]}
            </Typography>
          </div>
        );
      },
      Filter: SearchFilter,
      filter: "search",
    },
    {
      Header: "Departure\nDate",
      accessor: "departureDate",
      Filter: DateMonthFilter,
      filter: "date",
    },
  ];
  const [data, setData] = useState<DataType[]>([]);
  const updateData = (rowIndex: number, columnId: string, value: any) => {
    setData((prevData) =>
      prevData.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...prevData[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };
  const filterTypes = {
    search: (rows: any[], _id: any, filterValue: any) => {
      return rows.filter((val) => {
        const arrivalCountryValue_Low = String(
          getCountryRegion(val.original["arrivalCountry"])
        ).toLowerCase();
        const arrivalPortValue_Low = String(
          val.original["arrivalPort"]
        ).toLowerCase();
        const departureCountryValue_Low = String(
          getCountryRegion(val.original["departureCountry"])
        ).toLowerCase();
        const departurePortValue_Low = String(
          val.original["departurePort"]
        ).toLowerCase();

        const filterValue_Low = String(filterValue).toLocaleLowerCase();

        if (
          arrivalCountryValue_Low.includes(filterValue_Low) ||
          arrivalPortValue_Low.includes(filterValue_Low) ||
          departureCountryValue_Low.includes(filterValue_Low) ||
          departurePortValue_Low.includes(filterValue_Low)
        ) {
          return true;
        } else {
          return false;
        }
      });
    },
    date: (rows: any[], _id: any, filterValue: any) => {
      let startDate = moment(filterValue).local().startOf("month");
      let endDate = moment(filterValue).local().endOf("month");

      return view === "ALL"
        ? rows.filter(() => {
            return true;
          })
        : rows.filter((val) => {
            return moment(val.original["departureDate"])
              .local()
              .isBetween(startDate, endDate);
          });
    },
  };

  useEffect(() => {
    const regionNames = new Intl.DisplayNames(["en"], { type: "region" });
    const countryList: {
      code: CountryCode;
      region: string | undefined;
    }[] = [];
    getCountries().forEach((countryCode) => {
      if (!["AC", "TA"].includes(countryCode)) {
        countryList.push({
          code: countryCode,
          region: regionNames.of(countryCode),
        });
      }
    });
    const countrySortList = countryList.sort((a, b) => {
      if (a.region !== undefined && b.region !== undefined) {
        return a.region.localeCompare(b.region);
      } else {
        return a.code.localeCompare(b.code);
      }
    });
    setCountryList(countrySortList);
  }, []);

  useEffect(() => {
    if (shippingScheduleListQuery.isSuccess) {
      const result: DataType[] = [];
      shippingScheduleListQuery.data.data.results.forEach((data: any) => {
        result.push({
          vesselName: data.vessel_name,
          vesselType: data.vessel_type,
          line: data.line,
          arrivalCountry: data.arrival_country,
          arrivalPort: data.arrival_port,
          arrivalDate: data.arrival_date,
          departureCountry: data.departure_country,
          departurePort: data.departure_port,
          departureDate: data.departure_date,
        });
      });
      setData(result);
    }
  }, [shippingScheduleListQuery.isLoading]);

  return (
    <div>
      <div className={classes.topDiv}>
        {view === "ALL" && (
          <>
            <IconButton disabled>
              <NavigateBeforeIcon />
            </IconButton>
            <Typography className="all">{t("MY_LIST.ALL")}</Typography>
            <IconButton disabled>
              <NavigateNextIcon />
            </IconButton>
            <Typography
              className="view"
              onClick={() => {
                setView("DATE");
                setDate(moment(new Date()).startOf("month").local().toDate());
              }}
            >
              {t("MY_LIST.VIEW_MONTH")}
            </Typography>
          </>
        )}
        {view === "DATE" && (
          <>
            <IconButton onClick={() => handlePrevOnClick()}>
              <NavigateBeforeIcon />
            </IconButton>
            {date !== null && (
              <Typography
                className="date"
                onClick={() => handleDateOnClick()}
                ref={anchorRef}
              >
                {date.getFullYear()}
                <span>.</span>
                {("0" + (date.getMonth() + 1)).slice(-2)}
              </Typography>
            )}
            <IconButton onClick={() => handleNextOnClick()}>
              <NavigateNextIcon />
            </IconButton>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              placement="bottom"
              transition
              disablePortal
              style={{ zIndex: 5 }}
            >
              {({ TransitionProps }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: "top",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={() => setOpen(false)}>
                      <div>
                        <DatePicker
                          selected={date}
                          onChange={(date) => {
                            setDate(date);
                            setOpen(false);
                          }}
                          inline
                          dateFormat="MM/yyyy"
                          showMonthYearPicker
                        />
                      </div>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
            <Typography className="view" onClick={() => setView("ALL")}>
              {t("MY_LIST.VIEW_ALL")}
            </Typography>
          </>
        )}
      </div>
      <div className={classes.searchDiv}>
        <Input
          isClearable
          variant="standard"
          value={searchText}
          dispatch={setSearchText}
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <ShippingTable
        className={classes.table}
        columns={columns}
        data={data}
        updateData={updateData}
        filterTypes={filterTypes}
        noDataText={t("SUPPORT.SHIPPING.MSG.NO_DATA")}
      />
    </div>
  );
};

export default ShippingContainer;
