import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
import { useSnackbar } from "notistack";
// Redux
import { setLoginToken } from "@/common/lib/redux/actions/authAction";
import { loadingOn, loadingOff } from "@/common/lib/redux/actions/commonAction";
import { initWishList } from "@/common/lib/redux/actions/globalAction";
import { useTypedDispatch, useTypedSelector } from "@/common/lib/redux/store";
// Components
import { Card, CardContent, Divider, Theme, Typography } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Image } from "@/common/components";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// API
import { onError } from "@/common/lib/api/common";
import { logoutAPI } from "@/common/lib/api/auth";
// Libs
import {
  checkLogin,
  getTokenData,
  isNull,
  isNotNull,
} from "@/common/lib/common";

const styles = (theme: Theme) => ({
  card: {
    width: 215,
    height: "fit-content",
    overflow: "visible",
    marginTop: 0,
    borderRadius: 0,
    userSelect: "none",
    padding: "12px 16px",
    "&.on": {
      position: "sticky",
      top: 45,
    },
  },
  userContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 0,
  },
  menuContent: {
    padding: "0 10px !important",
  },
  userIcon: {
    fontSize: 125,
    color: "rgba(0, 0, 0, 0.54)",
  },
  userImage: {
    width: 110,
    aspectRatio: "1",
    borderRadius: "50%",
    objectFit: "cover",
    marginTop: 5,
    marginBottom: 10,
  },
  userTypography: {
    fontSize: 16,
    fontWeight: 500,
    textAlign: "center",
    "& > span.puid": {
      "&:hover": {
        color: theme.palette.primary.main,
        cursor: "copy",
        textDecoration: "underline",
      },
    },
  },
  userInfo: {
    width: "100%",
    borderTop: "2px solid " + theme.palette.text.secondary,
    borderBottom: "2px solid " + theme.palette.text.secondary,
    marginTop: 25,
    marginBottom: 0,
    padding: 12.5,
    alignSelf: "center",
    textAlign: "center",
    "& > p": {
      fontWeight: 500,
      userSelect: "none",
    },
    "& > div": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderTop: "2px solid " + theme.palette.text.secondary,
      marginTop: 10,
      paddingTop: 10,
      userSelect: "none",
      "& > p": {
        cursor: "pointer",
      },
      "& > p:not(:first-child):before": {
        display: "inline-block",
        content: "''",
        width: 2,
        height: 11.5,
        backgroundColor: theme.palette.text.secondary,
        margin: "0 10px",
      },
      "& > p:hover": {
        textDecoration: "underline",
      },
    },
  },
  menuUl: {
    margin: "0 20px 0",
    padding: 0,
    "& > li": {
      userSelect: "none",
      fontSize: 15,
      margin: "18.5px 0",
      "&:hover": {
        cursor: "pointer",
        textDecoration: "underline",
        color: theme.palette.primary.main,
      },
    },
  },
});

const SideMenu = ({ ...props }: { [key: string]: any }) => {
  const classes = useStyles(styles);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();
  const { loginToken } = useTypedSelector((state) => state.auth);
  const { init } = useTypedSelector((state) => state.common);
  const { enqueueSnackbar } = useSnackbar();

  const [isFixed, setIsFixed] = useState(false);
  const [tokenData, setTokenData] = useState<TokenData>({
    id: null,
    puid: undefined,
    guid: undefined,
    name: undefined,
    username: undefined,
    group: null,
    image: undefined,
    is_staff: false,
    is_manager: false,
    is_superuser: false,
  });

  const logoutMutation = useMutation(logoutAPI, {
    onSuccess: () => {
      dispatch(setLoginToken(""));
      dispatch(initWishList());
      navigate("/");
    },
    onError: (error) => {
      onError(error);
    },
    onSettled: () => {
      dispatch(loadingOff());
    },
  });

  const handleMyInfoOnClick = () => {
    navigate("/myinfo");
  };
  const handleLoginOnClick = () => {
    navigate("/login");
  };
  const handleLogoutOnClick = () => {
    dispatch(loadingOn());
    logoutMutation.mutate();
  };
  const handleJoinOnClick = () => {
    navigate("/join");
  };
  const handleCopyOnClick = (e: any) => {
    navigator.clipboard.writeText(e.target.textContent).then(
      () => {
        enqueueSnackbar(t("MSG.COPY_SUCCESS"), { variant: "success" });
      },
      () => {
        enqueueSnackbar(t("MSG.COPY_FAILURE"), { variant: "error" });
      }
    );
  };

  useEffect(() => {
    if (init) {
      setTokenData(getTokenData());
    }
  }, [init, loginToken]);

  useEffect(() => {
    const checkScroll = () => {
      // window.innerHeight : 보여지는 화면(969)
      // window.scrollY : 현재 스크롤 위치(0 ~ 509)
      // document.documentElement.scrollHeight : 화면 전체 크기(1478)
      if (window.innerHeight + window.scrollY >= 1196) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", checkScroll);

    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  });

  return (
    <Card
      classes={{ root: isFixed ? `${classes.card} on` : `${classes.card}` }}
      className={isNotNull(props.className) ? props.className : undefined}
      variant="outlined"
    >
      <CardContent classes={{ root: classes.userContent }}>
        <div className={classes.userInfo}>
          {isNull(tokenData.image) ? (
            <AccountCircleIcon className={classes.userIcon} />
          ) : (
            <Image alt="" src={tokenData.image} className={classes.userImage} />
          )}
          <Typography classes={{ root: classes.userTypography }}>
            {checkLogin() ? (
              <>
                <Markup
                  content={t("HEADER.WELCOME_MOBILE", {
                    user: tokenData["name"],
                  })}
                />
                (
                <span className="puid" onClick={handleCopyOnClick}>
                  {tokenData["puid"]}
                </span>
                )
              </>
            ) : (
              <Markup
                content={t("MSG.WELCOME_AUTOLEADER", { user: "guest" })}
              />
            )}
          </Typography>
          <div>
            {checkLogin() ? (
              <>
                <Typography onClick={handleMyInfoOnClick}>
                  {t("HEADER.MYINFO")}
                </Typography>
                <Typography onClick={handleLogoutOnClick}>
                  {t("HEADER.LOGOUT")}
                </Typography>
              </>
            ) : (
              <>
                <Typography onClick={handleLoginOnClick}>
                  {t("HEADER.LOGIN")}
                </Typography>
                <Typography onClick={handleJoinOnClick}>
                  {t("HEADER.JOIN")}
                </Typography>
              </>
            )}
          </div>
        </div>
      </CardContent>
      <CardContent classes={{ root: classes.menuContent }}>
        <ul className={classes.menuUl}>
          <li onClick={() => navigate("/shipping")}>
            {t("SUPPORT.SHIPPING.TITLE")}
          </li>
        </ul>
      </CardContent>
      <Divider />
      <CardContent classes={{ root: classes.menuContent }}>
        <ul className={classes.menuUl}>
          <li onClick={() => navigate("/offer")}>{t("OFFER.OFFER.TITLE")}</li>
          <li onClick={() => navigate("/offerlist")}>
            {t("OFFER.OFFER_LIST.TITLE")}
          </li>
        </ul>
      </CardContent>
      <Divider />
      <CardContent classes={{ root: classes.menuContent }}>
        <ul className={classes.menuUl}>
          <li onClick={() => navigate("/wishlist")}>
            {t("MY_LIST.WISH_LIST.TITLE")}
          </li>
          <li onClick={() => navigate("/booking")}>
            {t("MY_LIST.BOOKING.TITLE")}
          </li>
          <li onClick={() => navigate("/purchase-history")}>
            {t("MY_LIST.PURCHASE_HISTORY.TITLE")}
          </li>
        </ul>
      </CardContent>
    </Card>
  );
};

export default SideMenu;
