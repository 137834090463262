import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
// Components
import { CountUp } from "@/common/components";
import { Theme, Typography } from "@mui/material";
// Styles
import { useStyles } from "@/common/lib/style/hooks";

const styles = (theme: Theme) => ({
  ul: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    margin: "25px auto",
    padding: 0,
    listStyle: "none",
    "& > li": {
      width: "25%",
      display: "flex",
      alignItems: "center",
      "& > div": {
        width: "100%",
      },
    },
    "& > li:not(:first-child):before": {
      display: "inline-block",
      content: "''",
      width: 1,
      height: 125,
      backgroundColor: theme.palette.divider,
      margin: "0",
    },
  },
  typography: {
    textAlign: "center",
  },
});

const AchieveContainer = () => {
  const classes = useStyles(styles);
  const { t } = useTranslation();

  return (
    <ul className={classes.ul}>
      <li>
        <div>
          <CountUp start={0} end={155} />
          <Typography className={classes.typography}>
            <Markup content={t("PROFILE.ACHIEVE.SALES")} />
          </Typography>
        </div>
      </li>
      <li>
        <div>
          <CountUp start={0} end={3871} />
          <Typography className={classes.typography}>
            <Markup content={t("PROFILE.ACHIEVE.EXPORT")} />
          </Typography>
        </div>
      </li>
      <li>
        <div>
          <CountUp start={0} end={4359} />
          <Typography className={classes.typography}>
            <Markup content={t("PROFILE.ACHIEVE.IMPORT")} />
          </Typography>
        </div>
      </li>
      <li>
        <div>
          <CountUp start={0} end={467038} />
          <Typography className={classes.typography}>
            <Markup content={t("PROFILE.ACHIEVE.KOREA_EXPORT")} />
          </Typography>
        </div>
      </li>
    </ul>
  );
};

export default AchieveContainer;
