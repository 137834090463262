import {
  useTable,
  useFilters,
  usePagination,
  useGroupBy,
  useSortBy,
} from "react-table";
// Components
import { Dropdown } from "@/common/components";
import { IconButton, Theme, Typography } from "@mui/material";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
import { colors } from "@/common/lib/style/palette";
// Libs
import { browserViewList } from "@/common/lib/varList";
import { isNotNull, getCountryColor } from "@/common/lib/common";

const styles = (theme: Theme) => ({
  table: {
    width: "100%",
    tableLayout: "fixed",
    borderCollapse: "collapse",
    borderSpacing: 0,
    borderTop: "2px solid " + theme.palette.text.primary,
    userSelect: "none",
    "& th": {
      padding: "12.5px 15px",
      backgroundColor: colors.gray[2],
      border: "1px solid " + theme.palette.divider,
      whiteSpace: "pre-wrap",
    },
    "& td": {
      border: "1px solid " + theme.palette.divider,
      "&.CA": {
        "& .left": {
          "& > .top": {
            borderColor: colors.teal[8] + "!important",
            "& > div > .MuiTypography-body1": {
              color: colors.teal[8],
            },
            "& > svg": {
              color: colors.teal[8],
            },
          },
          "& > .bottom": {
            "& > div": {
              borderColor: colors.teal[8] + "!important",
              "& > .MuiTypography-body1": {
                color: colors.teal[8],
              },
            },
          },
        },
        "& .right": {
          "& > .top > svg": {
            color: colors.teal[8],
          },
          "& > .bottom": {
            background: colors.teal[8],
          },
        },
      },
      "&.SA": {
        "& .left": {
          "& > .top": {
            borderColor: colors.blue[6] + "!important",
            "& > div > .MuiTypography-body1": {
              color: colors.blue[6],
            },
            "& > svg": {
              color: colors.blue[6],
            },
          },
          "& > .bottom": {
            "& > div": {
              borderColor: colors.blue[6] + "!important",
              "& > .MuiTypography-body1": {
                color: colors.blue[6],
              },
            },
          },
        },
        "& .right": {
          "& > .top > svg": {
            color: colors.blue[6],
          },
          "& > .bottom": {
            background: colors.blue[6],
          },
        },
      },
      "&.CB": {
        "& .left": {
          "& > .top": {
            borderColor: colors.orange[6] + "!important",
            "& > div > .MuiTypography-body1": {
              color: colors.orange[6],
            },
            "& > svg": {
              color: colors.orange[6],
            },
          },
          "& > .bottom": {
            "& > div": {
              borderColor: colors.orange[6] + "!important",
              "& > .MuiTypography-body1": {
                color: colors.orange[6],
              },
            },
          },
        },
        "& .right": {
          "& > .top > svg": {
            color: colors.orange[6],
          },
          "& > .bottom": {
            background: colors.orange[6],
          },
        },
      },
      "&.RU": {
        "& .left": {
          "& > .top": {
            borderColor: colors.violet[5] + "!important",
            "& > div > .MuiTypography-body1": {
              color: colors.violet[5],
            },
            "& > svg": {
              color: colors.violet[5],
            },
          },
          "& > .bottom": {
            "& > div": {
              borderColor: colors.violet[5] + "!important",
              "& > .MuiTypography-body1": {
                color: colors.violet[5],
              },
            },
          },
        },
        "& .right": {
          "& > .top > svg": {
            color: colors.violet[5],
          },
          "& > .bottom": {
            background: colors.violet[5],
          },
        },
      },
    },
    "& tr:nth-child(2n) > td": {
      background: colors.gray[1],
    },
    "& .title": {
      "& > p": {
        fontSize: "1.35rem",
        fontWeight: 800,
        borderBottom: "1.5px solid " + theme.palette.text.primary,
        paddingBottom: 7.5,
      },
      "& > div": {
        display: "flex",
        paddingTop: 12.5,
        "& > p": {
          width: "50%",
          fontSize: "1.15rem",
          fontWeight: 600,
          wordSpacing: 25,
        },
      },
    },
    "& .left": {
      width: "50%",
      height: 140,
      padding: "12.5px 15px",
      float: "left",
      "& .top": {
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "space-around",
        padding: "10px 12.5px",
        borderBottom: "2px dotted " + theme.palette.primary.main,
        "& > div": {
          "& > .MuiTypography-body1": {
            fontSize: "1.1rem",
            fontWeight: 800,
            lineHeight: "1.3",
            color: theme.palette.primary.dark,
          },
          "& > .MuiTypography-body2": {
            fontSize: "0.925rem",
            fontWeight: 500,
            lineHeight: "1.3",
          },
        },
        "& > svg": {
          fontSize: "2.15rem",
          color: theme.palette.primary.dark,
        },
      },
      "& .bottom": {
        display: "inline-table",
        width: "100%",
        margin: "7.5px 0",
        "& > div": {
          display: "table-cell",
          width: "50%",
          textAlign: "center",
          "& > .MuiTypography-body1": {
            fontSize: "0.9rem",
            fontWeight: 800,
            color: theme.palette.primary.dark,
          },
          "& > .MuiTypography-body2": {
            fontSize: "0.875rem",
            fontWeight: 500,
          },
        },
        "& > div:first-child": {
          borderRight: "2px dotted " + theme.palette.primary.main,
        },
        "& > div:last-child": {
          borderLeft: "2px dotted " + theme.palette.primary.main,
        },
      },
    },
    "& .right": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      position: "relative",
      width: "50%",
      height: 140,
      padding: "12.5px 15px",
      float: "right",
      "& .top": {
        display: "flex",
        alignItems: "flex-start",
        paddingBottom: 7.5,
        "& > div": {
          width: "40%",
          textAlign: "center",
        },
        "& > svg": {
          width: "20%",
          alignSelf: "center",
          fontSize: "2.15rem",
          color: theme.palette.primary.dark,
        },
      },
      "& .bottom": {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "2.5px 7.5px",
        background: theme.palette.primary.main,
        "& > svg": {
          fontSize: "1.25rem",
          color: "white",
          marginRight: 3,
        },
        "& > p": {
          fontSize: "0.875rem",
          fontWeight: 500,
          color: "white",
          lineHeight: "1",
        },
      },
    },
    "& .noData": {
      fontSize: "0.925rem",
      textAlign: "center",
      padding: "15px 0",
    },
  },
  pagination: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: 15,
    "& > span": {
      wordSpacing: 5,
      marginRight: 10,
    },
  },
  dropdownFormControl: {
    marginLeft: 10,
    width: 135,
    fontSize: "14px !important",
  },
  dropdownMenuItem: {
    fontSize: "14px !important",
  },
});

const ShippingTable = ({
  columns,
  data,
  updateData,
  filterTypes,
  noDataText,
  ...props
}: any) => {
  const classes = useStyles(styles);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      updateData,
      filterTypes,
    },
    useFilters,
    useGroupBy,
    useSortBy,
    usePagination
  );

  return (
    <>
      <table
        className={
          props.className !== undefined
            ? `${classes.table} ${props.className}`
            : classes.table
        }
        {...getTableProps()}
      >
        <thead>
          <tr>
            <th colSpan={columns.length}>
              <div className="title">
                <Typography>* SHIPPING *</Typography>
                <div>
                  <Typography>Information</Typography>
                  <Typography>From &gt;&gt; To</Typography>
                </div>
              </div>
            </th>
          </tr>
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              style={{ display: "none" }}
            >
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>
                  {column.canFilter ? column.render("Filter") : null}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            const color = getCountryColor(row.cells[4].value);
            return (
              <tr {...row.getRowProps()}>
                <td
                  colSpan={columns.length}
                  className={isNotNull(color) ? color : ""}
                >
                  <div className="left">
                    <div className="top">
                      <div>
                        <Typography variant="body1">SHIPPING</Typography>
                        <Typography variant="body2">
                          {row.cells[0].value}
                        </Typography>
                      </div>
                      <DirectionsBoatIcon />
                    </div>
                    <div className="bottom">
                      <div>
                        <Typography variant="body1">Type</Typography>
                        <Typography variant="body2">
                          {row.cells[1].value}
                        </Typography>
                      </div>
                      <div>
                        <Typography variant="body1">Line</Typography>
                        <Typography variant="body2">
                          {row.cells[2].value}
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <div className="right">
                    <div className="top">
                      <div {...row.cells[3].getCellProps()}>
                        {row.cells[3].render("Cell")}
                      </div>
                      <KeyboardDoubleArrowRightIcon />
                      <div {...row.cells[4].getCellProps()}>
                        {row.cells[4].render("Cell")}
                      </div>
                    </div>
                    <div className="bottom">
                      <AccessTimeIcon />
                      <Typography>
                        Departure Date : {row.cells[5].value}
                      </Typography>
                    </div>
                  </div>
                </td>
              </tr>
            );
          })}
          {page.length === 0 && (
            <tr>
              <td colSpan={columns.length}>
                <Typography className="noData">{noDataText}</Typography>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <div className={classes.pagination}>
        {pageOptions.length === 0 ? (
          <span>0 of 1</span>
        ) : (
          <span>
            {pageIndex + 1} of {pageOptions.length}
          </span>
        )}
        <IconButton onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          <KeyboardDoubleArrowLeftIcon />
        </IconButton>
        <IconButton onClick={() => previousPage()} disabled={!canPreviousPage}>
          <NavigateBeforeIcon />
        </IconButton>
        <IconButton onClick={() => nextPage()} disabled={!canNextPage}>
          <NavigateNextIcon />
        </IconButton>
        <IconButton
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          <KeyboardDoubleArrowRightIcon />
        </IconButton>
        <Dropdown
          value={pageSize}
          options={browserViewList}
          dispatch={setPageSize}
          formControlClassName={classes.dropdownFormControl}
          menuItemClassName={classes.dropdownMenuItem}
        />
      </div>
    </>
  );
};

export default ShippingTable;
