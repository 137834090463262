// Components
import { Theme, Grid } from "@mui/material";
import { SNSIcon } from "@/common/components";
import { CompanyIcon } from "@/common/icons";
// Styles
import { useStyles } from "@/common/lib/style/hooks";

const styles = (theme: Theme) => ({
  snsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "1vh 4vw",
    borderTop: "1px solid " + theme.palette.divider,
  },
  fnb: {
    display: "flex",
    alignItems: "center",
    margin: 0,
    padding: 0,
    listStyle: "none",
    userSelect: "none",
    "& > li": {
      margin: "0 1.25vw",
      cursor: "pointer",
      fontSize: "0.75rem",
      wordSpacing: "-0.05rem",
      textAlign: "center",
      "& > a": {
        color: theme.palette.text.primary,
        textDecoration: "none",
      },
      "&:hover": {
        "& > a": {
          color: theme.palette.primary.main,
        },
      },
    },
  },
  sns: {
    display: "flex",
    alignItems: "center",
    margin: 0,
    padding: 0,
    listStyle: "none",
    "& > li": {
      margin: "0 1.25vw",
      cursor: "pointer",
    },
    "& > button": {
      margin: "0 1.25vw",
      width: "4.5vh",
      height: "4.5vh",
      minHeight: "4.5vh",
      zIndex: 1,
      "& > svg": {
        width: "3vh",
        height: "3vh",
      },
    },
  },
  imageContainer: {
    backgroundColor: theme.palette.footer.main,
    padding: "2vh 0",
    textAlign: "center",
  },
  companyIcon: {
    width: "65%",
    fill: theme.palette.footer.text,
  },
  table: {
    height: "fit-content",
    margin: "1vh auto 0",
    "& td": {
      fontSize: "0.7rem",
      fontWeight: "bold",
      color: theme.palette.footer.text,
      opacity: 0.925,
    },
    "& td, & th": {
      cursor: "default",
      userSelect: "none",
      whiteSpace: "nowrap",
    },
  },
});

const Footer = () => {
  const classes = useStyles(styles);

  return (
    <Grid container>
      <Grid item xs={12} className={classes.snsContainer}>
        <ul className={classes.fnb}>
          <li>
            <a className={classes.anchor} href="/profile">
              PROFILE
            </a>
          </li>
          <li>
            <a className={classes.anchor} href="/contact-us">
              CONTACT
              <br />
              US
            </a>
          </li>
        </ul>
        <div className={classes.sns}>
          <SNSIcon sns="whatsApp" />
          <SNSIcon sns="facebook" />
          <SNSIcon sns="youtube" />
          <SNSIcon sns="instagram" />
        </div>
      </Grid>
      <Grid item xs={12} className={classes.imageContainer}>
        <CompanyIcon className={classes.companyIcon} />
        <table className={classes.table}>
          <colgroup>
            <col style={{ width: "100%" }} />
          </colgroup>
          <thead></thead>
          <tbody>
            <tr>
              <td>CEO: Lee Jaekwang</td>
            </tr>
            <tr>
              <td>
                ADDRESS: 1F - 107, 187, Gyeongin-ro,
                <br />
                Michuhol-gu, Incheon, Republic of Korea
              </td>
            </tr>
            <tr>
              <td>TEL: 032-565-5513</td>
            </tr>
            <tr>
              <td>FAX: 032-565-5514</td>
            </tr>
            <tr>
              <td>EMAIL: autoleaderost@gmail.com</td>
            </tr>
            <tr>
              <td>
                COPYRIGHT© 2022 AUTO LEADER CO.,LTD.
                <br />
                ALL RIGHTS RESERVED.
              </td>
            </tr>
          </tbody>
        </table>
      </Grid>
    </Grid>
  );
};

export default Footer;
