/* eslint-disable no-eval */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  parsePhoneNumberFromString,
  getCountries,
  getPhoneCode,
  CountryCode,
} from "libphonenumber-js";
// Components
import {
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Theme,
  Typography,
} from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import Input from "./Input";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// Libs
import { isNull } from "@/common/lib/common";

const styles = (theme: Theme) => ({
  menuItem: {
    display: "flex",
    "& > img": {
      marginRight: 10,
    },
    "& > .MuiTypography-body1": {
      width: "100%",
      fontSize: "0.925rem",
    },
    "& > .MuiTypography-body2": {
      color: theme.palette.text.secondary,
    },
  },
});

const PhoneInput = ({ value, dispatch, InputProps, ...props }: any) => {
  const classes = useStyles(styles);

  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [displayValue, setDisplayValue] = useState<string>("");
  const [countryCode, setCountryCode] = useState<CountryCode | undefined>(
    undefined
  );
  const [countryList, setCountryList] = useState<
    {
      code: CountryCode;
      region: string | undefined;
      phone: string;
    }[]
  >([]);

  const defaultInputProps = {
    startAdornment: (
      <InputAdornment position="start">
        <IconButton
          id="languageBtn"
          aria-label="more"
          aria-controls={menuOpen ? "long-menu" : undefined}
          aria-expanded={menuOpen ? "true" : undefined}
          aria-haspopup="true"
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            setMenuOpen(true);
            setAnchorEl(event.currentTarget);
          }}
        >
          {isNull(countryCode) ? (
            <LanguageIcon />
          ) : (
            <img
              loading="lazy"
              width="20"
              src={`https://flagcdn.com/w20/${countryCode?.toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${countryCode?.toLowerCase()}.png 2x`}
              alt=""
            />
          )}
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          MenuListProps={{
            "aria-labelledby": "languageBtn",
          }}
          open={menuOpen}
          onClose={() => {
            setMenuOpen(false);
            setAnchorEl(null);
          }}
        >
          {countryList.map((country, idx) => {
            return (
              <MenuItem
                classes={{
                  root: classes.menuItem,
                }}
                key={idx}
                selected={countryCode === country.code}
                onClick={() => {
                  setCountryCode(country.code);
                  dispatch("+" + getPhoneCode(country.code));
                  setMenuOpen(false);
                  setAnchorEl(null);
                }}
              >
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png 2x`}
                  alt=""
                />
                <Typography variant="body1">{country.region}</Typography>
                <Typography variant="body2">{country.phone}</Typography>
              </MenuItem>
            );
          })}
        </Menu>
      </InputAdornment>
    ),
  };

  useEffect(() => {
    const regionNames = new Intl.DisplayNames(["en"], { type: "region" });
    const countryList: {
      code: CountryCode;
      region: string | undefined;
      phone: string;
    }[] = [];
    getCountries().forEach((countryCode) => {
      if (!["AC", "TA"].includes(countryCode)) {
        countryList.push({
          code: countryCode,
          region: regionNames.of(countryCode),
          phone: "+" + getPhoneCode(countryCode),
        });
      }
    });
    const countrySortList = countryList.sort((a, b) => {
      if (a.region !== undefined && b.region !== undefined) {
        return a.region.localeCompare(b.region);
      } else {
        return a.code.localeCompare(b.code);
      }
    });
    setCountryList(countrySortList);
  }, []);

  useEffect(() => {
    const phoneNumber = parsePhoneNumberFromString(value);
    setDisplayValue(
      phoneNumber !== undefined ? phoneNumber.format("INTERNATIONAL") : value
    );

    if (isNull(countryCode)) {
      if (phoneNumber === undefined) {
        const test = parsePhoneNumberFromString(value + "00");
        if (test !== undefined) {
          setCountryCode(test.country);
        }
      } else {
        setCountryCode(phoneNumber.country);
      }
    } else {
      if (phoneNumber === undefined) {
        const test = parsePhoneNumberFromString(value + "00");
        if (test === undefined) {
          setCountryCode(undefined);
        }
      }
    }
  }, [value]);

  return (
    <Input
      value={displayValue}
      dispatch={dispatch}
      InputProps={
        InputProps === undefined
          ? defaultInputProps
          : Object.assign(defaultInputProps, InputProps)
      }
      {...props}
    />
  );
};

export default PhoneInput;
