/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
// Redux
import { setParams } from "@/common/lib/redux/actions/searchAction";
import { useTypedDispatch } from "@/common/lib/redux/store";
// Components
import { Card, CardContent, Divider, Theme, Typography } from "@mui/material";
import { Autocomplete, Button } from "@/common/components";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// API
import { getVehicleCount } from "@/common/lib/api/vehicle";
// Libs
import { getUTCDate, isNull, isNotNull } from "@/common/lib/common";
import {
  initList,
  getMakerList,
  getModelList,
  getDetailModelList,
} from "@/common/lib/varList";

const styles = (theme: Theme) => ({
  container: {
    width: 720,
  },
  title: {
    fontSize: 26.5,
    fontStyle: "oblique",
    textAlign: "end",
    cursor: "default",
    "& > span > span": {
      fontSize: 37.5,
      fontWeight: "bold",
      color: theme.palette.primary.main,
      textShadow: "1px 1px 0px #000",
    },
  },
  card: {
    border: "4.5px solid " + theme.palette.primary.main,
    borderRadius: 4.5,
    boxShadow: "unset",
    padding: 16,
  },
  cardContent: {
    display: "flex",
    alignItems: "center",
    padding: "0px !important",
  },
  top: {
    paddingBottom: "5px !important",
    justifyContent: "space-between",
  },
  bottom: {
    justifyContent: "flex-end",
    paddingTop: "15px !important",
  },
  topDiv: {
    display: "flex",
    alignItems: "flex-end",
    cursor: "default",
    "& > p": {
      lineHeight: 1,
    },
  },
  table: {
    width: 200,
    "& td": {
      fontSize: 15,
      textAlign: "right",
      cursor: "default",
    },
    "& td.count": {
      color: theme.palette.primary.main,
      fontSize: 16,
      fontWeight: "bold",
    },
  },
  find: {
    color: theme.palette.primary.main,
    fontSize: 32,
    fontWeight: "bold",
    marginRight: 5,
  },
  mycar: {
    fontSize: 26.5,
    fontWeight: "bold",
  },
  in: {
    fontSize: 18.5,
    fontWeight: "bold",
    margin: "0 5px",
  },
  autoleader: {
    color: theme.palette.primary.main,
    fontSize: 29.5,
    fontWeight: "bold",
  },
  textField: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: 2,
      borderRadius: 0,
      borderColor: theme.palette.primary.main,
    },
    "&.left": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderLeft: "unset",
      },
    },
    "&.right": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderRight: "unset",
      },
    },
  },
  button: {
    top: -0.25,
    width: 125,
    height: 40.5,
    borderRadius: 0,
    boxShadow: "unset",
    "&:hover": {
      boxShadow: "unset",
    },
  },
});

const IndexSearchContainer = () => {
  const classes = useStyles(styles);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();

  const [totalCount, setTotalCount] = useState(0);
  const [weekCount, setWeekCount] = useState(0);

  const [maker, setMaker] = useState("");
  const [model, setModel] = useState("");
  const [detailModel, setDetailModel] = useState("");

  const [makerList, setMakerList] = useState(initList);
  const [modelList, setModelList] = useState(initList);
  const [detailModelList, setDetailModelList] = useState(initList);

  const totalCountQuery = useQuery("totalCount", () => {
    const startDate = new Date(1980, 1, 1);

    return getVehicleCount({
      criteria: JSON.stringify([
        {
          type: "SALES_ON",
          period: getUTCDate(startDate) + "~",
        },
      ]),
    });
  });
  const weekCountQuery = useQuery("weekCount", () => {
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 7);

    return getVehicleCount({
      criteria: JSON.stringify([
        {
          type: "SALES_ON",
          period: getUTCDate(startDate) + "~",
        },
      ]),
    });
  });

  const handleSearchOnClick = () => {
    const searchParams: { [key: string]: string } = {};
    if (isNotNull(maker)) {
      searchParams["maker"] = maker;
    }
    if (isNotNull(model)) {
      searchParams["model"] = model;
    }
    if (isNotNull(detailModel)) {
      searchParams["detail_model"] = detailModel;
    }
    dispatch(setParams(searchParams));
    navigate("/vehicle/list");
  };

  useEffect(() => {
    setMakerList(getMakerList(""));
  }, []);

  useEffect(() => {
    if (isNull(maker)) {
      setModel("");
      setModelList(initList);
    } else {
      setModelList(getModelList("", maker));
    }
  }, [maker]);

  useEffect(() => {
    if (isNull(maker) || isNull(model)) {
      setDetailModel("");
      setDetailModelList(initList);
    } else {
      setDetailModelList(getDetailModelList(maker, model));
    }
  }, [maker, model]);

  useEffect(() => {
    if (totalCountQuery.isSuccess) {
      setTotalCount(totalCountQuery.data.data.count["total"]);
    }
  }, [totalCountQuery.isLoading]);

  useEffect(() => {
    if (weekCountQuery.isSuccess) {
      setWeekCount(weekCountQuery.data.data.count["total"]);
    }
  }, [weekCountQuery.isLoading]);

  return (
    <div className={classes.container}>
      <Typography className={classes.title}>
        <Markup content={t("SEARCH.MSG.WELCOME", { count: totalCount })} />
      </Typography>
      <Card className={classes.card}>
        <CardContent className={`${classes.cardContent} ${classes.top}`}>
          <div className={classes.topDiv}>
            <Typography className={classes.find}>
              {t("SEARCH.INDEX_SEARCH_FIND")}
            </Typography>
            <Typography className={classes.mycar}>
              {t("SEARCH.INDEX_SEARCH_MYCAR")}
            </Typography>
            <Typography className={classes.in}>
              {t("SEARCH.INDEX_SEARCH_IN")}
            </Typography>
            <Typography className={classes.autoleader}>AUTOLEADER</Typography>
          </div>
          <table className={classes.table}>
            <colgroup>
              <col style={{ width: "55%" }} />
              <col style={{ width: "40%" }} />
              <col style={{ width: "5%" }} />
            </colgroup>
            <tbody>
              <tr>
                <td>{t("SEARCH.INDEX_SEARCH_TOTAL")}</td>
                <td className="count">{totalCount.toLocaleString()}</td>
                <td>{t("SEARCH.INDEX_SEARCH_EA")}</td>
              </tr>
              <tr>
                <td>
                  <Markup content={t("SEARCH.INDEX_SEARCH_RECENT")} />
                </td>
                <td className="count">{weekCount.toLocaleString()}</td>
                <td>{t("SEARCH.INDEX_SEARCH_EA")}</td>
              </tr>
            </tbody>
          </table>
        </CardContent>
        <Divider />
        <CardContent className={`${classes.cardContent} ${classes.bottom}`}>
          <Autocomplete
            size="large"
            label={t("VEHICLE.PRODUCT.MAKER")}
            value={maker}
            dispatch={setMaker}
            options={makerList}
            textFieldClassName={`${classes.textField} right`}
          />
          <Autocomplete
            size="large"
            label={t("VEHICLE.PRODUCT.MODEL")}
            value={model}
            dispatch={setModel}
            options={modelList}
            textFieldClassName={`${classes.textField} left right`}
          />
          <Autocomplete
            size="large"
            label={t("VEHICLE.PRODUCT.DETAIL_MODEL")}
            value={detailModel}
            dispatch={setDetailModel}
            options={detailModelList}
            textFieldClassName={`${classes.textField} left right`}
          />
          <Button
            variant="contained"
            className={classes.button}
            onClick={handleSearchOnClick}
          >
            {t("BUTTON.SEARCH")}
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default IndexSearchContainer;
