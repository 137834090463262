import { t } from "i18next";
// Redux
import { store } from "@/common/lib/redux/store";
import { setErrorText } from "@/common/lib/redux/actions/commonAction";
// API
import { isAxiosError } from "axios";

export const onError = (error: any) => {
  if (isAxiosError(error)) {
    switch (error.response?.status) {
      case 429:
        if (error.response.data === "[API] 429 - BOOKING TOO MANY REQUESTS") {
          store.dispatch(
            setErrorText({
              title: t("DIALOG.TITLE.FAIL_TO_BOOKING_REQUEST"),
              content: t("DIALOG.429.REQUEST_EXCEED"),
            })
          );
        } else if (
          error.response.data === "[API] 429 - WISH TOO MANY REQUESTS"
        ) {
          store.dispatch(
            setErrorText({
              title: t("DIALOG.TITLE.FAIL_TO_WISH"),
              content: t("DIALOG.429.REQUEST_EXCEED_WISH"),
            })
          );
        } else if (
          error.response.data === "[API] 429 - OFFER TOO MANY REQUESTS"
        ) {
          store.dispatch(
            setErrorText({
              title: t("DIALOG.TITLE.FAIL_TO_OFFER"),
              content: t("DIALOG.429.REQUEST_EXCEED"),
            })
          );
        } else {
          store.dispatch(
            setErrorText({
              title: t("DIALOG.TITLE.AUTH_SERVER_NOTICE"),
              content: t("DIALOG.429.TOO_MANY_REQUESTS"),
            })
          );
        }
        break;
      case 404:
        if (error.response.data === "[AUTH] 404 - LOGIN FAILED") {
          store.dispatch(
            setErrorText({
              title: t("DIALOG.TITLE.AUTH_SERVER_NOTICE"),
              content: t("DIALOG.404.FAIL_TO_LOGIN"),
            })
          );
        } else if (error.response.data === "[AUTH] 404 - LOGOUT FAILED") {
          store.dispatch(
            setErrorText({
              title: t("DIALOG.TITLE.AUTH_SERVER_NOTICE"),
              content: t("DIALOG.404.FAIL_TO_LOGOUT"),
            })
          );
        } else if (error.response.data === "[AUTH] 404 - TOKEN NOT FOUND") {
          store.dispatch(
            setErrorText({
              title: t("DIALOG.TITLE.AUTH_SERVER_NOTICE"),
              content: t("DIALOG.404.FAIL_TO_GET_TOKEN"),
            })
          );
        } else if (error.response.data === "[AUTH] 404 - USER NOT FOUND") {
          store.dispatch(
            setErrorText({
              title: t("DIALOG.TITLE.AUTH_SERVER_NOTICE"),
              content: t("DIALOG.404.NOT_FOUND_USER"),
            })
          );
        } else {
          store.dispatch(
            setErrorText({
              title: t("DIALOG.TITLE.AUTH_SERVER_NOTICE"),
              content: t("DIALOG.404.NOT_FOUND"),
            })
          );
        }
        break;
      case 403:
        if (error.response.data === "[AUTH] 403 - LOGIN FORBIDDEN") {
          store.dispatch(
            setErrorText({
              title: t("DIALOG.TITLE.AUTH_SERVER_NOTICE"),
              content: t("DIALOG.403.FORBIDDEN_USER"),
            })
          );
        } else if (error.response.data === "[AUTH] 403 - INCORRECT PASSWORD") {
          store.dispatch(
            setErrorText({
              title: t("DIALOG.TITLE.AUTH_SERVER_NOTICE"),
              content: t("DIALOG.403.INCORRECT_PASSWORD"),
            })
          );
        } else {
          store.dispatch(
            setErrorText({
              title: t("DIALOG.TITLE.AUTH_SERVER_NOTICE"),
              content: t("DIALOG.403.FORBIDDEN"),
            })
          );
        }
        break;
      case 401:
        if (error.response.data === "[AUTH] 401 - LOGIN UNAUTHORIZE") {
          store.dispatch(
            setErrorText({
              title: t("DIALOG.TITLE.AUTH_SERVER_NOTICE"),
              content: t("DIALOG.401.CHECK_ID_PASSWORD"),
            })
          );
        } else if (error.response.data === "[AUTH] 401 - TOKEN INVALID") {
          store.dispatch(
            setErrorText({
              title: t("DIALOG.TITLE.AUTH_SERVER_NOTICE"),
              content: t("DIALOG.401.EXPIRED_LOGIN"),
            })
          );
        } else if (error.response.data === "[AUTH] 401 - PASSWORD INVALID") {
          store.dispatch(
            setErrorText({
              title: t("DIALOG.TITLE.AUTH_SERVER_NOTICE"),
              content: t("DIALOG.401.INVALID_PASSWORD"),
            })
          );
        } else {
          store.dispatch(
            setErrorText({
              title: t("DIALOG.TITLE.AUTH_SERVER_NOTICE"),
              content: t("DIALOG.401.LOGIN_ONLY_SERVICE"),
            })
          );
        }
        break;
      case 400:
        if (error.response.data === "[AUTH] 400 - TOKEN INFO") {
          store.dispatch(
            setErrorText({
              title: t("DIALOG.TITLE.AUTH_SERVER_NOTICE"),
              content: t("DIALOG.400.BAD_REQUEST"),
            })
          );
        } else if (
          error.response.data === "[AUTH] 400 - ALREADY EXIST PASSWORD"
        ) {
          store.dispatch(
            setErrorText({
              title: t("DIALOG.TITLE.AUTH_SERVER_NOTICE"),
              content: t("DIALOG.400.ALREADY_EXIST_PASSWORD"),
            })
          );
        } else if (
          error.response.data === "[API] 400 - ALREADY REQUEST BOOKING"
        ) {
          store.dispatch(
            setErrorText({
              title: t("DIALOG.TITLE.FAIL_TO_BOOKING_REQUEST"),
              content: t("DIALOG.400.ALREADY_REQUEST_BOOKING"),
            })
          );
        } else {
          store.dispatch(
            setErrorText({
              title: t("DIALOG.TITLE.AUTH_SERVER_NOTICE"),
              content: t("DIALOG.400.BAD_REQUEST"),
            })
          );
        }
        break;
    }
  } else {
    console.log(error);
  }
};
