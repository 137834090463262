/* eslint-disable react-hooks/exhaustive-deps */
import {
  Dispatch,
  SetStateAction,
  forwardRef,
  useEffect,
  useState,
} from "react";
import Slider from "react-slick";
import { useQuery, useQueries } from "react-query";
import { Markup } from "interweave";
// Components
import { Divider, Theme, Typography, useTheme } from "@mui/material";
import {
  CarouselListItem,
  CarouselListItemSkeleton,
} from "@/mobile/components/index";
// Styles
import { useStyles, useWindowSize } from "@/common/lib/style/hooks";
// API
import { getVehicleList } from "@/common/lib/api/vehicle";
// Libs
import { isNotNull } from "@/common/lib/common";

const styles = (theme: Theme) => ({
  title: {
    fontSize: "1.25rem",
    fontWeight: "bold",
    lineHeight: "1.25",
    cursor: "default",
    padding: "0 4vw",
    "& > span > span": {
      fontSize: "1.7rem",
      color: theme.palette.primary.main,
    },
    "&.left": {
      textAlign: "left",
    },
    "&.right": {
      textAlign: "right",
    },
    marginBottom: "1vh",
  },
  divider: {
    marginBottom: "1vh",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "1vh",
  },
  carousel: {
    width: "100%",
    "& .slick-slide": {
      "& > div": {
        display: "flex",
        margin: "0.25rem 0",
        "& > div": {
          margin: "0 0.25rem",
        },
      },
    },
  },
});

interface propType {
  position?: string;
  title?: string;
  defaults?: boolean;
  rows?: number;
  data: CarouselDataType[];
  setData?: Dispatch<SetStateAction<VehicleData[]>>;
}

const ProductCarousel = forwardRef<Slider, propType>(
  (
    {
      position = "left",
      title,
      defaults = false,
      rows = 1,
      data = [],
      setData,
    },
    ref
  ) => {
    const theme = useTheme();
    const classes = useStyles(styles);
    const windowSize = useWindowSize();

    const [itemList, setItemList] = useState<VehicleData[]>([]);
    const [loading, setLoading] = useState(true);

    const defaultQuery = useQuery(
      "defaultProductCarousel",
      () => {
        return getVehicleList(1, 12, "-modified", { status: "ON" });
      },
      {
        enabled: false,
        retry: false,
        onSuccess: (response) => {
          setItemList(response.data.results);
        },
        onSettled: () => {
          setLoading(false);
        },
      }
    );
    const queries = useQueries(
      data.map((query) => {
        return {
          queryKey: query.key,
          queryFn: () =>
            getVehicleList(query.page, query.size, query.align, query.params),
          enabled: false,
          retry: false,
        };
      })
    );
    const settings = {
      dots: false,
      arrows: false,
      infinite: itemList.length > 3 ? true : false,
      draggable: false,
      swipe: true,
      swipeToSlide: true,
      rows: rows,
      slidesPerRow: 1,
      slidesToShow: 3,
      centerMode: true,
      responsive: [
        {
          breakpoint: theme.breakpoints.values.md,
          settings: {
            infinite: itemList.length > 2 ? true : false,
            slidesToShow: 2,
          },
        },
        {
          breakpoint: theme.breakpoints.values.sm,
          settings: {
            infinite: itemList.length > 1 ? true : false,
            slidesToShow: 1,
          },
        },
      ],
    };

    useEffect(() => {
      queries.forEach((query) => query.refetch());
    }, [data]);

    useEffect(() => {
      if (queries.every((result) => result.isSuccess === true)) {
        let maxSize = 0;
        for (let idx = 0; idx < data.length; idx++) {
          if (maxSize < data[idx].size) {
            maxSize = data[idx].size;
          }
        }
        setItemList([]);
        if (setData !== undefined) {
          setData([]);
        }
        for (let idx = 0; idx < maxSize; idx++) {
          for (let jdx = 0; jdx < queries.length; jdx++) {
            if (
              queries[jdx].isSuccess &&
              isNotNull(queries[jdx].data?.data.results[idx])
            ) {
              setItemList((prev) => [
                ...prev,
                queries[jdx].data?.data.results[idx],
              ]);
              if (setData !== undefined) {
                setData((prev) => [
                  ...prev,
                  queries[jdx].data?.data.results[idx],
                ]);
              }
            }
          }
        }
      }
    }, [queries.some((result) => result.isLoading)]);

    useEffect(() => {
      if (queries.every((result) => result.isSuccess === true)) {
        let maxSize = 0;
        for (let idx = 0; idx < data.length; idx++) {
          if (maxSize < data[idx].size) {
            maxSize = data[idx].size;
          }
        }
        setItemList([]);
        if (setData !== undefined) {
          setData([]);
        }
        for (let idx = 0; idx < maxSize; idx++) {
          for (let jdx = 0; jdx < queries.length; jdx++) {
            if (
              queries[jdx].isSuccess &&
              isNotNull(queries[jdx].data?.data.results[idx])
            ) {
              setItemList((prev) => [
                ...prev,
                queries[jdx].data?.data.results[idx],
              ]);
              if (setData !== undefined) {
                setData((prev) => [
                  ...prev,
                  queries[jdx].data?.data.results[idx],
                ]);
              }
            }
          }
        }
      }
    }, [queries.some((result) => result.isRefetching)]);

    useEffect(() => {
      if (queries.every((result) => result.isFetching === true)) {
        if (defaults) {
          defaultQuery.refetch();
        } else {
          setLoading(false);
        }
      }
    }, [queries.some((result) => result.isFetching)]);

    return (
      <>
        {(loading || itemList.length > 0) && (
          <>
            {isNotNull(title) && (
              <>
                <Divider className={classes.divider} />
                <Typography className={`${classes.title} ${position}`}>
                  <Markup content={title} />
                </Typography>
                <Divider className={classes.divider} />
              </>
            )}
            <div className={classes.container}>
              <Slider ref={ref} {...settings} className={classes.carousel}>
                {loading
                  ? [...Array(32).keys()].map((idx) => {
                      return (
                        <CarouselListItemSkeleton
                          key={"skeleton" + idx}
                          slidesToShow={
                            windowSize.width >= theme.breakpoints.values.md
                              ? 3
                              : windowSize.width >= theme.breakpoints.values.sm
                              ? 2
                              : 1
                          }
                        />
                      );
                    })
                  : itemList.map((item, idx) => {
                      return (
                        <CarouselListItem
                          key={idx}
                          data={item}
                          slidesToShow={
                            windowSize.width >= theme.breakpoints.values.md
                              ? 3
                              : windowSize.width >= theme.breakpoints.values.sm
                              ? 2
                              : 1
                          }
                        />
                      );
                    })}
              </Slider>
            </div>
          </>
        )}
      </>
    );
  }
);

export default ProductCarousel;
