// Common Header, Footer
import { Footer, Header } from "@/mobile/components/common";
// Styles
import { useStyles } from "@/common/lib/style/hooks";

const styles = () => ({
  main: {
    width: "100%",
    height: "auto",
  },
});

const BasePage = ({ isHeader, isFooter, ...props }: { [key: string]: any }) => {
  const classes = useStyles(styles);

  return (
    <>
      {isHeader && <Header />}
      <main
        className={
          props.className !== undefined
            ? `${classes.main} ${props.className}`
            : `${classes.main}`
        }
      >
        {props.children}
      </main>
      {isFooter && <Footer />}
    </>
  );
};

BasePage.defaultProps = {
  isHeader: true,
  isFooter: true,
};

export default BasePage;
