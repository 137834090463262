/* eslint-disable no-restricted-globals */
import React, { useEffect, useMemo, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
import { useSnackbar } from "notistack";
// Redux
import { setLoginToken } from "@/common/lib/redux/actions/authAction";
import { loadingOn, loadingOff } from "@/common/lib/redux/actions/commonAction";
import { setParams } from "@/common/lib/redux/actions/searchAction";
import { initWishList } from "@/common/lib/redux/actions/globalAction";
import { useTypedDispatch, useTypedSelector } from "@/common/lib/redux/store";
// Components
import {
  AppBar,
  Collapse,
  Drawer,
  Grid,
  IconButton,
  Modal,
  List,
  ListItemButton,
  ListItemText,
  Toolbar,
  Theme,
  Typography,
  useScrollTrigger,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CloseIcon from "@mui/icons-material/Close";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import { Button, Dropdown, Image } from "@/common/components";
import { CompanyLogoIcon } from "@/common/icons";
import LoginPage from "@/mobile/pages/auth/LoginPage";
import SearchPage from "@/mobile/pages/common/SearchPage";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// API
import { onError } from "@/common/lib/api/common";
import { logoutAPI } from "@/common/lib/api/auth";
// Libs
import i18n from "@/common/lib/lang/i18n";
import { checkLogin, getTokenData, isNull } from "@/common/lib/common";

const styles = (theme: Theme) => ({
  appbar: {
    background: theme.palette.background.default,
  },
  toolbar: {
    padding: "1.5vh 4vw",
  },
  gridItem: {
    display: "flex",
    alignItems: "center",
    "&.left": {
      paddingLeft: "2vw",
      justifyContent: "flex-start",
    },
    "&.center": {
      justifyContent: "center",
    },
    "&.right": {
      paddingRight: "2vw",
      justifyContent: "flex-end",
    },
  },
  close: {
    alignSelf: "end",
    color: "white",
    "& > svg": {
      fontSize: "1.875rem",
    },
  },
  menu: {
    color: theme.palette.logo.darkBlue,
    "& > svg": {
      fontSize: "1.85rem",
    },
  },
  companyLogoIcon: {
    width: "37.5vw",
    fill: theme.palette.logo.darkBlue,
    cursor: "pointer",
    margin: "0 auto",
  },
  dropdownFormControl: {
    width: "5em",
    fontSize: "0.875rem !important",
  },
  dropdownSelect: {
    height: "2.15em !important",
    letterSpacing: "-0.05rem",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.hard + "!important",
    },
    "& .MuiSelect-select": {
      color: theme.palette.primary.hard,
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.primary.hard + "!important",
    },
  },
  dropdownMenuItem: {
    fontSize: "0.875rem",
    letterSpacing: "-0.05rem",
  },
  menuPaper: {
    width: "66%",
    padding: "1vh",
    background: theme.palette.primary.hard,
  },
  searchPaper: {
    width: "100%",
    padding: "2vh 4vw",
  },
  userIcon: {
    alignSelf: "center",
    fontSize: "7rem",
    color: "white",
    marginBottom: "1vh",
    "& > span.puid": {
      "&:hover": {
        cursor: "copy",
        textDecoration: "underline",
      },
    },
  },
  userImage: {
    alignSelf: "center",
    width: "7rem",
    aspectRatio: "1",
    borderRadius: "50%",
    objectFit: "cover",
    marginBottom: "1vh",
  },
  userInfo: {
    color: "white",
    fontSize: "1.15rem",
    textAlign: "center",
    marginBottom: "2vh",
  },
  searchButton: {
    alignSelf: "center",
    width: "85%",
    color: "white",
    border: "2.5px solid white",
    padding: "0.5vh",
    marginBottom: "2vh",
  },
  infoDiv: {
    display: "flex",
    padding: "0.5rem",
    borderBottom: "2.5px solid white",
    marginBottom: "1.5vh",
    "& > p": {
      width: "50%",
      color: "white",
      textAlign: "center",
    },
  },
  listButton: {
    flexGrow: 0,
    padding: "1.5vh 8vw",
    "& > div > p": {
      color: "white",
    },
  },
  subListButton: {
    padding: "0.5vh 10vw",
    "& > div > p": {
      color: "white",
    },
  },
  collapse: {
    minHeight: "unset !important",
  },
  expandIcon: {
    color: "white",
    fontSize: "1.85rem !important",
    cursor: "pointer",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const ElevationScroll = (props: any) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: props.threshold,
    target: window,
  });

  return React.cloneElement(props.children, { elevation: trigger ? 2 : 0 });
};

const Header = () => {
  const classes = useStyles(styles);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();
  const { loginToken } = useTypedSelector((state) => state.auth);
  const { init } = useTypedSelector((state) => state.common);
  const { enqueueSnackbar } = useSnackbar();

  const [menuOpen, setMenuOpen] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [aboutUsOpen, setAboutUsOpen] = useState(false);
  const [productsListOpen, setProductsListOpen] = useState(false);
  const [myListOpen, setMyListOpen] = useState(false);
  const [offerOpen, setOfferOpen] = useState(false);
  const [helpOpen, setHelpOpen] = useState(false);
  const [tokenData, setTokenData] = useState<TokenData>({
    id: null,
    puid: undefined,
    guid: undefined,
    name: undefined,
    username: undefined,
    group: null,
    image: undefined,
    is_staff: false,
    is_manager: false,
    is_superuser: false,
  });

  const langList = useMemo(() => {
    const i18nLangList: Option[] = [];
    if (i18n.options.resources) {
      for (const [key, value] of Object.entries(i18n.options.resources)) {
        i18nLangList.push({ label: String(value.label), value: key });
      }
    }
    return i18nLangList;
  }, []);

  const logoutMutation = useMutation(logoutAPI, {
    onSuccess: () => {
      dispatch(setLoginToken(""));
      dispatch(initWishList());
      navigate("/");
    },
    onError: (error) => {
      onError(error);
    },
    onSettled: () => {
      dispatch(loadingOff());
    },
  });

  const handleImageOnClick = () => {
    navigate("/");
  };
  const handleMyInfoOnClick = () => {
    navigate("/myinfo");
  };
  const handleSearchOnClick = () => {
    if (window.location.pathname !== "/vehicle/list") {
      dispatch(setParams({}));
    }
    setMenuOpen(false);
    setSearchOpen(true);
  };
  const handleLoginOnClick = () => {
    setLoginOpen(true);
  };
  const handleLogoutOnClick = () => {
    dispatch(loadingOn());
    logoutMutation.mutate();
  };
  const handleJoinOnClick = () => {
    navigate("/join");
  };
  const handleListOnClick = (type: string) => {
    if (isNull(type)) {
      dispatch(setParams({}));
    } else {
      dispatch(
        setParams({
          type: type,
        })
      );
    }
    if (window.location.pathname !== "/vehicle/list") {
      navigate("/vehicle/list");
    } else {
      setMenuOpen(false);
    }
  };
  const handleCopyOnClick = (e: any) => {
    navigator.clipboard.writeText(e.target.textContent).then(
      () => {
        enqueueSnackbar(t("MSG.COPY_SUCCESS"), { variant: "success" });
      },
      () => {
        enqueueSnackbar(t("MSG.COPY_FAILURE"), { variant: "error" });
      }
    );
  };

  useEffect(() => {
    if (init) {
      setTokenData(getTokenData());
    }
  }, [init, loginToken]);

  useEffect(() => {
    if (!menuOpen) {
      setAboutUsOpen(false);
      setProductsListOpen(false);
      setMyListOpen(false);
      setOfferOpen(false);
      setHelpOpen(false);
    }
  }, [menuOpen]);

  return (
    <>
      <ElevationScroll threshold={0}>
        <AppBar className={classes.appbar}>
          <Toolbar className={classes.toolbar}>
            <Grid container>
              <Grid item xs={3} className={`${classes.gridItem} left`}>
                <IconButton
                  className={classes.menu}
                  onClick={() => setMenuOpen(true)}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
              <Grid item xs={6} className={`${classes.gridItem} center`}>
                <CompanyLogoIcon
                  className={classes.companyLogoIcon}
                  onClick={handleImageOnClick}
                />
              </Grid>
              <Grid item xs={3} className={`${classes.gridItem} right`}>
                <Dropdown
                  value={i18n.language}
                  options={langList}
                  dispatch={i18n.changeLanguage}
                  formControlClassName={classes.dropdownFormControl}
                  selectClassName={classes.dropdownSelect}
                  menuItemClassName={classes.dropdownMenuItem}
                />
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      {/* 화면 위 Margin 을 위한 Toolbar */}
      <Toolbar className={classes.toolbar}>
        <Grid container>
          <Grid item xs={12} className={`${classes.gridItem} center`}>
            <CompanyLogoIcon
              className={classes.companyLogoIcon}
              onClick={handleImageOnClick}
            />
          </Grid>
        </Grid>
      </Toolbar>
      <Drawer
        classes={{ paper: classes.menuPaper }}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
      >
        <IconButton
          className={classes.close}
          onClick={() => setMenuOpen(false)}
        >
          <CloseIcon />
        </IconButton>
        {isNull(tokenData.image) ? (
          <AccountCircleIcon className={classes.userIcon} />
        ) : (
          <Image alt="" src={tokenData.image} className={classes.userImage} />
        )}
        <Typography classes={{ root: classes.userInfo }}>
          {checkLogin() ? (
            <>
              <Markup
                content={t("HEADER.WELCOME_MOBILE", {
                  user: tokenData["name"],
                })}
              />
              (
              <span className="puid" onClick={handleCopyOnClick}>
                {tokenData["puid"]}
              </span>
              )
            </>
          ) : (
            <Markup content={t("MSG.WELCOME_AUTOLEADER")} />
          )}
        </Typography>
        <Button
          variant="outlined"
          className={classes.searchButton}
          onClick={handleSearchOnClick}
        >
          <SearchIcon className={classes.searchIcon} />
          {t("BUTTON.SEARCH")}
        </Button>
        <div className={classes.infoDiv}>
          {checkLogin() ? (
            <>
              <Typography onClick={handleMyInfoOnClick}>
                {t("HEADER.MYINFO")}
              </Typography>
              <Typography onClick={handleLogoutOnClick}>
                {t("HEADER.LOGOUT")}
              </Typography>
            </>
          ) : (
            <>
              <Typography onClick={handleLoginOnClick}>
                {t("HEADER.LOGIN")}
              </Typography>
              <Typography onClick={handleJoinOnClick}>
                {t("HEADER.JOIN")}
              </Typography>
            </>
          )}
        </div>
        <ListItemButton
          classes={{
            root: classes.listButton,
          }}
          onClick={() => setAboutUsOpen(!aboutUsOpen)}
          dense
        >
          <ListItemText>
            <Typography>ABOUT US</Typography>
          </ListItemText>
          {aboutUsOpen ? (
            <ExpandLessIcon classes={{ root: classes.expandIcon }} />
          ) : (
            <ExpandMoreIcon classes={{ root: classes.expandIcon }} />
          )}
        </ListItemButton>
        <Collapse
          className={classes.collapse}
          in={aboutUsOpen}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            <ListItemButton
              classes={{
                root: classes.subListButton,
              }}
              onClick={() => navigate("/profile")}
            >
              <ListItemText>
                <Typography>PROFILE</Typography>
              </ListItemText>
            </ListItemButton>
            <ListItemButton
              classes={{
                root: classes.subListButton,
              }}
            >
              <ListItemText>
                <Typography>HOW TO BUY</Typography>
              </ListItemText>
            </ListItemButton>
          </List>
        </Collapse>
        <ListItemButton
          classes={{
            root: classes.listButton,
          }}
          onClick={() => setProductsListOpen(!productsListOpen)}
          dense
        >
          <ListItemText>
            <Typography>PRODUCTS LIST</Typography>
          </ListItemText>
          {productsListOpen ? (
            <ExpandLessIcon classes={{ root: classes.expandIcon }} />
          ) : (
            <ExpandMoreIcon classes={{ root: classes.expandIcon }} />
          )}
        </ListItemButton>
        <Collapse
          className={classes.collapse}
          in={productsListOpen}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            <ListItemButton
              classes={{
                root: classes.subListButton,
              }}
              onClick={() => handleListOnClick("")}
            >
              <ListItemText>
                <Typography>ALL</Typography>
              </ListItemText>
            </ListItemButton>
            <ListItemButton
              classes={{
                root: classes.subListButton,
              }}
              onClick={() => handleListOnClick("CAR")}
            >
              <ListItemText>
                <Typography>CAR</Typography>
              </ListItemText>
            </ListItemButton>
            <ListItemButton
              classes={{
                root: classes.subListButton,
              }}
              onClick={() => handleListOnClick("TRUCK")}
            >
              <ListItemText>
                <Typography>TRUCK</Typography>
              </ListItemText>
            </ListItemButton>
            <ListItemButton
              classes={{
                root: classes.subListButton,
              }}
              onClick={() => handleListOnClick("BUS")}
            >
              <ListItemText>
                <Typography>BUS</Typography>
              </ListItemText>
            </ListItemButton>
          </List>
        </Collapse>
        <ListItemButton
          classes={{
            root: classes.listButton,
          }}
          onClick={() => setMyListOpen(!myListOpen)}
          dense
        >
          <ListItemText>
            <Typography>MY LIST</Typography>
          </ListItemText>
          {myListOpen ? (
            <ExpandLessIcon classes={{ root: classes.expandIcon }} />
          ) : (
            <ExpandMoreIcon classes={{ root: classes.expandIcon }} />
          )}
        </ListItemButton>
        <Collapse
          className={classes.collapse}
          in={myListOpen}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            <ListItemButton
              classes={{
                root: classes.subListButton,
              }}
              onClick={() => navigate("/wishlist")}
            >
              <ListItemText>
                <Typography>WISH LIST</Typography>
              </ListItemText>
            </ListItemButton>
            <ListItemButton
              classes={{
                root: classes.subListButton,
              }}
              onClick={() => navigate("/booking")}
            >
              <ListItemText>
                <Typography>BOOKING</Typography>
              </ListItemText>
            </ListItemButton>
            <ListItemButton
              classes={{
                root: classes.subListButton,
              }}
              onClick={() => navigate("/purchase-history")}
            >
              <ListItemText>
                <Typography>PURCHASE HISTORY</Typography>
              </ListItemText>
            </ListItemButton>
          </List>
        </Collapse>
        <ListItemButton
          classes={{
            root: classes.listButton,
          }}
          onClick={() => setOfferOpen(!offerOpen)}
          dense
        >
          <ListItemText>
            <Typography>OFFER</Typography>
          </ListItemText>
          {offerOpen ? (
            <ExpandLessIcon classes={{ root: classes.expandIcon }} />
          ) : (
            <ExpandMoreIcon classes={{ root: classes.expandIcon }} />
          )}
        </ListItemButton>
        <Collapse
          className={classes.collapse}
          in={offerOpen}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            <ListItemButton
              classes={{
                root: classes.subListButton,
              }}
              onClick={() => navigate("/offer")}
            >
              <ListItemText>
                <Typography>OFFER R.</Typography>
              </ListItemText>
            </ListItemButton>
            <ListItemButton
              classes={{
                root: classes.subListButton,
              }}
              onClick={() => navigate("/offerlist")}
            >
              <ListItemText>
                <Typography>OFFER LIST</Typography>
              </ListItemText>
            </ListItemButton>
          </List>
        </Collapse>
        <ListItemButton
          classes={{
            root: classes.listButton,
          }}
          onClick={() => setHelpOpen(!helpOpen)}
          dense
        >
          <ListItemText>
            <Typography>HELP & SUPPORT</Typography>
          </ListItemText>
          {helpOpen ? (
            <ExpandLessIcon classes={{ root: classes.expandIcon }} />
          ) : (
            <ExpandMoreIcon classes={{ root: classes.expandIcon }} />
          )}
        </ListItemButton>
        <Collapse
          className={classes.collapse}
          in={helpOpen}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            <ListItemButton
              classes={{
                root: classes.subListButton,
              }}
            >
              <ListItemText>
                <Typography>NOTICE</Typography>
              </ListItemText>
            </ListItemButton>
            <ListItemButton
              classes={{
                root: classes.subListButton,
              }}
            >
              <ListItemText>
                <Typography>FAQ</Typography>
              </ListItemText>
            </ListItemButton>
            <ListItemButton
              classes={{
                root: classes.subListButton,
              }}
              onClick={() => navigate("/shipping")}
            >
              <ListItemText>
                <Typography>SHIPPING</Typography>
              </ListItemText>
            </ListItemButton>
          </List>
        </Collapse>
      </Drawer>
      <Drawer
        classes={{ paper: classes.searchPaper }}
        open={searchOpen}
        onClose={() => setSearchOpen(false)}
      >
        <SearchPage setOpen={setSearchOpen} />
      </Drawer>
      <Modal
        classes={{ root: classes.modal }}
        open={loginOpen}
        onClose={() => setLoginOpen(false)}
      >
        <div>
          <LoginPage onClose={() => setLoginOpen(false)} />
        </div>
      </Modal>
    </>
  );
};

export default Header;
