import { useNavigate } from "react-router-dom";
// 컴포넌트
import { Box, Paper, Theme, Typography } from "@mui/material";
import { Button, SNSIcon } from "@/common/components";
import { CompanyLogoIcon } from "@/common/icons";
import BrowserNotSupportedIcon from "@mui/icons-material/BrowserNotSupported";
// 스타일 관련
import { useStyles } from "@/common/lib/style/hooks";

const styles = (theme: Theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: 500,
    padding: 24,
    borderRadius: 10,
    userSelect: "none",
  },
  topContainer: {
    width: 400,
    display: "flex",
    borderTop: "2px solid " + theme.palette.logo.blue,
    borderBottom: "2px solid " + theme.palette.logo.blue,
    margin: "5px 0",
    padding: "10px 0",
  },
  bottomContainer: {
    width: 400,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "10px 0",
  },
  logo: {
    width: 300,
    fill: theme.palette.logo.blue,
    marginBottom: 7.5,
  },
  icon: {
    width: 65,
    height: "auto",
    color: theme.palette.logo.blue,
  },
  content: {
    paddingLeft: 24,
  },
  title: {
    color: theme.palette.logo.blue,
    fontSize: "1.75rem",
    fontWeight: "bold",
    cursor: "default",
  },
  snsIcon: {
    margin: "0 10px",
  },
});

const NotFoundPage = () => {
  const classes = useStyles(styles);
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate("/");
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      minWidth="100vw"
      minHeight="100vh"
    >
      <Paper classes={{ root: classes.paper }} square={true}>
        <CompanyLogoIcon className={classes.logo} />
        <div className={classes.topContainer}>
          <BrowserNotSupportedIcon classes={{ root: classes.icon }} />
          <div className={classes.content}>
            <Typography className={classes.title}>404 NOT FOUND</Typography>
            <Typography variant="body1">
              The requested URL
              <br />
              was not found on this server.
            </Typography>
          </div>
        </div>
        <div className={classes.bottomContainer}>
          <div>
            <SNSIcon sns="whatsApp" />
            <SNSIcon sns="facebook" className={classes.snsIcon} />
            <SNSIcon sns="youtube" />
          </div>
          <Button variant="outlined" color="primary" onClick={handleOnClick}>
            &lt;&lt; Index page
          </Button>
        </div>
      </Paper>
    </Box>
  );
};

export default NotFoundPage;
