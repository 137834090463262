import { useTranslation } from "react-i18next";
// Components
import { Typography } from "@mui/material";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
import { colors } from "@/common/lib/style/palette";
// Libs
import { isNotNull } from "@/common/lib/common";

const styles = () => ({
  status: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 105,
    height: 35,
    borderRadius: 5,
    color: "white",
    boxSizing: "border-box",
    "&.WAIT": {
      backgroundColor: colors.gray[8],
    },
    "&.ON": {
      backgroundColor: colors.blue[7],
    },
    "&.REZ": {
      backgroundColor: colors.cyan[6],
    },
    "&.DONE": {
      backgroundColor: colors.teal[7],
    },
    "&.NOT": {
      backgroundColor: colors.red[7],
    },
  },
});

export const Status = ({ status, ...props }: any) => {
  const classes = useStyles(styles);
  const { t } = useTranslation();

  return (
    <Typography
      variant="caption"
      className={
        isNotNull(props.className)
          ? `${props.className} ${classes.status} ${status}`
          : `${classes.status} ${status}`
      }
    >
      {t("VEHICLE.STATUS." + status)}
    </Typography>
  );
};

export default Status;
