const AirconIcon = ({ ...props }: any) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <g transform="translate(0, 512) scale(0.1,-0.1)">
        <path
          d="M1110 4854 c-293 -27 -628 -109 -913 -223 -124 -50 -172 -77 -185
-106 -8 -18 -12 -105 -12 -286 0 -282 4 -304 55 -327 42 -19 59 -15 183 47
218 109 450 185 697 228 157 27 508 24 680 -6 217 -38 327 -81 707 -274 254
-129 375 -184 468 -215 433 -142 972 -134 1429 23 41 14 80 25 87 25 6 0 40
-50 75 -111 79 -136 116 -162 187 -125 36 19 552 968 552 1016 0 46 -45 87
-101 94 -24 3 -275 10 -556 16 -560 12 -555 12 -581 -45 -20 -44 -14 -64 49
-177 34 -60 59 -111 57 -114 -3 -2 -50 -13 -104 -24 -203 -41 -536 -36 -766
10 -142 29 -244 69 -418 165 -462 254 -676 337 -999 385 -131 20 -480 34 -591
24z m545 -219 c303 -46 551 -140 924 -353 203 -115 339 -168 521 -202 349 -66
745 -48 1024 46 130 43 146 96 70 226 -26 43 -45 80 -43 81 6 4 693 -13 697
-17 3 -4 -315 -596 -328 -609 -4 -5 -24 21 -44 57 -42 74 -77 106 -117 106
-16 0 -62 -13 -103 -30 -441 -175 -990 -196 -1410 -56 -68 23 -236 101 -459
214 -374 189 -466 225 -673 267 -461 94 -955 38 -1424 -159 -46 -20 -86 -36
-87 -36 -2 0 -3 56 -3 124 l0 124 133 50 c259 98 509 157 782 186 87 9 434 -3
540 -19z"
        />
        <path
          d="M890 3939 c-234 -36 -485 -177 -638 -359 -300 -357 -333 -860 -82
-1248 266 -411 774 -582 1236 -417 221 79 423 243 537 437 21 35 41 69 46 77
7 11 82 -22 357 -157 371 -181 482 -224 679 -261 375 -71 761 -49 1131 65 66
20 128 39 140 41 17 4 31 -13 85 -107 79 -137 116 -163 187 -126 36 19 552
968 552 1016 0 46 -45 87 -101 94 -24 3 -275 10 -556 16 -560 12 -555 12 -581
-45 -20 -44 -14 -64 49 -177 34 -60 59 -111 57 -114 -3 -2 -52 -14 -109 -26
-90 -19 -135 -22 -344 -21 -365 0 -521 34 -778 169 -212 111 -470 236 -579
280 l-97 39 -26 84 c-37 117 -66 179 -130 277 -140 216 -368 377 -625 443 -99
26 -307 36 -410 20z m393 -223 c287 -85 495 -292 584 -583 23 -78 26 -104 26
-233 0 -129 -3 -155 -26 -233 -89 -291 -293 -495 -584 -584 -78 -23 -104 -26
-233 -26 -129 0 -155 3 -233 26 -291 89 -495 293 -584 584 -23 78 -26 104 -26
233 0 129 3 155 26 233 99 324 350 546 684 607 88 16 269 4 366 -24z m1062
-936 c121 -60 275 -139 343 -175 190 -100 381 -153 627 -174 302 -27 560 -4
792 70 149 48 167 95 87 231 -26 43 -45 80 -43 81 6 4 693 -13 697 -17 3 -4
-315 -596 -328 -609 -4 -5 -24 21 -44 57 -42 74 -77 106 -117 106 -16 0 -62
-13 -103 -30 -440 -174 -990 -196 -1409 -56 -57 19 -229 96 -383 173 -153 77
-305 150 -337 164 -55 23 -58 26 -53 54 2 17 10 76 17 133 8 56 18 102 24 102
6 0 109 -49 230 -110z"
        />
        <path
          d="M999 3535 c-15 -8 -31 -22 -35 -32 -5 -10 -11 -108 -14 -219 l-5
-201 -165 104 c-186 116 -208 122 -262 74 -37 -33 -43 -81 -16 -122 8 -13 90
-71 182 -128 91 -56 166 -107 166 -111 0 -4 -75 -55 -166 -111 -92 -57 -174
-115 -182 -128 -27 -41 -21 -89 16 -122 54 -48 76 -42 262 74 l165 104 5 -201
c3 -111 9 -209 14 -219 10 -21 57 -47 86 -47 29 0 76 26 86 47 5 10 11 108 14
219 l5 201 165 -103 c186 -117 208 -123 262 -75 37 33 43 81 16 122 -8 14 -90
71 -182 129 -91 57 -166 106 -166 110 0 4 75 53 166 110 92 58 174 115 182
129 27 41 21 89 -16 122 -54 48 -76 42 -262 -75 l-165 -103 -5 201 c-3 111 -9
209 -14 219 -11 22 -58 47 -88 47 -13 -1 -35 -7 -49 -15z"
        />
        <path
          d="M1120 1624 c-19 -2 -78 -9 -130 -15 -346 -39 -932 -226 -976 -310
-11 -22 -14 -81 -14 -289 0 -283 4 -305 55 -328 42 -19 59 -15 183 47 279 139
557 219 861 247 64 5 79 11 103 34 47 48 33 126 -28 155 -29 14 -48 14 -140 5
-250 -26 -503 -92 -742 -194 -46 -20 -86 -36 -88 -36 -2 0 -4 56 -4 124 l0
123 97 38 c563 215 1084 260 1577 136 157 -40 225 -69 631 -271 253 -127 387
-187 450 -205 363 -103 844 -99 1169 11 130 43 146 96 70 226 -26 43 -45 80
-43 81 6 4 693 -13 697 -17 3 -4 -314 -595 -328 -609 -4 -5 -24 21 -44 57 -42
74 -77 106 -117 106 -16 0 -62 -13 -104 -30 -374 -149 -815 -188 -1210 -109
-174 35 -277 77 -643 261 -249 125 -365 178 -388 178 -49 0 -96 -44 -97 -92 0
-19 6 -46 15 -59 16 -24 637 -341 782 -399 416 -167 986 -172 1477 -14 58 19
111 34 116 34 6 0 39 -50 74 -111 79 -136 116 -162 187 -125 36 19 552 969
552 1017 0 45 -45 86 -101 93 -24 3 -275 10 -556 16 -560 12 -555 12 -581 -45
-20 -44 -14 -64 49 -177 34 -60 59 -111 57 -114 -3 -2 -52 -14 -109 -26 -138
-29 -505 -31 -668 -5 -197 32 -247 51 -616 235 -424 211 -496 242 -650 282
-190 50 -354 70 -585 75 -113 2 -221 1 -240 -1z"
        />
        <path
          d="M1550 1153 c-52 -20 -83 -89 -60 -134 47 -92 173 -73 187 27 4 35 1
46 -23 73 -29 31 -74 46 -104 34z"
        />
      </g>
    </svg>
  );
};

export default AirconIcon;
