import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
// 쿼리 관련
import { QueryClient, QueryClientProvider } from "react-query";
// import { ReactQueryDevtools } from "react-query/devtools";
// 리덕스 관련
import { setLoginToken } from "./lib/redux/actions/authAction";
import { initOn } from "./lib/redux/actions/commonAction";
import { initWishList } from "./lib/redux/actions/globalAction";
import { useTypedSelector, useTypedDispatch } from "./lib/redux/store";
// 다국어 관련
import { useTranslation } from "react-i18next";
import "./lib/lang/i18n";
// 브라우저, 모바일 관련
import BrowserApp from "@/browser/BrowserApp";
import MobileApp from "@/mobile/MobileApp";
import { isBrowser, isMobile } from "react-device-detect";
// 테마 관련
import { ThemeProvider, CssBaseline } from "@mui/material";
import { darkTheme, lightTheme } from "./lib/style/theme";
// Alert 관련
import { SnackbarProvider } from "notistack";
// 라이브러리
import { isNull, isNotNull, parseJWT } from "./lib/common";

const App = () => {
  const queryClient = new QueryClient();
  const { darkMode } = useTypedSelector((state) => state.common);
  const { loginToken } = useTypedSelector((state) => state.auth);
  const dispatch = useTypedDispatch();
  const { t } = useTranslation();

  const renderDevice = () => {
    if (isBrowser) {
      return <BrowserApp />;
    } else if (isMobile) {
      return <MobileApp />;
    } else {
      return <div>{t("MSG.NOT_SUPPORT_DEVICE")}</div>;
    }
  };

  useEffect(() => {
    const tabOpened = () => {
      const init = async () => {
        window.localStorage.removeItem("TCT");

        let TAB_ID = window.sessionStorage.getItem("TID");

        // 신규접속 : TAB_ID 미존재
        // 새로고침 : TAB_ID 존재
        if (isNull(TAB_ID)) {
          TAB_ID = uuidv4();
          window.sessionStorage.setItem("TID", TAB_ID);
        }

        if (isNotNull(loginToken)) {
          const data = parseJWT(loginToken);
          const accessTokenExpired = data.exp * 1000 - Date.now();

          if (accessTokenExpired < 0) {
            dispatch(setLoginToken(""));
            dispatch(initWishList());
          }
        }
      };
      init().then(() => {
        dispatch(initOn());
      });
    };

    // const tabClosed = () => {
    //   const TAB_COUNT = window.localStorage.getItem("TCT");

    //   if (Number(TAB_COUNT) === 0) {
    //     window.localStorage.removeItem("TCT");
    //   } else {
    //     window.localStorage.setItem("TCT", String(Number(TAB_COUNT) - 1));
    //   }
    // };

    // Edge 에서는 refresh 할 경우 load 가 실행되지 않음.
    // window.addEventListener("beforeunload", tabClosed);

    tabOpened();
    // return () => {
    //   window.removeEventListener("beforeunload", tabClosed);
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        {/* <ReactQueryDevtools /> */}
        <SnackbarProvider maxSnack={3}>{renderDevice()}</SnackbarProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
