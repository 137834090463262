/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
// Redux
import { useTypedDispatch, useTypedSelector } from "@/common/lib/redux/store";
import { loadingOn, loadingOff } from "@/common/lib/redux/actions/commonAction";
import { setParams } from "@/common/lib/redux/actions/searchAction";
// Components
import { Grid, Theme, Typography, IconButton, useTheme } from "@mui/material";
import GridViewIcon from "@mui/icons-material/GridView";
import {
  GeneralListItem,
  GeneralListItemSkeleton,
} from "@/mobile/components/vehicle/list";
import { Checkbox, Dropdown, Pagination } from "@/common/components";
// Styles
import { useStyles, useWindowSize } from "@/common/lib/style/hooks";
// API
import { getVehicleList } from "@/common/lib/api/vehicle";
// Libs
import { getUTCDate, isNull } from "@/common/lib/common";
import { browserViewList, sortList } from "@/common/lib/varList";

const styles = (theme: Theme) => ({
  title: {
    fontSize: "1.45rem",
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
  topDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "1vh",
    "& > button": {
      padding: "0.25rem",
    },
  },
  alignDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "1vh",
  },
  midDiv: {
    borderTop: "1px solid " + theme.palette.divider,
    borderBottom: "1px solid " + theme.palette.divider,
    paddingTop: 20,
    paddingBottom: 20,
    marginTop: 15,
    marginBottom: 15,
    "& .noData": {
      fontSize: "1.05rem",
      fontWeight: "bold",
      textAlign: "center",
      padding: "8px 0 0",
    },
  },
  checkboxFormControl: {
    "& > .MuiCheckbox-root": {
      padding: "0.35rem",
    },
    "& > .MuiTypography-root": {
      fontSize: "0.75rem",
    },
  },
  checkbox: {
    "& > svg": {
      fontSize: "1.15rem",
    },
  },
  dropdownFormControl: {
    width: "100% !important",
    fontSize: "0.75rem !important",
  },
  dropdownMenuItem: {
    width: "100% !important",
    fontSize: "0.75rem !important",
  },
});

const GeneralListContainer = () => {
  const theme = useTheme();
  const classes = useStyles(styles);
  const windowSize = useWindowSize();

  const { t } = useTranslation();
  const { loading } = useTypedSelector((state) => state.common);
  const { params } = useTypedSelector((state) => state.search);
  const dispatch = useTypedDispatch();
  const containerRef = useRef<HTMLDivElement>(null);

  const [init, setInit] = useState(false);

  const [page, setPage] = useState(1);
  const [view, setView] = useState("");
  const [sort, setSort] = useState("");
  const [itemList, setItemList] = useState<VehicleData[]>([]);

  const [dot, setDot] = useState(1);
  const [count, setCount] = useState(0);
  const [totalPage, setTotalPage] = useState(0);

  const [checkPromote, setCheckPromote] = useState(false);
  const [checkView, setCheckView] = useState(true);

  const vehicleListQuery = useQuery(
    "vehicleList",
    () => {
      const size = isNull(view) ? 100 : Number(view) * 10;
      const align = isNull(sort) ? "-modified" : sort;
      const listParams = {
        ...params,
      };
      dispatch(loadingOn());
      return getVehicleList(dot, size, align, listParams);
    },
    {
      retry: false,
      staleTime: 1000,
      onSuccess: (response) => {
        setItemList(response.data.results);
        setCount(response.data.count["total"]);
      },
      onSettled: () => {
        dispatch(loadingOff());
      },
    }
  );

  useEffect(() => {
    if (containerRef.current) {
      const toolbarHeight = document.getElementById("toolbar")?.offsetHeight;
      window.scrollTo({
        top: toolbarHeight
          ? containerRef.current.offsetTop - toolbarHeight
          : containerRef.current.offsetTop,
        left: 0,
      });
    }
    const size = isNull(view) ? 10 : Number(view);
    setDot(Math.floor((page - 1) / size) + 1);
  }, [page]);

  useEffect(() => {
    const size = isNull(view) ? 10 : Number(view);
    setTotalPage(Math.ceil(count / size));
  }, [view, count]);

  useEffect(() => {
    setPage(1);
  }, [view, sort, checkPromote]);

  useEffect(() => {
    if (checkPromote) {
      dispatch(
        setParams({
          ...params,
          criteria: JSON.stringify([
            {
              type: "DISCOUNT_EXPIRED",
              period: getUTCDate(new Date()) + "~2099-12-31T23:59",
            },
          ]),
        })
      );
    } else {
      const data = { ...params };
      if (Object.keys(params).includes("criteria")) {
        delete data["criteria"];
      }
      dispatch(setParams({ ...data }));
    }
  }, [checkPromote]);

  useEffect(() => {
    if (init) {
      vehicleListQuery.refetch();
    }
  }, [view, sort, dot, params]);

  useEffect(() => {
    if (vehicleListQuery.isSuccess) {
      setInit(true);
    }
  }, [vehicleListQuery.isLoading]);

  return (
    <>
      <Typography className={classes.title}>
        {t("LIST.GENERAL.TITLE")}
      </Typography>
      <div className={classes.topDiv} ref={containerRef}>
        <Checkbox
          label={t("LIST.GENERAL.ONLY_VIEW_PROMOTE")}
          value={checkPromote}
          dispatch={setCheckPromote}
          formControlClassName={classes.checkboxFormControl}
          checkboxClassName={classes.checkbox}
        />
        <IconButton onClick={() => setCheckView(!checkView)}>
          <GridViewIcon />
        </IconButton>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Dropdown
            label={t("DROPDOWN.ALIGN.LABEL")}
            value={sort}
            options={sortList}
            dispatch={setSort}
            formControlClassName={classes.dropdownFormControl}
            menuItemClassName={classes.dropdownMenuItem}
          />
        </Grid>
        <Grid item xs={6}>
          <Dropdown
            label={t("DROPDOWN.VIEW.LABEL")}
            value={view}
            options={browserViewList}
            dispatch={setView}
            formControlClassName={classes.dropdownFormControl}
            menuItemClassName={classes.dropdownMenuItem}
          />
        </Grid>
      </Grid>
      <div className={classes.midDiv}>
        <Grid
          container
          spacing={
            windowSize.width >= theme.breakpoints.values.md
              ? 3
              : windowSize.width >= theme.breakpoints.values.sm
              ? 2
              : 1
          }
        >
          {loading ? (
            <>
              {[...Array(10).keys()].map((idx) => {
                return (
                  <Grid
                    item
                    key={(isNull(view) ? 10 : Number(view)) * (page - 1) + idx}
                    xs={checkView ? 6 : 12}
                  >
                    <GeneralListItemSkeleton slidesToShow={checkView ? 2 : 1} />
                  </Grid>
                );
              })}
            </>
          ) : (
            <>
              {itemList.length > 0 ? (
                itemList
                  .slice(
                    (isNull(view) ? 10 : Number(view)) * ((page - 1) % 10),
                    (isNull(view) ? 10 : Number(view)) * (((page - 1) % 10) + 1)
                  )
                  .map((item, idx) => {
                    return (
                      <Grid
                        item
                        key={
                          (isNull(view) ? 10 : Number(view)) * (page - 1) + idx
                        }
                        xs={checkView ? 6 : 12}
                      >
                        <GeneralListItem
                          data={item}
                          slidesToShow={checkView ? 2 : 1}
                        />
                      </Grid>
                    );
                  })
              ) : (
                <Grid item xs={12} className="noData">
                  {t("LIST.GENERAL.MSG.NO_DATA")}
                </Grid>
              )}
            </>
          )}
        </Grid>
      </div>
      <div className={classes.botDiv}>
        <Pagination
          size={5}
          page={page}
          totalPage={totalPage}
          setPage={setPage}
        />
      </div>
    </>
  );
};

export default GeneralListContainer;
