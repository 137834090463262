/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
// Components
import {
  keyframes,
  ClickAwayListener,
  Grid,
  IconButton,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  LeftSide,
  FrontSide,
  RearSide,
  RightSide,
  StatusMark,
} from "@/mobile/components/vehicle/inspect";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
import { colors } from "@/common/lib/style/palette";
// Libs
import { isNotNull } from "@/common/lib/common";

const fadein = keyframes`
  from {
    opacity: 0;
    transform: translateY(5px);
  }
  to {
    opacity: 0.9;
    transform: none;
  }
`;

const styles = (theme: Theme) => ({
  container: {
    marginBottom: "3vh",
  },
  title: {
    fontSize: "1.25rem",
    fontWeight: "bold",
    userSelect: "none",
    marginBottom: "1vh",
  },
  notice: {
    fontSize: "0.85rem",
    padding: "0 2.5vw",
  },
  inspectionStatus: {
    position: "relative",
    borderTop: "2px solid " + theme.palette.text.primary,
    padding: "1vh 0",
    marginTop: "1vh",
    "& .front": {
      padding: "1.5vh 0",
      "@media (max-width: 600px)": {
        borderRight: "1px solid " + theme.palette.text.primary,
        borderBottom: "1px solid " + theme.palette.text.primary,
      },
    },
    "& .rear": {
      padding: "1.5vh 0",
      "@media (max-width: 600px)": {
        borderLeft: "1px solid " + theme.palette.text.primary,
        borderBottom: "1px solid " + theme.palette.text.primary,
      },
    },
    "& .left": {
      padding: "1.5vh 0",
      "@media (max-width: 600px)": {
        borderTop: "1px solid " + theme.palette.text.primary,
        borderRight: "1px solid " + theme.palette.text.primary,
      },
    },
    "& .right": {
      padding: "1.5vh 0",
      "@media (max-width: 600px)": {
        borderTop: "1px solid " + theme.palette.text.primary,
        borderLeft: "1px solid " + theme.palette.text.primary,
      },
    },
  },
  inspectionStatusMark: {
    display: "flex",
    justifyContent: "center",
    borderBottom: "2px solid " + theme.palette.text.primary,
    padding: "1vh 0",
    marginBottom: "1vh",
    "& td > div": {
      display: "flex",
      alignItems: "center",
      margin: "0 7.5px",
      "& > .MuiTypography-root": {
        fontSize: "0.85rem",
        marginLeft: "1.5vw",
      },
    },
  },
  inspectionBackground: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: "100%",
    height: "100%",
    backdropFilter: "blur(3px)",
  },
  inspectionAlert: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto",
    width: "fit-content",
    height: "fit-content",
    padding: "3vh 6vw",
    background: "white",
    borderRadius: "0.85rem",
    opacity: 0.9,
    animation: `${fadein} 1500ms ${theme.transitions.easing.easeInOut}`,
    "&.noData": {
      border: "0.25rem solid " + theme.palette.error.main,
      color: theme.palette.error.main,
    },
    "&.accidentFree": {
      border: "0.25rem solid " + theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
    "& > p.title": {
      margin: 0,
      marginBottom: "0.5vh",
      fontSize: "1rem",
      fontWeight: "bold",
      textAlign: "center",
    },
    "& > p.subtitle": {
      margin: 0,
      fontSize: "0.75rem",
      textAlign: "center",
    },
  },
  table: {
    width: "100%",
    height: "fit-content",
    "& th": {
      fontSize: "0.9rem",
      fontWeight: 500,
      "& > p": {
        display: "flex",
        alignItems: "center",
        margin: 0,
        textAlign: "left",
        "& > span": {
          fontSize: "1rem",
          fontWeight: "bold",
          marginRight: "2.5vw",
        },
        "& > button": {
          padding: "1vw",
          marginLeft: "1.5vw",
          "& > .MuiSvgIcon-root": {
            width: "1rem",
            height: "1rem",
            color: colors.gray[6],
            "&:hover": {
              color: colors.blue[5],
            },
          },
        },
      },
    },
    "& td": {
      fontSize: "0.85rem",
      padding: "1vh 2vw",
      fontWeight: 500,
    },
  },
  tooltip: {
    maxWidth: "85vw",
    fontSize: "0.825rem",
    fontWeight: 500,
    color: colors.blue[5],
    lineHeight: "1.15",
    backgroundColor: "white",
    border: "0.15rem solid " + colors.blue[5],
    padding: "0.65vh 1.5vw",
    userSelect: "none",
    "& .MuiTooltip-arrow": {
      color: colors.blue[5],
    },
  },
});

interface propType {
  data: VehicleData;
}

const InspectionContainer = ({ data }: propType) => {
  const classes = useStyles(styles);
  const { t } = useTranslation();

  const [isData, setIsData] = useState(false);
  const [isAccident, setIsAccident] = useState(true);
  const [accidentOpen, setAccidentOpen] = useState(false);
  const [repairOpen, setRepairOpen] = useState(false);

  const corrosionList: { [key: string]: string } = {
    N: t("OPTION.NONE_0"),
    L: t("OPTION.LESS_25"),
    M: t("OPTION.LESS_50"),
    H: t("OPTION.LESS_75"),
    F: t("OPTION.EXIST_100"),
  };
  const ynList: { [key: string]: string } = {
    Y: t("OPTION.EXIST"),
    N: t("OPTION.NOT_EXIST"),
  };

  useEffect(() => {
    if (data !== undefined) {
      let _isData = false;
      if (
        isNotNull(data.inspection["accident_history"]) &&
        isNotNull(data.inspection["simple_repair"])
      ) {
        _isData = true;
      }

      if (_isData) {
        let isAllSafe = true;
        Object.entries(data.inspection).forEach(([key, value]) => {
          if (
            ![
              "corrosion",
              "hole",
              "crack",
              "accident_history",
              "simple_repair",
            ].includes(key)
          ) {
            if (isNotNull(value)) {
              isAllSafe = false;
            }
          }
        });
        setIsData(true);
        if (isAllSafe) {
          setIsAccident(false);
        } else {
          setIsAccident(true);
        }
      } else {
        setIsData(false);
        setIsAccident(true);
      }
    } else {
      setIsData(false);
    }
  }, [data]);

  return (
    <>
      {data !== undefined && (
        <>
          <Typography className={classes.title}>
            {t("DETAIL.INSPECTION.TITLE")}
          </Typography>
          <Grid container className={classes.container}>
            <Grid item xs={12}>
              <table className={classes.table}>
                <tbody>
                  <tr>
                    <th>
                      <p>
                        <span>*</span>
                        {t("VEHICLE.INSPECTION.ACCIDENT_HISTORY")}
                        <ClickAwayListener
                          onClickAway={() => setAccidentOpen(false)}
                        >
                          <Tooltip
                            arrow
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            PopperProps={{
                              disablePortal: true,
                            }}
                            title={t("DETAIL.INSPECTION.MSG.ACCIDENT_HISTORY")}
                            classes={{
                              tooltip: classes.tooltip,
                            }}
                            open={accidentOpen}
                            onClose={() => setAccidentOpen(false)}
                          >
                            <IconButton
                              onClick={() => setAccidentOpen(!accidentOpen)}
                            >
                              <HelpOutlineIcon />
                            </IconButton>
                          </Tooltip>
                        </ClickAwayListener>
                      </p>
                    </th>
                    <td>
                      {ynList[data.inspection.accident_history] !== undefined
                        ? ynList[data.inspection.accident_history]
                        : "N/A"}
                    </td>
                    <th>
                      <p>
                        <span>*</span>
                        {t("VEHICLE.INSPECTION.SIMPLE_REPAIR")}
                        <ClickAwayListener
                          onClickAway={() => setRepairOpen(false)}
                        >
                          <Tooltip
                            arrow
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            PopperProps={{
                              disablePortal: true,
                            }}
                            title={t("DETAIL.INSPECTION.MSG.SIMPLE_REPAIR")}
                            classes={{
                              tooltip: classes.tooltip,
                            }}
                            open={repairOpen}
                            onClose={() => setRepairOpen(false)}
                          >
                            <IconButton
                              onClick={() => setRepairOpen(!repairOpen)}
                            >
                              <HelpOutlineIcon />
                            </IconButton>
                          </Tooltip>
                        </ClickAwayListener>
                      </p>
                    </th>
                    <td>
                      {ynList[data.inspection.simple_repair] !== undefined
                        ? ynList[data.inspection.simple_repair]
                        : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <p>
                        <span>*</span>
                        {t("VEHICLE.INSPECTION.IS_CORROSION")}
                      </p>
                    </th>
                    <td colSpan={3}>
                      {corrosionList[data.inspection.corrosion] !== undefined
                        ? corrosionList[data.inspection.corrosion]
                        : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <p>
                        <span>*</span>
                        {t("VEHICLE.INSPECTION.IS_HOLE")}
                      </p>
                    </th>
                    <td>
                      {ynList[data.inspection.hole] !== undefined
                        ? ynList[data.inspection.hole]
                        : "N/A"}
                    </td>
                    <th>
                      <p>
                        <span>*</span>
                        {t("VEHICLE.INSPECTION.IS_CRACK")}
                      </p>
                    </th>
                    <td>
                      {ynList[data.inspection.crack] !== undefined
                        ? ynList[data.inspection.crack]
                        : "N/A"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Grid>
            <Grid item xs={12} className={classes.inspectionStatus}>
              <Grid container>
                <Grid item xs={6} sm={3} className={"front"}>
                  <FrontSide data={data} />
                </Grid>
                <Grid item xs={6} sm={3} className={"rear"}>
                  <RearSide data={data} />
                </Grid>
                <Grid item xs={6} sm={3} className={"left"}>
                  <LeftSide data={data} />
                </Grid>
                <Grid item xs={6} sm={3} className={"right"}>
                  <RightSide data={data} />
                </Grid>
              </Grid>
              {(!isData || !isAccident) && (
                <>
                  <div className={classes.inspectionBackground} />
                  {!isData && (
                    <div className={`${classes.inspectionAlert} noData`}>
                      <p className="title">
                        {t("DETAIL.INSPECTION.MSG.NO_DATA")}
                      </p>
                      <p className="subtitle">
                        <Markup content={t("DETAIL.INSPECTION.MSG.CHECK")} />
                      </p>
                    </div>
                  )}
                  {isData && !isAccident && (
                    <div className={`${classes.inspectionAlert} accidentFree`}>
                      <p className="title">
                        {t("DETAIL.INSPECTION.MSG.NO_ACCIDENT")}
                      </p>
                      <p className="subtitle">
                        <Markup content={t("DETAIL.INSPECTION.MSG.CHECK")} />
                      </p>
                    </div>
                  )}
                </>
              )}
            </Grid>
            <Grid item xs={12} className={classes.inspectionStatusMark}>
              <table>
                <thead></thead>
                <tbody>
                  <tr>
                    <td>
                      <div>
                        <StatusMark status="X" />
                        <Typography>
                          {t("VEHICLE.INSPECTION.EXCHANGE")}
                        </Typography>
                      </div>
                    </td>
                    <td>
                      <div>
                        <StatusMark status="W" />
                        <Typography>
                          {t("VEHICLE.INSPECTION.PLATE_WELDING")}
                        </Typography>
                      </div>
                    </td>
                    <td>
                      <div>
                        <StatusMark status="C" />
                        <Typography>
                          {t("VEHICLE.INSPECTION.CORROSION")}
                        </Typography>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <StatusMark status="A" />
                        <Typography>
                          {t("VEHICLE.INSPECTION.SCRATCH")}
                        </Typography>
                      </div>
                    </td>
                    <td>
                      <div>
                        <StatusMark status="U" />
                        <Typography>
                          {t("VEHICLE.INSPECTION.UNEVEN")}
                        </Typography>
                      </div>
                    </td>
                    <td>
                      <div>
                        <StatusMark status="T" />
                        <Typography>
                          {t("VEHICLE.INSPECTION.DAMAGE")}
                        </Typography>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.notice}>
                <Markup content={t("DETAIL.INSPECTION.MSG.CHECK_INSPECTION")} />
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default InspectionContainer;
