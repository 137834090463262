import axios from "axios";
// Redux
import { store } from "@/common/lib/redux/store";
// Libs
import { isNotNull } from "@/common/lib/common";

export const getOfferList = async (params: { [key: string]: any } = {}) => {
  const state = store.getState();
  const headers: { [key: string]: string } = {
    "Content-Type": "application/json",
  };
  if (isNotNull(state.auth.loginToken)) {
    headers["Authorization"] = `Bearer ${state.auth.loginToken}`;
  }
  params = {
    ...params,
    search_type: "USER",
  };

  const url = `/api/offer/`;
  const result = await axios({
    method: "get",
    url: url,
    params: params,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
    headers: headers,
    withCredentials: true,
  });
  return result;
};

export const postOfferInfo = async (data: { data: any }) => {
  const state = store.getState();
  const headers: { [key: string]: string } = {
    "Content-Type": "application/json",
  };
  if (isNotNull(state.auth.loginToken)) {
    headers["Authorization"] = `Bearer ${state.auth.loginToken}`;
  }

  const sendData = new FormData();
  sendData.append("data", JSON.stringify(data));

  const url = "/api/offer/";
  const result = await axios({
    method: "post",
    url: url,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
    data: sendData,
    headers: headers,
    withCredentials: true,
  });
  return result;
};

export const patchOfferInfo = async (data: { id: number; data: any }) => {
  const state = store.getState();
  const headers: { [key: string]: string } = {
    "Content-Type": "application/json",
  };
  if (isNotNull(state.auth.loginToken)) {
    headers["Authorization"] = `Bearer ${state.auth.loginToken}`;
  }

  const sendData = new FormData();
  sendData.append("data", JSON.stringify(data["data"]));

  const url = `/api/offer/${data["id"]}/`;
  const result = await axios({
    method: "patch",
    url: url,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
    headers: headers,
    withCredentials: true,
    data: sendData,
  });
  return result;
};
