// Styles
import { useStyles } from "@/common/lib/style/hooks";

// Components
import BasePage from "@/mobile/pages/common/BasePage";
import MainCarousel from "@/mobile/components/about/profile/MainCarousel";
import AchieveContainer from "@/mobile/components/about/profile/AchieveContainer";
import ProductsCarousel from "@/mobile/components/about/profile/ProductsCarousel";
import YoutubeContainer from "@/mobile/components/about/profile/YoutubeContainer";
import ProfileContainer from "@/mobile/components/about/profile/ProfileContainer";
import MapContainer from "@/mobile/components/about/MapContainer";
import { SNSFab } from "@/mobile/components/common";

import { Divider } from "@mui/material";

const styles = () => ({
  basePage: {
    padding: "1vh 4vw 2vh",
  },
  divider: {
    width: "100%",
    margin: "1.5vh 0",
    "&.top": {
      marginTop: 0,
    },
    "&.bottom": {
      marginBottom: 0,
    },
  },
});

const ProfilePage = () => {
  const classes = useStyles(styles);

  return (
    <BasePage className={classes.basePage}>
      <MainCarousel />
      <AchieveContainer />
      <Divider className={classes.divider} />
      <ProductsCarousel />
      <Divider className={classes.divider} />
      <YoutubeContainer />
      <Divider className={classes.divider} />
      <ProfileContainer />
      <Divider className={classes.divider} />
      <MapContainer />
      <SNSFab />
    </BasePage>
  );
};

export default ProfilePage;
