/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
// Redux
import { useTypedDispatch, useTypedSelector } from "@/common/lib/redux/store";
import {
  loadingOn,
  loadingOff,
  setErrorText,
} from "@/common/lib/redux/actions/commonAction";
// Components
import Icon from "@mdi/react";
import {
  mdiWhatsapp as WhatsappIcon,
  mdiFacebookMessenger as FacebookMessengerIcon,
  mdiDropbox as DropboxIcon,
} from "@mdi/js";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider,
  Grid,
  Theme,
  Typography,
  IconButton,
} from "@mui/material";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Button } from "@/common/components";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
import { colors } from "@/common/lib/style/palette";
// API
import { onError } from "@/common/lib/api/common";
import { postBookingRequestInfo } from "@/common/lib/api/bookingRequest";
// Libs
import {
  isNotNull,
  getAutowiniCommission,
  getAutowiniBusCommission,
} from "@/common/lib/common";

const styles = (theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    width: "100%",
    position: "fixed",
    bottom: 0,
    zIndex: 99,
    pointerEvents: "none",
  },
  accordion: {
    width: "100%",
    margin: 0,
    background: theme.palette.background.default,
    pointerEvents: "all",
  },
  accordionSummary: {
    cursor: "default",
    height: "fit-content",
    minHeight: 0,
    padding: "2vh 4vw",
    borderTop: "1px solid " + theme.palette.text.primary,
    "& .MuiAccordionSummary-content": {
      margin: 0,
      justifyContent: "center",
    },
  },
  accordionDetails: {
    padding: "1vw",
  },
  expandGrid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > svg": {
      fontSize: "2.35rem",
      margin: 0,
      padding: 0,
      cursor: "pointer",
    },
  },
  priceGrid: {
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "column",
    paddingRight: "2.5vw",
    "& > div": {
      display: "flex",
      alignItems: "flex-end",
    },
    "& .price": {
      fontSize: "1.35rem",
      fontWeight: "bold",
      color: theme.palette.primary.main,
    },
    "& .discount_price": {
      fontSize: "1.35rem",
      fontWeight: "bold",
      color: theme.palette.error.main,
    },
    "& .disable_price": {
      fontSize: "1.15rem",
      fontWeight: "bold",
      color: theme.palette.divider,
      textDecoration: "line-through",
      textDecorationThickness: "0.1rem",
      textDecorationColor: theme.palette.divider,
      marginRight: "0.3rem",
    },
    "& .location": {
      fontSize: "0.9rem",
      fontWeight: 500,
      marginTop: "0.5vh",
    },
    "& .MuiTypography-root": {
      lineHeight: "1",
    },
    "&.WAIT": {
      borderRight: "0.45rem solid " + colors.gray[8],
    },
    "&.ON": {
      borderRight: "0.45rem solid " + colors.blue[7],
    },
    "&.REZ": {
      borderRight: "0.45rem solid " + colors.cyan[6],
    },
    "&.DONE": {
      borderRight: "0.45rem solid " + colors.teal[7],
    },
    "&.NOT": {
      borderRight: "0.45rem solid " + colors.red[7],
    },
  },
  whatsapp: {
    width: "100%",
    height: "fit-content",
    background: "#2BA63A !important",
    boxSizing: "border-box",
    padding: "1.5vh 2vw",
    borderRadius: "0.35rem",
    cursor: "pointer",
    textTransform: "none",
    fontSize: "0.875rem",
    fontWeight: 500,
    "& > svg": {
      width: "2rem",
      height: "2rem",
      marginRight: "0.45rem",
    },
  },
  facebook: {
    width: "100%",
    height: "fit-content",
    background: "#4B69B0 !important",
    boxSizing: "border-box",
    padding: "1.5vh 2vw",
    borderRadius: "0.35rem",
    cursor: "pointer",
    textTransform: "none",
    fontSize: "0.875rem",
    fontWeight: 500,
    "& > svg": {
      width: "2rem",
      height: "2rem",
      marginRight: "0.45rem",
    },
  },
  dropbox: {
    width: "100%",
    height: "fit-content",
    background: "#0061FE !important",
    boxSizing: "border-box",
    padding: "1.5vh 2vw",
    borderRadius: "0.35rem",
    cursor: "pointer",
    textTransform: "none",
    fontSize: "0.875rem",
    fontWeight: 500,
    "& > svg": {
      width: "2rem",
      height: "2rem",
      marginRight: "0.45rem",
    },
  },
  request: {
    width: "100%",
    height: "fit-content",
    background: colors.teal[7] + " !important",
    boxSizing: "border-box",
    padding: "1.5vh 2vw",
    borderRadius: "0.35rem",
    cursor: "pointer",
    textTransform: "none",
    fontSize: "0.875rem",
    fontWeight: 500,
    "& > svg": {
      width: "2rem",
      height: "2rem",
      marginRight: "0.45rem",
    },
  },
  button: {
    border: "0.175rem solid white",
    borderRadius: "50%",
    margin: "0.75rem",
    opacity: 0.75,
    pointerEvents: "all",
    backgroundColor: theme.palette.primary.main + "!important",
    "& > svg": {
      fontSize: "1.75rem",
      color: "white",
    },
  },
  dialog: {
    maxWidth: 900,
    width: "fit-content",
    height: "fit-content",
    padding: "2vh 4.5vw",
    borderRadius: "3.5vw",
    userSelect: "none",
  },
  dialogTitle: {
    padding: "1.5vh 4.5vw",
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    wordBreak: "keep-all",
  },
  dialogContent: {
    padding: "0 4.5vw",
    "& > p > span": {
      display: "block",
      fontSize: "0.925rem",
      fontWeight: "500",
      "& > span": {
        fontSize: "0.85rem",
        color: theme.palette.error.main,
        wordBreak: "keep-all",
      },
    },
  },
  dialogActions: {
    "& > button": {
      fontSize: "1.05rem",
    },
  },
});

const BottomContainer = ({ data }: any) => {
  const classes = useStyles(styles);
  const { t } = useTranslation();
  const { loginToken } = useTypedSelector((state) => state.auth);
  const dispatch = useTypedDispatch();

  const facebookURL = "https://www.facebook.com/autoleadercar";
  const whatsappURL = "https://wa.me/821076599744";

  const [expanded, setExpanded] = useState(true);
  const [isDiscount, setIsDiscount] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const postBookingRequestMutation = useMutation(postBookingRequestInfo, {
    onSuccess: () => {
      dispatch(
        setErrorText({
          title: t("DIALOG.TITLE.BOOKING_REQUEST_DONE_MOBILE"),
          content: t("DIALOG.200.BOOKING_SUCCESS_MOBILE"),
        })
      );
    },
    onError: (error) => {
      onError(error);
    },
    onSettled: () => {
      dispatch(loadingOff());
    },
  });

  useEffect(() => {
    setTimeout(() => {
      setExpanded(false);
    }, 850);

    if (data !== undefined && isNotNull(data.sales.discount_expired)) {
      const todayDate = new Date();
      const expireDate = new Date(data.sales.discount_expired);
      expireDate.setHours(expireDate.getHours() + 9);

      if (expireDate >= todayDate) {
        setIsDiscount(true);
      }
    }
  }, [data]);

  const handleOnScrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleOnApply = () => {
    if (isNotNull(loginToken)) {
      if (data.sales.status === "ON" || data.sales.status === "REZ") {
        dispatch(loadingOn());
        postBookingRequestMutation.mutate({ uid: data.product.uid });
      } else {
        dispatch(
          setErrorText({
            title: t("DIALOG.TITLE.DISABLE_STATUS"),
            content: t("DIALOG.CONTENT.BOOKING_REQUEST_STATUS_MOBILE"),
          })
        );
      }
    } else {
      dispatch(
        setErrorText({
          title: t("DIALOG.401.LOGIN_ONLY_SERVICE"),
          content: t("DIALOG.CONTENT.BOOKING_REQUEST_LOGIN"),
        })
      );
    }
    setDialogOpen(false);
  };
  const handleSNSOnClick = (url: string | undefined) => {
    if (url !== undefined) {
      window.open(url, "_blank");
    }
  };

  return (
    <>
      <div className={classes.container}>
        <IconButton className={classes.button} onClick={handleOnScrollUp}>
          <ArrowUpwardIcon />
        </IconButton>
        <Accordion classes={{ root: classes.accordion }} expanded={expanded}>
          <AccordionSummary classes={{ root: classes.accordionSummary }}>
            <Grid container onClick={() => setExpanded(!expanded)}>
              <Grid item xs={1} className={classes.expandGrid}>
                {expanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </Grid>
              <Grid
                item
                xs={11}
                className={
                  data !== undefined
                    ? `${classes.priceGrid} ${data.sales.status}`
                    : `${classes.priceGrid}`
                }
              >
                {/* {isNotNull(loginToken) ? ( */}
                {isDiscount ? (
                  <div>
                    <Typography className="disable_price">
                      USD{" "}
                      {data !== undefined
                        ? (
                            data.sales.price +
                            (data.product.type === "CAR" ||
                            data.product.type === "TRUCK"
                              ? getAutowiniCommission(data.sales.price)
                              : getAutowiniBusCommission(data.sales.price))
                          ).toLocaleString()
                        : 0}
                    </Typography>
                    <Typography className="discount_price">
                      USD{" "}
                      {data !== undefined
                        ? (
                            data.sales.price +
                            (data.product.type === "CAR" ||
                            data.product.type === "TRUCK"
                              ? getAutowiniCommission(data.sales.price)
                              : getAutowiniBusCommission(data.sales.price)) -
                            data.sales.discount
                          ).toLocaleString()
                        : 0}
                    </Typography>
                  </div>
                ) : (
                  <Typography className="price">
                    USD{" "}
                    {data !== undefined
                      ? (
                          data.sales.price +
                          (data.product.type === "CAR" ||
                          data.product.type === "TRUCK"
                            ? getAutowiniCommission(data.sales.price)
                            : getAutowiniBusCommission(data.sales.price))
                        ).toLocaleString()
                      : 0}
                  </Typography>
                )}
                {/* ) : (
                  <Typography className="price">USD *,***</Typography>
                )} */}
                <Typography className="location">
                  {data !== undefined
                    ? "FOB " +
                      data.sales.location_region +
                      ", " +
                      data.sales.location_country
                    : "FOB Incheon, Korea"}
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <Divider />
          <AccordionDetails classes={{ root: classes.accordionDetails }}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  className={classes.whatsapp}
                  onClick={() => handleSNSOnClick(whatsappURL)}
                >
                  <Icon path={WhatsappIcon} />
                  WhatsApp
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  className={classes.facebook}
                  onClick={() => handleSNSOnClick(facebookURL)}
                >
                  <Icon path={FacebookMessengerIcon} />
                  Messenger
                </Button>
              </Grid>
              {data !== undefined && data.media.dropbox !== "" && (
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    className={classes.dropbox}
                    onClick={() => handleSNSOnClick(data.media.dropbox)}
                  >
                    <Icon path={DropboxIcon} />
                    Get Image From Dropbox
                  </Button>
                </Grid>
              )}
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  className={classes.request}
                  onClick={() => setDialogOpen(true)}
                >
                  <SupportAgentIcon />
                  Booking Request
                </Button>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </div>
      <Dialog
        classes={{ paper: classes.dialog }}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="request-confirm-title"
        aria-describedby="request-confirm-description"
      >
        <DialogTitle
          id="request-confirm-title"
          classes={{ root: classes.dialogTitle }}
        >
          <Markup content={t("DETAIL.BOOKING.MSG.TITLE_MOBILE")} />
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
          <DialogContentText id="request-confirm-description">
            <Markup content={t("DETAIL.BOOKING.MSG.CONTENT_MOBILE")} />
          </DialogContentText>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogActions }}>
          <Button onClick={() => handleOnApply()}>{t("OPTION.APPLY")}</Button>
          <Button onClick={() => setDialogOpen(false)}>
            {t("OPTION.CANCEL")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BottomContainer;
