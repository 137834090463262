const CompanyLogoIcon = ({ ...props }: any) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1300 480"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <g transform="translate(0, 480) scale(0.1, -0.1)">
        <path
          d="M5228 4493 c-9 -27 -91 -259 -183 -518 -309 -872 -755 -2135 -755
-2140 0 -3 130 -5 290 -5 334 0 332 0 472 93 145 97 275 213 584 521 381 382
560 596 790 946 222 338 363 619 460 915 l43 130 0 -1275 c0 -829 -3 -1269 -9
-1258 -9 15 -69 179 -372 1018 -60 168 -113 308 -116 312 -4 4 -67 -73 -140
-170 -73 -97 -160 -210 -194 -250 l-61 -72 22 -73 c12 -39 77 -243 145 -452
l124 -380 1196 -3 1196 -2 0 215 0 215 -615 0 -615 0 -2 1138 -3 1137 -275 3
-275 2 -11 -34 c-20 -64 -159 -289 -274 -441 -266 -354 -448 -544 -1003 -1048
-164 -149 -535 -471 -546 -475 -5 -2 10 57 33 130 23 73 134 426 247 785 112
358 207 649 210 645 3 -4 61 -178 128 -387 67 -209 125 -383 129 -387 9 -9
267 229 352 323 l59 66 -135 374 c-75 206 -142 390 -148 409 l-13 35 -360 3
-359 2 -16 -47z"
        />
        <path
          d="M526 737 c-121 -260 -221 -478 -224 -485 -3 -9 22 -12 101 -12 l105
0 28 65 28 65 189 0 188 0 30 -65 29 -65 105 0 c58 0 105 1 105 3 0 11 -451
962 -457 964 -4 1 -107 -210 -227 -470z m282 -84 l50 -113 -105 0 c-80 0 -104
3 -100 13 17 41 101 217 103 215 1 -2 25 -54 52 -115z"
        />
        <path
          d="M3443 1195 c-162 -35 -306 -166 -360 -326 -25 -74 -25 -234 0 -308
35 -102 124 -214 213 -267 81 -48 152 -66 254 -66 144 0 251 44 350 144 95 97
140 206 140 343 0 45 -7 106 -16 136 -49 168 -193 303 -365 344 -77 18 -133
18 -216 0z m226 -215 c175 -76 227 -310 100 -454 -109 -124 -293 -134 -416
-23 -113 102 -123 286 -22 401 89 101 214 129 338 76z"
        />
        <path
          d="M6728 1164 c-11 -26 -110 -238 -220 -473 -110 -234 -202 -432 -205
-439 -4 -9 20 -12 101 -12 l106 0 29 65 30 65 189 0 189 0 28 -65 27 -65 105
0 c96 0 105 2 99 18 -26 66 -443 951 -449 952 -4 0 -17 -21 -29 -46z m131
-616 c1 -5 -46 -8 -103 -8 l-104 0 51 116 52 115 52 -108 c28 -59 52 -111 52
-115z"
        />
        <path
          d="M1323 848 c2 -245 7 -353 16 -378 24 -65 84 -144 141 -182 184 -126
439 -61 535 136 39 80 46 155 43 471 l-3 290 -97 3 -98 3 0 -309 c0 -329 -3
-355 -49 -406 -77 -85 -214 -68 -268 34 -23 42 -23 48 -23 362 l0 318 -101 0
-100 0 4 -342z"
        />
        <path
          d="M2270 1090 l0 -100 115 0 115 0 0 -375 0 -376 98 3 97 3 0 370 0 370
115 5 115 5 0 95 0 95 -327 3 -328 2 0 -100z"
        />
        <path
          d="M4750 715 l0 -475 285 0 285 0 0 100 0 100 -185 0 -185 0 -2 373 -3
372 -97 3 -98 3 0 -476z"
        />
        <path
          d="M5545 1178 c-3 -7 -4 -220 -3 -473 l3 -460 288 -3 288 -2 -3 97 -3
98 -187 3 -188 2 0 90 0 90 160 0 160 0 0 100 0 100 -160 0 -160 0 0 90 0 90
190 0 190 0 0 95 0 95 -285 0 c-219 0 -287 -3 -290 -12z"
        />
        <path
          d="M7420 716 l0 -476 163 0 c199 0 271 11 364 55 156 73 243 220 243
411 0 232 -102 386 -301 455 -56 20 -90 23 -266 27 l-203 4 0 -476z m384 260
c70 -21 119 -60 152 -119 26 -47 29 -61 29 -142 0 -73 -4 -98 -22 -130 -51
-96 -123 -136 -255 -143 l-88 -4 0 276 0 276 68 0 c38 0 90 -6 116 -14z"
        />
        <path
          d="M8430 715 l0 -475 285 0 285 0 0 100 0 100 -190 0 -190 0 0 90 0 90
163 2 162 3 3 98 3 97 -166 0 -166 0 3 88 3 87 188 3 187 2 0 95 0 95 -285 0
-285 0 0 -475z"
        />
        <path
          d="M9255 1178 c-3 -7 -4 -220 -3 -473 l3 -460 97 -3 98 -3 2 216 3 216
145 -215 145 -215 118 -1 c64 0 117 2 117 5 0 2 -59 87 -132 187 -120 166
-129 183 -112 193 10 5 42 33 69 60 146 144 94 381 -104 473 -53 26 -65 27
-248 30 -155 3 -194 1 -198 -10z m391 -222 c28 -28 34 -42 34 -76 0 -34 -6
-48 -34 -76 -33 -33 -36 -34 -115 -34 l-81 0 0 110 0 110 81 0 c79 0 82 -1
115 -34z"
        />
        <path
          d="M10250 660 c-77 -41 -130 -126 -130 -209 0 -21 12 -61 26 -90 62
-124 225 -167 323 -85 l30 25 -22 34 c-26 41 -30 42 -64 15 -17 -13 -41 -20
-71 -20 -38 0 -50 5 -83 39 -22 21 -39 49 -40 62 -4 73 13 108 64 139 42 26
80 25 128 -2 21 -12 40 -20 42 -17 46 60 47 62 35 76 -43 52 -170 70 -238 33z"
        />
        <path
          d="M10702 670 c-79 -33 -142 -127 -142 -209 0 -86 53 -166 140 -212 40
-20 138 -16 186 9 115 59 155 187 97 303 -41 80 -94 113 -186 116 -40 2 -82
-2 -95 -7z m132 -94 c81 -34 104 -134 45 -200 -52 -60 -126 -61 -186 -1 -28
28 -33 40 -33 83 0 57 25 96 74 118 40 17 59 17 100 0z"
        />
        <path
          d="M11530 460 l0 -220 130 0 130 0 0 45 0 45 -85 0 -85 0 0 175 0 175
-45 0 -45 0 0 -220z"
        />
        <path
          d="M11784 666 c-3 -8 -4 -29 -2 -48 3 -32 4 -33 56 -36 l52 -3 0 -170 0
-169 45 0 45 0 0 169 0 170 53 3 52 3 0 45 0 45 -148 3 c-120 2 -148 0 -153
-12z"
        />
        <path
          d="M12184 667 c-2 -7 -3 -105 -2 -217 l3 -205 70 -3 c107 -5 168 12 220
61 34 33 45 53 54 96 29 137 -34 243 -160 270 -71 15 -178 14 -185 -2z m191
-101 c45 -19 69 -66 62 -124 -7 -62 -40 -93 -106 -100 l-51 -5 0 122 0 121 30
0 c17 0 46 -6 65 -14z"
        />
        <path
          d="M11087 322 c-22 -25 -21 -55 2 -76 27 -25 72 -17 90 15 13 24 13 29
-2 53 -21 31 -66 35 -90 8z"
        />
        <path
          d="M11306 318 c-23 -32 -20 -54 9 -76 31 -25 32 -46 3 -67 -31 -21 -7
-37 34 -23 61 22 86 148 35 180 -24 15 -66 8 -81 -14z"
        />
        <path
          d="M12612 328 c-17 -17 -15 -64 4 -82 34 -34 94 -9 94 39 0 13 -7 31
-16 39 -18 19 -65 21 -82 4z"
        />
      </g>
    </svg>
  );
};

export default CompanyLogoIcon;
