import ReactPlayer from "react-player";
// Components
import { Card } from "@mui/material";
// Styles
import { useStyles } from "@/common/lib/style/hooks";

const styles = () => ({
  card: {
    width: "100%",
    aspectRatio: "1.778",
  },
});

const YoutubeContainer = () => {
  const classes = useStyles(styles);

  const playerSettings = {
    youtube: {
      playerVars: { showinfo: 1 },
    },
  };

  return (
    <Card className={classes.card}>
      <ReactPlayer
        url="https://youtu.be/DuM88xJRMtY"
        config={playerSettings}
        controls
        width="100%"
        height="100%"
      />
    </Card>
  );
};

export default YoutubeContainer;
