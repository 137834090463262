/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Markup } from "interweave";
// Redux
import { setLoginToken } from "@/common/lib/redux/actions/authAction";
import {
  loadingOn,
  loadingOff,
  setAccessURL,
  setErrorText,
} from "@/common/lib/redux/actions/commonAction";
import { useTypedDispatch, useTypedSelector } from "@/common/lib/redux/store";
// Components
import {
  IconButton,
  Modal,
  Paper,
  Tab,
  Tabs,
  Theme,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import BasePage from "@/browser/pages/common/BasePage";
import {
  Button,
  Dropdown,
  Input,
  NationAutocomplete,
} from "@/common/components";
import { CompanyLogoIcon } from "@/common/icons";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// API
import { onError } from "@/common/lib/api/common";
import { loginAPI } from "@/common/lib/api/auth";
import { findID, findPWD, patchUserInfo } from "@/common/lib/api/user";
// Libs
import i18n from "@/common/lib/lang/i18n";
import {
  isNull,
  isNotNull,
  parseJWT,
  validatePassword,
} from "@/common/lib/common";

const styles = (theme: Theme) => ({
  basePage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "100vw",
    minHeight: "100vh !important",
    userSelect: "none",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: 400,
    padding: 24,
    borderRadius: 10,
  },
  logo: {
    width: 275,
    fill: theme.palette.logo.blue,
    marginBottom: 7.5,
    cursor: "pointer",
  },
  input: {
    width: 320,
    margin: "3px 0",
  },
  button: {
    width: 320,
    margin: "10px 0 15px !important",
    fontSize: "1.125rem !important",
    fontWeight: "bold !important",
  },
  content: {
    width: 320,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  ul: {
    margin: "0",
    padding: 0,
    listStyle: "none",
    userSelect: "none",
    "& > li": {
      display: "inline-block",
      fontSize: 14,
      letterSpacing: "-0.75px",
      color: theme.palette.text.primary,
      cursor: "default",
      "& > div": {
        display: "inline-block",
        cursor: "pointer",
        "&:hover": {
          color: theme.palette.primary.main,
          opacity: 1,
          textDecoration: "underline",
        },
      },
    },
    "& > li:not(:first-child):before": {
      display: "inline-block",
      content: "''",
      width: 1,
      height: 10,
      backgroundColor: theme.palette.text.primary,
      margin: "0 10px",
    },
  },
  dropdownFormControl: {
    width: 72.5,
    fontSize: "14px !important",
  },
  dropdownSelect: {
    height: "27.5px !important",
    letterSpacing: "-0.75px",
  },
  dropdownMenuItem: {
    fontSize: 14,
    letterSpacing: "-0.75px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    userSelect: "none",
    "& > .MuiPaper-root": {
      position: "relative",
      width: 450,
      padding: 24,
      "& .MuiTab-root": {
        width: "50%",
      },
      "& > div:not(.MuiTabs-root)": {
        paddingTop: 16,
        "& > p": {
          userSelect: "none",
          fontSize: 15,
          fontWeight: "bold",
          marginTop: 10,
        },
        "& > div": {
          fontSize: 15,
          marginBottom: 15,
        },
        "& > h1": {
          fontSize: 17,
          fontWeight: "bold",
          marginBottom: 15,
        },
        "& > h2": {
          fontSize: 15,
          fontWeight: "bold",
          marginBottom: 5,
        },
        "& > h3": {
          fontSize: 14,
          fontWeight: "500",
          color: theme.palette.error.main,
          marginTop: -10,
          marginBottom: 15,
        },
        "& > button": {
          width: "100%",
          fontSize: "1rem",
          fontWeight: "bold",
        },
        "& > ul": {
          margin: 0,
          marginBottom: 10,
          paddingLeft: 20,
          "& > li": {
            fontSize: 14,
            "&::marker": {
              fontSize: 12,
            },
            "&.bad": {
              color: theme.palette.error.main,
              fontWeight: "bold",
              "&:not(.special)::after": {
                content: "'X'",
                padding: "0 3px",
              },
            },
            "&.good": {
              color: theme.palette.primary.main,
              fontWeight: "bold",
              "&:not(.special)::after": {
                content: "'O'",
                padding: "0 3px",
              },
            },
            "&.special": {
              listStyleType: "none",
            },
          },
        },
      },
    },
  },
  closeButton: {
    position: "absolute",
    top: 5,
    right: 5,
    zIndex: 2,
    "& > svg": {
      fontSize: "1.75rem",
    },
  },
});

const LoginPage = () => {
  const classes = useStyles(styles);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { loginToken } = useTypedSelector((state) => state.auth);
  const { init, accessURL } = useTypedSelector((state) => state.common);
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [modalOpen, setModalOpen] = useState(false);
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const [accessToken, setAccessToken] = useState("");
  const [tokenData, setTokenData] = useState<TokenData>({
    id: null,
    puid: undefined,
    guid: undefined,
    name: undefined,
    username: undefined,
    group: null,
    image: undefined,
    is_staff: false,
    is_manager: false,
    is_superuser: false,
  });
  const [data, setData] = useState<{ [key: string]: any }>({
    username: "",
    email: "",
    country: "",
  });

  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [error, setError] = useState(false);
  const [errorCode, setErrorCode] = useState(0);

  const langList = useMemo(() => {
    const i18nLangList: Option[] = [];
    if (i18n.options.resources) {
      for (const [key, value] of Object.entries(i18n.options.resources)) {
        i18nLangList.push({ label: String(value.label), value: key });
      }
    }
    return i18nLangList;
  }, []);

  const loginMutation = useMutation(loginAPI, {
    onSuccess: (response) => {
      if (Object.keys(response.data).includes("access_token")) {
        dispatch(setLoginToken(response.data.access_token));
      } else {
        dispatch(
          setErrorText({
            title: t("DIALOG.TITLE.AUTH_SERVER_NOTICE"),
            content: t("DIALOG.404.FAIL_TO_GET_TOKEN"),
          })
        );
      }
    },
    onError: (error) => {
      onError(error);
    },
    onSettled: () => {
      dispatch(loadingOff());
    },
  });
  const findIDMutation = useMutation(findID, {
    onSuccess: (response) => {
      setAccessToken(response.data.access_token);

      const data = parseJWT(response.data.access_token);
      dispatch(
        setErrorText({
          title: t("DIALOG.TITLE.AUTH_SERVER_NOTICE"),
          content: t("DIALOG.200.SUCCESS_FOUND_USER", {
            username: data["username"],
          }),
        })
      );
    },
    onError: (error) => {
      onError(error);
    },
    onSettled: () => {
      dispatch(loadingOff());
    },
  });
  const findPWDMutation = useMutation(findPWD, {
    onSuccess: (response) => {
      setAccessToken(response.data.access_token);
      setPasswordModalOpen(true);
      setModalOpen(false);
    },
    onError: (error) => {
      onError(error);
    },
    onSettled: () => {
      dispatch(loadingOff());
    },
  });
  const passwordMutation = useMutation(patchUserInfo, {
    onSuccess: () => {
      dispatch(setLoginToken(""));
      dispatch(
        setErrorText({
          title: t("DIALOG.TITLE.AUTH_SERVER_NOTICE"),
          content: t("DIALOG.200.SUCCESS_CHANGE_PASSWORD"),
        })
      );
      setPasswordModalOpen(false);
    },
    onError: (error) => {
      dispatch(setLoginToken(""));
      onError(error);
    },
    onSettled: () => {
      dispatch(loadingOff());
    },
  });

  const getClassName = (code: number) => {
    let result = "";

    if (isNotNull(newPassword) && isNotNull(newPasswordConfirm)) {
      if (newPassword === newPasswordConfirm) {
        if (errorCode & code) {
          return "bad";
        } else {
          return "good";
        }
      }
    }
    return result;
  };

  const handleOnSearch = (type: string) => {
    if (type === "PWD" && isNull(data["username"])) {
      enqueueSnackbar(t("USER.MSG.INPUT_USERNAME"), { variant: "warning" });
      return;
    }
    if (isNull(data["country"])) {
      enqueueSnackbar(t("USER.MSG.INPUT_COUNTRY"), { variant: "warning" });
      return;
    }
    if (isNull(data["email"])) {
      enqueueSnackbar(t("USER.MSG.INPUT_EMAIL"), { variant: "warning" });
      return;
    }

    if (type === "ID") {
      dispatch(loadingOn());
      findIDMutation.mutate({
        data: {
          country: data["country"],
          email: data["email"],
        },
      });
    } else if (type === "PWD") {
      dispatch(loadingOn());
      findPWDMutation.mutate({
        data: {
          username: data["username"],
          country: data["country"],
          email: data["email"],
        },
      });
    }
  };
  const handleOnSubmit = () => {
    if (isNull(username) || isNull(password)) {
      dispatch(
        setErrorText({
          title: t("DIALOG.404.FAIL_TO_LOGIN"),
          content: t("DIALOG.CONTENT.INPUT_ID_PASSWORD"),
        })
      );
    } else {
      dispatch(loadingOn());
      loginMutation.mutate({
        type: "WEB",
        username: username,
        password: password,
      });
    }
  };
  const handlePasswordOnBlur = () => {
    if (isNotNull(newPassword) && isNotNull(newPasswordConfirm)) {
      if (newPassword === newPasswordConfirm) {
        setError(false);
      } else {
        setError(true);
      }
    } else {
      setError(false);
    }
  };
  const handlePasswordOnClick = () => {
    if (isNull(newPassword)) {
      enqueueSnackbar(t("USER.MSG.INPUT_NEW_PASSWORD"), { variant: "warning" });
      return;
    }
    if (isNull(newPasswordConfirm)) {
      enqueueSnackbar(t("USER.MSG.INPUT_NEW_PASSWORD_CONFIRM"), {
        variant: "warning",
      });
      return;
    }
    if (error || errorCode !== 0) {
      enqueueSnackbar(t("USER.MSG.NOT_VALID_PASSWORD"), { variant: "error" });
      return;
    }

    dispatch(setLoginToken(accessToken));
    if (tokenData["puid"] !== undefined) {
      dispatch(loadingOn());
      passwordMutation.mutate({
        puid: tokenData["puid"],
        data: {
          password: "Autos_KoreaN@.1!@#$%",
          newPassword: newPassword,
        },
      });
    }
  };

  useEffect(() => {
    setData({
      username: "",
      email: "",
      country: "",
    });
    setNewPassword("");
    setNewPasswordConfirm("");
  }, [modalOpen]);

  useEffect(() => {
    if (isNull(accessToken)) {
      setTokenData({
        id: null,
        puid: undefined,
        guid: undefined,
        name: undefined,
        username: undefined,
        group: null,
        image: undefined,
        is_staff: false,
        is_manager: false,
        is_superuser: false,
      });
    } else {
      setTokenData(parseJWT(accessToken));
    }
  }, [accessToken]);

  useEffect(() => {
    setData({ ...data, username: tokenData["username"] });
  }, [tokenData]);

  useEffect(() => {
    if (init && isNotNull(loginToken)) {
      if (isNotNull(accessURL)) {
        navigate(accessURL);
        dispatch(setAccessURL(""));
      } else {
        navigate("/");
      }
    }
  }, [init, loginToken]);

  useEffect(() => {
    if (isNotNull(newPassword) && isNotNull(newPasswordConfirm)) {
      if (newPassword === newPasswordConfirm) {
        setErrorCode(validatePassword(newPassword));
      } else {
        setErrorCode(0);
      }
    } else {
      setErrorCode(0);
    }
  }, [newPassword, newPasswordConfirm]);

  return isNull(loginToken) ? (
    <BasePage isHeader={false} isFooter={false} className={classes.basePage}>
      <Paper classes={{ root: classes.paper }} square={true}>
        <CompanyLogoIcon
          className={classes.logo}
          onClick={() => navigate("/")}
        />
        <Input
          className={classes.input}
          variant="standard"
          placeholder="ID"
          value={username}
          dispatch={setUsername}
        />
        <Input
          className={classes.input}
          type="password"
          variant="standard"
          placeholder="PASSWORD"
          value={password}
          dispatch={setPassword}
        />
        <Button
          classes={{ root: classes.button }}
          variant="contained"
          onClick={handleOnSubmit}
        >
          {t("HEADER.LOGIN")}
        </Button>
        <div className={classes.content}>
          <ul className={classes.ul}>
            <li>
              <div onClick={() => setModalOpen(true)}>
                {t("HEADER.FIND_ID_PW")}
              </div>
            </li>
            <li>
              <div onClick={() => navigate("/join")}>{t("HEADER.JOIN")}</div>
            </li>
          </ul>
          <Dropdown
            value={i18n.language}
            options={langList}
            dispatch={i18n.changeLanguage}
            formControlClassName={classes.dropdownFormControl}
            selectClassName={classes.dropdownSelect}
            menuItemClassName={classes.dropdownMenuItem}
          />
        </div>
      </Paper>
      <Modal className={classes.modal} open={modalOpen} disableAutoFocus={true}>
        <Paper square={true}>
          <IconButton
            className={classes.closeButton}
            onClick={() => setModalOpen(false)}
          >
            <CloseIcon />
          </IconButton>
          <Tabs
            value={tabValue}
            onChange={(_e, newValue) => setTabValue(newValue)}
          >
            <Tab label={t("BUTTON.SEARCH_ID")} />
            <Tab label={t("BUTTON.SEARCH_PWD")} />
          </Tabs>
          <div hidden={tabValue !== 0}>
            {tabValue === 0 && (
              <>
                <Typography component="h1">
                  <Markup content={t("USER.MSG.INPUT_JOIN_INFO")} />
                </Typography>
                <Typography>Country</Typography>
                <NationAutocomplete
                  variant="standard"
                  value={data["country"]}
                  dispatch={(value: string) => {
                    setData({
                      ...data,
                      country: value,
                    });
                  }}
                  fullWidth
                />
                <Typography component="h3">
                  {t("USER.MSG.SELECT_PHONE_CODE")}
                </Typography>
                <Typography>Email Address</Typography>
                <Input
                  variant="standard"
                  value={data["email"]}
                  dispatch={(value: string) => {
                    setData({
                      ...data,
                      email: String(value).trim(),
                    });
                  }}
                  placeholder="example@autoskorea.com"
                  fullWidth
                  inputProps={{
                    maxLength: 64,
                  }}
                />
                <Button
                  variant="contained"
                  onClick={() => handleOnSearch("ID")}
                >
                  {t("BUTTON.SEARCH_ID")}
                </Button>
              </>
            )}
          </div>
          <div hidden={tabValue !== 1}>
            {tabValue === 1 && (
              <>
                <Typography component="h1">
                  <Markup content={t("USER.MSG.INPUT_JOIN_INFO")} />
                </Typography>
                <Typography>ID</Typography>
                <Input
                  variant="standard"
                  value={data["username"]}
                  dispatch={(value: string) => {
                    setData({
                      ...data,
                      username: String(value).trim(),
                    });
                  }}
                  fullWidth
                  inputProps={{
                    maxLength: 32,
                  }}
                />
                <Typography>Country</Typography>
                <NationAutocomplete
                  variant="standard"
                  value={data["country"]}
                  dispatch={(value: string) => {
                    setData({
                      ...data,
                      country: value,
                    });
                  }}
                  fullWidth
                />
                <Typography component="h3">
                  {t("USER.MSG.SELECT_PHONE_CODE")}
                </Typography>
                <Typography>Email Address</Typography>
                <Input
                  variant="standard"
                  value={data["email"]}
                  dispatch={(value: string) => {
                    setData({
                      ...data,
                      email: String(value).trim(),
                    });
                  }}
                  placeholder="example@autoskorea.com"
                  fullWidth
                  inputProps={{
                    maxLength: 64,
                  }}
                />
                <Button
                  variant="contained"
                  onClick={() => handleOnSearch("PWD")}
                >
                  {t("BUTTON.SEARCH_PWD")}
                </Button>
              </>
            )}
          </div>
        </Paper>
      </Modal>
      <Modal
        className={classes.modal}
        open={passwordModalOpen}
        disableAutoFocus={true}
      >
        <Paper square={true}>
          <IconButton
            className={classes.closeButton}
            onClick={() => setPasswordModalOpen(false)}
          >
            <CloseIcon />
          </IconButton>
          <div>
            <Typography component="h1">
              {t("USER.MSG.INPUT_NEW_PASSWORD")}
            </Typography>
            <Input
              type="password"
              variant="standard"
              placeholder={t("USER.NEW_PASSWORD")}
              error={error || errorCode !== 0}
              value={newPassword}
              dispatch={setNewPassword}
              onBlur={() => handlePasswordOnBlur()}
              fullWidth
              inputProps={{
                maxLength: 32,
              }}
            />
            <Input
              type="password"
              variant="standard"
              placeholder={t("USER.NEW_PASSWORD_CONFIRM")}
              error={error || errorCode !== 0}
              helperText={error ? t("USER.MSG.PASSWORD_ERROR") : ""}
              value={newPasswordConfirm}
              dispatch={setNewPasswordConfirm}
              onBlur={() => handlePasswordOnBlur()}
              fullWidth
              inputProps={{
                maxLength: 32,
              }}
            />
            <Typography component="h2">
              {t("USER.PASSWORD_CONDITION.TITLE")}
            </Typography>
            <ul>
              <li className={getClassName(1)}>
                {t("USER.PASSWORD_CONDITION.CONTENT_1")}
              </li>
              <li className={getClassName(2)}>
                {t("USER.PASSWORD_CONDITION.CONTENT_2")}
              </li>
              <li className={getClassName(4)}>
                {t("USER.PASSWORD_CONDITION.CONTENT_3")}
              </li>
              <li className={getClassName(8)}>
                {t("USER.PASSWORD_CONDITION.CONTENT_4")}
              </li>
              <li className={`${getClassName(8)} special`}>
                `~!@#$%^&*()-_=+{}[]:;'"
              </li>
            </ul>
            <Button variant="contained" onClick={() => handlePasswordOnClick()}>
              {t("BUTTON.MODIFY")}
            </Button>
          </div>
        </Paper>
      </Modal>
    </BasePage>
  ) : (
    <></>
  );
};

export default LoginPage;
