import {
  INIT_WISH_LIST,
  PUSH_WISH_LIST,
  POP_WISH_LIST,
} from "../types/globalType";

export const initWishList = () => ({
  type: INIT_WISH_LIST,
});

// Action Functions
export const pushWishList = (wish_id: number) => ({
  type: PUSH_WISH_LIST,
  payload: wish_id,
});

export const popWishList = (wish_id: number) => ({
  type: POP_WISH_LIST,
  payload: wish_id,
});
