/* eslint-disable no-eval */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
// Components
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Theme,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// Libs
import { getType, isNotNull } from "@/common/lib/common";

const styles = (theme: Theme, color: string) => ({
  root: {
    "& > .MuiInputBase-root": {
      width: "inherit",
      height: "inherit",
      fontSize: "inherit",
      borderRadius: "0.35em !important",
      "& > .MuiInputBase-input": {
        "&::-ms-clear": {
          display: "none",
          width: 0,
          height: 0,
        },
        "&::-ms-reveal": {
          display: "none",
          width: 0,
          height: 0,
        },
        "&::-webkit-search-decoration": {
          display: "none",
        },
        "&::-webkit-search-cancel-button": {
          display: "none",
        },
        "&::-webkit-search-results-button": {
          display: "none",
        },
        "&::-webkit-search-results-decoration": {
          display: "none",
        },
      },
      "& > .MuiInputAdornment-root": {
        "& > .MuiSvgIcon-root": {
          position: "absolute",
          top: "calc(50% - .5em)",
          right: ".38em",
          "&:hover": {
            cursor: "pointer",
            color: eval(`theme.palette.${color}.main`),
          },
        },
        "& > .MuiTypography-root": {
          color: theme.palette.text.primary,
        },
      },
      "& > .MuiSelect-select": {
        paddingRight: "calc(8px + 1.5em) !important",
      },
      "& > .MuiSvgIcon-root": {
        color: theme.palette.text.primary,
      },
      "&.MuiInputBase-adornedEnd": {
        padding: 0,
        "& > .MuiSelect-icon": {
          display: "none",
        },
      },
      "&::before": {
        borderBottom: "1px solid " + theme.palette.text.primary,
      },
      "&::after": {
        borderBottom: "1px solid " + eval(`theme.palette.${color}.main`),
      },
      "&:not(.Mui-error).Mui-focused": {
        color: eval(`theme.palette.${color}.main`),
        "& > .MuiSvgIcon-root": {
          color: eval(`theme.palette.${color}.main`),
        },
      },
      "&.Mui-error": {
        color: theme.palette.error.main,
        "& > .MuiSvgIcon-root": {
          color: theme.palette.error.main,
        },
      },
    },
    "& > .MuiOutlinedInput-root": {
      "& > .MuiOutlinedInput-input": {
        padding: ".6em 1em",
      },
      "& > .MuiOutlinedInput-notchedOutline": {
        fontSize: "inherit",
        padding: "0 .5em",
        borderColor: theme.palette.text.primary,
        "& > legend": {
          fontSize: "inherit",
        },
        "& span": {
          fontSize: "inherit",
          "&.notranslate": {
            padding: 0,
          },
          "&:not(.notranslate)": {
            padding: "0.25em",
          },
        },
      },
      "&.Mui-focused": {
        "& > .MuiOutlinedInput-notchedOutline": {
          borderColor: eval(`theme.palette.${color}.main`) + "!important",
        },
      },
      "&.Mui-error": {
        "& > .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.error.main + "!important",
        },
      },
    },
    "& > .MuiFilledInput-root": {
      "& > .MuiFilledInput-input": {
        padding: ".9em 1em .3em",
      },
    },
    "& > .MuiInput-root": {
      marginTop: 0,
      padding: 0,
      "& > .MuiInput-input": {
        padding: ".9em 0 .3em",
      },
      "& > .MuiSvgIcon-root": {
        right: 7,
      },
    },
    "& > .MuiInputLabel-root": {
      height: "inherit",
      fontSize: "inherit",
      color: theme.palette.text.primary,
      "&.Mui-focused:not(.Mui-error)": {
        color: eval(`theme.palette.${color}.main`),
      },
      "&.Mui-error": {
        color: theme.palette.error.main,
      },
    },
    "& > .MuiInputLabel-outlined": {
      transform: "translate(1em, .6em) scale(1)",
      "&.MuiInputLabel-shrink": {
        transform: "translate(1em, -.6em) scale(0.75)",
      },
    },
    "& > .MuiInputLabel-filled": {
      transform: "translate(1em, .6em) scale(1)",
      "&.MuiInputLabel-shrink": {
        transform: "translate(1em, 0) scale(0.75)",
      },
    },
    "& > .MuiInputLabel-standard": {
      transform: "translate(0, .6em) scale(1)",
      "&.MuiInputLabel-shrink": {
        transform: "translate(0, 0) scale(0.75)",
      },
    },
    "&.Size-tiny": {
      fontSize: "0.625rem",
      "& .MuiSvgIcon-root": {
        fontSize: "0.9375rem",
      },
    },
    "&.Size-small": {
      fontSize: "0.75rem",
      "& .MuiSvgIcon-root": {
        fontSize: "1.125rem",
      },
    },
    "&.Size-medium": {
      fontSize: "0.875rem",
      "& .MuiSvgIcon-root": {
        fontSize: "1.3125rem",
      },
    },
    "&.Size-large": {
      fontSize: "1.125rem",
      "& .MuiSvgIcon-root": {
        fontSize: "1.6875rem",
      },
    },
    "&.Size-huge": {
      fontSize: "1.375rem",
      "& .MuiSvgIcon-root": {
        fontSize: "2.0625rem",
      },
    },
    "&&:hover": {
      "& > .MuiInputLabel-root:not(.Mui-error)": {
        color: eval(`theme.palette.${color}.main`),
      },
      "& > .MuiInputLabel-root.Mui-error": {
        color: theme.palette.error.main,
      },
      "& > .MuiInputBase-root:not(.Mui-error)": {
        color: eval(`theme.palette.${color}.main`),
        "& > .MuiInputBase-input": {
          color: eval(`theme.palette.${color}.main`),
        },
        "& > .MuiOutlinedInput-notchedOutline": {
          borderColor: eval(`theme.palette.${color}.main`),
        },
        "&::before": {
          borderBottom: "1px solid " + eval(`theme.palette.${color}.main`),
        },
        "& > .MuiSvgIcon-root": {
          cursor: "pointer",
          color: eval(`theme.palette.${color}.main`) + "!important",
        },
      },
      "& > .MuiInputBase-root.Mui-error": {
        color: theme.palette.error.main,
        "& > .MuiInputBase-input": {
          color: theme.palette.error.main,
        },
        "& > .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.error.main,
        },
        "&::before": {
          borderBottom: "1px solid " + theme.palette.error.main,
        },
        "& > .MuiSvgIcon-root": {
          cursor: "pointer",
          color: theme.palette.error.main,
        },
      },
    },
  },
  menuItem: {
    minHeight: "unset",
    "&.MuiMenuItem-root": {
      "&:hover": {
        color: eval(`theme.palette.${color}.main`),
      },
    },
    "&.Mui-selected": {
      color: eval(`theme.palette.${color}.main`),
    },
  },
});

const Dropdown = ({
  id,
  label,
  labelId,
  size = "medium",
  color = "primary",
  value,
  dispatch,
  options = [],
  isClearable = false,
  multiple,
  helperText,
  formControlClassName,
  inputLabelClassName,
  selectClassName,
  menuItemClassName,
  ...props
}: any) => {
  const onInit = () => {
    return multiple !== undefined ? [] : "";
  };
  const onClose = () => {
    dispatch !== undefined ? dispatch(onInit()) : setInputValue(onInit());
  };
  const theme = useTheme();
  const classes = useStyles(styles(theme, color));

  const randNumber = Math.floor(Math.random() * 100000000000);
  const [inputValue, setInputValue] = useState<string | string[]>(onInit());

  const getClassName = () => {
    const results: string[] = [];
    if (size === "tiny") {
      results.push("Size-tiny");
    } else if (size === "small") {
      results.push("Size-small");
    } else if (size === "medium") {
      results.push("Size-medium");
    } else if (size === "large") {
      results.push("Size-large");
    } else if (size === "huge") {
      results.push("Size-huge");
    }
    return results.join(" ");
  };

  return (
    <FormControl
      classes={{
        root: classes.root,
      }}
      className={
        formControlClassName
          ? `${getClassName()} ${formControlClassName}`
          : getClassName()
      }
      {...props}
    >
      {label && (
        <InputLabel
          id={isNotNull(labelId) ? labelId : "Dropdown-Label-" + randNumber}
          className={inputLabelClassName}
        >
          {label}
        </InputLabel>
      )}
      <Select
        id={isNotNull(id) ? id : "Dropdown-" + randNumber}
        label={label}
        labelId={isNotNull(labelId) ? labelId : "Dropdown-Label-" + randNumber}
        value={value !== undefined ? value : inputValue}
        onChange={(e) => {
          if (multiple) {
            const filterValue = e.target.value.filter(
              (value: string) => value !== ""
            );
            dispatch !== undefined
              ? dispatch(filterValue)
              : setInputValue(filterValue);
          } else {
            dispatch !== undefined
              ? dispatch(e.target.value)
              : setInputValue(e.target.value);
          }
        }}
        multiple={multiple}
        endAdornment={
          (value !== undefined
            ? getType(value) === "Array"
              ? value.length !== 0
              : isNotNull(value)
            : getType(inputValue) === "Array"
            ? inputValue.length !== 0
            : isNotNull(inputValue)) && isClearable ? (
            <InputAdornment position="end">
              <CloseIcon onClick={onClose} />
            </InputAdornment>
          ) : undefined
        }
        className={selectClassName}
        inputProps={{
          name: isNotNull(id) ? id : "Dropdown-" + randNumber,
        }}
      >
        {options
          .filter(
            (option: Option) => !multiple || (multiple && option.value !== "")
          )
          .map((option: Option, idx: number) => {
            return (
              <MenuItem
                key={idx}
                value={option.value}
                className={
                  menuItemClassName
                    ? `${classes.menuItem} ${menuItemClassName}`
                    : classes.menuItem
                }
              >
                {option.label}
              </MenuItem>
            );
          })}
      </Select>
      {isNotNull(helperText) && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default Dropdown;
