import { useTranslation } from "react-i18next";
// Components
import { Grid, Theme, Typography } from "@mui/material";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// Libs
import { isNotNull } from "@/common/lib/common";

const styles = (theme: Theme) => ({
  container: {
    marginBottom: "2.5vh",
    padding: "1vh 2vw",
    borderTop: "2px solid " + theme.palette.text.primary,
    borderBottom: "2px solid " + theme.palette.text.primary,
    minHeight: "15vh",
    whiteSpace: "pre",
  },
  title: {
    fontSize: "1.25rem",
    fontWeight: "bold",
    userSelect: "none",
    marginBottom: "1vh",
  },
});

interface propType {
  data: VehicleData;
}

const CommentContainer = ({ data }: propType) => {
  const classes = useStyles(styles);
  const { t } = useTranslation();

  return (
    <>
      {data !== undefined && isNotNull(data.sales.advert_explain) && (
        <>
          <Typography className={classes.title}>
            {t("DETAIL.COMMENT.TITLE")}
          </Typography>
          <Grid container className={classes.container}>
            <Grid item xs={12}>
              {data.sales.advert_explain}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default CommentContainer;
