/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
// Redux
import { useTypedSelector } from "@/common/lib/redux/store";
// Components
import Slider from "react-slick";
import { IconButton, Theme, Typography } from "@mui/material";
import { ProductCarousel } from "@/browser/components/index";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// Libs
import { usePrevious } from "@/common/lib/hooks";

const styles = (theme: Theme) => ({
  subTitle: {
    fontSize: 17,
    fontWeight: 500,
    fontStyle: "Italic",
    color: theme.palette.primary.main,
    lineHeight: "1.25",
    letterSpacing: "0.5px",
    cursor: "default",
    userSelect: "none",
  },
  title: {
    fontSize: 24,
    fontWeight: 500,
    color: theme.palette.primary.main,
    lineHeight: "1.25",
    cursor: "default",
    userSelect: "none",
    marginBottom: 10,
  },
  titleContainer: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
  container: {
    marginBottom: 40,
    padding: "10px 0",
    borderTop: "1px solid " + theme.palette.divider,
    borderBottom: "1px solid " + theme.palette.divider,
  },
});

const RecommendListContainer = () => {
  const classes = useStyles(styles);
  const { t } = useTranslation();
  const { params } = useTypedSelector((state) => state.search);
  const prevParams = usePrevious(params);
  const [recommendData, setRecommendData] = useState<CarouselDataType[]>([
    {
      key: "recommendData",
      page: 1,
      size: 32,
      align: "-modified",
      params: {
        ...params,
        status: "ON,REZ",
        owner: "1",
      },
    },
  ]);
  const [data, setData] = useState<VehicleData[]>([]);

  const carouselRef = useRef<Slider>(null);

  useEffect(() => {
    if (
      prevParams !== undefined &&
      JSON.stringify(params) !== JSON.stringify(prevParams)
    ) {
      setRecommendData([
        {
          key: "recommendData",
          page: 1,
          size: 32,
          align: "-modified",
          params: {
            ...params,
            status: "ON,REZ",
            owner: "1",
          },
        },
      ]);
    }
  }, [params]);

  const handlePrevOnClick = () => {
    if (carouselRef.current !== null) {
      carouselRef.current.slickPrev();
    }
  };
  const handleNextOnClick = () => {
    if (carouselRef.current !== null) {
      carouselRef.current.slickNext();
    }
  };

  return (
    <div style={data.length > 0 ? {} : { display: "none" }}>
      <div className={classes.titleContainer}>
        <div>
          <Typography className={classes.subTitle}>
            {t("LIST.RECOMMEND.AUTOLEADER'S")}
          </Typography>
          <Typography className={classes.title}>
            {t("LIST.RECOMMEND.TITLE")}
          </Typography>
        </div>
        <div>
          <IconButton onClick={() => handlePrevOnClick()}>
            <NavigateBeforeIcon />
          </IconButton>
          <IconButton onClick={() => handleNextOnClick()}>
            <NavigateNextIcon />
          </IconButton>
        </div>
      </div>
      <div className={classes.container}>
        <ProductCarousel
          ref={carouselRef}
          arrows={false}
          rows={2}
          data={recommendData}
          setData={setData}
        />
      </div>
    </div>
  );
};

export default RecommendListContainer;
