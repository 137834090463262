import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
// Components
import { Theme, Typography } from "@mui/material";
// Styles
import { useStyles } from "@/common/lib/style/hooks";

const styles = (theme: Theme) => ({
  container: {
    userSelect: "none",
    display: "flex",
    alignItems: "flex-start",
    marginBottom: "3vh",
  },
  title: {
    fontSize: "1.25rem",
    fontWeight: "bold",
    userSelect: "none",
    marginBottom: "1vh",
    color: theme.palette.error.main,
  },
  content: {
    margin: 0,
    paddingLeft: "6vw",
    "& > li": {
      margin: "0.5vh 0",
      fontSize: "0.875rem",
      fontWeight: 500,
      "&::marker": {
        fontSize: "0.75rem",
      },
    },
  },
});

const NoticeContainer = () => {
  const classes = useStyles(styles);
  const { t } = useTranslation();

  return (
    <>
      <Typography className={classes.title}>
        {t("DETAIL.NOTICE.TITLE")}
      </Typography>
      <div className={classes.container}>
        <ul className={classes.content}>
          <li>
            <Markup content={t("DETAIL.NOTICE.MSG.NOTICE_1_MOBILE")} />
          </li>
          <li>
            <Markup content={t("DETAIL.NOTICE.MSG.NOTICE_2_MOBILE")} />
          </li>
          <li>
            <Markup content={t("DETAIL.NOTICE.MSG.NOTICE_3")} />
          </li>
          <li>
            <Markup content={t("DETAIL.NOTICE.MSG.NOTICE_4_MOBILE")} />
          </li>
          <li>
            <Markup content={t("DETAIL.NOTICE.MSG.NOTICE_5")} />
          </li>
          <li>
            <Markup content={t("DETAIL.NOTICE.MSG.NOTICE_6")} />
          </li>
        </ul>
      </div>
    </>
  );
};

export default NoticeContainer;
