/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// Components
import { Typography } from "@mui/material";
import { ProductCarousel } from "@/browser/components/index";
// Styles
import { useStyles } from "@/common/lib/style/hooks";

const styles = () => ({
  container: {
    userSelect: "none",
  },
  title: {
    fontSize: "1.65rem",
    fontWeight: "bold",
    userSelect: "none",
    marginBottom: 15,
  },
});

interface propType {
  data: VehicleData;
}

const RecommendContainer = ({ data }: propType) => {
  const classes = useStyles(styles);
  const { t } = useTranslation();

  const [recommendData, setRecommendData] = useState<CarouselDataType[]>([]);

  useEffect(() => {
    if (data !== undefined) {
      setRecommendData([
        {
          key: "recommendDetail",
          page: 1,
          size: 12,
          align: "-modified",
          params: {
            status: "ON",
            model: data.product.model,
            ex_uid: data.product.uid,
          },
        },
      ]);
    }
  }, [data]);

  return (
    <>
      {data !== undefined && (
        <>
          <Typography className={classes.title}>
            {t("DETAIL.RECOMMEND.TITLE")}
          </Typography>
          <ProductCarousel defaults={true} rows={1} data={recommendData} />
        </>
      )}
    </>
  );
};

export default RecommendContainer;
