/* eslint-disable react-hooks/exhaustive-deps */
// Components
import { Card, CardContent, Skeleton, Theme } from "@mui/material";
// Styles
import { useStyles } from "@/common/lib/style/hooks";

const styles = (theme: Theme) => ({
  card: {
    width: "100%",
    boxSizing: "border-box",
    border: "1px solid " + theme.palette.divider,
    borderRadius: "10px !important",
    boxShadow: "0 0 5px " + theme.palette.divider + " !important",
    position: "relative",
    "&.SLIDE-SHOW-1": {
      fontSize: "1rem",
      lineHeight: "1.25rem",
    },
    "&.SLIDE-SHOW-2": {
      fontSize: "0.75rem",
      lineHeight: "0.9375rem",
    },
    "&.SLIDE-SHOW-3": {
      fontSize: "0.5rem",
      lineHeight: "0.626rem",
    },
  },
  imageContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box",
    padding: "0 !important",
    marginBottom: "0.5em",
  },
  image: {
    width: "100%",
    height: "unset",
    aspectRatio: "1.33",
  },
  cardContent: {
    height: "6em",
    position: "relative",
    boxSizing: "border-box",
    padding: "0 0.75em 0.75em !important",
  },
  cardTitle: {
    maxHeight: "2.5em",
    fontSize: "1em",
    lineHeight: 1.25,
    fontWeight: "bold",
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordSpacing: "-0.05rem",
  },
  cardInfo: {
    "& > ul": {
      margin: 0,
      padding: 0,
      listStyle: "none",
      lineHeight: 1,
      maxHeight: "2.5em",
      overflow: "hidden",
      "& > li:first-child": {
        marginLeft: 0,
      },
      "& > li:last-child": {
        marginRight: 0,
      },
      "& > li": {
        display: "inline-block",
        margin: "0 0.15em",
        wordSpacing: "-0.05em",
        fontSize: "0.75em",
      },
    },
  },
  statusInfo: {
    position: "absolute",
    left: "0.75em",
    bottom: "0.75em",
    width: "4.5em",
    height: "1.5em",
    borderRadius: "0.35em",
  },
  priceInfo: {
    position: "absolute",
    right: "0.75em",
    bottom: "0.75em",
    width: "6em",
    height: "1.5em",
    borderRadius: "0.35em",
  },
});

interface propType {
  slidesToShow?: number;
}

const CarouselListItemSkeleton = ({ slidesToShow = 1 }: propType) => {
  const classes = useStyles(styles);

  return (
    <Card className={`${classes.card}  SLIDE-SHOW-${slidesToShow}`}>
      <CardContent className={classes.imageContent}>
        <Skeleton
          classes={{ root: classes.image }}
          animation="wave"
          variant="rectangular"
        />
      </CardContent>
      <CardContent className={classes.cardContent}>
        <Skeleton
          className={classes.cardTitle}
          animation="wave"
          variant="rectangular"
        />
        <Skeleton
          className={classes.cardInfo}
          animation="wave"
          variant="rectangular"
        />
        <Skeleton
          className={classes.statusInfo}
          animation="wave"
          variant="rectangular"
        />
        <Skeleton
          className={classes.priceInfo}
          animation="wave"
          variant="rectangular"
        />
      </CardContent>
    </Card>
  );
};

export default CarouselListItemSkeleton;
