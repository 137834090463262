/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useTranslation } from "react-i18next";
// Redux
import { useTypedDispatch, useTypedSelector } from "@/common/lib/redux/store";
import {
  pushWishList,
  popWishList,
} from "@/common/lib/redux/actions/globalAction";
// Components
import {
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton,
  Theme,
} from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { Button } from "@/common/components";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// API
import { onError } from "@/common/lib/api/common";
import {
  getWishInfo,
  postWishInfo,
  deleteWishInfo,
} from "@/common/lib/api/wish";

// Libs
import { isNull, isNotNull } from "@/common/lib/common";
import { isBrowser } from "react-device-detect";

const styles = (theme: Theme) => ({
  root: {
    zIndex: 3,
    "& > svg.on": {
      color: theme.palette.error.main,
    },
  },
  dialog: {
    maxWidth: 900,
    width: "fit-content",
    height: "fit-content",
    padding: isBrowser ? "12px 36px" : "1vh 6vw",
    borderRadius: isBrowser ? "10px" : "3.5vw",
    userSelect: "none",
  },
  dialogTitle: {
    fontSize: "1.35rem",
    fontWeight: "bold",
    padding: isBrowser ? "12px 24px" : "1vh 4vw",
    wordBreak: "keep-all",
  },
  dialogActions: {
    padding: 0,
    "& > button": {
      fontSize: "1rem",
    },
  },
});

// wish_id : 초기 값(LIST 목록에서 wish 값을 로드하여 입력하는 경우)
// product_uid : 필수 값
const FavoriteButton = ({
  confirm = false,
  wish_id = null,
  product_uid,
  ...props
}: any) => {
  const classes = useStyles(styles);
  const { t } = useTranslation();
  const { loginToken } = useTypedSelector((state) => state.auth);
  const { wishList } = useTypedSelector((state) => state.global);
  const dispatch = useTypedDispatch();
  const [wishId, setWishId] = useState<number | null>(wish_id);

  const [init, setInit] = useState(false);
  const [newWishList, setNewWishList] = useState<number[]>([]);

  const [dialogOpen, setDialogOpen] = useState(false);

  const getWishInfoQuery = useQuery(
    ["wishInfo", product_uid],
    () => {
      return getWishInfo("PRODUCT_UID", product_uid);
    },
    {
      enabled: false,
      retry: false,
      onSuccess: (response) => {
        setWishId(response.data["id"]);
      },
      onError: (error) => {
        onError(error);
      },
    }
  );
  const postWishMutation = useMutation(postWishInfo, {
    onSuccess: (response) => {
      if (wishId === null && !wishList.includes(product_uid)) {
        dispatch(pushWishList(product_uid));
        window.dispatchEvent(
          new StorageEvent("storage", {
            newValue: JSON.stringify({ wishList: [...wishList, product_uid] }),
          })
        );
      }
      setWishId(response.data);
    },
    onError: (error) => {
      onError(error);
    },
  });
  const deleteWishMutaion = useMutation(deleteWishInfo, {
    onSuccess: () => {
      if (wishList.includes(product_uid)) {
        dispatch(popWishList(product_uid));
      }
      if (wishId !== null) {
        setWishId(null);
      }
    },
    onError: (error) => {
      onError(error);
    },
  });

  const handleOnClick = (e: any) => {
    e.stopPropagation();

    if (confirm) {
      setDialogOpen(true);
    } else {
      if (init && isNotNull(loginToken)) {
        if (wishId === null) {
          postWishMutation.mutate({ uid: product_uid });
        } else {
          deleteWishMutaion.mutate({ id: wishId });
        }
      }
    }
  };

  useEffect(() => {
    setInit(true);
    if (wishId !== null && !wishList.includes(product_uid)) {
      dispatch(pushWishList(product_uid));
    }

    const storageListener = (event: StorageEvent) => {
      const newValue_String = event.newValue;

      const regex = /^{"wishList":.*/;
      if (newValue_String !== null && regex.test(newValue_String)) {
        const newValue_JSON = JSON.parse(
          newValue_String
            .replaceAll('\\"', '"')
            .replaceAll('"{', "{")
            .replaceAll('}"', "}")
            .replaceAll('"[', "[")
            .replaceAll(']"', "]")
        );
        if (Object.keys(newValue_JSON).includes("wishList")) {
          setNewWishList(newValue_JSON["wishList"]);
        }
      }
    };

    window.addEventListener("storage", storageListener);
    return () => {
      window.removeEventListener("storage", storageListener);
    };
  }, []);

  useEffect(() => {
    if (init && isNotNull(loginToken)) {
      if (wishId !== null) {
        if (!newWishList.includes(product_uid)) {
          setWishId(null);
        }
      } else {
        if (newWishList.includes(product_uid)) {
          getWishInfoQuery.refetch();
        }
      }
    }
  }, [newWishList]);

  return isNotNull(loginToken) ? (
    <>
      <IconButton
        className={
          isNotNull(props.className)
            ? `${classes.root} ${props.className}`
            : `${classes.root}`
        }
        onClick={handleOnClick}
      >
        {isNotNull(wishId) ? (
          <FavoriteIcon className="on" />
        ) : (
          <FavoriteBorderIcon />
        )}
      </IconButton>
      <Dialog
        classes={{ paper: classes.dialog }}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="favorite-confirm-title"
        aria-describedby="favorite-confirm-description"
      >
        <DialogTitle
          id="favorite-confirm-title"
          classes={{ root: classes.dialogTitle }}
        >
          {isNull(wishId)
            ? t("MSG.CONFIRM_ADD_FAVORITE")
            : t("MSG.CONFIRM_DELETE_FAVORITE")}
        </DialogTitle>
        <DialogActions classes={{ root: classes.dialogActions }}>
          <Button
            onClick={(e: any) => {
              e.stopPropagation();
              setDialogOpen(false);
              if (init && isNotNull(loginToken)) {
                if (wishId === null) {
                  postWishMutation.mutate({ uid: product_uid });
                } else {
                  deleteWishMutaion.mutate({ id: wishId });
                }
              }
            }}
          >
            {t("OPTION.YES")}
          </Button>
          <Button
            onClick={(e: any) => {
              e.stopPropagation();
              setDialogOpen(false);
            }}
          >
            {t("OPTION.CANCEL")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  ) : (
    <></>
  );
};

export default FavoriteButton;
