import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
// Components
import { CountUp } from "@/common/components";
import { Theme, Typography } from "@mui/material";
// Styles
import { useStyles } from "@/common/lib/style/hooks";

const styles = (theme: Theme) => ({
  table: {
    borderCollapse: "collapse",
    borderSpacing: 0,
    width: "90%",
    aspectRatio: "1",
    margin: "1.5vh auto",
    "& td": {
      paddingBottom: "1vh",
    },
  },
  typography: {
    textAlign: "center",
    fontSize: "0.85rem",
  },
  borderBottom: {
    borderBottom: "1px solid " + theme.palette.divider,
  },
  borderRight: {
    borderRight: "1px solid " + theme.palette.divider,
  },
});

const AchieveContainer = () => {
  const classes = useStyles(styles);
  const { t } = useTranslation();

  return (
    <table className={classes.table}>
      <colgroup>
        <col style={{ width: "50%" }} />
        <col style={{ width: "50%" }} />
      </colgroup>
      <thead></thead>
      <tbody>
        <tr className={classes.borderBottom}>
          <td className={classes.borderRight}>
            <div>
              <CountUp start={0} end={155} />
              <Typography className={classes.typography}>
                <Markup content={t("PROFILE.ACHIEVE.SALES")} />
              </Typography>
            </div>
          </td>
          <td>
            <div>
              <CountUp start={0} end={3871} />
              <Typography className={classes.typography}>
                <Markup content={t("PROFILE.ACHIEVE.EXPORT")} />
              </Typography>
            </div>
          </td>
        </tr>
        <tr>
          <td className={classes.borderRight}>
            <div>
              <CountUp start={0} end={4359} />
              <Typography className={classes.typography}>
                <Markup content={t("PROFILE.ACHIEVE.IMPORT")} />
              </Typography>
            </div>
          </td>
          <td>
            <div>
              <CountUp start={0} end={467038} />
              <Typography className={classes.typography}>
                <Markup content={t("PROFILE.ACHIEVE.KOREA_EXPORT")} />
              </Typography>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default AchieveContainer;
