import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
// Components
import { Divider, Theme, Typography } from "@mui/material";
import {
  AirconIcon,
  AudioIcon,
  BrakeIcon,
  ChassisIcon,
  EngineIcon,
  ExhuastIcon,
  HeaterIcon,
  MissionIcon,
  StarIcon,
  SteeringIcon,
  WindowIcon,
} from "@/common/icons";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
import { colors } from "@/common/lib/style/palette";

const styles = (theme: Theme) => ({
  container: {
    userSelect: "none",
    marginTop: 15,
    marginBottom: 40,
    padding: "0 25px",
  },
  title: {
    fontSize: "1.65rem",
    fontWeight: "bold",
    userSelect: "none",
    marginBottom: 10,
  },
  notice: {
    fontSize: "0.9rem",
    color: theme.palette.text.primary,
    textAlign: "right",
  },
  report: {
    display: "flex",
    "& > div": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "20%",
      "& > svg": {
        width: 45,
        height: 45,
        margin: 7.5,
        fill: theme.palette.text.primary,
        "&.heater": {
          fill: colors.red[5],
        },
        "&.aircon": {
          fill: colors.blue[5],
        },
      },
      "& > p": {
        fontSize: 16,
        fontWeight: "bold",
        textAlign: "center",
      },
      "& > span": {
        display: "flex",
        alignItems: "center",
        fontSize: 16,
        fontWeight: "bold",
        "& > svg": {
          width: 16,
          height: 16,
          fill: colors.yellow[6],
          stroke: colors.orange[3],
          strokeWidth: 450,
          marginRight: 2.5,
        },
      },
      "& > div": {
        fontSize: 14,
        fontWeight: "bold",
        textAlign: "center",
      },
    },
  },
  divider: {
    margin: "10px 0",
  },
});

interface propType {
  data: VehicleData;
}

const ConditionReport = ({ data }: propType) => {
  const classes = useStyles(styles);
  const { t } = useTranslation();

  return (
    <>
      {data !== undefined && (
        <>
          <Typography className={classes.title}>
            {t("DETAIL.CONDITION.TITLE")}
          </Typography>
          <div className={classes.container}>
            <div className={classes.report}>
              <div>
                <EngineIcon />
                <Typography>{t("VEHICLE.CONDITION.ENGINE")}</Typography>
                <span>
                  <StarIcon />
                  {data.condition.engine.toFixed(1)}
                </span>
              </div>
              <div>
                <MissionIcon />
                <Typography>{t("VEHICLE.CONDITION.MISSION")}</Typography>
                <span>
                  <StarIcon />
                  {data.condition.mission.toFixed(1)}
                </span>
              </div>
              <div>
                <SteeringIcon />
                <Typography>{t("VEHICLE.CONDITION.STEERING")}</Typography>
                <span>
                  <StarIcon />
                  {data.condition.steering.toFixed(1)}
                </span>
              </div>
              <div>
                <BrakeIcon />
                <Typography>{t("VEHICLE.CONDITION.BRAKE")}</Typography>
                <span>
                  <StarIcon />
                  {data.condition.brake.toFixed(1)}
                </span>
              </div>
              <div>
                <ExhuastIcon />
                <Typography>{t("VEHICLE.CONDITION.EXHAUST")}</Typography>
                <span>
                  <StarIcon />
                  {data.condition.exhaust.toFixed(1)}
                </span>
              </div>
            </div>
            <Divider className={classes.divider} />
            <div className={classes.report}>
              <div>
                <HeaterIcon className="heater" />
                <Typography>{t("VEHICLE.CONDITION.HEATER")}</Typography>
                <div>
                  <Markup
                    content={
                      data.condition.is_heater === "Y"
                        ? t("OPTION.OP")
                        : t("OPTION.NO_OP")
                    }
                  />
                </div>
              </div>
              <div>
                <AirconIcon className="aircon" />
                <Typography>{t("VEHICLE.CONDITION.AIRCON")}</Typography>
                <div>
                  <Markup
                    content={
                      data.condition.is_aircon === "Y"
                        ? t("OPTION.OP")
                        : t("OPTION.NO_OP")
                    }
                  />
                </div>
              </div>
              <div>
                <AudioIcon />
                <Typography>{t("VEHICLE.CONDITION.AUDIO")}</Typography>
                <div>
                  <Markup
                    content={
                      data.condition.is_audio === "Y"
                        ? t("OPTION.OP")
                        : t("OPTION.NO_OP")
                    }
                  />
                </div>
              </div>
              <div>
                <WindowIcon />
                <Typography>{t("VEHICLE.CONDITION.WINDOW")}</Typography>
                <div>
                  <Markup
                    content={
                      data.condition.is_window === "Y"
                        ? t("OPTION.OP")
                        : t("OPTION.NO_OP")
                    }
                  />
                </div>
              </div>
              <div>
                <ChassisIcon />
                <Typography>{t("VEHICLE.CONDITION.4WD")}</Typography>
                <div>
                  <Markup
                    content={
                      data.condition.is_4WD === "N/A"
                        ? t("OPTION.N/A")
                        : data.condition.is_4WD === "Y"
                        ? t("OPTION.OP")
                        : t("OPTION.NO_OP")
                    }
                  />
                </div>
              </div>
            </div>
            <Divider className={classes.divider} />
            <Typography className={classes.notice}>
              {t("DETAIL.CONDITION.MSG.CHECK_CONDITION")}
            </Typography>
          </div>
        </>
      )}
    </>
  );
};

export default ConditionReport;
