import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
// Components
import { Divider, Grid, Theme, Typography } from "@mui/material";
import {
  AirconIcon,
  AudioIcon,
  BrakeIcon,
  ChassisIcon,
  EngineIcon,
  ExhuastIcon,
  HeaterIcon,
  MissionIcon,
  StarIcon,
  SteeringIcon,
  WindowIcon,
} from "@/common/icons";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
import { colors } from "@/common/lib/style/palette";

const styles = (theme: Theme) => ({
  container: {
    marginBottom: "3vh",
  },
  title: {
    fontSize: "1.25rem",
    fontWeight: "bold",
    userSelect: "none",
    marginBottom: "1vh",
  },
  notice: {
    fontSize: "0.85rem",
    padding: "0 2.5vw",
  },
  report: {
    display: "flex",
    "& > div": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "20%",
      "& > svg": {
        width: "2.35rem",
        height: "2.35rem",
        margin: "1vh 2vw",
        fill: theme.palette.text.primary,
        "&.heater": {
          fill: colors.red[5],
        },
        "&.aircon": {
          fill: colors.blue[5],
        },
      },
      "& > p": {
        fontSize: "0.85rem",
        fontWeight: "bold",
        textAlign: "center",
      },
      "& > span": {
        display: "flex",
        alignItems: "center",
        fontSize: "0.825rem",
        fontWeight: "bold",
        "& > svg": {
          width: "0.85rem",
          height: "0.85rem",
          fill: colors.yellow[6],
          stroke: colors.orange[3],
          strokeWidth: 450,
          marginRight: "1vw",
        },
      },
      "& > div": {
        fontSize: "0.8rem",
        fontWeight: "bold",
        textAlign: "center",
      },
    },
  },
  divider: {
    margin: "1.5vh 0",
  },
});

interface propType {
  data: VehicleData;
}

const ConditionReport = ({ data }: propType) => {
  const classes = useStyles(styles);
  const { t } = useTranslation();

  return (
    <>
      {data !== undefined && (
        <>
          <Typography className={classes.title}>
            {t("DETAIL.CONDITION.TITLE")}
          </Typography>
          <Grid container className={classes.container}>
            <Grid item xs={12}>
              <Grid container className={classes.report}>
                <Grid item xs={4}>
                  <EngineIcon />
                  <Typography>{t("VEHICLE.CONDITION.ENGINE")}</Typography>
                  <span>
                    <StarIcon />
                    {data.condition.engine.toFixed(1)}
                  </span>
                </Grid>
                <Grid item xs={4}>
                  <MissionIcon />
                  <Typography>{t("VEHICLE.CONDITION.MISSION")}</Typography>
                  <span>
                    <StarIcon />
                    {data.condition.mission.toFixed(1)}
                  </span>
                </Grid>
                <Grid item xs={4}>
                  <SteeringIcon />
                  <Typography>{t("VEHICLE.CONDITION.STEERING")}</Typography>
                  <span>
                    <StarIcon />
                    {data.condition.steering.toFixed(1)}
                  </span>
                </Grid>
                <Grid item xs={6}>
                  <BrakeIcon />
                  <Typography>{t("VEHICLE.CONDITION.BRAKE")}</Typography>
                  <span>
                    <StarIcon />
                    {data.condition.brake.toFixed(1)}
                  </span>
                </Grid>
                <Grid item xs={6}>
                  <ExhuastIcon />
                  <Typography>{t("VEHICLE.CONDITION.EXHAUST")}</Typography>
                  <span>
                    <StarIcon />
                    {data.condition.exhaust.toFixed(1)}
                  </span>
                </Grid>
              </Grid>
              <Divider className={classes.divider} />
            </Grid>
            <Grid item xs={12}>
              <Grid container className={classes.report}>
                <Grid item xs={4}>
                  <HeaterIcon className="heater" />
                  <Typography>{t("VEHICLE.CONDITION.HEATER")}</Typography>
                  <div>
                    <Markup
                      content={
                        data.condition.is_heater === "Y"
                          ? t("OPTION.OP")
                          : t("OPTION.NO_OP")
                      }
                    />
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <AirconIcon className="aircon" />
                  <Typography>{t("VEHICLE.CONDITION.AIRCON")}</Typography>
                  <div>
                    <Markup
                      content={
                        data.condition.is_aircon === "Y"
                          ? t("OPTION.OP")
                          : t("OPTION.NO_OP")
                      }
                    />
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <AudioIcon />
                  <Typography>{t("VEHICLE.CONDITION.AUDIO")}</Typography>
                  <div>
                    <Markup
                      content={
                        data.condition.is_audio === "Y"
                          ? t("OPTION.OP")
                          : t("OPTION.NO_OP")
                      }
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <WindowIcon />
                  <Typography>{t("VEHICLE.CONDITION.WINDOW")}</Typography>
                  <div>
                    <Markup
                      content={
                        data.condition.is_window === "Y"
                          ? t("OPTION.OP")
                          : t("OPTION.NO_OP")
                      }
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <ChassisIcon />
                  <Typography>{t("VEHICLE.CONDITION.4WD")}</Typography>
                  <div>
                    <Markup
                      content={
                        data.condition.is_4WD === "N/A"
                          ? t("OPTION.N/A")
                          : data.condition.is_4WD === "Y"
                          ? t("OPTION.OP")
                          : t("OPTION.NO_OP")
                      }
                    />
                  </div>
                </Grid>
              </Grid>
              <Divider className={classes.divider} />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.notice}>
                <Markup content={t("DETAIL.CONDITION.MSG.CHECK_CONDITION")} />
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default ConditionReport;
