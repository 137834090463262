// Action Types
export const INIT_ON = "COMMON/INIT_ON" as const;
export const INIT_OFF = "COMMON/INIT_OFF" as const;
export const SET_ACCESS_URL = "COMMON/SET_ACCESS_URL" as const;
export const SET_ERROR_TEXT = "COMMON/SET_ERROR_TEXT" as const;
export const SET_CONFIRM_TEXT = "COMMON/SET_CONFIRM_TEXT" as const;
export const DARK_MODE_ON = "COMMON/DARK_MODE_ON" as const;
export const DARK_MODE_OFF = "COMMON/DARK_MODE_OFF" as const;
export const LOADING_ON = "COMMON/LOADING_ON" as const;
export const LOADING_OFF = "COMMON/LOADING_OFF" as const;
// 뒤에 as const 를 붙여줌으로써 나중에 액션 객체를 만들게 action.type 의 값을 추론하는 과정에서
// action.type 이 string 으로 추론되지 않고 'COMMON/LOADING_ON' 와 같이 실제 문자열 값으로 추론되도록 해줍니다.
