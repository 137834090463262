import Slider from "react-slick";
// Components
import { Card, Theme } from "@mui/material";
// Styles
import { useStyles } from "@/common/lib/style/hooks";

import image1 from "@/common/images/profile/image1.jpg";
import image2 from "@/common/images/profile/image2.jpg";
import image4 from "@/common/images/profile/image4.jpg";
import image5 from "@/common/images/profile/image5.jpg";
import image7 from "@/common/images/profile/image7.jpg";
import image8 from "@/common/images/profile/image8.jpg";
import image9 from "@/common/images/profile/image9.jpg";
import image10 from "@/common/images/profile/image10.jpg";
import image12 from "@/common/images/profile/image12.jpg";

const styles = (theme: Theme) => ({
  carousel: {
    "& .slick-slide": {
      "& > div": {
        display: "inline-flex",
        width: "100%",
        margin: "1vw 0",
        "& > div": {
          margin: "0 1vw",
        },
      },
    },
    "& .slick-prev, & .slick-next": {
      width: "6.5%",
      height: "100%",
      zIndex: 1,
      opacity: 0.25,
      transition: "500ms cubic-bezier(0.22, 0.61, 0.36, 1) all",
      "&::before": {
        fontSize: "3rem",
        color: theme.palette.primary.main,
      },
      "&:hover": {
        opacity: 1,
      },
    },
    "& .slick-prev": {
      left: "-6.5%",
    },
    "& .slick-next": {
      right: "-6.5%",
    },
  },
  carouselDiv: {
    display: "flex !important",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    width: "100%",
  },
});

const ProductsCarousel = () => {
  const classes = useStyles(styles);

  const imageList = [
    image1,
    image2,
    image4,
    image5,
    image7,
    image8,
    image9,
    image10,
    image12,
  ];

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    fade: false,
    draggable: false,
    swipe: false,
    rows: 1,
    slidesPerRow: 3,
    autoplay: true,
    autoplaySpeed: 6500,
  };

  return (
    <Slider {...settings} className={classes.carousel}>
      {imageList.map((item, idx) => {
        return (
          <Card key={idx} className={classes.carouselDiv}>
            <img alt="" src={item} className={classes.image} />
          </Card>
        );
      })}
    </Slider>
  );
};

export default ProductsCarousel;
