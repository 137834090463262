/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Markup } from "interweave";
// Redux
import { setParams } from "@/common/lib/redux/actions/searchAction";
import { useTypedDispatch, useTypedSelector } from "@/common/lib/redux/store";
// Components
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  RadioGroup,
  Theme,
  Typography,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, Checkbox, Dropdown, Input, Radio } from "@/common/components";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// Libs
import {
  initList,
  yearList,
  productList,
  getTypeList,
  getYearList,
  getMakerList,
  getModelList,
  getDetailModelList,
  carCategoryList,
  truckCategoryList,
  truckSubCategoryList,
  busCategoryList,
  carDriveList,
  truckDriveList,
  truckWheelbaseList,
  truckEngineTypeList,
  truckCabinTypeList,
  missionList,
  fuelList,
  colorList,
  carSeatsList,
  busSeatsList,
  optionList,
} from "@/common/lib/varList";
import { isNotNull, isNull } from "@/common/lib/common";
import { usePrevious } from "@/common/lib/hooks";

const styles = (theme: Theme) => ({
  titleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > button": {
      position: "absolute",
      left: "2vw",
      "& > svg": {
        fontSize: "1.75rem",
        color: theme.palette.text.primary,
      },
    },
    "& > p": {
      fontSize: "1.5rem",
      fontWeight: "bold",
      color: theme.palette.text.primary,
    },
  },
  searchContainer: {
    margin: "2.5vh 0",
    "& .MuiCardContent-root": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "1.5vh 4vw",
      position: "relative",
      "& > svg": {
        position: "absolute",
        right: "6vw",
        fontSize: "2rem",
      },
    },
    "& .MuiAccordion-root": {
      margin: 0,
      "& .MuiAccordionSummary-root": {
        display: "none",
      },
      "& .MuiAccordionDetails-root": {
        padding: "unset",
      },
    },
  },
  submitContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > button": {
      width: "35vw",
      aspectRatio: "3.65",
      borderRadius: "2vw",
      margin: "0 2.5vw",
    },
  },
  accordion: {
    "&::before": {
      display: "none",
    },
  },
  div: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "2vh 4vw",
    "& > div.fromto": {
      display: "flex",
      alignItems: "center",
      "& > .MuiTypography-root": {
        margin: "0 1.5vw",
      },
    },
  },
  table: {
    width: "100%",
    padding: "1vh 2vw",
    border: "none",
    "& th": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "1px solid " + theme.palette.divider,
      padding: "1vh 2vw",
      backgroundColor: theme.palette.grey[100],
      "& > svg": {
        position: "absolute",
        right: "6vw",
      },
    },
    "& td": {
      border: "none",
      padding: 0,
    },
  },
  optionList: {
    margin: 0,
    padding: 0,
    listStyle: "none",
    "& > li": {
      display: "inline-block",
      width: "50%",
      margin: "0.35vh 0",
      verticalAlign: "top",
      "& > div": {
        display: "flex",
        alignItems: "center",
      },
    },
  },
  formControl: {
    "& > .MuiTypography-root": {
      fontSize: "0.875rem",
    },
  },
  checkbox: {
    padding: "0.35vh",
    "& > svg": {
      fontSize: "1.125rem",
    },
  },
  checkboxGroup: {
    "&::before": {
      content: "'('",
      fontSize: "0.835rem",
    },
    "&::after": {
      content: "')'",
      paddingLeft: "0.35vh",
      fontSize: "0.835rem",
    },
  },
  radio: {
    padding: "0.35vh",
    "& > span > svg": {
      fontSize: "1.125rem",
    },
  },
  radioGroup: {
    "&::before": {
      content: "'('",
      fontSize: "0.835rem",
    },
    "&::after": {
      content: "')'",
      paddingLeft: "0.35vh",
      fontSize: "0.835rem",
    },
  },
  dropdownFormControl: {
    "&.SMALL": {
      width: "5.85em !important",
    },
    "&.NORMAL": {
      width: "8.75em !important",
    },
    fontSize: "1rem !important",
  },
  dropdownMenuItem: {
    fontSize: "1rem !important",
  },
  input: {
    width: "7.5em !important",
  },
});

interface propType {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SearchPage = ({ setOpen }: propType) => {
  const classes = useStyles(styles);
  const { t } = useTranslation();
  const { params } = useTypedSelector((state) => state.search);
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [init, setInit] = useState(false);

  const [typeExpanded, setTypeExpanded] = useState(false);
  const [makerExpanded, setMakerExpanded] = useState(true);
  const [searchExpanded, setSearchExpanded] = useState(false);
  const [optionExpanded, setOptionExpanded] = useState(false);
  const [inexteriorExpanded, setInexteriorExpanded] = useState(false);
  const [seatExpanded, setSeatExpanded] = useState(false);
  const [convinienceExpanded, setConvinienceExpanded] = useState(false);
  const [safetyExpanded, setSafetyExpanded] = useState(false);

  const [type, setType] = useState<string>(
    Object.keys(params).includes("type") ? params["type"] : ""
  );

  const [carCategory, setCarCategory] = useState<string>(
    Object.keys(params).includes("car_category") ? params["car_category"] : ""
  );
  const [truckCategory, setTruckCategory] = useState<string>(
    Object.keys(params).includes("truck_category")
      ? params["truck_category"]
      : ""
  );
  const [truckSubCategory, setTruckSubCategory] = useState<string[]>(
    Object.keys(params).includes("truck_sub_category")
      ? params["truck_sub_category"].split(",")
      : []
  );
  const [busCategory, setBusCategory] = useState<string>(
    Object.keys(params).includes("bus_category") ? params["bus_category"] : ""
  );

  const [maker, setMaker] = useState<string>(
    Object.keys(params).includes("maker") ? params["maker"] : ""
  );
  const [model, setModel] = useState<string>(
    Object.keys(params).includes("model") ? params["model"] : ""
  );
  const [detailModel, setDetailModel] = useState<string>(
    Object.keys(params).includes("detail_model") ? params["detail_model"] : ""
  );
  const [makerList, setMakerList] = useState(getMakerList(type));
  const [modelList, setModelList] = useState(getModelList(type, maker));
  const [detailModelList, setDetailModelList] = useState(
    getDetailModelList(maker, model)
  );

  const [minYear, setMinYear] = useState<string>(
    Object.keys(params).includes("min_year") ? params["min_year"] : ""
  );
  const [maxYear, setMaxYear] = useState<string>(
    Object.keys(params).includes("max_year") ? params["max_year"] : ""
  );
  const [minYearList, setMinYearList] = useState<Option[]>(yearList);
  const [maxYearList, setMaxYearList] = useState<Option[]>(yearList);

  const [minPrice, setMinPrice] = useState<string>(
    Object.keys(params).includes("min_price") ? params["min_price"] : ""
  );
  const [maxPrice, setMaxPrice] = useState<string>(
    Object.keys(params).includes("max_price") ? params["max_price"] : ""
  );

  const [carDrive, setCarDrive] = useState<string[]>(
    Object.keys(params).includes("car_drive") ? params["car_drive"] : []
  );
  const [truckDrive, setTruckDrive] = useState<string[]>(
    Object.keys(params).includes("truck_drive") ? params["truck_drive"] : []
  );
  const [truckWheelbase, setTruckWheelbase] = useState<string[]>(
    Object.keys(params).includes("truck_wheelbase")
      ? params["truck_wheelbase"]
      : []
  );
  const [truckEngineType, setTruckEngineType] = useState<string[]>(
    Object.keys(params).includes("truck_engine_type")
      ? params["truck_engine_type"]
      : []
  );
  const [truckCabinType, setTruckCabinType] = useState<string[]>(
    Object.keys(params).includes("truck_cabin_type")
      ? params["truck_cabin_type"]
      : []
  );

  const [mission, setMission] = useState<string[]>(
    Object.keys(params).includes("mission") ? params["mission"].split(",") : []
  );
  const [fuel, setFuel] = useState<string[]>(
    Object.keys(params).includes("fuel") ? params["fuel"].split(",") : []
  );
  const [color, setColor] = useState<string[]>(
    Object.keys(params).includes("color") ? params["color"].split(",") : []
  );

  const [carSeats, setCarSeats] = useState<string>(
    Object.keys(params).includes("car_min_seats")
      ? params["car_min_seats"]
      : Object.keys(params).includes("car_max_seats")
      ? params["car_max_seats"]
      : ""
  );
  const [busSeats, setBusSeats] = useState<string>(
    Object.keys(params).includes("bus_min_seats") ||
      Object.keys(params).includes("bus_max_seats")
      ? (Object.keys(params).includes("bus_min_seats")
          ? params["bus_min_seats"]
          : "") +
          "~" +
          (Object.keys(params).includes("bus_max_seats")
            ? params["bus_max_seats"]
            : "")
      : ""
  );

  const [carOption, setCarOption] = useState<string[]>(
    Object.keys(params).includes("car_option")
      ? params["car_option"].split(",")
      : []
  );
  const [truckOption, setTruckOption] = useState<string[]>(
    Object.keys(params).includes("truck_option")
      ? params["truck_option"].split(",")
      : []
  );
  const [busOption, setBusOption] = useState<string[]>(
    Object.keys(params).includes("bus_option")
      ? params["bus_option"].split(",")
      : []
  );

  const [searchText, setSearchText] = useState<string>(
    Object.keys(params).includes("search_text") ? params["search_text"] : ""
  );

  const prevType = usePrevious(type);

  const handleInitOnClick = () => {
    setType("");
    setMaker("");
    setModel("");
    setDetailModel("");

    setCarCategory("");
    setTruckCategory("");
    setBusCategory("");

    setCarSeats("");
    setBusSeats("");

    setCarDrive([]);
    setTruckDrive([]);
    setTruckWheelbase([]);
    setTruckEngineType([]);
    setTruckCabinType([]);

    setCarOption([]);
    setTruckOption([]);
    setBusOption([]);

    setMinYear("");
    setMaxYear("");
    setMinPrice("");
    setMaxPrice("");
    setMission([]);
    setFuel([]);
    setColor([]);
    setSearchText("");

    dispatch(setParams({}));
  };
  const handleSearchOnClick = () => {
    const searchParams: { [key: string]: string } = {};
    if (isNotNull(type)) {
      searchParams["type"] = type;
    }
    if (isNotNull(maker)) {
      searchParams["maker"] = maker;
    }
    if (isNotNull(model)) {
      searchParams["model"] = model;
    }
    if (isNotNull(detailModel)) {
      searchParams["detail_model"] = detailModel;
    }
    if (isNotNull(minYear)) {
      searchParams["min_year"] = minYear;
    }
    if (isNotNull(maxYear)) {
      searchParams["max_year"] = maxYear;
    }
    if (isNotNull(minPrice)) {
      searchParams["price_type"] = "ADPrice";
      searchParams["min_price"] = minPrice;
    }
    if (isNotNull(maxPrice)) {
      searchParams["price_type"] = "ADPrice";
      searchParams["max_price"] = maxPrice;
    }
    if (mission.length > 0) {
      searchParams["mission"] = mission.join(",");
    }
    if (fuel.length > 0) {
      searchParams["fuel"] = fuel.join(",");
    }
    if (color.length > 0) {
      searchParams["color"] = color.join(",");
    }
    if (type === "CAR") {
      if (isNotNull(carCategory)) {
        searchParams["car_category"] = carCategory;
      }
      if (carDrive.length > 0) {
        searchParams["car_drive"] = carDrive.join(",");
      }
      if (isNotNull(carSeats)) {
        searchParams["car_min_seats"] = carSeats;
        searchParams["car_max_seats"] = carSeats;
      }
      if (carOption.length > 0) {
        searchParams["car_option"] = carOption.join(",");
      }
    } else if (type === "TRUCK") {
      if (isNotNull(truckCategory)) {
        searchParams["truck_category"] = truckCategory;
      }
      if (truckSubCategory.length > 0) {
        searchParams["truck_sub_category"] = truckSubCategory.join(",");
      }
      if (truckDrive.length > 0) {
        searchParams["truck_drive"] = truckDrive.join(",");
      }
      if (truckWheelbase.length > 0) {
        searchParams["truck_wheelbase"] = truckWheelbase.join(",");
      }
      if (truckEngineType.length > 0) {
        searchParams["truck_engine_type"] = truckEngineType.join(",");
      }
      if (truckCabinType.length > 0) {
        searchParams["truck_cabin_type"] = truckCabinType.join(",");
      }
      if (truckOption.length > 0) {
        searchParams["truck_option"] = truckOption.join(",");
      }
    } else if (type === "BUS") {
      if (isNotNull(busCategory)) {
        searchParams["bus_category"] = busCategory;
      }
      if (isNotNull(busSeats)) {
        const result = busSeats.match(/(\d+)?\s*~\s*(\d+)?/);
        if (result !== null) {
          if (result[1] !== null) {
            searchParams["bus_min_seats"] = result[1];
          }
          if (result[2] !== null) {
            searchParams["bus_max_seats"] = result[2];
          }
        }
      }
      if (busOption.length > 0) {
        searchParams["bus_option"] = busOption.join(",");
      }
    }
    if (isNotNull(searchText)) {
      searchParams["search_text"] = searchText;
    }
    dispatch(setParams(searchParams));
    if (window.location.pathname !== "/vehicle/list") {
      navigate("/vehicle/list");
    } else {
      setOpen(false);
    }
  };

  const checkRange = (
    type: string,
    value: string,
    compare: string,
    dispatch: React.Dispatch<React.SetStateAction<string>>
  ) => {
    if (isNull(value) || isNull(compare)) {
      dispatch(value);
    } else {
      let isCorrectRange = true;

      const checkValue = Number(value);
      const checkCompare = Number(compare);
      if (isNaN(checkValue) || isNaN(checkCompare)) {
        isCorrectRange = false;
      }

      if (type === "MIN") {
        if (checkValue > checkCompare) {
          enqueueSnackbar(t("SEARCH.MSG.COMPARE_MIN_VALUE"), {
            variant: "warning",
          });
          isCorrectRange = false;
        }
      } else if (type === "MAX") {
        if (checkValue < checkCompare) {
          enqueueSnackbar(t("SEARCH.MSG.COMPARE_MAX_VALUE"), {
            variant: "warning",
          });
          isCorrectRange = false;
        }
      }

      if (isCorrectRange) {
        dispatch(String(checkValue));
      } else {
        dispatch("");
      }
    }
  };

  const getCheckboxValue = (optionList: Option[]) => {
    for (let idx = 0; idx < optionList.length; idx++) {
      if (type === "CAR") {
        if (carOption.indexOf(optionList[idx].value) !== -1) {
          return true;
        }
      } else if (type === "TRUCK") {
        if (truckOption.indexOf(optionList[idx].value) !== -1) {
          return true;
        }
      } else if (type === "BUS") {
        if (busOption.indexOf(optionList[idx].value) !== -1) {
          return true;
        }
      }
    }
    return false;
  };
  const handleCheckboxOnClick = (isCheck: boolean, optionList: Option[]) => {
    if (isCheck) {
      // Append
      if (type === "CAR") {
        setCarOption((prevState) => [...prevState, optionList[0].value]);
      } else if (type === "TRUCK") {
        setTruckOption((prevState) => [...prevState, optionList[0].value]);
      } else if (type === "BUS") {
        setBusOption((prevState) => [...prevState, optionList[0].value]);
      }
    } else {
      // Remove
      for (let idx = 0; idx < optionList.length; idx++) {
        if (type === "CAR") {
          if (carOption.indexOf(optionList[idx].value) !== -1) {
            setCarOption((prevState) =>
              prevState.filter((item) => item !== optionList[idx].value)
            );
          }
        } else if (type === "TRUCK") {
          if (truckOption.indexOf(optionList[idx].value) !== -1) {
            setTruckOption((prevState) =>
              prevState.filter((item) => item !== optionList[idx].value)
            );
          }
        } else if (type === "BUS") {
          if (busOption.indexOf(optionList[idx].value) !== -1) {
            setBusOption((prevState) =>
              prevState.filter((item) => item !== optionList[idx].value)
            );
          }
        }
      }
    }
  };
  const getRadioGroupValue = (optionList: Option[]) => {
    for (let idx = 0; idx < optionList.length; idx++) {
      if (type === "CAR") {
        if (carOption.indexOf(optionList[idx].value) !== -1) {
          return optionList[idx].value;
        }
      } else if (type === "TRUCK") {
        if (truckOption.indexOf(optionList[idx].value) !== -1) {
          return optionList[idx].value;
        }
      } else if (type === "BUS") {
        if (busOption.indexOf(optionList[idx].value) !== -1) {
          return optionList[idx].value;
        }
      }
    }
    return "";
  };
  const handleRadioGroupOnClick = (value: string, optionList: Option[]) => {
    // Remove
    for (let idx = 0; idx < optionList.length; idx++) {
      if (type === "CAR") {
        if (carOption.indexOf(optionList[idx].value) !== -1) {
          setCarOption((prevState) =>
            prevState.filter((item) => item !== optionList[idx].value)
          );
        }
      } else if (type === "TRUCK") {
        if (truckOption.indexOf(optionList[idx].value) !== -1) {
          setTruckOption((prevState) =>
            prevState.filter((item) => item !== optionList[idx].value)
          );
        }
      } else if (type === "BUS") {
        if (busOption.indexOf(optionList[idx].value) !== -1) {
          setBusOption((prevState) =>
            prevState.filter((item) => item !== optionList[idx].value)
          );
        }
      }
    }
    // Append
    if (type === "CAR") {
      setCarOption((prevState) => [...prevState, value]);
    } else if (type === "TRUCK") {
      setTruckOption((prevState) => [...prevState, value]);
    } else if (type === "BUS") {
      setBusOption((prevState) => [...prevState, value]);
    }
  };

  useEffect(() => {
    if (init) {
      setMakerList(getMakerList(type));
      if (
        isNotNull(type) &&
        isNotNull(maker) &&
        !Object.keys(productList[maker]).includes(type)
      ) {
        setMaker("");
      }

      setCarCategory("");
      setTruckCategory("");
      setBusCategory("");

      setCarSeats("");
      setBusSeats("");
      setCarDrive([]);
      setTruckDrive([]);
      setTruckWheelbase([]);
      setTruckEngineType([]);
      setTruckCabinType([]);

      setCarOption([]);
      setTruckOption([]);
      setBusOption([]);

      setInexteriorExpanded(false);
      setSeatExpanded(false);
      setConvinienceExpanded(false);
      setSafetyExpanded(false);
    }
  }, [type]);
  useEffect(() => {
    if (init) {
      setTruckSubCategory([]);
    }
  }, [type, truckCategory]);

  useEffect(() => {
    if (init) {
      setModelList(getModelList(type, maker));
      if (isNotNull(model)) {
        setModel("");
      }
    }
  }, [maker]);
  useEffect(() => {
    if (init) {
      setDetailModelList(getDetailModelList(maker, model));
      if (isNotNull(detailModel)) {
        setDetailModel("");
      }
      if (isNotNull(type) && isNull(model)) {
        setType("");
      } else if (isNull(prevType) && isNotNull(maker) && isNotNull(model)) {
        if (
          productList[maker]["CAR"] !== undefined &&
          Object.keys(productList[maker]["CAR"]).includes(model)
        ) {
          setType("CAR");
        } else if (
          productList[maker]["TRUCK"] !== undefined &&
          Object.keys(productList[maker]["TRUCK"]).includes(model)
        ) {
          setType("TRUCK");
        } else if (
          productList[maker]["BUS"] !== undefined &&
          Object.keys(productList[maker]["BUS"]).includes(model)
        ) {
          setType("BUS");
        }
      }
    }
  }, [model]);

  useEffect(() => {
    if (isNull(minYear)) {
      setMaxYearList(yearList);
    } else {
      const currentYear = new Date().getFullYear() + 1;
      setMaxYearList(getYearList(Number(minYear), currentYear));
    }
  }, [minYear]);
  useEffect(() => {
    if (isNull(maxYear)) {
      setMinYearList(yearList);
    } else {
      const currentYear = new Date().getFullYear() + 1;
      setMinYearList(getYearList(currentYear - 30, Number(maxYear)));
    }
  }, [maxYear]);

  useEffect(() => {
    if (Object.keys(params).includes("type")) {
      setType(params["type"].split(",")[0]);
    } else {
      setType("");
    }

    if (type === "CAR") {
      if (Object.keys(params).includes("car_drive")) {
        setCarDrive(params["car_drive"].split(",")[0]);
      }
      if (
        Object.keys(params).includes("car_min_seats") ||
        Object.keys(params).includes("car_max_seats")
      ) {
        const minSeats = isNotNull(params["car_min_seats"])
          ? params["car_min_seats"]
          : "";
        // const maxSeats = isNotNull(params["car_max_seats"])
        //   ? params["car_max_seats"]
        //   : "";
        setCarSeats(minSeats);
      }
      if (Object.keys(params).includes("car_option")) {
        setCarOption(params["car_option"].split(","));
      }
    } else if (type === "TRUCK") {
      if (Object.keys(params).includes("truck_drive")) {
        setTruckDrive(params["truck_drive"].split(",")[0]);
      }
      if (Object.keys(params).includes("truck_wheelbase")) {
        setTruckWheelbase(params["truck_wheelbase"].split(",")[0]);
      }
      if (Object.keys(params).includes("truck_engine_type")) {
        setTruckEngineType(params["truck_engine_type"].split(",")[0]);
      }
      if (Object.keys(params).includes("truck_cabin_type")) {
        setTruckCabinType(params["truck_cabin_type"].split(",")[0]);
      }
      if (Object.keys(params).includes("truck_option")) {
        setTruckOption(params["truck_option"].split(","));
      }
    } else if (type === "BUS") {
      if (
        Object.keys(params).includes("bus_min_seats") ||
        Object.keys(params).includes("bus_max_seats")
      ) {
        const minSeats = isNotNull(params["bus_min_seats"])
          ? params["bus_min_seats"]
          : "";
        const maxSeats = isNotNull(params["bus_max_seats"])
          ? params["bus_max_seats"]
          : "";
        setBusSeats(minSeats + "~" + maxSeats);
      }
      if (Object.keys(params).includes("bus_option")) {
        setBusOption(params["bus_option"].split(","));
      }
    }

    if (Object.keys(params).includes("search_text")) {
      setSearchText(params["search_text"]);
    } else {
      setSearchText("");
    }

    if (
      isNotNull(type) ||
      isNotNull(carCategory) ||
      isNotNull(truckCategory) ||
      isNotNull(busCategory)
    ) {
      setTypeExpanded(true);
    } else {
      setTypeExpanded(false);
    }
    if (
      isNotNull(minYear) ||
      isNotNull(maxYear) ||
      isNotNull(minPrice) ||
      isNotNull(maxPrice) ||
      isNotNull(mission) ||
      isNotNull(fuel) ||
      color.length > 0 ||
      isNotNull(carDrive) ||
      isNotNull(carSeats) ||
      isNotNull(truckDrive) ||
      isNotNull(truckWheelbase) ||
      isNotNull(truckEngineType) ||
      isNotNull(truckCabinType) ||
      isNotNull(busSeats)
    ) {
      setSearchExpanded(true);
    } else {
      setSearchExpanded(false);
    }
    if (
      carOption.length > 0 ||
      truckOption.length > 0 ||
      busOption.length > 0
    ) {
      setOptionExpanded(true);
    } else {
      setOptionExpanded(false);
    }

    const initOptionList = (checkType: string) => {
      if (
        type.split(",").includes(checkType) &&
        Object.keys(params).includes(checkType.toLowerCase + "_option")
      ) {
        const inexteriorOptionList: string[] = [];
        const seatOptionList: string[] = [];
        const convinienceOptionList: string[] = [];
        const safetyOptionList: string[] = [];

        const getOptionList = (type: string) => {
          Object.keys(optionList[type]["INEXTERIOR"]).forEach((key: string) => {
            optionList[type]["INEXTERIOR"][key].forEach((option: Option) => {
              inexteriorOptionList.push(option.value);
            });
          });
          Object.keys(optionList[type]["SEAT"]).forEach((key: string) => {
            optionList[type]["SEAT"][key].forEach((option: Option) => {
              seatOptionList.push(option.value);
            });
          });
          Object.keys(optionList[type]["CONVINIENCE"]).forEach(
            (key: string) => {
              optionList[type]["CONVINIENCE"][key].forEach((option: Option) => {
                convinienceOptionList.push(option.value);
              });
            }
          );
          Object.keys(optionList[type]["SAFETY"]).forEach((key: string) => {
            optionList[type]["SAFETY"][key].forEach((option: Option) => {
              safetyOptionList.push(option.value);
            });
          });
        };
        getOptionList(checkType);

        if (
          inexteriorOptionList.filter((key) =>
            params[checkType.toLowerCase + "_option"].split(",").includes(key)
          ).length > 0
        ) {
          setInexteriorExpanded(true);
        }
        if (
          seatOptionList.filter((key) =>
            params[checkType.toLowerCase + "_option"].split(",").includes(key)
          ).length > 0
        ) {
          setSeatExpanded(true);
        }
        if (
          convinienceOptionList.filter((key) =>
            params[checkType.toLowerCase + "_option"].split(",").includes(key)
          ).length > 0
        ) {
          setConvinienceExpanded(true);
        }
        if (
          safetyOptionList.filter((key) =>
            params[checkType.toLowerCase + "_option"].split(",").includes(key)
          ).length > 0
        ) {
          setSafetyExpanded(true);
        }
        setOptionExpanded(true);
      }
    };
    initOptionList("CAR");
    initOptionList("TRUCK");
    initOptionList("BUS");
    setInit(true);
  }, [params]);

  return (
    <>
      <Grid container>
        <Grid item xs={12} className={classes.titleContainer}>
          <IconButton onClick={() => setOpen(false)}>
            <ArrowBackIosNewIcon />
          </IconButton>
          <Typography className={classes.title}>{t("SEARCH.TITLE")}</Typography>
        </Grid>
        <Grid item xs={12} className={classes.searchContainer}>
          <Card variant="outlined">
            <CardContent onClick={() => setTypeExpanded(!typeExpanded)}>
              <Typography>{t("VEHICLE.PRODUCT.TYPE")}</Typography>
              {typeExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </CardContent>
            <Accordion
              className={classes.accordion}
              square
              elevation={0}
              expanded={typeExpanded}
            >
              <AccordionSummary />
              <AccordionDetails>
                <Divider />
                <div className={classes.div}>
                  <Typography>{t("VEHICLE.PRODUCT.TYPE")}</Typography>
                  <Dropdown
                    label={t("VEHICLE.PRODUCT.TYPE")}
                    value={type}
                    options={getTypeList(maker)}
                    dispatch={setType}
                    formControlClassName={`${classes.dropdownFormControl} NORMAL`}
                    menuItemClassName={classes.dropdownMenuItem}
                  />
                </div>
                <Accordion
                  className={classes.accordion}
                  square
                  elevation={0}
                  expanded={type === "CAR"}
                >
                  <AccordionSummary />
                  <AccordionDetails>
                    <div className={classes.div}>
                      <Typography>{t("VEHICLE.PRODUCT.CATEGORY")}</Typography>
                      <Dropdown
                        label={t("VEHICLE.PRODUCT.CATEGORY")}
                        value={carCategory}
                        options={carCategoryList}
                        dispatch={setCarCategory}
                        formControlClassName={`${classes.dropdownFormControl} NORMAL`}
                        menuItemClassName={classes.dropdownMenuItem}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  className={classes.accordion}
                  square
                  elevation={0}
                  expanded={type === "TRUCK"}
                >
                  <AccordionSummary />
                  <AccordionDetails>
                    <div className={classes.div}>
                      <Typography>{t("VEHICLE.PRODUCT.CATEGORY")}</Typography>
                      <Dropdown
                        label={t("VEHICLE.PRODUCT.CATEGORY")}
                        value={truckCategory}
                        options={truckCategoryList}
                        dispatch={setTruckCategory}
                        formControlClassName={`${classes.dropdownFormControl} NORMAL`}
                        menuItemClassName={classes.dropdownMenuItem}
                      />
                    </div>
                    <div className={classes.div}>
                      <Typography>
                        {t("VEHICLE.PRODUCT.SUB_CATEGORY")}
                      </Typography>
                      <Dropdown
                        multiple
                        label={t("VEHICLE.PRODUCT.SUB_CATEGORY")}
                        value={truckSubCategory}
                        options={
                          isNotNull(truckCategory)
                            ? truckSubCategoryList[truckCategory]
                            : initList
                        }
                        dispatch={setTruckSubCategory}
                        formControlClassName={`${classes.dropdownFormControl} NORMAL`}
                        menuItemClassName={classes.dropdownMenuItem}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  className={classes.accordion}
                  square
                  elevation={0}
                  expanded={type === "BUS"}
                >
                  <AccordionSummary />
                  <AccordionDetails>
                    <div className={classes.div}>
                      <Typography>{t("VEHICLE.PRODUCT.CATEGORY")}</Typography>
                      <Dropdown
                        label={t("VEHICLE.PRODUCT.CATEGORY")}
                        value={busCategory}
                        options={busCategoryList}
                        dispatch={setBusCategory}
                        formControlClassName={`${classes.dropdownFormControl} NORMAL`}
                        menuItemClassName={classes.dropdownMenuItem}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
              </AccordionDetails>
            </Accordion>
            <Divider />
            <CardContent onClick={() => setMakerExpanded(!makerExpanded)}>
              <Typography>
                {t("VEHICLE.PRODUCT.MAKER")}/{t("VEHICLE.PRODUCT.MODEL")}
              </Typography>
              {makerExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </CardContent>
            <Accordion
              className={classes.accordion}
              square
              elevation={0}
              expanded={makerExpanded}
            >
              <AccordionSummary />
              <AccordionDetails>
                <Divider />
                <div className={classes.div}>
                  <Typography>{t("VEHICLE.PRODUCT.MAKER")}</Typography>
                  <Dropdown
                    label={t("VEHICLE.PRODUCT.MAKER")}
                    value={maker}
                    options={makerList}
                    dispatch={setMaker}
                    formControlClassName={`${classes.dropdownFormControl} NORMAL`}
                    menuItemClassName={classes.dropdownMenuItem}
                  />
                </div>
                <div className={classes.div}>
                  <Typography>{t("VEHICLE.PRODUCT.MODEL")}</Typography>
                  <Dropdown
                    label={t("VEHICLE.PRODUCT.MODEL")}
                    value={model}
                    options={modelList}
                    dispatch={setModel}
                    formControlClassName={`${classes.dropdownFormControl} NORMAL`}
                    menuItemClassName={classes.dropdownMenuItem}
                  />
                </div>
                <div className={classes.div}>
                  <Typography>{t("VEHICLE.PRODUCT.DETAIL_MODEL")}</Typography>
                  <Dropdown
                    label={t("VEHICLE.PRODUCT.DETAIL_MODEL")}
                    value={detailModel}
                    options={detailModelList}
                    dispatch={setDetailModel}
                    formControlClassName={`${classes.dropdownFormControl} NORMAL`}
                    menuItemClassName={classes.dropdownMenuItem}
                  />
                </div>
              </AccordionDetails>
            </Accordion>
            <Divider />
            <CardContent onClick={() => setSearchExpanded(!searchExpanded)}>
              <Typography>{t("SEARCH.DETAIL_SEARCH")}</Typography>
              {searchExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </CardContent>
            <Accordion
              className={classes.accordion}
              square
              elevation={0}
              expanded={searchExpanded}
            >
              <AccordionSummary />
              <AccordionDetails>
                <Divider />
                <div className={classes.div}>
                  <Typography>{t("VEHICLE.PRODUCT.YEAR")}</Typography>
                  <div className="fromto">
                    <Dropdown
                      label="From"
                      value={minYear}
                      options={minYearList}
                      dispatch={setMinYear}
                      formControlClassName={`${classes.dropdownFormControl} SMALL`}
                      menuItemClassName={classes.dropdownMenuItem}
                    />
                    <Typography>-</Typography>
                    <Dropdown
                      label="To"
                      value={maxYear}
                      options={maxYearList}
                      dispatch={setMaxYear}
                      formControlClassName={`${classes.dropdownFormControl} SMALL`}
                      menuItemClassName={classes.dropdownMenuItem}
                    />
                  </div>
                </div>
                <div className={classes.div}>
                  <Typography>{t("VEHICLE.SALES.PRICE")}</Typography>
                  <div className="fromto">
                    <Input
                      type="integer"
                      label="Min"
                      variant="standard"
                      className={classes.input}
                      value={minPrice}
                      dispatch={(value: string) => {
                        if (Number(value) === 0) {
                          setMinPrice("");
                        } else {
                          setMinPrice(value);
                        }
                      }}
                      onBlur={(value: string) => {
                        if (Number(value) === 0) {
                          checkRange("MIN", "", maxPrice, setMinPrice);
                        } else {
                          checkRange("MIN", value, maxPrice, setMinPrice);
                        }
                      }}
                      inputProps={{
                        maxLength: 10,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                    <Typography>-</Typography>
                    <Input
                      type="integer"
                      label="Max"
                      variant="standard"
                      className={classes.input}
                      value={maxPrice}
                      dispatch={(value: string) => {
                        if (Number(value) === 0) {
                          setMaxPrice("");
                        } else {
                          setMaxPrice(value);
                        }
                      }}
                      onBlur={(value: string) => {
                        if (Number(value) === 0) {
                          checkRange("MAX", "", minPrice, setMaxPrice);
                        } else {
                          checkRange("MAX", value, minPrice, setMaxPrice);
                        }
                      }}
                      inputProps={{
                        maxLength: 10,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
                <Accordion
                  className={classes.accordion}
                  square
                  elevation={0}
                  expanded={type === "CAR" ? true : false}
                >
                  <AccordionSummary />
                  <AccordionDetails>
                    <div className={classes.div}>
                      <Typography>{t("VEHICLE.PRODUCT.DRIVE")}</Typography>
                      <Dropdown
                        multiple
                        label={t("VEHICLE.PRODUCT.DRIVE")}
                        value={carDrive}
                        options={carDriveList}
                        dispatch={setCarDrive}
                        formControlClassName={`${classes.dropdownFormControl} NORMAL`}
                        menuItemClassName={classes.dropdownMenuItem}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  className={classes.accordion}
                  square
                  elevation={0}
                  expanded={type === "TRUCK" ? true : false}
                >
                  <AccordionSummary />
                  <AccordionDetails>
                    <div className={classes.div}>
                      <Typography>{t("VEHICLE.PRODUCT.DRIVE")}</Typography>
                      <Dropdown
                        multiple
                        label={t("VEHICLE.PRODUCT.DRIVE")}
                        value={truckDrive}
                        options={truckDriveList}
                        dispatch={setTruckDrive}
                        formControlClassName={`${classes.dropdownFormControl} NORMAL`}
                        menuItemClassName={classes.dropdownMenuItem}
                      />
                    </div>
                    <div className={classes.div}>
                      <Typography>{t("VEHICLE.PRODUCT.WHEELBASE")}</Typography>
                      <Dropdown
                        multiple
                        label={t("VEHICLE.PRODUCT.WHEELBASE")}
                        value={truckWheelbase}
                        options={truckWheelbaseList}
                        dispatch={setTruckWheelbase}
                        formControlClassName={`${classes.dropdownFormControl} NORMAL`}
                        menuItemClassName={classes.dropdownMenuItem}
                      />
                    </div>
                    <div className={classes.div}>
                      <Typography>
                        {t("VEHICLE.PRODUCT.ENGINE_TYPE")}
                      </Typography>
                      <Dropdown
                        multiple
                        label={t("VEHICLE.PRODUCT.ENGINE_TYPE")}
                        value={truckEngineType}
                        options={truckEngineTypeList}
                        dispatch={setTruckEngineType}
                        formControlClassName={`${classes.dropdownFormControl} NORMAL`}
                        menuItemClassName={classes.dropdownMenuItem}
                      />
                    </div>
                    <div className={classes.div}>
                      <Typography>{t("VEHICLE.PRODUCT.CABIN_TYPE")}</Typography>
                      <Dropdown
                        multiple
                        label={t("VEHICLE.PRODUCT.CABIN_TYPE")}
                        value={truckCabinType}
                        options={truckCabinTypeList}
                        dispatch={setTruckCabinType}
                        formControlClassName={`${classes.dropdownFormControl} NORMAL`}
                        menuItemClassName={classes.dropdownMenuItem}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
                <div className={classes.div}>
                  <Typography>{t("VEHICLE.PRODUCT.MISSION")}</Typography>
                  <Dropdown
                    multiple
                    label={t("VEHICLE.PRODUCT.MISSION")}
                    value={mission}
                    options={missionList}
                    dispatch={setMission}
                    formControlClassName={`${classes.dropdownFormControl} NORMAL`}
                    menuItemClassName={classes.dropdownMenuItem}
                  />
                </div>
                <div className={classes.div}>
                  <Typography>{t("VEHICLE.PRODUCT.FUEL")}</Typography>
                  <Dropdown
                    multiple
                    label={t("VEHICLE.PRODUCT.FUEL")}
                    value={fuel}
                    options={fuelList}
                    dispatch={setFuel}
                    formControlClassName={`${classes.dropdownFormControl} NORMAL`}
                    menuItemClassName={classes.dropdownMenuItem}
                  />
                </div>
                <div className={classes.div}>
                  <Typography>{t("VEHICLE.PRODUCT.COLOR")}</Typography>
                  <Dropdown
                    multiple
                    label={t("VEHICLE.PRODUCT.COLOR")}
                    value={color}
                    options={colorList}
                    dispatch={setColor}
                    formControlClassName={`${classes.dropdownFormControl} NORMAL`}
                    menuItemClassName={classes.dropdownMenuItem}
                  />
                </div>
                <Accordion
                  className={classes.accordion}
                  square
                  elevation={0}
                  expanded={type === "CAR" ? true : false}
                >
                  <AccordionSummary />
                  <AccordionDetails>
                    <div className={classes.div}>
                      <Typography>{t("VEHICLE.PRODUCT.SEATS")}</Typography>
                      <Dropdown
                        label={t("VEHICLE.PRODUCT.SEATS")}
                        value={carSeats}
                        options={carSeatsList}
                        dispatch={setCarSeats}
                        formControlClassName={`${classes.dropdownFormControl} NORMAL`}
                        menuItemClassName={classes.dropdownMenuItem}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  className={classes.accordion}
                  square
                  elevation={0}
                  expanded={type === "BUS" ? true : false}
                >
                  <AccordionSummary />
                  <AccordionDetails>
                    <div className={classes.div}>
                      <Typography>{t("VEHICLE.PRODUCT.SEATS")}</Typography>
                      <Dropdown
                        label={t("VEHICLE.PRODUCT.SEATS")}
                        value={busSeats}
                        options={busSeatsList}
                        dispatch={setBusSeats}
                        formControlClassName={`${classes.dropdownFormControl} NORMAL`}
                        menuItemClassName={classes.dropdownMenuItem}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
              </AccordionDetails>
            </Accordion>
            <Divider />
            <Accordion
              className={classes.accordion}
              square
              elevation={0}
              expanded={isNotNull(type)}
            >
              <AccordionSummary />
              <AccordionDetails>
                <CardContent onClick={() => setOptionExpanded(!optionExpanded)}>
                  <Typography>{t("SEARCH.DETAIL_OPTION")}</Typography>
                  {optionExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </CardContent>
                <Accordion
                  className={classes.accordion}
                  square
                  elevation={0}
                  expanded={optionExpanded}
                >
                  <AccordionSummary />
                  <AccordionDetails>
                    <Divider />
                    {(type === "CAR" || type === "TRUCK" || type === "BUS") && (
                      <table className={classes.table}>
                        <colgroup>
                          <col style={{ width: "100%" }} />
                        </colgroup>
                        <thead></thead>
                        <tbody>
                          <tr>
                            <th
                              onClick={() =>
                                setInexteriorExpanded(!inexteriorExpanded)
                              }
                            >
                              <Typography>
                                {t("VEHICLE.OPTION.INEXTERIOR")}
                              </Typography>
                              {inexteriorExpanded ? (
                                <ExpandLessIcon />
                              ) : (
                                <ExpandMoreIcon />
                              )}
                            </th>
                          </tr>
                          <tr>
                            <td>
                              <Accordion
                                square
                                elevation={0}
                                expanded={inexteriorExpanded}
                              >
                                <AccordionSummary />
                                <AccordionDetails>
                                  <ul className={classes.optionList}>
                                    {Object.keys(
                                      optionList[type]["INEXTERIOR"]
                                    ).map((option: string, idx: number) => {
                                      return (
                                        <li key={idx}>
                                          <Checkbox
                                            label={
                                              <Markup
                                                content={t(
                                                  "VEHICLE.OPTION." + option
                                                )}
                                              />
                                            }
                                            checked={getCheckboxValue(
                                              optionList[type]["INEXTERIOR"][
                                                option
                                              ]
                                            )}
                                            dispatch={(isCheck: boolean) =>
                                              handleCheckboxOnClick(
                                                isCheck,
                                                optionList[type]["INEXTERIOR"][
                                                  option
                                                ]
                                              )
                                            }
                                            formControlClassName={
                                              classes.formControl
                                            }
                                            checkboxClassName={classes.checkbox}
                                          />
                                          {type === "CAR" &&
                                            ["SUNROOF"].includes(option) && (
                                              <RadioGroup
                                                row
                                                className={classes.radioGroup}
                                                value={getRadioGroupValue(
                                                  optionList[type][
                                                    "INEXTERIOR"
                                                  ][option]
                                                )}
                                                onChange={(e) =>
                                                  handleRadioGroupOnClick(
                                                    e.target.value,
                                                    optionList[type][
                                                      "INEXTERIOR"
                                                    ][option]
                                                  )
                                                }
                                              >
                                                {optionList[type]["INEXTERIOR"][
                                                  option
                                                ].map((detail_option, idx) => {
                                                  return (
                                                    <Radio
                                                      key={idx}
                                                      label={
                                                        <Markup
                                                          content={
                                                            detail_option.label
                                                          }
                                                        />
                                                      }
                                                      value={
                                                        detail_option.value
                                                      }
                                                      formControlClassName={
                                                        classes.formControl
                                                      }
                                                      radioClassName={
                                                        classes.radio
                                                      }
                                                    />
                                                  );
                                                })}
                                              </RadioGroup>
                                            )}
                                          {["HEADLAMP"].includes(option) && (
                                            <RadioGroup
                                              row
                                              className={classes.radioGroup}
                                              value={getRadioGroupValue(
                                                optionList[type]["INEXTERIOR"][
                                                  option
                                                ]
                                              )}
                                              onChange={(e) =>
                                                handleRadioGroupOnClick(
                                                  e.target.value,
                                                  optionList[type][
                                                    "INEXTERIOR"
                                                  ][option]
                                                )
                                              }
                                            >
                                              {optionList[type]["INEXTERIOR"][
                                                option
                                              ].map((detail_option, idx) => {
                                                return (
                                                  <Radio
                                                    key={idx}
                                                    label={
                                                      <Markup
                                                        content={
                                                          detail_option.label
                                                        }
                                                      />
                                                    }
                                                    value={detail_option.value}
                                                    formControlClassName={
                                                      classes.formControl
                                                    }
                                                    radioClassName={
                                                      classes.radio
                                                    }
                                                  />
                                                );
                                              })}
                                            </RadioGroup>
                                          )}
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </AccordionDetails>
                              </Accordion>
                            </td>
                          </tr>
                          <tr>
                            <th onClick={() => setSeatExpanded(!seatExpanded)}>
                              <Typography>
                                {t("VEHICLE.OPTION.SEAT")}
                              </Typography>
                              {seatExpanded ? (
                                <ExpandLessIcon />
                              ) : (
                                <ExpandMoreIcon />
                              )}
                            </th>
                          </tr>
                          <tr>
                            <td>
                              <Accordion
                                square
                                elevation={0}
                                expanded={seatExpanded}
                              >
                                <AccordionSummary />
                                <AccordionDetails>
                                  <ul className={classes.optionList}>
                                    {Object.keys(optionList[type]["SEAT"]).map(
                                      (option: string, idx: number) => {
                                        return (
                                          <li key={idx}>
                                            <Checkbox
                                              label={
                                                <Markup
                                                  content={t(
                                                    "VEHICLE.OPTION." + option
                                                  )}
                                                />
                                              }
                                              checked={getCheckboxValue(
                                                optionList[type]["SEAT"][option]
                                              )}
                                              dispatch={(isCheck: boolean) =>
                                                handleCheckboxOnClick(
                                                  isCheck,
                                                  optionList[type]["SEAT"][
                                                    option
                                                  ]
                                                )
                                              }
                                              formControlClassName={
                                                classes.formControl
                                              }
                                              checkboxClassName={
                                                classes.checkbox
                                              }
                                            />
                                            {type === "CAR" &&
                                              [
                                                "HEAT_SEAT",
                                                "POWER_SEAT",
                                                "VENT_SEAT",
                                                "MEMORY_SEAT",
                                              ].includes(option) && (
                                                <div
                                                  className={
                                                    classes.checkboxGroup
                                                  }
                                                >
                                                  {optionList[type]["SEAT"][
                                                    option
                                                  ].map(
                                                    (
                                                      detail_option: Option,
                                                      idx: number
                                                    ) => {
                                                      return (
                                                        <Checkbox
                                                          key={idx}
                                                          label={
                                                            <Markup
                                                              content={
                                                                detail_option.label
                                                              }
                                                            />
                                                          }
                                                          checked={getCheckboxValue(
                                                            [
                                                              optionList[type][
                                                                "SEAT"
                                                              ][option][idx],
                                                            ]
                                                          )}
                                                          dispatch={(
                                                            isCheck: boolean
                                                          ) =>
                                                            handleCheckboxOnClick(
                                                              isCheck,
                                                              [
                                                                optionList[
                                                                  type
                                                                ]["SEAT"][
                                                                  option
                                                                ][idx],
                                                              ]
                                                            )
                                                          }
                                                          formControlClassName={
                                                            classes.formControl
                                                          }
                                                          checkboxClassName={
                                                            classes.checkbox
                                                          }
                                                        />
                                                      );
                                                    }
                                                  )}
                                                </div>
                                              )}
                                          </li>
                                        );
                                      }
                                    )}
                                  </ul>
                                </AccordionDetails>
                              </Accordion>
                            </td>
                          </tr>
                          <tr>
                            <th
                              onClick={() =>
                                setConvinienceExpanded(!convinienceExpanded)
                              }
                            >
                              <Typography>
                                {t("VEHICLE.OPTION.CONVINIENCE")}
                              </Typography>
                              {convinienceExpanded ? (
                                <ExpandLessIcon />
                              ) : (
                                <ExpandMoreIcon />
                              )}
                            </th>
                          </tr>
                          <tr>
                            <td>
                              <Accordion
                                square
                                elevation={0}
                                expanded={convinienceExpanded}
                              >
                                <AccordionSummary />
                                <AccordionDetails>
                                  <ul className={classes.optionList}>
                                    {Object.keys(
                                      optionList[type]["CONVINIENCE"]
                                    ).map((option: string, idx: number) => {
                                      return (
                                        <li key={idx}>
                                          <Checkbox
                                            label={
                                              <Markup
                                                content={t(
                                                  "VEHICLE.OPTION." + option
                                                )}
                                              />
                                            }
                                            checked={getCheckboxValue(
                                              optionList[type]["CONVINIENCE"][
                                                option
                                              ]
                                            )}
                                            dispatch={(isCheck: boolean) =>
                                              handleCheckboxOnClick(
                                                isCheck,
                                                optionList[type]["CONVINIENCE"][
                                                  option
                                                ]
                                              )
                                            }
                                            formControlClassName={
                                              classes.formControl
                                            }
                                            checkboxClassName={classes.checkbox}
                                          />
                                          {type === "CAR" &&
                                            ["CRUISE_CONTROL"].includes(
                                              option
                                            ) && (
                                              <RadioGroup
                                                row
                                                className={classes.radioGroup}
                                                value={getRadioGroupValue(
                                                  optionList[type][
                                                    "CONVINIENCE"
                                                  ][option]
                                                )}
                                                onChange={(e) =>
                                                  handleRadioGroupOnClick(
                                                    e.target.value,
                                                    optionList[type][
                                                      "CONVINIENCE"
                                                    ][option]
                                                  )
                                                }
                                              >
                                                {optionList[type][
                                                  "CONVINIENCE"
                                                ][option].map(
                                                  (detail_option, idx) => {
                                                    return (
                                                      <Radio
                                                        key={idx}
                                                        label={
                                                          <Markup
                                                            content={
                                                              detail_option.label
                                                            }
                                                          />
                                                        }
                                                        value={
                                                          detail_option.value
                                                        }
                                                        formControlClassName={
                                                          classes.formControl
                                                        }
                                                        radioClassName={
                                                          classes.radio
                                                        }
                                                      />
                                                    );
                                                  }
                                                )}
                                              </RadioGroup>
                                            )}
                                          {["AIRCON"].includes(option) && (
                                            <RadioGroup
                                              row
                                              className={classes.radioGroup}
                                              value={getRadioGroupValue(
                                                optionList[type]["CONVINIENCE"][
                                                  option
                                                ]
                                              )}
                                              onChange={(e) =>
                                                handleRadioGroupOnClick(
                                                  e.target.value,
                                                  optionList[type][
                                                    "CONVINIENCE"
                                                  ][option]
                                                )
                                              }
                                            >
                                              {optionList[type]["CONVINIENCE"][
                                                option
                                              ].map((detail_option, idx) => {
                                                return (
                                                  <Radio
                                                    key={idx}
                                                    label={
                                                      <Markup
                                                        content={
                                                          detail_option.label
                                                        }
                                                      />
                                                    }
                                                    value={detail_option.value}
                                                    formControlClassName={
                                                      classes.formControl
                                                    }
                                                    radioClassName={
                                                      classes.radio
                                                    }
                                                  />
                                                );
                                              })}
                                            </RadioGroup>
                                          )}
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </AccordionDetails>
                              </Accordion>
                            </td>
                          </tr>
                          <tr>
                            <th
                              onClick={() => setSafetyExpanded(!safetyExpanded)}
                            >
                              <Typography>
                                {t("VEHICLE.OPTION.SAFETY")}
                              </Typography>
                              {safetyExpanded ? (
                                <ExpandLessIcon />
                              ) : (
                                <ExpandMoreIcon />
                              )}
                            </th>
                          </tr>
                          <tr>
                            <td>
                              <Accordion
                                square
                                elevation={0}
                                expanded={safetyExpanded}
                              >
                                <AccordionSummary />
                                <AccordionDetails>
                                  <ul className={classes.optionList}>
                                    {Object.keys(
                                      optionList[type]["SAFETY"]
                                    ).map((option: string, idx: number) => {
                                      return (
                                        <li key={idx}>
                                          <Checkbox
                                            label={
                                              <Markup
                                                content={t(
                                                  "VEHICLE.OPTION." + option
                                                )}
                                              />
                                            }
                                            checked={getCheckboxValue(
                                              optionList[type]["SAFETY"][option]
                                            )}
                                            dispatch={(isCheck: boolean) =>
                                              handleCheckboxOnClick(
                                                isCheck,
                                                optionList[type]["SAFETY"][
                                                  option
                                                ]
                                              )
                                            }
                                            formControlClassName={
                                              classes.formControl
                                            }
                                            checkboxClassName={classes.checkbox}
                                          />
                                          {[
                                            "AIRBAG",
                                            "CAMERA",
                                            "PARK_SENSOR",
                                          ].includes(option) && (
                                            <div
                                              className={classes.checkboxGroup}
                                            >
                                              {optionList[type]["SAFETY"][
                                                option
                                              ].map(
                                                (
                                                  detail_option: Option,
                                                  idx: number
                                                ) => {
                                                  return (
                                                    <Checkbox
                                                      key={idx}
                                                      label={
                                                        <Markup
                                                          content={
                                                            detail_option.label
                                                          }
                                                        />
                                                      }
                                                      checked={getCheckboxValue(
                                                        [
                                                          optionList[type][
                                                            "SAFETY"
                                                          ][option][idx],
                                                        ]
                                                      )}
                                                      dispatch={(
                                                        isCheck: boolean
                                                      ) =>
                                                        handleCheckboxOnClick(
                                                          isCheck,
                                                          [
                                                            optionList[type][
                                                              "SAFETY"
                                                            ][option][idx],
                                                          ]
                                                        )
                                                      }
                                                      formControlClassName={
                                                        classes.formControl
                                                      }
                                                      checkboxClassName={
                                                        classes.checkbox
                                                      }
                                                    />
                                                  );
                                                }
                                              )}
                                            </div>
                                          )}
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </AccordionDetails>
                              </Accordion>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </AccordionDetails>
                </Accordion>
              </AccordionDetails>
            </Accordion>
            <Divider />
            <CardContent>
              <Input
                fullWidth
                isClearable
                variant="outlined"
                placeholder="ex. Prod No, Advert text..."
                value={searchText}
                dispatch={setSearchText}
                inputProps={{ maxLength: 1000 }}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} className={classes.submitContainer}>
          <Button variant="contained" onClick={handleInitOnClick}>
            {t("BUTTON.INIT")}
          </Button>
          <Button variant="contained" onClick={handleSearchOnClick}>
            {t("BUTTON.SEARCH")}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default SearchPage;
