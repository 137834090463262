/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
// Components
import { keyframes, Theme, Tooltip, Typography } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  LeftSide,
  FrontSide,
  RearSide,
  RightSide,
  StatusMark,
} from "@/browser/components/vehicle/inspect";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
import { colors } from "@/common/lib/style/palette";
// Libs
import { isNotNull } from "@/common/lib/common";

const fadein = keyframes`
  from {
    opacity: 0;
    transform: translateY(5px);
  }
  to {
    opacity: 0.9;
    transform: none;
  }
`;

const styles = (theme: Theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    userSelect: "none",
    marginBottom: 30,
  },
  title: {
    fontSize: "1.65rem",
    fontWeight: "bold",
    userSelect: "none",
    marginBottom: 10,
  },
  table: {
    width: 400,
    height: "fit-content",
    padding: 15,
    "& th": {
      fontSize: "1rem",
      fontWeight: 500,
      "& > p": {
        display: "flex",
        alignItems: "center",
        margin: 0,
        textAlign: "left",
        "& > span": {
          fontSize: "0.85rem",
          fontWeight: "bold",
          marginRight: 7.5,
        },
        "& > .MuiSvgIcon-root": {
          width: "1rem",
          height: "1rem",
          marginLeft: 2.5,
          color: colors.gray[6],
          "&:hover": {
            color: colors.blue[5],
          },
        },
      },
    },
    "& td": {
      "&.notice": {
        padding: "15px 0",
        fontSize: "0.9rem",
      },
      "&:not(.notice)": {
        padding: "15px 10px",
        fontSize: "0.95rem",
        fontWeight: 500,
      },
    },
  },
  inspectionBackground: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: "100%",
    height: "100%",
    backdropFilter: "blur(3px)",
  },
  inspectionAlert: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto",
    width: "fit-content",
    height: "fit-content",
    padding: "20px 25px",
    background: "white",
    borderRadius: 15,
    opacity: 0.9,
    animation: `${fadein} 1500ms ${theme.transitions.easing.easeInOut}`,
    "&.noData": {
      border: "3px solid " + theme.palette.error.main,
      color: theme.palette.error.main,
    },
    "&.accidentFree": {
      border: "3px solid " + theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
    "& > p.title": {
      margin: 0,
      marginBottom: 5,
      fontSize: "1.15rem",
      fontWeight: "bold",
      textAlign: "center",
    },
    "& > p.subtitle": {
      margin: 0,
      fontSize: "0.85rem",
      textAlign: "center",
    },
  },
  inspectionStatus: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
  },
  inspectionStatusMark: {
    display: "flex",
    justifyContent: "center",
    margin: "15px 0 10px",
    "& > div": {
      display: "flex",
      alignItems: "center",
      margin: "0 7.5px",
      "& > .MuiTypography-root": {
        fontSize: "0.925rem",
        marginLeft: 5,
      },
    },
  },
  tooltip: {
    maxWidth: 375,
    fontSize: "0.825rem",
    fontWeight: 500,
    color: colors.blue[5],
    lineHeight: "1.15",
    backgroundColor: "white",
    border: "2px solid " + colors.blue[5],
    padding: "3px 6px",
    userSelect: "none",
    "& .MuiTooltip-arrow": {
      color: colors.blue[5],
    },
  },
});

interface propType {
  data: VehicleData;
}

const InspectionContainer = ({ data }: propType) => {
  const classes = useStyles(styles);
  const { t } = useTranslation();

  const [isData, setIsData] = useState(false);
  const [isAccident, setIsAccident] = useState(true);

  const corrosionList: { [key: string]: string } = {
    N: t("OPTION.NONE_0"),
    L: t("OPTION.LESS_25"),
    M: t("OPTION.LESS_50"),
    H: t("OPTION.LESS_75"),
    F: t("OPTION.EXIST_100"),
  };
  const ynList: { [key: string]: string } = {
    Y: t("OPTION.EXIST"),
    N: t("OPTION.NOT_EXIST"),
  };

  useEffect(() => {
    if (data !== undefined) {
      let _isData = false;
      if (
        isNotNull(data.inspection["accident_history"]) &&
        isNotNull(data.inspection["simple_repair"])
      ) {
        _isData = true;
      }

      if (_isData) {
        let isAllSafe = true;
        Object.entries(data.inspection).forEach(([key, value]) => {
          if (
            ![
              "corrosion",
              "hole",
              "crack",
              "accident_history",
              "simple_repair",
            ].includes(key)
          ) {
            if (isNotNull(value)) {
              isAllSafe = false;
            }
          }
        });
        setIsData(true);
        if (isAllSafe) {
          setIsAccident(false);
        } else {
          setIsAccident(true);
        }
      } else {
        setIsData(false);
        setIsAccident(true);
      }
    } else {
      setIsData(false);
      setIsAccident(true);
    }
  }, [data]);

  return (
    <>
      {data !== undefined && (
        <>
          <Typography className={classes.title}>
            {t("DETAIL.INSPECTION.TITLE")}
          </Typography>
          <div className={classes.container}>
            <table className={classes.table}>
              <tbody>
                <tr>
                  <th>
                    <p>
                      <span>*</span>
                      {t("VEHICLE.INSPECTION.ACCIDENT_HISTORY")}
                      <Tooltip
                        arrow
                        disableFocusListener
                        title={t("DETAIL.INSPECTION.MSG.ACCIDENT_HISTORY")}
                        classes={{
                          tooltip: classes.tooltip,
                        }}
                      >
                        <HelpOutlineIcon />
                      </Tooltip>
                    </p>
                  </th>
                  <td>
                    {ynList[data.inspection.accident_history] !== undefined
                      ? ynList[data.inspection.accident_history]
                      : "N/A"}
                  </td>
                  <th>
                    <p>
                      <span>*</span>
                      {t("VEHICLE.INSPECTION.SIMPLE_REPAIR")}
                      <Tooltip
                        arrow
                        disableFocusListener
                        title={t("DETAIL.INSPECTION.MSG.SIMPLE_REPAIR")}
                        classes={{
                          tooltip: classes.tooltip,
                        }}
                      >
                        <HelpOutlineIcon />
                      </Tooltip>
                    </p>
                  </th>
                  <td>
                    {ynList[data.inspection.simple_repair] !== undefined
                      ? ynList[data.inspection.simple_repair]
                      : "N/A"}
                  </td>
                </tr>
                <tr>
                  <th>
                    <p>
                      <span>*</span>
                      {t("VEHICLE.INSPECTION.IS_CORROSION")}
                    </p>
                  </th>
                  <td colSpan={3}>
                    {corrosionList[data.inspection.corrosion] !== undefined
                      ? corrosionList[data.inspection.corrosion]
                      : "N/A"}
                  </td>
                </tr>
                <tr>
                  <th>
                    <p>
                      <span>*</span>
                      {t("VEHICLE.INSPECTION.IS_HOLE")}
                    </p>
                  </th>
                  <td>
                    {ynList[data.inspection.hole] !== undefined
                      ? ynList[data.inspection.hole]
                      : "N/A"}
                  </td>
                  <th>
                    <p>
                      <span>*</span>
                      {t("VEHICLE.INSPECTION.IS_CRACK")}
                    </p>
                  </th>
                  <td>
                    {ynList[data.inspection.crack] !== undefined
                      ? ynList[data.inspection.crack]
                      : "N/A"}
                  </td>
                </tr>
                <tr>
                  <td colSpan={4} className="notice">
                    <Markup
                      content={t("DETAIL.INSPECTION.MSG.CHECK_INSPECTION")}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div>
              <div className={classes.inspectionStatus}>
                <LeftSide data={data} />
                <FrontSide data={data} />
                <RearSide data={data} />
                <RightSide data={data} />
                {(!isData || !isAccident) && (
                  <>
                    <div className={classes.inspectionBackground} />
                    {!isData && (
                      <div className={`${classes.inspectionAlert} noData`}>
                        <p className="title">
                          {t("DETAIL.INSPECTION.MSG.NO_DATA")}
                        </p>
                        <p className="subtitle">
                          <Markup content={t("DETAIL.INSPECTION.MSG.CHECK")} />
                        </p>
                      </div>
                    )}
                    {isData && !isAccident && (
                      <div
                        className={`${classes.inspectionAlert} accidentFree`}
                      >
                        <p className="title">
                          {t("DETAIL.INSPECTION.MSG.NO_ACCIDENT")}
                        </p>
                        <p className="subtitle">
                          <Markup content={t("DETAIL.INSPECTION.MSG.CHECK")} />
                        </p>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className={classes.inspectionStatusMark}>
                <div>
                  <StatusMark status="X" />
                  <Typography>{t("VEHICLE.INSPECTION.EXCHANGE")}</Typography>
                </div>
                <div>
                  <StatusMark status="W" />
                  <Typography>
                    {t("VEHICLE.INSPECTION.PLATE_WELDING")}
                  </Typography>
                </div>
                <div>
                  <StatusMark status="C" />
                  <Typography>{t("VEHICLE.INSPECTION.CORROSION")}</Typography>
                </div>
                <div>
                  <StatusMark status="A" />
                  <Typography>{t("VEHICLE.INSPECTION.SCRATCH")}</Typography>
                </div>
                <div>
                  <StatusMark status="U" />
                  <Typography>{t("VEHICLE.INSPECTION.UNEVEN")}</Typography>
                </div>
                <div>
                  <StatusMark status="T" />
                  <Typography>{t("VEHICLE.INSPECTION.DAMAGE")}</Typography>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default InspectionContainer;
