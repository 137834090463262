const TitleBackground = ({ ...props }: any) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1760 245"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <g transform="translate(0.000000,245.000000) scale(0.100000,-0.100000)">
        <path
          style={{
            fill: "#a3d4ff",
          }}
          d="M10550 2447 c0 -8 2051 -1712 2060 -1712 9 0 1914 1688 1926 1706 3
5 -784 9 -1990 9 -1098 0 -1996 -2 -1996 -3z"
        />
        <path
          style={{
            fill: "#e5f3ff",
          }}
          d="M4022 862 l-963 -857 1001 -3 c551 -1 1449 -1 1996 0 l994 3 -1026
855 c-564 470 -1029 856 -1033 857 -3 0 -439 -384 -969 -855z"
        />
        <path
          style={{
            fill: props.fill,
          }}
          d="M0 1225 l0 -1225 1528 0 1528 0 340 302 c186 165 341 305 342 310 2
4 139 129 305 276 239 212 1712 1519 1752 1554 5 4 -1297 8 -2893 8 l-2902 0
0 -1225z"
        />
        <path
          style={{
            fill: props.fill,
          }}
          d="M14454 2362 c-55 -49 -668 -594 -1364 -1211 -696 -618 -1272 -1130
-1280 -1137 -12 -12 423 -14 2888 -14 l2902 0 0 1225 0 1225 -1524 0 -1524 0
-98 -88z"
        />
      </g>
    </svg>
  );
};

export default TitleBackground;
