import { useTranslation } from "react-i18next";
// Components
import { Breadcrumbs, Link, Theme, Typography } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import LoginOnlyPage from "@/mobile/pages/auth/LoginOnlyPage";
import BasePage from "@/mobile/pages/common/BasePage";
import BookingContainer from "@/mobile/components/mylist/booking/BookingContainer";
import { SNSFab } from "@/mobile/components/common";
import { Image } from "@/common/components";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
import { colors } from "@/common/lib/style/palette";
// Images
import image from "@/mobile/images/mylist/booking.jpg";

const styles = (theme: Theme) => ({
  basePage: {
    padding: "2vh 5vw",
  },
  title: {
    paddingBottom: "0.75vh",
    borderBottom: "1px solid " + colors.gray[7],
    marginBottom: "1vh",
    "& > p": {
      fontSize: "1.75rem",
      fontWeight: 600,
      fontFamily: '"Exo 2"',
      textAlign: "center",
      color: theme.palette.primary.main,
      cursor: "default",
      userSelect: "none",
      whiteSpace: "nowrap",
      margin: "-1vh -5vw 1vh",
    },
    "& > nav": {
      display: "flex",
      justifyContent: "flex-end",
      "& .MuiLink-root": {
        display: "flex",
        alignItems: "center",
        fontSize: "0.85rem",
        fontWeight: 500,
        color: colors.gray[7],
        "& > svg": {
          fontSize: "1.35rem",
          marginRight: "1vw",
        },
      },
    },
  },
  content: {
    position: "relative",
    userSelect: "none",
    marginBottom: "2vh",
    "& > img": {
      width: "100%",
      filter: "brightness(0.75)",
    },
    "& > div": {
      position: "absolute",
      top: "50%",
      left: 0,
      padding: "0 5%",
      transform: "translate(0, -50%)",
      "& > .MuiTypography-body1": {
        fontSize: "1.35rem",
        fontWeight: "bold",
        color: "white",
        marginBottom: "1vh",
        lineHeight: "1.35",
        wordBreak: "keep-all",
      },
      "& > .MuiTypography-body2": {
        "&:before": {
          content: "'*'",
          marginRight: "1vw",
        },
        fontSize: "0.8rem",
        fontWeight: 500,
        color: "white",
        lineHeight: 1.5,
        marginBottom: "0.35vh",
        paddingLeft: "2.5vw",
        textIndent: "-2.5vw",
        wordBreak: "keep-all",
      },
    },
  },
});

const BookingPage = () => {
  const classes = useStyles(styles);
  const { t } = useTranslation();

  return (
    <LoginOnlyPage>
      <BasePage className={classes.basePage}>
        <div className={classes.title}>
          <Typography>{t("MY_LIST.BOOKING.TITLE")}</Typography>
          <Breadcrumbs>
            <Link underline="hover" color="inherit" href="/">
              <HomeIcon />
              Home
            </Link>
            <Link underline="hover" color="inherit" href="/wishlist">
              My List
            </Link>
            <Link underline="hover" color="inherit" href="/booking">
              Booking
            </Link>
          </Breadcrumbs>
        </div>
        <div className={classes.content}>
          <Image alt="" src={image} />
          <div>
            <Typography variant="body1">
              {t("MY_LIST.BOOKING.CONTENT")}
            </Typography>
            <Typography variant="body2">
              {t("MY_LIST.BOOKING.MSG.NOTICE_1")}
            </Typography>
            <Typography variant="body2">
              {t("MY_LIST.BOOKING.MSG.NOTICE_2")}
            </Typography>
          </div>
        </div>
        <BookingContainer />
        <SNSFab />
      </BasePage>
    </LoginOnlyPage>
  );
};

export default BookingPage;
