// Components
import {
  Checkbox as MuiCheckbox,
  FormControlLabel,
  Theme,
} from "@mui/material";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// Libs
import { isNull, isNotNull } from "@/common/lib/common";

const styles = (theme: Theme) => ({
  formControlLabel: {
    margin: 0,
    "&:hover:not(.Mui-disabled)": {
      "& > .MuiCheckbox-root > svg": {
        color: theme.palette.primary.main,
      },
      "& > .MuiTypography-root": {
        color: theme.palette.primary.main,
      },
    },
    "&& .Mui-checked:not(.Mui-disabled)": {
      "& + .MuiTypography-root": {
        color: theme.palette.primary.main,
      },
    },
  },
  checkbox: {
    color: theme.palette.text.primary,
    "&.Mui-checked": {
      color: theme.palette.primary.main + "!important",
      "& ~ span": {
        color: theme.palette.primary.main + "!important",
      },
    },
  },
});

const Checkbox = ({
  id,
  label,
  dispatch,
  formControlClassName,
  checkboxClassName,
  ...props
}: any) => {
  const classes = useStyles(styles);

  const randNumber = Math.floor(Math.random() * 100000000000);

  return isNotNull(label) ? (
    <FormControlLabel
      className={
        isNull(formControlClassName)
          ? classes.formControlLabel
          : `${classes.formControlLabel} ${formControlClassName}`
      }
      label={label}
      control={
        <MuiCheckbox
          id={isNotNull(id) ? id : "Checkbox-" + randNumber}
          {...props}
          className={
            isNull(checkboxClassName)
              ? classes.checkbox
              : `${classes.checkbox} ${checkboxClassName}`
          }
          onChange={(e) => dispatch(e.target.checked)}
        />
      }
    />
  ) : (
    <MuiCheckbox
      {...props}
      className={
        isNull(checkboxClassName)
          ? classes.checkbox
          : `${classes.checkbox} ${checkboxClassName}`
      }
      onChange={(e) => dispatch(e.target.checked)}
      inputProps={{
        name: isNotNull(id) ? id : "Checkbox-" + randNumber,
      }}
    />
  );
};

export default Checkbox;
