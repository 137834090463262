/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
// Components
import { Skeleton, Theme } from "@mui/material";
// Styles
import { useStyles } from "@/common/lib/style/hooks";

const styles = (theme: Theme) => ({
  border: {
    zIndex: 1,
    overflow: "hidden",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    borderRadius: 10,
    border: "1px solid " + theme.palette.divider,
    boxShadow: "0 0 5px " + theme.palette.divider,
    cursor: "pointer",
    pointerEvents: "auto",
  },
  container: {
    display: "flex",
    alignItems: "top",
    width: "calc(100% - 20px)",
    height: "calc(100% - 20px)",
    margin: 10,
  },
  image: {
    width: 225,
    height: "unset",
    aspectRatio: "1.33",
    borderRadius: 3,
    marginBottom: 8.5,
  },
  status: {
    width: 225,
    height: 25,
  },
  infoDiv: {
    display: "flex",
    flexDirection: "column",
    width: "calc(100% - 280px)",
    paddingTop: 3,
    paddingLeft: 23,
  },
  topDiv: {
    width: "100%",
    marginBottom: 5,
  },
  tableDiv: {
    width: 575,
    marginLeft: 30,
    "& dl": {
      height: 23.5,
    },
    "& dl:nth-child(2n)": {
      width: "55%",
    },
    "& dl:nth-child(2n+1)": {
      width: "45%",
      "&:last-child": {
        width: "100%",
      },
    },
    "& dt": {
      width: 100,
      "& > .MuiSkeleton-root": {
        width: 80,
        height: 19,
      },
    },
    "& dd": {
      width: "calc(100% - 100px)",
      "& > .MuiSkeleton-root": {
        width: "70%",
        height: 19,
      },
    },
    "& dl, & dt, & dd": {
      display: "inline-block",
      margin: 0,
    },
  },
  priceDiv: {
    position: "absolute",
    right: 13,
    bottom: 13,
    "& .MuiSkeleton-root": {
      width: 115,
      height: 23.5,
    },
  },
  title: {
    width: 350,
    height: 20,
    marginBottom: 5,
  },
  subtitle: {
    width: 500,
    height: 20,
    marginBottom: 5,
  },
});

const GeneralListItemSkeleton = () => {
  const classes = useStyles(styles);

  return (
    <>
      <div className={classes.border} />
      <div className={classes.container}>
        <div>
          <Skeleton
            classes={{ root: classes.image }}
            animation="wave"
            variant="rectangular"
          />
          <Skeleton
            classes={{ root: classes.status }}
            animation="wave"
            variant="rectangular"
          />
        </div>
        <div className={classes.infoDiv}>
          <div className={classes.topDiv}>
            <Skeleton
              classes={{ root: classes.title }}
              animation="wave"
              variant="rectangular"
            />
            <Skeleton
              classes={{ root: classes.subtitle }}
              animation="wave"
              variant="rectangular"
            />
          </div>
          <div className={classes.tableDiv}>
            <dl>
              <dt>
                <Skeleton animation="wave" variant="rectangular" />
              </dt>
              <dd>
                <Skeleton animation="wave" variant="rectangular" />
              </dd>
            </dl>
            <dl>
              <dt></dt>
              <dd></dd>
            </dl>
            <dl>
              <dt>
                <Skeleton animation="wave" variant="rectangular" />
              </dt>
              <dd>
                <Skeleton animation="wave" variant="rectangular" />
              </dd>
            </dl>
            <dl>
              <dt>
                <Skeleton animation="wave" variant="rectangular" />
              </dt>
              <dd>
                <Skeleton animation="wave" variant="rectangular" />
              </dd>
            </dl>
            <dl>
              <dt>
                <Skeleton animation="wave" variant="rectangular" />
              </dt>
              <dd>
                <Skeleton animation="wave" variant="rectangular" />
              </dd>
            </dl>
            <dl>
              <dt>
                <Skeleton animation="wave" variant="rectangular" />
              </dt>
              <dd>
                <Skeleton animation="wave" variant="rectangular" />
              </dd>
            </dl>
            <dl>
              <dt>
                <Skeleton animation="wave" variant="rectangular" />
              </dt>
              <dd>
                <Skeleton animation="wave" variant="rectangular" />
              </dd>
            </dl>
            <dl>
              <dt></dt>
              <dd></dd>
            </dl>
          </div>
          <div className={classes.priceDiv}>
            <Skeleton animation="wave" variant="rectangular" />
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(GeneralListItemSkeleton);
