/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
// Components
import {
  Badge,
  Divider,
  RadioGroup,
  Tab,
  Tabs,
  Theme,
  Typography,
} from "@mui/material";
import { Checkbox, Radio } from "@/common/components";
import AirlineSeatReclineNormalIcon from "@mui/icons-material/AirlineSeatReclineNormal";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// Libs
import { optionList } from "@/common/lib/varList";

const styles = (theme: Theme) => ({
  container: {
    userSelect: "none",
    padding: "0 25px",
    marginTop: 15,
    marginBottom: 40,
    position: "relative",
    "&.tab": {
      display: "flex",
    },
  },
  title: {
    fontSize: "1.65rem",
    fontWeight: "bold",
    userSelect: "none",
    marginBottom: 10,
  },
  subTitle: {
    padding: "10px 0",
    "& > .MuiTypography-root": {
      fontSize: 17,
      fontWeight: 500,
      color: theme.palette.text.primary,
      textAlign: "center",
      cursor: "default",
      userSelect: "none",
    },
  },
  viewTitle: {
    position: "absolute",
    bottom: 15,
    right: 35,
    textDecoration: "underline",
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  tabRoot: {
    padding: "16px",
    color: theme.palette.text.primary,
    "& .MuiSvgIcon-root": {
      fontSize: "2.5rem",
    },
  },
  tabSelected: {
    "& .MuiBadge-badge": {
      background: theme.palette.primary.main,
    },
  },
  tabsRoot: {
    borderRight: "1px solid " + theme.palette.divider,
  },
  badgeBadge: {
    width: 18,
    height: 18,
    minWidth: 18,
    background: theme.palette.text.primary,
    color: "white",
  },
  optionList: {
    margin: 0,
    listStyle: "none",
    userSelect: "none",
    "&.tab": {
      width: 1000,
      padding: "0 25px",
      "& > li": {
        width: "25%",
      },
    },
    "&:not(.tab)": {
      width: "100%",
      padding: "15px 25px",
      "& > li": {
        width: "20%",
      },
    },
    "& > li": {
      display: "inline-block",
      "& > div": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "10px 12.5px",
        "& > p": {
          fontSize: 14,
          cursor: "default",
          "&.disabled": {
            color: theme.palette.text.disabled,
          },
        },
      },
    },
  },
  formControl: {
    cursor: "pointer",
    "& > .MuiTypography-root": {
      fontSize: 15,
      userSelect: "none",
    },
    "&.Mui-disabled": {
      "& .MuiButtonBase-root": {
        color: theme.palette.grey[600],
      },
      "& .MuiTypography-root": {
        color: theme.palette.grey[600],
      },
      "& + div": {
        "&::before": {
          color: theme.palette.grey[600],
        },
        "&::after": {
          color: theme.palette.grey[600],
        },
      },
    },
  },
  checkbox: {
    padding: 3,
    "& > .MuiSvgIcon-root": {
      fontSize: 16,
    },
  },
  checkboxGroup: {
    "&::before": {
      content: "'('",
      fontSize: 14,
    },
    "&::after": {
      content: "')'",
      paddingLeft: 3,
      fontSize: 14,
    },
  },
  radio: {
    padding: 3,
    "& > span > svg": {
      fontSize: 16,
    },
  },
  radioGroup: {
    "&::before": {
      content: "'('",
      fontSize: 14,
    },
    "&::after": {
      content: "')'",
      paddingLeft: 3,
      fontSize: 14,
    },
  },
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
};

interface propType {
  data: VehicleData;
}

const OptionContainer = ({ data }: propType) => {
  const classes = useStyles(styles);
  const { t } = useTranslation();

  const ref = useRef<HTMLParagraphElement>(null);

  const [viewAll, setViewAll] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [inexteriorCount, setInexteriorCount] = useState(0);
  const [seatCount, setSeatCount] = useState(0);
  const [convinienceCount, setConvinienceCount] = useState(0);
  const [safetyCount, setSafetyCount] = useState(0);

  const getCheckboxValue = (optionList: Option[]) => {
    if (data === undefined) {
      return false;
    }

    const option: string[] = [];
    if (data.product.type === "CAR") {
      if (data.car_option === undefined) {
        return false;
      }

      Object.entries(data.car_option).forEach(([key, value]) => {
        if (value) {
          option.push(key);
        }
      });
    } else if (data.product.type === "TRUCK") {
      if (data.truck_option === undefined) {
        return false;
      }

      Object.entries(data.truck_option).forEach(([key, value]) => {
        if (value) {
          option.push(key);
        }
      });
    } else if (data.product.type === "BUS") {
      if (data.bus_option === undefined) {
        return false;
      }

      Object.entries(data.bus_option).forEach(([key, value]) => {
        if (value) {
          option.push(key);
        }
      });
    }

    for (let idx = 0; idx < optionList.length; idx++) {
      if (option.indexOf(optionList[idx].value) !== -1) {
        return true;
      }
    }
    return false;
  };
  const getRadioGroupValue = (optionList: Option[]) => {
    if (data === undefined) {
      return false;
    }

    const option: string[] = [];
    if (data.product.type === "CAR") {
      if (data.car_option === undefined) {
        return false;
      }

      Object.entries(data.car_option).forEach(([key, value]) => {
        if (value) {
          option.push(key);
        }
      });
    } else if (data.product.type === "TRUCK") {
      if (data.truck_option === undefined) {
        return false;
      }

      Object.entries(data.truck_option).forEach(([key, value]) => {
        if (value) {
          option.push(key);
        }
      });
    } else if (data.product.type === "BUS") {
      if (data.bus_option === undefined) {
        return false;
      }

      Object.entries(data.bus_option).forEach(([key, value]) => {
        if (value) {
          option.push(key);
        }
      });
    }

    for (let idx = 0; idx < optionList.length; idx++) {
      if (option.indexOf(optionList[idx].value) !== -1) {
        return optionList[idx].value;
      }
    }
    return "";
  };

  const handleViewAll = () => {
    setViewAll(!viewAll);
    if (ref.current) {
      const toolbarHeight = document.getElementById("toolbar")?.offsetHeight;
      window.scrollTo({
        top: toolbarHeight
          ? ref.current.offsetTop - toolbarHeight
          : ref.current.offsetTop,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    let inexteriorCount = 0;
    let seatCount = 0;
    let convinienceCount = 0;
    let safetyCount = 0;

    if (data !== undefined) {
      const option: string[] = [];
      if (data.product.type === "CAR" && data.car_option !== undefined) {
        Object.entries(data.car_option).forEach(([key, value]) => {
          if (value) {
            option.push(key);
          }
        });
      } else if (
        data.product.type === "TRUCK" &&
        data.truck_option !== undefined
      ) {
        Object.entries(data.truck_option).forEach(([key, value]) => {
          if (value) {
            option.push(key);
          }
        });
      } else if (data.product.type === "BUS" && data.bus_option !== undefined) {
        Object.entries(data.bus_option).forEach(([key, value]) => {
          if (value) {
            option.push(key);
          }
        });
      }

      const inexteriorOptionList: string[] = [];
      const seatOptionList: string[] = [];
      const convinienceOptionList: string[] = [];
      const safetyOptionList: string[] = [];

      Object.values(optionList[data.product.type]["INEXTERIOR"]).forEach(
        (value: Option[]) => {
          for (let i = 0; i < value.length; i++) {
            inexteriorOptionList.push(value[i].value);
          }
        }
      );
      Object.values(optionList[data.product.type]["SEAT"]).forEach(
        (value: Option[]) => {
          for (let i = 0; i < value.length; i++) {
            seatOptionList.push(value[i].value);
          }
        }
      );
      Object.values(optionList[data.product.type]["CONVINIENCE"]).forEach(
        (value: Option[]) => {
          for (let i = 0; i < value.length; i++) {
            convinienceOptionList.push(value[i].value);
          }
        }
      );
      Object.values(optionList[data.product.type]["SAFETY"]).forEach(
        (value: Option[]) => {
          for (let i = 0; i < value.length; i++) {
            safetyOptionList.push(value[i].value);
          }
        }
      );

      inexteriorCount = option.filter((x) =>
        inexteriorOptionList.includes(x)
      ).length;
      seatCount = option.filter((x) => seatOptionList.includes(x)).length;
      convinienceCount = option.filter((x) =>
        convinienceOptionList.includes(x)
      ).length;
      safetyCount = option.filter((x) => safetyOptionList.includes(x)).length;
    }

    setInexteriorCount(inexteriorCount);
    setSeatCount(seatCount);
    setConvinienceCount(convinienceCount);
    setSafetyCount(safetyCount);
  }, [data]);

  return (
    <>
      {data !== undefined && (
        <>
          <Typography ref={ref} className={classes.title}>
            {t("DETAIL.OPTION.TITLE")}
          </Typography>
          <>
            {viewAll ? (
              <div className={classes.container}>
                <Divider />
                <div className={classes.subTitle}>
                  <Typography>{t("VEHICLE.OPTION.INEXTERIOR")}</Typography>
                </div>
                <Divider />
                <ul className={classes.optionList}>
                  {Object.keys(optionList[data.product.type]["INEXTERIOR"]).map(
                    (option, idx) => {
                      return (
                        <li key={idx}>
                          <div>
                            <Checkbox
                              disabled
                              label={
                                <Markup
                                  content={t("VEHICLE.OPTION." + option)}
                                />
                              }
                              checked={getCheckboxValue(
                                optionList[data.product.type]["INEXTERIOR"][
                                  option
                                ]
                              )}
                              formControlClassName={classes.formControl}
                              checkboxClassName={classes.checkbox}
                            />
                            {data.product.type === "CAR" &&
                              ["SUNROOF"].includes(option) && (
                                <RadioGroup
                                  row
                                  className={classes.radioGroup}
                                  value={getRadioGroupValue(
                                    optionList[data.product.type]["INEXTERIOR"][
                                      option
                                    ]
                                  )}
                                >
                                  {optionList[data.product.type]["INEXTERIOR"][
                                    option
                                  ].map((detail_option, idx) => {
                                    return (
                                      <Radio
                                        key={idx}
                                        disabled
                                        label={
                                          <Markup
                                            content={detail_option.label}
                                          />
                                        }
                                        value={detail_option.value}
                                        formControlClassName={
                                          classes.formControl
                                        }
                                        radioClassName={classes.radio}
                                      />
                                    );
                                  })}
                                </RadioGroup>
                              )}
                            {["HEADLAMP"].includes(option) && (
                              <RadioGroup
                                row
                                className={classes.radioGroup}
                                value={getRadioGroupValue(
                                  optionList[data.product.type]["INEXTERIOR"][
                                    option
                                  ]
                                )}
                              >
                                {optionList[data.product.type]["INEXTERIOR"][
                                  option
                                ].map((detail_option, idx) => {
                                  return (
                                    <Radio
                                      key={idx}
                                      disabled
                                      label={
                                        <Markup content={detail_option.label} />
                                      }
                                      value={detail_option.value}
                                      formControlClassName={classes.formControl}
                                      radioClassName={classes.radio}
                                    />
                                  );
                                })}
                              </RadioGroup>
                            )}
                          </div>
                        </li>
                      );
                    }
                  )}
                </ul>
                <Divider />
                <div className={classes.subTitle}>
                  <Typography>{t("VEHICLE.OPTION.SEAT")}</Typography>
                </div>
                <Divider />
                <ul className={classes.optionList}>
                  {Object.keys(optionList[data.product.type]["SEAT"]).map(
                    (option, idx) => {
                      return (
                        <li key={idx}>
                          <div>
                            <Checkbox
                              disabled
                              label={
                                <Markup
                                  content={t("VEHICLE.OPTION." + option)}
                                />
                              }
                              checked={getCheckboxValue(
                                optionList[data.product.type]["SEAT"][option]
                              )}
                              formControlClassName={classes.formControl}
                              checkboxClassName={classes.checkbox}
                            />
                            {data.product.type === "CAR" &&
                              [
                                "HEAT_SEAT",
                                "POWER_SEAT",
                                "VENT_SEAT",
                                "MEMORY_SEAT",
                              ].includes(option) && (
                                <div className={classes.checkboxGroup}>
                                  {optionList[data.product.type]["SEAT"][
                                    option
                                  ].map((detail_option, idx) => {
                                    return (
                                      <Checkbox
                                        key={idx}
                                        disabled
                                        label={
                                          <Markup
                                            content={detail_option.label}
                                          />
                                        }
                                        checked={getCheckboxValue([
                                          optionList[data.product.type]["SEAT"][
                                            option
                                          ][idx],
                                        ])}
                                        formControlClassName={
                                          classes.formControl
                                        }
                                        checkboxClassName={classes.checkbox}
                                      />
                                    );
                                  })}
                                </div>
                              )}
                          </div>
                        </li>
                      );
                    }
                  )}
                </ul>
                <Divider />
                <div className={classes.subTitle}>
                  <Typography>{t("VEHICLE.OPTION.CONVINIENCE")}</Typography>
                </div>
                <Divider />
                <ul className={classes.optionList}>
                  {Object.keys(
                    optionList[data.product.type]["CONVINIENCE"]
                  ).map((option, idx) => {
                    return (
                      <li key={idx}>
                        <div>
                          <Checkbox
                            disabled
                            label={
                              <Markup content={t("VEHICLE.OPTION." + option)} />
                            }
                            checked={getCheckboxValue(
                              optionList[data.product.type]["CONVINIENCE"][
                                option
                              ]
                            )}
                            formControlClassName={classes.formControl}
                            checkboxClassName={classes.checkbox}
                          />
                          {data.product.type === "CAR" &&
                            ["CRUISE_CONTROL"].includes(option) && (
                              <RadioGroup
                                row
                                className={classes.radioGroup}
                                value={getRadioGroupValue(
                                  optionList[data.product.type]["CONVINIENCE"][
                                    option
                                  ]
                                )}
                              >
                                {optionList[data.product.type]["CONVINIENCE"][
                                  option
                                ].map((detail_option, idx) => {
                                  return (
                                    <Radio
                                      key={idx}
                                      disabled
                                      label={
                                        <Markup content={detail_option.label} />
                                      }
                                      value={detail_option.value}
                                      formControlClassName={classes.formControl}
                                      radioClassName={classes.radio}
                                    />
                                  );
                                })}
                              </RadioGroup>
                            )}
                          {["AIRCON"].includes(option) && (
                            <RadioGroup
                              row
                              className={classes.radioGroup}
                              value={getRadioGroupValue(
                                optionList[data.product.type]["CONVINIENCE"][
                                  option
                                ]
                              )}
                            >
                              {optionList[data.product.type]["CONVINIENCE"][
                                option
                              ].map((detail_option, idx) => {
                                return (
                                  <Radio
                                    key={idx}
                                    disabled
                                    label={
                                      <Markup content={detail_option.label} />
                                    }
                                    value={detail_option.value}
                                    formControlClassName={classes.formControl}
                                    radioClassName={classes.radio}
                                  />
                                );
                              })}
                            </RadioGroup>
                          )}
                        </div>
                      </li>
                    );
                  })}
                </ul>
                <Divider />
                <div className={classes.subTitle}>
                  <Typography>{t("VEHICLE.OPTION.SAFETY")}</Typography>
                </div>
                <Divider />
                <ul className={classes.optionList}>
                  {Object.keys(optionList[data.product.type]["SAFETY"]).map(
                    (option, idx) => {
                      return (
                        <li key={idx}>
                          <div>
                            <Checkbox
                              disabled
                              label={
                                <Markup
                                  content={t("VEHICLE.OPTION." + option)}
                                />
                              }
                              checked={getCheckboxValue(
                                optionList[data.product.type]["SAFETY"][option]
                              )}
                              formControlClassName={classes.formControl}
                              checkboxClassName={classes.checkbox}
                            />
                            {["AIRBAG", "CAMERA", "PARK_SENSOR"].includes(
                              option
                            ) && (
                              <div className={classes.checkboxGroup}>
                                {optionList[data.product.type]["SAFETY"][
                                  option
                                ].map((detail_option, idx) => {
                                  return (
                                    <Checkbox
                                      key={idx}
                                      disabled
                                      label={
                                        <Markup content={detail_option.label} />
                                      }
                                      checked={getCheckboxValue([
                                        optionList[data.product.type]["SAFETY"][
                                          option
                                        ][idx],
                                      ])}
                                      formControlClassName={classes.formControl}
                                      checkboxClassName={classes.checkbox}
                                    />
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        </li>
                      );
                    }
                  )}
                </ul>
                <Divider />
                <Typography
                  className={classes.viewTitle}
                  onClick={handleViewAll}
                >
                  {t("DETAIL.OPTION.VIEW_NORMAL")}
                </Typography>
              </div>
            ) : (
              <div className={`${classes.container} tab`}>
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={tabValue}
                  onChange={(_, value) => setTabValue(value)}
                  classes={{
                    root: classes.tabsRoot,
                  }}
                >
                  <Tab
                    icon={
                      <Badge
                        badgeContent={inexteriorCount}
                        classes={{
                          badge: classes.badgeBadge,
                        }}
                      >
                        <DirectionsCarIcon />
                      </Badge>
                    }
                    label={
                      <Markup content={t("VEHICLE.OPTION.INEXTERIOR_BR")} />
                    }
                    classes={{
                      root: classes.tabRoot,
                      selected: classes.tabSelected,
                    }}
                    {...a11yProps(0)}
                  />
                  <Tab
                    icon={
                      <Badge
                        badgeContent={seatCount}
                        color="primary"
                        classes={{
                          badge: classes.badgeBadge,
                        }}
                      >
                        <AirlineSeatReclineNormalIcon />
                      </Badge>
                    }
                    label={t("VEHICLE.OPTION.SEAT")}
                    classes={{
                      root: classes.tabRoot,
                      selected: classes.tabSelected,
                    }}
                    {...a11yProps(1)}
                  />
                  <Tab
                    icon={
                      <Badge
                        badgeContent={convinienceCount}
                        color="primary"
                        classes={{
                          badge: classes.badgeBadge,
                        }}
                      >
                        <ThumbUpOffAltIcon />
                      </Badge>
                    }
                    label={t("VEHICLE.OPTION.CONVINIENCE")}
                    classes={{
                      root: classes.tabRoot,
                      selected: classes.tabSelected,
                    }}
                    {...a11yProps(2)}
                  />
                  <Tab
                    icon={
                      <Badge
                        badgeContent={safetyCount}
                        color="primary"
                        classes={{
                          badge: classes.badgeBadge,
                        }}
                      >
                        <HealthAndSafetyIcon />
                      </Badge>
                    }
                    label={t("VEHICLE.OPTION.SAFETY")}
                    classes={{
                      root: classes.tabRoot,
                      selected: classes.tabSelected,
                    }}
                    {...a11yProps(3)}
                  />
                </Tabs>
                <TabPanel value={tabValue} index={0}>
                  <ul className={`${classes.optionList} tab`}>
                    {Object.keys(
                      optionList[data.product.type]["INEXTERIOR"]
                    ).map((option, idx) => {
                      return (
                        <li key={idx}>
                          <div>
                            <Checkbox
                              disabled
                              label={
                                <Markup
                                  content={t("VEHICLE.OPTION." + option)}
                                />
                              }
                              checked={getCheckboxValue(
                                optionList[data.product.type]["INEXTERIOR"][
                                  option
                                ]
                              )}
                              formControlClassName={classes.formControl}
                              checkboxClassName={classes.checkbox}
                            />
                            {data.product.type === "CAR" &&
                              ["SUNROOF"].includes(option) && (
                                <RadioGroup
                                  row
                                  className={classes.radioGroup}
                                  value={getRadioGroupValue(
                                    optionList[data.product.type]["INEXTERIOR"][
                                      option
                                    ]
                                  )}
                                >
                                  {optionList[data.product.type]["INEXTERIOR"][
                                    option
                                  ].map((detail_option, idx) => {
                                    return (
                                      <Radio
                                        key={idx}
                                        disabled
                                        label={
                                          <Markup
                                            content={detail_option.label}
                                          />
                                        }
                                        value={detail_option.value}
                                        formControlClassName={
                                          classes.formControl
                                        }
                                        radioClassName={classes.radio}
                                      />
                                    );
                                  })}
                                </RadioGroup>
                              )}
                            {["HEADLAMP"].includes(option) && (
                              <RadioGroup
                                row
                                className={classes.radioGroup}
                                value={getRadioGroupValue(
                                  optionList[data.product.type]["INEXTERIOR"][
                                    option
                                  ]
                                )}
                              >
                                {optionList[data.product.type]["INEXTERIOR"][
                                  option
                                ].map((detail_option, idx) => {
                                  return (
                                    <Radio
                                      key={idx}
                                      disabled
                                      label={
                                        <Markup content={detail_option.label} />
                                      }
                                      value={detail_option.value}
                                      formControlClassName={classes.formControl}
                                      radioClassName={classes.radio}
                                    />
                                  );
                                })}
                              </RadioGroup>
                            )}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                  <ul className={`${classes.optionList} tab`}>
                    {Object.keys(optionList[data.product.type]["SEAT"]).map(
                      (option, idx) => {
                        return (
                          <li key={idx}>
                            <div>
                              <Checkbox
                                disabled
                                label={
                                  <Markup
                                    content={t("VEHICLE.OPTION." + option)}
                                  />
                                }
                                checked={getCheckboxValue(
                                  optionList[data.product.type]["SEAT"][option]
                                )}
                                formControlClassName={classes.formControl}
                                checkboxClassName={classes.checkbox}
                              />
                              {data.product.type === "CAR" &&
                                [
                                  "HEAT_SEAT",
                                  "POWER_SEAT",
                                  "VENT_SEAT",
                                  "MEMORY_SEAT",
                                ].includes(option) && (
                                  <div className={classes.checkboxGroup}>
                                    {optionList[data.product.type]["SEAT"][
                                      option
                                    ].map((detail_option, idx) => {
                                      return (
                                        <Checkbox
                                          key={idx}
                                          disabled
                                          label={
                                            <Markup
                                              content={detail_option.label}
                                            />
                                          }
                                          checked={getCheckboxValue([
                                            optionList[data.product.type][
                                              "SEAT"
                                            ][option][idx],
                                          ])}
                                          formControlClassName={
                                            classes.formControl
                                          }
                                          checkboxClassName={classes.checkbox}
                                        />
                                      );
                                    })}
                                  </div>
                                )}
                            </div>
                          </li>
                        );
                      }
                    )}
                  </ul>
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                  <ul className={`${classes.optionList} tab`}>
                    {Object.keys(
                      optionList[data.product.type]["CONVINIENCE"]
                    ).map((option, idx) => {
                      return (
                        <li key={idx}>
                          <div>
                            <Checkbox
                              disabled
                              label={
                                <Markup
                                  content={t("VEHICLE.OPTION." + option)}
                                />
                              }
                              checked={getCheckboxValue(
                                optionList[data.product.type]["CONVINIENCE"][
                                  option
                                ]
                              )}
                              formControlClassName={classes.formControl}
                              checkboxClassName={classes.checkbox}
                            />
                            {data.product.type === "CAR" &&
                              ["CRUISE_CONTROL"].includes(option) && (
                                <RadioGroup
                                  row
                                  className={classes.radioGroup}
                                  value={getRadioGroupValue(
                                    optionList[data.product.type][
                                      "CONVINIENCE"
                                    ][option]
                                  )}
                                >
                                  {optionList[data.product.type]["CONVINIENCE"][
                                    option
                                  ].map((detail_option, idx) => {
                                    return (
                                      <Radio
                                        key={idx}
                                        disabled
                                        label={
                                          <Markup
                                            content={detail_option.label}
                                          />
                                        }
                                        value={detail_option.value}
                                        formControlClassName={
                                          classes.formControl
                                        }
                                        radioClassName={classes.radio}
                                      />
                                    );
                                  })}
                                </RadioGroup>
                              )}
                            {["AIRCON"].includes(option) && (
                              <RadioGroup
                                row
                                className={classes.radioGroup}
                                value={getRadioGroupValue(
                                  optionList[data.product.type]["CONVINIENCE"][
                                    option
                                  ]
                                )}
                              >
                                {optionList[data.product.type]["CONVINIENCE"][
                                  option
                                ].map((detail_option, idx) => {
                                  return (
                                    <Radio
                                      key={idx}
                                      disabled
                                      label={
                                        <Markup content={detail_option.label} />
                                      }
                                      value={detail_option.value}
                                      formControlClassName={classes.formControl}
                                      radioClassName={classes.radio}
                                    />
                                  );
                                })}
                              </RadioGroup>
                            )}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </TabPanel>
                <TabPanel value={tabValue} index={3}>
                  <ul className={`${classes.optionList} tab`}>
                    {Object.keys(optionList[data.product.type]["SAFETY"]).map(
                      (option, idx) => {
                        return (
                          <li key={idx}>
                            <div>
                              <Checkbox
                                disabled
                                label={
                                  <Markup
                                    content={t("VEHICLE.OPTION." + option)}
                                  />
                                }
                                checked={getCheckboxValue(
                                  optionList[data.product.type]["SAFETY"][
                                    option
                                  ]
                                )}
                                formControlClassName={classes.formControl}
                                checkboxClassName={classes.checkbox}
                              />
                              {["AIRBAG", "CAMERA", "PARK_SENSOR"].includes(
                                option
                              ) && (
                                <div className={classes.checkboxGroup}>
                                  {optionList[data.product.type]["SAFETY"][
                                    option
                                  ].map((detail_option, idx) => {
                                    return (
                                      <Checkbox
                                        key={idx}
                                        disabled
                                        label={
                                          <Markup
                                            content={detail_option.label}
                                          />
                                        }
                                        checked={getCheckboxValue([
                                          optionList[data.product.type][
                                            "SAFETY"
                                          ][option][idx],
                                        ])}
                                        formControlClassName={
                                          classes.formControl
                                        }
                                        checkboxClassName={classes.checkbox}
                                      />
                                    );
                                  })}
                                </div>
                              )}
                            </div>
                          </li>
                        );
                      }
                    )}
                  </ul>
                </TabPanel>
                <Typography
                  className={classes.viewTitle}
                  onClick={handleViewAll}
                >
                  {t("DETAIL.OPTION.VIEW_ALL")}
                </Typography>
              </div>
            )}
          </>
        </>
      )}
    </>
  );
};

export default OptionContainer;
