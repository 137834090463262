import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
// Redux
import {
  setErrorText,
  setConfirmText,
} from "@/common/lib/redux/actions/commonAction";
import { useTypedDispatch, useTypedSelector } from "@/common/lib/redux/store";
// Component
import {
  Backdrop,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider,
  IconButton,
  Theme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@/common/components";
// Pages
import AuthVerify from "./pages/auth/AuthVerify";
import LoginPage from "./pages/auth/LoginPage";
import IndexPage from "./pages/index/IndexPage";
import ColorPage from "./pages/index/ColorPage";
import JoinPage from "./pages/user/JoinPage";
import MyInfoPage from "./pages/user/MyInfoPage";
import ProfilePage from "./pages/about/ProfilePage";
import ListPage from "./pages/vehicle/ListPage";
import DetailPage from "./pages/vehicle/DetailPage";
import WishListPage from "./pages/mylist/WishListPage";
import BookingPage from "./pages/mylist/BookingPage";
import PurchaseHistoryPage from "./pages/mylist/PurchaseHistoryPage";
import OfferPage from "./pages/offer/OfferPage";
import OfferListPage from "./pages/offer/OfferListPage";
import ShippingPage from "./pages/support/ShippingPage";
import NotFoundPage from "./pages/common/NotFoundPage";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// Libs
import { isNotNull } from "@/common/lib/common";

const styles = (theme: Theme) => ({
  dialog: {
    maxWidth: 900,
    width: "fit-content",
    height: "fit-content",
    padding: "12px 45px 16px",
    borderRadius: 10,
    userSelect: "none",
  },
  dialogTitle: {
    padding: "16px 24px 8px",
    textAlign: "center",
  },
  dialogContent: {
    padding: "10px 24px 12px",
    textAlign: "center",
    "& > p > span > em": {
      fontSize: "1.25rem",
      fontWeight: "bold",
      fontStyle: "normal",
      color: theme.palette.primary.main,
      userSelect: "text",
    },
  },
  dialogActions: {
    "& > button": {
      fontSize: "1rem",
    },
  },
  closeButton: {
    position: "absolute",
    top: 5,
    right: 5,
    zIndex: 2,
    "& > svg": {
      fontSize: "1.75rem",
    },
  },
  circularRoot: {
    width: "175px !important",
    height: "175px !important",
  },
  circularCircle: {
    color: theme.palette.primary.main,
  },
});

const BrowserApp = () => {
  const classes = useStyles(styles);
  const { t } = useTranslation();
  const { errorTitle, errorContent, errorOnClose } = useTypedSelector(
    (state) => state.common
  );
  const { confirmTitle, confirmContent, confirmOnApply, confirmOnClose } =
    useTypedSelector((state) => state.common);
  const { loading } = useTypedSelector((state) => state.common);
  const dispatch = useTypedDispatch();

  const handleOnClose = () => {
    dispatch(
      setErrorText({
        title: "",
        content: "",
        onClose: () => {},
      })
    );
    errorOnClose();
  };
  const handleConfirmOnApply = () => {
    dispatch(
      setConfirmText({
        title: "",
        content: "",
        onApply: () => {},
        onClose: () => {},
      })
    );
    confirmOnApply();
  };
  const handleConfirmOnClose = () => {
    dispatch(
      setConfirmText({
        title: "",
        content: "",
        onApply: () => {},
        onClose: () => {},
      })
    );
    confirmOnClose();
  };

  return (
    <>
      <Router>
        <AuthVerify />
        <Routes>
          <Route element={<IndexPage />} path="/" />
          <Route element={<JoinPage />} path="/join" />
          <Route element={<LoginPage />} path="/login" />
          <Route element={<MyInfoPage />} path="/myinfo" />
          <Route element={<ListPage />} path="/vehicle/list" />
          <Route element={<DetailPage />} path="/vehicle/detail/:uid" />
          <Route element={<WishListPage />} path="/wishlist" />
          <Route element={<OfferListPage />} path="/offerlist" />
          <Route element={<BookingPage />} path="/booking" />
          <Route element={<PurchaseHistoryPage />} path="/purchase-history" />
          <Route element={<ProfilePage />} path="/profile" />
          <Route element={<ShippingPage />} path="/shipping" />
          <Route element={<OfferPage />} path="/offer" />
          <Route element={<ColorPage />} path="/color" />
          <Route element={<NotFoundPage />} path="*" />
        </Routes>
      </Router>
      <Dialog
        classes={{ paper: classes.dialog }}
        open={errorTitle !== "" || errorContent !== "" ? true : false}
        onClose={handleOnClose}
      >
        <IconButton className={classes.closeButton} onClick={handleOnClose}>
          <CloseIcon />
        </IconButton>
        <DialogTitle classes={{ root: classes.dialogTitle }}>
          <Markup content={errorTitle} />
        </DialogTitle>
        <Divider />
        {isNotNull(errorContent) && (
          <DialogContent classes={{ root: classes.dialogContent }}>
            <DialogContentText>
              <Markup content={errorContent} />
            </DialogContentText>
          </DialogContent>
        )}
      </Dialog>
      <Dialog
        classes={{ paper: classes.dialog }}
        open={confirmTitle !== "" || confirmContent !== "" ? true : false}
        onClose={handleConfirmOnClose}
        aria-labelledby="confirm-title"
        aria-describedby="confirm-description"
      >
        <DialogTitle id="confirm-title" classes={{ root: classes.dialogTitle }}>
          <Markup content={confirmTitle} />
        </DialogTitle>
        {isNotNull(confirmContent) && (
          <DialogContent classes={{ root: classes.dialogContent }}>
            <DialogContentText id="confirm-description">
              <Markup content={confirmContent} />
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions classes={{ root: classes.dialogActions }}>
          <Button onClick={handleConfirmOnApply}>{t("OPTION.YES")}</Button>
          <Button onClick={handleConfirmOnClose}>{t("OPTION.CANCEL")}</Button>
        </DialogActions>
      </Dialog>
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress
          classes={{
            root: classes.circularRoot,
            circle: classes.circularCircle,
          }}
          thickness={2.75}
        />
      </Backdrop>
    </>
  );
};

export default BrowserApp;
