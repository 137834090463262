import { useTranslation } from "react-i18next";
// Components
import { Breadcrumbs, Link, Theme, Typography } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import LoginOnlyPage from "@/mobile/pages/auth/LoginOnlyPage";
import BasePage from "@/mobile/pages/common/BasePage";
import MyInfoContainer from "@/mobile/components/user/MyInfoContainer";
import { SNSFab } from "@/mobile/components/common";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
import { colors } from "@/common/lib/style/palette";

const styles = (theme: Theme) => ({
  basePage: {
    padding: "2vh 5vw",
  },
  title: {
    paddingBottom: "0.75vh",
    borderBottom: "1px solid " + colors.gray[7],
    marginBottom: "1vh",
    "& > p": {
      fontSize: "1.75rem",
      fontWeight: 600,
      fontFamily: '"Exo 2"',
      textAlign: "center",
      color: theme.palette.primary.main,
      cursor: "default",
      userSelect: "none",
      whiteSpace: "nowrap",
      margin: "-1vh -5vw 1vh",
    },
    "& > nav": {
      display: "flex",
      justifyContent: "flex-end",
      "& .MuiLink-root": {
        display: "flex",
        alignItems: "center",
        fontSize: "0.85rem",
        fontWeight: 500,
        color: colors.gray[7],
        "& > svg": {
          fontSize: "1.35rem",
          marginRight: "1vw",
        },
      },
    },
  },
  container: {
    width: "100%",
    margin: "auto",
    marginTop: "3vh",
    marginBottom: "1vh",
  },
});

const MyInfoPage = () => {
  const classes = useStyles(styles);
  const { t } = useTranslation();

  return (
    <LoginOnlyPage>
      <BasePage className={classes.basePage}>
        <div className={classes.title}>
          <Typography>{t("MY_INFO.TITLE")}</Typography>
          <Breadcrumbs>
            <Link underline="hover" color="inherit" href="/">
              <HomeIcon />
              Home
            </Link>
            <Link underline="hover" color="inherit" href="/myinfo">
              My Info
            </Link>
          </Breadcrumbs>
        </div>
        <div className={classes.container}>
          <MyInfoContainer />
        </div>
        <SNSFab />
      </BasePage>
    </LoginOnlyPage>
  );
};

export default MyInfoPage;
