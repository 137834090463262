/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
// Components
import { IconButton, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ScrollComponent, SNSIcon, Image } from "@/common/components";
import {
  CommentContainer,
  ConditionReport,
  InfoContainer,
  InspectionContainer,
  NoticeContainer,
  OptionContainer,
  RecommendContainer,
  SpecialPoint,
} from "@/browser/components/vehicle/detail";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
import { colors } from "@/common/lib/style/palette";
// Libs
import { isNotNull } from "@/common/lib/common";

const styles = () => ({
  container: {},
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 40,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    userSelect: "none",
  },
  modalDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100vw",
    padding: "35px 25px",
    color: "white",
    opacity: 0.85,
    "&.REZ": {
      background: colors.cyan[6],
      borderColor: colors.cyan[6],
    },
    "&.DONE": {
      background: colors.teal[7],
      borderColor: colors.teal[7],
    },
    "&.NOT": {
      background: colors.red[7],
      borderColor: colors.red[7],
    },
  },
  modalClose: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    "& > button": {
      margin: 0,
      padding: 8,
      "& > svg": {
        fontSize: "2.85rem",
      },
    },
  },
  modalText: {
    width: "100%",
    textAlign: "center",
    "& .title": {
      fontSize: "2rem",
      fontWeight: "bold",
      margin: "10px 0",
    },
    "& .consult": {
      fontSize: "1.25rem",
      marginBottom: 5,
    },
    "& .detail": {
      fontSize: "1.15rem",
      marginBottom: 15,
    },
  },
  modalSNS: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    "& > .MuiFab-root": {
      marginLeft: 20,
    },
  },
});

interface propType {
  data: VehicleData | undefined;
}

const DetailContainer = ({ data }: propType) => {
  const classes = useStyles(styles);
  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    window.onbeforeunload = () => {
      window.scrollTo(0, 0);
    };
  }, []);

  useEffect(() => {
    if (data !== undefined) {
      if (isNotNull(data.sales.status)) {
        if (
          data.sales.status === "REZ" ||
          data.sales.status === "DONE" ||
          data.sales.status === "NOT"
        ) {
          setModalOpen(true);
        } else {
          setModalOpen(false);
        }
      }
    }
  }, [data]);

  return (
    <>
      {data !== undefined && (
        <>
          <div className={classes.container}>
            <InfoContainer data={data} />
            <ScrollComponent>
              <SpecialPoint data={data} />
            </ScrollComponent>
            <ScrollComponent>
              <OptionContainer data={data} />
            </ScrollComponent>
            <ScrollComponent>
              <ConditionReport data={data} />
            </ScrollComponent>
            <ScrollComponent>
              <InspectionContainer data={data} />
            </ScrollComponent>
            <ScrollComponent>
              <CommentContainer data={data} />
            </ScrollComponent>
            <div className={classes.imageContainer}>
              <Image
                alt=""
                src={require(`@/browser/images/image.png`)}
                className={classes.image}
              />
            </div>
            <ScrollComponent>
              <NoticeContainer />
            </ScrollComponent>
            <ScrollComponent>
              <RecommendContainer data={data} />
            </ScrollComponent>
          </div>
          <Modal
            className={classes.modal}
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            disableAutoFocus={true}
          >
            <div>
              <div className={classes.modalClose}>
                <IconButton onClick={() => setModalOpen(false)}>
                  <CloseIcon sx={{ color: "white", fontSize: "2rem" }} />
                </IconButton>
              </div>
              <div className={`${classes.modalDiv} ${data.sales.status}`}>
                <div className={classes.modalText}>
                  <Typography className="title">
                    {t("DETAIL.STATUS." + data.sales.status)}
                  </Typography>
                  <Typography className="consult">
                    <Markup content={t("DETAIL.STATUS.CONSULT")} />
                  </Typography>
                  <Typography className="detail">
                    {t("DETAIL.STATUS.COMMENT")}
                  </Typography>
                </div>
                <div className={classes.modalSNS}>
                  <SNSIcon sns="whatsApp" />
                  <SNSIcon sns="facebook" />
                  <SNSIcon sns="youtube" />
                  <SNSIcon sns="instagram" />
                </div>
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default DetailContainer;
