import React, { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
// Components
import {
  AppBar,
  Grid,
  IconButton,
  Toolbar,
  Theme,
  Typography,
  useScrollTrigger,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Dropdown } from "@/common/components";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// Libs
import i18n from "@/common/lib/lang/i18n";

const styles = (theme: Theme) => ({
  appbar: {
    background: theme.palette.logo.darkBlue,
  },
  toolbar: {
    padding: "1.5vh 4vw",
  },
  gridItem: {
    display: "flex",
    alignItems: "center",
    "&.left": {
      justifyContent: "flex-start",
    },
    "&.center": {
      justifyContent: "center",
    },
    "&.right": {
      paddingRight: "2vw",
      justifyContent: "flex-end",
    },
  },
  topTitle: {
    fontSize: "1.35rem",
    fontWeight: 500,
  },
  dropdownFormControl: {
    width: "5em",
    fontSize: "0.875rem !important",
  },
  dropdownSelect: {
    height: "2.15em !important",
    letterSpacing: "-0.05rem",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.background.default + "!important",
    },
    "& .MuiSelect-select": {
      color: theme.palette.background.default,
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.background.default + "!important",
    },
  },
  dropdownMenuItem: {
    fontSize: "0.875rem",
    letterSpacing: "-0.05rem",
  },
});

const ElevationScroll = (props: any) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: props.threshold,
    target: window,
  });

  return React.cloneElement(props.children, { elevation: trigger ? 2 : 0 });
};

const TopContainer = ({ data }: any) => {
  const classes = useStyles(styles);
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const langList = useMemo(() => {
    const i18nLangList: Option[] = [];
    if (i18n.options.resources) {
      for (const [key, value] of Object.entries(i18n.options.resources)) {
        i18nLangList.push({ label: String(value.label), value: key });
      }
    }
    return i18nLangList;
  }, []);

  const handleCopyOnClick = () => {
    navigator.clipboard.writeText(window.location.href).then(
      () => {
        enqueueSnackbar(t("MSG.COPY_SUCCESS"), { variant: "success" });
      },
      () => {
        enqueueSnackbar(t("MSG.COPY_FAILURE"), { variant: "error" });
      }
    );
  };

  return (
    <>
      <ElevationScroll threshold={0}>
        <AppBar id="toolbar" className={classes.appbar}>
          <Toolbar className={classes.toolbar}>
            <Grid container>
              <Grid item xs={3} className={`${classes.gridItem} left`}>
                <IconButton
                  className={`${classes.topButton} arrow`}
                  color="inherit"
                  onClick={() => {
                    if (location.key === "default") {
                      navigate("/");
                    } else {
                      navigate(-1);
                    }
                  }}
                >
                  <ArrowBackIosNewIcon />
                </IconButton>
              </Grid>
              <Grid item xs={6} className={`${classes.gridItem} center`}>
                <Typography
                  className={classes.topTitle}
                  onClick={handleCopyOnClick}
                >
                  {data !== undefined ? data.product.uid : "AC00000000"}
                </Typography>
              </Grid>
              <Grid item xs={3} className={`${classes.gridItem} right`}>
                <Dropdown
                  value={i18n.language}
                  options={langList}
                  dispatch={i18n.changeLanguage}
                  formControlClassName={classes.dropdownFormControl}
                  selectClassName={classes.dropdownSelect}
                  menuItemClassName={classes.dropdownMenuItem}
                />
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Toolbar className={classes.toolbar}>
        <Grid container>
          <Grid item xs={3} className={`${classes.gridItem} left`}>
            <IconButton
              className={`${classes.topButton} arrow`}
              color="inherit"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIosNewIcon />
            </IconButton>
          </Grid>
          <Grid item xs={6} className={`${classes.gridItem} center`}>
            <Typography
              className={classes.topTitle}
              onClick={handleCopyOnClick}
            >
              {data !== undefined ? data.product.uid : "AC00000000"}
            </Typography>
          </Grid>
          <Grid item xs={3} className={`${classes.gridItem} right`}></Grid>
        </Grid>
      </Toolbar>
    </>
  );
};

export default TopContainer;
