/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
// Components
import { keyframes, Grid, Theme, Typography } from "@mui/material";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// Libs
import { isNotNull } from "@/common/lib/common";

const fadein = keyframes`
  from {
    opacity: 0;
    transform: translateY(5px);
  }
  to {
    opacity: 0.9;
    transform: none;
  }
`;

const styles = (theme: Theme) => ({
  container: {
    userSelect: "none",
  },
  title: {
    fontSize: "1.25rem",
    fontWeight: "bold",
    userSelect: "none",
    marginBottom: "1vh",
    "& > span > span": {
      fontSize: "1.5rem",
      color: theme.palette.primary.main,
    },
  },
  imageContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "2.5vh",
    "& > span": {
      width: "100%",
      aspectRatio: "1.65",
      marginTop: "1.5vh",
      marginBottom: "2.5vh",
      borderRadius: "1.5rem",
      backgroundSize: "105%",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "50% 50%",
      transition: "background-size .7s ease-out",
      "&:hover": {
        backgroundSize: "100%",
      },
    },
    "& > div.container": {
      width: "100%",
      "& > .title": {
        fontSize: "1.25rem",
        fontWeight: 500,
        wordSpacing: "-0.05rem",
        wordBreak: "keep-all",
        marginBottom: "1vh",
        "& > span > span": {
          fontSize: "1.5rem",
          fontWeight: "bold",
          color: theme.palette.primary.main,
        },
      },
      "& > .content": {
        fontSize: "0.9rem",
        color: theme.palette.text.secondary,
        wordSpacing: "-0.05rem",
        wordBreak: "keep-all",
      },
      "&.left > p": {
        textAlign: "left",
      },
      "&.right > p": {
        textAlign: "right",
      },
    },
  },
  inspectionAlert: {
    margin: "0 auto 2.5vh",
    width: "fit-content",
    height: "fit-content",
    padding: "3vh 6vw",
    background: "white",
    borderRadius: "0.85rem",
    opacity: 0.9,
    animation: `${fadein} 1500ms ${theme.transitions.easing.easeInOut}`,
    "&.noData": {
      border: "0.25rem solid " + theme.palette.error.main,
      color: theme.palette.error.main,
    },
    "&.accidentFree": {
      border: "0.25rem solid " + theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
    "& > p.title": {
      margin: 0,
      marginBottom: "0.5vh",
      fontSize: "1rem",
      fontWeight: "bold",
      textAlign: "center",
    },
    "& > p.subtitle": {
      margin: 0,
      fontSize: "0.75rem",
      textAlign: "center",
    },
  },
});

interface propType {
  data: VehicleData;
}

const SpecialPointItem = ({ position = "left", data, ...props }: any) => {
  const classes = useStyles(styles);
  const { t } = useTranslation();

  return (
    <div {...props} className={classes.imageContainer}>
      <span style={{ backgroundImage: "url(" + data.image + ")" }} />
      <div className={`${position} container`}>
        <Typography className="title">
          <Markup
            content={t("DETAIL.SPECIAL_POINT.MSG.TITLE", {
              point: data.title,
            })}
          />
        </Typography>
        <Typography className="content">
          <Markup content={data.content} />
        </Typography>
      </div>
    </div>
  );
};

const SpecialPoint = ({ data }: propType) => {
  const classes = useStyles(styles);
  const { t } = useTranslation();

  const [isInit, setIsInit] = useState(false);
  const [isAccident, setIsAccident] = useState(0); // 0 : Accident, 1: No Accident, 2: Perfect

  useEffect(() => {
    if (!isInit && data !== undefined) {
      // 무사고/무사고이력/무단순수리 검사
      const checkInspection = (data: VehicleData) => {
        let checkValue = true;
        if (data !== undefined) {
          Object.entries(data.inspection).forEach(([key, value]) => {
            if (
              ![
                "hole",
                "corrosion",
                "crack",
                "accident_history",
                "simple_repair",
              ].includes(key)
            ) {
              if (isNotNull(value)) {
                checkValue = false;
              }
            }
          });
        }
        return checkValue;
      };

      if (
        data.inspection.accident_history === "N" &&
        data.inspection.simple_repair === "N" &&
        checkInspection(data)
      ) {
        setIsAccident(2);
      } else if (
        isNotNull(data.inspection.accident_history) &&
        isNotNull(data.inspection.simple_repair) &&
        checkInspection(data)
      ) {
        setIsAccident(1);
      }
      setIsInit(true);
    }
  }, [data]);

  return (
    <>
      {data !== undefined && isAccident !== 0 && (
        <>
          <Typography className={classes.title}>
            <Markup content={t("DETAIL.SPECIAL_POINT.TITLE_MOBILE")} />
          </Typography>
          <Grid container className={classes.container}>
            {data.special_point !== undefined && (
              <Grid item xs={12}>
                <SpecialPointItem
                  position="right"
                  data={{
                    image: data.special_point.image,
                    title: t(
                      "VEHICLE.SPECIAL_POINT." + data.special_point.type
                    ),
                    content: t(
                      "DETAIL.SPECIAL_POINT.MSG." + data.special_point.type
                    ),
                  }}
                />
              </Grid>
            )}
          </Grid>
        </>
      )}
    </>
  );
};

export default SpecialPoint;
