/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
// Redux
import { useTypedSelector } from "@/common/lib/redux/store";
// Components
import { Theme, Typography } from "@mui/material";
import { FavoriteButton, Image } from "@/common/components";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
import { colors } from "@/common/lib/style/palette";
// Libs
import {
  isNotNull,
  openNewTab,
  getAutowiniCommission,
  getAutowiniBusCommission,
} from "@/common/lib/common";

const styles = (theme: Theme) => ({
  border: {
    zIndex: 1,
    overflow: "hidden",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    borderRadius: 10,
    border: "1px solid " + theme.palette.divider,
    boxShadow: "0 0 5px " + theme.palette.divider,
    cursor: "pointer",
    pointerEvents: "auto",
    "&:hover": {
      border: "3px solid " + theme.palette.primary.main,
      "& > div": {
        top: 16,
        left: -38,
      },
    },
  },
  discountLabel: {
    zIndex: 3,
    position: "absolute",
    top: 18,
    left: -36,
    width: 150,
    height: 40,
    transform: "rotate(-45deg)",
    background: theme.palette.error.main,
    color: "white",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 13,
    lineHeight: 1.25,
    opacity: 0.85,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "& > p": {
      margin: 0,
      "&.discount": {
        fontSize: 14.5,
      },
    },
  },
  container: {
    display: "flex",
    alignItems: "top",
    width: "calc(100% - 20px)",
    height: "calc(100% - 20px)",
    margin: 10,
  },
  image: {
    width: 225,
    aspectRatio: "1.33",
    borderRadius: 3,
    objectFit: "contain",
  },
  status: {
    width: 225,
    height: 25,
    textAlign: "center",
    color: "white",
    marginTop: 2.5,
    "&.WAIT": {
      backgroundColor: colors.gray[8],
      borderColor: colors.gray[8],
    },
    "&.ON": {
      backgroundColor: colors.blue[7],
      borderColor: colors.blue[7],
    },
    "&.REZ": {
      backgroundColor: colors.cyan[6],
      borderColor: colors.cyan[6],
    },
    "&.DONE": {
      backgroundColor: colors.teal[7],
      borderColor: colors.teal[7],
    },
    "&.NOT": {
      backgroundColor: colors.red[7],
      borderColor: colors.red[7],
    },
  },
  infoDiv: {
    display: "flex",
    flexDirection: "column",
    width: "calc(100% - 280px)",
    margin: 3,
    paddingLeft: 30,
  },
  topDiv: {
    width: "100%",
    marginBottom: 5,
  },
  tableDiv: {
    width: 575,
    marginLeft: 30,
    "& dl": {
      height: 23.5,
    },
    "& dl:nth-child(2n)": {
      width: "55%",
    },
    "& dl:nth-child(2n+1)": {
      width: "45%",
      "&:last-child": {
        width: "100%",
      },
    },
    "& dt": {
      width: 100,
    },
    "& dd": {
      width: "calc(100% - 100px)",
    },
    "& dl, & dt, & dd": {
      display: "inline-block",
      margin: 0,
      fontSize: 14,
    },
  },
  priceDiv: {
    position: "absolute",
    right: 13,
    bottom: 13,
    "& .MuiTypography-root": {
      fontSize: "1.45rem",
      lineHeight: 1,
      textAlign: "end",
      "&.normal": {
        fontWeight: "bold",
      },
      "&.discount": {
        fontSize: 24,
        fontWeight: "bold",
        color: theme.palette.error.main,
      },
      "&.discountLabel": {
        fontSize: 20,
        textDecoration: "line-through",
        textDecorationThickness: 2,
        color: theme.palette.grey[500],
      },
    },
  },
  title: {
    height: 25,
    overflow: "hidden",
    fontSize: 18.5,
    fontWeight: "bold",
  },
  subtitle: {
    height: 25,
    overflow: "hidden",
    fontSize: 16.5,
    fontWeight: 500,
  },
  copyText: {
    position: "relative",
    zIndex: 2,
    cursor: "copy",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  favorite: {
    position: "absolute",
    top: 10,
    right: 10,
    "& > svg": {
      fontSize: "1.85rem",
    },
  },
});

interface propType {
  data: VehicleData;
}

const GeneralListItem = ({ data }: propType) => {
  const classes = useStyles(styles);
  const { t } = useTranslation();
  const { loginToken } = useTypedSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();

  const [isDiscount, setIsDiscount] = useState(false);

  const getMainTitle = () => {
    const titles = [];
    titles.push(data.product.year);
    titles.push(data.product.maker);
    titles.push(data.product.detail_model);
    if (data.product.type === "CAR") {
    } else if (data.product.type === "TRUCK") {
      if (data.truck_product !== undefined) {
        titles.push(data.truck_product.tonner.toFixed(1) + "t");
        if (
          typeof data.truck_product.engine_type !== "undefined" &&
          data.truck_product.engine_type !== null
        ) {
          titles.push(data.truck_product.engine_type);
        }
        if (
          typeof data.truck_product.cabin_type !== "undefined" &&
          data.truck_product.cabin_type !== null
        ) {
          titles.push(data.truck_product.cabin_type);
        }
      }
    } else if (data.product.type === "BUS") {
    }
    return titles.join(" ");
  };

  const handleCopyOnClick = (e: any) => {
    navigator.clipboard.writeText(e.target.textContent).then(
      () => {
        enqueueSnackbar(t("MSG.COPY_SUCCESS"), { variant: "success" });
      },
      () => {
        enqueueSnackbar(t("MSG.COPY_FAILURE"), { variant: "error" });
      }
    );
  };

  useEffect(() => {
    if (data.sales.discount_expired !== null) {
      const todayDate = new Date();
      const expireDate = new Date(data.sales.discount_expired);
      expireDate.setHours(expireDate.getHours() + 9);

      if (expireDate >= todayDate) {
        setIsDiscount(true);
      }
    }
  }, []);

  return (
    <>
      <div
        className={classes.border}
        onClick={() => openNewTab("/vehicle/detail/" + data.product.uid)}
      >
        {isNotNull(loginToken) && isDiscount && (
          <div className={classes.discountLabel}>
            <p>SPECIAL</p>
            <p className="discount">DISCOUNT</p>
          </div>
        )}
      </div>
      <div className={classes.container}>
        <div>
          <Image alt="" src={data.media.image[0]} className={classes.image} />
          <Typography className={`${classes.status} ${data.sales.status}`}>
            {t("VEHICLE.STATUS." + data.sales.status)}
          </Typography>
        </div>
        <div className={classes.infoDiv}>
          <div className={classes.topDiv}>
            <Typography className={classes.title}>{getMainTitle()}</Typography>
            <Typography className={classes.subtitle}>
              {data.sales.advert_text}
            </Typography>
          </div>
          <div className={classes.tableDiv}>
            <dl>
              <dt>Prod. No</dt>
              <dd className={classes.copyText} onClick={handleCopyOnClick}>
                {data.product.uid}
              </dd>
            </dl>
            <dl>
              <dt></dt>
              <dd></dd>
            </dl>
            <dl>
              <dt>Fuel Type</dt>
              <dd>{data.product.fuel}</dd>
            </dl>
            <dl>
              <dt>Mission</dt>
              <dd>{data.product.mission}</dd>
            </dl>
            {data.product.type === "CAR" && (
              <>
                <dl>
                  <dt>Steering</dt>
                  <dd>{data.product.steering}</dd>
                </dl>
                {data.car_product !== undefined && (
                  <dl>
                    <dt>Drive Type</dt>
                    <dd>{data.car_product.drive}</dd>
                  </dl>
                )}
                <dl>
                  <dt>Engine Size</dt>
                  <dd>{data.product.exhaust} cc</dd>
                </dl>
                {data.car_product !== undefined && (
                  <dl>
                    <dt>Seats</dt>
                    <dd>{data.car_product.seats}</dd>
                  </dl>
                )}
              </>
            )}
            {data.product.type === "TRUCK" && (
              <>
                {data.truck_product !== undefined && (
                  <>
                    {(data.truck_product.category === "CargoTruck" ||
                      data.truck_product.category === "TopTruck" ||
                      data.truck_product.category === "WindbodyTruck" ||
                      data.truck_product.category === "DumpTruck" ||
                      data.truck_product.category === "Trailer") && (
                      <>
                        {(data.truck_product.category === "TopTruck" ||
                          data.truck_product.category === "WindbodyTruck") && (
                          <dl>
                            <dt>Box Type</dt>
                            <dd>{data.truck_product.sub_category}</dd>
                          </dl>
                        )}
                        {data.truck_product.category === "Trailer" && (
                          <dl>
                            <dt>Container Type</dt>
                            <dd>{data.truck_product.sub_category}</dd>
                          </dl>
                        )}
                        {data.truck_product.load_weight !== null &&
                          data.truck_product.load_weight > 0 && (
                            <dl>
                              <dt>Load Weight</dt>
                              <dd>{data.truck_product.load_weight} t</dd>
                            </dl>
                          )}
                      </>
                    )}
                    {data.truck_product.category === "CraneTruck" && (
                      <dl>
                        <dt>Boom Height</dt>
                        <dd>{data.truck_product.crane_boom_height} m</dd>
                      </dl>
                    )}
                    {data.truck_product.category === "TankLorry" && (
                      <>
                        <dl>
                          <dt>Tank Type</dt>
                          <dd>{data.truck_product.sub_category}</dd>
                        </dl>
                        {data.truck_product.tank_volume !== null &&
                          data.truck_product.tank_volume > 0 && (
                            <dl>
                              <dt>Tank Volume</dt>
                              <dd>{data.truck_product.tank_volume} ℓ</dd>
                            </dl>
                          )}
                      </>
                    )}
                  </>
                )}
                <dl>
                  <dt>Steering</dt>
                  <dd>{data.product.steering}</dd>
                </dl>
                {data.truck_product !== undefined && (
                  <dl>
                    <dt>Drive Type</dt>
                    <dd>{data.truck_product.drive}</dd>
                  </dl>
                )}
                <dl>
                  <dt>Engine Size</dt>
                  <dd>{data.product.exhaust} cc</dd>
                </dl>
              </>
            )}
            {data.product.type === "BUS" && (
              <>
                <dl>
                  <dt>Steering</dt>
                  <dd>{data.product.steering}</dd>
                </dl>
                <dl>
                  <dt>Engine Power</dt>
                  <dd>{data.product.horse_power} HP</dd>
                </dl>
                {data.bus_product !== undefined && (
                  <dl>
                    <dt>Seats</dt>
                    <dd>{data.bus_product.seats}</dd>
                  </dl>
                )}
              </>
            )}
          </div>
          <div className={classes.priceDiv}>
            {/* {isNotNull(loginToken) ? ( */}
            {isDiscount ? (
              <>
                <Typography className="discountLabel">
                  USD{" "}
                  {(
                    data.sales.price +
                    (data.product.type === "CAR" ||
                    data.product.type === "TRUCK"
                      ? getAutowiniCommission(data.sales.price)
                      : getAutowiniBusCommission(data.sales.price))
                  ).toLocaleString()}
                </Typography>
                <Typography className="discount">
                  USD{" "}
                  {(
                    data.sales.price +
                    (data.product.type === "CAR" ||
                    data.product.type === "TRUCK"
                      ? getAutowiniCommission(data.sales.price)
                      : getAutowiniBusCommission(data.sales.price)) -
                    (data.sales.discount !== null ? data.sales.discount : 0)
                  ).toLocaleString()}
                </Typography>
              </>
            ) : (
              <Typography className="normal">
                USD{" "}
                {(
                  data.sales.price +
                  (data.product.type === "CAR" || data.product.type === "TRUCK"
                    ? getAutowiniCommission(data.sales.price)
                    : getAutowiniBusCommission(data.sales.price))
                ).toLocaleString()}
              </Typography>
            )}
            {/* ) : (
              <Typography className="normal">USD *,***</Typography>
            )} */}
          </div>
        </div>
        <FavoriteButton
          className={classes.favorite}
          wish_id={data.wish !== undefined ? data.wish.id : null}
          product_uid={data.product.uid}
        />
      </div>
    </>
  );
};

export default React.memo(GeneralListItem);
