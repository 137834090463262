import ReactPlayer from "react-player";
import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
// Components
import { Card, Theme, Typography } from "@mui/material";
// Styles
import { useStyles } from "@/common/lib/style/hooks";

const styles = (theme: Theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    padding: "0 10px",
  },
  card: {
    width: "37.5%",
    aspectRatio: "1.778",
  },
  logoImg: {
    width: 185,
    height: "fit-content",
    margin: "0 15px",
    cursor: "pointer",
  },
  bannerImg: {
    width: "100%",
  },
  title: {
    width: "62.5%",
    fontSize: "2.5rem",
    fontWeight: "bold",
    cursor: "default",
    lineHeight: "1.35",
    "& > span > span": {
      color: theme.palette.primary.main,
    },
  },
});

const YoutubeContainer = () => {
  const classes = useStyles(styles);
  const { t } = useTranslation();

  const playerSettings = {
    youtube: {
      playerVars: { showinfo: 1 },
    },
  };

  return (
    <div className={classes.container}>
      <Typography className={classes.title}>
        <Markup content={t("PROFILE.TITLE")} />
      </Typography>
      <Card className={classes.card}>
        <ReactPlayer
          url="https://youtu.be/DuM88xJRMtY"
          config={playerSettings}
          controls
          width="100%"
          height="100%"
        />
      </Card>
    </div>
  );
};

export default YoutubeContainer;
