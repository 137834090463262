import { AnyAction } from "redux";

import {
  INIT_WISH_LIST,
  PUSH_WISH_LIST,
  POP_WISH_LIST,
} from "../types/globalType";

interface stateType {
  wishList: number[];
}

const initialState: stateType = {
  wishList: [],
};

const globalReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case INIT_WISH_LIST:
      return {
        ...state,
        wishList: [],
      };
    case PUSH_WISH_LIST:
      if (!state.wishList.includes(action.payload)) {
        return {
          ...state,
          wishList: [...state.wishList, action.payload],
        };
      } else {
        return {
          ...state,
        };
      }
    case POP_WISH_LIST:
      return {
        ...state,
        wishList: state.wishList.filter((item) => item !== action.payload),
      };
    default:
      return state;
  }
};

export default globalReducer;
