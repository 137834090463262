/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
// Components
import { IconButton, Theme } from "@mui/material";
// Styles
import { useStyles } from "@/common/lib/style/hooks";

const styles = (theme: Theme) => ({
  ul: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    listStyle: "none",
    margin: 0,
    padding: 0,
    "& > li": {
      "& > button": {
        width: "2em",
        height: "2em",
        fontSize: "1rem",
        fontFamily: "'Noto Sans', 'Noto Sans KR', sans-serif",
        "&.active": {
          color: theme.palette.primary.main,
        },
        "&:hover": {
          color: theme.palette.primary.main,
        },
      },
    },
  },
});

interface propType {
  size?: number;
  page?: number;
  totalPage: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

const Pagination = ({ size = 10, page = 1, totalPage, setPage }: propType) => {
  const classes = useStyles(styles);

  const [startPage, setStartPage] = useState(1);
  const [endPage, setEndPage] = useState(1);
  const [pages, setPages] = useState<number[]>([]);

  useEffect(() => {
    const step = Math.floor((page - 1) / size) * size;
    setStartPage(step + 1);
    if (step + size <= totalPage) {
      setEndPage(step + size);
    } else {
      setEndPage(totalPage);
    }
  }, [page, totalPage]);

  useEffect(() => {
    setPages(
      [...Array(endPage - startPage + 1).keys()].map((i) => startPage + i)
    );
  }, [startPage, endPage]);

  return (
    <ul className={classes.ul}>
      <li>
        <IconButton
          disabled={page === 1 ? true : false}
          onClick={() => setPage(1)}
        >
          &lt;&lt;
        </IconButton>
      </li>
      <li>
        <IconButton
          disabled={page === 1 ? true : false}
          onClick={() => setPage(page - 1)}
        >
          &lt;
        </IconButton>
      </li>
      {totalPage > 0 ? (
        pages.map((item, index) => (
          <li key={index}>
            <IconButton
              className={item === page ? `active` : ""}
              onClick={() => setPage(item)}
            >
              {item}
            </IconButton>
          </li>
        ))
      ) : (
        <li>
          <IconButton>1</IconButton>
        </li>
      )}
      <li>
        <IconButton
          disabled={page === totalPage || totalPage === 0 ? true : false}
          onClick={() => setPage(page + 1)}
        >
          &gt;
        </IconButton>
      </li>
      <li>
        <IconButton
          disabled={page === totalPage || totalPage === 0 ? true : false}
          onClick={() => setPage(totalPage)}
        >
          &gt;&gt;
        </IconButton>
      </li>
    </ul>
  );
};

export default Pagination;
