import axios from "axios";
// Redux
import { store } from "@/common/lib/redux/store";
// Libs
import { isNotNull } from "@/common/lib/common";

export const getPurchaseHistoryList = async () => {
  const state = store.getState();
  const headers: { [key: string]: string } = {
    "Content-Type": "application/json",
  };
  if (isNotNull(state.auth.loginToken)) {
    headers["Authorization"] = `Bearer ${state.auth.loginToken}`;
  }

  const url = `/api/purchase-history/`;
  const result = await axios({
    method: "get",
    url: url,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
    headers: headers,
    withCredentials: true,
  });
  return result;
};

export const patchPurchaseHistoryInfo = async (data: {
  id: number;
  data: any;
}) => {
  const state = store.getState();
  const headers: { [key: string]: string } = {
    "Content-Type": "application/json",
  };
  if (isNotNull(state.auth.loginToken)) {
    headers["Authorization"] = `Bearer ${state.auth.loginToken}`;
  }

  const url = `/api/purchase-history/${data["id"]}/`;
  const result = await axios({
    method: "patch",
    url: url,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
    headers: headers,
    withCredentials: true,
    data: data["data"],
  });
  return result;
};
