const YoutubeIcon = ({ ...props }: any) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1024 768"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <g transform="translate(0, 768) scale(0.1,-0.1)">
        <path
          d="M4075 6884 c-22 -2 -206 -8 -410 -14 -640 -17 -882 -29 -1250 -61
-698 -60 -879 -116 -1152 -356 -109 -96 -225 -270 -273 -409 -68 -195 -123
-515 -155 -884 -49 -590 -59 -860 -52 -1460 11 -909 58 -1453 163 -1900 45
-192 96 -299 220 -461 156 -204 435 -357 729 -398 288 -40 388 -51 660 -70
501 -37 641 -42 1765 -66 1054 -23 1926 -9 3185 51 221 11 608 47 799 75 157
22 223 38 329 76 161 58 256 119 390 251 249 245 332 488 406 1197 10 96 15
159 43 540 18 234 18 1480 0 1700 -7 88 -17 223 -23 300 -39 550 -113 953
-210 1147 -53 105 -154 243 -230 314 -111 103 -291 206 -440 252 -144 45 -450
83 -974 122 -143 11 -450 24 -925 41 -256 9 -2514 20 -2595 13z m408 -1857
c116 -68 331 -192 477 -276 146 -84 353 -204 460 -266 107 -62 310 -179 450
-260 455 -263 650 -377 650 -380 0 -5 -70 -46 -815 -475 -121 -70 -440 -254
-710 -410 -711 -411 -726 -420 -731 -420 -2 0 -4 587 -4 1305 0 718 2 1305 5
1305 3 0 101 -55 218 -123z"
        />
      </g>
    </svg>
  );
};

export default YoutubeIcon;
