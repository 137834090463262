import { useTranslation } from "react-i18next";
// Components
import { Breadcrumbs, Link, Theme, Typography } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import LoginOnlyPage from "@/browser/pages/auth/LoginOnlyPage";
import BasePage from "@/browser/pages/common/BasePage";
import OfferContainer from "@/browser/components/offer/offer/OfferContainer";
import { SideMenu, SNSFab, TitleBackground } from "@/browser/components/common";
import { Image } from "@/common/components";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
import { colors } from "@/common/lib/style/palette";
// Images
import image from "@/browser/images/support/offer.jpg";

const styles = (theme: Theme) => ({
  basePage: {
    width: "100% !important",
  },
  title: {
    position: "relative",
    minWidth: 1400,
    height: 165,
    background: theme.palette.primary.hard,
    userSelect: "none",
    "& > div": {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: 1140,
      height: "inherit",
      margin: "0 auto",
      background: colors.gray[0],
      "& > svg": {
        position: "absolute",
        height: 165,
        fill: theme.palette.primary.hard,
      },
      "& > .MuiTypography-body1": {
        fontSize: 38,
        fontWeight: 700,
        fontFamily: '"Exo 2"',
        whiteSpace: "nowrap",
      },
      "& > .MuiTypography-body2": {
        fontSize: 18,
        fontWeight: 500,
        fontFamily: '"Exo 2"',
        textAlign: "center",
        whiteSpace: "nowrap",
        "& > span": {
          fontSize: 22,
          fontWeight: 700,
          fontFamily: '"Noto Sans","Noto Sans KR",sans-serif',
          color: theme.palette.primary.main,
        },
      },
    },
  },
  breadcrumbs: {
    minWidth: 1400,
    borderTop: "1px solid " + theme.palette.divider,
    borderBottom: "1px solid " + theme.palette.divider,
    "& > div": {
      display: "flex",
      justifyContent: "flex-end",
      width: 900,
      padding: 10,
      margin: "0 auto",
      "& > nav .MuiLink-root": {
        display: "flex",
        alignItems: "center",
        fontSize: "0.95rem",
        fontWeight: 500,
        color: colors.gray[7],
        "& > svg": {
          fontSize: "1.35rem",
          marginRight: 3,
        },
      },
    },
  },
  container: {
    display: "flex",
    margin: "0 auto",
    width: 1400, // 215 + 35 + 900 + 35 + 215
    "& > div:nth-child(1)": {
      width: 215,
      marginTop: 45,
      marginBottom: 80,
    },
    "& > div:nth-child(2)": {
      width: 900,
      margin: "auto",
      marginTop: 45,
      marginBottom: 80,
    },
    "& > div:nth-child(3)": {
      width: 215,
      marginTop: 45,
    },
  },
  content: {
    position: "relative",
    userSelect: "none",
    marginBottom: 45,
    "& > img": {
      width: "100%",
      filter: "brightness(0.75)",
    },
    "& > div": {
      position: "absolute",
      top: "50%",
      left: 0,
      padding: "0 5%",
      transform: "translate(0, -50%)",
      "& > .MuiTypography-body1": {
        fontSize: "2rem",
        fontWeight: "bold",
        color: "white",
        marginBottom: 10,
        lineHeight: "1.35",
        wordBreak: "keep-all",
      },
      "& > .MuiTypography-body2": {
        "&:before": {
          content: "'*'",
          marginRight: 5,
        },
        fontSize: "0.95rem",
        fontWeight: 500,
        color: "white",
        lineHeight: 1.5,
        marginBottom: 3.5,
        paddingLeft: 8.5,
        textIndent: -8.5,
        wordBreak: "keep-all",
      },
    },
  },
});

const OfferPage = () => {
  const classes = useStyles(styles);
  const { t } = useTranslation();

  return (
    <LoginOnlyPage>
      <BasePage className={classes.basePage}>
        <div className={classes.title}>
          <div>
            <TitleBackground />
            <Typography variant="body1">{t("OFFER.OFFER.TITLE")}</Typography>
            <Typography variant="body2">
              The BEST Choice to buy Korean Used Car!
              <br />
              <span>Autoleader co.,LTD</span>
            </Typography>
          </div>
        </div>
        <div className={classes.breadcrumbs}>
          <div>
            <Breadcrumbs>
              <Link underline="hover" color="inherit" href="/">
                <HomeIcon />
                Home
              </Link>
              <Link underline="hover" color="inherit" href="/offer">
                Offer
              </Link>
              <Link underline="hover" color="inherit" href="/offer">
                Offer R.
              </Link>
            </Breadcrumbs>
          </div>
        </div>
        <div className={classes.container}>
          <SideMenu />
          <div>
            <div className={classes.content}>
              <Image alt="" src={image} />
              <div>
                <Typography variant="body1">
                  {t("OFFER.OFFER.CONTENT")}
                </Typography>
                <Typography variant="body2">
                  {t("OFFER.OFFER.MSG.NOTICE_1")}
                </Typography>
                <Typography variant="body2">
                  {t("OFFER.OFFER.MSG.NOTICE_2")}
                </Typography>
              </div>
            </div>
            <OfferContainer />
          </div>
          <div className={classes.advertContainer} />
        </div>
        <SNSFab />
      </BasePage>
    </LoginOnlyPage>
  );
};

export default OfferPage;
