/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
// Components
import { keyframes, Theme, Typography } from "@mui/material";
import {
  LeftSide,
  FrontSide,
  RearSide,
  RightSide,
} from "@/browser/components/vehicle/inspect";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// Libs
import { isNotNull } from "@/common/lib/common";

const fadein = keyframes`
  from {
    opacity: 0;
    transform: translateY(5px);
  }
  to {
    opacity: 0.9;
    transform: none;
  }
`;

const styles = (theme: Theme) => ({
  container: {
    userSelect: "none",
  },
  title: {
    fontSize: "1.65rem",
    fontWeight: "bold",
    userSelect: "none",
    marginBottom: 25,
    "& > span > span": {
      fontSize: "1.85rem",
      color: theme.palette.primary.main,
    },
  },
  imageContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 25px",
    marginBottom: 60,
    "& > span": {
      width: 550,
      aspectRatio: "1.65",
      borderRadius: 25,
      backgroundSize: "105%",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "50% 50%",
      transition: "background-size .7s ease-out",
      "&:hover": {
        backgroundSize: "100%",
      },
    },
    "& > div.container": {
      width: 550,
      paddingTop: 25,
      "& > .title": {
        fontSize: 29,
        fontWeight: 500,
        wordSpacing: "-0.75px",
        marginBottom: 15,
        "& > span > span": {
          fontSize: 32,
          fontWeight: "bold",
          color: theme.palette.primary.main,
        },
      },
      "& > .content": {
        fontSize: 17,
        color: theme.palette.text.secondary,
        wordSpacing: "-0.75px",
      },
      "&.left > p": {
        textAlign: "left",
      },
      "&.right > p": {
        textAlign: "right",
      },
    },
  },
  inspection: {
    height: "205px !important",
    margin: "7.5px !important",
  },
  inspectionBackground: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: "100%",
    height: "100%",
    borderRadius: 25,
    backdropFilter: "blur(3px)",
  },
  inspectionAlert: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto",
    width: "75%",
    height: "fit-content",
    padding: "20px 25px",
    background: "white",
    borderRadius: 15,
    opacity: 0.9,
    animation: `${fadein} 1500ms ${theme.transitions.easing.easeInOut}`,
    "&.noData": {
      border: "3px solid " + theme.palette.error.main,
      color: theme.palette.error.main,
    },
    "&.accidentFree": {
      border: "3px solid " + theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
    "& > p.title": {
      margin: 0,
      marginBottom: 5,
      fontSize: "1.15rem",
      fontWeight: "bold",
      textAlign: "center",
    },
    "& > p.subtitle": {
      margin: 0,
      fontSize: "0.85rem",
      textAlign: "center",
    },
  },
  inspectionStatus: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    width: 550,
    aspectRatio: "1.65",
    borderRadius: 25,
    border: "1px solid " + theme.palette.divider,
    padding: 20,
  },
});

interface propType {
  data: VehicleData;
}

const SpecialPointItem = ({ position = "left", data, ...props }: any) => {
  const classes = useStyles(styles);
  const { t } = useTranslation();

  return (
    <div {...props} className={classes.imageContainer}>
      {position === "left" && (
        <span style={{ backgroundImage: "url(" + data.image + ")" }} />
      )}
      <div className={`${position} container`}>
        <Typography className="title">
          <Markup
            content={t("DETAIL.SPECIAL_POINT.MSG.TITLE", {
              point: data.title,
            })}
          />
        </Typography>
        <Typography className="content">
          <Markup content={data.content} />
        </Typography>
      </div>
      {position === "right" && (
        <span style={{ backgroundImage: "url(" + data.image + ")" }} />
      )}
    </div>
  );
};

const NoAccidentItem = ({ position = "right", data, isAccident = 0 }: any) => {
  const classes = useStyles(styles);
  const { t } = useTranslation();

  return (
    <div className={classes.imageContainer}>
      {position === "left" && (
        <div className={classes.inspectionStatus}>
          <LeftSide className={classes.inspection} data={data} />
          <FrontSide className={classes.inspection} data={data} />
          <RearSide className={classes.inspection} data={data} />
          <RightSide className={classes.inspection} data={data} />
          <div className={classes.inspectionBackground} />
          <div className={`${classes.inspectionAlert} accidentFree`}>
            <p className="title">{t("DETAIL.SPECIAL_POINT.MSG.NO_ACCIDENT")}</p>
            <p className="subtitle">
              <Markup content={t("DETAIL.SPECIAL_POINT.MSG.CHECK")} />
            </p>
          </div>
        </div>
      )}
      <div className={`${position} container`}>
        <Typography className="title">
          {isAccident === 1 && (
            <Markup
              content={t("DETAIL.SPECIAL_POINT.MSG.TITLE", {
                point: t("VEHICLE.SPECIAL_POINT.ACCIDENT_FREE"),
              })}
            />
          )}
          {isAccident === 2 && (
            <Markup
              content={t("DETAIL.SPECIAL_POINT.MSG.TITLE", {
                point: t("VEHICLE.SPECIAL_POINT.ACCIDENT_FREE_FULL"),
              })}
            />
          )}
        </Typography>
        <Typography className="content">
          {isAccident === 1 && (
            <Markup content={t("DETAIL.SPECIAL_POINT.MSG.ACCIDENT_FREE")} />
          )}
          {isAccident === 2 && (
            <Markup
              content={t("DETAIL.SPECIAL_POINT.MSG.ACCIDENT_FREE_FULL")}
            />
          )}
        </Typography>
      </div>
      {position === "right" && (
        <div className={classes.inspectionStatus}>
          <LeftSide className={classes.inspection} data={data} />
          <FrontSide className={classes.inspection} data={data} />
          <RearSide className={classes.inspection} data={data} />
          <RightSide className={classes.inspection} data={data} />
          <div className={classes.inspectionBackground} />
          <div className={`${classes.inspectionAlert} accidentFree`}>
            <p className="title">{t("DETAIL.SPECIAL_POINT.MSG.NO_ACCIDENT")}</p>
            <p className="subtitle">
              <Markup content={t("DETAIL.SPECIAL_POINT.MSG.CHECK")} />
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

const SpecialPoint = ({ data }: propType) => {
  const classes = useStyles(styles);
  const { t } = useTranslation();

  const [isInit, setIsInit] = useState(false);
  const [isAccident, setIsAccident] = useState(0); // 0 : Accident, 1: No Accident, 2: Perfect

  useEffect(() => {
    if (!isInit && data !== undefined) {
      // 무사고/무사고이력/무단순수리 검사
      const checkInspection = (data: VehicleData) => {
        let checkValue = true;
        if (data !== undefined) {
          Object.entries(data.inspection).forEach(([key, value]) => {
            if (
              ![
                "hole",
                "corrosion",
                "crack",
                "accident_history",
                "simple_repair",
              ].includes(key)
            ) {
              if (isNotNull(value)) {
                checkValue = false;
              }
            }
          });
        }
        return checkValue;
      };

      if (
        data.inspection.accident_history === "N" &&
        data.inspection.simple_repair === "N" &&
        checkInspection(data)
      ) {
        setIsAccident(2);
      } else if (
        isNotNull(data.inspection.accident_history) &&
        isNotNull(data.inspection.simple_repair) &&
        checkInspection(data)
      ) {
        setIsAccident(1);
      }
      setIsInit(true);
    }
  }, [data]);

  return (
    <>
      {(data.special_point !== undefined || isAccident !== 0) && (
        <>
          <Typography className={classes.title}>
            <Markup content={t("DETAIL.SPECIAL_POINT.TITLE")} />
          </Typography>
          <div className={classes.container}>
            {data.special_point !== undefined && (
              <SpecialPointItem
                position="right"
                data={{
                  image: data.special_point.image,
                  title: t("VEHICLE.SPECIAL_POINT." + data.special_point.type),
                  content: t(
                    "DETAIL.SPECIAL_POINT.MSG." + data.special_point.type
                  ),
                }}
              />
            )}
            {isAccident !== 0 && (
              <NoAccidentItem
                position={data.special_point !== undefined ? "left" : "right"}
                data={data}
                isAccident={isAccident}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default SpecialPoint;
