const EngineIcon = ({ ...props }: any) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <g transform="translate(0, 512) scale(0.1,-0.1)">
        <path
          d="M1785 4446 c-91 -41 -130 -148 -85 -236 35 -68 70 -84 188 -88 l102
-4 0 -94 0 -93 -362 -3 c-362 -3 -363 -3 -396 -26 -64 -46 -72 -79 -72 -287
l0 -185 -172 0 c-195 0 -238 -8 -284 -54 -47 -47 -54 -88 -54 -314 l0 -203
-152 3 -153 3 -5 276 -5 276 -27 35 c-76 100 -233 86 -287 -25 -21 -43 -21
-49 -21 -973 0 -884 1 -931 19 -970 27 -59 72 -87 146 -92 50 -3 65 0 94 20
77 52 76 48 79 354 l3 274 154 0 155 0 0 -202 c0 -219 6 -260 47 -309 47 -55
69 -59 325 -59 l233 0 310 -383 c258 -317 318 -386 355 -405 l44 -22 965 0
c959 0 965 0 1008 21 29 14 52 35 68 63 23 40 25 52 25 179 l0 137 84 0 84 0
4 -138 c2 -116 6 -142 24 -172 24 -42 74 -77 121 -86 72 -14 99 4 427 285 261
224 316 275 329 310 15 37 17 107 17 664 l0 623 -22 44 c-20 38 -68 79 -323
272 -165 126 -314 235 -332 243 -91 44 -204 -11 -233 -113 -5 -20 -10 -99 -10
-175 l0 -138 -82 3 -83 3 -5 167 c-4 148 -7 170 -26 194 -54 73 -94 83 -316
84 l-168 0 0 332 c0 316 -1 334 -21 375 -11 23 -35 53 -52 65 -31 22 -41 23
-332 28 l-300 5 0 90 0 90 100 5 c119 6 157 24 192 89 42 79 24 156 -50 215
l-39 31 -601 2 c-488 2 -608 0 -632 -11z"
        />
      </g>
    </svg>
  );
};

export default EngineIcon;
