/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-eval */
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { getCountries, CountryCode } from "libphonenumber-js";
// Components
import {
  Autocomplete as MuiAutocomplete,
  Box,
  InputAdornment,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import Input from "./Input";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// Libs
import { isNotNull } from "@/common/lib/common";

const styles = (theme: Theme, color: string = "primary") => ({
  root: {},
  endAdornment: {
    "& > .MuiIconButton-root:hover": {
      color: eval(`theme.palette.${color}.main`),
    },
  },
  option: {
    color: theme.palette.text.primary,
    "&:hover": {
      color: eval(`theme.palette.${color}.main`),
    },
    "&[aria-selected='true']": {
      color: eval(`theme.palette.${color}.main`),
    },
  },
});

const NationAutocomplete = ({
  label,
  size = "medium",
  color = "primary",
  variant,
  placeholder,
  value,
  dispatch,
  autocompleteClassName,
  textFieldClassName,
  ...props
}: any) => {
  const theme = useTheme();
  const classes = useStyles(styles(theme, color));
  const [countryList, setCountryList] = useState<
    {
      code: CountryCode;
      region: string | undefined;
    }[]
  >([]);

  const getCountryCode = (region: any) => {
    if (typeof region === "string" && region !== "") {
      if (countryList.filter((item) => item.region === region).length > 0) {
        return countryList.filter((item) => item.region === region)[0].code;
      } else {
        return "";
      }
    }
    return "";
  };

  useEffect(() => {
    const regionNames = new Intl.DisplayNames(["en"], { type: "region" });
    const countryList: {
      code: CountryCode;
      region: string | undefined;
    }[] = [];
    getCountries().forEach((countryCode) => {
      if (!["AC", "TA"].includes(countryCode)) {
        countryList.push({
          code: countryCode,
          region: regionNames.of(countryCode),
        });
      }
    });
    const countrySortList = countryList.sort((a, b) => {
      if (a.region !== undefined && b.region !== undefined) {
        return a.region.localeCompare(b.region);
      } else {
        return a.code.localeCompare(b.code);
      }
    });
    setCountryList(countrySortList);
  }, []);

  return (
    <MuiAutocomplete
      size={size}
      classes={{
        root: classes.root,
        endAdornment: classes.endAdornment,
        option: classes.option,
      }}
      className={
        isMobile
          ? `${autocompleteClassName} Mobile`
          : `${autocompleteClassName}`
      }
      value={
        isNotNull(value)
          ? countryList.filter((country: any) => {
              return country.code === value;
            })[0]
          : null
      }
      onChange={(_event, option: any) => {
        isNotNull(option) ? dispatch(option.code) : dispatch("");
      }}
      options={countryList}
      getOptionLabel={(option: any) => option.region}
      renderOption={(props: any, option: any) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.code?.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code?.toLowerCase()}.png 2x`}
            alt=""
          />
          <Typography variant="body1">{option.region}</Typography>
        </Box>
      )}
      renderInput={(params) => (
        <Input
          {...params}
          variant={variant}
          label={label}
          placeholder={placeholder}
          className={textFieldClassName}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 8,
                  }}
                >
                  {getCountryCode(params.inputProps.value) ? (
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${getCountryCode(
                        params.inputProps.value
                      )?.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${getCountryCode(
                        params.inputProps.value
                      )?.toLowerCase()}.png 2x`}
                      alt=""
                    />
                  ) : (
                    <LanguageIcon />
                  )}
                </div>
              </InputAdornment>
            ),
          }}
        />
      )}
      {...props}
    />
  );
};

export default NationAutocomplete;
