import axios from "axios";
// Redux
import { store } from "@/common/lib/redux/store";
// Libs
import { isNotNull } from "@/common/lib/common";

export const getUserInfo = async (puid: string) => {
  const state = store.getState();
  const headers: { [key: string]: string } = {
    "Content-Type": "application/json",
  };
  if (isNotNull(state.auth.loginToken)) {
    headers["Authorization"] = `Bearer ${state.auth.loginToken}`;
  }

  const url = `/auth/users/${puid}/`;
  const result = await axios({
    method: "get",
    url: url,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
    headers: headers,
    withCredentials: true,
  });
  return result;
};

export const postUserInfo = async (data: { data: any }) => {
  const state = store.getState();
  const headers: { [key: string]: string } = {
    "Content-Type": "multipart/form-data",
  };
  if (isNotNull(state.auth.loginToken)) {
    headers["Authorization"] = `Bearer ${state.auth.loginToken}`;
  }

  const sendData = new FormData();
  sendData.append("data", JSON.stringify(data["data"]));
  if (Object.keys(data["data"]).includes("image")) {
    sendData.append("image", data["data"]["image"]);
  }

  const url = "/auth/users/";
  const result = await axios({
    method: "post",
    url: url,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
    data: sendData,
    headers: headers,
    withCredentials: true,
  });
  return result;
};

export const patchUserInfo = async (data: { puid: string; data: any }) => {
  const state = store.getState();
  const headers: { [key: string]: string } = {
    "Content-Type": "multipart/form-data",
  };
  if (isNotNull(state.auth.loginToken)) {
    headers["Authorization"] = `Bearer ${state.auth.loginToken}`;
  }

  const sendData = new FormData();
  sendData.append("data", JSON.stringify(data["data"]));
  if (Object.keys(data["data"]).includes("image")) {
    sendData.append("image", data["data"]["image"]);
  }

  const url = `/auth/users/${data["puid"]}/`;
  const result = await axios({
    method: "patch",
    url: url,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
    data: sendData,
    headers: headers,
    withCredentials: true,
  });
  return result;
};

export const deleteUserInfo = async (data: { puid: string }) => {
  const state = store.getState();
  const headers: { [key: string]: string } = {
    "Content-Type": "application/json",
  };
  if (isNotNull(state.auth.loginToken)) {
    headers["Authorization"] = `Bearer ${state.auth.loginToken}`;
  }

  const url = `/auth/users/${data["puid"]}/`;
  const result = await axios({
    method: "delete",
    url: url,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
    headers: headers,
    withCredentials: true,
  });
  return result;
};

export const findID = async (data: { data: any }) => {
  const state = store.getState();
  const headers: { [key: string]: string } = {
    "Content-Type": "application/json",
  };
  if (isNotNull(state.auth.loginToken)) {
    headers["Authorization"] = `Bearer ${state.auth.loginToken}`;
  }

  const url = `/auth/users/find-id/`;
  const result = await axios({
    method: "post",
    url: url,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
    data: data["data"],
    headers: headers,
    withCredentials: true,
  });
  return result;
};

export const findPWD = async (data: { data: any }) => {
  const state = store.getState();
  const headers: { [key: string]: string } = {
    "Content-Type": "application/json",
  };
  if (isNotNull(state.auth.loginToken)) {
    headers["Authorization"] = `Bearer ${state.auth.loginToken}`;
  }

  const url = `/auth/users/find-pwd/`;
  const result = await axios({
    method: "post",
    url: url,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
    data: data["data"],
    headers: headers,
    withCredentials: true,
  });
  return result;
};

export const checkUserDuplicate = async (data: { data: any }) => {
  const state = store.getState();
  const headers: { [key: string]: string } = {
    "Content-Type": "application/json",
  };
  if (isNotNull(state.auth.loginToken)) {
    headers["Authorization"] = `Bearer ${state.auth.loginToken}`;
  }

  const url = `/auth/users/duplication/`;
  const result = await axios({
    method: "post",
    url: url,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
    data: data["data"],
    headers: headers,
    withCredentials: true,
  });
  return result;
};
