// Common Header, Footer
import { Footer, Header } from "@/browser/components/common";
// Styles
import { useStyles } from "@/common/lib/style/hooks";

const styles = () => ({
  main: {
    width: 1200,
    minHeight: 900,
    margin: "0 auto",
  },
});

const BasePage = ({ isHeader, isFooter, ...props }: { [key: string]: any }) => {
  const classes = useStyles(styles);

  return (
    <>
      {isHeader && <Header />}
      <main className={`${classes.main} ${props.className}`}>
        {props.children}
      </main>
      {isFooter && <Footer />}
    </>
  );
};

BasePage.defaultProps = {
  isHeader: true,
  isFooter: true,
};

export default BasePage;
