// Components
import { Fab } from "@mui/material";

import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// Libs
import { isNotNull } from "@/common/lib/common";

const styles = () => ({
  whatsAppFab: {
    background: "#2BA63A !important",
    color: "white !important",
  },
  facebookFab: {
    background: "#4B69B0 !important",
    color: "white !important",
  },
  youtubeFab: {
    background: "#E83F3A !important",
    color: "white !important",
  },
  instagramFab: {
    background:
      "linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%)",
    color: "white !important",
  },
  fab: {
    width: "3.25rem",
    height: "3.25rem",
  },
  snsIcon: {
    width: "2.25rem",
    height: "2.25rem",
  },
});

const SNSIcon = ({ sns, url, ...props }: { [key: string]: any }) => {
  const classes = useStyles(styles);

  const facebookURL = "https://www.facebook.com/autoleadercar";
  const youtubeURL = "https://www.youtube.com/channel/UCs_u8iM_p0vSE1XfQsBPDqg";
  const whatsappURL = "https://wa.me/821076599744";
  const instagramURL = "https://www.instagram.com/autoleader_exportador";

  const handleOnClick = (url: string) => {
    window.open(url, "_blank");
  };

  return sns === "whatsApp" ? (
    <Fab
      className={`${classes.fab} ${classes.whatsAppFab} ${props.className}`}
      onClick={() => handleOnClick(isNotNull(url) ? url : whatsappURL)}
    >
      <WhatsAppIcon className={classes.snsIcon} />
    </Fab>
  ) : sns === "facebook" ? (
    <Fab
      className={`${classes.fab} ${classes.facebookFab} ${props.className}`}
      onClick={() => handleOnClick(facebookURL)}
    >
      <FacebookIcon className={classes.snsIcon} />
    </Fab>
  ) : sns === "youtube" ? (
    <Fab
      className={`${classes.fab} ${classes.youtubeFab} ${props.className}`}
      onClick={() => handleOnClick(youtubeURL)}
    >
      <YouTubeIcon className={classes.snsIcon} />
    </Fab>
  ) : sns === "instagram" ? (
    <Fab
      className={`${classes.fab} ${classes.instagramFab} ${props.className}`}
      onClick={() => handleOnClick(instagramURL)}
    >
      <InstagramIcon className={classes.snsIcon} />
    </Fab>
  ) : (
    <></>
  );
};

export default SNSIcon;
