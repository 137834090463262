/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Markup } from "interweave";
// Redux
import { useTypedDispatch, useTypedSelector } from "@/common/lib/redux/store";
import { loadingOn, loadingOff } from "@/common/lib/redux/actions/commonAction";
import { setParams } from "@/common/lib/redux/actions/searchAction";
// Components
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  Divider,
  InputAdornment,
  RadioGroup,
  Theme,
  Typography,
} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReplayIcon from "@mui/icons-material/Replay";
import { Button, Checkbox, Dropdown, Input, Radio } from "@/common/components";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// API
import { getVehicleCount } from "@/common/lib/api/vehicle";
// Libs
import {
  typeList,
  yearList,
  productList,
  getYearList,
  getMakerList,
  getModelList,
  getDetailModelList,
  carCategoryList,
  truckCategoryList,
  truckSubCategoryList,
  busCategoryList,
  missionList,
  fuelList,
  colorList,
  carDriveList,
  carSeatsList,
  truckDriveList,
  truckWheelbaseList,
  truckEngineTypeList,
  truckCabinTypeList,
  busSeatsList,
  optionList,
} from "@/common/lib/varList";
import { isNull, isNotNull, getType } from "@/common/lib/common";
import { usePrevious } from "@/common/lib/hooks";

const styles = (theme: Theme) => ({
  card: {
    width: 215,
    height: "fit-content",
    overflow: "visible",
    borderRadius: 0,
    userSelect: "none",
    "& > .MuiCardContent-root:first-child": {
      display: "flex",
      flexDirection: "column",
      alignItems: "end",
      padding: "20px 16px",
      "& > .MuiTypography-root": {
        width: "100%",
        fontSize: 20,
        fontWeight: "bold",
        color: theme.palette.primary.main,
        cursor: "default",
      },
      "& > div": {
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
      },
      "& > div.countDiv": {
        width: "fit-content",
        marginBottom: 3,
        "& > .MuiTypography-root": {
          cursor: "default",
        },
        "& > .count": {
          fontSize: 17,
          fontWeight: "bold",
          margin: "0 3px",
        },
      },
      "& > div.btnDiv": {
        width: "fit-content",
        "&:hover": {
          cursor: "pointer !important",
          "& > .MuiSvgIcon-root": {
            color: theme.palette.primary.main,
          },
          "& > .MuiTypography-root": {
            color: theme.palette.primary.main,
          },
        },
        "& > .MuiSvgIcon-root": {
          fontSize: 15,
          marginRight: 3,
          color: theme.palette.text.secondary,
        },
        "& > .MuiTypography-root": {
          fontSize: 13,
          color: theme.palette.text.secondary,
          lineHeight: 1,
        },
      },
    },
    "& .MuiCardContent-root": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "12px 16px",
      cursor: "pointer",
      "& > .MuiTypography-root": {
        fontSize: 14,
      },
    },
    "& .innerContent": {
      position: "relative",
      padding: "12px 20px",
      "& div.content": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& > .MuiTypography-root": {
          fontSize: 14,
          cursor: "default",
          "&.disabled": {
            color: theme.palette.text.disabled,
          },
        },
      },
      "& div.more": {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        "& > .MuiTypography-root": {
          width: "fit-content",
          fontSize: 14,
          color: theme.palette.text.secondary,
          "&:hover": {
            cursor: "pointer",
            color: theme.palette.primary.main,
          },
        },
        "&.ON": {
          "& > .MuiTypography-root": {
            color: theme.palette.primary.main,
          },
        },
      },
      "& div.content:first-child:not(:only-child)": {
        marginBottom: 3,
      },
      "& div.content:last-child:not(:only-child)": {
        marginTop: 3,
      },
      "& div.content:not(:first-child):not(:last-child):not(:only-child)": {
        margin: "3px 0",
      },
    },
    "& .scrollContent-5": {
      height: "fit-content",
      maxHeight: 140,
      overflow: "auto",
      "&::-webkit-scrollbar": {
        width: 12,
        backgroundColor: "transparent",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 10,
        backgroundClip: "padding-box",
        border: "3.5px solid transparent",
      },
    },
    "& .scrollContent-7": {
      height: "fit-content",
      maxHeight: 185,
      overflow: "auto",
      "&::-webkit-scrollbar": {
        width: 12,
        backgroundColor: "transparent",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 10,
        backgroundClip: "padding-box",
        border: "3.5px solid transparent",
      },
    },
  },
  accordion: {
    margin: "0 !important",
    "&::before": {
      display: "none",
    },
    "& .MuiAccordionSummary-root": {
      display: "none",
    },
    "& .MuiAccordionDetails-root": {
      padding: "unset",
    },
  },
  formControl: {
    cursor: "pointer",
    userSelect: "none",
    "& > .MuiTypography-root": {
      fontSize: 13,
    },
    "&.Mui-disabled": {
      "& + div": {
        "&::before": {
          color: theme.palette.divider,
        },
        "&::after": {
          color: theme.palette.divider,
        },
      },
    },
  },
  checkbox: {
    padding: 3,
    "& > .MuiSvgIcon-root": {
      fontSize: 17,
    },
  },
  checkboxGroup: {
    "&::before": {
      content: "'('",
      fontSize: 14,
    },
    "&::after": {
      content: "')'",
      paddingLeft: 3,
      fontSize: 14,
    },
  },
  radio: {
    padding: 3,
    "& > span > svg": {
      fontSize: 16,
    },
  },
  radioGroup: {
    "&::before": {
      content: "'('",
      fontSize: 14,
    },
    "&::after": {
      content: "')'",
      paddingLeft: 3,
      fontSize: 14,
    },
  },
  dropdownFormControl: {
    fontSize: "14px !important",
    "&.SMALL": {
      width: "77.5px !important",
    },
    "&.NORMAL": {
      width: "125px !important",
    },
  },
  dropdownMenuItem: {
    fontSize: "14px !important",
  },
  input: {
    width: 75,
    "& > label": {
      fontSize: 14,
    },
    "& > div": {
      fontSize: 14,
      marginTop: 10,
      "& > input": {
        padding: "4px 0",
      },
    },
  },
  searchInput: {
    marginBottom: 10,
    "& > div": {
      fontSize: 13,
    },
  },
  button: {
    width: "100%",
  },
  showbox: {
    display: "none !important",
    position: "absolute",
    left: 235,
    zIndex: 99,
    height: "fit-content",
    padding: "30px 20px 20px",
    borderRadius: 5,
    boxShadow:
      "rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundColor: "white",
    "&.ON": {
      display: "block !important",
    },
    "& > div.title": {
      position: "absolute",
      left: -15,
      top: 10,
      width: "fit-content",
      padding: "5px 25px",
      background: theme.palette.primary.main,
      borderRadius: 5,
      userSelect: "none",
      "& > .MuiTypography-root": {
        fontSize: 15,
        fontWeight: 500,
        color: "white",
        textAlign: "center",
        cursor: "default",
      },
    },
    "& > div.subTitle": {
      padding: "7.5px 0",
      userSelect: "none",
      "& > .MuiTypography-root": {
        fontSize: 15,
        fontWeight: 500,
        color: theme.palette.primary.main,
        textAlign: "center",
        cursor: "default",
      },
    },
    "& > hr.top": {
      borderColor: theme.palette.primary.main,
      marginTop: 0,
      marginBottom: 7.5,
    },
    "& > hr.bottom": {
      marginTop: 7.5,
      marginBottom: 0,
    },
  },
  optionList: {
    margin: 0,
    padding: "7.5px 0",
    listStyle: "none",
    userSelect: "none",
    "& > li": {
      display: "inline-block",
      width: "25%",
      "& > div": {
        display: "flex",
        justifyContent: "space-between",
        padding: 5,
      },
    },
  },
  innerContainer: {
    padding: "6px 8px",
  },
  seatsContainer: {
    display: "flex",
    justifyContent: "center",
    padding: "0 16px 16px",
  },
});

const SearchContainer = ({ ...props }: { [key: string]: any }) => {
  const classes = useStyles(styles);
  const { t } = useTranslation();
  const { params } = useTypedSelector((state) => state.search);
  const dispatch = useTypedDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [init, setInit] = useState(false);
  const [search, setSearch] = useState(false);

  const [typeExpanded, setTypeExpanded] = useState(true);
  const [categoryExpanded, setCategoryExpanded] = useState(false);
  const [makerExpanded, setMakerExpanded] = useState(true);
  const [yearExpanded, setYearExpanded] = useState(false);
  const [priceExpanded, setPriceExpanded] = useState(false);
  const [missionExpanded, setMissionExpanded] = useState(false);
  const [fuelExpanded, setFuelExpanded] = useState(false);
  const [colorExpanded, setColorExpanded] = useState(false);
  const [searchExpanded, setSearchExpanded] = useState(false);
  const [optionExpanded, setOptionExpanded] = useState(false);

  const [carDriveExpanded, setCarDriveExpanded] = useState(false);
  const [carSeatsExpanded, setCarSeatsExpanded] = useState(false);

  const [truckDriveExpanded, setTruckDriveExpanded] = useState(false);
  const [truckWheelbaseExpanded, setTruckWheelbaseExpanded] = useState(false);
  const [truckEngineTypeExpanded, setTruckEngineTypeExpanded] = useState(false);
  const [truckCabinTypeExpanded, setTruckCabinTypeExpanded] = useState(false);

  const [busSeatsExpanded, setBusSeatsExpanded] = useState(false);

  const [showColorBox, setShowColorBox] = useState(false);
  const [showOptionBox, setShowOptionBox] = useState(false);

  const [searchParams, setSearchParams] = useState({ ...params });
  const [countData, setCountData] = useState<CountData>({
    total: 0,
    type: {},
    status: {},
    maker: {},
    mission: {},
    fuel: {},
    color: {},
  });

  const [type, setType] = useState<string>(
    Object.keys(params).includes("type") ? params["type"] : ""
  );

  const [carCategory, setCarCategory] = useState<string>(
    Object.keys(params).includes("car_category") ? params["car_category"] : ""
  );
  const [truckCategory, setTruckCategory] = useState<string>(
    Object.keys(params).includes("truck_category")
      ? params["truck_category"]
      : ""
  );
  const [truckSubCategory, setTruckSubCategory] = useState<string[]>(
    Object.keys(params).includes("truck_sub_category")
      ? params["truck_sub_category"].split(",")
      : []
  );
  const [busCategory, setBusCategory] = useState<string>(
    Object.keys(params).includes("bus_category") ? params["bus_category"] : ""
  );

  const [maker, setMaker] = useState<string>(
    Object.keys(params).includes("maker") ? params["maker"] : ""
  );
  const [model, setModel] = useState<string>(
    Object.keys(params).includes("model") ? params["model"] : ""
  );
  const [detailModel, setDetailModel] = useState<string>(
    Object.keys(params).includes("detail_model") ? params["detail_model"] : ""
  );
  const [makerList, setMakerList] = useState(getMakerList(type));
  const [modelList, setModelList] = useState(getModelList(type, maker));
  const [detailModelList, setDetailModelList] = useState(
    getDetailModelList(maker, model)
  );

  const [minYear, setMinYear] = useState<string>(
    Object.keys(params).includes("min_year") ? params["min_year"] : ""
  );
  const [maxYear, setMaxYear] = useState<string>(
    Object.keys(params).includes("max_year") ? params["max_year"] : ""
  );
  const [minYearList, setMinYearList] = useState<Option[]>(yearList);
  const [maxYearList, setMaxYearList] = useState<Option[]>(yearList);

  const [minPrice, setMinPrice] = useState<string>(
    Object.keys(params).includes("min_price") ? params["min_price"] : ""
  );
  const [maxPrice, setMaxPrice] = useState<string>(
    Object.keys(params).includes("max_price") ? params["max_price"] : ""
  );

  const [carDrive, setCarDrive] = useState<string[]>(
    Object.keys(params).includes("car_drive") ? params["car_drive"] : []
  );
  const [truckDrive, setTruckDrive] = useState<string[]>(
    Object.keys(params).includes("truck_drive") ? params["truck_drive"] : []
  );
  const [truckWheelbase, setTruckWheelbase] = useState<string[]>(
    Object.keys(params).includes("truck_wheelbase")
      ? params["truck_wheelbase"]
      : []
  );
  const [truckEngineType, setTruckEngineType] = useState<string[]>(
    Object.keys(params).includes("truck_engine_type")
      ? params["truck_engine_type"]
      : []
  );
  const [truckCabinType, setTruckCabinType] = useState<string[]>(
    Object.keys(params).includes("truck_cabin_type")
      ? params["truck_cabin_type"]
      : []
  );

  const [mission, setMission] = useState<string[]>(
    Object.keys(params).includes("mission") ? params["mission"].split(",") : []
  );
  const [fuel, setFuel] = useState<string[]>(
    Object.keys(params).includes("fuel") ? params["fuel"].split(",") : []
  );
  const [color, setColor] = useState<string[]>(
    Object.keys(params).includes("color") ? params["color"].split(",") : []
  );

  const [carSeats, setCarSeats] = useState<string>(
    Object.keys(params).includes("car_min_seats")
      ? params["car_min_seats"]
      : Object.keys(params).includes("car_max_seats")
      ? params["car_max_seats"]
      : ""
  );
  const [busSeats, setBusSeats] = useState<string>(
    Object.keys(params).includes("bus_min_seats") ||
      Object.keys(params).includes("bus_max_seats")
      ? (Object.keys(params).includes("bus_min_seats")
          ? params["bus_min_seats"]
          : "") +
          "~" +
          (Object.keys(params).includes("bus_max_seats")
            ? params["bus_max_seats"]
            : "")
      : ""
  );

  const [carOption, setCarOption] = useState<string[]>(
    Object.keys(params).includes("car_option")
      ? params["car_option"].split(",")
      : []
  );
  const [truckOption, setTruckOption] = useState<string[]>(
    Object.keys(params).includes("truck_option")
      ? params["truck_option"].split(",")
      : []
  );
  const [busOption, setBusOption] = useState<string[]>(
    Object.keys(params).includes("bus_option")
      ? params["bus_option"].split(",")
      : []
  );

  const [searchText, setSearchText] = useState<string>(
    Object.keys(params).includes("search_text") ? params["search_text"] : ""
  );

  const prevType = usePrevious(type);

  const vehicleCountQuery = useQuery(
    "vehicleCount",
    () => {
      const countParams = {
        ...params,
      };
      dispatch(loadingOn());
      return getVehicleCount(countParams);
    },
    {
      onSettled: () => {
        dispatch(loadingOff());
      },
    }
  );

  const handleInitOnClick = () => {
    setType("");
    setMaker("");
    setModel("");
    setDetailModel("");

    setCarCategory("");
    setTruckCategory("");
    setBusCategory("");

    setCarSeats("");
    setBusSeats("");

    setCarDrive([]);
    setTruckDrive([]);
    setTruckWheelbase([]);
    setTruckEngineType([]);
    setTruckCabinType([]);

    setCarOption([]);
    setTruckOption([]);
    setBusOption([]);

    setMinYear("");
    setMaxYear("");
    setMinPrice("");
    setMaxPrice("");
    setMission([]);
    setFuel([]);
    setColor([]);
    setSearchText("");

    setSearchParams({});
    dispatch(setParams({}));
  };
  const handleSearchOnClick = () => {
    const searchParams: { [key: string]: string } = {};
    if (isNotNull(type)) {
      searchParams["type"] = type;
    }
    if (isNotNull(maker)) {
      searchParams["maker"] = maker;
    }
    if (isNotNull(model)) {
      searchParams["model"] = model;
    }
    if (isNotNull(detailModel)) {
      searchParams["detail_model"] = detailModel;
    }
    if (isNotNull(minYear)) {
      searchParams["min_year"] = minYear;
    }
    if (isNotNull(maxYear)) {
      searchParams["max_year"] = maxYear;
    }
    if (isNotNull(minPrice)) {
      searchParams["price_type"] = "ADPrice";
      searchParams["min_price"] = minPrice;
    }
    if (isNotNull(maxPrice)) {
      searchParams["price_type"] = "ADPrice";
      searchParams["max_price"] = maxPrice;
    }
    if (mission.length > 0) {
      searchParams["mission"] = mission.join(",");
    }
    if (fuel.length > 0) {
      searchParams["fuel"] = fuel.join(",");
    }
    if (color.length > 0) {
      searchParams["color"] = color.join(",");
    }
    if (type === "CAR") {
      if (isNotNull(carCategory)) {
        searchParams["car_category"] = carCategory;
      }
      if (carDrive.length > 0) {
        searchParams["car_drive"] = carDrive.join(",");
      }
      if (isNotNull(carSeats)) {
        searchParams["car_min_seats"] = carSeats;
        searchParams["car_max_seats"] = carSeats;
      }
      if (carOption.length > 0) {
        searchParams["car_option"] = carOption.join(",");
      }
    } else if (type === "TRUCK") {
      if (isNotNull(truckCategory)) {
        searchParams["truck_category"] = truckCategory;
      }
      if (truckSubCategory.length > 0) {
        searchParams["truck_sub_category"] = truckSubCategory.join(",");
      }
      if (truckDrive.length > 0) {
        searchParams["truck_drive"] = truckDrive.join(",");
      }
      if (truckWheelbase.length > 0) {
        searchParams["truck_wheelbase"] = truckWheelbase.join(",");
      }
      if (truckEngineType.length > 0) {
        searchParams["truck_engine_type"] = truckEngineType.join(",");
      }
      if (truckCabinType.length > 0) {
        searchParams["truck_cabin_type"] = truckCabinType.join(",");
      }
      if (truckOption.length > 0) {
        searchParams["truck_option"] = truckOption.join(",");
      }
    } else if (type === "BUS") {
      if (isNotNull(busCategory)) {
        searchParams["bus_category"] = busCategory;
      }
      if (isNotNull(busSeats)) {
        const result = busSeats.match(/(\d+)?\s*~\s*(\d+)?/);
        if (result !== null) {
          if (result[1] !== null) {
            searchParams["bus_min_seats"] = result[1];
          }
          if (result[2] !== null) {
            searchParams["bus_max_seats"] = result[2];
          }
        }
      }
      if (busOption.length > 0) {
        searchParams["bus_option"] = busOption.join(",");
      }
    }
    if (isNotNull(searchText)) {
      searchParams["search_text"] = searchText;
    }
    dispatch(setParams(searchParams));
  };
  const handleCheckboxOnClick = (
    isCheck: boolean,
    value: string,
    setValue: React.Dispatch<React.SetStateAction<string>>
  ) => {
    if (isCheck) {
      setValue(value);
    } else {
      setValue("");
    }
  };
  const handleCheckboxOnClick_Array = (
    isCheck: boolean,
    value: string,
    dispatch: React.Dispatch<React.SetStateAction<string[]>>
  ) => {
    if (isCheck) {
      dispatch((prevState) => [...prevState, value]);
    } else {
      dispatch((prevState) =>
        prevState.filter((item: string) => item !== value)
      );
    }
  };
  const handleColorMoreOnClick = () => {
    setShowColorBox(!showColorBox);
  };
  const handleOptionMoreOnClick = () => {
    setShowOptionBox(!showOptionBox);
  };

  const getCheckboxValue = (value: string, target: string | string[]) => {
    if (getType(target) === "String") {
      if (value === target) {
        return true;
      }
    } else if (getType(target) === "Array") {
      if (target.includes(value)) {
        return true;
      }
    }
    return false;
  };
  const getOptionItemList = useCallback(
    (type: string) => {
      const itemList: Option[] = [
        { label: t("VEHICLE.OPTION.LEATHER_SEAT"), value: "leather_seat" },
      ];
      if (type === "CAR") {
        itemList.push.apply(itemList, [
          {
            label:
              t("VEHICLE.OPTION.POWER_SEAT") +
              "(" +
              t("VEHICLE.OPTION.DRIVER_SEAT") +
              ")",
            value: "power_driver_seat",
          },
        ]);
      } else if (type === "TRUCK") {
        itemList.push.apply(itemList, [
          {
            label: t("VEHICLE.OPTION.POWER_SEAT"),
            value: "power_seat",
          },
        ]);
      } else if (type === "BUS") {
        itemList.push.apply(itemList, [
          {
            label: t("VEHICLE.OPTION.POWER_SEAT"),
            value: "power_seat",
          },
        ]);
      }

      itemList.push.apply(itemList, [
        { label: t("VEHICLE.OPTION.SMART_KEY"), value: "smart_key" },
        {
          label:
            t("VEHICLE.OPTION.AIRCON") + "(" + t("VEHICLE.OPTION.AUTO") + ")",
          value: "auto_aircon",
        },
        {
          label:
            t("VEHICLE.OPTION.CAMERA") +
            "(" +
            t("VEHICLE.OPTION.REAR_SIDE") +
            ")",
          value: "rear_camera",
        },
      ]);

      if (type === "CAR") {
        itemList.push.apply(itemList, [
          {
            label:
              t("VEHICLE.OPTION.SUNROOF") +
              "(" +
              t("VEHICLE.OPTION.NORMAL") +
              ")",
            value: "normal_sunroof",
          },
          {
            label:
              t("VEHICLE.OPTION.SUNROOF") +
              "(" +
              t("VEHICLE.OPTION.PANORAMIC") +
              ")",
            value: "panoramic_sunroof",
          },
          { label: t("VEHICLE.OPTION.POWER_WINDOW"), value: "power_window" },
        ]);
      } else if (type === "TRUCK") {
      } else if (type === "BUS") {
      }

      return itemList;
    },
    [type]
  );
  const getSortItemList = (
    sort = "ASC", // ASC | DESC
    count: number,
    optionList: Option[],
    countData: { [key: string]: number } | undefined
  ) => {
    const itemList: Option[] = [];
    if (countData !== undefined) {
      const sortable = Object.fromEntries(
        Object.entries(countData).sort(([, a], [, b]) =>
          sort === "ASC" ? a - b : b - a
        )
      );
      for (const key of Object.keys(sortable)) {
        if (itemList.length < count) {
          for (const option of optionList) {
            if (key === option.value) {
              itemList.push(option);
              break;
            }
          }
        } else {
          break;
        }
      }
    }
    return itemList;
  };
  const checkRange = (
    type: string,
    value: string,
    compare: string,
    dispatch: React.Dispatch<React.SetStateAction<string>>
  ) => {
    if (isNull(value) || isNull(compare)) {
      dispatch(value);
    } else {
      let isCorrectRange = true;

      const checkValue = Number(value);
      const checkCompare = Number(compare);
      if (isNaN(checkValue) || isNaN(checkCompare)) {
        isCorrectRange = false;
      }

      if (type === "MIN") {
        if (checkValue > checkCompare) {
          enqueueSnackbar(t("SEARCH.MSG.COMPARE_MIN_VALUE"), {
            variant: "warning",
          });
          isCorrectRange = false;
        }
      } else if (type === "MAX") {
        if (checkValue < checkCompare) {
          enqueueSnackbar(t("SEARCH.MSG.COMPARE_MAX_VALUE"), {
            variant: "warning",
          });
          isCorrectRange = false;
        }
      }

      if (isCorrectRange) {
        dispatch(String(checkValue));
      } else {
        dispatch("");
      }
    }
  };

  const getDisabled_Option = (optionList: Option[]) => {
    for (let idx = 0; idx < optionList.length; idx++) {
      if (type === "CAR") {
        if (
          countData["car_option"] !== undefined &&
          countData["car_option"][optionList[idx].value] !== undefined &&
          countData["car_option"][optionList[idx].value] > 0
        ) {
          return false;
        }
      } else if (type === "TRUCK") {
        if (
          countData["truck_option"] !== undefined &&
          countData["truck_option"][optionList[idx].value] !== undefined &&
          countData["truck_option"][optionList[idx].value] > 0
        ) {
          return false;
        }
      } else if (type === "BUS") {
        if (
          countData["bus_option"] !== undefined &&
          countData["bus_option"][optionList[idx].value] !== undefined &&
          countData["bus_option"][optionList[idx].value] > 0
        ) {
          return false;
        }
      }
    }
    return true;
  };
  const getCheckboxValue_Option = (optionList: Option[]) => {
    for (let idx = 0; idx < optionList.length; idx++) {
      if (type === "CAR") {
        if (carOption.indexOf(optionList[idx].value) !== -1) {
          return true;
        }
      } else if (type === "TRUCK") {
        if (truckOption.indexOf(optionList[idx].value) !== -1) {
          return true;
        }
      } else if (type === "BUS") {
        if (busOption.indexOf(optionList[idx].value) !== -1) {
          return true;
        }
      }
    }
    return false;
  };
  const handleCheckboxOnClick_Option = (
    isCheck: boolean,
    optionList: Option[]
  ) => {
    if (isCheck) {
      // Append
      if (type === "CAR") {
        setCarOption((prevState) => [...prevState, optionList[0].value]);
      } else if (type === "TRUCK") {
        setTruckOption((prevState) => [...prevState, optionList[0].value]);
      } else if (type === "BUS") {
        setBusOption((prevState) => [...prevState, optionList[0].value]);
      }
    } else {
      // Remove
      for (let idx = 0; idx < optionList.length; idx++) {
        if (type === "CAR") {
          if (carOption.indexOf(optionList[idx].value) !== -1) {
            setCarOption((prevState) =>
              prevState.filter((item) => item !== optionList[idx].value)
            );
          }
        } else if (type === "TRUCK") {
          if (truckOption.indexOf(optionList[idx].value) !== -1) {
            setTruckOption((prevState) =>
              prevState.filter((item) => item !== optionList[idx].value)
            );
          }
        } else if (type === "BUS") {
          if (busOption.indexOf(optionList[idx].value) !== -1) {
            setBusOption((prevState) =>
              prevState.filter((item) => item !== optionList[idx].value)
            );
          }
        }
      }
    }
  };
  const getRadioGroupValue_Option = (optionList: Option[]) => {
    for (let idx = 0; idx < optionList.length; idx++) {
      if (type === "CAR") {
        if (carOption.indexOf(optionList[idx].value) !== -1) {
          return optionList[idx].value;
        }
      } else if (type === "TRUCK") {
        if (truckOption.indexOf(optionList[idx].value) !== -1) {
          return optionList[idx].value;
        }
      } else if (type === "BUS") {
        if (busOption.indexOf(optionList[idx].value) !== -1) {
          return optionList[idx].value;
        }
      }
    }
    return "";
  };
  const handleRadioGroupOnClick_Option = (
    value: string,
    optionList: Option[]
  ) => {
    // Remove
    for (let idx = 0; idx < optionList.length; idx++) {
      if (type === "CAR") {
        if (carOption.indexOf(optionList[idx].value) !== -1) {
          setCarOption((prevState) =>
            prevState.filter((item) => item !== optionList[idx].value)
          );
        }
      } else if (type === "TRUCK") {
        if (truckOption.indexOf(optionList[idx].value) !== -1) {
          setTruckOption((prevState) =>
            prevState.filter((item) => item !== optionList[idx].value)
          );
        }
      } else if (type === "BUS") {
        if (busOption.indexOf(optionList[idx].value) !== -1) {
          setBusOption((prevState) =>
            prevState.filter((item) => item !== optionList[idx].value)
          );
        }
      }
    }
    // Append
    if (type === "CAR") {
      setCarOption((prevState) => [...prevState, value]);
    } else if (type === "TRUCK") {
      setTruckOption((prevState) => [...prevState, value]);
    } else if (type === "BUS") {
      setBusOption((prevState) => [...prevState, value]);
    }
  };

  useEffect(() => {
    if (init && !search) {
      setMakerList(getMakerList(type));
      if (
        isNotNull(type) &&
        isNotNull(maker) &&
        !Object.keys(productList[maker]).includes(type)
      ) {
        setMaker("");
      }

      setCarCategory("");
      setTruckCategory("");
      setBusCategory("");

      setCarSeats("");
      setBusSeats("");
      setCarDrive([]);
      setTruckDrive([]);
      setTruckWheelbase([]);
      setTruckEngineType([]);
      setTruckCabinType([]);

      setCarOption([]);
      setTruckOption([]);
      setBusOption([]);
    }
  }, [type]);
  useEffect(() => {
    if (init && !search) {
      setTruckSubCategory([]);
    }
  }, [type, truckCategory]);

  useEffect(() => {
    if (init && !search) {
      setModelList(getModelList(type, maker));
      setDetailModelList(getDetailModelList(maker, model));
      if (isNotNull(model)) {
        setModel("");
      }
      if (isNotNull(detailModel)) {
        setDetailModel("");
      }
    }
  }, [maker]);
  useEffect(() => {
    if (init && !search) {
      setDetailModelList(getDetailModelList(maker, model));
      if (isNotNull(detailModel)) {
        setDetailModel("");
      }
      if (isNotNull(type) && isNull(model)) {
        setType("");
      } else if (isNull(prevType) && isNotNull(maker) && isNotNull(model)) {
        if (
          productList[maker]["CAR"] !== undefined &&
          Object.keys(productList[maker]["CAR"]).includes(model)
        ) {
          setType("CAR");
        } else if (
          productList[maker]["TRUCK"] !== undefined &&
          Object.keys(productList[maker]["TRUCK"]).includes(model)
        ) {
          setType("TRUCK");
        } else if (
          productList[maker]["BUS"] !== undefined &&
          Object.keys(productList[maker]["BUS"]).includes(model)
        ) {
          setType("BUS");
        }
      }
    }
  }, [model]);

  useEffect(() => {
    if (isNull(minYear)) {
      setMaxYearList(yearList);
    } else {
      const currentYear = new Date().getFullYear() + 1;
      setMaxYearList(getYearList(Number(minYear), currentYear));
    }
  }, [minYear]);
  useEffect(() => {
    if (isNull(maxYear)) {
      setMinYearList(yearList);
    } else {
      const currentYear = new Date().getFullYear() + 1;
      setMinYearList(getYearList(currentYear - 30, Number(maxYear)));
    }
  }, [maxYear]);

  useEffect(() => {
    if (init && !search) {
      setSearch(true);
      setSearchText("");
    }
  }, [
    type,
    maker,
    model,
    detailModel,
    carCategory,
    truckCategory,
    busCategory,
    carOption,
    truckOption,
    busOption,
  ]);
  useEffect(() => {
    if (search) {
      setSearch(false);
      handleSearchOnClick();
    }
  }, [search]);

  useEffect(() => {
    if (Object.keys(params).includes("type")) {
      setType(params["type"]);
    } else {
      setType("");
    }

    if (type === "CAR") {
      if (Object.keys(params).includes("car_drive")) {
        setCarDrive(params["car_drive"].split(","));
      }
      if (
        Object.keys(params).includes("car_min_seats") ||
        Object.keys(params).includes("car_max_seats")
      ) {
        const minSeats = isNotNull(params["car_min_seats"])
          ? params["car_min_seats"]
          : "";
        // const maxSeats = isNotNull(params["car_max_seats"])
        //   ? params["car_max_seats"]
        //   : "";
        setCarSeats(minSeats);
      }
      if (Object.keys(params).includes("car_option")) {
        setCarOption(params["car_option"].split(","));
      }
    }
    if (type === "TRUCK") {
      if (Object.keys(params).includes("truck_drive")) {
        setTruckDrive(params["truck_drive"].split(","));
      }
      if (Object.keys(params).includes("truck_wheelbase")) {
        setTruckWheelbase(params["truck_wheelbase"].split(","));
      }
      if (Object.keys(params).includes("truck_engine_type")) {
        setTruckEngineType(params["truck_engine_type"].split(","));
      }
      if (Object.keys(params).includes("truck_cabin_type")) {
        setTruckCabinType(params["truck_cabin_type"].split(","));
      }
      if (Object.keys(params).includes("truck_option")) {
        setTruckOption(params["truck_option"].split(","));
      }
    }
    if (type === "BUS") {
      if (
        Object.keys(params).includes("bus_min_seats") ||
        Object.keys(params).includes("bus_max_seats")
      ) {
        const minSeats = isNotNull(params["bus_min_seats"])
          ? params["bus_min_seats"]
          : "";
        const maxSeats = isNotNull(params["bus_max_seats"])
          ? params["bus_max_seats"]
          : "";
        setBusSeats(minSeats + "~" + maxSeats);
      }
      if (Object.keys(params).includes("bus_option")) {
        setBusOption(params["bus_option"].split(","));
      }
    }

    if (Object.keys(params).includes("search_text")) {
      setSearchText(params["search_text"]);
    } else {
      setSearchText("");
    }

    if (
      isNotNull(carCategory) ||
      isNotNull(truckCategory) ||
      isNotNull(busCategory)
    ) {
      setCategoryExpanded(true);
    } else {
      setCategoryExpanded(false);
    }
    if (isNotNull(minYear) || isNotNull(maxYear)) {
      setYearExpanded(true);
    } else {
      setYearExpanded(false);
    }
    if (isNotNull(minPrice) || isNotNull(maxPrice)) {
      setPriceExpanded(true);
    } else {
      setPriceExpanded(false);
    }
    if (mission.length > 0) {
      setMissionExpanded(true);
    } else {
      setMissionExpanded(false);
    }
    if (fuel.length > 0) {
      setFuelExpanded(true);
    } else {
      setFuelExpanded(false);
    }
    if (showColorBox) {
      setColorExpanded(true);
    } else {
      if (color.length > 0) {
        setColorExpanded(true);
      } else {
        setColorExpanded(false);
      }
    }
    if (
      carDrive.length > 0 ||
      isNotNull(carSeats) ||
      truckDrive.length > 0 ||
      truckWheelbase.length > 0 ||
      truckEngineType.length > 0 ||
      truckCabinType.length > 0 ||
      isNotNull(busSeats)
    ) {
      setSearchExpanded(true);
    } else {
      setSearchExpanded(false);
    }
    if (showOptionBox) {
      setOptionExpanded(true);
    } else {
      if (
        carOption.length > 0 ||
        truckOption.length > 0 ||
        busOption.length > 0
      ) {
        setOptionExpanded(true);
      } else {
        setOptionExpanded(false);
      }
    }
  }, [params]);
  useEffect(() => {
    setInit(false);
    vehicleCountQuery.refetch();
  }, [params, searchParams]);
  useEffect(() => {
    if (vehicleCountQuery.isSuccess) {
      setCountData({ ...vehicleCountQuery.data.data.count });
      setInit(true);
    }
  }, [vehicleCountQuery.isLoading, vehicleCountQuery.isRefetching]);

  return (
    <Card
      classes={{ root: classes.card }}
      className={isNotNull(props.className) ? props.className : undefined}
      variant="outlined"
    >
      <CardContent>
        <Typography>{t("SEARCH.TITLE")}</Typography>
        <div className="countDiv">
          <Typography>{t("SEARCH.INDEX_SEARCH_TOTAL")}</Typography>
          <Typography className="count">
            {countData["total"].toLocaleString()}
          </Typography>
          <Typography>{t("SEARCH.INDEX_SEARCH_EA")}</Typography>
        </div>
        <div className="btnDiv" onClick={handleInitOnClick}>
          <ReplayIcon />
          <Typography>{t("SEARCH.RESET")}</Typography>
        </div>
      </CardContent>
      <Divider />
      <CardContent onClick={() => setTypeExpanded(!typeExpanded)}>
        <Typography>{t("VEHICLE.PRODUCT.TYPE")}</Typography>
        {typeExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </CardContent>
      <Accordion
        className={classes.accordion}
        square
        elevation={0}
        expanded={typeExpanded}
      >
        <AccordionSummary />
        <AccordionDetails>
          <Divider />
          <div className="innerContent">
            {countData["total"] > 0
              ? typeList
                  .filter(
                    (item) =>
                      isNotNull(item.value) &&
                      countData["type"] !== undefined &&
                      countData["type"][item.value] !== undefined
                  )
                  .map((item, idx) => {
                    return (
                      <div className="content" key={idx}>
                        <Checkbox
                          label={item.label}
                          checked={getCheckboxValue(item.value, type)}
                          dispatch={(isCheck: boolean) => {
                            handleCheckboxOnClick(isCheck, item.value, setType);
                          }}
                          formControlClassName={classes.formControl}
                          checkboxClassName={classes.checkbox}
                        />
                        <Typography>
                          {countData["type"][item.value].toLocaleString()}
                        </Typography>
                      </div>
                    );
                  })
              : typeList
                  .filter((item) => isNotNull(item.value))
                  .map((item, idx) => {
                    return (
                      <div className="content" key={idx}>
                        <Checkbox
                          disabled
                          label={item.label}
                          checked={getCheckboxValue(item.value, type)}
                          formControlClassName={classes.formControl}
                          checkboxClassName={classes.checkbox}
                        />
                        <Typography className="disabled">0</Typography>
                      </div>
                    );
                  })}
          </div>
        </AccordionDetails>
      </Accordion>
      <Divider />
      {isNotNull(type) && (
        <>
          <CardContent onClick={() => setCategoryExpanded(!categoryExpanded)}>
            <Typography>{t("VEHICLE.PRODUCT.CATEGORY")}</Typography>
            {categoryExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </CardContent>
          <Accordion
            className={classes.accordion}
            square
            elevation={0}
            expanded={categoryExpanded}
          >
            <AccordionSummary />
            <AccordionDetails>
              <Divider />
              {type === "CAR" && (
                <div className="innerContent scrollContent-5">
                  {countData["total"] > 0
                    ? carCategoryList
                        .filter((item) =>
                          isNull(carCategory)
                            ? isNotNull(item.value) &&
                              countData["car_category"] !== undefined &&
                              countData["car_category"][item.value] !==
                                undefined
                            : carCategory === item.value &&
                              isNotNull(item.value) &&
                              countData["car_category"] !== undefined &&
                              countData["car_category"][item.value] !==
                                undefined
                        )
                        .map((item, idx) => {
                          return (
                            <div className="content" key={idx}>
                              <Checkbox
                                label={item.label}
                                checked={getCheckboxValue(
                                  item.value,
                                  carCategory
                                )}
                                dispatch={(isCheck: boolean) => {
                                  handleCheckboxOnClick(
                                    isCheck,
                                    item.value,
                                    setCarCategory
                                  );
                                }}
                                formControlClassName={classes.formControl}
                                checkboxClassName={classes.checkbox}
                              />
                              <Typography>
                                {countData["car_category"] !== undefined &&
                                countData["car_category"][item.value] !==
                                  undefined
                                  ? countData["car_category"][
                                      item.value
                                    ].toLocaleString()
                                  : 0}
                              </Typography>
                            </div>
                          );
                        })
                    : carCategoryList
                        .filter((item) => isNotNull(item.value))
                        .map((item, idx) => {
                          return (
                            <div className="content" key={idx}>
                              <Checkbox
                                disabled
                                label={item.label}
                                checked={getCheckboxValue(
                                  item.value,
                                  carCategory
                                )}
                                formControlClassName={classes.formControl}
                                checkboxClassName={classes.checkbox}
                              />
                              <Typography className="disabled">0</Typography>
                            </div>
                          );
                        })}
                </div>
              )}
              {type === "TRUCK" && (
                <div className="innerContent scrollContent-5">
                  <div>
                    {countData["total"] > 0
                      ? truckCategoryList
                          .filter((item) =>
                            isNull(truckCategory)
                              ? isNotNull(item.value) &&
                                countData["truck_category"] !== undefined &&
                                countData["truck_category"][item.value] !==
                                  undefined
                              : truckCategory === item.value &&
                                isNotNull(item.value) &&
                                countData["truck_category"] !== undefined &&
                                countData["truck_category"][item.value] !==
                                  undefined
                          )
                          .map((item, idx) => {
                            return (
                              <div className="content" key={idx}>
                                <Checkbox
                                  label={item.label}
                                  checked={getCheckboxValue(
                                    item.value,
                                    truckCategory
                                  )}
                                  dispatch={(isCheck: boolean) => {
                                    handleCheckboxOnClick(
                                      isCheck,
                                      item.value,
                                      setTruckCategory
                                    );
                                  }}
                                  formControlClassName={classes.formControl}
                                  checkboxClassName={classes.checkbox}
                                />
                                <Typography>
                                  {countData["truck_category"] !== undefined &&
                                  countData["truck_category"][item.value] !==
                                    undefined
                                    ? countData["truck_category"][
                                        item.value
                                      ].toLocaleString()
                                    : 0}
                                </Typography>
                              </div>
                            );
                          })
                      : truckCategoryList
                          .filter((item) => isNotNull(item.value))
                          .map((item, idx) => {
                            return (
                              <div className="content" key={idx}>
                                <Checkbox
                                  disabled
                                  label={item.label}
                                  checked={getCheckboxValue(
                                    item.value,
                                    truckCategory
                                  )}
                                  formControlClassName={classes.formControl}
                                  checkboxClassName={classes.checkbox}
                                />
                                <Typography className="disabled">0</Typography>
                              </div>
                            );
                          })}
                  </div>
                  <Accordion
                    className={classes.accordion}
                    square
                    elevation={0}
                    expanded={isNotNull(truckCategory)}
                  >
                    <AccordionSummary />
                    <AccordionDetails>
                      <Divider sx={{ margin: "10px 0" }} />
                      <div>
                        {countData["total"] > 0
                          ? truckSubCategoryList[truckCategory]
                              .filter(
                                (item) =>
                                  isNotNull(item.value) &&
                                  countData["truck_sub_category"] !==
                                    undefined &&
                                  countData["truck_sub_category"][
                                    item.value
                                  ] !== undefined
                              )
                              .map((item, idx) => {
                                return (
                                  <div className="content" key={idx}>
                                    <Checkbox
                                      label={item.label}
                                      checked={getCheckboxValue(
                                        item.value,
                                        truckSubCategory
                                      )}
                                      dispatch={(isCheck: boolean) => {
                                        handleCheckboxOnClick_Array(
                                          isCheck,
                                          item.value,
                                          setTruckSubCategory
                                        );
                                      }}
                                      formControlClassName={classes.formControl}
                                      checkboxClassName={classes.checkbox}
                                    />
                                    <Typography>
                                      {countData["truck_sub_category"] !==
                                        undefined &&
                                      countData["truck_sub_category"][
                                        item.value
                                      ] !== undefined
                                        ? countData["truck_sub_category"][
                                            item.value
                                          ].toLocaleString()
                                        : 0}
                                    </Typography>
                                  </div>
                                );
                              })
                          : truckSubCategoryList[truckCategory]
                              .filter((item) => isNotNull(item.value))
                              .map((item, idx) => {
                                return (
                                  <div className="content" key={idx}>
                                    <Checkbox
                                      disabled
                                      label={item.label}
                                      checked={getCheckboxValue(
                                        item.value,
                                        truckSubCategory
                                      )}
                                      formControlClassName={classes.formControl}
                                      checkboxClassName={classes.checkbox}
                                    />
                                    <Typography className="disabled">
                                      0
                                    </Typography>
                                  </div>
                                );
                              })}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              )}
              {type === "BUS" && (
                <div className="innerContent scrollContent-5">
                  {countData["total"] > 0
                    ? busCategoryList
                        .filter((item) =>
                          isNull(busCategory)
                            ? isNotNull(item.value) &&
                              countData["bus_category"] !== undefined &&
                              countData["bus_category"][item.value] !==
                                undefined
                            : busCategory === item.value &&
                              isNotNull(item.value) &&
                              countData["bus_category"] !== undefined &&
                              countData["bus_category"][item.value] !==
                                undefined
                        )
                        .map((item, idx) => {
                          return (
                            <div className="content" key={idx}>
                              <Checkbox
                                label={item.label}
                                checked={getCheckboxValue(
                                  item.value,
                                  busCategory
                                )}
                                dispatch={(isCheck: boolean) => {
                                  handleCheckboxOnClick(
                                    isCheck,
                                    item.value,
                                    setBusCategory
                                  );
                                }}
                                formControlClassName={classes.formControl}
                                checkboxClassName={classes.checkbox}
                              />
                              <Typography>
                                {countData["bus_category"] !== undefined &&
                                countData["bus_category"][item.value] !==
                                  undefined
                                  ? countData["bus_category"][
                                      item.value
                                    ].toLocaleString()
                                  : 0}
                              </Typography>
                            </div>
                          );
                        })
                    : busCategoryList
                        .filter((item) => isNotNull(item.value))
                        .map((item, idx) => {
                          return (
                            <div className="content" key={idx}>
                              <Checkbox
                                disabled
                                label={item.label}
                                checked={getCheckboxValue(
                                  item.value,
                                  busCategory
                                )}
                                formControlClassName={classes.formControl}
                                checkboxClassName={classes.checkbox}
                              />
                              <Typography className="disabled">0</Typography>
                            </div>
                          );
                        })}
                </div>
              )}
            </AccordionDetails>
          </Accordion>
          <Divider />
        </>
      )}
      <CardContent onClick={() => setMakerExpanded(!makerExpanded)}>
        <Typography>
          {t("VEHICLE.PRODUCT.MAKER")}/{t("VEHICLE.PRODUCT.MODEL")}
        </Typography>
        {makerExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </CardContent>
      <Accordion
        className={classes.accordion}
        square
        elevation={0}
        expanded={makerExpanded}
      >
        <AccordionSummary />
        <AccordionDetails>
          <Divider />
          <div className="innerContent scrollContent-7">
            <div>
              {countData["total"] > 0
                ? makerList
                    .filter((item) =>
                      isNull(maker)
                        ? isNotNull(item.value) &&
                          countData["maker"] !== undefined &&
                          countData["maker"][item.value] !== undefined
                        : maker === item.value &&
                          isNotNull(item.value) &&
                          countData["maker"] !== undefined &&
                          countData["maker"][item.value] !== undefined
                    )
                    .map((item, idx) => {
                      return (
                        <div className="content" key={idx}>
                          <Checkbox
                            label={item.label}
                            checked={getCheckboxValue(item.value, maker)}
                            dispatch={(isCheck: boolean) => {
                              handleCheckboxOnClick(
                                isCheck,
                                item.value,
                                setMaker
                              );
                            }}
                            formControlClassName={classes.formControl}
                            checkboxClassName={classes.checkbox}
                          />
                          <Typography>
                            {countData["maker"] !== undefined &&
                            countData["maker"][item.value] !== undefined
                              ? countData["maker"][item.value].toLocaleString()
                              : 0}
                          </Typography>
                        </div>
                      );
                    })
                : makerList
                    .filter((item) => isNotNull(item.value))
                    .map((item, idx) => {
                      return (
                        <div className="content" key={idx}>
                          <Checkbox
                            disabled
                            label={item.label}
                            checked={getCheckboxValue(item.value, maker)}
                            formControlClassName={classes.formControl}
                            checkboxClassName={classes.checkbox}
                          />
                          <Typography className="disabled">0</Typography>
                        </div>
                      );
                    })}
            </div>
            <Accordion
              className={classes.accordion}
              square
              elevation={0}
              expanded={isNotNull(maker)}
            >
              <AccordionSummary />
              <AccordionDetails>
                <Divider sx={{ margin: "10px 0" }} />
                <div>
                  {countData["total"] > 0
                    ? modelList
                        .filter((item) =>
                          isNull(model)
                            ? isNotNull(item.value) &&
                              countData["model"] !== undefined &&
                              countData["model"][item.value] !== undefined
                            : model === item.value &&
                              isNotNull(item.value) &&
                              countData["model"] !== undefined &&
                              countData["model"][item.value] !== undefined
                        )
                        .map((item, idx) => {
                          return (
                            <div className="content" key={idx}>
                              <Checkbox
                                label={item.label}
                                checked={getCheckboxValue(item.value, model)}
                                dispatch={(isCheck: boolean) => {
                                  handleCheckboxOnClick(
                                    isCheck,
                                    item.value,
                                    setModel
                                  );
                                }}
                                formControlClassName={classes.formControl}
                                checkboxClassName={classes.checkbox}
                              />
                              <Typography>
                                {countData["model"] !== undefined &&
                                countData["model"][item.value] !== undefined
                                  ? countData["model"][
                                      item.value
                                    ].toLocaleString()
                                  : 0}
                              </Typography>
                            </div>
                          );
                        })
                    : modelList
                        .filter((item) => isNotNull(item.value))
                        .map((item, idx) => {
                          return (
                            <div className="content" key={idx}>
                              <Checkbox
                                disabled
                                label={item.label}
                                checked={getCheckboxValue(item.value, model)}
                                formControlClassName={classes.formControl}
                                checkboxClassName={classes.checkbox}
                              />
                              <Typography className="disabled">0</Typography>
                            </div>
                          );
                        })}
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              className={classes.accordion}
              square
              elevation={0}
              expanded={isNotNull(model)}
            >
              <AccordionSummary />
              <AccordionDetails>
                <Divider sx={{ margin: "10px 0" }} />
                <div>
                  {countData["total"] > 0
                    ? detailModelList
                        .filter(
                          (item) =>
                            isNotNull(item.value) &&
                            countData["detail_model"] !== undefined &&
                            countData["detail_model"][item.value] !== undefined
                        )
                        .map((item, idx) => {
                          return (
                            <div className="content" key={idx}>
                              <Checkbox
                                label={item.label}
                                checked={getCheckboxValue(
                                  item.value,
                                  detailModel
                                )}
                                dispatch={(isCheck: boolean) => {
                                  handleCheckboxOnClick(
                                    isCheck,
                                    item.value,
                                    setDetailModel
                                  );
                                }}
                                formControlClassName={classes.formControl}
                                checkboxClassName={classes.checkbox}
                              />
                              <Typography>
                                {countData["detail_model"] !== undefined &&
                                countData["detail_model"][item.value] !==
                                  undefined
                                  ? countData["detail_model"][
                                      item.value
                                    ].toLocaleString()
                                  : 0}
                              </Typography>
                            </div>
                          );
                        })
                    : detailModelList
                        .filter((item) => isNotNull(item.value))
                        .map((item, idx) => {
                          return (
                            <div className="content" key={idx}>
                              <Checkbox
                                disabled
                                label={item.label}
                                checked={getCheckboxValue(
                                  item.value,
                                  detailModel
                                )}
                                formControlClassName={classes.formControl}
                                checkboxClassName={classes.checkbox}
                              />
                              <Typography className="disabled">0</Typography>
                            </div>
                          );
                        })}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </AccordionDetails>
      </Accordion>
      <Divider />
      <CardContent onClick={() => setYearExpanded(!yearExpanded)}>
        <Typography>{t("VEHICLE.PRODUCT.YEAR")}</Typography>
        {yearExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </CardContent>
      <Accordion
        className={classes.accordion}
        square
        elevation={0}
        expanded={yearExpanded}
      >
        <AccordionSummary />
        <AccordionDetails>
          <Divider />
          <CardContent style={{ justifyContent: "space-evenly" }}>
            <Dropdown
              label="From"
              variant="standard"
              value={minYear}
              options={minYearList}
              dispatch={setMinYear}
              formControlClassName={`${classes.dropdownFormControl} SMALL`}
              menuItemClassName={classes.dropdownMenuItem}
            />
            <Typography>~</Typography>
            <Dropdown
              label="To"
              variant="standard"
              value={maxYear}
              options={maxYearList}
              dispatch={setMaxYear}
              formControlClassName={`${classes.dropdownFormControl} SMALL`}
              menuItemClassName={classes.dropdownMenuItem}
            />
          </CardContent>
        </AccordionDetails>
      </Accordion>
      <Divider />
      <CardContent onClick={() => setPriceExpanded(!priceExpanded)}>
        <Typography>{t("VEHICLE.SALES.PRICE")}</Typography>
        {priceExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </CardContent>
      <Accordion
        className={classes.accordion}
        square
        elevation={0}
        expanded={priceExpanded}
      >
        <AccordionSummary />
        <AccordionDetails>
          <Divider />
          <CardContent style={{ justifyContent: "space-evenly" }}>
            <Input
              type="integer"
              label="Min"
              variant="standard"
              className={classes.input}
              value={minPrice}
              dispatch={(value: string) => {
                if (Number(value) === 0) {
                  setMinPrice("");
                } else {
                  setMinPrice(value);
                }
              }}
              onBlur={(value: string) => {
                if (Number(value) === 0) {
                  checkRange("MIN", "", maxPrice, setMinPrice);
                } else {
                  checkRange("MIN", value, maxPrice, setMinPrice);
                }
              }}
              inputProps={{
                maxLength: 10,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
            <Typography>~</Typography>
            <Input
              type="integer"
              label="Max"
              variant="standard"
              className={classes.input}
              value={maxPrice}
              dispatch={(value: string) => {
                if (Number(value) === 0) {
                  setMaxPrice("");
                } else {
                  setMaxPrice(value);
                }
              }}
              onBlur={(value: string) => {
                if (Number(value) === 0) {
                  checkRange("MAX", "", minPrice, setMaxPrice);
                } else {
                  checkRange("MAX", value, minPrice, setMaxPrice);
                }
              }}
              inputProps={{
                maxLength: 10,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </CardContent>
        </AccordionDetails>
      </Accordion>
      <Divider />
      <CardContent onClick={() => setMissionExpanded(!missionExpanded)}>
        <Typography>{t("VEHICLE.PRODUCT.MISSION")}</Typography>
        {missionExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </CardContent>
      <Accordion
        className={classes.accordion}
        square
        elevation={0}
        expanded={missionExpanded}
      >
        <AccordionSummary />
        <AccordionDetails>
          <Divider />
          <div className="innerContent">
            {countData["total"] > 0
              ? missionList
                  .filter(
                    (item) =>
                      isNotNull(item.value) &&
                      countData["mission"] !== undefined &&
                      countData["mission"][item.value] !== undefined
                  )
                  .map((item, idx) => {
                    return (
                      <div className="content" key={idx}>
                        <Checkbox
                          label={item.label}
                          checked={getCheckboxValue(item.value, mission)}
                          dispatch={(isCheck: boolean) => {
                            handleCheckboxOnClick_Array(
                              isCheck,
                              item.value,
                              setMission
                            );
                          }}
                          formControlClassName={classes.formControl}
                          checkboxClassName={classes.checkbox}
                        />
                        <Typography>
                          {countData["mission"][item.value].toLocaleString()}
                        </Typography>
                      </div>
                    );
                  })
              : missionList
                  .filter((item) => isNotNull(item.value))
                  .map((item, idx) => {
                    return (
                      <div className="content" key={idx}>
                        <Checkbox
                          disabled
                          label={item.label}
                          checked={getCheckboxValue(item.value, mission)}
                          formControlClassName={classes.formControl}
                          checkboxClassName={classes.checkbox}
                        />
                        <Typography className="disabled">0</Typography>
                      </div>
                    );
                  })}
          </div>
        </AccordionDetails>
      </Accordion>
      <Divider />
      <CardContent onClick={() => setFuelExpanded(!fuelExpanded)}>
        <Typography>{t("VEHICLE.PRODUCT.FUEL")}</Typography>
        {fuelExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </CardContent>
      <Accordion
        className={classes.accordion}
        square
        elevation={0}
        expanded={fuelExpanded}
      >
        <AccordionSummary />
        <AccordionDetails>
          <Divider />
          <div className="innerContent">
            {countData["total"] > 0
              ? fuelList
                  .filter(
                    (item) =>
                      isNotNull(item.value) &&
                      countData["fuel"] !== undefined &&
                      countData["fuel"][item.value] !== undefined
                  )
                  .map((item, idx) => {
                    return (
                      <div className="content" key={idx}>
                        <Checkbox
                          label={item.label}
                          checked={getCheckboxValue(item.value, fuel)}
                          dispatch={(isCheck: boolean) => {
                            handleCheckboxOnClick_Array(
                              isCheck,
                              item.value,
                              setFuel
                            );
                          }}
                          formControlClassName={classes.formControl}
                          checkboxClassName={classes.checkbox}
                        />
                        <Typography>
                          {countData["fuel"][item.value].toLocaleString()}
                        </Typography>
                      </div>
                    );
                  })
              : fuelList
                  .filter((item) => isNotNull(item.value))
                  .map((item, idx) => {
                    return (
                      <div className="content" key={idx}>
                        <Checkbox
                          disabled
                          label={item.label}
                          checked={getCheckboxValue(item.value, fuel)}
                          formControlClassName={classes.formControl}
                          checkboxClassName={classes.checkbox}
                        />
                        <Typography className="disabled">0</Typography>
                      </div>
                    );
                  })}
          </div>
        </AccordionDetails>
      </Accordion>
      <Divider />
      <CardContent onClick={() => setColorExpanded(!colorExpanded)}>
        <Typography>{t("VEHICLE.PRODUCT.COLOR")}</Typography>
        {colorExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </CardContent>
      <Accordion
        className={classes.accordion}
        square
        elevation={0}
        expanded={colorExpanded}
      >
        <AccordionSummary />
        <AccordionDetails>
          <Divider />
          <div className="innerContent">
            {countData["total"] > 0
              ? getSortItemList("DESC", 5, colorList, countData["color"])
                  .filter(
                    (item) =>
                      isNotNull(item.value) &&
                      countData["color"] !== undefined &&
                      countData["color"][item.value] !== undefined
                  )
                  .map((item, idx) => {
                    return (
                      <div className="content" key={idx}>
                        <Checkbox
                          label={item.label}
                          checked={getCheckboxValue(item.value, color)}
                          dispatch={(isCheck: boolean) => {
                            handleCheckboxOnClick_Array(
                              isCheck,
                              item.value,
                              setColor
                            );
                          }}
                          formControlClassName={classes.formControl}
                          checkboxClassName={classes.checkbox}
                        />
                        <Typography>
                          {countData["color"][item.value].toLocaleString()}
                        </Typography>
                      </div>
                    );
                  })
              : colorList
                  .slice(1, 6)
                  .filter((item) => isNotNull(item.value))
                  .map((item, idx) => {
                    return (
                      <div className="content" key={idx}>
                        <Checkbox
                          disabled
                          label={item.label}
                          checked={getCheckboxValue(item.value, color)}
                          formControlClassName={classes.formControl}
                          checkboxClassName={classes.checkbox}
                        />
                        <Typography className="disabled">0</Typography>
                      </div>
                    );
                  })}
            <div className={showColorBox ? `more ON` : `more`}>
              <Typography onClick={handleColorMoreOnClick}>
                {t("SEARCH.MORE")}
              </Typography>
            </div>
            <div
              className={
                showColorBox ? `${classes.showbox} ON` : classes.showbox
              }
              style={{ width: 650, bottom: -150 }}
            >
              <div className="title">
                <Typography>{t("VEHICLE.PRODUCT.COLOR")}</Typography>
              </div>
              <Divider className="top" />
              <ul className={classes.optionList}>
                {colorList
                  .filter(
                    (item) =>
                      isNotNull(item.value) && countData["color"] !== undefined
                  )
                  .map((item, idx) => {
                    return (
                      <li key={idx}>
                        <div>
                          <Checkbox
                            disabled={
                              countData["color"] !== undefined &&
                              countData["color"][item.value] !== undefined &&
                              countData["color"][item.value] > 0
                                ? false
                                : true
                            }
                            label={item.label}
                            checked={getCheckboxValue(item.value, color)}
                            dispatch={(isCheck: boolean) => {
                              handleCheckboxOnClick_Array(
                                isCheck,
                                item.value,
                                setColor
                              );
                            }}
                            formControlClassName={classes.formControl}
                            checkboxClassName={classes.checkbox}
                          />
                          {countData["color"] !== undefined &&
                          countData["color"][item.value] !== undefined &&
                          countData["color"][item.value] > 0 ? (
                            <Typography>
                              {countData["color"][item.value].toLocaleString()}
                            </Typography>
                          ) : (
                            <Typography className="disabled">0</Typography>
                          )}
                        </div>
                      </li>
                    );
                  })}
              </ul>
              <Divider className="bottom" />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Divider />
      {isNotNull(type) && (
        <>
          <CardContent onClick={() => setSearchExpanded(!searchExpanded)}>
            <Typography>{t("SEARCH.DETAIL_SEARCH")}</Typography>
            {searchExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </CardContent>
          <Accordion
            className={classes.accordion}
            square
            elevation={0}
            expanded={searchExpanded}
          >
            <AccordionSummary />
            <AccordionDetails>
              <Divider />
              {type === "CAR" && (
                <div className={classes.innerContainer}>
                  <CardContent
                    onClick={() => setCarDriveExpanded(!carDriveExpanded)}
                  >
                    <Typography>{t("VEHICLE.PRODUCT.DRIVE")}</Typography>
                    {carDriveExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </CardContent>
                  <Accordion
                    className={classes.accordion}
                    square
                    elevation={0}
                    expanded={carDriveExpanded}
                  >
                    <AccordionSummary />
                    <AccordionDetails>
                      <Divider />
                      <div className="innerContent">
                        {carDriveList.map((item, idx) => {
                          if (
                            item.value !== "" &&
                            countData["car_drive"] !== undefined &&
                            isNotNull(countData["car_drive"][item.value])
                          ) {
                            return (
                              <div className="content" key={idx}>
                                <Checkbox
                                  label={item.label}
                                  checked={getCheckboxValue(
                                    item.value,
                                    carDrive
                                  )}
                                  dispatch={(isCheck: boolean) => {
                                    handleCheckboxOnClick_Array(
                                      isCheck,
                                      item.value,
                                      setCarDrive
                                    );
                                  }}
                                  formControlClassName={classes.formControl}
                                  checkboxClassName={classes.checkbox}
                                />
                                <Typography>
                                  {countData["car_drive"][
                                    item.value
                                  ].toLocaleString()}
                                </Typography>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  <Divider />
                  <CardContent
                    onClick={() => setCarSeatsExpanded(!carSeatsExpanded)}
                  >
                    <Typography>{t("VEHICLE.PRODUCT.SEATS")}</Typography>
                    {carSeatsExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </CardContent>
                  <Accordion
                    className={classes.accordion}
                    square
                    elevation={0}
                    expanded={carSeatsExpanded}
                  >
                    <AccordionSummary />
                    <AccordionDetails>
                      <Divider />
                      <div className={classes.seatsContainer}>
                        <Dropdown
                          label={t("VEHICLE.PRODUCT.SEATS")}
                          variant="standard"
                          value={carSeats}
                          options={carSeatsList}
                          dispatch={setCarSeats}
                          formControlClassName={`${classes.dropdownFormControl} NORMAL`}
                          menuItemClassName={classes.dropdownMenuItem}
                        />
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              )}
              {type === "TRUCK" && (
                <div className={classes.innerContainer}>
                  <CardContent
                    onClick={() => setTruckDriveExpanded(!truckDriveExpanded)}
                  >
                    <Typography>{t("VEHICLE.PRODUCT.DRIVE")}</Typography>
                    {truckDriveExpanded ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </CardContent>
                  <Accordion
                    className={classes.accordion}
                    square
                    elevation={0}
                    expanded={truckDriveExpanded}
                  >
                    <AccordionSummary />
                    <AccordionDetails>
                      <Divider />
                      <div className="innerContent">
                        {truckDriveList.map((item, idx) => {
                          if (
                            item.value !== "" &&
                            countData["truck_drive"] !== undefined &&
                            isNotNull(countData["truck_drive"][item.value])
                          ) {
                            return (
                              <div className="content" key={idx}>
                                <Checkbox
                                  label={item.label}
                                  checked={getCheckboxValue(
                                    item.value,
                                    truckDrive
                                  )}
                                  dispatch={(isCheck: boolean) => {
                                    handleCheckboxOnClick_Array(
                                      isCheck,
                                      item.value,
                                      setTruckDrive
                                    );
                                  }}
                                  formControlClassName={classes.formControl}
                                  checkboxClassName={classes.checkbox}
                                />
                                <Typography>
                                  {countData["truck_drive"][
                                    item.value
                                  ].toLocaleString()}
                                </Typography>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  <Divider />
                  <CardContent
                    onClick={() =>
                      setTruckWheelbaseExpanded(!truckWheelbaseExpanded)
                    }
                  >
                    <Typography>{t("VEHICLE.PRODUCT.WHEELBASE")}</Typography>
                    {truckWheelbaseExpanded ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </CardContent>
                  <Accordion
                    className={classes.accordion}
                    square
                    elevation={0}
                    expanded={truckWheelbaseExpanded}
                  >
                    <AccordionSummary />
                    <AccordionDetails>
                      <Divider />
                      <div className="innerContent">
                        {truckWheelbaseList.map((item, idx) => {
                          if (
                            item.value !== "" &&
                            countData["truck_wheelbase"] !== undefined &&
                            isNotNull(countData["truck_wheelbase"][item.value])
                          ) {
                            return (
                              <div className="content" key={idx}>
                                <Checkbox
                                  label={item.label}
                                  checked={getCheckboxValue(
                                    item.value,
                                    truckWheelbase
                                  )}
                                  dispatch={(isCheck: boolean) => {
                                    handleCheckboxOnClick_Array(
                                      isCheck,
                                      item.value,
                                      setTruckWheelbase
                                    );
                                  }}
                                  formControlClassName={classes.formControl}
                                  checkboxClassName={classes.checkbox}
                                />
                                <Typography>
                                  {countData["truck_wheelbase"][
                                    item.value
                                  ].toLocaleString()}
                                </Typography>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  <Divider />
                  <CardContent
                    onClick={() =>
                      setTruckEngineTypeExpanded(!truckEngineTypeExpanded)
                    }
                  >
                    <Typography>{t("VEHICLE.PRODUCT.ENGINE_TYPE")}</Typography>
                    {truckEngineTypeExpanded ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </CardContent>
                  <Accordion
                    className={classes.accordion}
                    square
                    elevation={0}
                    expanded={truckEngineTypeExpanded}
                  >
                    <AccordionSummary />
                    <AccordionDetails>
                      <Divider />
                      <div className="innerContent">
                        {truckEngineTypeList.map((item, idx) => {
                          if (
                            item.value !== "" &&
                            countData["truck_engine_type"] !== undefined &&
                            isNotNull(
                              countData["truck_engine_type"][item.value]
                            )
                          ) {
                            return (
                              <div className="content" key={idx}>
                                <Checkbox
                                  label={item.label}
                                  checked={getCheckboxValue(
                                    item.value,
                                    truckEngineType
                                  )}
                                  dispatch={(isCheck: boolean) => {
                                    handleCheckboxOnClick_Array(
                                      isCheck,
                                      item.value,
                                      setTruckEngineType
                                    );
                                  }}
                                  formControlClassName={classes.formControl}
                                  checkboxClassName={classes.checkbox}
                                />
                                <Typography>
                                  {countData["truck_engine_type"][
                                    item.value
                                  ].toLocaleString()}
                                </Typography>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  <Divider />
                  <CardContent
                    onClick={() =>
                      setTruckCabinTypeExpanded(!truckCabinTypeExpanded)
                    }
                  >
                    <Typography>{t("VEHICLE.PRODUCT.CABIN_TYPE")}</Typography>
                    {truckCabinTypeExpanded ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </CardContent>
                  <Accordion
                    className={classes.accordion}
                    square
                    elevation={0}
                    expanded={truckCabinTypeExpanded}
                  >
                    <AccordionSummary />
                    <AccordionDetails>
                      <Divider />
                      <div className="innerContent">
                        {truckCabinTypeList.map((item, idx) => {
                          if (
                            item.value !== "" &&
                            countData["truck_cabin_type"] !== undefined &&
                            isNotNull(countData["truck_cabin_type"][item.value])
                          ) {
                            return (
                              <div className="content" key={idx}>
                                <Checkbox
                                  label={item.label}
                                  checked={getCheckboxValue(
                                    item.value,
                                    truckCabinType
                                  )}
                                  dispatch={(isCheck: boolean) => {
                                    handleCheckboxOnClick_Array(
                                      isCheck,
                                      item.value,
                                      setTruckCabinType
                                    );
                                  }}
                                  formControlClassName={classes.formControl}
                                  checkboxClassName={classes.checkbox}
                                />
                                <Typography>
                                  {countData["truck_cabin_type"][
                                    item.value
                                  ].toLocaleString()}
                                </Typography>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              )}
              {type === "BUS" && (
                <div className={classes.innerContainer}>
                  <CardContent
                    onClick={() => setBusSeatsExpanded(!busSeatsExpanded)}
                  >
                    <Typography>{t("VEHICLE.PRODUCT.SEATS")}</Typography>
                    {busSeatsExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </CardContent>
                  <Accordion
                    className={classes.accordion}
                    square
                    elevation={0}
                    expanded={busSeatsExpanded}
                  >
                    <AccordionSummary />
                    <AccordionDetails>
                      <Divider />
                      <div className={classes.seatsContainer}>
                        <Dropdown
                          label={t("VEHICLE.PRODUCT.SEATS")}
                          variant="standard"
                          value={busSeats}
                          options={busSeatsList}
                          dispatch={setBusSeats}
                          formControlClassName={`${classes.dropdownFormControl} NORMAL`}
                          menuItemClassName={classes.dropdownMenuItem}
                        />
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              )}
            </AccordionDetails>
          </Accordion>
          <Divider />
        </>
      )}
      {isNotNull(type) && (
        <>
          <CardContent onClick={() => setOptionExpanded(!optionExpanded)}>
            <Typography>{t("SEARCH.DETAIL_OPTION")}</Typography>
            {optionExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </CardContent>
          <Accordion
            className={classes.accordion}
            square
            elevation={0}
            expanded={optionExpanded}
          >
            <AccordionSummary />
            <AccordionDetails>
              <Divider />
              <div className="innerContent">
                {type === "CAR" &&
                  getOptionItemList(type)
                    .filter(
                      (item) =>
                        isNotNull(item.value) &&
                        countData["car_option"] !== undefined
                    )
                    .map((item, idx) => {
                      return (
                        <div className="content" key={idx}>
                          <Checkbox
                            disabled={
                              countData["car_option"] !== undefined &&
                              countData["car_option"][item.value] !==
                                undefined &&
                              countData["car_option"][item.value] > 0
                                ? false
                                : true
                            }
                            label={item.label}
                            checked={getCheckboxValue(item.value, carOption)}
                            dispatch={(isCheck: boolean) => {
                              handleCheckboxOnClick_Array(
                                isCheck,
                                item.value,
                                setCarOption
                              );
                            }}
                            formControlClassName={classes.formControl}
                            checkboxClassName={classes.checkbox}
                          />
                          {countData["car_option"] !== undefined &&
                          countData["car_option"][item.value] !== undefined &&
                          countData["car_option"][item.value] > 0 ? (
                            <Typography>
                              {countData["car_option"][
                                item.value
                              ].toLocaleString()}
                            </Typography>
                          ) : (
                            <Typography className="disabled">0</Typography>
                          )}
                        </div>
                      );
                    })}
                {type === "TRUCK" &&
                  getOptionItemList(type)
                    .filter(
                      (item) =>
                        isNotNull(item.value) &&
                        countData["truck_option"] !== undefined
                    )
                    .map((item, idx) => {
                      return (
                        <div className="content" key={idx}>
                          <Checkbox
                            disabled={
                              countData["truck_option"] !== undefined &&
                              countData["truck_option"][item.value] !==
                                undefined &&
                              countData["truck_option"][item.value] > 0
                                ? false
                                : true
                            }
                            label={item.label}
                            checked={getCheckboxValue(item.value, truckOption)}
                            dispatch={(isCheck: boolean) => {
                              handleCheckboxOnClick_Array(
                                isCheck,
                                item.value,
                                setTruckOption
                              );
                            }}
                            formControlClassName={classes.formControl}
                            checkboxClassName={classes.checkbox}
                          />
                          {countData["truck_option"] !== undefined &&
                          countData["truck_option"][item.value] !== undefined &&
                          countData["truck_option"][item.value] > 0 ? (
                            <Typography>
                              {countData["truck_option"][
                                item.value
                              ].toLocaleString()}
                            </Typography>
                          ) : (
                            <Typography className="disabled">0</Typography>
                          )}
                        </div>
                      );
                    })}
                {type === "BUS" &&
                  getOptionItemList(type)
                    .filter(
                      (item) =>
                        isNotNull(item.value) &&
                        countData["bus_option"] !== undefined
                    )
                    .map((item, idx) => {
                      return (
                        <div className="content" key={idx}>
                          <Checkbox
                            disabled={
                              countData["bus_option"] !== undefined &&
                              countData["bus_option"][item.value] !==
                                undefined &&
                              countData["bus_option"][item.value] > 0
                                ? false
                                : true
                            }
                            label={item.label}
                            checked={getCheckboxValue(item.value, busOption)}
                            dispatch={(isCheck: boolean) => {
                              handleCheckboxOnClick_Array(
                                isCheck,
                                item.value,
                                setBusOption
                              );
                            }}
                            formControlClassName={classes.formControl}
                            checkboxClassName={classes.checkbox}
                          />
                          {countData["bus_option"] !== undefined &&
                          countData["bus_option"][item.value] !== undefined &&
                          countData["bus_option"][item.value] > 0 ? (
                            <Typography>
                              {countData["bus_option"][
                                item.value
                              ].toLocaleString()}
                            </Typography>
                          ) : (
                            <Typography className="disabled">0</Typography>
                          )}
                        </div>
                      );
                    })}
                <div className={showOptionBox ? `more ON` : `more`}>
                  <Typography onClick={handleOptionMoreOnClick}>
                    {t("SEARCH.MORE")}
                  </Typography>
                </div>
                <div
                  className={
                    showOptionBox ? `${classes.showbox} ON` : classes.showbox
                  }
                  style={{ width: 725, bottom: -950 }}
                >
                  <div className="title">
                    <Typography>{t("SEARCH.DETAIL_OPTION")}</Typography>
                  </div>
                  <Divider className="top" />
                  <div className="subTitle" style={{ paddingTop: 0 }}>
                    <Typography>{t("VEHICLE.OPTION.INEXTERIOR")}</Typography>
                  </div>
                  <Divider />
                  <ul className={classes.optionList}>
                    {Object.keys(optionList[type]["INEXTERIOR"]).map(
                      (option, idx) => {
                        return (
                          <li key={idx}>
                            <div style={{ flexDirection: "column" }}>
                              <Checkbox
                                disabled={getDisabled_Option(
                                  optionList[type]["INEXTERIOR"][option]
                                )}
                                label={
                                  <Markup
                                    content={t("VEHICLE.OPTION." + option)}
                                  />
                                }
                                checked={getCheckboxValue_Option(
                                  optionList[type]["INEXTERIOR"][option]
                                )}
                                dispatch={(isCheck: boolean) =>
                                  handleCheckboxOnClick_Option(
                                    isCheck,
                                    optionList[type]["INEXTERIOR"][option]
                                  )
                                }
                                formControlClassName={classes.formControl}
                                checkboxClassName={classes.checkbox}
                              />
                              {type === "CAR" &&
                                ["SUNROOF"].includes(option) && (
                                  <RadioGroup
                                    row
                                    className={classes.radioGroup}
                                    value={getRadioGroupValue_Option(
                                      optionList[type]["INEXTERIOR"][option]
                                    )}
                                    onChange={(e) =>
                                      handleRadioGroupOnClick_Option(
                                        e.target.value,
                                        optionList[type]["INEXTERIOR"][option]
                                      )
                                    }
                                  >
                                    {optionList[type]["INEXTERIOR"][option].map(
                                      (detail_option, idx) => {
                                        return (
                                          <Radio
                                            key={idx}
                                            disabled={getDisabled_Option([
                                              optionList[type]["INEXTERIOR"][
                                                option
                                              ][idx],
                                            ])}
                                            label={
                                              <Markup
                                                content={detail_option.label}
                                              />
                                            }
                                            value={detail_option.value}
                                            formControlClassName={
                                              classes.formControl
                                            }
                                            radioClassName={classes.radio}
                                          />
                                        );
                                      }
                                    )}
                                  </RadioGroup>
                                )}
                              {["HEADLAMP"].includes(option) && (
                                <RadioGroup
                                  row
                                  className={classes.radioGroup}
                                  value={getRadioGroupValue_Option(
                                    optionList[type]["INEXTERIOR"][option]
                                  )}
                                  onChange={(e) =>
                                    handleRadioGroupOnClick_Option(
                                      e.target.value,
                                      optionList[type]["INEXTERIOR"][option]
                                    )
                                  }
                                >
                                  {optionList[type]["INEXTERIOR"][option].map(
                                    (detail_option, idx) => {
                                      return (
                                        <Radio
                                          key={idx}
                                          disabled={getDisabled_Option([
                                            optionList[type]["INEXTERIOR"][
                                              option
                                            ][idx],
                                          ])}
                                          label={
                                            <Markup
                                              content={detail_option.label}
                                            />
                                          }
                                          value={detail_option.value}
                                          formControlClassName={
                                            classes.formControl
                                          }
                                          radioClassName={classes.radio}
                                        />
                                      );
                                    }
                                  )}
                                </RadioGroup>
                              )}
                            </div>
                          </li>
                        );
                      }
                    )}
                  </ul>
                  <Divider />
                  <div className="subTitle">
                    <Typography>{t("VEHICLE.OPTION.SEAT")}</Typography>
                  </div>
                  <Divider />
                  <ul className={classes.optionList}>
                    {Object.keys(optionList[type]["SEAT"]).map(
                      (option, idx) => {
                        return (
                          <li key={idx}>
                            <div style={{ flexDirection: "column" }}>
                              <Checkbox
                                disabled={getDisabled_Option(
                                  optionList[type]["SEAT"][option]
                                )}
                                label={
                                  <Markup
                                    content={t("VEHICLE.OPTION." + option)}
                                  />
                                }
                                checked={getCheckboxValue_Option(
                                  optionList[type]["SEAT"][option]
                                )}
                                dispatch={(isCheck: boolean) =>
                                  handleCheckboxOnClick_Option(
                                    isCheck,
                                    optionList[type]["SEAT"][option]
                                  )
                                }
                                formControlClassName={classes.formControl}
                                checkboxClassName={classes.checkbox}
                              />
                              {type === "CAR" &&
                                [
                                  "HEAT_SEAT",
                                  "POWER_SEAT",
                                  "VENT_SEAT",
                                  "MEMORY_SEAT",
                                ].includes(option) && (
                                  <div className={classes.checkboxGroup}>
                                    {optionList[type]["SEAT"][option].map(
                                      (detail_option, idx) => {
                                        return (
                                          <Checkbox
                                            key={idx}
                                            disabled={getDisabled_Option([
                                              optionList[type]["SEAT"][option][
                                                idx
                                              ],
                                            ])}
                                            label={
                                              <Markup
                                                content={detail_option.label}
                                              />
                                            }
                                            checked={getCheckboxValue_Option([
                                              optionList[type]["SEAT"][option][
                                                idx
                                              ],
                                            ])}
                                            dispatch={(isCheck: boolean) =>
                                              handleCheckboxOnClick_Option(
                                                isCheck,
                                                [
                                                  optionList[type]["SEAT"][
                                                    option
                                                  ][idx],
                                                ]
                                              )
                                            }
                                            formControlClassName={
                                              classes.formControl
                                            }
                                            checkboxClassName={classes.checkbox}
                                          />
                                        );
                                      }
                                    )}
                                  </div>
                                )}
                            </div>
                          </li>
                        );
                      }
                    )}
                  </ul>
                  <Divider />
                  <div className="subTitle">
                    <Typography>{t("VEHICLE.OPTION.CONVINIENCE")}</Typography>
                  </div>
                  <Divider />
                  <ul className={classes.optionList}>
                    {Object.keys(optionList[type]["CONVINIENCE"]).map(
                      (option, idx) => {
                        return (
                          <li key={idx}>
                            <div style={{ flexDirection: "column" }}>
                              <Checkbox
                                disabled={getDisabled_Option(
                                  optionList[type]["CONVINIENCE"][option]
                                )}
                                label={
                                  <Markup
                                    content={t("VEHICLE.OPTION." + option)}
                                  />
                                }
                                checked={getCheckboxValue_Option(
                                  optionList[type]["CONVINIENCE"][option]
                                )}
                                dispatch={(isCheck: boolean) =>
                                  handleCheckboxOnClick_Option(
                                    isCheck,
                                    optionList[type]["CONVINIENCE"][option]
                                  )
                                }
                                formControlClassName={classes.formControl}
                                checkboxClassName={classes.checkbox}
                              />
                              {type === "CAR" &&
                                ["CRUISE_CONTROL"].includes(option) && (
                                  <RadioGroup
                                    row
                                    className={classes.radioGroup}
                                    value={getRadioGroupValue_Option(
                                      optionList[type]["CONVINIENCE"][option]
                                    )}
                                    onChange={(e) =>
                                      handleRadioGroupOnClick_Option(
                                        e.target.value,
                                        optionList[type]["CONVINIENCE"][option]
                                      )
                                    }
                                  >
                                    {optionList[type]["CONVINIENCE"][
                                      option
                                    ].map((detail_option, idx) => {
                                      return (
                                        <Radio
                                          key={idx}
                                          disabled={getDisabled_Option([
                                            optionList[type]["CONVINIENCE"][
                                              option
                                            ][idx],
                                          ])}
                                          label={
                                            <Markup
                                              content={detail_option.label}
                                            />
                                          }
                                          value={detail_option.value}
                                          formControlClassName={
                                            classes.formControl
                                          }
                                          radioClassName={classes.radio}
                                        />
                                      );
                                    })}
                                  </RadioGroup>
                                )}
                              {["AIRCON"].includes(option) && (
                                <RadioGroup
                                  row
                                  className={classes.radioGroup}
                                  value={getRadioGroupValue_Option(
                                    optionList[type]["CONVINIENCE"][option]
                                  )}
                                  onChange={(e) =>
                                    handleRadioGroupOnClick_Option(
                                      e.target.value,
                                      optionList[type]["CONVINIENCE"][option]
                                    )
                                  }
                                >
                                  {optionList[type]["CONVINIENCE"][option].map(
                                    (detail_option, idx) => {
                                      return (
                                        <Radio
                                          key={idx}
                                          disabled={getDisabled_Option([
                                            optionList[type]["CONVINIENCE"][
                                              option
                                            ][idx],
                                          ])}
                                          label={
                                            <Markup
                                              content={detail_option.label}
                                            />
                                          }
                                          value={detail_option.value}
                                          formControlClassName={
                                            classes.formControl
                                          }
                                          radioClassName={classes.radio}
                                        />
                                      );
                                    }
                                  )}
                                </RadioGroup>
                              )}
                            </div>
                          </li>
                        );
                      }
                    )}
                  </ul>
                  <Divider />
                  <div className="subTitle">
                    <Typography>{t("VEHICLE.OPTION.SAFETY")}</Typography>
                  </div>
                  <Divider />
                  <ul className={classes.optionList}>
                    {Object.keys(optionList[type]["SAFETY"]).map(
                      (option, idx) => {
                        return (
                          <li key={idx}>
                            <div style={{ flexDirection: "column" }}>
                              <Checkbox
                                disabled={getDisabled_Option(
                                  optionList[type]["SAFETY"][option]
                                )}
                                label={
                                  <Markup
                                    content={t("VEHICLE.OPTION." + option)}
                                  />
                                }
                                checked={getCheckboxValue_Option(
                                  optionList[type]["SAFETY"][option]
                                )}
                                dispatch={(isCheck: boolean) =>
                                  handleCheckboxOnClick_Option(
                                    isCheck,
                                    optionList[type]["SAFETY"][option]
                                  )
                                }
                                formControlClassName={classes.formControl}
                                checkboxClassName={classes.checkbox}
                              />
                              {["AIRBAG", "CAMERA", "PARK_SENSOR"].includes(
                                option
                              ) && (
                                <div className={classes.checkboxGroup}>
                                  {optionList[type]["SAFETY"][option].map(
                                    (detail_option, idx) => {
                                      return (
                                        <Checkbox
                                          key={idx}
                                          disabled={getDisabled_Option([
                                            optionList[type]["SAFETY"][option][
                                              idx
                                            ],
                                          ])}
                                          label={
                                            <Markup
                                              content={detail_option.label}
                                            />
                                          }
                                          checked={getCheckboxValue_Option([
                                            optionList[type]["SAFETY"][option][
                                              idx
                                            ],
                                          ])}
                                          dispatch={(isCheck: boolean) =>
                                            handleCheckboxOnClick_Option(
                                              isCheck,
                                              [
                                                optionList[type]["SAFETY"][
                                                  option
                                                ][idx],
                                              ]
                                            )
                                          }
                                          formControlClassName={
                                            classes.formControl
                                          }
                                          checkboxClassName={classes.checkbox}
                                        />
                                      );
                                    }
                                  )}
                                </div>
                              )}
                            </div>
                          </li>
                        );
                      }
                    )}
                  </ul>
                  <Divider className="bottom" />
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Divider />
        </>
      )}
      <CardContent>
        <div>
          <Input
            fullWidth
            isClearable
            variant="standard"
            placeholder="ex. Prod No, Advert text..."
            value={searchText}
            dispatch={setSearchText}
            className={classes.searchInput}
            inputProps={{ maxLength: 1000 }}
          />
          <Button
            className={classes.button}
            variant="contained"
            onClick={handleSearchOnClick}
          >
            {t("BUTTON.SEARCH")}
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

export default SearchContainer;
