import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
// Components
import Slider from "react-slick";
import { Theme, Typography } from "@mui/material";
import { CompanyLogoIcon } from "@/common/icons";
// Styles
import { keyframes } from "@mui/material";
import { useStyles } from "@/common/lib/style/hooks";
import { colors } from "@/common/lib/style/palette";
// Images
import image1 from "@/browser/images/banners/main_image1.png";
import image2 from "@/browser/images/banners/main_image2.png";
import image3 from "@/browser/images/banners/main_image3.png";
import image4 from "@/browser/images/banners/main_image4.png";

const fadein = keyframes`
  from {
    opacity: 0;
    transform: translateY(5px);
  }
  to {
    opacity: 1;
    transform: none;
  }
`;

const styles = (theme: Theme) => ({
  carousel: {
    "& > .slick-list .slick-active p": {
      animation: `${fadein} 1s ${theme.transitions.easing.easeInOut}`,
    },
    "& > .slick-dots": {
      "& > li > button:before": {
        content: "'■'",
        fontSize: 18,
      },
      "& > li.slick-active > button:before": {
        color: colors.blue[10],
        opacity: 0.85,
      },
      "& > li:not(.slick-active) > button": {
        "&:before": {
          color: colors.blue[4],
          opacity: 0.75,
        },
        "&:hover": {
          "&:before": {
            color: colors.blue[6],
            opacity: 0.85,
          },
        },
      },
    },
  },
  carouselDiv: {
    position: "relative",
  },
  logoImage: {
    position: "absolute",
    top: 22.5,
    right: 22.5,
    width: 200,
    fill: colors.gray[0],
  },
  image: {
    width: "100%",
    aspectRatio: "3",
  },
  titleText: {
    position: "absolute",
    margin: 0,
    top: "30%",
    width: "100%",
    color: "white",
    fontSize: 35,
    fontWeight: "bold",
    textAlign: "center",
    textShadow: "0.5px 0 #000, -0.5px 0 #000, 0 0.5px #000, 0 -0.5px #000",
    wordBreak: "keep-all",
  },
  motoDiv: {
    position: "absolute",
    bottom: "15%",
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
  },
  motoText: {
    margin: 0,
    color: colors.blue[6],
    fontSize: 30,
    fontWeight: "bold",
    textShadow: "1px 0 #FFF, -1px 0 #FFF, 0 1px #FFF, 0 -1px #FFF",
    textAlign: "center",
    wordBreak: "keep-all",
    "&.navy": {
      color: colors.blue[10],
    },
  },
  imageText: {
    position: "absolute",
    margin: 0,
    left: 27.5,
    bottom: 27.5,
    color: "white",
    fontSize: 30,
    fontWeight: "bold",
    textShadow: "-1px 0 #000, 0 1px #000",
    wordBreak: "keep-all",
  },
});

const MainCarousel = () => {
  const classes = useStyles(styles);
  const { t } = useTranslation();

  const imageList = [
    {
      image: image1,
      text: t("MAIN_CAROUSEL.TEXT1"),
    },
    {
      image: image2,
      text: t("MAIN_CAROUSEL.TEXT2"),
    },
    {
      image: image3,
      text: t("MAIN_CAROUSEL.TEXT3"),
    },
    {
      image: image4,
      text: t("MAIN_CAROUSEL.TEXT4"),
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    fade: true,
    arrows: false,
    draggable: false,
    swipe: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6500,
  };

  return (
    <Slider {...settings} className={classes.carousel}>
      {imageList.map((image, idx) => {
        return (
          <div key={idx} className={classes.carouselDiv}>
            <CompanyLogoIcon className={classes.logoImage} />
            <img alt="" src={image.image} className={classes.image} />
            {idx === 0 ? (
              <>
                <Typography className={classes.titleText}>
                  <Markup content={image.text} />
                </Typography>
                <div className={classes.motoDiv}>
                  <Typography className={classes.motoText}>
                    <Markup content={t("MAIN_CAROUSEL.MOTO1")} />
                  </Typography>
                  <Typography className={`${classes.motoText} navy`}>
                    <Markup content={t("MAIN_CAROUSEL.MOTO2")} />
                  </Typography>
                  <Typography className={`${classes.motoText} navy`}>
                    <Markup content={t("MAIN_CAROUSEL.MOTO3")} />
                  </Typography>
                  <Typography className={classes.motoText}>
                    <Markup content={t("MAIN_CAROUSEL.MOTO4")} />
                  </Typography>
                </div>
              </>
            ) : (
              <Typography className={classes.imageText}>
                <Markup content={image.text} />
              </Typography>
            )}
          </div>
        );
      })}
    </Slider>
  );
};

export default MainCarousel;
