/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Markup } from "interweave";
// Components
import { Theme, Typography } from "@mui/material";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
import { colors } from "@/common/lib/style/palette";
// Libs
import {
  getAutowiniCommission,
  getAutowiniBusCommission,
} from "@/common/lib/common";

const styles = (theme: Theme) => ({
  container: {
    zIndex: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: theme.palette.background.default,
    position: "sticky",
    top: 0,
    padding: "10px 0",
    userSelect: "none",
  },
  titleDiv: {
    "& .title": {
      fontSize: "2.25rem",
      fontWeight: "bold",
      lineHeight: "1.15",
      wordSpacing: "-1.5px",
    },
    "& .advert_text": {
      fontSize: "1.85rem",
      lineHeight: "1.35",
      wordSpacing: "-1.5px",
    },
  },
  priceDiv: {
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "column",
    paddingRight: 15,
    "& > div": {
      display: "flex",
      alignItems: "flex-end",
    },
    "& .price": {
      fontSize: "2.35rem",
      fontWeight: "bold",
      color: theme.palette.primary.main,
    },
    "& .discount_price": {
      fontSize: "2.35rem",
      fontWeight: "bold",
      color: theme.palette.error.main,
    },
    "& .disable_price": {
      fontSize: "1.85rem",
      fontWeight: "bold",
      color: theme.palette.divider,
      textDecoration: "line-through",
      textDecorationThickness: "0.1rem",
      textDecorationColor: theme.palette.divider,
      marginRight: 10,
    },
    "& .location": {
      fontSize: "0.975rem",
      fontWeight: 500,
      marginTop: 5,
    },
    "& .MuiTypography-root": {
      lineHeight: "1",
    },
    "&.WAIT": {
      borderRight: "0.45rem solid " + colors.gray[8],
    },
    "&.ON": {
      borderRight: "0.45rem solid " + colors.blue[7],
    },
    "&.REZ": {
      borderRight: "0.45rem solid " + colors.cyan[6],
    },
    "&.DONE": {
      borderRight: "0.45rem solid " + colors.teal[7],
    },
    "&.NOT": {
      borderRight: "0.45rem solid " + colors.red[7],
    },
  },
});

interface propType {
  data: VehicleData | undefined;
}

const TopContainer = ({ data }: propType) => {
  const classes = useStyles(styles);

  const [isDiscount, setIsDiscount] = useState(false);

  useEffect(() => {
    if (data !== undefined) {
      if (data.sales.discount_expired !== null) {
        const todayDate = new Date();
        const expireDate = new Date(data.sales.discount_expired);
        expireDate.setHours(expireDate.getHours() + 9);

        if (expireDate >= todayDate) {
          setIsDiscount(true);
        }
      }
    }
  }, [data]);

  const getMainTitle = () => {
    const titles = [];
    if (data !== undefined) {
      titles.push(data.product.year);
      titles.push(data.product.maker);
      titles.push(data.product.detail_model);
      if (data.product.type === "TRUCK" && data.truck_product !== undefined) {
        titles.push("<br />");
        if (data.truck_product.category === "CargoTruck") {
          titles.push(data.truck_product.engine_type);
          titles.push(data.truck_product.cabin_type);
        }
        if (
          data.truck_product.category === "TopTruck" ||
          data.truck_product.category === "WingbodyTruck" ||
          data.truck_product.category === "Trailer" ||
          data.truck_product.category === "TankLorry"
        ) {
          titles.push(data.truck_product.sub_category);
        }
        titles.push(data.truck_product.drive);
        titles.push(data.truck_product.tonner.toFixed(1) + "t");
      }
    }
    return titles.join(" ");
  };

  return (
    <>
      {data !== undefined && (
        <div id="toolbar" className={classes.container}>
          <div className={classes.titleDiv}>
            <Typography className="title">
              <Markup content={data !== undefined ? getMainTitle() : ""} />
            </Typography>
            <Typography className="advert_text">
              {data !== undefined ? data.sales.advert_text : ""}
            </Typography>
          </div>
          <div
            className={
              data !== undefined
                ? `${classes.priceDiv} ${data.sales.status}`
                : `${classes.priceDiv}`
            }
          >
            {/* {isNotNull(loginToken) ? ( */}
            {isDiscount ? (
              <div>
                <Typography className="disable_price">
                  USD{" "}
                  {data !== undefined
                    ? (
                        data.sales.price +
                        (data.product.type === "CAR" ||
                        data.product.type === "TRUCK"
                          ? getAutowiniCommission(data.sales.price)
                          : getAutowiniBusCommission(data.sales.price))
                      ).toLocaleString()
                    : 0}
                </Typography>
                <Typography className="discount_price">
                  USD{" "}
                  {data !== undefined
                    ? (
                        data.sales.price +
                        (data.product.type === "CAR" ||
                        data.product.type === "TRUCK"
                          ? getAutowiniCommission(data.sales.price)
                          : getAutowiniBusCommission(data.sales.price)) -
                        (data.sales.discount !== null ? data.sales.discount : 0)
                      ).toLocaleString()
                    : 0}
                </Typography>
              </div>
            ) : (
              <Typography className="price">
                USD{" "}
                {data !== undefined
                  ? (
                      data.sales.price +
                      (data.product.type === "CAR" ||
                      data.product.type === "TRUCK"
                        ? getAutowiniCommission(data.sales.price)
                        : getAutowiniBusCommission(data.sales.price))
                    ).toLocaleString()
                  : 0}
              </Typography>
            )}
            {/* ) : (
              <Typography className="price">USD *,***</Typography>
            )} */}
            <Typography className="location">
              {data !== undefined
                ? "FOB " +
                  data.sales.location_region +
                  ", " +
                  data.sales.location_country
                : "FOB Incheon, Korea"}
            </Typography>
          </div>
        </div>
      )}
    </>
  );
};

export default TopContainer;
