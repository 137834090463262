/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
// Redux
import { useTypedSelector } from "@/common/lib/redux/store";
// Components
import { Theme, Typography } from "@mui/material";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// Libs
import { isNotNull, getDateFormat } from "@/common/lib/common";

const styles = (theme: Theme) => ({
  container: {
    backgroundColor: theme.palette.error.main,
    padding: "10px 25px",
    opacity: 0.9,
    "& .title": {
      fontSize: "1.35rem",
      fontWeight: "bold",
      color: "white",
    },
    "& .period": {
      fontSize: "1rem",
      fontWeight: 500,
      color: "white",
    },
  },
});

interface propType {
  data: VehicleData | undefined;
}

const PromoteContainer = ({ data }: propType) => {
  const classes = useStyles(styles);
  const { t } = useTranslation();
  const { loginToken } = useTypedSelector((state) => state.auth);

  const [isDiscount, setIsDiscount] = useState(false);

  useEffect(() => {
    if (data !== undefined) {
      if (data.sales.discount_expired !== null) {
        const todayDate = new Date();
        const expireDate = new Date(data.sales.discount_expired);
        expireDate.setHours(expireDate.getHours() + 9);

        if (expireDate >= todayDate) {
          setIsDiscount(true);
        } else {
          setIsDiscount(false);
        }
      }
    }
  }, [data]);

  return (
    <>
      {isNotNull(loginToken) && isDiscount && (
        <div className={classes.container}>
          <Typography className="title">
            {t("DETAIL.PROMOTE.SPECIAL_EVENT")}
          </Typography>
          <Typography className="period">
            <Markup
              content={t("DETAIL.PROMOTE.PERIOD", {
                startDate:
                  data !== undefined
                    ? getDateFormat(data.sales.discount_created)
                    : "1970/01/01",
                endDate:
                  data !== undefined
                    ? getDateFormat(data.sales.discount_expired)
                    : "2099/12/31",
              })}
            />
          </Typography>
        </div>
      )}
    </>
  );
};

export default PromoteContainer;
