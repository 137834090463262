// Redux
import { useTypedSelector } from "@/common/lib/redux/store";
// Components
import { Image } from "@/common/components";
import BasePage from "@/mobile/pages/common/BasePage";
import { SNSFab } from "@/mobile/components/common";
import {
  IndexSearchContainer,
  MainCarousel,
  ProductCarousel,
} from "@/mobile/components/index";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// Libs
import { getUTCDate, isNotNull } from "@/common/lib/common";

const styles = () => ({
  basePage: {
    marginBottom: "1vh",
  },
  divider: {
    margin: "1vh 0",
  },
  image: {
    width: "100%",
  },
});

const IndexPage = () => {
  const classes = useStyles(styles);
  const { loginToken } = useTypedSelector((state) => state.auth);

  const promoteData = [
    {
      key: "promoteData",
      page: 1,
      size: 32,
      align: "-discount_expired",
      params: {
        status: "ON",
        criteria: JSON.stringify([
          {
            type: "DISCOUNT_EXPIRED",
            period: getUTCDate(new Date()) + "~2099-12-31T23:59",
          },
        ]),
      },
    },
  ];
  const suvData = [
    {
      key: "suvData_1",
      page: 1,
      size: 8,
      align: "-modified",
      params: {
        status: "ON",
        model: "SantaFe",
      },
    },
    {
      key: "suvData_2",
      page: 1,
      size: 8,
      align: "-modified",
      params: {
        status: "ON",
        model: "Tucson",
      },
    },
    {
      key: "suvData_3",
      page: 1,
      size: 8,
      align: "-modified",
      params: {
        status: "ON",
        model: "Sportage",
      },
    },
    {
      key: "suvData_4",
      page: 1,
      size: 8,
      align: "-modified",
      params: {
        status: "ON",
        model: "Sorento",
      },
    },
  ];
  const vanData = [
    {
      key: "vanData_1",
      page: 1,
      size: 8,
      align: "-modified",
      params: {
        status: "ON",
        model: "Carnival",
      },
    },
    {
      key: "vanData_2",
      page: 1,
      size: 8,
      align: "-modified",
      params: {
        status: "ON",
        model: "Starex",
      },
    },
  ];
  const newCarData = [
    {
      key: "newCarData_1",
      page: 1,
      size: 24,
      align: "-created",
      params: {
        status: "ON",
        type: "CAR",
      },
    },
  ];
  const newTruckData = [
    {
      key: "newTruckData_1",
      page: 1,
      size: 24,
      align: "-created",
      params: {
        status: "ON",
        type: "TRUCK",
      },
    },
  ];

  return (
    <BasePage className={classes.basePage}>
      <MainCarousel />
      <IndexSearchContainer />
      <Image
        alt=""
        src={require(`@/mobile/images/image2.png`)}
        className={classes.image}
      />
      {isNotNull(loginToken) && (
        <ProductCarousel
          position="right"
          title="Special Promotion <span>SALE</span>"
          rows={1}
          data={promoteData}
        />
      )}
      <ProductCarousel
        position="right"
        title="<span>Best</span> SUV/VAN Models"
        rows={1}
        data={suvData}
      />
      <ProductCarousel rows={1} data={vanData} />
      <ProductCarousel
        position="right"
        title="<span>New</span> Products in Stock"
        rows={1}
        data={newCarData}
      />
      <ProductCarousel position="right" rows={1} data={newTruckData} />
      <SNSFab />
    </BasePage>
  );
};

export default IndexPage;
