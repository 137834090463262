/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { v4 as uuidv4 } from "uuid";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { Markup } from "interweave";
// Redux
import { setLoginToken } from "@/common/lib/redux/actions/authAction";
import {
  loadingOn,
  loadingOff,
  setErrorText,
} from "@/common/lib/redux/actions/commonAction";
import { useTypedDispatch } from "@/common/lib/redux/store";
// Components
import {
  Divider,
  IconButton,
  InputAdornment,
  Theme,
  Typography,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CheckIcon from "@mui/icons-material/Check";
import CollectionsIcon from "@mui/icons-material/Collections";
import {
  Button,
  Image,
  Input,
  NationAutocomplete,
  PhoneInput,
} from "@/common/components";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// API
import { onError } from "@/common/lib/api/common";
import { loginAPI } from "@/common/lib/api/auth";
import { postUserInfo, checkUserDuplicate } from "@/common/lib/api/user";
// Libs
import {
  isNull,
  isNotNull,
  resizeImage,
  validatePassword,
} from "@/common/lib/common";
import { emailRegex, phoneRegex } from "@/common/lib/regex";

const styles = (theme: Theme) => ({
  container: {
    width: "85%",
    margin: "0 auto",
    border: "1px solid " + theme.palette.divider,
    padding: "65px 50px",
    userSelect: "none",
  },
  title: {
    "& > h1": {
      userSelect: "none",
      fontSize: 30,
      fontWeight: "bold",
      color: theme.palette.primary.main,
      textAlign: "center",
      marginBottom: 35,
    },
    "& > ul.content": {
      margin: 0,
      userSelect: "none",
      "& > li": {
        fontSize: 15,
        marginBottom: 3,
        "&::marker": {
          fontSize: 12,
        },
        "& > span > span": {
          color: theme.palette.error.main,
          fontWeight: "bold",
          padding: "0 3px",
        },
        "&.listStyleNone": {
          listStyleType: "none",
          fontSize: "0.8rem",
        },
      },
    },
  },
  topDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 35px",
    "& > div:first-child": {
      position: "relative",
      width: "35%",
      aspectRatio: "1",
    },
    "& > div:last-child": {
      display: "flex",
      flexDirection: "column",
      width: "50%",
      "& > p": {
        userSelect: "none",
        fontSize: 15,
        fontWeight: "bold",
        "&.require-value::after": {
          content: "'*'",
          color: theme.palette.error.main,
          padding: 3,
        },
        "&.unique-value::after": {
          content: "'**'",
          color: theme.palette.error.main,
          padding: 3,
        },
      },
      "& > .MuiFormControl-root": {
        marginBottom: 15,
        "&.good": {
          "& > div": {
            color: theme.palette.primary.main,
          },
          "& .MuiFormHelperText-root, & .MuiSvgIcon-root": {
            color: theme.palette.primary.main,
          },
        },
        "&.bad": {
          "& > div": {
            color: theme.palette.error.main,
          },
          "& .MuiFormHelperText-root, & .MuiSvgIcon-root": {
            color: theme.palette.error.main,
          },
        },
      },
      "& > ul": {
        margin: 0,
        marginBottom: 10,
        paddingLeft: 20,
        "& > li": {
          fontSize: 14,
          "&::marker": {
            fontSize: 12,
          },
          "&.bad": {
            color: theme.palette.error.main,
            fontWeight: "bold",
            "&:not(.special)::after": {
              content: "'X'",
              padding: "0 3px",
            },
          },
          "&.good": {
            color: theme.palette.primary.main,
            fontWeight: "bold",
            "&:not(.special)::after": {
              content: "'O'",
              padding: "0 3px",
            },
          },
          "&.special": {
            listStyleType: "none",
          },
        },
      },
    },
  },
  middleDiv: {
    padding: "0 35px",
    "& > h1": {
      userSelect: "none",
      fontSize: 22,
      fontWeight: "bold",
      color: theme.palette.primary.main,
      marginBottom: 25,
    },
    "& > div.content": {
      width: "92.5%",
      marginLeft: "7.5%",
      "& > p": {
        userSelect: "none",
        fontSize: 15,
        fontWeight: "bold",
        "&.require-value::after": {
          content: "'*'",
          color: theme.palette.error.main,
          padding: 3,
        },
        "&.unique-value::after": {
          content: "'**'",
          color: theme.palette.error.main,
          padding: 3,
        },
      },
      "& > .MuiFormControl-root": {
        marginBottom: 15,
        "&.good": {
          "& > div": {
            color: theme.palette.primary.main,
          },
          "& .MuiFormHelperText-root, & .MuiSvgIcon-root": {
            color: theme.palette.primary.main,
          },
        },
        "&.bad": {
          "& > div": {
            color: theme.palette.error.main,
          },
          "& .MuiFormHelperText-root, & .MuiSvgIcon-root": {
            color: theme.palette.error.main,
          },
        },
      },
      "& > .MuiAutocomplete-root": {
        marginBottom: 15,
      },
    },
  },
  middleDiv2: {
    padding: "0 35px",
    "& > h1": {
      userSelect: "none",
      fontSize: 22,
      fontWeight: "bold",
      color: theme.palette.primary.main,
      marginBottom: 25,
    },
    "& > div.content": {
      width: "92.5%",
      marginLeft: "7.5%",
      "& > div.subcontent": {
        display: "flex",
        justifyContent: "space-between",
        "& > div": {
          width: "45%",
          "& > p": {
            userSelect: "none",
            fontSize: 15,
            fontWeight: "bold",
            "&.require-value::after": {
              content: "'*'",
              color: theme.palette.error.main,
              padding: 3,
            },
            "&.unique-value::after": {
              content: "'**'",
              color: theme.palette.error.main,
              padding: 3,
            },
          },
          "& > .MuiFormControl-root": {
            marginBottom: 15,
            "&.good": {
              "& > div": {
                color: theme.palette.primary.main,
              },
              "& .MuiFormHelperText-root, & .MuiSvgIcon-root": {
                color: theme.palette.primary.main,
              },
            },
            "&.bad": {
              "& > div": {
                color: theme.palette.error.main,
              },
              "& .MuiFormHelperText-root, & .MuiSvgIcon-root": {
                color: theme.palette.error.main,
              },
            },
          },
        },
      },
      "& > p": {
        userSelect: "none",
        fontSize: 15,
        fontWeight: "bold",
        "&.require-value::after": {
          content: "'*'",
          color: theme.palette.error.main,
          padding: 3,
        },
        "&.unique-value::after": {
          content: "'**'",
          color: theme.palette.error.main,
          padding: 3,
        },
      },
      "& > .MuiFormControl-root": {
        marginBottom: 15,
        "&.good": {
          "& > div": {
            color: theme.palette.primary.main,
          },
          "& .MuiFormHelperText-root, & .MuiSvgIcon-root": {
            color: theme.palette.primary.main,
          },
        },
        "&.bad": {
          "& > div": {
            color: theme.palette.error.main,
          },
          "& .MuiFormHelperText-root, & .MuiSvgIcon-root": {
            color: theme.palette.error.main,
          },
        },
      },
    },
  },
  bottomDiv: {
    display: "flex",
    flexDirection: "column",
    padding: "0 35px",
    "& > button": {
      width: "100%",
      height: 50,
      fontSize: "1.35rem",
      fontWeight: "bold",
    },
  },
  divider: {
    borderStyle: "dotted",
    borderColor: theme.palette.divider,
    margin: "25px 0",
  },
  userIcon: {
    width: "100%",
    height: "100%",
    color: "rgba(0, 0, 0, 0.54)",
  },
  userImage: {
    width: "100%",
    aspectRatio: "1",
    borderRadius: "50%",
    objectFit: "cover",
    padding: 20,
  },
  uploadButton: {
    position: "absolute",
    bottom: 5,
    right: 5,
    border: "3.5px solid rgba(0, 0, 0, 0.54)",
    "& > svg": {
      fontSize: "1.5rem",
    },
    "&:hover": {
      borderColor: theme.palette.primary.main,
      "& > svg": {
        color: theme.palette.primary.main,
      },
    },
  },
  uploadInput: {
    display: "none",
  },
});

const JoinContainer = () => {
  const classes = useStyles(styles);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [image, setImage] = useState<any>();
  const [userID, setUserID] = useState("");
  const [userName, setUserName] = useState("");
  const [nationality, setNationality] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [zipCode, setZipCode] = useState("");

  const [isCheckID, setIsCheckID] = useState(false);
  const [isCheckEmail, setIsCheckEmail] = useState(false);
  const [isCheckPhone, setIsCheckPhone] = useState(false);

  const [errorID, setErrorID] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPhone, setErrorPhone] = useState(false);

  const [helperTextID, setHelperTextID] = useState<string | null>("");
  const [helperTextEmail, setHelperTextEmail] = useState<string | null>("");
  const [helperTextPhone, setHelperTextPhone] = useState<string | null>("");

  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [error, setError] = useState(false);
  const [errorCode, setErrorCode] = useState(0);

  const checkIDMutation = useMutation(checkUserDuplicate, {
    onSuccess: (response) => {
      if (isNotNull(response.data.puid)) {
        setHelperTextID(t("USER.MSG.ALREADY_EXIST_ID"));
        setErrorID(true);
      } else {
        setHelperTextID(t("USER.MSG.AVAILABLE_ID"));
      }
      setIsCheckID(true);
    },
    onError: (error) => {
      onError(error);
    },
    onSettled: () => {
      dispatch(loadingOff());
    },
  });
  const checkEmailMutation = useMutation(checkUserDuplicate, {
    onSuccess: (response) => {
      if (isNotNull(response.data.puid)) {
        setHelperTextEmail(t("USER.MSG.ALREADY_EXIST_EMAIL"));
        setErrorEmail(true);
      } else {
        setHelperTextEmail(t("USER.MSG.AVAILABLE_EMAIL"));
      }
      setIsCheckEmail(true);
    },
    onError: (error) => {
      onError(error);
    },
    onSettled: () => {
      dispatch(loadingOff());
    },
  });
  const checkPhoneMutation = useMutation(checkUserDuplicate, {
    onSuccess: (response) => {
      if (isNotNull(response.data.puid)) {
        setHelperTextPhone(t("USER.MSG.ALREADY_EXIST_PHONE"));
        setErrorPhone(true);
      } else {
        setHelperTextPhone(t("USER.MSG.AVAILABLE_PHONE"));
      }
      setIsCheckPhone(true);
    },
    onError: (error) => {
      onError(error);
    },
    onSettled: () => {
      dispatch(loadingOff());
    },
  });
  const loginMutation = useMutation(loginAPI, {
    onSuccess: (response) => {
      if (Object.keys(response.data).includes("access_token")) {
        dispatch(setLoginToken(response.data.access_token));
        navigate("/");
      } else {
        dispatch(
          setErrorText({
            title: t("DIALOG.TITLE.AUTH_SERVER_NOTICE"),
            content: t("DIALOG.404.FAIL_TO_GET_TOKEN"),
          })
        );
      }
    },
    onError: (error) => {
      onError(error);
    },
    onSettled: () => {
      dispatch(loadingOff());
    },
  });
  const postUserInfoMutation = useMutation(postUserInfo, {
    onSuccess: () => {
      loginMutation.mutate({
        type: "WEB",
        username: userID,
        password: password,
      });
    },
    onError: (error) => {
      dispatch(loadingOff());
      onError(error);
    },
  });

  const InputProps = (key: string) => {
    const onClick = () => {
      if (key === "id") {
        if (isNotNull(userID)) {
          if (userID.length >= 8) {
            dispatch(loadingOn());
            checkIDMutation.mutate({ data: { username: userID } });
          } else {
            setErrorID(true);
            setHelperTextID(t("USER.MSG.INPUT_AT_LEAST_CHAR", { length: 8 }));
          }
        } else {
          setErrorID(true);
          setHelperTextID(t("USER.MSG.INPUT_ID"));
        }
      } else if (key === "email") {
        if (isNotNull(email)) {
          if (emailRegex.test(email)) {
            dispatch(loadingOn());
            checkEmailMutation.mutate({ data: { email: email } });
          } else {
            setErrorEmail(true);
            setHelperTextEmail(t("USER.MSG.INVALID_EMAIL"));
          }
        } else {
          setErrorEmail(true);
          setHelperTextEmail(t("USER.MSG.INPUT_EMAIL"));
        }
      } else if (key === "phone") {
        if (isNotNull(phone)) {
          const phoneNumber = parsePhoneNumberFromString(phone);
          if (phoneNumber !== undefined && phoneNumber.isValid()) {
            dispatch(loadingOn());
            checkPhoneMutation.mutate({ data: { phone: phone } });
          } else {
            setErrorPhone(true);
            setHelperTextPhone(t("USER.MSG.INVALID_PHONE"));
          }
        } else {
          setErrorPhone(true);
          setHelperTextPhone(t("USER.MSG.INPUT_PHONE"));
        }
      }
    };

    return {
      endAdornment: (
        <InputAdornment position="end">
          <CheckIcon onClick={() => onClick()} />
        </InputAdornment>
      ),
    };
  };
  const getClassName = (code: number) => {
    let result = "";

    if (isNotNull(password) && isNotNull(passwordConfirm)) {
      if (password === passwordConfirm) {
        if (errorCode & code) {
          return "bad";
        } else {
          return "good";
        }
      }
    }
    return result;
  };
  const handlePasswordOnBlur = () => {
    if (isNotNull(password) && isNotNull(passwordConfirm)) {
      if (password === passwordConfirm) {
        setError(false);
      } else {
        setError(true);
      }
    } else {
      setError(false);
    }
  };
  const handleOnSubmit = async () => {
    if (!isCheckID) {
      enqueueSnackbar(t("USER.MSG.CHECK_EXIST_ID"), {
        variant: "warning",
      });
      return;
    }
    if (!isCheckEmail) {
      enqueueSnackbar(t("USER.MSG.CHECK_EXIST_EMAIL"), {
        variant: "warning",
      });
      return;
    }
    if (!isCheckPhone) {
      enqueueSnackbar(t("USER.MSG.CHECK_EXIST_PHONE"), {
        variant: "warning",
      });
      return;
    }

    if (errorID) {
      enqueueSnackbar(t("USER.MSG.CHECK_ID"), {
        variant: "warning",
      });
      return;
    }
    if (errorEmail) {
      enqueueSnackbar(t("USER.MSG.CHECK_EMAIL"), {
        variant: "warning",
      });
      return;
    }
    if (errorPhone) {
      enqueueSnackbar(t("USER.MSG.CHECK_PHONE"), {
        variant: "warning",
      });
      return;
    }
    if (error || errorCode !== 0) {
      enqueueSnackbar(t("USER.MSG.NOT_VALID_PASSWORD"), { variant: "error" });
      return;
    }

    // 필수값 확인
    if (
      isNull(userID) ||
      isNull(password) ||
      isNull(userName) ||
      isNull(email) ||
      isNull(phone) ||
      isNull(nationality)
    ) {
      enqueueSnackbar(t("MSG.CHECK_REQUIRE_VALUE"), { variant: "error" });
      return;
    }

    const asyncFunction = async () => {
      const sendData: { [key: string]: any } = {};
      sendData["name"] = userName;
      sendData["username"] = userID;
      sendData["password"] = password;
      sendData["image"] = undefined;
      sendData["email"] = email;
      sendData["phone"] = phone.replaceAll(" ", "");
      sendData["nationality"] = nationality;
      sendData["address1"] = address1;
      sendData["address2"] = address2;
      sendData["address3"] = address3;
      sendData["zip_code"] = zipCode;
      sendData["country"] = country;
      sendData["city"] = city;
      sendData["state"] = state;
      sendData["group"] = null;
      sendData["is_superuser"] = false;
      sendData["is_manager"] = false;
      sendData["is_staff"] = false;

      if (isNotNull(image)) {
        const fileData = await resizeImage(image, 320, 240, 100, 0, "blob");
        const blobData = fileData as BlobPart;
        sendData["image"] = new File([blobData], uuidv4() + ".jpg", {
          type: "image/jpg",
        });
      }

      dispatch(loadingOn());
      postUserInfoMutation.mutate({ data: sendData });
    };
    asyncFunction();
  };

  useEffect(() => {
    window.onbeforeunload = () => {
      window.scrollTo(0, 0);
    };
  }, []);

  useEffect(() => {
    if (isNotNull(password) && isNotNull(passwordConfirm)) {
      if (password === passwordConfirm) {
        setErrorCode(validatePassword(password));
      } else {
        setErrorCode(0);
      }
    } else {
      setErrorCode(0);
    }
  }, [password, passwordConfirm]);

  useEffect(() => {
    setIsCheckID(false);
    setErrorID(false);
    setHelperTextID("");
  }, [userID]);

  useEffect(() => {
    setIsCheckEmail(false);
    setErrorEmail(false);
    setHelperTextEmail("");
  }, [email]);

  useEffect(() => {
    setIsCheckPhone(false);
    setErrorPhone(false);
    setHelperTextPhone("");
  }, [phone]);

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <Typography component="h1">Membership Register</Typography>
        <ul className="content">
          <li>
            <Markup content={t("JOIN.MSG.NOTICE_1")} />
          </li>
          <li>
            <Markup content={t("JOIN.MSG.NOTICE_2")} />
          </li>
          <li className="listStyleNone">{t("JOIN.MSG.NOTICE_3")}</li>
          <li>{t("JOIN.MSG.NOTICE_4")}</li>
          <li className="listStyleNone">
            <Markup content={t("JOIN.MSG.NOTICE_5")} />
          </li>
        </ul>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.topDiv}>
        <div>
          {isNull(image) ? (
            <AccountCircleIcon
              className={classes.userIcon}
              onDoubleClick={() => {
                document.getElementById("imageUpload")?.click();
              }}
            />
          ) : (
            <Image
              alt={""}
              src={image}
              className={classes.userImage}
              onDoubleClick={() => {
                document.getElementById("imageUpload")?.click();
              }}
            />
          )}
          <IconButton
            className={classes.uploadButton}
            onClick={() => {
              document.getElementById("imageUpload")?.click();
            }}
          >
            <CollectionsIcon />
          </IconButton>
          <input
            type="file"
            id="imageUpload"
            name="imageUpload"
            accept="image/jpg, image/gif, image/png, image/jpeg"
            className={classes.uploadInput}
            onChange={async (e: any) => {
              const resizeFile = await resizeImage(
                e.target.files[0],
                320,
                240,
                75,
                0,
                "file"
              );
              setImage(resizeFile);
            }}
          />
        </div>
        <div>
          <Typography className="unique-value">ID</Typography>
          <Input
            className={isCheckID ? (!errorID ? "good" : "bad") : ""}
            variant="standard"
            value={userID}
            dispatch={setUserID}
            error={errorID}
            helperText={helperTextID}
            InputProps={InputProps("id")}
            inputProps={{
              maxLength: 32,
            }}
          />
          <Typography className="require-value">Password</Typography>
          <Input
            type="password"
            variant="standard"
            placeholder={t("USER.NEW_PASSWORD")}
            error={error || errorCode !== 0}
            value={password}
            dispatch={(value: string) => {
              setPassword(String(value).trim());
            }}
            onBlur={() => handlePasswordOnBlur()}
            inputProps={{
              maxLength: 32,
            }}
          />
          <Typography className="require-value">Confirm Password</Typography>
          <Input
            type="password"
            variant="standard"
            placeholder={t("USER.NEW_PASSWORD_CONFIRM")}
            error={error || errorCode !== 0}
            helperText={error ? t("USER.MSG.PASSWORD_ERROR") : ""}
            value={passwordConfirm}
            dispatch={(value: string) => {
              setPasswordConfirm(String(value).trim());
            }}
            onBlur={() => handlePasswordOnBlur()}
            inputProps={{
              maxLength: 32,
            }}
          />
          <Typography style={{ marginBottom: 5 }}>
            {t("USER.PASSWORD_CONDITION.TITLE")}
          </Typography>
          <ul>
            <li className={getClassName(1)}>
              {t("USER.PASSWORD_CONDITION.CONTENT_1")}
            </li>
            <li className={getClassName(2)}>
              {t("USER.PASSWORD_CONDITION.CONTENT_2")}
            </li>
            <li className={getClassName(4)}>
              {t("USER.PASSWORD_CONDITION.CONTENT_3")}
            </li>
            <li className={getClassName(8)}>
              {t("USER.PASSWORD_CONDITION.CONTENT_4")}
            </li>
            <li className={`${getClassName(8)} special`}>
              `~!@#$%^&*()-_=+{}[]:;'"
            </li>
          </ul>
        </div>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.middleDiv}>
        <Typography component="h1">Basic Information</Typography>
        <div className="content">
          <Typography className="require-value">Name</Typography>
          <Input
            variant="standard"
            value={userName}
            dispatch={setUserName}
            fullWidth
            inputProps={{
              maxLength: 32,
            }}
          />
          <Typography className="require-value">Nationality</Typography>
          <NationAutocomplete
            variant="standard"
            value={nationality}
            dispatch={setNationality}
            fullWidth
          />
          <Typography className="unique-value">Email Address</Typography>
          <Input
            className={isCheckEmail ? (!errorEmail ? "good" : "bad") : ""}
            variant="standard"
            value={email}
            dispatch={setEmail}
            error={errorEmail}
            helperText={helperTextEmail}
            placeholder="example@autoskorea.com"
            fullWidth
            InputProps={InputProps("email")}
            inputProps={{
              maxLength: 64,
            }}
          />
          <Typography className="unique-value">Phone Number</Typography>
          <PhoneInput
            className={isCheckPhone ? (!errorPhone ? "good" : "bad") : ""}
            variant="standard"
            value={phone}
            dispatch={(value: string) => {
              if (isNull(value) || phoneRegex.test(value)) {
                if (isNotNull(value) && value[0] !== "+" && value[0] !== " ") {
                  setPhone("+" + value);
                } else {
                  setPhone(String(value).trim());
                }
              }
            }}
            placeholder="Please enter only digits except '-'"
            error={errorPhone}
            helperText={helperTextPhone}
            fullWidth
            InputProps={InputProps("phone")}
            inputProps={{
              maxLength: 20,
            }}
          />
        </div>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.middleDiv2}>
        <Typography component="h1">Shipping Information</Typography>
        <div className="content">
          <Typography>Address</Typography>
          <Input
            variant="standard"
            value={address1}
            dispatch={setAddress1}
            fullWidth
            inputProps={{
              maxLength: 80,
            }}
          />
          <Typography>Address 2</Typography>
          <Input
            variant="standard"
            value={address2}
            dispatch={setAddress2}
            fullWidth
            inputProps={{
              maxLength: 80,
            }}
          />
          <Typography>Address 3</Typography>
          <Input
            variant="standard"
            value={address3}
            dispatch={setAddress3}
            fullWidth
            inputProps={{
              maxLength: 80,
            }}
          />
        </div>
        <div className="content">
          <div className="subcontent">
            <div>
              <Typography>City</Typography>
              <Input
                variant="standard"
                value={city}
                dispatch={setCity}
                fullWidth
                inputProps={{
                  maxLength: 64,
                }}
              />
            </div>
            <div>
              <Typography>State</Typography>
              <Input
                variant="standard"
                value={state}
                dispatch={setState}
                fullWidth
                inputProps={{
                  maxLength: 64,
                }}
              />
            </div>
          </div>
          <div className="subcontent">
            <div>
              <Typography>Country</Typography>
              <NationAutocomplete
                variant="standard"
                value={country}
                dispatch={setCountry}
                fullWidth
              />
            </div>
            <div>
              <Typography>Zip Code</Typography>
              <Input
                variant="standard"
                value={zipCode}
                dispatch={setZipCode}
                fullWidth
                inputProps={{
                  maxLength: 16,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.bottomDiv}>
        <Button variant="contained" onClick={() => handleOnSubmit()}>
          {t("BUTTON.JOIN")}
        </Button>
      </div>
    </div>
  );
};

export default JoinContainer;
