// Components
import { SNSIcon } from "@/common/components";
// Styles
import { useStyles } from "@/common/lib/style/hooks";

const styles = () => ({
  container: {
    position: "fixed",
    bottom: "3vh",
    right: "6vw",
    zIndex: 99,
    display: "flex",
    flexDirection: "column",
  },
  snsIcon: {
    margin: "1.5vh 0",
  },
});

const SNSFab = () => {
  const classes = useStyles(styles);

  return (
    <div className={classes.container}>
      <SNSIcon sns="whatsApp" />
      <SNSIcon sns="facebook" className={classes.snsIcon} />
      <SNSIcon sns="youtube" />
    </div>
  );
};

export default SNSFab;
