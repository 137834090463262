/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
// Redux
import { useTypedDispatch } from "@/common/lib/redux/store";
import {
  setErrorText,
  loadingOn,
  loadingOff,
} from "@/common/lib/redux/actions/commonAction";
// Components
import BasePage from "@/browser/pages/common/BasePage";
import { SNSFab } from "@/browser/components/common";
import {
  DetailContainer,
  PromoteContainer,
  TopContainer,
} from "@/browser/components/vehicle/detail";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// API
import { getVehicleInfo } from "@/common/lib/api/vehicle";
import { isNotNull } from "@/common/lib/common";

const styles = () => ({
  basePage: {
    marginTop: "80px !important",
    marginBottom: "80px !important",
  },
});

const DetailPage = () => {
  const classes = useStyles(styles);
  const params = useParams();
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [init, setInit] = useState(false);
  const [data, setData] = useState<VehicleData>();

  const vehicleInfoQuery = useQuery(
    "vehicleInfo",
    () => {
      dispatch(loadingOn());
      if (params.uid !== undefined) {
        return getVehicleInfo(params.uid);
      } else {
        throw new Error("Check if Vehicle's UID exists.");
      }
    },
    {
      retry: false,
      staleTime: 1000,
      enabled: isNotNull(params.uid),
      onSettled: () => {
        setInit(true);
        dispatch(loadingOff());
      },
    }
  );

  useEffect(() => {
    if (init && data === undefined) {
      dispatch(
        setErrorText({
          title: t("DIALOG.TITLE.AUTH_SERVER_NOTICE"),
          content: t("DIALOG.404.NOT_FOUND_VEHICLE"),
          onClose: () => {
            navigate("/vehicle/list");
          },
        })
      );
    }
  }, [init]);

  useEffect(() => {
    if (vehicleInfoQuery.isSuccess) {
      window.scrollTo(0, 0);
      setData(vehicleInfoQuery.data.data);
    }
  }, [vehicleInfoQuery.isLoading]);

  return (
    <BasePage className={classes.basePage}>
      <PromoteContainer data={data} />
      <TopContainer data={data} />
      <DetailContainer data={data} />
      <SNSFab />
    </BasePage>
  );
};

export default DetailPage;
