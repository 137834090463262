const BrakeIcon = ({ ...props }: any) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <g transform="translate(0, 512) scale(0.1,-0.1)">
        <path
          d="M2499 5091 l-29 -29 0 -366 c0 -233 4 -375 11 -393 14 -39 67 -91
103 -103 17 -5 77 -18 133 -29 282 -54 580 -195 808 -381 98 -80 259 -251 328
-347 158 -222 269 -477 318 -735 12 -62 27 -124 34 -138 18 -34 64 -77 98 -89
18 -7 160 -11 393 -11 l366 0 29 29 c34 35 35 45 14 208 -62 489 -252 952
-552 1346 -110 144 -372 404 -518 513 -400 299 -843 479 -1326 539 -168 21
-175 20 -210 -14z m305 -546 c58 -53 19 -155 -60 -155 -51 0 -89 38 -89 90 0
34 6 47 28 66 37 32 86 32 121 -1z m1741 -1741 c33 -35 33 -84 1 -121 -53 -61
-156 -21 -156 61 0 50 38 86 90 86 31 0 47 -6 65 -26z"
        />
        <path
          d="M2085 4734 c-375 -51 -756 -197 -1050 -403 -252 -177 -507 -444 -662
-694 -402 -651 -471 -1441 -187 -2149 144 -359 386 -692 681 -935 334 -275
700 -443 1133 -520 126 -23 174 -26 370 -27 244 -1 360 12 560 61 595 144
1103 505 1442 1023 141 215 264 507 322 765 26 112 56 329 56 397 l0 37 -242
3 -243 3 -58 29 c-64 33 -134 102 -166 164 -12 23 -33 99 -47 169 -86 437
-309 791 -659 1048 -200 147 -413 239 -674 291 -69 13 -146 34 -171 46 -59 29
-140 110 -170 173 -24 48 -25 58 -28 293 l-3 242 -52 -1 c-29 -1 -97 -7 -152
-15z m-20 -269 c18 -17 25 -35 25 -62 -1 -53 -103 -466 -123 -497 -45 -69
-157 -32 -157 52 0 20 25 138 55 262 62 251 72 270 137 270 28 0 46 -7 63 -25z
m-967 -452 c22 -22 41 -76 96 -264 47 -161 67 -245 62 -263 -10 -39 -45 -66
-87 -66 -66 0 -74 14 -149 270 -38 129 -70 248 -70 263 0 18 10 39 29 58 39
38 83 39 119 2z m1531 -288 c751 -144 1237 -862 1092 -1613 -101 -521 -500
-937 -1024 -1068 -137 -34 -394 -44 -539 -20 -198 32 -411 120 -573 234 -103
74 -255 226 -327 327 -116 165 -203 378 -234 578 -20 124 -14 382 11 497 55
252 173 473 349 658 119 124 235 210 381 282 170 83 267 112 490 144 59 9 292
-3 374 -19z m-2156 -577 c42 -22 375 -348 381 -374 15 -59 -50 -125 -110 -110
-39 10 -391 350 -399 386 -7 32 15 83 42 99 24 14 59 14 86 -1z m3747 -1140
c100 -94 185 -179 191 -189 38 -71 -33 -157 -107 -129 -37 15 -383 348 -390
376 -15 60 27 114 88 114 35 0 50 -11 218 -172z m-3610 27 c251 -62 270 -72
270 -137 0 -28 -7 -46 -25 -63 -38 -39 -35 -39 -325 31 -192 46 -228 58 -247
80 -52 58 -13 144 64 144 21 0 139 -25 263 -55z m3019 -710 c35 -18 29 -2 111
-281 42 -142 66 -242 63 -258 -12 -61 -108 -89 -151 -43 -13 14 -45 105 -91
263 -62 212 -70 245 -60 273 11 32 49 60 82 61 10 0 31 -7 46 -15z m-2322 -89
c36 -31 39 -95 6 -127 -22 -23 -464 -159 -515 -159 -15 0 -39 11 -55 24 -35
30 -39 95 -7 126 22 22 456 157 511 159 20 1 42 -8 60 -23z m1587 -360 c21 -9
37 -25 46 -46 13 -30 10 -49 -34 -229 -68 -278 -74 -298 -98 -321 -46 -43
-129 -16 -144 47 -8 31 99 485 122 521 13 20 44 40 67 41 4 1 22 -5 41 -13z
m-859 -30 c39 -16 55 -42 55 -86 0 -24 -14 -46 -62 -98 -129 -140 -288 -301
-308 -312 -28 -15 -72 -4 -98 23 -21 22 -27 42 -24 82 2 25 332 375 370 392
34 15 27 15 67 -1z"
        />
        <path
          d="M2270 3560 c-612 -55 -1080 -569 -1080 -1186 0 -648 536 -1184 1184
-1184 698 0 1244 594 1185 1289 -56 656 -632 1139 -1289 1081z m-118 -249 c26
-23 32 -36 31 -67 -3 -100 -143 -116 -177 -20 -10 30 13 85 42 101 41 22 68
18 104 -14z m391 -317 c208 -53 395 -241 453 -454 28 -102 23 -253 -10 -357
-52 -162 -171 -300 -321 -374 -117 -57 -189 -72 -315 -66 -176 8 -303 63 -422
184 -123 123 -177 247 -185 423 -6 126 9 198 66 315 90 182 270 313 475 346
67 10 183 3 259 -17z m640 -25 c31 -33 34 -57 12 -99 -16 -31 -70 -55 -101
-44 -42 15 -64 46 -64 89 0 30 6 47 23 62 43 40 87 38 130 -8z m-1664 -525
c25 -21 31 -33 31 -65 0 -86 -100 -123 -154 -58 -29 34 -32 62 -12 98 30 53
89 64 135 25z m1658 -538 c22 -19 28 -32 28 -66 0 -34 -6 -47 -28 -66 -62 -53
-147 -15 -147 66 0 80 86 118 147 66z m-1038 -321 c54 -27 61 -108 13 -145
-47 -38 -122 -18 -143 37 -20 53 27 121 84 123 10 0 31 -7 46 -15z"
        />
        <path
          d="M2238 2811 c-298 -98 -413 -459 -225 -706 194 -255 573 -241 748 27
52 81 73 165 67 276 -4 79 -9 100 -41 164 -47 97 -118 168 -215 216 -70 34
-83 37 -176 39 -78 2 -114 -1 -158 -16z m261 -192 c102 -51 157 -146 149 -258
-6 -79 -32 -134 -87 -184 -56 -50 -104 -69 -182 -71 -195 -3 -330 198 -254
380 17 42 81 111 119 131 85 44 170 45 255 2z"
        />
        <path
          d="M2321 2444 c-25 -21 -31 -33 -31 -65 0 -86 100 -123 154 -58 14 17
26 39 26 49 0 51 -49 100 -99 100 -11 0 -33 -12 -50 -26z"
        />
      </g>
    </svg>
  );
};

export default BrakeIcon;
