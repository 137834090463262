const HeaterIcon = ({ ...props }: any) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <g transform="translate(0, 512) scale(0.1,-0.1)">
        <path
          d="M1110 4854 c-293 -27 -628 -109 -913 -223 -124 -50 -172 -77 -185
-106 -8 -18 -12 -105 -12 -286 0 -282 4 -304 55 -327 42 -19 59 -15 183 47
218 109 450 185 697 228 157 27 508 24 680 -6 217 -38 327 -81 707 -274 254
-129 375 -184 468 -215 433 -142 972 -134 1429 23 41 14 80 25 87 25 6 0 40
-50 75 -111 79 -136 116 -162 187 -125 36 19 552 968 552 1016 0 46 -45 87
-101 94 -24 3 -275 10 -556 16 -560 12 -555 12 -581 -45 -20 -44 -14 -64 49
-177 34 -60 59 -111 57 -114 -3 -2 -50 -13 -104 -24 -203 -41 -536 -36 -766
10 -142 29 -244 69 -418 165 -462 254 -676 337 -999 385 -131 20 -480 34 -591
24z m545 -219 c303 -46 551 -140 924 -353 203 -115 339 -168 521 -202 349 -66
745 -48 1024 46 130 43 146 96 70 226 -26 43 -45 80 -43 81 6 4 693 -13 697
-17 3 -4 -315 -596 -328 -609 -4 -5 -24 21 -44 57 -42 74 -77 106 -117 106
-16 0 -62 -13 -103 -30 -441 -175 -990 -196 -1410 -56 -68 23 -236 101 -459
214 -374 189 -466 225 -673 267 -461 94 -955 38 -1424 -159 -46 -20 -86 -36
-87 -36 -2 0 -3 56 -3 124 l0 124 133 50 c259 98 509 157 782 186 87 9 434 -3
540 -19z"
        />
        <path
          d="M890 3939 c-36 -6 -114 -26 -173 -46 -446 -148 -741 -582 -714 -1053
27 -459 347 -843 803 -961 117 -30 349 -33 462 -6 297 71 525 234 678 485 26
42 49 79 51 81 1 2 159 -73 350 -167 370 -182 479 -224 678 -261 375 -71 761
-49 1131 65 66 20 128 39 140 41 17 4 31 -13 85 -107 79 -137 116 -163 187
-126 36 19 552 968 552 1016 0 46 -45 87 -101 94 -24 3 -275 10 -556 16 -560
12 -555 12 -581 -45 -20 -44 -14 -64 49 -177 34 -60 59 -111 57 -114 -3 -2
-50 -13 -104 -24 -203 -41 -536 -36 -766 10 -143 30 -244 70 -418 166 -204
112 -413 214 -527 255 l-92 34 -26 84 c-37 118 -66 179 -130 277 -140 216
-368 377 -625 443 -99 26 -307 36 -410 20z m50 -220 c0 -67 -99 -425 -151
-545 l-19 -46 -65 84 c-84 110 -132 132 -188 88 -25 -20 -151 -218 -200 -315
-36 -73 -66 -177 -74 -259 l-7 -71 -14 50 c-10 33 -15 99 -15 195 0 129 3 154
26 233 57 187 154 328 301 439 97 73 171 111 278 142 106 32 128 33 128 5z
m366 -10 c170 -52 318 -159 429 -310 62 -84 99 -159 132 -266 23 -78 26 -104
26 -233 0 -129 -3 -155 -27 -234 -15 -49 -30 -95 -34 -102 -4 -7 -7 42 -7 109
0 152 -20 236 -85 373 -63 130 -94 164 -150 164 -46 0 -70 -20 -122 -99 -15
-22 -32 -41 -36 -41 -5 0 -12 31 -16 69 -12 132 -75 336 -170 554 -9 20 -14
37 -12 37 2 0 35 -10 72 -21z m-151 -329 c54 -164 68 -240 69 -390 2 -148 8
-170 55 -190 56 -23 167 16 250 86 24 21 46 33 51 28 5 -5 16 -35 24 -67 74
-285 -46 -592 -285 -730 -148 -86 -372 -87 -544 -2 -138 69 -257 206 -306 356
-34 105 -34 276 0 364 23 59 96 191 111 200 4 2 34 -34 66 -81 33 -47 72 -93
88 -101 43 -22 89 -9 126 35 64 76 177 353 225 550 12 50 20 69 26 60 5 -7 24
-60 44 -118z m1190 -600 c121 -60 275 -139 343 -174 194 -103 377 -154 632
-176 296 -26 556 -2 787 71 149 48 167 95 87 231 -26 43 -45 80 -43 81 6 4
693 -13 697 -17 3 -4 -315 -596 -328 -609 -4 -5 -24 21 -44 57 -42 74 -77 106
-117 106 -16 0 -62 -13 -103 -30 -439 -174 -992 -197 -1408 -57 -57 20 -252
109 -441 203 -329 162 -339 168 -333 193 3 14 11 72 18 129 7 56 17 102 23
102 6 0 109 -49 230 -110z"
        />
        <path
          d="M1120 1624 c-19 -2 -78 -9 -130 -15 -346 -39 -932 -226 -976 -310
-11 -22 -14 -81 -14 -289 0 -283 4 -305 55 -328 42 -19 59 -15 183 47 279 139
557 219 861 247 64 5 79 11 103 34 20 20 28 38 28 63 0 42 -37 92 -75 101 -36
9 -268 -20 -404 -50 -144 -32 -301 -82 -439 -140 -58 -24 -107 -44 -108 -44
-2 0 -4 56 -4 124 l0 123 97 38 c527 201 1004 253 1473 159 204 -41 351 -95
590 -218 421 -215 506 -256 595 -281 362 -103 844 -99 1169 11 130 43 146 96
70 226 -26 43 -45 80 -43 81 6 4 693 -13 697 -17 3 -4 -314 -595 -328 -609 -4
-5 -24 21 -44 57 -46 83 -79 109 -126 103 -19 -3 -94 -25 -166 -51 -368 -131
-764 -160 -1139 -85 -174 35 -277 77 -643 261 -249 125 -365 178 -388 178 -49
0 -96 -44 -97 -92 0 -19 6 -46 15 -59 16 -24 637 -341 782 -399 100 -40 225
-75 336 -94 393 -66 801 -36 1159 85 47 16 90 29 97 29 6 0 40 -50 75 -111 79
-136 116 -162 187 -125 36 19 552 968 552 1016 0 46 -45 87 -101 94 -24 3
-275 10 -556 16 -560 12 -555 12 -581 -45 -20 -44 -14 -64 49 -177 34 -60 59
-111 57 -114 -3 -2 -52 -14 -109 -26 -88 -18 -138 -22 -329 -22 -369 -1 -523
33 -785 168 -88 46 -230 120 -315 164 -372 194 -667 269 -1090 277 -113 2
-221 1 -240 -1z"
        />
        <path
          d="M1550 1153 c-52 -20 -83 -89 -60 -134 47 -92 173 -73 187 27 4 35 1
46 -23 73 -29 31 -74 46 -104 34z"
        />
      </g>
    </svg>
  );
};

export default HeaterIcon;
