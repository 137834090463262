import { useEffect, useMemo, useState } from "react";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
import { useSnackbar } from "notistack";
// Redux
import { setLoginToken } from "@/common/lib/redux/actions/authAction";
import {
  loadingOn,
  loadingOff,
  setAccessURL,
} from "@/common/lib/redux/actions/commonAction";
import { setParams } from "@/common/lib/redux/actions/searchAction";
import { initWishList } from "@/common/lib/redux/actions/globalAction";
import { useTypedDispatch, useTypedSelector } from "@/common/lib/redux/store";
// Components
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Theme,
  Typography,
} from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import { Dropdown } from "@/common/components";
import { CompanyLogoIcon } from "@/common/icons";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// API
import { onError } from "@/common/lib/api/common";
import { logoutAPI } from "@/common/lib/api/auth";
// Libs
import i18n from "@/common/lib/lang/i18n";
import { checkLogin, getTokenData, isNull } from "@/common/lib/common";

const styles = (theme: Theme) => ({
  container: {
    minWidth: 1400,
    backgroundColor: theme.palette.header.main,
  },
  menu: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: 1200,
    height: 40,
    margin: "0 auto",
  },
  menuUl: {
    display: "flex",
    alignItems: "center",
    margin: "0 15px",
    padding: 0,
    listStyle: "none",
    "& > li": {
      display: "inline-block",
      fontSize: 14,
      letterSpacing: "-0.75px",
      color: theme.palette.header.text,
      cursor: "default",
      whiteSpace: "pre",
      userSelect: "none",
      "& > div": {
        display: "inline-block",
        "&:hover": {
          cursor: "pointer",
          textDecoration: "underline",
        },
      },
      "& > span.puid": {
        "&:hover": {
          cursor: "copy",
          textDecoration: "underline",
        },
      },
    },
    "& > li:not(:first-child):before": {
      display: "inline-block",
      content: "''",
      width: 1,
      height: 10,
      backgroundColor: theme.palette.primary.contrastText,
      margin: "0 10px",
    },
  },
  titleDiv: {
    display: "flex",
    alignItems: "flex-end",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    lineHeight: 1.35,
    cursor: "default",
    "& > span > span": {
      color: theme.palette.primary.main,
    },
  },
  companyLogoIcon: {
    width: 165,
    fill: theme.palette.logo.darkBlue,
    marginRight: 15,
    cursor: "pointer",
  },
  languageIcon: {
    color: theme.palette.primary.contrastText,
    fontSize: 22.5,
    marginRight: 7.5,
  },
  dropdownFormControl: {
    width: 72.5,
    fontSize: "14px !important",
  },
  dropdownSelect: {
    height: "27.5px !important",
    letterSpacing: "-0.75px",
    backgroundColor: theme.palette.grey[100],
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.hard,
    },
    "& .MuiSelect-select": {
      color: theme.palette.primary.hard,
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.primary.hard,
    },
  },
  dropdownMenuItem: {
    fontSize: 14,
    letterSpacing: "-0.75px",
  },
  accordion: {
    minWidth: 1400,
    borderBottom: "1px solid " + theme.palette.divider,
    "&::before": {
      display: "none",
    },
    "& .MuiCollapse-root": {
      position: "absolute",
      width: "100%",
      opacity: 0.8,
      backgroundColor: theme.palette.grey[100],
      zIndex: 10,
    },
  },
  summaryRoot: {
    width: 1140,
    margin: "0 auto",
    padding: 0,
  },
  summaryContent: {
    justifyContent: "space-between",
  },
  summaryUl: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: 0,
    margin: 0,
    listStyle: "none",
    userSelect: "none",
    "& > li": {
      width: 107.5,
      margin: "0 7.5px",
      textAlign: "center",
      fontWeight: "bold",
      wordSpacing: "-0.75px",
    },
  },
  detailRoot: {
    display: "flex",
    width: 1140,
    margin: "0 auto",
    padding: 0,
    justifyContent: "flex-end",
  },
  detailsUl: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    padding: 0,
    listStyle: "none",
    userSelect: "none",
    "& > li": {
      width: 107.5,
      margin: "0 7.5px",
      textAlign: "center",
      fontWeight: "bold",
      wordSpacing: "-0.75px",
    },
  },
  collapseUl: {
    margin: 0,
    padding: 0,
    listStyle: "none",
    userSelect: "none",
    "& > li": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: 45,
      cursor: "default",
      "&:hover": {
        "& > a, & > p": {
          cursor: "pointer",
          color: theme.palette.primary.main,
        },
      },
    },
  },
  anchor: {
    fontWeight: "bold",
    color: theme.palette.text.primary,
    textDecoration: "none",
  },
});

const Header = () => {
  const classes = useStyles(styles);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();
  const { loginToken } = useTypedSelector((state) => state.auth);
  const { init } = useTypedSelector((state) => state.common);
  const { enqueueSnackbar } = useSnackbar();

  const [expanded, setExpanded] = useState(false);
  const [tokenData, setTokenData] = useState<TokenData>({
    id: null,
    puid: undefined,
    guid: undefined,
    name: undefined,
    username: undefined,
    group: null,
    image: undefined,
    is_staff: false,
    is_manager: false,
    is_superuser: false,
  });

  const langList = useMemo(() => {
    const i18nLangList: Option[] = [];
    if (i18n.options.resources) {
      for (const [key, value] of Object.entries(i18n.options.resources)) {
        i18nLangList.push({ label: String(value.label), value: key });
      }
    }
    return i18nLangList;
  }, []);

  const logoutMutation = useMutation(logoutAPI, {
    onSuccess: () => {
      dispatch(setLoginToken(""));
      dispatch(initWishList());
      navigate("/");
    },
    onError: (error) => {
      onError(error);
    },
    onSettled: () => {
      dispatch(loadingOff());
    },
  });

  const handleMouseEnter = () => {
    setExpanded(true);
  };
  const handleMouseLeave = () => {
    setExpanded(false);
  };

  const handleImageOnClick = () => {
    navigate("/");
  };
  const handleMyInfoOnClick = () => {
    navigate("/myinfo");
  };
  const handleLoginOnClick = () => {
    dispatch(setAccessURL(location.pathname));
    navigate("/login");
  };
  const handleLogoutOnClick = () => {
    dispatch(loadingOn());
    logoutMutation.mutate();
  };
  const handleJoinOnClick = () => {
    navigate("/join");
  };
  const handleListOnClick = (type: string) => {
    if (isNull(type)) {
      dispatch(setParams({}));
    } else {
      dispatch(
        setParams({
          type: type,
        })
      );
    }
    if (window.location.pathname !== "/vehicle/list") {
      navigate("/vehicle/list");
    }
  };
  const handleCopyOnClick = (e: any) => {
    navigator.clipboard.writeText(e.target.textContent).then(
      () => {
        enqueueSnackbar(t("MSG.COPY_SUCCESS"), { variant: "success" });
      },
      () => {
        enqueueSnackbar(t("MSG.COPY_FAILURE"), { variant: "error" });
      }
    );
  };

  useEffect(() => {
    if (init) {
      setTokenData(getTokenData());
    }
  }, [init, loginToken]);

  return (
    <>
      <div className={classes.container}>
        <div className={classes.menu}>
          <ul className={classes.menuUl}>
            {checkLogin() ? (
              <>
                <li>
                  {t("HEADER.WELCOME", { user: tokenData["name"] })}
                  {"  "}(
                  <span className="puid" onClick={handleCopyOnClick}>
                    {tokenData["puid"]}
                  </span>
                  )
                </li>
                <li>
                  <div onClick={handleMyInfoOnClick}>{t("HEADER.MYINFO")}</div>
                </li>
                <li>
                  <div onClick={handleLogoutOnClick}>{t("HEADER.LOGOUT")}</div>
                </li>
              </>
            ) : (
              <>
                <li>
                  <div onClick={handleLoginOnClick}>{t("HEADER.LOGIN")}</div>
                </li>
                <li>
                  <div onClick={handleJoinOnClick}>{t("HEADER.JOIN")}</div>
                </li>
              </>
            )}
          </ul>
          <LanguageIcon className={classes.languageIcon} />
          <Dropdown
            value={i18n.language}
            options={langList}
            dispatch={i18n.changeLanguage}
            formControlClassName={classes.dropdownFormControl}
            selectClassName={classes.dropdownSelect}
            menuItemClassName={classes.dropdownMenuItem}
          />
        </div>
      </div>
      <Accordion
        disableGutters
        elevation={0}
        square
        expanded={expanded}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        classes={{
          root: classes.accordion,
        }}
      >
        <AccordionSummary
          classes={{
            root: classes.summaryRoot,
            content: classes.summaryContent,
          }}
        >
          <div className={classes.titleDiv}>
            <CompanyLogoIcon
              className={classes.companyLogoIcon}
              onClick={handleImageOnClick}
            />
            <Typography className={classes.title}>
              <Markup content={t("HEADER.TITLE")} />
            </Typography>
          </div>
          <ul className={classes.summaryUl}>
            <li>ABOUT US</li>
            <li>
              PRODUCTS
              <br />
              LIST
            </li>
            <li>MY LIST</li>
            <li>OFFER</li>
            <li>
              HELP &<br />
              SUPPORT
            </li>
          </ul>
        </AccordionSummary>
        <AccordionDetails
          classes={{
            root: classes.detailRoot,
          }}
        >
          <ul className={classes.detailsUl}>
            <li>
              <ul className={classes.collapseUl}>
                <li>
                  <a className={classes.anchor} href="/profile">
                    PROFILE
                  </a>
                </li>
                <li>HOW TO BUY</li>
              </ul>
            </li>
            <li>
              <ul className={classes.collapseUl}>
                <li>
                  <Typography
                    className={classes.anchor}
                    onClick={() => handleListOnClick("")}
                  >
                    ALL
                  </Typography>
                </li>
                <li>
                  <Typography
                    className={classes.anchor}
                    onClick={() => handleListOnClick("CAR")}
                  >
                    CAR
                  </Typography>
                </li>
                <li>
                  <Typography
                    className={classes.anchor}
                    onClick={() => handleListOnClick("TRUCK")}
                  >
                    TRUCK
                  </Typography>
                </li>
                <li>
                  <Typography
                    className={classes.anchor}
                    onClick={() => handleListOnClick("BUS")}
                  >
                    BUS
                  </Typography>
                </li>
              </ul>
            </li>
            <li>
              <ul className={classes.collapseUl}>
                <li>
                  <a className={classes.anchor} href="/wishlist">
                    WISH LIST
                  </a>
                </li>
                <li>
                  <a className={classes.anchor} href="/booking">
                    BOOKING
                  </a>
                </li>
                <li>
                  <a className={classes.anchor} href="/purchase-history">
                    PURCHASE HISTORY
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <ul className={classes.collapseUl}>
                <li>
                  <a className={classes.anchor} href="/offer">
                    OFFER R.
                  </a>
                </li>
                <li>
                  <a className={classes.anchor} href="/offerlist">
                    OFFER LIST
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <ul className={classes.collapseUl}>
                <li>NOTICE</li>
                <li>FAQ</li>
                <li>
                  <a className={classes.anchor} href="/shipping">
                    SHIPPING
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default Header;
