const ChassisIcon = ({ ...props }: any) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <g transform="translate(0, 512) scale(0.1,-0.1)">
        <path
          d="M334 5086 l-34 -34 0 -728 c0 -563 3 -735 13 -754 7 -14 28 -33 47
-42 32 -16 74 -18 431 -18 218 0 410 3 428 6 17 4 42 18 56 31 23 24 25 32 27
147 l3 121 165 2 c108 2 173 7 189 15 32 17 51 66 51 134 l0 56 138 -4 c162
-4 190 -15 263 -100 26 -30 69 -69 98 -88 l50 -35 1 -941 0 -942 -71 -4 c-65
-3 -74 -6 -100 -36 l-29 -32 0 -164 0 -163 -71 -5 c-60 -5 -75 -10 -98 -32
-26 -26 -26 -29 -31 -199 l-5 -172 -97 -3 -98 -3 0 44 c0 69 -10 104 -37 132
-25 24 -29 25 -197 27 l-171 3 -3 121 c-2 115 -4 123 -27 147 -14 13 -39 27
-56 31 -18 3 -210 6 -428 6 -357 0 -399 -2 -431 -18 -19 -9 -40 -28 -47 -42
-10 -19 -13 -191 -13 -753 l0 -729 34 -34 35 -35 441 3 442 3 24 28 c22 26 24
36 24 148 l0 119 163 0 c109 0 170 4 187 13 35 17 60 73 60 135 l0 52 900 0
900 0 0 -52 c0 -62 25 -118 60 -135 17 -9 78 -13 188 -13 l162 0 0 -119 c0
-112 2 -122 24 -148 l24 -28 442 -3 441 -3 35 35 34 34 0 729 c0 562 -3 734
-13 753 -7 14 -28 33 -47 42 -32 16 -74 18 -431 18 -218 0 -410 -3 -428 -6
-17 -4 -42 -18 -56 -31 -23 -24 -25 -32 -27 -147 l-3 -121 -168 -3 c-190 -3
-210 -9 -227 -71 -5 -20 -10 -58 -10 -84 l0 -48 -97 3 -98 3 -5 172 c-5 170
-5 173 -31 199 -23 22 -38 27 -98 32 l-71 5 0 163 0 164 -29 32 c-26 30 -35
33 -100 36 l-71 4 0 942 1 941 50 35 c29 19 72 58 98 88 73 85 101 96 264 100
l137 4 0 -56 c0 -68 19 -117 51 -134 16 -8 81 -13 189 -15 l165 -2 3 -121 c2
-115 4 -123 27 -147 14 -13 39 -27 56 -31 18 -3 210 -6 428 -6 357 0 399 2
431 18 19 9 40 28 47 42 10 19 13 191 13 754 l0 728 -34 34 -35 35 -441 -3
-442 -3 -24 -28 c-22 -26 -24 -36 -24 -148 l0 -119 -162 0 c-110 0 -171 -4
-188 -13 -35 -17 -60 -73 -60 -134 l0 -53 -135 0 c-164 0 -168 2 -286 115
-258 248 -600 248 -858 0 -118 -113 -122 -115 -286 -115 l-135 0 0 53 c0 61
-25 117 -60 134 -17 9 -78 13 -187 13 l-163 0 0 119 c0 112 -2 122 -24 148
l-24 28 -442 3 -441 3 -35 -35z m766 -771 l0 -605 -300 0 -300 0 0 605 0 605
300 0 300 0 0 -605z m3520 0 l0 -605 -300 0 -300 0 0 605 0 605 300 0 300 0 0
-605z m-1886 361 c52 -25 91 -55 143 -109 51 -54 91 -85 139 -108 68 -34 70
-34 231 -37 l163 -4 0 -99 0 -99 -112 0 c-222 0 -307 -31 -428 -156 -54 -56
-92 -86 -140 -108 -60 -29 -73 -31 -170 -31 -97 0 -110 2 -170 31 -47 22 -86
52 -140 108 -122 125 -206 156 -427 156 l-113 0 0 99 0 99 163 4 c158 3 164 4
228 34 49 23 86 52 139 107 114 118 207 159 344 153 65 -4 91 -10 150 -40z
m-1224 -356 l0 -300 -105 0 -105 0 0 293 c0 162 3 297 7 300 3 4 51 7 105 7
l98 0 0 -300z m2308 -2 l2 -298 -105 0 -105 0 0 300 0 301 103 -3 102 -3 3
-297z m-1158 -1503 l0 -905 -100 0 -100 0 0 905 0 905 100 0 100 0 0 -905z
m200 -1204 l0 -98 -65 -5 c-72 -6 -111 -26 -125 -64 -6 -14 -10 -97 -10 -185
l0 -159 -100 0 -100 0 0 159 c0 88 -4 171 -10 185 -14 38 -53 58 -125 64 l-65
5 0 98 0 99 300 0 300 0 0 -99z m-1810 -806 l0 -605 -300 0 -300 0 0 605 0
605 300 0 300 0 0 -605z m3620 0 l0 -605 -300 0 -300 0 0 605 0 605 300 0 300
0 0 -605z m-2412 398 l3 -103 -101 0 -100 0 0 105 0 106 98 -3 97 -3 3 -102z
m802 2 l0 -105 -100 0 -100 0 0 98 c0 54 3 102 7 105 3 4 48 7 100 7 l93 0 0
-105z m-1600 -405 l0 -301 -102 3 -103 3 -3 298 -2 297 105 0 105 0 0 -300z
m2408 3 l-3 -298 -102 -3 -103 -3 0 301 0 300 105 0 105 0 -2 -297z m-408 -3
l0 -100 -900 0 -900 0 0 100 0 100 900 0 900 0 0 -100z"
        />
      </g>
    </svg>
  );
};

export default ChassisIcon;
