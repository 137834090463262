// Styles
import { useStyles } from "@/common/lib/style/hooks";

// Components
import BasePage from "@/browser/pages/common/BasePage";
import MainCarousel from "@/browser/components/about/profile/MainCarousel";
import AchieveContainer from "@/browser/components/about/profile/AchieveContainer";
import ProductsCarousel from "@/browser/components/about/profile/ProductsCarousel";
import YoutubeContainer from "@/browser/components/about/profile/YoutubeContainer";
import ProfileContainer from "@/browser/components/about/profile/ProfileContainer";
import MapContainer from "@/browser/components/about/MapContainer";
import { SNSFab } from "@/browser/components/common";

import { Divider } from "@mui/material";

const styles = () => ({
  basePage: {
    justifyContent: "center",
    width: 1200,
    marginBottom: 25,
    marginTop: 0,
  },
  divider: {
    width: 1200,
    margin: "15px 0",
  },
});

const ProfilePage = () => {
  const classes = useStyles(styles);

  return (
    <BasePage className={classes.basePage}>
      <MainCarousel />
      <AchieveContainer />
      <Divider className={classes.divider} />
      <ProductsCarousel />
      <Divider className={classes.divider} />
      <YoutubeContainer />
      <Divider className={classes.divider} />
      <ProfileContainer />
      <Divider className={classes.divider} />
      <MapContainer />
      <SNSFab />
    </BasePage>
  );
};

export default ProfilePage;
